app.factory("soglieConsumiFactory", ($http, $location, $q) => new Trace.SoglieConsumi.soglieConsumiFactory($http, $location, $q));
module Trace.SoglieConsumi {

    export class soglieConsumiFactory {
        public get baseUrl(): string {
            return appRoot + "api/soglieConsumi/";
        }

        static $inject = ["$http", "$location", "$q"];
        constructor(
            private $http: ng.IHttpService,
            private $location: ng.ILocationService,
            private $q: ng.IQService
        ) { }

        public ricerca(filtro: ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Pagination.GenericPaginationVm<VwSoglieConsumi>> {
            var workUrl = `${this.baseUrl}Ricerca/${page}`;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro)
                .success(data => { deferred.resolve(data); })
                .error(error => { deferred.reject(error); });

            return deferred.promise;
        }

        public ricercaItemByCodice(codice: string): ng.IPromise<ItemSoglia[]> {
            var workUrl = `${this.baseUrl}RicercaItemByCodice/${codice}`;
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success(data => { deferred.resolve(data); })
                .error(error => { deferred.reject(error); });

            return deferred.promise;
        }

        public listaRaggruppamentiMateriali(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = `${this.baseUrl}ListaRaggruppamentiMateriali`;
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success(data => { deferred.resolve(data); })
                .error(error => { deferred.reject(error); });

            return deferred.promise;
        }

        public get(id: number): ng.IPromise<VwSoglieConsumi> {
            var workUrl = `${this.baseUrl}${id}`;
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success(data => { deferred.resolve(data); })
                .error(error => { deferred.reject(error); });

            return deferred.promise;
        }

        public cancella(id: number): ng.IPromise<boolean> {
            var workUrl = `${this.baseUrl}${id}`;
            var deferred = this.$q.defer();
            this.$http.delete(workUrl)
                .success(data => { deferred.resolve(data); })
                .error(error => { deferred.reject(error); });

            return deferred.promise;
        }

        public crea(model: VwSoglieConsumi, sostituisci: boolean): ng.IPromise<VwSoglieConsumi> {
            var workUrl = `${this.baseUrl}${sostituisci}`;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, model)
                .success(data => { deferred.resolve(data); })
                .error(error => { deferred.reject(error); });

            return deferred.promise;
        }

        public modifica(model: VwSoglieConsumi, sostituisci: boolean): ng.IPromise<VwSoglieConsumi> {
            var workUrl = `${this.baseUrl}${model.Id}/${sostituisci}`;
            var deferred = this.$q.defer();
            this.$http.put(workUrl, model)
                .success(data => { deferred.resolve(data); })
                .error(error => { deferred.reject(error); });

            return deferred.promise;
        }

        public exists(model: VwSoglieConsumi): ng.IPromise<boolean> {
            var workUrl = `${this.baseUrl}exists`;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, model)
                .success(data => { deferred.resolve(data); })
                .error(error => { deferred.reject(error); });

            return deferred.promise;
        }
    }
}