namespace Trace.Set {
    export class TipoSetVm {
        public Id: number;
        public Codice: string;
        public Descrizione_it_IT: string;
        public Descrizione_en_US: string;
        public Descrizione_sq_AL: string;
        public FlgUnitaSterilizzazione: boolean;
        public FlgGriglie: boolean;
        public FlgBuste: boolean;
    }
}