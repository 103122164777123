
module Trace.Produzione {
    export class GrigliaVm implements GrigliaStrumentiVm {
        public IdLotto: number;
        public NumGriglia: number;
        public NumStrumenti: number;
        public IdMacchina: number;
        public NumCiclo: number;
        public Descrizione: string;
        public DesGruppo: string;
        public DesSottogruppo: string;
        public DesSede: string;
        public DesCentroCosto: string;
        public DesCentrale: string;
        public FlgUtilizzabile: boolean;
        public FlgTrovata: boolean;
        public Id: number;
        public Codice: string;
        public CodiceAlternativo: string;
        public DataCreazione: Date;
        public Descrizione_en_US: string;
        public Descrizione_it_IT: string;
        public Descrizione_sq_AL: string;
        public IdCentroCosto: number;
        public IdGruppo: number;
        public IdSede: number;
        public IdSottogruppo: number;
        public IdCentrale: number;
        public FlgAggiunta: boolean;
        public CodiceLotto: string;
        public IdTipo: number;
    }
}