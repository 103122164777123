module Trace.SoglieConsumi {
    export interface ItemSoglia {
        Id: number;
        // Indica se Prodotto (0) o TNT (1)
        IdTipoItem: number;
        // Indica la specifica del tipo (tnt sterile, materiale di consumo....)
        IdTipo: number;
        Tipo: string;
        Codice: string;
        Descrizione: string;
        IdUm: number;
        UnitaMisura: string;
        Qta: number;
    }
}