module Trace.Analisi {
    export class inevasoFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Analisi/";
        }

        public Ricerca(filtro: Analisi.FiltroInevaso): ng.IPromise<Analisi.VwAnalisiInevaso[]> {
            var workUrl = this.GetBaseUrl() + "RicercaOrdiniInevasi";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetUtenti(): ng.IPromise<Trace.Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "GetUtentiOrdiniInevasi";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

    }
}
app.factory("inevasoFactory", ($http, $location, $q) => new Trace.Analisi.inevasoFactory($http, $location, $q));