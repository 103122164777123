
app.factory("confezionamentoFactory", ($http, $location, $q) => new Trace.Confezionamento.confezionamentoFactory($http, $location, $q));
module Trace.Confezionamento {
    export class confezionamentoFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Confezionamento/";
        }

        public Lista(): ng.IPromise<Produzione.LottoProduzioneVm[]> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}