module Trace.Analisi {
    export class produttivitaController {
        private filtro: FiltroProduttivita; 
        private graficoShow: boolean;
        private gruppi: Trace.Gruppo.Gruppo[];
        private sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        private sedi: Trace.Sede.Sede[];
        private centriCosto: Trace.CentroCosto.CentroCosto[];
        private listaComplessita: Set.ComplessitaVm[];
        private tipiSet: Trace.Set.TipoSetVm[];
        private centrali: Trace.Centrale.Centrale[];
        private operatori: Helpers.SimpleEntity[];
        private modelliSet: Helpers.SimpleEntity[];
        private fasiProduzione: Produzione.FaseDetailVm[];
        private risultatoRicerca: Trace.Analisi.GraficiProduttivitaVm;
        private isLoading: boolean;

        static $inject = ['$scope','centraliFactory', 'produttivitaFactory','setFactory', 'modelliSetFactory', 'gruppiFactory', 'sottoGruppiFactory', 'sediFactory',
                            'centriCostoFactory', 'utenteFactory', 'fasiFactory', 'localeFactory', 'msgFactory'];
        constructor(
            private $scope: ng.IScope,
            private centraliFactory: Centrale.centraliFactory,
            private produttivitaFactory: Analisi.produttivitaFactory,
            private setFactory: Set.setFactory,
            private modelliSetFactory: ModelliSet.modelliSetFactory,           
            private gruppiFactory: Gruppo.gruppiFactory,
            private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory,           
            private utenteFactory: Utente.utenteFactory,           
            private fasiFactory: Fase.fasiFactory,
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory            
        ) {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 1000);

            this.risultatoRicerca = <Trace.Analisi.GraficiProduttivitaVm>{ GrafProdOraria: Array<Trace.Analisi.ProdOraria>() };
            this.filtro = new FiltroProduttivita();
            this.getModelliSet();
            this.getOperatori();
            this.getFasiProduzione();
            this.getComplessita();
            this.getGruppi();
            this.getTipiSet();
            this.getCentrali();
            this.setUpWatchers();
        }

        public ricerca(): void {
            if (this.filtro.IdFaseProduzione == undefined || this.filtro.IdCentrale == undefined) {
                let msg = "Seleziona ";
                if (this.filtro.IdFaseProduzione == undefined) msg += "una fase di produzione ";
                if (this.filtro.IdCentrale == undefined) msg += this.filtro.IdFaseProduzione == undefined ? "ed una centrale " : "una centrale ";
                msg += "prima di procedere alla ricerca.";
                this.msgFactory.alert(msg);
                return;
            }
            this.isLoading = true;
            this.graficoShow = true;
            //this.graficoShow = this.filtro.IdFaseProduzione != undefined ? true : false;
            this.produttivitaFactory.Ricerca(this.filtro).then(
                result => {
                    this.risultatoRicerca = result;
                    this.isLoading = false;
                    //this.updateLottiLavorati1();
                    //this.updateFasiLottiLavorati2();
                    //if (this.ricercaPerFase) this.updateFasiLottiLavorati3();

                    if (this.risultatoRicerca.GrafProdOraria != null) {                        
                        this.updateFasiLottiLavorati4();
                        this.updateFasiLottiLavorati5();
                        this.updateFasiLottiLavorati6();
                    }
                    else {
                        this.graficoShow = false;
                        this.msgFactory.alert(this.localeFactory.get("nessun_risultato_ricerca"));
                    }
                }
            );
        }
        private resetOriginale;
        private init() {
            
            Highcharts.Point.prototype.highlight = function (event) {
                this.onMouseOver(); // Show the hover marker
                this.series.chart.tooltip.refresh(this);
                this.series.chart.xAxis[0].drawCrosshair(event, this); // Show the crosshair
            };
            //this.resetOriginale = Highcharts.Pointer.prototype.reset; 
            //let vm = this;
            //Highcharts.Pointer.prototype.reset = function () {
            //    if ($(this.chart.renderTo).attr('id').indexOf("my-chart-5") >= 0) return undefined;
            //    else vm.resetOriginale();
            //};
        }
        
        //private initAppoggio() {
        //    return {  // array idUtente ]  
        //        //"0-1": new Array<number>(),
        //        //"1-2": new Array<number>(),
        //        //"2-3": new Array<number>(),
        //        //"3-4": new Array<number>(),
        //        //"4-5": new Array<number>(),
        //        //"5-6": new Array<number>(),
        //        "6-7": new Array<number>(),
        //        "7-8": new Array<number>(),
        //        "8-9": new Array<number>(),
        //        "9-10": new Array<number>(),
        //        "10-11": new Array<number>(),
        //        "11-12": new Array<number>(),
        //        "12-13": new Array<number>(),
        //        "13-14": new Array<number>(),
        //        "14-15": new Array<number>(),
        //        "15-16": new Array<number>(),
        //        "16-17": new Array<number>(),
        //        "17-18": new Array<number>(),
        //        "18-19": new Array<number>(),
        //        "19-20": new Array<number>(),
        //        "20-21": new Array<number>(),
        //        "21-22": new Array<number>(),
        //        "22-23": new Array<number>(),
        //        "23-00": new Array<number>()
        //    };
        //}
        //private findNumber(vec: Array<number>, value: number): number {
        //    let i: number = 0;            
        //    while (i < vec.length) {
        //        if (vec[i] == value) return i;
        //        i++;
        //    }
        //    return -1;
        //}
        private updateFasiLottiLavorati4() {
            if (this.risultatoRicerca == undefined) return;



            //this.risultatoRicerca.GrafProdOp = this.risultatoRicerca.GrafProdOp.slice(0, 5);
            //this.risultatoRicerca.GrafProdOp[0].LabelOperatore = "Massimo Verdi";
            //this.risultatoRicerca.GrafProdOp[1].LabelOperatore = "Gerardo Croci";
            //this.risultatoRicerca.GrafProdOp[2].LabelOperatore = "Maurizio De Angelis";
            //this.risultatoRicerca.GrafProdOp[3].LabelOperatore = "Lorenzo Tardioli";
            //this.risultatoRicerca.GrafProdOp[4].LabelOperatore = "Paolo Pirelli";
            
            let avg = this.risultatoRicerca.AvgProdOp;
            let avg2decimal = parseFloat(avg.toFixed(2));

            let chartOption = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: this.risultatoRicerca.GrafProdOp.map(r => r.LabelOperatore)
                },
                yAxis: {
                    //tickInterval: parseFloat(this.risultatoRicerca.SqmProdOraria.toFixed(2)),
                    //tickAmount: 4,
                    title: {
                        text: 'Strumenti / minuti'
                    },
                    //min: -100,
                    labels: {
                        formatter: function () {
                            //var val = (this.value + avg).toFixed(2);
                            if (this.value == 0)
                                return '<b>\u03BC = ' + avg2decimal + '</b>';
                            //else if (this.value == -sqm2decimal)
                            //    return '<b>\u03BC-\u03C3 = ' + val + '</b>';
                            //else if (this.value == sqm2decimal)
                            //    return '<b>\u03BC+\u03C3 = ' + val + '</b>';
                            else
                                return (this.value > 0 ? '+' : '') + this.value.toFixed(1) + '%';
                        },
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                legend: {
                    enabled: false
                },
                series: [<any>{
                    name: "Strumenti / minuti",
                    data: this.risultatoRicerca.GrafProdOp.map(r => r.ScStrMinuti),
                    color: Highcharts.getOptions().colors[1],
                    tooltip: {
                        pointFormatter: function () {
                            let txt = '<span style="color:' + this.series.color + '">\u25CF</span>' + this.series.name + ': <b>' + ((100 + this.y) * avg / 100).toFixed(1) + '</b><br/>';
                            txt += '<span style="color:' + this.series.color + ';opacity:0;" >\u25CF </span> Sc. strum/min: <b>' + (this.y > 0 ? '+' : '') + (this.y).toFixed(1) + '%</b>';
                            return txt;
                        }
                    }
                }]
            };
            $('#my-chart-4-container').highcharts(chartOption);
        }
        private updateFasiLottiLavorati5() {
            
            $('#my-chart-5-container').unbind('mousemove touchmove touchstart').bind('mousemove touchmove touchstart', function (e) {
                var chart,
                    point,
                    i,
                    event;

                let subCharts = Highcharts.charts.filter(h => h != undefined && 
                                    ($(h['renderTo']).attr('id') == 'my-chart-5_1-container'
                                    || $(h['renderTo']).attr('id') == 'my-chart-5_2-container'
                                    || $(h['renderTo']).attr('id') == 'my-chart-5_3-container'
                                    || $(h['renderTo']).attr('id') == 'my-chart-5_4-container'
                                    || $(h['renderTo']).attr('id') == 'my-chart-5_5-container'
                                    //|| $(h['renderTo']).attr('id') == 'my-chart-5_6-container'
                                    )
                );

                $.each(subCharts, function (index, value) {
                    chart = value;
                    event = chart.pointer.normalize(e.originalEvent); // Find coordinates within the chart

                    point = chart.series[0].searchPoint(event, true); // Get the hovered point

                    if (point) {
                        point.highlight(e);
                    }                    
                });                
            });
            //___________________________________________________________________________________________________

            let avg = this.risultatoRicerca.AvgProdOraria;
            let sqm = this.risultatoRicerca.SqmProdOraria;

            let chiavi = this.risultatoRicerca.GrafProdOraria.map(r => r.LabelFasciaOraria);
            
            let datiChart = [{
                name: 'Strumenti / minuti',
                type: 'column',
                data: this.risultatoRicerca.GrafProdOraria.map(r => r.ScStrMinuti),
                tooltip: {
                    pointFormatter: function () {     
                        let myColor: string;          
                        if (this.y >= 0) { // FASCA DEL VERDE
                            if (this.y <= sqm2decimal) { // fascia normale
                                myColor = "#008000";
                            }
                            else if (this.y <= sqm2decimal * 2) {
                                //if (this.y <= sqm2decimal * 2) { // fascia bravo
                                myColor = "#00C200";
                                //}
                                // else { // fascia bravissimo
                                //    myColor = "#00E100";
                                //}
                            }
                            else { // fenomeno
                                myColor = "#00bfff";
                            }
                        }
                        else { // FASCIA DEL ROSSO
                            if (this.y >= -sqm2decimal) { // fascia mediocre
                                myColor = "#FFBF18";
                            }
                            else if (this.y >= -sqm2decimal * 2) { // fascia malino
                                myColor = "#FF8100";
                            }
                            else 
                            {
                                //if (this.y >= -sqm2decimal * 3) { // fascia male
                                myColor = "#FF1F10";
                                //}
                                //else { // fascia malissimo
                                //    myColor = "#E10000";
                                //}
                            }
                        }  
                        if (this.y == 0) myColor = 'black';
                        let originale = this.y == 0 ? 0
                            : (this.y + avg2decimal).toFixed(2);

                        var percent = ((this.y + avg) * 100 / avg - 100);
                        //percent = percent < 0 ? percent + 100 : percent - 100;
                        var percentTxt =  (percent > 0 ? '+' + percent.toFixed(1) : percent.toFixed(1)) + '% ';  
                        let txt = '<span style="color:' + this.series.color + '">\u25CF</span> Strum/min: <b>' + originale + '</b>';
                        txt += '<span style="opacity:0;color:' + this.series.color + '">\u25CF</span>Sc. Strum/min: <span style="font-weight:bold;color:' + myColor + '">' + percentTxt /*(this.y > 0 ? '+' + (this.y).toFixed(2) : this.y == 0 ? this.y : (this.y).toFixed(2))*/ + '</span><br/>';
                        return txt;
                    }
                }
            }];

            let avg2decimal = parseFloat(avg.toFixed(2));
            let sqm2decimal = parseFloat(sqm.toFixed(2));
            //let avg2decimal = parseFloat(avg.toFixed(2));
            //let sqm2decimal = parseFloat(sqm.toFixed(2));

            let chartOption = {
                chart: {
                    marginLeft: 100, // Keep all charts left aligned
                    spacingTop: 20,
                    spacingBottom: 20,
                },
                title: {
                    text: 'Produttività',
                    align: 'left',
                    margin: 0,
                    x: 90
                },
                legend: {
                    enabled: false
                },
                xAxis: {
                    crosshair: true,
                    categories: chiavi,
                    labels: {
                        format: '{value}'
                    }
                },
                yAxis: {
                    title: {
                        text: null
                    },                    
                    color: Highcharts.getOptions().colors[1],
                    tickInterval: sqm2decimal, // era questo quello bono
                    //minRange: avg2decimal,
                    //min: -(avg * 0.9),
                    //tickAmount: 4,
                    min: -2 * sqm2decimal,
                    max: 2 * sqm2decimal,
                    labels: {
                        formatter: function () {
                            //var val = (this.value + avg).toFixed(2);
                            //if (this.value == 0)
                            //    return '<b>\u03BC = ' + val + '</b>';
                            //else if (this.value == -sqm2decimal)
                            //    return '<b>\u03BC-\u03C3 = ' + val + '</b>';
                            //else if (this.value == sqm2decimal)
                            //    return '<b>\u03BC+\u03C3 = ' + val + '</b>';
                            //else
                            //    return val;
                            var val = (this.value + avg).toFixed(2);
                            if (this.value == 0)
                                return '<b>\u03BC = ' + val + '</b>';
                            else {
                                var percent = ((this.value + avg) * 100 / avg - 100);
                                //percent = percent < 0 ? percent + 100 : percent - 100;
                                return (percent > 0 ? '+' + percent.toFixed(1) : percent.toFixed(1)) + '% ' + val;
                            }
                        },
                        //formatter: function () {
                        //    return this.value;
                        //},
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                },
                tooltip: {
                    positioner: function () {
                        return {
                            x: this.chart.chartWidth - this.label.width, // right aligned
                            y: 10 // align to title
                        };
                    },
                    borderWidth: 0,
                    backgroundColor: 'none',
                    pointFormat: '{point.y}',
                    headerFormat: '',
                    shadow: false,
                    style: {
                        fontSize: '18px'
                    }                    
                },                
                series: datiChart
            };
            
            $('#my-chart-5_1-container')  
             .highcharts(chartOption);
            
            $('#my-chart-5_2-container')
                .highcharts(<any>{
                    chart: {
                        marginLeft: 100, // Keep all charts left aligned
                        spacingTop: 20,
                        spacingBottom: 20
                    },
                    title: {
                        text: 'Strumenti lavorati / giorno',
                        align: 'left',
                        margin: 0,
                        x: 90
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        crosshair: true,
                        categories: chiavi,
                        labels: {
                            format: '{value}'
                        }
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        color: Highcharts.getOptions().colors[1],
                        fillOpacity: 0.3
                    },
                    tooltip: {
                        positioner: function () {
                            return {
                                x: this.chart.chartWidth - this.label.width, // right aligned
                                y: 10 // align to title
                            };
                        },
                        borderWidth: 0,
                        backgroundColor: 'none',
                        //pointFormat: '{point.y}',
                        headerFormat: '',
                        shadow: false,
                        style: {
                            fontSize: '18px'
                        },
                        //valueDecimals: dataset.valueDecimals
                    },
                    plotOptions: {
                        area: {
                            marker: {
                                enabled: false
                            }
                        }
                    },
                    series: [<any>{
                        data: this.risultatoRicerca.GrafProdOraria.map(r => r.MediaStrumenti),
                        type: 'area',
                        color: Highcharts.getOptions().colors[2],
                        fillOpacity: 0.3,
                        tooltip: {
                            pointFormatter: function () {
                                let txt = '<span style="color:' + this.series.color + '">\u25CF</span> Strumenti: <b>' + this.y.toFixed(1) + '</b>';
                                return txt;
                            }
                        }
                    }]
                });

            $('#my-chart-5_3-container')
                .highcharts(<any>{
                    chart: {
                        marginLeft: 100, // Keep all charts left aligned
                        spacingTop: 20,
                        spacingBottom: 20
                    },
                    title: {
                        text: 'Tempo impiegato / giorno',
                        align: 'left',
                        margin: 0,
                        x: 90
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        crosshair: true,
                        categories: chiavi,
                        labels: {
                            format: '{value}'
                        }
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        color: Highcharts.getOptions().colors[1],
                        fillOpacity: 0.3
                    },
                    tooltip: {
                        positioner: function () {
                            return {
                                x: this.chart.chartWidth - this.label.width, // right aligned
                                y: 10 // align to title
                            };
                        },
                        borderWidth: 0,
                        backgroundColor: 'none',
                        //pointFormat: '{point.y}',
                        headerFormat: '',
                        shadow: false,
                        style: {
                            fontSize: '18px'
                        }                        
                        //valueDecimals: dataset.valueDecimals
                    },
                    plotOptions: {
                        area: {
                            marker: {
                                enabled: false,
                            }
                        }
                    },
                    series: [
                        <any>{
                            data: this.risultatoRicerca.GrafProdOraria.map(r => r.MediaMinuti),
                            type: 'area',
                            color: Highcharts.getOptions().colors[4],
                            fillOpacity: 0.3,
                            tooltip: {
                                pointFormatter: function () {
                                    let txt = ' <span style="color:' + this.series.color + '">\u25CF</span> Minuti: <b>' + Math.round(this.y) + ' \u007E ' + Math.round(this.y / 60) + 'h</b>';
                                    return txt;
                                }
                            }
                        }]
                });            
            
            $('#my-chart-5_4-container')
                .highcharts(<any>{
                    chart: {
                        marginLeft: 100, // Keep all charts left aligned
                        spacingTop: 20,
                        spacingBottom: 20                        
                    },
                    title: {
                        text: 'Operatori',
                        align: 'left',
                        margin: 0,
                        x: 90
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        crosshair: true,
                        categories: chiavi,
                        labels: {
                            format: '{value}'
                        }
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        color: Highcharts.getOptions().colors[1],
                        fillOpacity: 0.3
                    },
                    tooltip: {
                        positioner: function () {
                            return {
                                x: this.chart.chartWidth - this.label.width, // right aligned
                                y: 10 // align to title
                            };
                        },
                        borderWidth: 0,
                        backgroundColor: 'none',
                        //pointFormat: '{point.y}',
                        headerFormat: '',
                        shadow: false,
                        style: {
                            fontSize: '18px'
                        },                        
                        //valueDecimals: dataset.valueDecimals
                    },
                    plotOptions: {
                        area: {
                            marker: {
                                enabled: false,
                            }
                        }
                    },
                    series: [                        
                        <any>{
                            data: this.risultatoRicerca.GrafProdOraria.map(r => r.MediaOperatori),
                            name: 'prova2',
                            type: 'area',
                            linkedTo: ':previous',
                            color: Highcharts.getOptions().colors[5],
                            fillOpacity: 0.3,
                            tooltip: {
                                pointFormatter: function () {
                                    let txt = ' <span style="color:' + this.series.color + '">\u25CF</span> Operatori: <b>' + this.y.toFixed(1) + '</b>';
                                    return txt;
                                }
                            }
                        }]
                }); 
            $('#my-chart-5_5-container')
                .highcharts(<any>{
                    chart: {
                        marginLeft: 100, // Keep all charts left aligned
                        spacingTop: 20,
                        spacingBottom: 20
                    },
                    title: {
                        text: 'Procedure / giorno',
                        align: 'left',
                        margin: 0,
                        x: 90
                    },
                    credits: {
                        enabled: false
                    },
                    legend: {
                        enabled: false
                    },
                    xAxis: {
                        crosshair: true,
                        categories: chiavi,
                        labels: {
                            format: '{value}'
                        }
                    },
                    yAxis: {
                        title: {
                            text: null
                        },
                        color: Highcharts.getOptions().colors[6],
                        fillOpacity: 0.3
                    },
                    tooltip: {
                        positioner: function () {
                            return {
                                x: this.chart.chartWidth - this.label.width, // right aligned
                                y: 10 // align to title
                            };
                        },
                        borderWidth: 0,
                        backgroundColor: 'none',
                        //pointFormat: '{point.y}',
                        headerFormat: '',
                        shadow: false,
                        style: {
                            fontSize: '18px'
                        },
                        //valueDecimals: dataset.valueDecimals
                    },
                    plotOptions: {
                        area: {
                            marker: {
                                enabled: false,
                            }
                        }
                    },
                    series: [
                        <any>{
                            data: this.risultatoRicerca.GrafProdOraria.map(r => r.MediaProcedure),
                            name: 'prova3',
                            type: 'area',
                            color: Highcharts.getOptions().colors[6],
                            fillOpacity: 0.3,
                            tooltip: {
                                pointFormatter: function () {
                                    let txt = ' <span style="color:' + this.series.color + '">\u25CF</span> Procedure: <b>' + this.y.toFixed(1) + '</b>';
                                    return txt;
                                }
                            }
                        }]
                });            
        }
        private updateFasiLottiLavorati6() {
            if (this.risultatoRicerca == undefined) return;

            let datiChart = [
                {
                    name: 'Container',
                    yAxis: 0,
                    data: this.risultatoRicerca.GrafProdOp.map(r => r.TotaleContainer)
                },
                {
                    name: 'Sfuso',
                    yAxis: 1,
                    data: this.risultatoRicerca.GrafProdOp.map(r => r.TotaleSfusi)
                },
                {
                    name: 'Strumenti',
                    yAxis: 2,
                    data: this.risultatoRicerca.GrafProdOp.map(r => r.TotaleStrumenti)
                },
                {
                    name: 'Tempo impiegato (hh:mm)',
                    yAxis: 3,
                    data: this.risultatoRicerca.GrafProdOp.map(r => r.TotaleMinuti),
                    tooltip: {
                        pointFormatter: function () {
                            //let tmp = this.y / 60000;
                            //let h = Math.floor(tmp / 60);
                            //let m = Math.floor(tmp) % 60;

                            let h = Math.floor(this.y / 60);
                            let m = this.y % 60;
                            return '<span style="color:' + this.series.color + '">\u25CF</span> ' + this.series.name + ': <b>' + h.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + m.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '</b><br/>';
                        }
                    }
                }
            ];
            
            let chartOption = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: null
                },
                xAxis: [{
                    categories: this.risultatoRicerca.GrafProdOp.map(r => r.LabelOperatore),
                    crosshair: true
                }],
                yAxis: [
                    {
                        gridLineWidth: 0,
                        title: {
                            text: 'Lotti lavorati',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        labels: {
                            format: '{value}',
                            style: {
                                color: Highcharts.getOptions().colors[0]
                            }
                        },
                        opposite: false
                    },
                    {
                        gridLineWidth: 0,
                        title: {
                            text: 'Sfuso',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        labels: {
                            format: '{value}',
                            style: {
                                color: Highcharts.getOptions().colors[1]
                            }
                        },
                        opposite: false
                    },
                    {
                        labels: {
                            format: '{value}',
                            style: {
                                color: Highcharts.getOptions().colors[2]
                            }
                        },
                        title: {
                            text: 'Strumenti',
                            style: {
                                color: Highcharts.getOptions().colors[2]
                            }
                        },
                        opposite: false
                    }
                    ,
                    { // Tertiary yAxis
                        //min:0,
                        //max: 15000,
                        //minTickInterval: 60,
                        //tickAmount: 3,
                        gridLineWidth: 0,
                        title: {
                            text: 'Tempo (hh:mm)',
                            style: {
                                color: Highcharts.getOptions().colors[3]
                            }
                        },
                        labels: {
                            style: {
                                color: Highcharts.getOptions().colors[3]
                            },
                            formatter: function () {
                                //let tmp = this.value / 60000;
                                //let h = Math.floor(tmp / 60);
                                //let m = Math.floor(tmp) % 60;
                                let h = Math.floor(this.value / 60);
                                let m = this.value % 60;
                                //if (m != 0) return null;
                                return h + ":" + m;
                            }

                        },
                        opposite: true
                    }],
                tooltip: {
                    //headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    //pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    //'<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                    //footerFormat: '</table>',
                    shared: true,
                    //useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: datiChart
            };

            $('#my-chart-6-container').highcharts(chartOption);
        }


        public dynamicSort(property: string, sortOrder: number/*, isDate: boolean = false*/) {
            sortOrder = sortOrder == 2 ? -1 : sortOrder;
            return function (a, b) {
                var result;
                //if (!isDate)
                    result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                //else result = (moment(a[property]).isBefore(b[property])) ? -1 : (moment(a[property]).isAfter(b[property])) ? 1 : 0;
                return result * sortOrder;
            }
        }
        private getCentrali(): void {
            this.centraliFactory.lista().then(
                result => {
                    this.centrali = result;
                }
            );
        }
        private getTipiSet(): void {
            this.setFactory.listaTipiSet().then(
                result => {
                    this.tipiSet = result;
                }
            );
        }

        private getFasiProduzione(): void {
            this.fasiFactory.SimpleList().then(
                result => {
                    this.fasiProduzione = result;
                }
            );
        }
        private getOperatori(): void {
            this.utenteFactory.OperatoriCentrale().then(
                result => {
                    this.operatori = result;
                }
            );
        }

        private getModelliSet(): void {
            this.modelliSetFactory.SimpleList().then(
                result => {
                    this.modelliSet = result;
                }
            );
        }

        private getComplessita(): void {
            this.setFactory.listaComplessita().then(
                risultato => {
                    this.listaComplessita = risultato;
                }
            );
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.filtro.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppi = undefined;
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSottogruppi(newVal);
            });
            this.$scope.$watch("vm.filtro.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSedi(newVal);
            });
            this.$scope.$watch("vm.filtro.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCosto = undefined;
                else this.getCentriCosto(newVal);
            });
        }
        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

        private getGruppi(): void {
            this.gruppiFactory.lista().then(
                result => {
                    this.gruppi = result;
                }
            );
        }

        private getSottogruppi(idGruppo: number): void {
            this.sottoGruppiFactory.listaGruppo(idGruppo).then(
                result => {
                    this.sottoGruppi = result;
                }
            );
        }

        private getSedi(idSottogruppo: number): void {
            this.sediFactory.listaSottoGruppo(idSottogruppo).then(
                result => {
                    this.sedi = result;
                }
            );
        }

        private getCentriCosto(idSede: number): void {
            this.centriCostoFactory.listaSede(idSede).then(
                result => {
                    this.centriCosto = result;
                }
            );
        }        
    }    
}
app.controller("produttivitaController", Trace.Analisi.produttivitaController);