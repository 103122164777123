module Trace.Produzione {
    export class MaterialeVm implements Materiale {
        public IdTipo: number;
        public Tipo: string;
        public Descrizione: string;
        public Qta: number;
        public QtaAttuale: number;
        public Uid: string;
        public Id: number;
        public IdDeposito: number;
        public Deposito: string;
        public IdSet: number;
        public Set: string;
        public Codice: string;
        public FlgQta: boolean;
        public ScortaMinima: number;
        public ScortaMassima: number;
        public QtaRiordino: number;
        public QtaOrdinato: number;
        public Giacenza: number;
        public MancanteMassimo: number;
        public MancanteRiordino: number;
        public FlgSottoScorta: boolean;
        public FlgSottoRiordino: boolean;
        public QtaValidata: number;
        public IdDocumento: number;
        public IdDocumentoDetail: number;
        public Depositi: Trace.Helpers.SimpleEntity[];
        public Componenti: Trace.Produzione.MaterialeVm[];
        public FlgInDocumento: boolean;
        public FlgReale: boolean;
        public Lotto: string;
        public DataScadenzaLotto: Date;
        public IdTipoProdotto: number;
        public IdOrdine: number;
        public IdOrdineDetail: number;
        public Matricola: string;
        public PrezzoUnitario: number;
        public PrezzoTotale: number;
        //public LottoMateriale: string;
    }
}