module Trace.Cespiti { 
    export class RicercaCespite {
        public Id: string;
        public Codice: string;
        public Descrizione: string;
    }

    export class cespitiController {
        public lista: Cespite[];
        public recordCorrente: Cespite;
        public testoAzione: string;
        public ricerca: RicercaCespite;
        public ricercaVm: any;

        public currentState: string;
        public titoloManipola: string;

        public showValidationMessages: boolean;
        public isSaving: boolean;
        public formCespiti: any;

        public gruppi: Trace.Gruppo.Gruppo[];
        public sottogruppi: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];

        public fornitori: Trace.Cespiti.FornitoriCespiti[];
        public produttori: Trace.Cespiti.ProduttoriCespiti[];
        public categorie: Trace.Cespiti.CategorieCespiti[];
        public ubicazioni: Trace.Cespiti.UbicazioniCespiti[];

        public currentPage: number;

        static $inject = ['$scope', 'cespitiFactory','gruppiFactory', 'sottoGruppiFactory',
            'sediFactory', 'centriCostoFactory', 'localeFactory', 'msgFactory', 'dtFactory'];
        constructor(private $scope: ng.IScope,
            private cespitiFactory: Trace.Cespiti.cespitiFactory,
            private gruppiFactory: Trace.Gruppo.gruppiFactory,
            private sottoGruppiFactory: Trace.SottoGruppo.sottoGruppiFactory,
            private sediFactory: Trace.Sede.sediFactory,
            private centriCostoFactory: Trace.CentroCosto.centroCostoFactory,
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory) {

            this.gruppiFactory.lista().then((risultato: Trace.Gruppo.Gruppo[]) => {
                this.gruppi = risultato;
            });
            this.cespitiFactory.listaCategorie().then((risultato: Trace.Cespiti.CategorieCespiti[]) => {
                this.categorie = risultato;
            });
            this.cespitiFactory.listaUbicazioni().then((risultato: Trace.Cespiti.UbicazioniCespiti[]) => {
                this.ubicazioni = risultato;
            });
            this.cespitiFactory.listaFornitori().then((risultato: Trace.Cespiti.FornitoriCespiti[]) => {
                this.fornitori = risultato;
            });
            this.cespitiFactory.listaProduttori().then((risultato: Trace.Cespiti.ProduttoriCespiti[]) => {
                this.produttori = risultato;
            });
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 500);   
            this.setUpWatchers();
        }

        public getExportUrl(tipo: string = 'Cespiti'): string {
            var result: string;
            switch (tipo) {
                case 'Cespiti':
                    result = appRoot + "Reports/ExportCespiti?strFiltro=" + JSON.stringify(this.ricercaVm);
                    break;

                default:
                    //result = appRoot + "Reports/ExportAnalisi" + tipo + "?strFiltro=" + JSON.stringify(this.filtro);
                    break;
            }
            return result;
        }

        public formatDate(data: Date, format: string = "DD/MM/YYYY"): string {
            if (data == null) data = new Date();
            var result = moment(data).format(format);
            return result;
        }
        public dateChanged(property: string, item: any): void {
            if (item[property + 'Str'] == undefined) return;

            var data = moment(item[property + 'Str'], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }
        private setUpWatchers(): void {
            this.$scope.$watch("vm.ricerca.DataFatturaDalStr", (newVal: string, oldVal: string) => {
                this.dateChanged('DataFatturaDal', this.ricerca);
            });
            this.$scope.$watch("vm.ricerca.DataFatturaAlStr", (newVal: string, oldVal: string) => {
                this.dateChanged('DataFatturaAl', this.ricerca);
            });
            this.$scope.$watch("vm.recordCorrente.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoChanged(newVal);
            });            
            this.$scope.$watch("vm.recordCorrente.IdSottogruppo", (newVal: number, oldVal: number) => {
                this.sottoGruppoChanged(newVal);
            });            
            this.$scope.$watch("vm.recordCorrente.IdSede", (newVal: number, oldVal: number) => {
                this.sedeChanged(newVal);
            });   
            this.$scope.$watch("vm.recordCorrente.StrDataFattura", (newVal: string, oldVal: string) => {
                if (this.recordCorrente == undefined) return;
                var t = moment(newVal, "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
                this.recordCorrente.DataFattura = newVal != undefined && newVal != "" && t.toString() != "Invalid date" ? t : undefined;
            });
            this.$scope.$watch("vm.recordCorrente.StrDataOrdine", (newVal: string, oldVal: string) => {
                if (this.recordCorrente == undefined) return;
                var t = moment(newVal, "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
                this.recordCorrente.DataOrdine = newVal != undefined && newVal != "" && t.toString() != "Invalid date" ? t : undefined;
            });   
            this.$scope.$watch("vm.recordCorrente.StrDataInizioAmmortamento", (newVal: string, oldVal: string) => {
                if (this.recordCorrente == undefined) return;
                var t = moment(newVal, "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
                this.recordCorrente.DataInizioAmmortamento = newVal != undefined && newVal != "" && t.toString() != "Invalid date" ? t : undefined;
            });  
            this.$scope.$watch("vm.recordCorrente.StrDataUltimoAmmortamento", (newVal: string, oldVal: string) => {
                if (this.recordCorrente == undefined) return;
                var t = moment(newVal, "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
                this.recordCorrente.DataUltimoAmmortamento = newVal != undefined && newVal != "" && t.toString() != "Invalid date" ? t : undefined;
            });     
            this.$scope.$watch("vm.recordCorrente.StrDataUltimoAmmortamentoIT", (newVal: string, oldVal: string) => {
                if (this.recordCorrente == undefined) return;
                var t = moment(newVal, "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
                this.recordCorrente.DataUltimoAmmortamentoIT = newVal != undefined && newVal != "" && t.toString() != "Invalid date" ? t : undefined;
            });  
        }

        public gruppoChanged(idGruppo: number): void {
            this.sottogruppi = [];
            this.sedi = [];
            this.centriCosto = [];

            if (idGruppo == undefined) return;
            this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                this.sottogruppi = risultato;
            });
        }

        public sottoGruppoChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];

            if (idSottogruppo == undefined) return;
            this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                this.sedi = risultato;
            });
        }

        public sedeChanged(idSede: number): void {
            this.centriCosto = [];

            if (idSede == undefined) return;
            this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                this.centriCosto = risultato;
            });
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formCespiti.$valid) {
                this.showValidationMessages = true;
                this.formCespiti.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.isSaving = true;
                    if (this.recordCorrente.Id == undefined)
                        this.cespitiFactory.inserisci(this.recordCorrente).then(result => {
                            this.formCespiti.$setPristine();
                            this.isSaving = false;
                            this.recordCorrente.Id = result.Id;
                            this.recordCorrente.Codice = result.Codice;
                            this.recordCorrente.ValoreResiduoAmmortamento = this.recordCorrente.ValoreTotale;
                            this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                            //this.annulla();
                        },
                        error => {
                            this.isSaving = false;
                            this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_errore", [error.toString()]));
                        });
                    else
                        this.cespitiFactory.modifica(this.recordCorrente).then(result => {
                            this.formCespiti.$setPristine();
                            this.isSaving = false;
                            this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                            //this.annulla();
                        }, error => {
                            this.isSaving = false;
                            this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_errore", [error.toString()]));
                        });
                }
            });
        }

        public annulla(): void {
            this.recordCorrente = <Trace.Cespiti.Cespite>{};
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.ricerca = new RicercaCespite();

            this.cespitiFactory.lista().then((risultato: Cespite[]) => {
                this.lista = risultato;
                this.changeState('list');
            },
            error => {
                this.changeState('list');
            });
        }

        public cancella(idCespite: number): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.cespitiFactory.cancella(idCespite).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        public modifica(idCespite: number): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.cespitiFactory.leggi(idCespite).then((cespite: Cespite) => {
                this.recordCorrente = cespite;
                for (var field in this.recordCorrente) {
                    if (field.indexOf("Data") != -1) {
                        this.recordCorrente["Str" + field] = this.recordCorrente[field] != undefined ? moment(this.recordCorrente[field]).format("DD/MM/YYYY").toString() : undefined;
                    }
                }
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.changeState('manipola');
            });
        }

        public nuovo(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = <Trace.Cespiti.Cespite>{};
            this.changeState("manipola");
        }

        public quantitaPrezziChanged(field: string): void {
            if (this.recordCorrente[field] == undefined || isNaN(this.recordCorrente[field])) return;
            switch (field) {
                case "ValoreCespite":
                    if (this.recordCorrente.ValoreCespite < 0.01) return;
                    //this.recordCorrente.ValoreCespite = parseFloat(this.recordCorrente.ValoreCespite.toFixed(2));
                    if (this.recordCorrente.Qta != undefined && this.recordCorrente.Qta != NaN && this.recordCorrente.Qta > 0 && Math.round(this.recordCorrente.Qta) == this.recordCorrente.Qta) {
                        this.recordCorrente.ValoreTotale = parseFloat((this.recordCorrente.ValoreCespite * this.recordCorrente.Qta).toFixed(2));
                    }
                    else if (this.recordCorrente.ValoreTotale != undefined && this.recordCorrente.ValoreTotale != NaN && this.recordCorrente.ValoreTotale > 0.01) {
                        var tmpQta = this.recordCorrente.ValoreTotale / this.recordCorrente.ValoreCespite;
                        this.recordCorrente.Qta = Math.round(tmpQta);
                        if (this.recordCorrente.Qta != tmpQta) this.recordCorrente.ValoreTotale = parseFloat((this.recordCorrente.ValoreCespite * this.recordCorrente.Qta).toFixed(2));
                    }
                    break;
                case "Qta":
                    if (this.recordCorrente.Qta < 1 || Math.round(this.recordCorrente.Qta) != this.recordCorrente.Qta) return;
                    //this.recordCorrente.Qta = Math.round(this.recordCorrente.Qta);
                    if (this.recordCorrente.ValoreCespite != undefined && this.recordCorrente.ValoreCespite != NaN && this.recordCorrente.ValoreCespite > 0.01) {
                        this.recordCorrente.ValoreTotale = parseFloat((this.recordCorrente.ValoreCespite * this.recordCorrente.Qta).toFixed(2));
                    }
                    else if (this.recordCorrente.ValoreTotale != undefined && this.recordCorrente.ValoreTotale != NaN && this.recordCorrente.ValoreTotale > 0.01) {
                        this.recordCorrente.ValoreCespite = parseFloat((this.recordCorrente.ValoreTotale / this.recordCorrente.Qta).toFixed(2));
                    }
                    break;
                case "ValoreTotale":
                    if (this.recordCorrente.ValoreTotale < 0.01) return;
                    //this.recordCorrente.ValoreTotale = parseFloat(this.recordCorrente.ValoreTotale.toFixed(2));
                    if (this.recordCorrente.Qta != undefined && this.recordCorrente.Qta != NaN && this.recordCorrente.Qta > 0 && Math.round(this.recordCorrente.Qta) == this.recordCorrente.Qta) {
                        this.recordCorrente.ValoreCespite = parseFloat((this.recordCorrente.ValoreTotale / this.recordCorrente.Qta).toFixed(2));
                    }
                    else if (this.recordCorrente.ValoreCespite != undefined && this.recordCorrente.ValoreCespite != NaN && this.recordCorrente.ValoreCespite > 0.01) {
                        var tmp = this.recordCorrente.ValoreTotale / this.recordCorrente.ValoreCespite;
                        this.recordCorrente.Qta = Math.round(tmp) == 0 ? 1 : Math.round(tmp);
                        if (this.recordCorrente.Qta != tmp) this.recordCorrente.ValoreCespite = parseFloat((this.recordCorrente.ValoreTotale / this.recordCorrente.Qta).toFixed(2));
                    }
            }
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.ricercaVm = ricerca;
            this.cespitiFactory.ricerca(ricerca, page).then((risultato: CespitiPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.lista = risultato.List;
            });
        }

        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = <Trace.Cespiti.Cespite>{};
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formCespiti.$setPristine();
                    setTimeout(() => $(".datetime").datepicker(),500);
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaCespite();
        }
    }
}
app.controller("cespitiController", Trace.Cespiti.cespitiController);