app.factory("flussoFactory", ($http, $location, $q) => new Trace.Flusso.flussoFactory($http, $location, $q));

module Trace.Flusso {
    export class Flusso {
        public Id : number;
        public Codice : string;
        public DataCreazione : Date;
        public DataObsoleto : Date;
        public DataUltimaModifica : Date;
        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public Note_en_US: string;
        public Note_it_IT: string;
        public Note_sq_AL: string;
        public IdUtenteCreazione : number;
        public IdUtenteObsoleto : number;
        public IdUtenteUltimaModifica : number;
        public IdTipoSet: number;
        public IdCentrale: number;
    }

    export class FlussoDetail {
        public Id: number;
        public Note_it_IT: string;
        public Note_en_US: string;
        public Note_sq_AL: string;
        public IdFlussoProduzione: number;
        public IdFaseProduzione: number;
        public IdTipoMacchina: number;
        public Ordinamento: number;
        public DataCreazione: Date;
    }

    export class flussoFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Flusso/";
        }


        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {

        }

        public lista(): ng.IPromise<flussoVm[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: flussoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public listaCompleta(): ng.IPromise<flussoVm[]> {
            var workUrl = this.GetBaseUrl() + "ListaCompleta";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: flussoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(flusso: flussoVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, flusso).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(flusso: flussoVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + flusso.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, flusso).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }


        public cancella(idFlusso: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idFlusso;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public patch(idFlusso: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idFlusso;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<flussoVm[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: flussoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idFlusso: number): ng.IPromise<flussoVm> {
            var workUrl = this.GetBaseUrl() + idFlusso;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: flussoVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public dettagli(id: number): ng.IPromise<flussoDetailVm[]> {
            var workUrl = this.GetBaseUrl() + "Dettagli/" + id;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: flussoDetailVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public aggiungiDettaglio(idTnt: number, dettaglio: FlussoDetail): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AggiungiDettagli/" + idTnt;

            var deferred = this.$q.defer();
            this.$http.post(workUrl, dettaglio).success((data: flussoDetailVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public cancellaDettaglio(idTnt: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CancellaDettaglio/" + idTnt;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: flussoDetailVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

    }
}