app.factory("centraliFactory", ($http, $location, $q) => new Trace.Centrale.centraliFactory($http, $location, $q));

module Trace.Centrale {
    export class Centrale {
        public Id : number;
        public Codice : string;
        public DataCreazione: Date;

        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public IdImmagine : number;
        public IdUtenteCreazione : number;
        public IdUtenteObsoleto : number;
        public IdUtenteUltimaModifica: number;

        public Macchine: Produzione.Macchina[];
    }

    export class centraliFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Centrale/";
        }


        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {

        }

        public listaAree(idCentrale: number): ng.IPromise<Aree[]> {
            var workUrl = this.GetBaseUrl() + "GetAreeCentrale/" + idCentrale;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Aree[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public lista(): ng.IPromise<Centrale[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Centrale[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaAsDropdown(): ng.IPromise<Trace.Helpers.SimpleDropdown[]> {
            var workUrl = this.GetBaseUrl()+ 'ListAsDropDown';

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Centrale[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaMappabili(): ng.IPromise<Centrale.CentraleMappabileVm[]> {
            var workUrl = this.GetBaseUrl() + "GetCentraliMappabili";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Centrale.CentraleMappabileVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaUtente(idUtente: number): ng.IPromise<Centrale[]> {
            var workUrl = this.GetBaseUrl() + "Utente/" + idUtente.toString();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Centrale[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaCompleta(): ng.IPromise<Centrale[]> {
            var workUrl = this.GetBaseUrl() + "ListaCompleta";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Centrale[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(centrale: Centrale): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, centrale).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(centrale: Centrale): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + centrale.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, centrale).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }


        public cancella(idCentrale: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idCentrale;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public patch(idCentrale: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idCentrale;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<Centrale[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Centrale[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idCentrale: number): ng.IPromise<Centrale> {
            var workUrl = this.GetBaseUrl() + idCentrale;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Centrale, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public asSe(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + 'asSe/';

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public TipiMacchine(): ng.IPromise<ViewModels.TipoMacchina[]> {
            var workUrl = this.GetBaseUrl() + 'TipiMacchine';

            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        }

        public ListaMacchine(idTipoMacchina: number = undefined): ng.IPromise<Produzione.Macchina[]> {
            var workUrl = this.GetBaseUrl() + "ListaMacchine";
            var deferred = this.$q.defer();
            if (idTipoMacchina != undefined) {
                this.$http.post(workUrl, idTipoMacchina).then(
                    result => {
                        deferred.resolve(result.data);
                    },
                    reason => {
                        deferred.reject(reason);
                    }
                );
            }
            else {
                this.$http.get(workUrl).then(
                    result => {
                        deferred.resolve(result.data);
                    },
                    reason => {
                        deferred.reject(reason);
                    }
                );
            }
            return deferred.promise;
        }
    }
}