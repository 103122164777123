module Trace.Produzione {
    export class AssociazioneLottoSterilizzazioneSetVm implements AssociazioneLottoSterilizzazioneSet {
        public IdLotto: number;
        public IdCiclo: number;
        public IdLottoSterilizzazione: number;
        public Codice: string;
        public Set: string;
        public LottoSterilizzazione: string;
        public Autoclave: string;
        public Ciclo: number;
        public DataCiclo: Date;
        public FlgCompleto: boolean;
    }
}