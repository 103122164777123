module Trace.Accettazione {
    export class listaAccettazioniController extends epBaseController {
        private gruppiFiltro: Array<Trace.Gruppo.Gruppo>;
        private sottoGruppiFiltro: Array<Trace.SottoGruppo.SottoGruppo>;
        private sediFiltro: Array<Trace.Sede.Sede>;
        private centriCostoFiltro: Array<Trace.CentroCosto.CentroCosto>;
        private gruppi: Array<Trace.Gruppo.Gruppo>;
        private sottoGruppi: Array<Trace.SottoGruppo.SottoGruppo>;
        private sedi: Array<Trace.Sede.Sede>;
        private centriCosto: Array<Trace.CentroCosto.CentroCosto>;
        private mediciFiltro: string[];

        private operatori: Array<Trace.Helpers.SimpleEntity>;

        private currentPage: number;
        private formAccettazioni: any;
        private formRegistrazioneAccettazione: any;
        private filtro: Trace.Accettazione.FiltroAccettazioni;
        private selectedItem: Trace.Accettazione.AccettazioneVm;
        private listaAccettazioni: Array<Trace.Accettazione.Accettazione>;
        private listaComplessita: Array<Set.ComplessitaVm>;
        private tipiIntervento: Array<TipoIntervento.VwTipoInterventoVm>;

        private codiceDisabled: boolean;

        static $inject = ["$rootScope", "$scope", "dtFactory", "accettazioneFactory", "tipoInterventoFactory", "interventoFactory", "setFactory", "gruppiFactory",
            "sottoGruppiFactory", "sediFactory", "centriCostoFactory", "localeFactory", "msgFactory", "$uibModal", "Upload", "utenteFactory", "tracciabilitaFactory"];

        constructor(
            public $rootScope: ng.IRootScopeService,
            private $scope: ng.IScope,
            private dtFactory: Trace.datatables.dtFactory,
            private accettazioneFactory: accettazioneFactory,
            private tipoInterventoFactory: Trace.TipoIntervento.tipoInterventoFactory,
            private interventoFactory: Trace.Intervento.interventoFactory,
            public setFactory: Trace.Set.setFactory,
            private gruppiFactory: Trace.Gruppo.gruppiFactory,
            private sottoGruppiFactory: Trace.SottoGruppo.sottoGruppiFactory,
            private sediFactory: Trace.Sede.sediFactory,
            private centriCostoFactory: Trace.CentroCosto.centroCostoFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public Upload: ng.angularFileUpload.IUploadService,
            public utenteFactory: Trace.Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(Upload, utenteFactory, setFactory, undefined, undefined, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);
            var vm = this;
            this.formAccettazioni = {};
            this.formRegistrazioneAccettazione = {};
            this.getComplessita();
            this.getGruppi();
            this.getMedici();
            this.getOperatori();
            this.getComplessita();
            this.getTipiIntervento();
            this.setUpWatchers();
            this.showValidationMessages = false;

            $(document).on("imageUpload", (e: JQueryEventObject) => {
                vm.selectedItem.Immagine = e.image;
                vm.selectedItem.Scansione = "";
                vm.formRegistrazioneAccettazione.$setDirty();
            });
        }

        private init(): void {
            this.changeState('list');

        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.filtro.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppiFiltro = undefined;
                    this.sediFiltro = undefined;
                    this.centriCostoFiltro = undefined;
                }
                else this.getSottogruppi(newVal, "filtro");
            });
            this.$scope.$watch("vm.filtro.IdSottogruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sediFiltro = undefined;
                    this.centriCostoFiltro = undefined;
                }
                else this.getSedi(newVal, "filtro");
            });
            this.$scope.$watch("vm.filtro.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCostoFiltro = undefined;
                else this.getCentriCosto(newVal, "filtro");
            });

            this.$scope.$watch("vm.selectedItem.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppi = undefined;
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSottogruppi(newVal, "");
            });
            this.$scope.$watch("vm.selectedItem.IdSottogruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSedi(newVal, "");
            });
            this.$scope.$watch("vm.selectedItem.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCosto = undefined;
                else this.getCentriCosto(newVal, "");
            });
        }

        public controllaFile($file: File[], tipo: string, isImage: boolean = true): void {
            this.checkFile($file, tipo);
            this.ricerca();
        }

        public saveFile($file: File, tipo: string) {
            this.onFileSelect($file, tipo);
            this.ricerca();
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            if (stato === "manipola") {
                this.formRegistrazioneAccettazione.$setPristine();
            }
            else {
            }

            setTimeout(function () {
                $(".datetime").datepicker();
            }, 1000);
        }

        public showScansione(item: Accettazione.Accettazione): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Analisi/modalScan.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.selectedItem = <Trace.Accettazione.AccettazioneVm>item;
            this.$modalInstance = this.$uibModal.open(options);
        }

        private leggi(item: Accettazione.Accettazione): void {
            var vm = this;
            this.selectedItem = <Trace.Accettazione.AccettazioneVm>item;

            this.codiceDisabled = false;

            if (this.selectedItem.Codice == "ACC-SFUSO" ||
                this.selectedItem.Codice == "ACC-SFUSO-BT" ||
                this.selectedItem.Codice == "ACC-TESSILE")
                this.codiceDisabled = true

            console.log("dettagli accettazione", this.selectedItem);
            this.changeState('manipola');
            setTimeout(function () {
                $('#dataIntervento').datepicker("setDate", moment(vm.selectedItem.Data).format("DD/MM/YYYY"));
            }, 700);

        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            this.currentPage = page;
            this.ricerca();
        }

        public ricerca(): void {
            this.isSaving = true;
            if (!this.formAccettazioni.$valid) {
                this.showValidationMessages = true;
                this.formAccettazioni.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }



            //if (this.filtro != undefined) {
            //    if (this.filtro.StrDataAccettazioneDal != undefined)
            //        this.filtro.DataAccettazioneDal = moment(this.filtro.StrDataAccettazioneDal, 'DD/MM/YYYY').toDate();
            //    if (this.filtro.StrDataAccettazioneAl != undefined)
            //        this.filtro.DataAccettazioneAl = moment(this.filtro.StrDataAccettazioneAl, 'DD/MM/YYYY').toDate();

            //    if (this.filtro.StrDataInterventoDal != undefined)
            //        this.filtro.DataInterventoDal = moment(this.filtro.StrDataInterventoDal, 'DD/MM/YYYY').toDate();
            //    if (this.filtro.StrDataInterventoAl != undefined)
            //        this.filtro.DataInterventoAl = moment(this.filtro.StrDataInterventoAl, 'DD/MM/YYYY').toDate();
            //}
            this.currentPage = this.currentPage == undefined ? 1 : this.currentPage;
            this.accettazioneFactory.RicercaAccettazioni(this.currentPage, this.filtro).then(
                result => {
                    this.dtFactory.tables[0].pagination = <ViewModels.PaginationVm>result;
                    this.listaAccettazioni = result.List;
                    console.log(this.listaAccettazioni);
                    this.isSaving = false;
                },
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_errore", [errore]));
                    this.isSaving = false;
                }
            );
        }

        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data == 'Invalid date' ? null : data;
        }

        private annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                vm.backToList();
            });
        }

        private registra(): void {
            this.isSaving = true;
            if (!this.formRegistrazioneAccettazione.$valid) {
                this.showValidationMessages = true;
                this.formRegistrazioneAccettazione.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            if (this.selectedItem.Data != undefined && typeof this.selectedItem.Data.getMonth !== 'function') {
                var format: string = <any>(this.selectedItem.Data).toString().indexOf("-") == 4 ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
                console.log("controllo data", this.selectedItem.Data, format);
                this.selectedItem.Data = moment(<any>(this.selectedItem.Data), format).toDate();
            }

            //console.log("registro le modifica all'accettazione", this.selectedItem);
            console.log("SelectedItem", this.selectedItem);
            console.log("this.FormAccettazione", this.formAccettazioni);
            console.log("this.FormReguistrazioneAccettazione", this.formRegistrazioneAccettazione);

            this.accettazioneFactory.EditAccettazione(this.selectedItem).then(
                result => {
                    this.msgFactory.alert(this.localeFactory.getFormatted("modifica_registrata", [this.selectedItem.Codice]));
                    this.leggi(result);
                    this.isSaving = false;
                    this.ricerca();
                },
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_errore", [errore]));
                    this.isSaving = false;
                }
            );
        }

        private backToList(): void {
            this.changeState('list');
        }

          //////////////////////////////////////
         //////////////// GET /////////////////
        //////////////////////////////////////

        private getTipiIntervento(): void {
            this.tipoInterventoFactory.lista().then(
                result => {
                    this.tipiIntervento = result;
                },
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_errore", [errore]));
                    this.isSaving = false;
                }
            );
        }

        private getGruppi(tipo: string = undefined): void {
            this.gruppiFactory.lista().then(
                result => {
                    this.gruppi = result;
                    this.gruppiFiltro = result;
                }
            );
        }

        private getOperatori(): void {
            this.utenteFactory.OperatoriCentrale().then(
                result => {
                    this.operatori = result;
                }
            );
        }

        private getMedici(): void {
            this.interventoFactory.MediciPerNome().then(
                result => {
                    this.mediciFiltro = result;
                }
            );
        }

        private getSottogruppi(idGruppo: number, tipo: string = undefined): void {
            this.sottoGruppiFactory.listaGruppo(idGruppo).then(
                result => {
                    if (tipo == "")
                        this.sottoGruppi = result;
                    else
                        this.sottoGruppiFiltro = result;
                }
            );
        }

        private getSedi(idSottogruppo: number, tipo: string = undefined): void {
            this.sediFactory.listaSottoGruppo(idSottogruppo).then(
                result => {
                    if (tipo == "")
                        this.sedi = result;
                    else
                        this.sediFiltro = result;
                }
            );
        }

        private getCentriCosto(idSede: number, tipo: string = undefined): void {
            this.centriCostoFactory.listaSede(idSede).then(
                result => {
                    if (tipo == "")
                        this.centriCosto = result;
                    else
                        this.centriCostoFiltro = result;
                }
            );
        }

        private getComplessita(): void {
            this.setFactory.listaComplessita().then(risultato => {
                this.listaComplessita = risultato;
            });
        }


    }
}
app.controller("listaAccettazioniController", Trace.Accettazione.listaAccettazioniController);