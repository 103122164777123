module Trace.MappaturaCentrale {
	export class MappaturaCentraleVm {
		Id: number;
		Codice: string;
        Descrizione_it_IT: string;
        Descrizione_en_US: string;
        Descrizione_sq_AL: string;
        IdCentrale: number;
        CentraleStr: string;
        Note: string;
        Dettagli: MappaturaCentraleDetailsVm[];
	}
}
