module Trace.Manutenzioni {
    export class RicercaManutenzioniVm {
        public Id: number;
        public DescrizioneAl: string;
        public DescrizioneEn: string;
        public DescrizioneIt: string;
        public Codice: string;
        public Uid: string;
        public CodiceStato: string;
        public Matricola: string;
        public IdStato: number;
        public DataFermo: Date;
    }
}