
module Trace.Fornitori { 
    export class RicercaFornitore {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string; 
        public RagioneSociale: string;
    }

    export class fornitoriController {
        public lista: FornitoreVm[];
        public recordCorrente: FornitoreVm;
        public testoAzione: string;
        public ricerca: RicercaFornitore;

        public currentState: string;
        public titoloManipola: string;

        public currentPage: number;

        public showValidationMessages: boolean;
        public formFornitori: any;

        public marcheDropDown: Trace.Helpers.SimpleDropdown[];
        public dropDownOption;
        //public dropDownPristine: boolean;

        static $inject = ['$scope', 'fornitoriFactory', 'localeFactory', 'msgFactory', 'dtFactory'];
        constructor(private $scope: ng.IScope, private fornitoriFactory: Trace.Fornitori.fornitoriFactory,
            private localeFactory: Localization.localeFactory, private msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory) {  
            
            //this.annulla();            
        }

        private init() {
            this.changeState('list');            
        }

        public registra(): void {            
            var vm = this;            
            this.showValidationMessages = false;
            if (!this.formFornitori.$valid) {
                this.showValidationMessages = true;
                this.formFornitori.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.recordCorrente.Produttori = this.recordCorrente.Produttori.filter(h => h.Selezionato == true);                
                    if (this.recordCorrente.Id == undefined)
                        this.fornitoriFactory.inserisci(this.recordCorrente).then((result => {
                            this.annulla();
                        }));
                    else
                        this.fornitoriFactory.modifica(this.recordCorrente).then((result => {
                            this.annulla();
                        }));
                }
            });
        }

        public annulla(): void {
            this.recordCorrente = <Trace.Fornitori.FornitoreVm>{};
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.ricerca = new RicercaFornitore();

            this.fornitoriFactory.lista().then((risultato: FornitoreVm[]) => {
                this.lista = risultato;
                this.changeState('list');
            });
        }

        public cancella(idFornitore: number): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.fornitoriFactory.cancella(idFornitore).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        

        public modifica(idFornitore: number): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.fornitoriFactory.leggi(idFornitore).then((fornitore: FornitoreVm) => {
                this.recordCorrente = fornitore;
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.changeState('manipola');                
                this.marcheDropDown = fornitore.Produttori;
                var vm = this;                
                setTimeout(
                    function () { 
                        $('#selezioneMarche').dropdown(vm.dropDownOption);
                    }, 250);
            });
        }

        public nuovo(): void {            
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.showValidationMessages = false;
            this.recordCorrente = <Trace.Fornitori.FornitoreVm>{};
            this.changeState("manipola");
            this.fornitoriFactory.marcheDropDown().then((risultato: Trace.Helpers.SimpleDropdown[]) => {                
                var vm = this;
                this.marcheDropDown = risultato;
                this.recordCorrente.Produttori = risultato;                 
                setTimeout(
                    function () {
                        $('#selezioneMarche').dropdown(vm.dropDownOption);
                    }, 250);
            });          
        }

        

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.fornitoriFactory.ricerca(ricerca, page).then((risultato: FornitoriPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.lista = risultato.List;
            });
        }


        //public aggiungi(): void {
        //    this.titoloManipola = this.localeFactory.get("titolo_nuovo");
        //    this.recordCorrente = <Trace.Fornitori.FornitoreVm>{};
        //    this.testoAzione = this.localeFactory.get('button_inserisci');
        //    this.changeState('manipola');
        //}

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.marcheDropDown = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    var vm = this;
                    this.dropDownOption = {
                        //data: json1.data,
                        //limitCount: 40,
                        multipleMode: 'label',
                        choice: function (a, b) {
                            console.log(arguments, this);
                            //vm.dropDownPristine = false;
                            var item = vm.recordCorrente.Produttori.filter(h => h.Id == b.id)[0];                            
                            item.Selezionato = b.selected == true ? true : false;
                            vm.formFornitori.$setDirty();
                            vm.$scope.$apply();
                            //vm.formFornitori.$commitViewValue();
                        },
                        limitCount: Infinity,
                        input: '<input type="text" id="cerca-marca" maxLength="20" style="font-size:12px" placeholder="' + vm.localeFactory.get("campo_ricerca") + '">',
                        searchable: true,
                        searchNoData: '<li style="color:#CCC">No Results</li>',

                    }; 
                                      
                    break;
            }
        }

        
        public getMarcheDropDown() {

        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaFornitore();
        }
    }
}
app.controller("fornitoriController", Trace.Fornitori.fornitoriController);