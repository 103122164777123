module Trace.GestioneManutenzioniTransitPoint {
    export class GestioneManutenzioniTransitPointController extends Movimenti.epMovimentiController {
        public ricercaTransitPoint: Trace.GestioneManutenzioni.RicercaGestioneManutenzioniTransitPointVm;
        public formDettagliManutenzione: any;
        public movimentiTP: Movimenti.MovimentoVm[];
        public movimentoDetails: Movimenti.MovimentoVm;
        public aziendeRiparatrici: Marche.Marca[];

        public movToSave: Movimenti.MovimentoVm;

        static $inject = ["$rootScope", "$scope", "gestioneManutenzioniTransitPointFactory", "dtFactory", "movimentiFactory", "produzioneFactory",
            "documentoFactory", "setFactory", "prodottiFactory", "depositoFactory", "utenteFactory", "localeFactory", "msgFactory", "$uibModal",
            "gruppiFactory", "sottoGruppiFactory", "sediFactory", "centriCostoFactory", "tracciabilitaFactory"];

        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private gestioneManutenzioniTransitPointFactory: gestioneManutenzioniTransitPointFactory,
            public dtFactory: Trace.datatables.dtFactory,
            public movimentiFactory: Movimenti.movimentiFactory,
            private produzioneFactory: Produzione.produzioneFactory,
            public documentoFactory: Documento.documentoFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public depositoFactory: Deposito.depositoFactory,
            public utenteFactory: Utente.utenteFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public gruppiFactory: Gruppo.gruppiFactory,
            public sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            public sediFactory: Sede.sediFactory,
            public centroCostoFactory: CentroCosto.centroCostoFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($scope, $rootScope, $uibModal, dtFactory, localeFactory, msgFactory, setFactory, prodottiFactory, undefined,
                depositoFactory, utenteFactory, movimentiFactory, undefined, documentoFactory,
                gruppiFactory, sottoGruppiFactory, sediFactory, centroCostoFactory, tracciabilitaFactory);

            this.formDettagliManutenzione = {};
            this.movToSave = {} as Movimenti.MovimentoVm;
        }

        public init(): void {
            this.changeState('list');
            this.movimentiTP = [];

            var ricercaTransitPoint: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            this.gestioneManutenzioniTransitPointFactory.ricercaTransitPoint(ricercaTransitPoint, 1).then(
                risultato => {
                    var r = risultato;
                    console.log(risultato);
                    //this.dtFactory.tables[0].pagination = <ViewModels.PaginationVm>risultato;
                    this.movimentiTP = r.List;
                }
            );

            this.getAziendeRiparatrici();
        }

        public aggiornaRicercaTransitPoint(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricercaTransitPoint: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricercaTransitPoint.colonne = filtro;
            ricercaTransitPoint.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricercaTransitPoint.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.gestioneManutenzioniTransitPointFactory.ricercaTransitPoint(ricercaTransitPoint, page).then(
                risultato => {
                    var r = risultato;
                    console.log(risultato);
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                    this.movimentiTP = r.List;
                }
            );
        }

        public resetForm(): void {
            this.ricercaTransitPoint = new Trace.GestioneManutenzioni.RicercaGestioneManutenzioniTransitPointVm();
        }

        public leggi(id: number): void {
            this.gestioneManutenzioniTransitPointFactory.leggi(id).then(
                result => {
                    this.movimentoDetails = result;
                    this.changeState('manipola');
                    console.log(result);
                    console.log(this.movimentoDetails.MovimentiDetails);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public annullaManutenzioniTranistPoint(): void {
            this.changeState('list');
        }

        public associaAziendaRiparatrice(item: Movimenti.VwRigaMovimento, aziendaId: number): void {
            item.IdAziendaRiparatrice = aziendaId;
        }

        public cambiaStato(item: Movimenti.VwRigaMovimento, nuovoStato: number): void {
            item.IdStatoMovimento = nuovoStato;
            if (nuovoStato !== 1) {
                item.IdAziendaRiparatrice = null;
            }
        }

        public getAziendeRiparatrici(): void {
            this.gestioneManutenzioniTransitPointFactory.getAziendeRiparatrici().then(
                result => {
                    this.aziendeRiparatrici = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public salvaManutenzioniTranistPoint(): void {
            this.isSaving = true;
            console.log(this.movimentoDetails);

            this.movimentoDetails.Details.forEach(details => {
                if (this.isSaving === false) return;

                if (details.IdStatoMovimento == null) {
                    this.isSaving = false;
                    this.msgFactory.alert("Seleziona uno stato per ogni sturmento");
                    return;
                }

                if (details.IdStatoMovimento == 1 && details.IdAziendaRiparatrice == null) {
                    this.isSaving = false;
                    this.msgFactory.alert("Seleziona un'azienda riparatrice per ogni strumento da mandare in riparazione.");
                    return;
                }
            });
            
            this.movToSave.IdTipo = this.movimentoDetails.IdTipo;
            this.movToSave.DescrTipo = this.movimentoDetails.DescrTipo;
            this.movToSave.Provenienza = this.movimentoDetails.Provenienza;
            this.movToSave.Destinazione = this.movimentoDetails.Destinazione;
            this.movToSave.DescrUtenteCreazione = this.movimentoDetails.DescrUtenteCreazione;
            this.movToSave.DescrTipoMateriali = this.movimentoDetails.DescrTipoMateriali;
            this.movToSave.Details = this.movimentoDetails.Details;
            this.movToSave.RichiestaDetails = this.movimentoDetails.RichiestaDetails;
            this.movToSave.IdVettore = this.movimentoDetails.IdVettore;
            this.movToSave.IdCarrello = this.movimentoDetails.IdCarrello;
            this.movToSave.NumDocumento = this.movimentoDetails.NumDocumento;
            this.movToSave.DataAccettazione = this.movimentoDetails.DataAccettazione;
            this.movToSave.IdUtenteAccettazione = this.movimentoDetails.IdUtenteAccettazione;
            this.movToSave.FlgAccettazione = this.movimentoDetails.FlgAccettazione;
            this.movToSave.UtenteAccettazione = this.movimentoDetails.UtenteAccettazione;
            this.movToSave.IdMovimento = this.movimentoDetails.IdMovimento;
            
            this.gestioneManutenzioniTransitPointFactory.salvaTransitPoint(this.movimentoDetails).then(
                result => {
                    this.msgFactory.alert("Strumenti inviati in riparazione con Successo", "Invio Riuscito");
                    this.isSaving = false;
                    this.changeState('list');
                },
                reason => {
                    this.isSaving = false;
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );


        }

        public selezionaFile() {
            const fileInput = document.getElementById('fileInput') as HTMLInputElement;
            fileInput.click();
        }

        public fileSelezionato(event: Event, item: Movimenti.VwRigaMovimento): void {
            const element = event.target as HTMLInputElement;
            let file = element.files ? element.files[0] : null;
            if (file) {
                item.DocumentoAllegato = file; 
                console.log('File selezionato:', item.DocumentoAllegato.name);
            }
        }
    }
}
app.controller("gestioneManutenzioniTransitPointController", Trace.GestioneManutenzioniTransitPoint.GestioneManutenzioniTransitPointController);
