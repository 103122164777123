module Trace.Autoclavi {
    export class autoclaviController extends epProduzioneController {
        public ora: string;
        private caricamentoLottiMultipli: Array<Produzione.LottoProduzioneVm>;
        static $inject = ["$rootScope", "$scope", "autoclaviFactory", "centraliFactory", "setFactory", "produzioneFactory", "localeFactory", "msgFactory",
            "$uibModal", "$element", "dragularService", "$interval", "utenteFactory", "tracciabilitaFactory"];
        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private autoclaviFactory: autoclaviFactory,
            private centraliFactory: Centrale.centraliFactory,
            public setFactory: Set.setFactory,
            public produzioneFactory: Produzione.produzioneFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            private $element: any,
            private dragularService: any,
            private $interval: any,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($rootScope, $scope, $uibModal, localeFactory, msgFactory, setFactory, undefined, undefined, produzioneFactory, 7, "autoclavi", undefined, null, utenteFactory, tracciabilitaFactory);
            this.myRoot = <epRootScope>$rootScope;
            var vm = this;
            //this.listaFase();
            $(document).on("barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkEventHandler(e);
            }).on("mouseDownProduzione", (e: JQueryEventObject) => {
                this.mouseDownLotto(e);
            }).on("eseguiCicloManuale", (e: JQueryEventObject) => {
                this.eseguiCicloManuale(e);
            }).on("cesta", (e: JQueryEventObject) => {
                this.cestaHandler(e.lettura);
            });
            this.setupDeD();
        }

        private cestaHandler(codice: string): void {
            var vm = this;
            var lotti: Array<Produzione.LottoProduzioneVm> = new Array<Produzione.LottoProduzioneVm>();
            console.log("Controllo i set da caricare", this.lista);
            $.each(this.lista, (idl, l) => {
                if (l.Cesta != undefined && l.Cesta.Codice.toLowerCase() === codice.toLowerCase())
                    lotti.push(l);
            });
            if (lotti.length <= 0) {
                $.each(this.macchine, (index, item) => {
                    if (item.Carico != undefined) {
                        $.each(item.Carico, (id, c) => {
                            if (c.LottoProduzione.Cesta != undefined && c.LottoProduzione.Cesta.Codice.toLowerCase() === codice) {
                                lotti.push(c.LottoProduzione);
                                if (vm.idMacchinaSelezionata == undefined) vm.idMacchinaSelezionata = index;
                            }
                        });
                    }
                });
                if (lotti.length <= 0) {
                    this.msgFactory.alert(this.localeFactory.get("cesta_non_associata"));
                    return;
                }
            }
            if (this.idMacchinaSelezionata == undefined) {
                this.msgFactory.error(this.localeFactory.get('selezionare_macchina'));
                return;
            }
            this.caricamentoLottiMultipli = lotti;
            this.caricaContenutoCesta(lotti);
        }

        private caricaContenutoCesta(lotti: Array<Produzione.LottoProduzioneVm>): void {
            console.log("Ci sono lotti da caricare con il sistema di caricamento multiplo", lotti);
            if (lotti == undefined || lotti.length <= 0) {
                this.caricamentoLottiMultipli = undefined;
                return;
            }
            var l = lotti.splice(0, 1)[0];
            console.log("Carico questo lotto", l);
            var result = this.findInList(l.Codice);
            if (result) {
                console.log("Non caricato", result);
                this.caricaAutoclavi(this.lottoSelezionato(this.lista));
            }
            // Il lotto esiste ed p già caricato in un'altra macchina
            var caricato = this.findInLottiCaricati(l.Codice);
            if (caricato != undefined) {
                console.log("Caricato", caricato);
                this.lottoCaricato(caricato);
            }
        }

        private eseguiCicloManuale(e: JQueryEventObject): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("esecuzione_ciclo_manuale_conferma", [this.descrFase, e.macchina.Nome, e.macchina.NumCiclo])).then(
                result => {
                    if (!result.result) return;
                    this.doEseguiCicloManuale(e.macchina);
                }
            );
        }

        private doEseguiCicloManuale(macchina: Produzione.Macchina): void {
            this.produzioneFactory.EseguiCicloManuale(macchina.Id, macchina.NumCiclo).then(
                result => {
                    if (!result.Result) {
                        this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [result.Message]));
                        console.log("errore", result);
                        return;
                    }
                    this.listaFase();
                    this.macchine[this.idMacchinaSelezionata].Carico = undefined;
                    this.macchine[this.idMacchinaSelezionata].FlgManuale = false;
                    this.idMacchinaSelezionata = undefined;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private registerEvents(el: any): void {
            var vm = this;
            el.on('dragularrelease', function (e) {
                if ((el[0] === e.target && // filter bubbled
                    vm.dragularService.shared.extra && // extra on dragleave contains element the drag is leaving to
                    vm.dragularService.shared.extra.parentElement !== e.target) // is that element child of this container?
                    || e.type === 'dragularrelease') {
                    var idMacchina = parseInt($(el).data("idmacchina"));
                    console.log("ID Macchina", idMacchina);
                    vm.isDragging = false;
                    // Cambio la macchina selezionata al dorp
                    if (!isNaN(idMacchina) && idMacchina !== vm.idMacchinaSelezionata)
                        vm.idMacchinaSelezionata = idMacchina;
                    if (vm.lottoDrag != undefined) {
                        triggerBarcode(vm.lottoDrag.Codice);
                        vm.lottoDrag = undefined;
                    }
                }
            });
        }

        private setupDeD(): void {
            this.dragularOptions = {
                classes: { mirror: 'custom-green-mirror' }
            };
        }

        public initModule(timer: boolean = false): void {
            var vm = this;
            console.log("timer?", timer);
            this.listaMacchine(TipoMacchina.AUTOCLAVE);
            if (timer === true) {
                var stop: any;
                if (angular.isDefined(stop)) return;

                stop = this.$interval(function () {
                    vm.listaMacchine(TipoMacchina.AUTOCLAVE);
                    vm.listaFase(timer);
                }, Globals.TimerRefreshAnalisi);
            }
            vm.listaFase(timer);
            vm.ora = vm.now();
        }

        public listaFase(analisi: boolean = false): void {
            var vm = this;
            this.idLottoSelezionato = undefined;
            this.autoclaviFactory.Lista().then(
                result => {
                    //$(".set-container .dragular-sandbox .list-row-container").remove();
                    this.lista = result;
                    setTimeout(function () {
                        //console.log("lung", $(".dragular-sandbox").length);
                        $.each($(".dragular-sandbox"), (index, el) => {
                            vm.registerEvents(angular.element(el));
                        });
                        $('#drag-element').unbind("mousedown touchstart");
                        $('#drag-element').bind("mousedown touchstart", function (e) {
                            var idLotto = parseInt($(e.currentTarget).data("idlotto"));
                            console.log("dajeeee", vm.lista);
                            $.each(vm.macchine, (idm, m) => {
                                if (m.Carico != undefined) {
                                    $.each(m.Carico, (idl, l) => {
                                        console.log("controllo lotti", l.LottoProduzione.Id, idLotto);
                                        if (l.LottoProduzione.Id === idLotto) {
                                            var eld = $(e.currentTarget).closest(".list-row-macchina").length > 0 ? "" : "daCaricare";
                                            triggerProduzioneEvent("mouseDownProduzione", l.LottoProduzione, eld);
                                        }
                                    });
                                }
                            });
                        });
                        if (!vm.primoCaricamento) return;
                        vm.primoCaricamento = false;
                        vm.cambiaAltezzaMacchine(analisi);
                    }, 1000);
                    if (this.caricamentoLottiMultipli != undefined) this.caricaContenutoCesta(this.caricamentoLottiMultipli);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public listaMacchine(idTipoMacchina: number = undefined): void {
            this.centraliFactory.ListaMacchine(idTipoMacchina).then(
                result => {
                    console.log(result);
                    this.macchine = result;
                    this.listaCarichi(TipoMacchina.AUTOCLAVE);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_lista_autoclavi_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public mouseDownLotto(e: JQueryEventObject): void {
            console.log("DRAG START", e.funzione);
            // Visualizzo l'area di drop solo se sto SCARICANDO
            if (e.funzione != 'daCaricare')
                this.isDragging = true;
            this.lottoDrag = e.lotto;
        };

        public barcodeOkEventHandler(e: JQueryEventObject): void {
            if (this.idMacchinaSelezionata == undefined) {
                this.msgFactory.error(this.localeFactory.get('selezionare_macchina'));
                return;
            }
            // Il lotto esiste ma non è caricato
            var result = this.findInList(e.lettura);
            if (result) {
                return this.caricaAutoclavi(this.lottoSelezionato(this.lista));
            }
            // Il lotto esiste ed p già caricato in un'altra macchina
            var caricato = this.findInLottiCaricati(this.ultimaLetturaBarcode.barcode);
            if (caricato != undefined) {
                this.lottoCaricato(caricato);
            }
        }

        private lottoCaricato(caricato): void {
            // CANCELLAZIONE - Lotto presente nella stessa macchina selezionata
            if (caricato[0] === this.idMacchinaSelezionata) {
                this.msgFactory.confirm(this.localeFactory.getFormatted("msg_cancellazione_conferma", [caricato[2]])).then(
                    result => {
                        if (!result.result) {
                            this.listaCarichi(TipoMacchina.AUTOCLAVE);
                            return;
                        }
                        this.rimuoviLottoDaAutoclavi(this.macchine[<number>caricato[0]].Carico[<number>caricato[1]].LottoProduzione)
                    }
                );
            }
            // SPOSTAMENTO - Lotto presente in un'altra macchina rispetto a quella selezionata
            else {
                this.msgFactory.confirm(this.localeFactory.getFormatted("msg_spostamento_conferma", [caricato[2]])).then(
                    result => {
                        if (!result.result) {
                            this.listaCarichi(TipoMacchina.AUTOCLAVE);
                            return;
                        }
                        this.caricaAutoclavi(this.macchine[<number>caricato[0]].Carico[<number>caricato[1]].LottoProduzione);
                    }
                );
            }
        }

        private rimuoviLottoDaAutoclavi(lotto: Produzione.LottoProduzioneVm): void {
            this.produzioneFactory.CambiaFaseLottoProduzione(lotto.Id, this.idFase).then(
                result => {
                    if (!result.Result) {
                        this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [result.Message]));
                        console.log("errore", result);
                        return;
                    }
                    $.each(this.macchine[this.idMacchinaSelezionata].Carico, (index, item) => {
                        if (item.Codice === lotto.Codice) {
                            this.macchine[this.idMacchinaSelezionata].Carico.splice(index, 1);
                            return;
                        }
                    });
                    this.listaFase();
                    this.listaCarichi(TipoMacchina.AUTOCLAVE);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private caricaAutoclavi(lotto: Produzione.LottoProduzioneVm): void {
            var macchina = this.macchinaSelezionata();
            if (macchina == null) {
                this.msgFactory.error(this.localeFactory.get("nessuna_macchina_selezionata"));
                return;
            }
            if (lotto.FlgFiltroSterilizzazione && (macchina.IdTipoSterilizzazione == null || macchina.IdTipoSterilizzazione.indexOf(lotto.IdTipoSterilizzazione) == -1)) {
                this.msgFactory.error(this.localeFactory.get("tipo_macchina_imcompatibile"));
                return;
            }
            else if (!lotto.FlgFiltroSterilizzazione && macchina.IdTipoSterilizzazione != null && macchina.IdTipoSterilizzazione.indexOf(lotto.IdTipoSterilizzazione) == -1){
                this.msgFactory.error(this.localeFactory.get("tipo_macchina_imcompatibile"));
                return;
            }
            var carico = new Produzione.CaricoMacchinaVm(TipoMacchina.AUTOCLAVE, macchina.Id, lotto.Id);
            this.produzioneFactory.CaricaMacchina(carico).then(
                result => {
                    if (!result.Result) {
                        this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [result.Message]));
                        console.log("errore", result);
                        return;
                    }
                    this.listaCarichi(TipoMacchina.AUTOCLAVE);
                    this.listaFase();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }
    }
}
app.controller("autoclaviController", Trace.Autoclavi.autoclaviController);