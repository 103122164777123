app.factory("centriCostoFactory", ($http, $location, $q) => new Trace.CentroCosto.centroCostoFactory($http, $location, $q));

module Trace.CentroCosto {
    export class CentroCosto {
        public Id : number;
        public Codice : string;
        public DataCreazione : Date;
        public DataObsoleto : Date;
        public DataUltimaModifica : Date;
        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public IdImmagine : number;
        public IdUtenteCreazione : number;
        public IdUtenteObsoleto : number;
        public IdUtenteUltimaModifica : number;
        public IdGruppo: number;
        public IdSottogruppo: number;
        public IdSede: number;
        public FlgEditabile: boolean = true;
    }

    export class centroCostoFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/CentroCosto/";
        }


        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {

        }

        public lista(): ng.IPromise<centroCostoVm[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: CentroCosto[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public listaCompleta(): ng.IPromise<centroCostoVm[]> {
            var workUrl = this.GetBaseUrl() + "ListaCompleta";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: centroCostoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public getByCodice(codice: string): ng.IPromise<CentroCosto.CentroCosto> {
            var workUrl = this.GetBaseUrl() + "GetByCodice/" + codice;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: CentroCosto.CentroCosto, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(centroCosto: CentroCosto): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, centroCosto).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(centroCosto: CentroCosto): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + centroCosto.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, centroCosto).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public cancella(idCentroCosto: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idCentroCosto;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public patch(idCentroCosto: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idCentroCosto;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public listaSede(idSede: number): ng.IPromise<CentroCosto[]> {
            var workUrl = this.GetBaseUrl() + "ListaSede/" + idSede;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: CentroCosto[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idCentroCosto: number): ng.IPromise<CentroCosto> {
            var workUrl = this.GetBaseUrl() + idCentroCosto;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<centroCostoVm[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: centroCostoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaSedeAsSe(idSede: number): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "ListaSedeAsSe/" + idSede;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

    }
}