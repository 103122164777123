module Trace.Analisi {
    export class FiltroInterventi implements FiltroInterventiVm {
        public StrDataInterventoDal: string;
        public DataInterventoDal: Date;
        public StrDataInterventoAl: string;
        public DataInterventoAl: Date;
        public TipiIntervento: number[];
        public ListaSet: number[];
        public ListaTnt: number[];
        public IdGruppo: number;
        public IdSottoGruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public IdComplessita: number;
    }
}
