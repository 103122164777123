namespace Trace.Set {
    export class SetVm {
        public Id: number;
        public Codice: string;
        public Descrizione_it_IT: string;
        public Descrizione_en_US: string;
        public Descrizione_sq_AL: string; 
        public IdGruppo: number;
        public IdSottogruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public Gruppo: string;
        public Sottogruppo: string;
        public Sede: string;
        public CentroCosto: string;
        public Tipo: string;
        public IdUnitaSterilizzazione: number;
        public UnitaSterilizzazione: string;
        public Immagini: any;
        public IdTipo: number;
        public IdTipoSterilizzazione: number;
        public IdComplessita: number;
        public Complessita: string;
        public Peso: number;
        public Note: string;
        public Precauzioni: string;
        public NumGriglie: number;
        public NumBuste: number;
        public Costo: number;
        public DataInizioValidita: Date;
        public StrDataInizioValidita: string;
        public NumRegistro: string; 
        public NumRepertorio: string; 
        public IdRevisione: number;
        public DataRevisione: Date;
        public StrDataRevisione: string;
        public Corpo: Array<any>;
        public Strumenti: Array<StrumentoVm>;
        public Altezza: number;
        public Larghezza: number;
        public Profondita: number;
        public NumContainerAssociati: number;
        public Associati: Array<SetVm>;
        public IdModelloNuovaRevisione: number;
        public DataFineValidita: Date;
        public NumCopia: number;
        public IdModelloSet: number;
        public ModelloSet: string;
        public NumContainer: number;
        public TotContainer: number;
        public TotCopie: number;
        public FlgAttivo: boolean;
        public IdRaggruppamento: number;
    }
}