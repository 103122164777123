module Trace.Prodotto {
    export class DistintaVm {
        public IdProdottoPadre: number;
        public IdProdotto: number;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public Qta: number;
    }
}