app.factory("tntFactory", ($http, $location, $q) => new Trace.Tnt.tntFactory($http, $location, $q));

module Trace.Tnt {
    export class TipoTnt {
        public Id: number;
        public Codice: string;
        public DataCreazione: Date;
        public Descrizione_en_US: string;
        public Descrizione_it_IT: string;
        public Descrizione_sq_AL: string;
    }

    export class Tnt {
        public Id : number;
        public Codice : string;
        public DataCreazione : Date;
        public DataObsoleto : Date;
        public DataUltimaModifica : Date;
        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public IdUtenteCreazione : number;
        public IdUtenteObsoleto : number;
        public IdUtenteUltimaModifica : number;
        public IdTipoTnt: number;
    }

    export class TntDetail {
        public Id: number;
        public Codice: string;
        public DataCreazione: Date;
        public DataObsoleto: Date;
        public Descrizione_en_US: string;
        public Descrizione_it_IT: string;
        public Descrizione_sq_AL: string;
        public IdUtenteCreazione: number;
        public IdUtenteObsoleto: number;
        public IdTipo: number;
        public Quantita: number;
    }

    export class tntFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Tnt/";
        }


        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {

        }

        public lista(): ng.IPromise<tntVm[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: tntVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public listaCompleta(): ng.IPromise<tntVm[]> {
            var workUrl = this.GetBaseUrl() + "ListaCompleta";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: tntVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(tnt: fullTntVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, tnt).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(tnt: fullTntVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + tnt.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, tnt).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }


        public cancella(idTnt: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idTnt;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public patch(idTnt: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idTnt;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public listaTipoTnt(idTipoTnt: number): ng.IPromise<Tnt[]> {
            var workUrl = this.GetBaseUrl() + "ListaTipoTnt/" + idTipoTnt;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Tnt[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricerca(filtro: ViewModels.RicercaVm): ng.IPromise<tntVm[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: tntVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idTnt: number): ng.IPromise<fullTntVm> {
            var workUrl = this.GetBaseUrl() + idTnt;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: fullTntVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public listaTipi(): ng.IPromise<TipoTnt[]> {
            var workUrl = this.GetBaseUrl() + "listaTipi";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: TipoTnt[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public dettagli(id: number): ng.IPromise<tntDetailVm[]> {
            var workUrl = this.GetBaseUrl() + "Dettagli/" + id;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: tntDetailVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public aggiungiDettaglio(idTnt: number, dettaglio: TntDetail): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AggiungiDettagli/" + idTnt;

            var deferred = this.$q.defer();
            this.$http.post(workUrl, dettaglio).success((data: tntDetailVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public cancellaDettaglio(idTnt: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CancellaDettaglio/" + idTnt;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: tntDetailVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaTipiAsSe(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "listaTipiAsSe";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public SimpleList(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "SimpleList";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ScadenzeTnt(filtro: Trace.Scadenze.FiltroScadenzeVm): ng.IPromise<Array<Trace.Tnt.VwScadenzeTnt>> {
            var workUrl = this.GetBaseUrl() + "RicercaScadenzeTnt";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public decodeTntLotto(id: number): ng.IPromise<ViewModels.EanCodeInfoVm> {
            var workUrl = this.GetBaseUrl() + "DecodeTntLotto/" + id;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ViewModels.EanCodeInfoVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaRaggruppamenti(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "listaRaggruppamenti";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}