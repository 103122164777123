
module Trace.Rot { 
    export class RicercaRot {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string; 
    }

    export class rotController extends epBaseController {
        public lista: Rot[];
        public recordCorrente: Rot;
        public testoAzione: string;
        public ricerca: RicercaRot;

        public currentState: string;
        public titoloManipola: string;

        public showValidationMessages: boolean;
        public formRot: any;
        public tipiRot: Trace.Helpers.SimpleEntity[];

        public riepilogo: RiepilogoRot[];
        public numEtichette: number;
        public idTipoEtichette: number;
        public etichettaDa: number;
        public etichettaA: number;

        public stampaNew: boolean;

        static $inject = ['$scope', 'rotFactory', 'localeFactory', 'msgFactory', 'dtFactory', 'utenteFactory', "$uibModal", "tracciabilitaFactory"];
        constructor(private $scope: ng.IScope,
            private rotFactory: rotFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory,
            public utenteFactory: Utente.utenteFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory) {
            super(undefined, utenteFactory, undefined, undefined, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            //var vm = this;
            //this.$scope.$watch("vm.stampaNew", (newVal: any, oldVal: any) => {
            //    if (typeof newVal != "boolean")
            //        vm.stampaNew = newVal === "true";
            //});
            this.getTipiRot();
        }

        public getTipiRot() {
            this.rotFactory.getSimpleTipiRot(true).then(
                result => {
                    this.tipiRot = result;
                },
                error => { }
            );
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formRot.$valid) {
                this.showValidationMessages = true;
                this.formRot.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    if (this.recordCorrente.Id == undefined)
                        this.rotFactory.inserisci(this.recordCorrente).then((result => {
                            this.annulla();
                        })).catch((reason: string) => {
                            this.msgFactory.error(reason);
                        });
                    else
                        this.rotFactory.modifica(this.recordCorrente).then((result => {
                            this.annulla();
                        })).catch((reason: string) => {
                            this.msgFactory.error(reason);
                        });
                }
            });
        }
        
        public stampaRot(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Rot/modalStampaRot.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };

            this.numEtichette = 1;
            this.stampaNew = true;
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => {
                vm.idTipoEtichette = undefined;
                vm.numEtichette = undefined;
                vm.etichettaDa = undefined;
                vm.etichettaA = undefined;
                vm.stampaNew = true;
            });
        }

        public doStampaRot(): void {
            if (this.stampaNew) {
                if (isNaN(this.numEtichette) || this.numEtichette > 500 || this.idTipoEtichette == undefined) {
                    this.msgFactory.error(this.localeFactory.get("errore_stampa_etichette"));
                    return;
                }
                window.open("/Reports/StampaEtichetteRot/?idTipoRot=" + this.idTipoEtichette + "&num=" + this.numEtichette.toString(), "_blank");
            }
            else {
                if (isNaN(this.etichettaDa) || isNaN(this.etichettaA)) {
                    this.msgFactory.error(this.localeFactory.get("errore_stampa_etichette"));
                    return;
                }
                window.open("/Reports/RistampaEtichetteRot/?from=" + this.etichettaDa.toString() + "&to=" + this.etichettaA.toString(), "_blank");
            }
        }

        public annulla(): void {
            this.recordCorrente = new Rot();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.ricerca = new RicercaRot();

            this.rotFactory.lista().then((risultato: Rot[]) => {
                this.lista = risultato;
                this.changeState('list');
            });
        }

        public cancella(Rot: Rot): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.rotFactory.cancella(Rot.Id).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        public modifica(Rot: Rot): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.rotFactory.leggi(Rot.Id).then((rot: Rot) => {
                this.recordCorrente = rot;
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.changeState('manipola');
            });
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: ViewModels.DirezioneSort, filtro: Array<ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<ViewModels.SortRigaVm>();
            ricerca.sort.push(new ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.rotFactory.ricerca(ricerca).then((risultato: Rot[]) => {
                this.lista = risultato;
            });
        }

        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = new Rot();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formRot.$setPristine();
                    break;

                case "riepilogo":
                    this.rotFactory.riepilogo().then(
                        result => {
                            this.riepilogo = result;
                        }
                    );
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }


        public resetForm(): void {
            this.ricerca = new RicercaRot();
        }


    }
}

app.controller("rotController", Trace.Rot.rotController);