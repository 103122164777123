app.factory("utenteFactory", ($http, $q) => new Trace.Utente.utenteFactory($http, $q));
namespace Trace.Utente {
    export class utenteFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Utente/";
        }

        static $inject = ["$http", "$q"];

        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public login(username: string, password: string, ipaddreess: string): ng.IPromise<any> {
            var workUrl = appRoot + "api/login";
            var deferred = this.$q.defer();
            var loginVm = new Trace.ViewModels.LoginVm();
            loginVm.Username = username;
            loginVm.Password = password;
            loginVm.IpAddress = ipaddreess;
            this.$http.post(workUrl, loginVm).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public apriSessione(idUtente: number, idCentrale: number, idDeposito: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + 'NuovaSessione';
            var deferred = this.$q.defer();
            var session = new ApplicationSessionVm();
            session.IdCentrale = idCentrale;
            session.IdUtente = idUtente;
            session.IdDeposito = idDeposito;
            this.$http.post(workUrl, session).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public avatar(username: string): ng.IPromise<ViewModels.ImmagineVm> {
            var workUrl = this.GetBaseUrl() + 'Avatar/' + username;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ViewModels.ImmagineVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public analisiDiscordanzeUtenti(): ng.IPromise<AnalisiDiscordanzeUtenti> {
            var workUrl = this.GetBaseUrl() + 'DiscordanzeUtenti';
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: AnalisiDiscordanzeUtenti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public analisiDiscordanzeUtentiFilePaghe(): ng.IPromise<AnalisiDiscordanzeUtentiFilePaghe> {
            var workUrl = this.GetBaseUrl() + 'DiscordanzeUtentiFilePaghe';
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: AnalisiDiscordanzeUtentiFilePaghe, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public listaRuoli(): ng.IPromise<RuoloVm[]> {
            var workUrl = this.GetBaseUrl() + 'Ruoli';
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: RuoloVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public listaTipiUtenti(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + 'TipiUtenti';
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public lingue(): ng.IPromise<ViewModels.LinguaVm[]> {
            var workUrl = this.GetBaseUrl() + 'Lingue';
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ViewModels.LinguaVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<UtenteVm[]> {
            var workUrl = this.GetBaseUrl() + "Ricerca";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: UtenteVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricercaUtentiTimeMoto(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<UtenteVm[]> {
            var workUrl = this.GetBaseUrl() + "RicercaUtentiTimeMoto";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: UtenteVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricercaUtentiFilePaghe(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<Helpers.SimpleCodeEntity[]> {
            var workUrl = this.GetBaseUrl() + "RicercaUtentiFilePaghe";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Helpers.SimpleCodeEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public checkLogin(username: string, password: string): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CheckLogin";
            var deferred = this.$q.defer();
            var login = new ViewModels.LoginVm();
            login.Username = username;
            login.Password = password;
            this.$http.post(workUrl, login).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public changePassword(id: number, vecchia: string, nuova: string): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "ChangePassword";
            var deferred = this.$q.defer();
            var change = new ChangePasswordVm();
            change.Id = id;
            change.Current = vecchia;
            change.New = nuova;
            this.$http.post(workUrl, change).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public recoverPassword(username: string): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + 'RecoverPassword/' + username;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ViewModels.ImmagineVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public inserisci(utente: UtenteVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, utente).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public modifica(utente: UtenteVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + utente.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, utente).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idUtente: number): ng.IPromise<UtenteVm> {
            var workUrl = this.GetBaseUrl() + idUtente;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: UtenteVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public getIdUtentiTimeMotoInUso(escluso: number = null): ng.IPromise<number[]> {
            var workUrl = this.GetBaseUrl() +'GetIdUtentiTimeMotoInUso';
            if (escluso != null) workUrl += '/'+escluso;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: number[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public getCodiciUtentiFilePagheInUso(escluso: string = null): ng.IPromise<string[]> {
            var workUrl = this.GetBaseUrl() + 'GetCodiciUtentiFilePagheInUso';
            if (escluso != null) workUrl += '/' + escluso;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: string[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public spAggiornaDb(): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + "AggiornaDb";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: any, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inUso(username: string): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "IsCurrent/" + username;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public cancella(idUtente: number): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + idUtente;
            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: any, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public currentUser(): ng.IPromise<UtenteVm> {
            var workUrl = this.GetBaseUrl() + "GetCurrentUser";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: UtenteVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ultimaSessione(): ng.IPromise<ApplicationSessionVm> {
            var workUrl = this.GetBaseUrl() + "UltimaSessione";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ApplicationSessionVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public sessioneCorrente(): ng.IPromise<ApplicationSessionVm> {
            var workUrl = this.GetBaseUrl() + "SessioneCorrente";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ApplicationSessionVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public UtentiAttivi(idCentrale: number = undefined): ng.IPromise<Utente.UtenteSimpleVm[]> {
            var workUrl = this.GetBaseUrl() + "UtentiAttivi/";
            if (idCentrale != undefined)
                workUrl += idCentrale.toString();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public moduleLogin(password: string): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "ModuleLogin/" + password;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public OperatoriCentrale(): ng.IPromise<Array<Trace.Helpers.SimpleEntity>> {
            var workUrl = this.GetBaseUrl() + "OperatoriCentrale";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}