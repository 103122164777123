
module Trace.Lavaggio {
    export class lavaggioController extends epProduzioneController {
        public tipoSet: Set.TipoSetVm;
        public ultrasuoni: Array<Produzione.TrattamentoVm>;
        public manuali: Array<Produzione.TrattamentoVm>;
        public griglia: Produzione.GrigliaVm;
        public aggiuntaGriglia: boolean;
        public tipoLista: string = 'lotti';
        static $inject = [
            "$rootScope", "$scope", "lavaggioFactory", "setFactory", "prodottiFactory",
            "produzioneFactory", "localeFactory", "msgFactory", "$uibModal", "$document", "utenteFactory", "tracciabilitaFactory"
        ];
        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private lavaggioFactory: lavaggioFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public produzioneFactory: Produzione.produzioneFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public $document: any,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($rootScope, $scope, $uibModal, localeFactory, msgFactory, setFactory, prodottiFactory, undefined, produzioneFactory, 2, "lavaggio", $document, null, utenteFactory, tracciabilitaFactory);
            this.myRoot = <epRootScope>$rootScope;
            var vm = this;
            setTimeout(function () {
                vm.setUpAzioni();
            }, 300);

            $(document).on("barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkEventHandler(e);
            });
            $(document).on("grigliaSfusa", (e: JQueryEventObject) => {
                this.grigliaSfusaHandler(e);
            });
            $(document).on("strumentoAction", (e: JQueryEventObject) => {
                var f = e.funzione;
                var vm = this;
                vm.formDettagliProduzione.$setDirty();
                eval("vm." + f + "(e.strumento)");
            });
            this.$scope.$watch('vm.currentState', (newVal: number, oldVal: number) => {
                if (this.currentState === "list")
                    this.listaFase();
                if (this.currentState === "manipola" || this.currentState === "manipolaBox") {
                    this.tipoSet = null;
                    if (this.currentState!= "manipolaBox") this.setFactory.getTipoSet(this.lotto.IdTipoSet).then((risultato) => {
                        this.tipoSet = risultato;
                    }, error => { });
                    this.strumentoSelezionato = undefined;
                    this.listaGriglie(this.currentState === "manipola");
                    this.getContaStrumenti();
                    this.titoloManipola = this.localeFactory.getFormatted("titolo_dettagli_produzione", [this.descrFase.capitalize(), this.descrizioneLotto()]);
                    this.formDettagliProduzione.$setDirty();
                }
            });
        }

        private lavaggioContainerToggle(): void {
            let vm = this;
            vm.lotto.FlgLavaggioContainer = !vm.lotto.FlgLavaggioContainer;
            vm.formDettagliProduzione.$setDirty();
            //console.log(vm.formDettagliProduzione)
        }

        private grigliaSfusaHandler(e: JQueryEventObject): void {
            if (this.aggiuntaGriglia !== true) return;
            this.griglia.Codice = e.lettura;
            this.$scope.$apply();
            this.cercaGriglia();
        }

        private setUpTrattamenti(): void {
            /** Trattamenti per il set o le griglie **/
            this.ultrasuoni = new Array<Produzione.TrattamentoVm>();
            this.manuali = new Array<Produzione.TrattamentoVm>();

            var uss = new Produzione.TrattamentoVm(0, TipoTrattamento.ULTRASUONI, this.localeFactory.get("btn_ultrasuoni_set"), "trattamentoUltrasuoni", "btn btn-facebook btn-block btn-touch");
            uss.spazioGriglia = "12";
            this.ultrasuoni.push(uss);
            var usg = this.trattamentiGriglia(TipoTrattamento.ULTRASUONI, 'btn_ultrasuoni_griglia', 'trattamentoUltrasuoni', 'btn btn-info btn-touch');

            var ms = new Produzione.TrattamentoVm(0, TipoTrattamento.LAVAGGIOMANUALE, this.localeFactory.get("btn_manuale_set"), "trattamentoLavaggioManuale", "btn btn-danger btn-block btn-touch");
            ms.spazioGriglia = "12";
            this.manuali.push(ms);
            var mg = this.trattamentiGriglia(TipoTrattamento.LAVAGGIOMANUALE, 'btn_manuale_griglia', 'trattamentoLavaggioManuale', 'btn btn-warning btn-touch');

            this.ultrasuoni = this.ultrasuoni.concat(usg);
            this.manuali = this.manuali.concat(mg);

            /** Trattamenti per il singolo strumento **/
            this.trattStrumenti = new Array<Produzione.TrattamentoVm>();
            this.trattStrumenti.push(new Produzione.TrattamentoVm(1, 1, this.localeFactory.get("btn_ultrasuoni"), "trattamentoUltrasuoniStrumento", "btn btn-info btn-block btn-touch"));
            this.trattStrumenti.push(new Produzione.TrattamentoVm(1, 2, this.localeFactory.get("btn_manuale"), "trattamentoLavaggioManualeStrumento", "btn btn-warning btn-block btn-touch"));

            this.trattStrumentiSfusiBT = new Array<Produzione.TrattamentoVm>();
            this.trattStrumentiSfusiBT.push(new Produzione.TrattamentoVm(1, 2, this.localeFactory.get("btn_manuale"), "", "btn btn-warning btn-block btn-touch",10));
        }

        public trattStrumentiSfusiBT: Array<Produzione.TrattamentoVm>;

        private setUpAzioni(): void {
            this.azioni.push(new Produzione.AzioneVm("Dettagli", this.localeFactory.get("dettagli_lotto"), "fa-search", "btn-secondary"));
            this.azioniBox.push(new Produzione.AzioneVm("DettagliBox", this.localeFactory.get("dettagli_lotto"), "fa-search", "btn-secondary"));
            //this.azioniStrumenti.push(new Produzione.AzioneVm("notePrecauzioniStrumento", this.localeFactory.get("btn_note_precauzioni"), "fa-exclamation-triangle", "btn-danger"));
            //this.azioniStrumenti.push(new Produzione.AzioneVm("dististaStrumento", this.localeFactory.get("btn_distinta"), "fa-puzzle-piece", "btn-default"));
        }

        public listaFase(): void {
            this.lavaggioFactory.Lista().then(
                result => {
                    this.lista = result;
                    this.listaFaseBox(this.idFase);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                }
            );
        }

        public eseguiTrattamento(funzione: string, index: number): void {
            var vm = this;
            eval("vm." + funzione + "(" + index + ")");
        }

        public trattamentoUltrasuoni(index: number): void {
            this.formDettagliProduzione.$setDirty();
            var eseguito = !this.ultrasuoni[index].eseguito;
            this.ultrasuoni[index].eseguito = eseguito;
            if (index === 0) {
                $.each(this.ultrasuoni, (i, elem) => {
                    elem.eseguito = eseguito;
                });
                if (this.currentState == 'manipola') {
                    $.each(this.lotto.Strumenti, (index, item) => {
                        (<Set.StrumentoVm>item).FlgUltrasuoni = eseguito;
                    });
                }
                else if (this.currentState == 'manipolaBox') {
                    $.each(this.box.Contenuto, (index, item) => {
                        (<Produzione.VwContenutoBox>item).FlgUltrasuoni = eseguito;
                    });
                }
            }
            else {
                this.ultrasuoni[index].numGriglia = index;
                $.each(this.lotto.Strumenti, (ids, item) => {
                    var s = (<Set.StrumentoVm>item);
                    if (s.NumGriglia == index)
                        s.FlgUltrasuoni = eseguito;
                });
            } 
        }

        public trattamentoLavaggioManuale(index: number): void {
            this.formDettagliProduzione.$setDirty();
            var eseguito = !this.manuali[index].eseguito;
            this.manuali[index].eseguito = eseguito;
            if (index === 0) {
                $.each(this.manuali, (i, elem) => {
                    elem.eseguito = eseguito;
                });
                if (this.currentState == 'manipola') {
                    $.each(this.lotto.Strumenti, (index, item) => {
                        (<Set.StrumentoVm>item).FlgLavaggioManuale = eseguito;
                    });
                }
                else if (this.currentState == 'manipolaBox') {
                    $.each(this.box.Contenuto, (index, item) => {
                        (<Produzione.VwContenutoBox>item).FlgLavaggioManuale = eseguito;
                    });
                }
            }
            else {
                this.manuali[index].numGriglia = index;
                $.each(this.lotto.Strumenti, (ids, item) => {
                    var s = (<Set.StrumentoVm>item);
                    if (s.NumGriglia == index)
                        s.FlgLavaggioManuale = eseguito;
                });
            }
        }

        public trattamentoLavaggioManualeStrumento(item: Set.StrumentoVm): void {
            this.formDettagliProduzione.$setDirty();
            item.FlgLavaggioManuale = !item.FlgLavaggioManuale;
        }
        public trattamentoUltrasuoniStrumento(item: Set.StrumentoVm): void {
            this.formDettagliProduzione.$setDirty();
            item.FlgUltrasuoni = !item.FlgUltrasuoni;
        }
        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formDettagliProduzione.$valid || (this.contaStrumenti != undefined && this.contaStrumenti.ContaAttuale == undefined)) {
                this.showValidationMessages = true;
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                this.formDettagliProduzione.$setDirty();
                return;
            }

            if ((this.isLottoTransito() || this.isLottoSfuso() || this.currentState == "manipolaBox") && (this.griglie == undefined || this.griglie.length <= 0)) {
                var keyLabel = "set_" + (this.isLottoTransito() ? "transito" : "sfuso") + "_no_griglia";
                this.msgFactory.error(this.localeFactory.get(keyLabel));
                return;
            }

            var vm = this;
            var set = this.descrizioneLotto();

            this.msgFactory.confirm(this.localeFactory.getFormatted("conferma_registrazione_set", [this.descrFase, set])).then(
                result => {
                    if (!result.result) return;
                    vm.doRegistra();
                }
            );
        }

        private doRegistra(): void {
            this.initTrattamenti();
            var vm = this;
            var dati = new Produzione.DatiProduzioneVm();

            if (this.currentState === "manipola") {

                $.each(this.ultrasuoni, (index, item) => {
                    if (item.eseguito) {
                        if (item.numGriglia === 0 && item.idGriglia == undefined) {
                            this.trattamentiSet.push(new Produzione.InfoTrattamento(vm.lotto.Id, item.idTrattamento));
                        }
                        else if (item.idGriglia != undefined) {
                            this.trattamentiGriglie.push(new Produzione.InfoTrattamentoGriglia(vm.lotto.Id, item.idGriglia, item.idTrattamento));
                        }
                    }
                });
                $.each(this.manuali, (index, item) => {
                    if (item.eseguito) {
                        if (item.numGriglia === 0 && item.idGriglia == undefined) {
                            this.trattamentiSet.push(new Produzione.InfoTrattamento(vm.lotto.Id, item.idTrattamento));
                        }
                        else if (item.idGriglia != undefined) {
                            this.trattamentiGriglie.push(new Produzione.InfoTrattamentoGriglia(vm.lotto.Id, item.idGriglia, item.idTrattamento));
                        }
                    }
                });
                $.each(this.lotto.Strumenti, (index, item) => {
                    var s = <Set.StrumentoVm>item;
                    if (s.FlgUltrasuoni === true)
                        this.trattamentiStrumenti.push(new Produzione.InfoTrattamentoStrumento(vm.lotto.Id, s.IdLottoProduzioneDetail, TipoTrattamento.ULTRASUONI));
                    if (s.FlgLavaggioManuale === true)
                        this.trattamentiStrumenti.push(new Produzione.InfoTrattamentoStrumento(vm.lotto.Id, s.IdLottoProduzioneDetail, TipoTrattamento.LAVAGGIOMANUALE));
                });

                dati.FlgLavaggioContainer = this.lotto.FlgLavaggioContainer;
                dati.TrattamentiSet = this.trattamentiSet;
                dati.TrattamentiGriglie = this.trattamentiGriglie;
                dati.TrattamentiStrumenti = this.trattamentiStrumenti;
                dati.IdLottoProduzione = this.lotto.Id;
                dati.ContaLavaggio = this.contaStrumenti.ContaAttuale;
                dati.Note = this.lotto.Note;

                console.log("registro questi dati", dati);
                this.produzioneFactory.RegistraDatiProduzione(vm.lotto.Id, dati).then(
                    result => {
                        if (result === true) {
                            var lotto = vm.descrizioneLotto();
                            vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_ok", [lotto]));
                            vm.formDettagliProduzione.$setPristine();
                        }
                    },
                    reason => {
                        this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                        console.log("errore", reason);
                    }
                );
            }
            else {
                this.formDettagliProduzione.$setPristine();
            }
        }

        public aggiungiGriglia(): void {
            this.aggiuntaGriglia = true;
            this.griglia = new Produzione.GrigliaVm();
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Lavaggio/grigliaAggiuntiva.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.associazioneLottiSterilizzazione = true;
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => {
                console.log("chiuso");
                this.griglia = undefined;
                this.aggiuntaGriglia = false;
            });
        }

        public cercaGriglia(): void {
            this.produzioneFactory.RicercaGriglia(this.griglia.Codice).then(
                result => {
                    this.griglia = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private listaGriglie(lotto: boolean = true): void {
            var idLotto = (!lotto) ? this.box.Contenuto[0].IdLotto : this.lotto.Id;
            this.produzioneFactory.ListaGriglie(idLotto).then(
                result => {
                    this.griglie = result;
                    this.setUpTrattamenti();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public confermaAggiuntaGriglia(): void {
            if (this.currentState === "manipola") {
                this.produzioneFactory.AggiungiGrigliaSet(this.lotto.Id, this.griglia.Id).then(
                    result => {
                        this.chiudiModal();
                        this.listaGriglie(this.currentState === "manipola");
                    },
                    reason => {
                        this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                        console.log("errore", reason);
                    }
                );
            }
            else {
                this.produzioneFactory.AggiungiGrigliaBox(this.box.Id, this.griglia.Id).then(
                    result => {
                        this.chiudiModal();
                        this.listaGriglie(this.currentState === "manipola");
                    },
                    reason => {
                        this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                        console.log("errore", reason);
                    }
                );
            }
        }
    }
}
app.controller("lavaggioController", Trace.Lavaggio.lavaggioController);