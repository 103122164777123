module Trace.sections {
    export class sectionsFactory {
        private sections: Array<sectionVm>;
        constructor() {
            this.sections = new Array<sectionVm>();
        }

        public evalSection(scope: ng.IScope, element: JQuery, attributes: any): void {
            var s = new sectionVm();
            s.key = attributes.ngEpSection;
            s.value = element;
            element.remove();
            this.sections.push(s);
        }

        public evalSectionRenderer(scope: ng.IScope, element: JQuery, attributes: any): void {
            var elementiSezione = $.grep(this.sections, (elem: sectionVm, index: number) => {
                return elem.containsKey(attributes.ngEpRenderSection);
            });
            $.each(elementiSezione, (index: number, item: sectionVm) => {
                element.append(item.value.html());
            });
        }
    }

    export class sectionVm {
        public key: string;
        public value: JQuery;
        public containsKey(key: string): boolean {
            return key === this.key;
        }
    }
}
app.factory("sectionsFactory", () => new Trace.sections.sectionsFactory());