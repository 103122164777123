module Trace.ModelliSet {
    export class GiacenzaProdottoModelloSetVm implements GiacenzaProdottoModelloSetPlus {
        public IdProdotto: number;
        public QtaDocumento: number;
        public QtaDeposito: number;
        public IdModelloSet: number;
        public LimiteDocumento: number;
        public LimiteDeposito: number;
        public UtilizzatiDeposito: number;
        public UtilizzatiDocumento: number;
        public Conferma: boolean;
    }
}