namespace Trace.Utente {
    export class ApplicationSessionVm {
        public IdUtente: number;
        public IdCentrale: number;
        public IdDeposito: number;
        public DataInizio: Date;
        public DataFine: Date;
        public Durata: number;
        public IdPrivilegi: string;
        public FlgRichestaApprovvigionamento: boolean;
    }
}