module Trace.Ordini {
    export class OrdineVm {
        public Id: number;
        public Numero: string;
        public Serie: string;
        public IdModelloSet: number;
        public Copie: number;
        public ModelloSet: string;
        public Dettagli: OrdineDetail[];
        public NumRighe: number;
        public NumProdotti: number;
        public TotaleOrdine: number;
        public IdCentroCosto: number;
        public IdSottogrupo: number;
        public IdSede: number;
        public IdGruppo: number;
        public IdFornitore: number;
        public GestionaleFilePath: string;
        public OrdineFornitoreFilePath: string;
        public PdfOrdineFilePath: string;
    }
}