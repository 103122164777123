module Trace.Confezionamento {
    export class ConfezionamentoLottoDetailVm {
        public IdLottoDetails: number;
        public IdLottoProduzione: number;
        public IdProdotto: number;
        public IdProdottoSostitutivo: number;
        public DescrizioneIt: string;
        public DescrizioneAl: string;
        public Qta: number;
        public QtaContati: number;
        public QtaGuasti: number;
        public QtaMancante: number;
        public QtaSostituito: number;
        public QtaSostituiti: number;
        public QtaEsaurimento: number;
        public FlgContato: boolean;
        public FlgGuasto: boolean;
        public FlgMancante: boolean;
        public FlgSostituito: boolean;
        //public FlgContatFlgMancante: boolean;
        public FlgStessoCodice: boolean;
        public FlgEsaurimento: boolean;
        public FlgRiparazione: boolean;
        public FlgSmaltimento: boolean;
    }
}