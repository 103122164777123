module Trace.CreazioneDdt {
    export class creazioneDdtController extends epProduzioneController {
        public movimenti: Movimenti.MovimentoVm[];
        public movimento: Movimenti.MovimentoVm;
        public carrelli: Helpers.SimpleEntity[];
        public vettori: Helpers.SimpleEntity[];
        static idFase
        static $inject = ["$rootScope", "$scope", "localeFactory", "msgFactory",
            "$uibModal", "setFactory", "prodottiFactory", "produzioneFactory",
            "creazioneddtFactory", "carrelliFactory", "utenteFactory", "tracciabilitaFactory"];
        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public produzioneFactory: Produzione.produzioneFactory,
            private creazioneddtFactory: creazioneddtFactory,
            public carrelliFactory: Carrelli.carrelliFactory,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        )
        {
            super($scope, $rootScope, $uibModal, localeFactory, msgFactory, setFactory, prodottiFactory, undefined, produzioneFactory, 12, "stampa del documento di trasporto", undefined, null, utenteFactory, tracciabilitaFactory);
            var vm = this;
            this.listaFase();
            setTimeout(function () {
                vm.setUpAzioni();
            }, 500);
            this.getCarrelli();
            this.getVettori();
            $(document).on("listRowMovAction", (e: JQueryEventObject) => {
                var f = e.funzione;
                var vm = this;
                var funzione = "vm." + f + "(e.movimento)";
                console.log(funzione);
                eval(funzione);
            });
        }

        public init(): void {
            this.listaFase();
            this.changeState('list');
        }
        
        public descrizioneRiga(item: Movimenti.VwRigaMovimento): string {
            var copia = this.copiaDaBarcode(item.Uid);
            var container = this.containerDaBarcode(item.Uid);
            var descrizione = this.localized(item, "Descrizione_");
            if (item.IdTipo == 2) return descrizione;
            return descrizione + " " + this.localeFactory.get("copia") + " " + copia + " " + this.localeFactory.get("container") + " " + container;
        }

        private setUpAzioni(): void {
            this.azioni.push(new Produzione.AzioneVm("dettagliMovimento", this.localeFactory.get("dettagli"), "fa-search", "btn-touch btn-secondary"));
        }

        public dettagliMovimento(movimento: Movimenti.MovimentoVm): void {
            this.creazioneddtFactory.Dettagli(movimento.Id).then(
                result => {
                    this.movimento = result;
                    console.log("Movimento", this.movimento);
                    this.changeState("manipola");
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                }
            );//
        }

        public registra(): void {// prova luca 2021  11 11
            this.creazioneddtFactory.registra(this.movimento).then(
                result => {
                    this.msgFactory.alert(this.localeFactory.get('documento_confermato')).then(
                        result => {
                            this.dettagliMovimento(this.movimento);
                        }
                    );
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                }
            );
        }

        public Conferma(): void {
            //if (this.movimento.FlgConfermato === true) return;
            this.isSaving = true;
            this.creazioneddtFactory.Conferma(this.movimento.Id).then(
                result => {
                    this.isSaving = false;
                    this.dettagliMovimento(this.movimento);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                    this.isSaving = false;
                }
            );
        }

        public listaFase(): void {
            this.creazioneddtFactory.lista().then(
                result => {
                    this.movimenti = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                }
            );
        }

        private getCarrelli(): void {
            this.carrelliFactory.lista().then(
                result => {
 
                    this.carrelli = result;
                },
                reason => {
                }
            );
        }

        private getVettori(): void {
            this.creazioneddtFactory.ListaVettori().then(
                result => {
                    console.log("vettori", result);
                    this.vettori = result;
                },
                reason => {
                }
            );
        }
    }
}
app.controller("creazioneDdtController", Trace.CreazioneDdt.creazioneDdtController);