module Trace.Confezionamento {
    export class confezionamentoController extends epProduzioneController {
        public anomalie: Array<Produzione.AnomaliaStrumentoVm>;
        public etiLottoStampata: boolean;
        private listaManutenzioni: Array<Set.StrumentoVm>;
        private listaSmaltimenti: Array<Set.StrumentoVm>;
        private listaGuasti: Array<Set.StrumentoVm>;
        public aggiuntaCesta: boolean;
        public cesta: Produzione.GrigliaVm;
        public listaEtichette: ViewModels.DictionaryItem[];
        public lottoCorrente: Produzione.LottoProduzioneVm;
        public disableButtonModalSost: boolean = false;
        public statusUsura: number[] = [1,2,3];

        public numeroBuste: Trace.Helpers.SimpleEntity[] =
            [<Trace.Helpers.SimpleEntity>{ Id: 1, Descrizione: 'Busta singola' },
             <Trace.Helpers.SimpleEntity>{ Id: 2, Descrizione: 'Busta doppia' }];

        static $inject = [
            "$rootScope", "$scope", "confezionamentoFactory", "setFactory", "prodottiFactory",
            "produzioneFactory", "localeFactory", "msgFactory", "$uibModal", "$document", "utenteFactory", "tracciabilitaFactory"
        ];

        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private confezionamentoFactory: confezionamentoFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public produzioneFactory: Produzione.produzioneFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public $document: any,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($rootScope, $scope, $uibModal, localeFactory, msgFactory, setFactory, prodottiFactory, undefined, produzioneFactory, 6, "confezionamento", $document, null, utenteFactory, tracciabilitaFactory);
            this.myRoot = <epRootScope>$rootScope;

            //this.numeroBuste.push(<Trace.Helpers.SimpleEntity>{ Id: 1, Descrizione: 'Busta singola' });
            //this.numeroBuste.push(<Trace.Helpers.SimpleEntity>{ Id: 2, Descrizione: 'Busta doppia' });

            var vm = this;
            setTimeout(function () {
                vm.setUpAzioni();
            }, 300);
            $(document).on("barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkEventHandler(e);
            }).on("barcodeGrigliaOk", (e: JQueryEventObject) => {
                this.barcodeGrigliaOkEventHandler(e);
            }).on("grigliaSfusa", (e: JQueryEventObject) => {
                this.grigliaSfusaEventHandler(e);
            }).on("cesta", e => {
                this.cestaHandler(e);
            });

            $(document).on("strumentoAction", (e: JQueryEventObject) => {
                var f = e.funzione;
                var vm = this;
                vm.formDettagliProduzione.$setDirty();
                //console.log("strumentoAction.funzione", f);
                eval("vm." + f + "(e.strumento)");
            });

            $(document).on("cancellaLottoDetailSostituito", (e: JQueryEventObject) => {
                this.cancellaLottoDetailSostituito(e.idLotto);

            });

            //Once($(document), "cancellaLottoDetailSostituito", (e: JQueryEventObject) => {
            //    this.cancellaLottoDetailSostituito(e.idLotto);
            //});

            this.$scope.$watch('vm.currentState', (newVal: number, oldVal: number) => {
                if (this.currentState === "list")
                    this.listaFase();
                if (this.currentState === "manipola") {
                    this.strumentoSelezionato = undefined;
                    this.listaGriglie();
                    this.setUpTrattamenti();
                    this.getContaStrumenti();
                    this.titoloManipola = this.localeFactory.getFormatted("titolo_dettagli_produzione", [this.descrFase.capitalize(), this.descrizioneLotto()]);
                }
            });
        }

        private init(): void {
            this.listaFase();
        }

        public stampaEtichette(): void {
            var vm = this;
            vm.listaEtichette = new Array<ViewModels.DictionaryItem>();
            vm.listaEtichette.push(new ViewModels.DictionaryItem("0", vm.localeFactory.get("container")));

            for (var i = 1; i <= vm.set.NumGriglie; i++) {
                vm.listaEtichette.push(new ViewModels.DictionaryItem(i.toString(), vm.localeFactory.getFormatted("griglia_numero", [i])));
            }

            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Set/modalSelectEtichette.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public getPrintLink(): string {
            var param = "";
            $.each($(".chk-etichetta"), (idc, chk) => {
                if ($(chk).is(":checked"))
                    param += (param === "")
                        ? $(chk).val()
                        : ";;" + $(chk).val();
            });
            var result = appRoot + "Reports/EtichetteSet?idSet=" + this.set.Id + "&elementi=" + param;
            //console.log("url stampa etichette", result);
            return result;
        }

        public Dettagli(lotto: Produzione.LottoProduzioneVm): void {

            this.lottoCorrente = lotto;

            this.produzioneFactory.Dettagli(lotto.Id, this.idFase).then(
                result => {
                    result.Strumenti.forEach((value, index) => {
                        value.IdTipoSet = result.IdTipoSet;
                        if (result.IdTipoSet == 4 || result.IdTipoSet==10) value.NumeroBuste = value.NumeroBuste == undefined ? "1" : value.NumeroBuste.toString();
                    });
                    //console.log(result.Strumenti);
                    this.lotto = result;
                    console.log(result);
                    this.getCestaAssociata(this.lotto.Id);
                    this.setFactory.leggi(lotto.IdSet).then(
                        risultato => {
                            this.set = risultato;
                            this.produzioneFactory.GetImmagini(lotto.Id).then(
                                risultato => {
                                    this.set.Immagini = this.set.Immagini.concat(risultato);
                                }
                            );
                            this.cambiaAltezzaListaProdotti();
                            if (!this.needsContaStrumenti())
                                this.checkFlgContatoStrumenti();
                        }
                    );
                    this.changeState("manipola");
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private stampaEtichettaLotto() {
            this.registra(true);
        }

        private listaGriglie(): void {
            this.produzioneFactory.ListaGriglie(this.lotto.Id).then(
                result => {
                    this.griglie = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public getCestaAssociata(idLotto: number): void {
            this.produzioneFactory.CestaAssociata(idLotto).then(
                result => {
                    if (<any>result === "") result = undefined;
                    this.cesta = result;
                    console.log("cesta associata", this.cesta);
                }
            );
        }

        private setUpTrattamenti(): void {
            this.anomalie = this.anomalieStrumento();
            this.trattStrumenti = new Array<Produzione.TrattamentoVm>();
            this.trattStrumenti.push(new Produzione.TrattamentoVm(1, 1, this.localeFactory.get("btn_guasto"), "setGuasti", "btn btn-warning btn-block btn-touch-small btn-touch-small"));
            this.trattStrumenti.push(new Produzione.TrattamentoVm(1, 2, this.localeFactory.get("btn_mancante"), "setMancanti", "btn btn-primary btn-block btn-touch-small btn-touch-small"));
            this.trattStrumenti.push(new Produzione.TrattamentoVm(1, 3, this.localeFactory.get("btn_esaurimento"), "setEsaurimento", "btn btn-secondary btn-block btn-touch-small btn-touch-small"));
        }

        private setUpAzioni(): void {
            this.azioni.push(new Produzione.AzioneVm("Dettagli", this.localeFactory.get("dettagli_lotto"), "fa-search", "btn-secondary"));
            this.azioniStrumenti.push(new Produzione.AzioneVm("notePrecauzioniStrumento", this.localeFactory.get("btn_note_precauzioni"), "fa-exclamation-triangle", "btn-danger"));
            this.azioniStrumenti.push(new Produzione.AzioneVm("dististaStrumento", this.localeFactory.get("btn_distinta"), "fa-puzzle-piece", "btn-default"));
        }

        public listaFase(): void {
            this.confezionamentoFactory.Lista().then(
                result => {
                    this.lista = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                }
            );
        }
        
        public changeState(stato: string): void {
            super.changeState(stato);
            if (stato === "manipola") {
                this.etiLottoStampata = false;
                this.strumentiMagazzini = null;
                this.checkMagazzinoStrumenti();
                this.formDettagliProduzione.$setDirty();
            }
        }

        public checkMagazzinoStrumenti(): void {
            this.produzioneFactory.CheckMagazzinoStrumenti(this.lotto.Id).then(
                result => {
                    if (result != undefined && result.length > 0)
                        this.showStrumentiMangazzini(result);
                }
            );
        }

        private showStrumentiMangazzini(lista: Set.StrumentoVm[]): void {
            var vm = this;
            this.strumentiMagazzini = lista;
            this.strumentiReintegrati = new Array<Set.StrumentoVm>();
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Confezionamento/modalStrumentiMagazzini.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public disabilitaAvanzamentoStato(): boolean {
            if (this.lotto == undefined) return true;
            var result = this.isSaving || (this.lotto.FlgRistampaEtichettaLotto && !this.etiLottoStampata);
            return result;
        }

        public registra(fromEtichettaLotto: boolean = false): void {
            this.showValidationMessages = false;
            if (!this.formDettagliProduzione.$valid) {
                this.showValidationMessages = true;
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                this.formDettagliProduzione.$setDirty();
                return;
            }
            var nonContati = $.grep(this.lotto.Strumenti, (item, index) => {
                var s = <Set.StrumentoVm>item;
                return !s.FlgFermo && s.FlgGuasto !== true && s.FlgMancante !== true && s.FlgEsaurimento !== true && s.FlgContato !== true;
            });
            if (nonContati.length > 0) {
                this.msgFactory.error(this.localeFactory.get("validazione_errore_conta"));
                this.formDettagliProduzione.$setDirty();
                return;
            }
            this.checkGuastiMancanti(fromEtichettaLotto);
        }

        private needsContaStrumenti(): boolean {
            if (this.set == undefined) return true;
            return this.set.IdTipo !== 7;
        }

        private checkGuastiMancanti(fromEtichettaLotto: boolean = false): void {
            var gm = $.grep(this.lotto.Strumenti, (item, index) => {
                return (<Set.StrumentoVm>item).FlgGuasto && !(<Set.StrumentoVm>item).FlgFermo;
            });
            if (gm == undefined || gm.length <= 0) {
                this.confirmRegistra(null,fromEtichettaLotto);
                return;
            }
            this.showListaManutenzioni(gm);
        }

        public checkFlgContatoStrumenti(): void {
            $.each(this.lotto.Strumenti, (index, item) => {
                item.FlgContato = true;
                item.FlgGuasto = false;
                item.FlgMancante = false;
                item.QtaContati = item.Qta;
                item.QtaMancante = 0;
                item.QtaGuasto = 0;
            });
        }

        private showListaManutenzioni(lista: Set.StrumentoVm[]): void {
            var vm = this;
            console.log(lista)
            this.listaGuasti = lista.filter(item => !item.FlgSmaltimento);
            this.listaSmaltimenti = lista.filter(item => item.FlgSmaltimento);

            // 19/07/2024 #LORENZO COMMENTATO FLUSSO MANUTENZIONI

            //this.listaManutenzioni = new Array<Set.StrumentoVm>();
            //var options: ng.ui.bootstrap.IModalSettings = {
            //    templateUrl: '/views/Confezionamento/modalManutenzioni.html',
            //    scope: this.$scope,
            //    windowClass: "modal-styled",
            //    size: "xxl"
            //};
            //this.$modalInstance = this.$uibModal.open(options);
            //this.$modalInstance.result.then(() => { vm.listaGuasti = undefined; });
            vm.doRegistra(false);

        }

        public inManutenzione(id: number): boolean {
        
            // 19/07/2024 #LORENZO COMMENTATO FLUSSO MANUTENZIONI
            //var exists = false;
            //$.each(this.listaManutenzioni, (index, item) => {
            //    if (item.Id === id) {
            //        exists = true;
            //        return;
            //    }
            //});
            //return exists;
            return false;
        }

        public reintegrato(id: number): boolean {
            var exists = false;
            $.each(this.strumentiReintegrati, (index, item) => {
                if (item.Id === id) {
                    exists = true;
                    return;
                }
            });
            return exists;
        }

        public addToManutenzioni(strumento: Set.StrumentoVm): void {
            var exists = false;
            $.each(this.listaManutenzioni, (index, item) => {
                if (item.Id === strumento.Id) {
                    this.listaManutenzioni.splice(index, 1);
                    exists = true;
                    return;
                }
            });
            if (exists) return;
            this.listaManutenzioni.push(strumento);
        }

        public addToReintegri(strumento: Set.StrumentoVm): void {
            var exists = false;
            $.each(this.strumentiReintegrati, (index, item) => {
                if (item.Id === strumento.Id) {
                    this.strumentiReintegrati.splice(index, 1);
                    exists = true;
                    return;
                }
            });
            if (exists) return;
            this.strumentiReintegrati.push(strumento);
        }

        public addAllToManutenzioni(): void {
            this.listaManutenzioni = angular.copy(this.listaGuasti);
        }

        public registraReintegroStrumenti(): void {
            if (this.strumentiReintegrati == undefined || this.strumentiReintegrati.length <= 0) {
                this.msgFactory.error(this.localeFactory.get("reintegro_strumenti_validation_error"));
                return;
            }
            this.msgFactory.confirm(this.localeFactory.getFormatted("reintegro_strumenti_conferma", [this.strumentiReintegrati.length, this.descrizioneLotto()])).then(
                result => {
                    if (!result.result) return;
                    this.doRegistraReintegroStrumenti();
                }
            );
        }

        public doRegistraReintegroStrumenti(): void {
            var ids = new Array<number>();
            $.each(this.strumentiReintegrati, (index, item) => {
                ids.push(item.Id);
            });

            this.produzioneFactory.RegistraReintegroStrumenti(this.lotto.Id, ids).then(
                result => {
                    this.Dettagli(this.lotto);
                    this.chiudiModal();
                }
            );
        }

        private confirmRegistra(numManutenzioni: number = undefined, fromEtichettaLotto: boolean = false): void {
            var vm = this;
            var set = this.descrizioneLotto();
            var messaggio = numManutenzioni == undefined
                ? this.localeFactory.getFormatted("conferma_registrazione_set", [this.descrFase, set])
                : this.localeFactory.getFormatted("conferma_registrazione_set_manutenzioni", [this.descrFase, set, numManutenzioni]);
            this.msgFactory.confirm(messaggio).then(
                result => {
                    if (!result.result) return;
                    vm.doRegistra(fromEtichettaLotto);
                }
            );
        }

        private doRegistra(fromEtichettaLotto: boolean = false, flgSostituzioniConf: boolean = false): void {
            var vm = this;
            this.anomalie = new Array<Produzione.AnomaliaStrumentoVm>();
            //console.log("anomalie", this.lotto.Strumenti);
            // Anomalie strumenti
            $.each(this.lotto.Strumenti, (index, item) => {
                if (!item.FlgFermo) {
                    var a = new Produzione.AnomaliaStrumentoVm();
                    a.FlgGuasto = item.FlgGuasto;
                    a.FlgEsaurimento = item.FlgEsaurimento;
                    a.FlgMancante = item.FlgMancante;
                    a.Qta = item.Qta;
                    a.QtaContati = item.QtaContati;
                    a.Id = item.Id;
                    a.DataClickConfezionamento = item.DataClickConfezionamento;
                    a.FlgNote = item.FlgNote;
                    a.FlgValidazione = item.FlgValidazione;
                    if (this.lotto.IdTipoSet == 4 || this.lotto.IdTipoSet==10) a.NumeroBuste = item.NumeroBuste;
                    this.anomalie.push(a);
                }
            });

            var dati = new Produzione.DatiProduzioneVm();
                dati.AnomalieStrumenti = this.anomalie;
                dati.PesoIngressoAutoclave = this.lotto.PesoIngressoAutoclave;
                dati.AddToManutenzione = this.listaManutenzioni;
                dati.AddToSmaltimento = this.listaSmaltimenti;
                dati.Note = this.lotto.Note;
                dati.IdStatusUsura = this.lotto.IdStatusUsura;

            this.produzioneFactory.RegistraDatiProduzione(vm.lotto.Id, dati).then(
                result => {
                    if (result === true) {
                        var lotto = vm.descrizioneLotto();
                        if (!fromEtichettaLotto) vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_ok", [lotto]));
                        vm.formDettagliProduzione.$setPristine();
                        vm.chiudiModal();

                        // #LUCA - salvataggio preventivo, prima di stampare etichettta lotto, per non perdersi nel calcolo della scadenza la selezione singola/doppia
                        if (fromEtichettaLotto) {
                            //console.log("doRegistra, fromEtichettaLotto")
                            vm.etiLottoStampata = true;
                            window.open("/Reports/PelabileLotto?idLotto=" + this.lotto.Id, "_blank");
                        }

                        if (flgSostituzioniConf) {
                            this.Dettagli(this.lottoCorrente);
                            this.chiudiModal();
                        }
                    }
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private cestaHandler(e: JQueryEventObject): void {
            if (!this.aggiungiCesta) return;
            if (this.cesta == undefined) this.cesta = new Produzione.GrigliaVm();
            this.cesta.Codice = e.lettura;
            this.cercaCesta();
        }

        public aggiungiCesta(): void {
            var vm = this;
            this.aggiuntaCesta = true;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Confezionamento/cestaAggiuntiva.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => {
                vm.cesta = undefined;
                vm.aggiuntaCesta = false;
            });
        }

        public cercaCesta(): void {
            //console.log("Cerco la cesta", this.cesta);
            this.produzioneFactory.RicercaCesta(this.cesta.Codice).then(
                result => {
                    this.cesta = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_errore_generico", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public confermaAggiuntaCesta(): void {
            this.produzioneFactory.AggiungiCestaSet(this.lotto.Id, this.cesta.Id).then(
                result => {
                    this.chiudiModal();
                    this.getCestaAssociata(this.lotto.Id);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_errore_generico", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private setGuasti(item: Set.StrumentoVm): void {
            //console.log(item);
            this.showDialogSostituzioni(item, "guasto");
            this.formDettagliProduzione.$setDirty();
            item.FlgGuasto = !item.FlgGuasto;
            item.FlgContato = false;
            item.FlgMancante = false;
            item.FlgEsaurimento = false;
            item.QtaEsaurimento = 0;
            item.QtaContati = 0;
            item.QtaMancante = 0;
            item.QtaGuasto = (item.FlgGuasto) ? 1 : 0;
        }

        private setMancanti(item: Set.StrumentoVm): void {
            //console.log(item);
            this.showDialogSostituzioni(item, "mancante");
            this.formDettagliProduzione.$setDirty();
            item.FlgMancante = !item.FlgMancante;
            item.FlgContato = false;
            item.FlgGuasto = false;
            item.FlgEsaurimento = false;
            item.QtaEsaurimento = 0;
            item.QtaContati = 0;
            item.QtaGuasto = 0;
            item.QtaMancante = (item.FlgMancante) ? 1 : 0;
        }

        private setEsaurimento(item: Set.StrumentoVm): void {
            //console.log(item);
            this.showDialogSostituzioni(item, "esaurimento");
            this.formDettagliProduzione.$setDirty();
            item.FlgMancante = false;
            item.FlgContato = false;
            item.FlgGuasto = false;
            item.FlgEsaurimento = !item.FlgEsaurimento;
            item.QtaContati = 0;
            item.QtaGuasto = 0;
            item.QtaMancante = 0;
            item.QtaEsaurimento = (item.FlgEsaurimento) ? 1 : 0;
        }
        
        private resetStrumento(item: Set.StrumentoVm): void {
            //console.log(item);
            this.formDettagliProduzione.$setDirty();
            item.FlgMancante = false;
            item.FlgContato = false;
            item.FlgGuasto = false;
            item.FlgEsaurimento = false;
            item.QtaContati = 0;
            item.QtaGuasto = 0;
            item.QtaMancante = 0;
            item.QtaEsaurimento = 0;
        }

        // ****************************************
        // GESTIONE FLUSSO SOSTITUZIONI
        // ****************************************
        
        public infoSost: any = {};
        public componenti: any = {};

        public aggiornaRicercaSostituzioni() {
            var filtro = <Trace.ViewModels.RicercaVm>{ max: 100, colonne: [], sort: [] };

            filtro.sort.push(new Trace.ViewModels.SortRigaVm("Codice", 1));

            var somethingToSearch = false;

            if (this.infoSost.filtroRicerca.Id != undefined) {
                filtro.colonne.push(new Trace.ViewModels.RicercaRigaVm("Id", this.infoSost.filtroRicerca.Id));
                somethingToSearch = true;
            }
            if (this.infoSost.filtroRicerca.Codice != undefined && this.infoSost.filtroRicerca.Codice.trim() != "") {
                filtro.colonne.push(new Trace.ViewModels.RicercaRigaVm("Codice", this.infoSost.filtroRicerca.Codice));
                somethingToSearch = true;
            }
            if (this.infoSost.filtroRicerca.Descrizione != undefined && this.infoSost.filtroRicerca.Descrizione.trim() != "") {
                filtro.colonne.push(new Trace.ViewModels.RicercaRigaVm("Descrizione", this.infoSost.filtroRicerca.Descrizione));
                somethingToSearch = true;
            }
            //console.log("aggiornaRicercaSostituzioni", this.infoSost.itemOriginale);
            filtro.idProdotto = this.infoSost.itemOriginale.IdProdotto;
            this.prodottiFactory.ricercaCodiciAlternativi(filtro).then(
                result => {

                    //console.log(result);

                    result.forEach((item) => {
                        item.FlgCensito = true;
                    });

                    //console.log(result);

                    this.infoSost.prodotti = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                });

            if (!this.infoSost.item.FlgEsaurimento) {
                this.prodottiFactory.ricerca(filtro)
                    .then(
                    result => {
                        result.forEach((item) => {
                            item.FlgCensito = false;
                        });

                        //console.log(result);

                        this.infoSost.prodotti.push(...result);
                        // i PUNTINI servono per far prendere ogni elemento di result e aggiungerlo singolarmente, 
                        // altrimenti.push aggiungerebbe result come un unico elemento dell'array
                    },
                    reason => {
                        this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    });
            }
        }

        public initDialogSostituzioni() {
            //this.aggiornaRicercaSostituzioni();

            // tutto read only se è presente una sostituizione già salvata per questo strumento
            if (this.infoSost.item.IdLottoDetailSostitutivo != null)
            {
                this.infoSost.disabled_all = true;
                this.infoSost.filtroRicerca.Id = this.infoSost.item.IdProdottoSostitutivo;
                this.aggiornaRicercaSostituzioni();
            }
            else
            {
                this.infoSost.disabled_all = false;
                this.infoSost.disabled_1 = false;

                if (!this.infoSost.item.FlgGuasto && !this.infoSost.item.FlgMancante)
                    this.infoSost.disabled_2 = true;
               
                if (!this.infoSost.item.FlgSostituito)
                    this.infoSost.disabled_3 = true;
            }
        }

        public initFiltroRicercaSostituzioni() {
            this.infoSost.filtroRicerca = {
                Id: null,
                Codice: null,
                Descrizione: null
            };
        }

        public switchHandlerSostituzioni(tipo: string) {
            switch (tipo) {
                case "guasto":
                    this.infoSost.item.IdProdottoSostitutivo = undefined; this.infoSost.item.CodiceProdottoSostitutivo = undefined;
                    this.infoSost.item.MatricolaProdottoSostitutivo = undefined; this.infoSost.item.IdLottoDetailSostitutivo = undefined;
                    if (this.infoSost.item.FlgGuasto) {
                        this.infoSost.disabled_2 = false;
                    }
                    else {
                        this.infoSost.item.FlgSostituito = false;
                        this.infoSost.item.FlgStessoCodice = false;
                        this.infoSost.disabled_2 = true;
                        this.infoSost.disabled_3 = true;
                    }

                    this.infoSost.item.QtaContati = 0;
                    this.infoSost.item.QtaGuasto = this.infoSost.item.FlgGuasto ? 1 : 0;
                    this.infoSost.item.QtaMancante = 0;
                    this.infoSost.item.QtaSostituito = 0;
                    break;
                case "riparazioni":
                    this.infoSost.item.FlgRiparazione = !this.infoSost.item.FlgRiparazione;
                    break;
                case "mancante":
                    this.infoSost.item.IdProdottoSostitutivo = undefined; this.infoSost.item.CodiceProdottoSostitutivo = undefined;
                    this.infoSost.item.MatricolaProdottoSostitutivo = undefined; this.infoSost.item.IdLottoDetailSostitutivo = undefined;
                    if (this.infoSost.item.FlgMancante) {
                        this.infoSost.disabled_2 = false;
                    }
                    else {
                        this.infoSost.item.FlgSostituito = false;
                        this.infoSost.item.FlgStessoCodice = false;
                        this.infoSost.disabled_2 = true;
                        this.infoSost.disabled_3 = true;
                    }

                    this.infoSost.item.QtaContati = 0;
                    this.infoSost.item.QtaGuasto = 0;
                    this.infoSost.item.QtaMancante = this.infoSost.item.FlgMancante ? 1 : 0;
                    this.infoSost.item.QtaSostituito = 0;
                    break;

                case "esaurimento":
                    this.infoSost.item.IdProdottoSostitutivo = undefined; this.infoSost.item.CodiceProdottoSostitutivo = undefined;
                    this.infoSost.item.MatricolaProdottoSostitutivo = undefined; this.infoSost.item.IdLottoDetailSostitutivo = undefined;
                    this.infoSost.item.QtaEsaurimento = this.infoSost.item.FlgEsaurimento ? 1 : 0;
                    this.infoSost.disabled_1 = false;
                    this.infoSost.disabled_2 = false;
                    this.infoSost.disabled_3 = false;
                    this.infoSost.disable_All = false;

                    this.infoSost.item.QtaContati = 0;
                    this.infoSost.item.QtaGuasto = 0;
                    this.infoSost.item.QtaMancante = 0;
                    this.infoSost.item.QtaEsaurimento = 1;
                    break;

                case "sostituito":
                    this.infoSost.item.IdProdottoSostitutivo = undefined; this.infoSost.item.CodiceProdottoSostitutivo = undefined;
                    this.infoSost.item.MatricolaProdottoSostitutivo = undefined; this.infoSost.item.IdLottoDetailSostitutivo = undefined;
                    this.infoSost.item.QtaSostituiti = this.infoSost.item.FlgSostituito ? 1 : 0;
                    if (this.infoSost.item.FlgSostituito) {
                        this.infoSost.disabled_3 = false;
                        this.initFiltroRicercaSostituzioni();
                        this.infoSost.prodotti = [];
                    }
                    else {
                        this.infoSost.item.FlgStessoCodice = false;
                        this.infoSost.disabled_3 = true;
                        this.initFiltroRicercaSostituzioni();
                        this.infoSost.prodotti = [];
                    }
                break;

                case "stessoCodice":
                    this.infoSost.item.IdProdottoSostitutivo = undefined; this.infoSost.item.CodiceProdottoSostitutivo = undefined;
                    this.infoSost.item.MatricolaProdottoSostitutivo = undefined; this.infoSost.item.IdLottoDetailSostitutivo = undefined;
                    break;

                case "smaltimento":
                    console.log("SMALTIMENTO");
                    console.log(this.infoSost.item.FlgSmaltimento);
                    break;
            }
        }

        public showDialogSostituzioni(item: Set.StrumentoVm, tipo: string): void {

            this.infoSost = {
                tipo: tipo,

                disabledAll: false,
                disabled_1: false,
                disabled_2: false,
                disabled_3: false,

                itemOriginale: item,
                item: {
                    Qta: item.Qta,

                    QtaContati: item.QtaContati,
                    QtaGuasto: item.QtaGuasto,
                    QtaMancante: item.QtaMancante,
                    QtaEsaurimento: item.QtaEsaurimento,
                    QtaSostituito: 0,  //item.QtaSostituito,

                    FlgContato: item.FlgContato,
                    FlgGuasto: item.FlgGuasto,
                    FlgMancante: item.FlgMancante,
                    FlgEsaurimento: item.FlgEsaurimento,
                    FlgSostituito: false, //item.FlgSostituito

                    IdProdotto: item.IdProdotto,
                    IdProdottoSostitutivo: null, //item.IdProdottoSostitutivo,
                    CodiceProdottoSostitutivo: null, //item.CodiceProdottoSostitutivo
                    MatricolaProdottoSostitutivo: null,
                    IdLottoDetailSostitutivo: null,
                    FlgStessoCodice: false //item.FlgStessoCodice
                },
                filtroRicerca: {
                    Id: null,
                    Codice: null,
                    Descrizione: null
                },
                prodotti: []
            };

            this.initDialogSostituzioni();
            
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Confezionamento/modalSostituzioni.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => {
                // Pulizia oggetti appoggio ?
                //vm.infoSost.tipo = undefined;
                //vm.infoSost.item = undefined;
            });
        }

        private cancellaLottoDetailSostituito(idLotto: number): void {
            this.produzioneFactory.CancellaLottoDetailSostituito(idLotto).then(
                result => {

                    if (!result)
                        return this.msgFactory.error(this.localeFactory.get("errore_non_gestito_dettagli"));;

                    this.doRegistra(false, true)
                    // messi nella funzione doREgistra e le avvio tramite flg TRUE su secondo argomento
                    // this.Dettagli(this.lottoCorrente);
                    // this.chiudiModal();
                }
            );
        }

        public chiudiModalSostituzioni(): void {
            this.chiudiModal();
            //this.resetStrumento(this.infoSost);
        }

        public confermaModalSostituzioni(): void {
            if (this.infoSost.item.IdProdottoSostitutivo != null &&
                this.infoSost.item.FlgMatricolaObbligatoria == true) {
                if (this.infoSost.item.MatricolaProdottoSostitutivo == null ||
                    this.infoSost.item.MatricolaProdottoSostitutivo == "") {
                    this.msgFactory.alert("La matricola è obbligatoria, se non si dispone di una matricola inserire 'NOCODE' ");
                    return;
                }
                this.doConfermaModalSostituzioni(false);
            }
            else
                this.doConfermaModalSostituzioni(false);
        }

        public doConfermaModalSostituzioni(flgRiparazione: boolean): void {
            console.log(this.infoSost);

            this.disableButtonModalSost = true;
            var idLottoSostituitoDetail = this.infoSost.itemOriginale.Id;
            var idProdottoSostitutivo = this.infoSost.item.idProdottoSostitutivo;

            var lottoDetail = new ConfezionamentoLottoDetailVm();

            //console.log(this.infoSost.itemOriginale.Id);
            lottoDetail.IdLottoDetails = this.infoSost.itemOriginale.Id;

            lottoDetail.FlgContato = this.infoSost.item.FlgContato;
            lottoDetail.FlgGuasto = this.infoSost.item.FlgGuasto;
            lottoDetail.FlgMancante = this.infoSost.item.FlgMancante;
            lottoDetail.FlgSostituito = this.infoSost.item.FlgSostituito;
            lottoDetail.FlgStessoCodice = this.infoSost.item.FlgStessoCodice;
            lottoDetail.FlgEsaurimento = this.infoSost.item.FlgEsaurimento;
            lottoDetail.FlgRiparazione = this.infoSost.item.FlgRiparazione;
            lottoDetail.FlgSmaltimento = this.infoSost.item.FlgSmaltimento;

            lottoDetail.IdProdotto = this.infoSost.item.IdProdotto;
            lottoDetail.IdProdottoSostitutivo = this.infoSost.item.IdProdottoSostitutivo;

            lottoDetail.Qta = this.infoSost.item.Qta;
            lottoDetail.QtaContati = this.infoSost.item.QtaContati;
            lottoDetail.QtaGuasti = this.infoSost.item.QtaGuasto;
            lottoDetail.QtaMancante = this.infoSost.item.QtaMancante;
            lottoDetail.QtaSostituiti = this.infoSost.item.QtaSostituiti;
            lottoDetail.QtaSostituito = this.infoSost.item.QtaSostituito;
            lottoDetail.QtaEsaurimento = this.infoSost.item.QtaEsaurimento;

            lottoDetail.DescrizioneIt = this.infoSost.itemOriginale.DescrizioneIt;
            lottoDetail.DescrizioneAl = this.infoSost.itemOriginale.DescrizioneAl;

            //lottoDetail.FlgRiparazione = flgRiparazione;

            this.produzioneFactory.SaveStrumentoGestioneSegnalazioni(lottoDetail).then(
                result => {
                    //this.doRegistra(false, true);
                    this.Dettagli(this.lottoCorrente);// lo faccio dentro doRegistra passando flgTRUE
                    this.chiudiModal();
                    this.disableButtonModalSost = false;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public selezioneProdSostituzioni(prodotto: any) {
            if (prodotto.Id == this.infoSost.item.IdProdottoSostitutivo) {
                this.infoSost.item.FlgStessoCodice = true;
                // $("#flgStessoCodice").prop("checked", true);
                return;
            }

            this.infoSost.item.IdProdottoSostitutivo = prodotto.Id;
            this.infoSost.item.CodiceProdottoSostitutivo = prodotto.Codice;
            this.infoSost.item.MatricolaProdottoSostitutivo = undefined;
            this.infoSost.item.IdLottoDetailSostitutivo = undefined;

        }

        public selezioneProdSostituzioniComponenti(prodotto: any) {
            if (prodotto.Id == this.componenti.item.IdProdottoSostitutivo) {
                this.infoSost.item.FlgStessoCodice = true;
                // $("#flgStessoCodice").prop("checked", true);
                return;
            }

            this.componenti.item.IdProdottoSostitutivo = prodotto.Id;
            this.componenti.item.CodiceProdottoSostitutivo = prodotto.Codice;
            this.componenti.item.MatricolaProdottoSostitutivo = undefined;
            this.componenti.item.IdLottoDetailSostitutivo = undefined;
        }

        public openModalRiparazionComponenti() {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Confezionamento/modalSostituzioniComponenti.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.aggiornaRicercaSostituzioniComponenti();
        }
        
        public aggiornaRicercaSostituzioniComponenti() {
            var filtro = <Trace.ViewModels.RicercaVm>{ max: 100, colonne: [], sort: [] };
            filtro.sort.push(new Trace.ViewModels.SortRigaVm("Codice", 1));

            this.prodottiFactory.ricercaComponenti(filtro).then(
                result => {
                    console.log(result);
                    this.componenti = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                });
        }

        // ****************************************
    }
}
app.controller("confezionamentoController", Trace.Confezionamento.confezionamentoController);