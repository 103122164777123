module Trace.TipoIntervento {
    export class RicercaTipoIntervento {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public DescrizioneSet: string;
    }

    export class RicercaDistintaTipoIntervento {
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdTipo: number;
    }
}