
module Trace.Sede { 
    export class RicercaSede {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public Indirizzo: string;
        public IdGruppo: string;
        public IdSottoGruppo: string;
    }

    export class sediController {
        public lista: sedeVm[];
        public recordCorrente: Sede;
        public testoAzione: string;
        public gruppi: Helpers.SimpleEntity[];
        public sottoGruppi: Helpers.SimpleEntity[];
        public gruppiRicerca: Gruppo.Gruppo[];
        public sottoGruppiRicerca: SottoGruppo.SottoGruppo[];
        public ricerca: RicercaSede;

        public currentState: string;
        public titoloManipola: string;

        public showValidationMessages: boolean;
        public formSedi: any;

        private sedeOld: Sede;

        static $inject = ['$scope', 'sottoGruppiFactory', 'gruppiFactory', 'sediFactory', 'dtFactory', 'localeFactory',
            'msgFactory'];
        constructor(private $scope: ng.IScope, private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private gruppiFactory: Gruppo.gruppiFactory, private sediFactory: Sede.sediFactory,
            private dtFactory: datatables.dtFactory, private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory) {

            gruppiFactory.asSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.gruppi = risultato;
            });

            gruppiFactory.lista().then((risultato: Gruppo.Gruppo[]) => {
                this.gruppiRicerca = risultato;

                var dummy: Gruppo.Gruppo = new Gruppo.Gruppo();
                dummy.Descrizione_it_IT = '';
                this.gruppiRicerca.unshift(dummy);
            });

            this.annulla();
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formSedi.$valid) {
                this.showValidationMessages = true;
                this.formSedi.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    if (this.recordCorrente.Id == undefined)
                        this.sediFactory.inserisci(this.recordCorrente).then((result => {
                            this.annulla();
                        }),
                        (error => {
                            this.msgFactory.alert(error);
                        }));
                    else
                        this.sediFactory.modifica(this.recordCorrente).then((result => {
                            this.annulla();
                        }),
                        (error => {
                            this.msgFactory.alert(error);
                        }));
                }
            });
        }

        public annulla(): void {
            this.recordCorrente = new Sede();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.ricerca = new RicercaSede();

            this.sediFactory.lista().then((risultato: sedeVm[]) => {
                this.lista = risultato;
                this.changeState('list');
            });
        }

        public cancella(Sede: Sede): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.sediFactory.cancella(Sede.Id).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        public modifica(SedeVm: sedeVm): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.sediFactory.leggi(SedeVm.Id).then((Sede: Sede) => {
                this.recordCorrente = Sede;
                this.sedeOld = <Sede>{
                    IdGruppo: this.recordCorrente.IdGruppo,
                    IdSottogruppo: this.recordCorrente.IdSottogruppo,
                    Codice: this.recordCorrente.Codice
                };
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.cambiaGruppo();
                this.changeState('manipola');
            });
        }

        private fixCodice(): void {
            if (this.sedeOld != undefined) {
                if (this.sedeOld.IdGruppo != this.recordCorrente.IdGruppo || this.sedeOld.IdSottogruppo != this.recordCorrente.IdSottogruppo) {
                    this.recordCorrente.Codice = undefined;
                }
                else {
                    this.recordCorrente.Codice = this.sedeOld.Codice;
                }
            }
        }

        public cambiaGruppo(): void {
            this.fixCodice();
            if (this.recordCorrente.IdGruppo == undefined)
                return;

            this.sottoGruppiFactory.listaGruppoAsSe(this.recordCorrente.IdGruppo).then((risultato: Helpers.SimpleEntity[]) => {
                this.sottoGruppi = risultato;
            });
        }

        public aggiornaRicercaGruppo() {
            this.sottoGruppiRicerca = [];
            this.ricerca.IdSottoGruppo = undefined;

            if (this.ricerca.IdGruppo == undefined) {
                this.dtFactory.aggiornaRicerca();
                return;
            }
            this.sottoGruppiFactory.listaGruppo(parseInt(this.ricerca.IdGruppo)).then((risultato: SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppiRicerca = risultato;

                var dummy: SottoGruppo.SottoGruppo = new SottoGruppo.SottoGruppo();
                dummy.Descrizione_it_IT = '';
                this.sottoGruppiRicerca.unshift(dummy);

                this.dtFactory.aggiornaRicerca();
            });
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: ViewModels.DirezioneSort, filtro: Array<ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<ViewModels.SortRigaVm>();
            ricerca.sort.push(new ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.sediFactory.ricerca(ricerca).then((risultato: sedeVm[]) => {
                this.lista = risultato;
            });
        }

        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = new Sede();
            this.sedeOld = undefined;
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formSedi.$setPristine();
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaSede();
        }


    }
}

app.controller("sedeController", Trace.Sede.sediController);