module Trace.Flusso {
    export class flussoDetailVm {
        public Id: number;
        public Descrizione_it: string;
        public Descrizione_en: string;
        public Descrizione_al: string;
        public Flusso: string;
        public Fase: string;
        public TipoMacchina: string;
        public Ordinamento: number;
        public IdFlusso: number;
        public IdFase: number;
        public IdMacchina: number;
    }
}