module Trace.Produzione {
    export class InfoTrattamento implements Produzione.LottoProduzione_Trattamento {
        public Id: number;
        public DataCreazione: Date;
        public FlgGriglia: boolean;
        public IdUtente: number;
        public Note: string;
        public NumGriglia: number;
        constructor(public IdLottoProduzione: number, public IdTrattamento: number) { }
    }
}