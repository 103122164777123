module Trace.SoglieConsumi {
    export class soglieConsumiController {
        public editMode: boolean = false;
        public showValidationMessages: boolean;
        public isSaving: boolean;

        public currentState: string;
        public model: SoglieConsumi.VwSoglieConsumi;
        public tipoSoglia: number;
        public codArt: string;
        public formSoglie: any;
        public isSearching: any;

        public currentPage: number;
        public ricercaVm: any;
        public listaSoglie: VwSoglieConsumi[];

        public raggruppamenti: Helpers.SimpleEntity[];
        public gruppiRicerca: Gruppo.Gruppo[];
        public sottoGruppiRicerca: SottoGruppo.SottoGruppo[];
        public sediRicerca: Sede.Sede[];
        public centriCostoRicerca: CentroCosto.CentroCosto[];
        public centrali: Helpers.SimpleEntity[];

        public gruppi: Gruppo.Gruppo[];
        public sottoGruppi: SottoGruppo.SottoGruppo[];
        public sedi: Sede.Sede[];
        public centriCosto: CentroCosto.CentroCosto[];

        public items: ItemSoglia[];
        public gruppiMateriali: Helpers.SimpleEntity[];
        public detail: VwSoglieConsumiDetails;

        public titoloManipola: string;

        static $inject = [
            '$scope',
            'soglieConsumiFactory',
            'gruppiFactory',
            'sottoGruppiFactory',
            'sediFactory',
            'centriCostoFactory',
            'centraliFactory',
            'localeFactory',
            'msgFactory',
            'dtFactory',
            'setFactory'
        ];

        constructor(
            private $scope: ng.IScope,
            private soglieConsumiFactory: SoglieConsumi.soglieConsumiFactory,
            private gruppiFactory: Gruppo.gruppiFactory,
            private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory,
            private centraliFactory: Centrale.centraliFactory,
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory,
            private setFactory: Set.setFactory
        ) {
            this.gruppiFactory.lista().then(
                result => {
                    this.gruppi = result;
                    this.gruppiRicerca = result;
                });

            this.setFactory.listaRaggruppamenti().then(
                result => {
                    this.raggruppamenti = result;
                });

            this.centraliFactory.asSe().then(
                result => {
                    this.centrali = result;
                });

            this.soglieConsumiFactory.listaRaggruppamentiMateriali().then(
                result => {
                    this.gruppiMateriali = result;
                });
        }

        public $onInit(): void {
            this.setUpWatchers();
        }

        public changeState(stato: string): void {
            this.currentState = stato;

            switch (this.currentState) {
                case "list":
                    this.isSaving = false;
                    this.isSearching = undefined;
                    this.editMode = false;
                    this.model = undefined;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.tipoSoglia = 0;
                    this.codArt = undefined;
                    this.formSoglie.$setPristine();
                    break;
            }

            setTimeout(() => $(".datetime").datepicker(), 500);
        }

        public leggi(id: number) {
            this.modifica(id);
        }

        public cancella(id: number) {
            this.msgFactory.confirm("Eliminare la soglia selezionata?").then(
                result => {
                    if (!result.result)
                        return;

                    this.doCancella(id);
                });
        }

        private doCancella(id: number): void {
            this.soglieConsumiFactory.cancella(id).then(
                result => {
                    this.dtFactory.aggiornaRicerca();
                },
                error => {
                    console.log("Errore", error);
                    this.msgFactory.error(error.toString());
                });
        }

        public nuovo() {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.model = <SoglieConsumi.VwSoglieConsumi>{};
            this.detail = <VwSoglieConsumiDetails>{};
            this.changeState("manipola");
            this.editMode = false;
        }

        public modifica(id: number) {
            this.changeState('manipola');
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.editMode = true;
            this.soglieConsumiFactory.get(id).then(
                result => {
                    this.model = result;
                    this.tipoSoglia = this.isSpecific ? 1 : 0;
                });
        }

        public aggiungiGruppo() {
            if (!this.model.Details)
                this.model.Details = [];

            if (!this.detail.IdRaggruppamento) {
                this.msgFactory.error("Selezionare un gruppo materiali");
                return;
            }
            if (!this.detail.Qta || this.detail.Qta <= 0 || isNaN(this.detail.Qta)) {
                this.msgFactory.error("Immettere una quantità valida");
                return;
            }
            let r = this.gruppiMateriali.find(el => el.Id == this.detail.IdRaggruppamento);
            this.detail.Raggruppamento = r.Descrizione;

            var exists = this.model.Details.find((r: VwSoglieConsumiDetails) => r.IdRaggruppamento == this.detail.IdRaggruppamento);
            if (exists) {
                this.msgFactory.confirm(
                    `Il gruppo materiali ${r.Descrizione} è già presente nella lista. Sostituirlo con l'attuale o annullare l'inserimento?`,
                    undefined,
                    undefined,
                    'sostituisci',
                    'annulla'
                ).then(
                    result => {
                        if (!result.result)
                            return;
                        let idx = this.model.Details.indexOf(exists);
                        this.model.Details.splice(idx, 1);
                        this.doAggiungiGruppo();
                    });
                return;
            }

            this.doAggiungiGruppo();
        }

        private doAggiungiGruppo() {
            this.model.Details.push(this.detail);
            this.detail = <VwSoglieConsumiDetails>{};
        }

        public cancellaDetail(item) {
            this.msgFactory.confirm("Eliminare questo elemento?").then(
                result => {
                    if (!result.result)
                        return;

                    this.model.Details.splice(item, 1);
                });
        }

        public ricercaCodice() {
            if (!this.codArt || this.codArt == "") {
                this.msgFactory.error("Immettere un codice valido");
                return;
            }
            this.soglieConsumiFactory.ricercaItemByCodice(this.codArt).then(
                result => {
                    this.items = result;
                });
        }

        public backToList(): void {
            this.changeState('list');
        }

        public annulla() {
            this.msgFactory.confirm("Annullare le modifiche e tornare alla lista?").then(
                result => {
                    if (!result.result)
                        return;
                    this.backToList();
                });
        }

        public registra() {
            this.showValidationMessages = false;
            if (!this.formSoglie.$valid) {
                this.showValidationMessages = true;
                this.formSoglie.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            if (!this.model.Details || this.model.Details.length <= 0) {
                this.msgFactory.error("Non è possibile registrare soglie senza dettagli associati");
                return;
            }

            if (this.tipoSoglia == 0) {
                this.model.IdGruppo = undefined;
                this.model.IdSottogruppo = undefined;
                this.model.IdSede = undefined;
                this.model.IdCentroCosto = undefined;
            }

            this.soglieConsumiFactory.exists(this.model).then(
                result => this.doRegistra(result));
        }

        private doRegistra(sostituisci: boolean = false) {
            let msg = sostituisci
                ? this.localeFactory.get('conferma_sostituzione')
                : this.localeFactory.get('conferma_registrazione');
            this.msgFactory.confirm(msg).then(
                result => {
                    if (!result.result)
                        return;
                    this.isSaving = true;
                    if (this.editMode)
                        this.doModifica(sostituisci);
                    else
                        this.doSalva(sostituisci);
                });
        }

        private doSalva(sostituisci: boolean): void {
            this.soglieConsumiFactory.crea(this.model, sostituisci).then(
                result => {
                    this.formSoglie.$setPristine();
                    this.isSaving = false;
                    this.model = result;
                    this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                },
                error => {
                    this.isSaving = false;
                    console.log("Errore", error);
                });
        }

        private doModifica(sostituisci: boolean): void {
            this.soglieConsumiFactory.modifica(this.model, sostituisci).then(
                result => {
                    this.formSoglie.$setPristine();
                    this.isSaving = false;
                    this.model = result;
                    this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                },
                error => {
                    this.isSaving = false;
                    console.log("Errore", error);
                });
        }
        
        public formatDate(data: Date, format: string = "DD/MM/YYYY"): string {
            if (data == null) data = new Date();
            var result = moment(data).format(format);
            return result;
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            if (this.isSearching)
                return;

            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.ricercaVm = ricerca;
            this.soglieConsumiFactory.ricerca(ricerca, page).then(
                result => {
                    this.isSearching = undefined;
                    var r = result;
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>result;
                    this.listaSoglie = result.List;
                },
                error => {
                    this.isSearching = undefined;
                });
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.model.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdGruppo", (newVal: number, oldVal: number) => {
                if (oldVal == undefined && newVal == undefined) return;
                this.gruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.model.IdSottogruppo", (newVal: number, oldVal: number) => {
                this.sottoGruppoChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if (oldVal == undefined && newVal == undefined) return;
                this.sottoGruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.model.IdSede", (newVal: number, oldVal: number) => {
                this.sedeChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSede", (newVal: number, oldVal: number) => {
                if (oldVal == undefined && newVal == undefined) return;
                this.sedeRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdCentroCosto", (newVal: number, oldVal: number) => {
                if (oldVal == undefined && newVal == undefined) return;
                this.dtFactory.aggiornaRicerca();
            });
        }

        public gruppoChanged(idGruppo: number): void {
            this.sottoGruppi = [];

            if (idGruppo == undefined) return;
            this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppi = risultato;
            });
        }

        public sottoGruppoChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];

            if (idSottogruppo == undefined) return;
            this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                this.sedi = risultato;
            });
        }

        public sedeChanged(idSede: number): void {
            this.centriCosto = [];

            if (idSede == undefined) return;
            this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                this.centriCosto = risultato;
            });
        }

        public gruppoRicercaChanged(idGruppo: number): void {
            this.sottoGruppiRicerca = [];
            if (idGruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                    this.sottoGruppiRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sottoGruppoRicercaChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];
            if (idSottogruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                    this.sediRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sedeRicercaChanged(idSede: number): void {
            this.centriCosto = [];
            if (idSede == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                    this.centriCostoRicerca = risultato;
                });
            }
        }

        private get isSpecific(): boolean {
            if (!this.model)
                return false;

            return this.model.IdGruppo != undefined ||
                this.model.IdSottogruppo != undefined ||
                this.model.IdSede != undefined ||
                this.model.IdCentroCosto != undefined;
        }
    }
}
app.controller("soglieConsumiController", Trace.SoglieConsumi.soglieConsumiController);