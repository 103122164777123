app.factory("movimentiFactory", ($http, $q) => new Trace.Movimenti.movimentiFactory($http, $q));
module Trace.Movimenti {
    export class movimentiFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Movimenti/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, currentPage: number = undefined): ng.IPromise<MovimentiPaginationVm> {
            var workUrl = this.GetBaseUrl() + "Ricerca/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ReadExcelInventario(item: ViewModels.ReadExcelItem): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "ReadExcelInventario";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, item).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Tipi(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "Tipi";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Stati(idTipoStato: number): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "Stati/" + idTipoStato.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public TipiMateriali(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "TipiMateriali";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Registra(movimento: MovimentoRigaVm): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, movimento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RegistraRichiestaApprovvigionamento(movimento: MovimentoRigaVm): ng.IPromise<number> {
            var workUrl = appRoot + "api/RichiesteApprovvigionamenti/";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, movimento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public leggi(id: number): ng.IPromise<MovimentoVm> {
            var workUrl = this.GetBaseUrl() + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SetStato(id: number, codice: string): ng.IPromise<boolean> {
            if (id == 0) {
                alert("Brutto ID: " + id.toString());
                return null;
            }
            var workUrl = this.GetBaseUrl() + "SetStatoMovimentoDetail/" + codice;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, id).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public DettagliMateriale(query: QueryDettaglioMovimenti): ng.IPromise<Movimenti.VwDettaglioMovimentoMateriale[]> {
            var workUrl = this.GetBaseUrl() + "DettagliMateriale";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, query).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public getDepositoProvenienza(idDestinazione: number, idTipoMateriale: number = undefined): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl() + "DepositoProvenienza/" + idDestinazione;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idTipoMateriale).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetTipiProvenienze(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "GetTipiProvenienze";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetProvenienze(idTipoProvenienza: number, idFornitore: number = undefined): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "GetProvenienze/" + idTipoProvenienza;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idFornitore).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetMaterialiProvenienza(idTipoProvenienza: number, idProvenienza: number): ng.IPromise<any[]> {
            var workUrl = this.GetBaseUrl() + "GetMaterialiProvenienza/" + idTipoProvenienza;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idProvenienza).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}