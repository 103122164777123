
module Trace.Flusso { 
    export class RicercaFlusso {
        public Codice: string;
        public Descrizione_it: string;
        public Descrizione_en: string;
        public Descrizione_al: string;
        public IdCentrale: number;
        public IdTipoSet: number;
    }

    export class flussoController {
        public lista: flussoVm[];
        public recordCorrente: flussoVm;
        public testoAzione: string;
        public centraliRicerca: Centrale.Centrale[];
        public centrali: Helpers.SimpleEntity[];
        public tipiSetRicerca: Set.TipoSetVm[];
        public tipiSet: Helpers.SimpleEntity[];
        public ricerca: RicercaFlusso;
        public dettaglioCorrente: flussoDetailVm;
        public testoAzioneDettaglio: string;
        public listaDettagli: flussoDetailVm[];

        public fasiDettaglio: Helpers.SimpleEntity[];
        public tipiMacchinaDettaglio: Helpers.SimpleEntity[];

        public showValidationMessages: boolean;
        public formFlussi: any;
        public formFlussiDettagli: any;

        public currentState: string;
        public titoloManipola: string;

        static $inject = ['$scope', 'flussoFactory', 'centraliFactory', 'setFactory', 'fasiFactory', 'macchineFactory', 'localeFactory',
            'msgFactory', 'dtFactory'];
        constructor(private $scope: ng.IScope, private flussoFactory: flussoFactory, private centraliFactory: Centrale.centraliFactory,
            private setFactory: Set.setFactory, private fasiFactory: Fase.fasiFactory, private macchinaFactory: Macchina.macchineFactory,
            private localeFactory: Localization.localeFactory, private msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory) {

            centraliFactory.asSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.centrali = risultato;
            });


            centraliFactory.lista().then((risultato: Centrale.Centrale[]) => {
                this.centraliRicerca = risultato;

                var dummy: Centrale.Centrale = new Centrale.Centrale();
                dummy.Descrizione_it_IT = '';
                this.centraliRicerca.unshift(dummy);
            });

            setFactory.listaTipiSetAsSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.tipiSet = risultato;
            });

            setFactory.listaTipiSet().then((risultato: Set.TipoSetVm[]) => {
                this.tipiSetRicerca = risultato;

                var dummy: Set.TipoSetVm = new Set.TipoSetVm();
                dummy.Descrizione_it_IT = '';
                this.tipiSetRicerca.unshift(dummy);
            });

            fasiFactory.asSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.fasiDettaglio = risultato;
            });

            macchinaFactory.listaTipiAsSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.tipiMacchinaDettaglio = risultato;
            });

            flussoFactory.lista().then((risultato: flussoVm[]) => {
                this.lista = risultato;
            });
            this.changeState('list');
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formFlussi.$valid) {
                this.showValidationMessages = true;
                this.formFlussi.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {

                    if (this.recordCorrente.Id == undefined)
                        this.flussoFactory.inserisci(this.recordCorrente).then((result => {
                            this.annulla();
                        }));
                    else
                        this.flussoFactory.modifica(this.recordCorrente).then((result => {
                            this.annulla();
                        }));
                }
            });

        }

        public annulla(): void {
            this.recordCorrente = new flussoVm();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.ricerca = new RicercaFlusso();

            this.flussoFactory.lista().then((risultato: flussoVm[]) => {
                this.lista = risultato;
                this.changeState('list');
            });

        }

        public cancella(flusso: Flusso): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.flussoFactory.cancella(flusso.Id).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        public modifica(flussoVm: flussoVm): void {
            this.flussoFactory.leggi(flussoVm.Id).then((flusso: flussoVm) => {
                this.recordCorrente = flusso;
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.changeState('manipola');
            });
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: ViewModels.DirezioneSort, filtro: Array<ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<ViewModels.SortRigaVm>();
            ricerca.sort.push(new ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.flussoFactory.ricerca(ricerca).then((risultato: flussoVm[]) => {
                this.lista = risultato; 
            });
        }

        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = new flussoVm();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formFlussi.$setPristine();
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaFlusso();
        }

        public cancellaDistinta(dettaglio: flussoDetailVm): void {
            if (this.recordCorrente != undefined && this.recordCorrente.Distinta != undefined) {
                var posizione = this.recordCorrente.Distinta.indexOf(dettaglio);
                this.recordCorrente.Distinta.splice(posizione, 1);
            }
        }

        public aggiungiDistinta(d: flussoDetailVm): void {
            if (d.Ordinamento == undefined) {
                this.msgFactory.alert(this.localeFactory.get("write_ordinamento"));
                return;
            }

            var presente: boolean = false;
            if (this.recordCorrente.Distinta != undefined) {
                $.each(this.recordCorrente.Distinta, (index: number, item: flussoDetailVm) => {
                    if (item.Ordinamento === d.Ordinamento) {
                        presente = true;
                        this.msgFactory.alert(this.localeFactory.get("exists_same_number"));
                        return;
                    }
                });
            }
            else {
                this.recordCorrente.Distinta = new Array<flussoDetailVm>();
            }

            if (presente) return;
            if (this.recordCorrente.Id != undefined)
                d.IdFlusso = this.recordCorrente.Id;
            if (d.IdFase != undefined) {
                this.fasiDettaglio.forEach((fase: Helpers.SimpleEntity) => {
                    if (fase.Id == d.IdFase)
                        d.Fase = fase.Descrizione;
                });
            }
            if (d.IdMacchina != undefined) {
                this.tipiMacchinaDettaglio.forEach((tipo: Helpers.SimpleEntity) => {
                    if (tipo.Id == d.IdMacchina)
                        d.TipoMacchina = tipo.Descrizione;
                });
            }

            this.recordCorrente.Distinta.push(d);
            this.dettaglioCorrente = new flussoDetailVm();
        }

    }
}

app.controller("flussoController", Trace.Flusso.flussoController);