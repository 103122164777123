module Trace {
    export enum TipoSet {
        SFUSO = 4,
        SFUSO_IN_BUSTA = 9,
        IN_TRANSITO = 5,
        SFUSO_BASSA_TEMP = 10
    }

    export enum TipoTrattamento {
        ULTRASUONI = 1,
        LAVAGGIOMANUALE = 2
    }

    export enum TipoMacchina {
        AUTOCLAVE = 1,
        LAVAFERRI = 2
    }

    export enum TipoBarcode {
        CONTAINER = 0,
        GRIGLIA1 = 1,
        GRIGLIA2 = 2,
        GRIGLIA3 = 3,
        SET = 4,
        NONE = 5,
        GRIGLIASFUSA = 6,
        EAN = 7,
        PRODOTTO = 8,
        TERMODISINFETTORE = 9,
        AUTOCLAVE = 10,
        CESTA = 11,
        EAN2 = 12,
        CENTROCOSTO = 13,
        CONSUMABILE_LOTTO = 14,
        TNT_LOTTO = 15
    }

    export enum TipoDocumento {
        DDT = 1
    }

    export enum TipoMovimento {
        CARICO = 2,
        SCARICO = 3,
        SET = 4
    }

    export enum TipoControparte {
        SET = 2,
        DOCUMENTO = 3,
        DEPOSITO = 4
    }

    export class Globals {
        static LimiteInferioreBarcodeSet: number = 0;
        static LimiteSuperioreBarcodeSet: number = 4;
        static LunghezzaBarcodeSet: number = 14;
        static LunghezzaBarcodeSetInBusta: number = 10;
        static LunghezzaBarcodeDettaglioSet: number = 15;
        static LunghezzaBarcodeGrigliaSfusa: number = 8;
        static LunghezzaBarcodeGrigliaSet: number = 8;
        static LunghezzaBarcodeCentroCosto: number = 10;
        static PrefissoGrigliaSfusa: string = "GR";
        static prefissoGrigliaSet: string = "GS";
        static PrefissoCesta: string = "CS";
        static PrefissoCentroCosto: string = "CC";
        static PrefissoConsumabileLotto: string = "CL";
        static PrefissoTntLotto: string = "TL";
        static PrefissoMaterialeSfuso: string = "SF";
        static UploadMaxFileSize: string = "2MB";
        static TimerRefreshAnalisi: number = 30000;
        static ScontoSaniService: number = 50;
    }
}