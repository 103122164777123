module Trace.Tracciabilita {
    export class tracciabilitaComposizioneSetController extends epBaseController {
        static $inject = ["$scope", "Upload", "tracciabilitaFactory", "utenteFactory", "prodottiFactory", "setFactory", "modelliSetFactory", "localeFactory", "msgFactory", "$uibModal", "tracciabilitaFactory"];
        private listaComposizioni: Array<VwComposizioneSet>;
        private listaMancanti: Array<VwStrumentiMancanti>;
        private lista
        private modelli: Array<Helpers.SimpleEntity>;
        private marche: Array<Helpers.SimpleEntity>;
        private formComposizioneSet: any;
        private filtro: FiltroTracciabilitaComposizioneSet;
        private prodottoCercato: Prodotto.ProdottoVm;
        private setCercato: Set.SetVm;

        constructor(
            private $scope: ng.IScope,
            public upload: ng.angularFileUpload.IUploadService,
            public tracciabilitaFactory: tracciabilitaFactory,
            public utenteFactory: Utente.utenteFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public setFactory: Set.setFactory,
            private modelliSetFactory: ModelliSet.modelliSetFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService
        ) {
            super(upload, utenteFactory, setFactory, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            this.formComposizioneSet = {};
            this.resetFiltro();
            this.getModelliSet();
            this.getMarche();
        }

        private init(): void {
            var vm = this;
            $(document).on("gotUserSession", (e: JQueryEventObject) => {
                if (tCodPrd == null) return;
                vm.filtro.CodiceProdotto = tCodPrd;
                vm.ricerca();
            });
        }

        private resetFiltro(): void {
            this.filtro = {
                CodiceProdotto: undefined,
                CodiceSet: undefined,
                IdCentroCosto: undefined,
                IdGruppo: undefined,
                IdModelloSet: undefined,
                IdSede: undefined,
                IdSottogruppo: undefined,
                IdMarca: undefined
            };
        }

        private getModelliSet(): void {
            this.modelliSetFactory.SimpleList().then(
                result => {
                    this.modelli = result;
                }
            );
        }

        private getMarche(): void {
            this.prodottiFactory.ListaMarche().then(
                result => {
                    this.marche = result;
                }
            );
        }

        private decodificaStrumento(codice: string): void {
            if (codice == undefined || $.trim(codice) == "") {
                this.prodottoCercato = undefined;
                return;
            }
            this.prodottiFactory.GetByCodice(codice).then(
                result => {
                    this.prodottoCercato = result;
                }
            );
        }

        private decodificaSet(codice: string): void {
            if (codice == undefined || $.trim(codice) == "") {
                this.setCercato = undefined;
                return;
            }
            this.setFactory.GetByCodice(codice).then(
                result => {
                    this.setCercato = result;
                }
            );
        }

        private svuotaCampi(): void {
            this.resetFiltro();
        }

        private ricerca(): void {
            this.listaComposizioni = undefined;
            this.listaMancanti = undefined;
            this.isSaving = true;
            var wait = 2;
            this.tracciabilitaFactory.ComposizioneSet(this.filtro).then(
                result => {
                    this.listaComposizioni = result;
                    wait--;
                    if (wait == 0)
                        this.isSaving = false;
                }
            );
            this.tracciabilitaFactory.StrumentiMancanti(this.filtro).then(
                result => {
                    this.listaMancanti = result;
                    wait--;
                    if (wait == 0)
                        this.isSaving = false;
                }
            );
        }

        public getExportUrl(): string {
            var result = appRoot + "Reports/ComposizioneSet?strFiltro=" + JSON.stringify(this.filtro);
            console.log("ecco il link", result);
            return result;
        }
    }
}
app.controller("tracciabilitaComposizioneSetController", Trace.Tracciabilita.tracciabilitaComposizioneSetController);