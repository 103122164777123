module Trace.Ordini {
    export class RicercaOrdiniVm {
        public Id: number;
        public DataCreazione: Date;
        public UtenteCreazione: string;
        public IdGruppo: number;
        public IdSottogruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public ModelloSet: string;
        public Numero: string;
        public Serie: string;
    }
}