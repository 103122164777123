module Trace.Accettazione {
    export class accettazioneController extends epProduzioneController {
        public ricerca: TipoIntervento.RicercaTipoIntervento;
        public causali: Array<Helpers.SimpleEntity>;
        public tipiInterventi: Array<TipoIntervento.VwTipoInterventoVm>;
        public tipoInterventoSelezionato: TipoIntervento.VwTipoInterventoVm;
        public interventi: Array<Intervento.IInterventoVm>;
        public intervento: Intervento.IInterventoVm;
        public formAccettazione: any;
        public formIntervento: any;
        private currentPage: number;
        public itemRicerca: Produzione.Materiale;
        public codiceRicerca: string;
        public searchByCodeNotFound: boolean;
        public ultimaImmagine: ViewModels.ImmagineVm;
        public gruppi: Trace.Gruppo.Gruppo[];
        public sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];
        private codiceAccettazioneTessile = "ACC-TESSILE";
        private codiceAccettazioneSfuso = "ACC-SFUSO";
        private codiceAccettazioneSfusoBT = "ACC-SFUSO-BT";
        public descrizioneSfuso: string;
        public censimentoSfusoOpened: boolean;
        private modalTipoInterventoOpened: boolean;
        private medici: Array<Helpers.SimpleEntity>;
        private flgNuovoMedico: boolean = false;
        private codice: string;

        static $inject = ["$rootScope", "$scope", "rotFactory","accettazioneFactory", "tipoInterventoFactory", "interventoFactory",
            "setFactory", "prodottiFactory", "tntFactory", "produzioneFactory", "gruppiFactory",
            "sottoGruppiFactory", "sediFactory", "centriCostoFactory", "localeFactory",
            "msgFactory", "$uibModal", "$document", "Upload", "utenteFactory", "tracciabilitaFactory"
        ];
        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private rotFactory: Trace.Rot.rotFactory,
            private accettazioneFactory: accettazioneFactory,
            public tipoInterventoFactory: TipoIntervento.tipoInterventoFactory,
            public interventoFactory: Intervento.interventoFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public tntFactory: Tnt.tntFactory,
            public produzioneFactory: Trace.Produzione.produzioneFactory,
            private gruppiFactory: Trace.Gruppo.gruppiFactory,
            private sottoGruppiFactory: Trace.SottoGruppo.sottoGruppiFactory,
            private sediFactory: Trace.Sede.sediFactory,
            private centriCostoFactory: Trace.CentroCosto.centroCostoFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public $document: any,
            public Upload: ng.angularFileUpload.IUploadService,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($rootScope, $scope, $uibModal, localeFactory, msgFactory, setFactory, prodottiFactory, tntFactory, produzioneFactory, 1, "accettazione", $document, Upload, utenteFactory, tracciabilitaFactory);
            this.formAccettazione = {};
            this.myRoot = <epRootScope>$rootScope;

            this.listaCausali();
            this.listaMedici();
            var vm = this;
            setTimeout(function () {
                vm.setUpAzioni();
            }, 300);

            Once($(document), "barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkEventHandler(e);
                return false;
            });
            Once($(document), "eanCode", (e: JQueryEventObject) => {
                this.eanCodeHandler(e);
                return false;
            });
            Once($(document), "urgenzaSet", (e: JQueryEventObject) => {
                this.listaFase();
            });
            Once($(document), "removeItemIntervento", (e: JQueryEventObject) => {
                this.removeItemIntervento(e.itemIntervento);
            });
            Once($(document), "cancellaLottoDaIntervento", (e: JQueryEventObject) => {
                this.cancellaLottoDaIntervento(e.idLotto);
            });
            Once($(document), "gestioneEscludiProduzione", (e: JQueryEventObject) => {
                this.gestioneEscludiProduzione(<any>e.lettura);
            });
            Once($(document), "imageUpload", (e: JQueryEventObject) => {
                if (e.image.baseUrl.indexOf("schedeintervento") != -1) {
                    vm.intervento.Immagine = e.image;
                    vm.ultimaImmagine = e.image;                    
                }
                else if (e.image.baseUrl.indexOf("fototransiti") != -1) {                    
                    if (vm.intervento.ImmaginiTransiti == null) vm.intervento.ImmaginiTransiti = [];
                    vm.intervento.ImmaginiTransiti.push(e.image);
                }
                vm.formAccettazione.$setDirty();
            });
            Once($(document), "tntLotto", (e: JQueryEventObject) => {
                this.tntLottoHandler(e);
            });
            this.setUpWatchers();
        }

        private tntLottoHandler(e: JQueryEventObject): void {
            var barcode = this.ultimaLetturaBarcode.barcode;
            this.eanCodeHandler(this.ultimaLetturaBarcode);
        }
        
        private gestioneEscludiProduzione(lotto: Trace.Produzione.LottoProduzioneVm) {
            if ((lotto.IdTipoSet == 5 && lotto.IdLottoProduzionePrecedente == undefined) || (lotto.IdTipoSet != 5 && lotto.IdSet != 483)) return;

            this.msgFactory.confirm(this.localeFactory.getFormatted(lotto.FlgEscludiProduzione ? "confirm_includi_produzione" : "confirm_escludi_produzione", [lotto.Descrizione_it_IT])).then(
                result => {
                    if (result.result) lotto.FlgEscludiProduzione = !lotto.FlgEscludiProduzione;
                }
            );
        }

        private listaMedici(): void {
            this.interventoFactory.Medici().then(
                result => {
                    this.medici = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                }
            );
        }

        public removeSet(item: Produzione.LottoProduzioneVm): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("conferma_rimozione_item_intervento", ["SET", item.Codice, this.descrizioneLotto(item)])).then(
                result => {
                    if (!result.result) return;
                    this.produzioneFactory.RipristinaUltimaFaseLottoProduzione(item.Id, true).then(
                        result => {
                            this.listaFase();
                        },
                        reason => {
                            this.msgFactory.error(this.localeFactory.get("errore_rimozione_item_intervento"));
                        }
                    )
                }
            );
        }

        public removeItemIntervento(item: Intervento.IInterventoDetailVm): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("conferma_rimozione_item_intervento", [item.Tipo, item.Codice, item.Descrizione])).then(
                result => {
                    if (!result.result) return;
                    this.interventoFactory.RemoveItem(item.Id).then(
                        result => {
                            this.listaFase();
                        },
                        reason => {
                            this.msgFactory.error(this.localeFactory.get("errore_rimozione_item_intervento"));
                        }
                    )
                }
            );
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.tipoInterventoSelezionato.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppi = undefined;
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSottogruppi(newVal, "");
            });
            this.$scope.$watch("vm.tipoInterventoSelezionato.IdSottogruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSedi(newVal, "");
            });
            this.$scope.$watch("vm.tipoInterventoSelezionato.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCosto = undefined;
                else this.getCentriCosto(newVal, "");
            });
        }

        private setUpAzioni(): void {
            //this.azioni.push(new Produzione.AzioneVm("removeSet", this.localeFactory.get("cancella"), "fa-times", "btn-default text-danger"));
            // this.azioni.push(new Produzione.AzioneVm("setUrgenza", this.localeFactory.get("set_urgente"), "fa-exclamation-triangle", "btn-secondary"));
            //this.azioni.push(new Produzione.AzioneVm("dettagliLotto", this.localeFactory.get("dettagli_lotto"), "fa-search", "btn-secondary"));
            //this.azioni.push(new Produzione.AzioneVm("cambiaCentroCosto", this.localeFactory.get("cambia_centrocosto_utilizzatore"), "fa-users", "btn-info"));
        }

        private cancellaLottoDaIntervento(idLotto: number) {
            //alert(idLotto);
            var intervento = this.interventi.filter(h => h.Lotti.filter(k => k.Id == idLotto).length > 0)[0];
            var lotto = intervento.Lotti.filter(h => h.Id == idLotto)[0];
            this.accettazioneFactory.CancellaLottoDaIntervento(idLotto).then(
                result => {
                    if (result) {
                        var index = intervento.Lotti.indexOf(lotto);
                        intervento.Lotti.splice(index, 1);
                        index = this.lista.indexOf(lotto);
                        this.lista.splice(index, 1);
                    }
                },
                error => { });
        }

        private listaCausali(): void {
            this.accettazioneFactory.Causali().then(
                result => {
                    this.causali = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                }
            );
        }

        public cambiaCentroCosto(lotto: Produzione.LottoProduzioneVm): void { }

        public dettagliLotto(lotto: Produzione.LottoProduzioneVm): void { }

        public listaFase(getInterventi: boolean = true): void {
            this.accettazioneFactory.Lista().then(
                result => {
                    this.lista = result;
                    //console.log(result);
                    //console.log(this.lista);
                    if (this.lista == undefined || this.lista.length <= 0) {
                        this.interventi = undefined;
                        this.intervento = undefined;
                        return;
                    }
                    if (getInterventi)
                        this.getInterventi();
                    else {
                        this.associaInterventiLotti();

                    }
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore _ 2", reason);
                }
            );
        }

        private getInterventi(): void {
            var interventi = new Array<number>();
            $.each(this.lista, (index, item) => {
                if (interventi.indexOf(item.IdIntervento) < 0)
                    interventi.push(item.IdIntervento);
            });
            this.interventoFactory.InterventiAccettazione(interventi).then(
                result => {
                    this.interventi = result;
                    if (result == undefined || result.length <= 0) return;
                    this.intervento = this.interventi[0];
                    this.associaInterventiLotti();
                }
            );
        }

        private associaInterventiLotti(): void {
            $.each(this.interventi, (index, item) => {
                $.each(this.lista, (idl, l) => {
                    if (l.IdIntervento === item.Id) {
                        if (item.Lotti == undefined) item.Lotti = new Array<Produzione.LottoProduzioneVm>();
                        item.Lotti.push(l);
                    }
                });
            });
            if (this.ultimaImmagine != undefined)
                this.intervento.Immagine = this.ultimaImmagine;
        }

        private accettaAssociati(idSet: number, idIntervento: number): void {
            this.accettazioneFactory.AccettaSetAssociati(idSet, idIntervento).then(
                result => {
                    var errori = $.grep(result, (elem, index) => {
                        return !elem.Result;
                    }).length > 0;

                    if (!errori) this.listaFase();
                    else {
                        var messaggio = this.localeFactory.get("accettazione_massiva_errori");
                        this.msgFactory.error(messaggio).then(
                            msgResult => {
                                this.listaFase();
                            }
                        );
                    }
                }
            );
        }

        private eanCodeHandler(e: JQueryEventObject | any): void {
            if (this.intervento == undefined) {

                var messaggio = "";

                if (this.interventi == undefined || this.interventi.length <= 0)
                    messaggio = this.localeFactory.get("creazione_intervento_error");
                else if (this.intervento == undefined)
                    messaggio = this.localeFactory.get("selezione_intervento_error");

                this.msgFactory.error(messaggio);
                return;
            }
            this.codiceRicerca = e.ean.CodiceProduttore;
            this.searchByCode(false, e.ean);
        }

        public barcodeOkEventHandler(e: JQueryEventObject): void {
            console.log("Accettazione barcodeOkEventHandler");
            if (this.intervento == undefined) {
                if (this.ultimaLetturaBarcode.tipo == TipoBarcode.CENTROCOSTO) {
                    this.getInfoCentroCosto(e.lettura.substr(3));
                    return;
                }

                var messaggio = "";
                if (this.interventi == undefined || this.interventi.length <= 0)
                    messaggio = this.localeFactory.get("creazione_intervento_error");
                else if (this.intervento == undefined)
                    messaggio = this.localeFactory.get("selezione_intervento_error");
                this.msgFactory.error(messaggio);
                return;
            }

            if (this.ultimaLetturaBarcode.tipo === TipoBarcode.PRODOTTO ||
                this.ultimaLetturaBarcode.tipo === TipoBarcode.EAN) {
                //if (this.ultimaLetturaBarcode.testo.length === 6) {// && this.ultimaLetturaBarcode.testo.substr(0, 1) == '0') {
                if (this.ultimaLetturaBarcode.testo.length >= 6 &&
                    this.ultimaLetturaBarcode.testo.length <= 7 &&
                    this.ultimaLetturaBarcode.testo.charAt(0) !== '0' &&
                    !isNaN(+this.ultimaLetturaBarcode.testo.charAt(0))) {
                    console.log("ho letto il lotto di un set", e);
                    this.produzioneFactory.BarcodeByLotto(this.ultimaLetturaBarcode.testo).then(
                        result => {
                            console.log("il codice a barre del lotto", this.ultimaLetturaBarcode.testo, result);
                            triggerBarcodeByLotto(result, this.ultimaLetturaBarcode.testo);
                            return;
                        },
                        reason => {
                            this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                            return;
                        }
                    );
                }
                if (this.ultimaLetturaBarcode.testo.length === 9 && !isNaN(parseInt(this.ultimaLetturaBarcode.testo))) {
                    console.log("ho letto il lotto di uno sfuso", e);
                    this.accettazioneFactory.AccettaLottoSfuso(this.intervento.Id, this.ultimaLetturaBarcode.testo).then(
                        result => {
                            console.log("lotto sfuso accettato", this.ultimaLetturaBarcode.testo, result);
                            if (this.censimentoSfusoOpened)
                                this.strumentoSfuso = result;
                            else
                                this.censimentoSfuso(result);
                            return;
                        },
                        reason => {
                            this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                            return;
                        }
                    );
                }
                console.log("ho letto un prodotto o un tnt", e);
                if (this.ultimaLetturaBarcode.tipo === TipoBarcode.EAN)
                    this.codiceRicerca = e.ean.CodiceProduttore;
                else
                    this.codiceRicerca = e.lettura
                this.searchByCode(true, e.ean);
            }
            else {
                var result = this.findInList(e.lettura);
                if (result) {
                    this.$scope.$apply();
                    return;
                }
                this.accettaSet(e.lettura, e.idLotto);
            }
        }

        public associazioneIntervento(): void {
            this.gruppiFactory.lista().then(
                result => {
                    console.log(result);
                    this.gruppi = result;
                    this.apriModalTipoIntervento();
                }
            );
        }

        private apriModalTipoIntervento(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Accettazione/modalTipiInterventi.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl",
                keyboard: false,
                backdrop: "static"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.opened.then(() => {
                vm.modalTipoInterventoOpened = true;
                vm.formIntervento = {};
                vm.aggiornaRicerca("Descrizione_it_IT", ViewModels.DirezioneSort.Ascendente, [], 0);
            });
            this.$modalInstance.result.then(() => {
                vm.modalTipoInterventoOpened = false;
                vm.tipoInterventoSelezionato = null;
            });
        }

        public addTipoIntervento(): void {
            this.showValidationMessages = false;
            if (!this.formIntervento.$valid) {
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                this.showValidationMessages = true;
                return;
            }
            if (this.interventi == undefined)
                this.interventi = new Array<Intervento.IInterventoVm>();

            console.log("Tipo di intervento selezionato", this.tipoInterventoSelezionato);

            var i = new Intervento.InterventoVm();
            //i.FlgImmagini = this.tipoInterventoSelezionato.FlgImmagini;
            
            if (this.tipoInterventoSelezionatoIsTessile()) {
                i.Codice = this.tipoInterventoSelezionato.Codice;
                i.Descrizione = this.localeFactory.getFormatted('descrizione_accettazione_tessile', [this.tipoInterventoSelezionato.DescrizioneTessile]);
                i.NumeroDdt = this.tipoInterventoSelezionato.NumeroDdt;
            }
            else if (this.tipoInterventoSelezionatoIsSfuso()) {
                i.Codice = this.tipoInterventoSelezionato.Codice;
            }
            else 
                i.Descrizione = this.localized(this.tipoInterventoSelezionato, "Descrizione_");

            if (this.tipoInterventoSelezionato.IdMedico != undefined) {
                var idMedico = this.tipoInterventoSelezionato.IdMedico;
                var medico = $.grep(this.medici, elem => {
                    return elem.Id == idMedico;
                })[0];
                i.Medico = medico.Descrizione;
            }
            else {
                i.Medico = this.tipoInterventoSelezionato.Medico;
            }

            i.IdCausaleUtilizzoSet = this.tipoInterventoSelezionato.IdCausaleUtilizzo;
            i.IdComplessita = this.tipoInterventoSelezionato.IdComplessita;
            i.IdMassimaComplessitaSet = this.tipoInterventoSelezionato.IdMassimaComplessitaSet;
            i.IdTipoIntervento = this.tipoInterventoSelezionato.Id;
            i.MassimoNumeroSet = this.tipoInterventoSelezionato.MassimoNumeroSet;
            i.IdGruppo = this.tipoInterventoSelezionato.IdGruppo;
            i.IdSottogruppo = this.tipoInterventoSelezionato.IdSottogruppo;
            i.IdSede = this.tipoInterventoSelezionato.IdSede;
            i.IdCentroCosto = this.tipoInterventoSelezionato.IdCentroCosto;
            i.IdMedico = this.tipoInterventoSelezionato.IdMedico;

            if (this.tipoInterventoSelezionato.FlgMocoma) {
                i.FlgMocoma = true;
                i.CodiceMocoma = this.tipoInterventoSelezionato.CodiceMocoma;
            }
            else i.FlgMocoma = false;

            this.interventoFactory.RegistraIntervento(i).then(
                result => {
                    this.interventi.push(result);
                    this.intervento = result;

                    // Lavorazione del tessile, oltre ad aggiungere un nuovo intervento, effettuo una nuova accettazione del set tessile
                    if (this.tipoInterventoSelezionatoIsTessile()) {
                        var accTessile = <AccettazioneMaterialeVm>{
                            Descrizione: this.tipoInterventoSelezionato.DescrizioneTessile,
                            NumeroDdt: this.tipoInterventoSelezionato.NumeroDdt,
                            IdIntervento: result.Id,
                            ListaTessili: new Array<AccettazioneTessileVm>(),
                            IdCentroCosto: undefined,
                            IdGruppo: undefined,
                            IdSede: undefined,
                            IdSottogruppo: undefined,
                            Qta: undefined
                        };

                        // Garze
                        if (this.tipoInterventoSelezionato.NumeroGarze > 0)
                            accTessile.ListaTessili.push(<AccettazioneTessileVm>{
                                Descrizione: "Garze",
                                Qta: this.tipoInterventoSelezionato.NumeroGarze
                            });
                        // Pezze
                        if (this.tipoInterventoSelezionato.NumeroPezze > 0)
                            accTessile.ListaTessili.push(<AccettazioneTessileVm>{
                                Descrizione: "Strisce",
                                Qta: this.tipoInterventoSelezionato.NumeroPezze
                            });
                        // Tamponi
                        if (this.tipoInterventoSelezionato.NumeroTamponi > 0)
                            accTessile.ListaTessili.push(<AccettazioneTessileVm>{
                                Descrizione: "Tamponi",
                                Qta: this.tipoInterventoSelezionato.NumeroTamponi
                            });

                        this.accettazioneFactory.AccettaMaterialeTessileModel(accTessile).then(
                            result => {
                                if (!result.Result) {
                                    this.msgFactory.error(this.localeFactory.getFormatted("accettazione_set_errore", [result.Message]));
                                    return;
                                }
                                this.listaFase();
                            },
                            reason => {
                                this.msgFactory.error(this.localeFactory.getFormatted("accettazione_set_errore", [reason]));
                            }
                        );
                    }
                    this.listaMedici();
                    this.flgNuovoMedico = false;
                    this.chiudiModal();
                }
            );
        }

        public selezionaTipoIntervento(item: TipoIntervento.VwTipoInterventoVm): void {
            if (this.tipoInterventoSelezionato == undefined || this.tipoInterventoSelezionato.IdGruppo == undefined) {
                this.tipoInterventoSelezionato = angular.copy(item);
                this.tipoInterventoSelezionato.IdGruppo = 1013;
                this.tipoInterventoSelezionato.IdSottogruppo = 1015;
                this.tipoInterventoSelezionato.IdSede = 1010;
                this.tipoInterventoSelezionato.IdCentroCosto = 1014;
            }
            else {
                var ti = angular.copy(this.tipoInterventoSelezionato);
                this.tipoInterventoSelezionato = angular.copy(item);
                this.tipoInterventoSelezionato.IdGruppo = ti.IdGruppo;
                this.tipoInterventoSelezionato.IdSottogruppo = ti.IdSottogruppo;
                this.tipoInterventoSelezionato.IdSede = ti.IdSede;
                this.tipoInterventoSelezionato.IdCentroCosto = ti.IdCentroCosto;
            }

            //this.tipoInterventoSelezionato.FlgImmagini = item.FlgImmagini;

            this.accettazioneFactory.GetNumeroDdtTessile().then(
                result => {
                    console.log(result);
                    console.log(this.tipoInterventoSelezionato);
                    if (this.tipoInterventoSelezionato == null)
                        return;

                    this.tipoInterventoSelezionato.NumeroDdt = result;
                    if (this.tipoInterventoSelezionato.FlgMocoma)
                        this.tipoInterventoSelezionato.CodiceMocoma = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("accettazione_set_errore", [reason]));
                });
        }

        public accettaSet(lettura: string, idLotto?:number): void {
            lettura = lettura.length === Globals.LunghezzaBarcodeDettaglioSet
                ? lettura.substr(0, Globals.LunghezzaBarcodeSet)
                : lettura;
            this.accettazioneFactory.AccettaSet(lettura, this.intervento.Id, idLotto).then(
                result => {
                    if (!result.Result) {
                        var messaggio: string;
                        if (result.ExceptionThrown)
                            messaggio = this.localeFactory.getFormatted("accettazione_set_errore", [result.Message]);
                        else {
                            switch (result.Message) {
                                case "nocentrale":
                                    messaggio = this.localeFactory.get("accettazione_errore_nocentrale");
                                    break;

                                case "noflusso":
                                    messaggio = this.localeFactory.get("accettazione_errore_noflusso");
                                    break;

                                case "fasenonaccettata":
                                    var a1 = this.localeFactory.get("descr_azione_1");
                                    var a2 = this.localeFactory.get("descr_azione_2");
                                    messaggio = this.localeFactory.getFormatted("accettazione_errore_fasenonaccettata", [a1, result.Descrizione, a2]);
                                    break;

                                default:
                                    messaggio = this.localeFactory.getFormatted("accettazione_set_errore", [result.Message]);
                                    break;
                            }
                        }
                        this.msgFactory.error(messaggio);
                        return;
                    }
                    if (result.Associati.TotContainer > 1) {
                        var messaggio = this.localeFactory.getFormatted("conferma_accettazione_associati", [result.Descrizione, result.Associati.NumContainer, result.Associati.TotContainer]);
                        this.msgFactory.confirm(messaggio).then(
                            msgResult => {
                                if (!msgResult.result) {
                                    this.listaFase();
                                    return;
                                }

                                this.accettaAssociati(result.IdSet, this.intervento.Id);
                            }
                        );
                    }
                    else this.listaFase();
                },
                reason => {
                    var status = reason.status;
                    switch (status) {
                        //case 404:
                        //    this.msgFactory.error(this.localeFactory.getFormatted("set_non_trovato_barcode", [lettura]));
                        //    break;
                        
                        case 400:
                            var messaggio: string = reason.data;
                            this.msgFactory.error(this.localeFactory.getFormatted("accettazione_set_errore", [lettura, messaggio]));
                            break;

                        case 500:
                            this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                            break;
                    }
                    console.log("errore", reason);
                }
            );
        }

        public checkCausaleUtilizzo(intervento: Intervento.InterventoVm): void {
            if (intervento != null && intervento.IdCausaleUtilizzoSet !== 1 && intervento.IdCausaleUtilizzoSet !== 6) {
                intervento.Data = undefined;
                intervento.StrData = undefined;
            }
        }

        public salvaDati(avanza: boolean = false): void {
            this.showValidationMessages = false;
            $.each(this.interventi, (idi, i) => {
                $.each(i.Lotti, (index, item) => {
                    item.IdCausaleUtilizzoSet = i.IdCausaleUtilizzoSet;
                    item.CodiceIntervento = (item.IdCausaleUtilizzoSet !== 1 && item.IdCausaleUtilizzoSet !== 6) ? null : i.Codice;
                    item.DataIntervento = (item.IdCausaleUtilizzoSet !== 1 && item.IdCausaleUtilizzoSet !== 6) ? null : i.Data;
                });
            });
            if (!this.formAccettazione.$valid) {
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                this.showValidationMessages = true;
                return;
            }
            if (!this.interventoSelezionatoIsTessile() && this.intervento.Immagine == undefined) {
                this.msgFactory.confirm(this.localeFactory.get('no_img_associata')).then(
                    result => {
                        if (!result.result) return;
                        this.doSalvaDati(avanza);
                    }
                );
            }
            else {
                this.doSalvaDati(avanza);
            }
        }

        private doSalvaDati(avanza: boolean = false): void {
            this.accettazioneFactory.SalvaDati(this.interventi).then(
                result => {
                    if (avanza != true) {
                        if (result == 0)
                            this.msgFactory.alert(this.localeFactory.get("salva_dati_ok"));
                        else if (result == 1)
                            this.msgFactory.alert(this.localeFactory.getFormatted("salva_dati_ok_re_intervento", [this.interventi[0].Codice]));
                        this.formAccettazione.$setPristine(); 
                        this.listaFase();
                    }                                       
                    else this.faseSuccessivaStep2();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                }
            );
        }

        public checkFaseSuccessiva(): boolean {
            return this.formAccettazione.$valid && this.formAccettazione.$pristine && this.lista != undefined && this.lista.length > 0;
        }

        public faseSuccessiva() {
            this.salvaDati(true);
        }

        public faseSuccessivaStep2(): void {
            this.showValidationMessages = false;
            if (!this.formAccettazione.$valid) {
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                this.showValidationMessages = true;
                return;
            }
            var setImgsTransitiMancanti = this.interventi.filter(h => h.FlgImmagini == true && (h.ImmaginiTransiti == null || h.ImmaginiTransiti.length < 1));

            if (setImgsTransitiMancanti != null && setImgsTransitiMancanti.length > 0) {
                this.msgFactory.confirm(this.localeFactory.get('no_img_transiti')).then(
                    result => {
                        if (!result.result) return;
                        this.scaricaInterventi();
                    }
                );
            }
            else this.scaricaInterventi();
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.tipoInterventoFactory.ricerca(ricerca).then(
                risultato => {
                this.tipiInterventi = risultato;
            });
        }

        public resetForm(): void {
            this.ricerca = new TipoIntervento.RicercaTipoIntervento();
        }

        public addMaterialeIntervento(reset: boolean = true): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Accettazione/modalAddMaterialeIntervento.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            if (reset) {
                vm.codiceRicerca = undefined;
                vm.itemRicerca = undefined;
            }
            this.$modalInstance = this.$uibModal.open(options);
        }

        public searchByCode(openModal: boolean = false, ean: ViewModels.EanCodeInfoVm = undefined): void {
            this.searchByCodeNotFound = false;
            this.itemRicerca = undefined;
            var ricerca = new Produzione.RicercaMaterialiVm(this.codiceRicerca, undefined, true);
            this.produzioneFactory.RicercaMateriali(ricerca, true).then(
                result => {
                    if (result == undefined || result.length <= 0) {
                        this.searchByCodeNotFound = true;
                        return;
                    }
                    this.itemRicerca = result[0];
                    if (ean != undefined) {
                        this.itemRicerca.Uid = ean.Originale;
                        this.itemRicerca.Lotto = ean.Lotto;
                        this.itemRicerca.DataScadenzaLotto = ean.DataScadenzaLotto;
                    }
                    if (openModal)
                        this.addMaterialeIntervento(false);
                    else
                        this.doAddMateriale();
                }
            );
        }

        public confermaAddMateriale(): void {
            if (this.itemRicerca.IdTipo != 5) this.doAddMateriale();
            else {
                this.rotFactory.CheckRotAccettatoMoreThanOne(this.itemRicerca.Codice).then(
                    result => {
                        if (result.MoreThanOne) {
                            this.msgFactory.confirm(this.localeFactory.get('confirm_accettazione_rot_piu_volte')).then(
                                result => {
                                    if (!result.result) return;
                                    this.doAddMateriale();
                                }
                            );
                        }
                        else this.doAddMateriale();
                    },
                    error => { }
                );
            }
            //this.msgFactory.confirm(this.localeFactory.getFormatted('add_materiale_conferma', [this.itemRicerca.Tipo, this.itemRicerca.Codice, this.itemRicerca.Descrizione, this.intervento.Descrizione])).then(
            //    result => {
            //        if (!result.result) return;
            //        this.doAddMateriale();
            //    }
            //);
        }

        private doAddMateriale(): void {
            this.interventoFactory.AddMateriale(this.intervento.Id, this.itemRicerca).then(
                result => {
                    this.chiudiModal();
                    this.listaFase();
                },
                reason => {
                    console.log(reason);
                    this.msgFactory.error(JSON.stringify(reason));
                }
            );
        }

        public scaricaInterventi(): void {
            // lista di interventi da scaricare
            var lista = new Array<Trace.Helpers.SimpleEntity>();
            $.each(this.interventi, (index, item) => {
                lista.push(<Trace.Helpers.SimpleEntity>{
                    Descrizione: item.Box,
                    Id: item.Id
                });
            });

            this.interventoFactory.AggiornaComplessita(lista).then(
                result => {
                    this.interventoFactory.ScaricaMateriali(lista).then(
                        result => {
                            this.doFaseSuccessiva();
                        },
                        reason => {
                            console.log(reason);
                        }
                    );
                }
            );
        }

        private doFaseSuccessiva(): void {
            var lotti: Array<number> = new Array<number>();
            $.each(this.lista, (index, item) => {
                lotti.push(item.Id);
            });

            this.produzioneFactory.CambiaFasiLottiProduzione(lotti).then(
                result => {
                    console.log("faseSuccessiva", result);
                    if (!result.Result) {
                        var messaggio: string;
                        if (result.ExceptionThrown)
                            messaggio = this.localeFactory.getFormatted("accettazione_set_errore", [result.Message]);
                        else {
                            switch (result.Message) {
                                case "nocentrale":
                                    messaggio = this.localeFactory.get("accettazione_errore_nocentrale");
                                    break;

                                case "noflusso":
                                    messaggio = this.localeFactory.get("accettazione_errore_noflusso");
                                    break;

                                case "fasenonaccettata":
                                    var a1 = this.localeFactory.get("descr_azione_1");
                                    var a2 = this.localeFactory.get("descr_azione_2");
                                    messaggio = this.localeFactory.getFormatted("accettazione_errore_fasenonaccettata", [a1, result.Descrizione, a2]);
                                    break;

                                default:
                                    messaggio = this.localeFactory.getFormatted("accettazione_set_errore", [result.Message]);
                                    break;
                            }
                        }
                        this.msgFactory.error(messaggio);
                        return;
                    }
                    this.msgFactory.alert(this.localeFactory.get("accettazione_set_ok"));
                    this.ultimaImmagine = undefined;
                    this.listaFase();
                },
                reason => {
                    console.log(reason);
                    var status = reason.status;
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore", reason);
                }
            );
        }

        public tipoInterventoSelezionatoIsTessile(): boolean {
            return this.tipoInterventoSelezionato != undefined && this.tipoInterventoSelezionato.Codice === this.codiceAccettazioneTessile;
        }

        public tipoInterventoSelezionatoIsStandard(): boolean {
            var result = !(this.tipoInterventoSelezionatoIsTessile() || this.tipoInterventoSelezionatoIsSfuso());
            console.log("int.standard?", result);
            return result;
        }

        public tipoInterventoSelezionatoIsSfuso(): boolean {
            return this.tipoInterventoSelezionato != undefined && (this.tipoInterventoSelezionato.Codice === this.codiceAccettazioneSfuso || this.tipoInterventoSelezionato.Codice === this.codiceAccettazioneSfusoBT);
        }

        public interventoSelezionatoIsTessile(): boolean {
            return this.intervento != undefined && this.intervento.Codice === this.codiceAccettazioneTessile;
        }

        public interventoSelezionatoIsSfuso(): boolean {
            var result = this.intervento != undefined && (this.intervento.Codice === this.codiceAccettazioneSfuso || this.intervento.Codice === this.codiceAccettazioneSfusoBT);
            return result;
        }

        // Registra il materiale sfuso e i transiti
        public registraMateriale(): void {
            //var vm = this;
            this.msgFactory.confirm(this.localeFactory.get("registrazione_materiale_conferma")).then(
                result => {
                    if (!result.result) return;
                    console.log("intervento", this.intervento);

                    var testata = new Accettazione.AccettazioneMateriale();
                    testata.Descrizione = this.descrizioneSfuso;
                    testata.IdCentroCosto = this.intervento.IdCentroCosto;
                    testata.IdGruppo = this.intervento.IdGruppo;
                    testata.IdIntervento = this.intervento.Id;
                    testata.IdSede = this.intervento.IdSede;
                    testata.IdSottogruppo = this.intervento.IdSottogruppo;
                    testata.Qta = this.somma(this.strumentiSfusi, "Qta");

                    // #LUCA 08.11.2020 per gestire la registrazione degli sfusi a bassa temperatura, così lato server verrà associato il set corretto
                    if (this.intervento.Codice == 'ACC-SFUSO-BT' && this.flgModelloSfuso == undefined) this.flgModelloSfuso = "SFB00000001"; // SFUSI A BASSA TEMPERATURA

                    if (this.flgModelloSfuso == undefined) this.flgModelloSfuso = "SF00000001";

                    var accettazione = <Accettazione.IAccettazioneSfuso>{
                        Testata: testata,
                        Strumentario: this.strumentiSfusi,
                        CodiceSet: this.flgModelloSfuso,
                        ImmaginiTransiti: this.intervento.ImmaginiTransiti,
                        //FlgCreazioneSet: this.intervento.FlgCreazioneSet
                    };                    
                    this.flgModelloSfuso = undefined;

                    if (this.intervento.FlgCreazioneSet) {
                        this.accettazioneFactory.AccettaMaterialeTransito(accettazione).then(
                            result => {
                                this.msgFactory.alert(this.localeFactory.get("registrazione_materiale_ok"));
                                this.descrizioneSfuso = "";
                                this.chiudiModal();
                                this.listaFase();
                                this.censimentoSfusoOpened = false;
                            },
                            reason => { }
                        );
                    }
                    else {
                        this.accettazioneFactory.AccettaMaterialeSfuso(accettazione).then(
                            result => {
                                this.msgFactory.alert(this.localeFactory.get("registrazione_materiale_ok"));
                                this.descrizioneSfuso = "";
                                this.chiudiModal();
                                this.listaFase();
                                this.censimentoSfusoOpened = false;
                            },
                            reason => { }
                        );
                    }
                }
            );
        }

        public censimentoSfuso(strumento?: Prodotto.StrumentoSfuso): void {
            this.strumentiSfusi = undefined;
            this.strumentiDb = undefined;
            this.strumentoSfuso = strumento;
            this.descrizioneSfuso = this.localeFactory.getFormatted("nome_" + (this.intervento.IdTipoIntervento == 1066 ? "transito" : this.intervento.Codice == "ACC-SFUSO-BT" ? "sfuso_bt" : "sfuso") + "_default", [this.intervento.CentroCosto, this.intervento.Gruppo]);
            this.censimentoSfusoOpened = true;
            super.censimentoSfuso();
        }

        public modelloSfusoSelected(id: number, descrizione: string, codice: any): void {
            this.descrizioneSfuso = this.localeFactory.getFormatted("set_sfuso_default", [descrizione, this.intervento.CentroCosto, this.intervento.Gruppo]);
            super.modelloSfusoSelected(id, descrizione, codice);
        }

        public aggiungiStrumentoSfuso(): boolean {
            return super.aggiungiStrumentoSfuso(this.intervento.IdCentroCosto, this.intervento.FlgCreazioneSet);;         
        }

        public associaBox(idIntervento: number): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Accettazione/selezioneBox.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };

            this.$modalInstance = this.$uibModal.open(options);
        }

        public confermaAssociazioneBox(): void {
            this.formAccettazione.$setDirty();
            this.chiudiModal();
        }

        public readBarcode(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Accettazione/modalReadBarcode.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md",
                keyboard: false,
                backdrop: "static"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.opened.then(() => {
                vm.codice = "";
            });
            this.$modalInstance.result.then(() => {
                vm.codice = "";
            });
        }

        public leggiCodice(): void {
            if (this.codice == undefined || this.codice.trim() == "")
                return;
            $("footer>#barcodeReader").val(this.codice);
            letturaBarcodeEseguita();
        }

        ////////////////////////////////////////////////////
        ///////////////// GET //////////////////////////////
        ////////////////////////////////////////////////////

        public getScansioneSchedaIntervento(): void {
            this.interventoFactory.GetInterventiDaScansioni().then(
                result => {
                    if (result == undefined || result.length <= 0) {
                        this.msgFactory.error(this.localeFactory.get('no_interventi_da_scansione'));
                        return;
                    }
                    var lotti = new Array<AccettazioneLottoVm>();
                    $.each(result, (intId, int) => {
                        $.each(int.Dettagli, (dIntId, dettInt) => {
                            if (dettInt.IdLottoProduzione != undefined) {
                                var l = new AccettazioneLottoVm();
                                l.IdIntervento = int.Id;
                                l.IdLotto = dettInt.IdLottoProduzione;
                                lotti.push(l);
                            }
                        });
                    });

                    console.log("devo accettare", lotti);

                    this.accettazioneFactory.AccettazioneMassivaLotti(lotti).then(
                        result => {
                            if (result.Result) {
                                this.listaFase(false);
                            }
                            else {
                                this.msgFactory.error(result.Message);
                            }
                        }
                    );
                    this.interventi = result;
                },
                reason => {
                    if (reason.status === 404) {
                        this.msgFactory.alert(this.localeFactory.get("no_scansione_intervento"));
                    }
                    //console.log(reason);
                }
            );
        }

        private getGruppi(tipo: string = undefined): void {
            this.gruppiFactory.lista().then(
                result => {
                    this.gruppi = result;
                }
            );
        }

        private getSottogruppi(idGruppo: number, tipo: string = undefined): void {
            this.sottoGruppiFactory.listaGruppo(idGruppo).then(
                result => {
                    this.sottoGruppi = result;
                }
            );
        }

        private getSedi(idSottogruppo: number, tipo: string = undefined): void {
            this.sediFactory.listaSottoGruppo(idSottogruppo).then(
                result => {
                    this.sedi = result;
                }
            );
        }

        private getCentriCosto(idSede: number, tipo: string = undefined): void {
            this.centriCostoFactory.listaSede(idSede).then(
                result => {
                    this.centriCosto = result;
                }
            );
        }

        private getInfoCentroCosto(codice: string): void {
            this.getGruppi();
            this.centriCostoFactory.getByCodice(codice).then(
                cc => {
                    if (this.tipoInterventoSelezionato == undefined)
                        this.tipoInterventoSelezionato = new TipoIntervento.VwTipoInterventoVm();

                    this.tipoInterventoSelezionato.IdGruppo = cc.IdGruppo;
                    this.tipoInterventoSelezionato.IdSottogruppo = cc.IdSottogruppo;
                    this.tipoInterventoSelezionato.IdSede = cc.IdSede;
                    this.tipoInterventoSelezionato.IdCentroCosto = cc.Id;

                    if (this.modalTipoInterventoOpened !== true)
                        this.apriModalTipoIntervento();
                },
                error => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                }
            );
        }

    }
} 
app.controller("accettazioneController", Trace.Accettazione.accettazioneController);