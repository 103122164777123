module Trace.RichiesteApprovvigionamenti {
    export class richiesteApprovvigionamentiController extends Movimenti.epMovimentiController {
        static $inject = ["$rootScope", "$scope", "dtFactory", "produzioneFactory", "richiesteApprovvigionamentiFactory",
            "movimentiFactory", "documentoFactory", "setFactory", "prodottiFactory",
            "rotFactory", "tntFactory", "depositoFactory", "utenteFactory", "localeFactory",
            "msgFactory", "$uibModal", "gruppiFactory", "sottoGruppiFactory",
            "sediFactory", "centriCostoFactory", "ordiniFactory", "tracciabilitaFactory"
        ];

        private richieste: Array<Movimenti.MovimentoVm>;
        private formRichiestaApprovvigionamento: any;
        private numElementiRichiesti: number;
        private numRigheRichiesta: number;

        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            public dtFactory: Trace.datatables.dtFactory,
            private produzioneFactory: Produzione.produzioneFactory,
            public richiesteApprovvigionamentiFactory: richiesteApprovvigionamentiFactory,
            public movimentiFactory: Movimenti.movimentiFactory,
            public documentoFactory: Documento.documentoFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            private rotFactory: Rot.rotFactory,
            public tntFactory: Tnt.tntFactory,
            public depositoFactory: Deposito.depositoFactory,
            public utenteFactory: Utente.utenteFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public gruppiFactory: Gruppo.gruppiFactory,
            public sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            public sediFactory: Sede.sediFactory,
            public centroCostoFactory: CentroCosto.centroCostoFactory,
            public ordiniFactory: Ordini.ordiniFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($scope, $rootScope, $uibModal, dtFactory, localeFactory, msgFactory, setFactory, prodottiFactory, tntFactory,
                depositoFactory, utenteFactory, movimentiFactory, ordiniFactory, documentoFactory,
                gruppiFactory, sottoGruppiFactory, sediFactory, centroCostoFactory, tracciabilitaFactory);

            var vm = this;
            this.formRichiestaApprovvigionamento = {};
            $(document).off("gotUserSession").on("gotUserSession", (event) => {
                vm.getDepositi();
            });
            $(document).on("barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkHandler(e);
            });
            $(document).on("eanCode", (e: JQueryEventObject) => {
                this.eanCodeHandler(e);
            });
            $(document).on("tntLotto", (e: JQueryEventObject) => {
                this.tntLottoHandler(e);
            });
        }

        private init(): void {
            this.changeState('list');
        }

        private getDepositi(): void {
            if (this.applicationSession.FlgRichestaApprovvigionamento !== true) return;
            this.depositoFactory.listaByTipo([3, 4]).then(
                result => {
                    this.depositi = result;
                }
            );
        }
    
        public changeState(stato: string): void {
            super.changeState(stato);
            this.dtFactory.aggiornaRicerca();
        }

        private aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.richiesteApprovvigionamentiFactory.ricerca(ricerca, page).then(
                risultato => {
                    var r = risultato;
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                    this.richieste = r.List;
                }
            );
        }

        private leggi(id: number): void {
            var vm = this;
            this.richiesteApprovvigionamentiFactory.leggi(id).then(
                result => {
                    this.movimento = result;
                    this.depositoFactory.leggi(this.movimento.Provenienza.Id).then(
                        result => {
                            this.depositoProvenienzaSelezionato = result;
                        }
                    );
                    this.depositoFactory.leggi(this.movimento.Destinazione.Id).then(
                        result => {
                            this.depositoDestinazioneSelezionato = result;
                        }
                    );
                    this.materiali = this.getMaterialiFromRigheRichiesta(result.RichiestaDetails);
                    this.numElementiRichiesti = 0;
                    this.numRigheRichiesta = this.materiali.length;
                    $.each(this.materiali, (index, item) => {
                        vm.numElementiRichiesti += item.Qta;
                    });
                    this.changeState('manipola');
                }
            );
        }

        private deleteRiga(item: Produzione.MaterialeVm): void {
            var msg = this.localeFactory.getFormatted("conferma_rimozione_riga", [item.Descrizione]);
            this.msgFactory.confirm(msg).then(
                result => {
                    if (!result.result) return;
                    this.doDeleteRiga(item);
                }
            );
        }

        private doDeleteRiga(item: Produzione.MaterialeVm): void {
            var index = this.materiali.indexOf(item);
            this.materiali.splice(index, 1);
        }

        private barcodeOkHandler(e: JQueryEventObject): void {
            var barcode = this.ultimaLetturaBarcode.barcode;
            this.codiceMateriale = barcode;
            this.aggiungiMateriale(true);
        }

        private eanCodeHandler(e: JQueryEventObject): void {
            this.codiceMateriale = e.ean.CodiceProduttore;
            this.aggiungiMateriale(true);
        }

        private tntLottoHandler(e: JQueryEventObject): void {
            var barcode = this.ultimaLetturaBarcode.barcode;
            this.codiceMateriale = this.ultimaLetturaBarcode.ean.CodiceProduttore;
            this.aggiungiMateriale(true);
        }

        private richiestaModificata(): boolean {
            if (this.materiali.length !== this.numRigheRichiesta) return true;
            var sum = 0;
            $.each(this.materiali, (index, item) => {
                sum += item.Qta;
            });
            return sum != this.numElementiRichiesti;
        }

        public aggiungiMateriale(exact: boolean): void {
            if (this.tipoMateriale == undefined) {
                this.msgFactory.error(this.localeFactory.get("errore_sel_tipo_materiale"));
                return;
            }
            this.formRichiestaApprovvigionamento.$setDirty();
            var ricerca = new Produzione.RicercaMaterialiVm(this.codiceMateriale, this.tipoMateriale, exact);
            ricerca.IdDepositoDestinazione = this.depositoDestinazioneSelezionato.Id;
            this.produzioneFactory.RicercaMateriali(ricerca).then(
                result => {
                    this.codiceMateriale = "";
                    this.ricercaMaterialiResult = result;
                    if (this.ricercaMaterialiResult == undefined || this.ricercaMaterialiResult.length <= 0) {
                        this.msgFactory.error(this.localeFactory.getFormatted("codice_no_corrispondenza", [this.codiceMateriale]));
                        return;
                    }
                    this.addMaterialeToList(this.ricercaMaterialiResult[0]);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );

            this.filtraTipoMateriali();
        }
        private lottoMaterialeAllowed(item: Produzione.MaterialeVm): boolean {
            var deny = [2, 3, 4, 5]; // Il Tnt (4) lo escludo anch'esso, perché lotto e data_scadenza vengono fuori diversamente
            return deny.indexOf(item.IdTipo) == -1;
        }
        public dataScadenzaChanged(item: any) {
            if (item == undefined) return;
            var t = moment(item.StrDataScadenzaLotto, "DD/MM/YYYY").format("YYYY/MM/DDT00:00:00");
            item.DataScadenzaLotto = item.StrDataScadenzaLotto != undefined && item.StrDataScadenzaLotto != "" && t.toString() != "Invalid date" ? t : undefined;
        }

        public registra(): void {
            this.canPrint = false;
            this.showValidationMessages = false;
            if (!this.formRichiestaApprovvigionamento.$valid) {
                this.showValidationMessages = true;
                this.formRichiestaApprovvigionamento.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            if (this.materiali == undefined || this.materiali.length <= 0) {
                this.showValidationMessages = true;
                this.formRichiestaApprovvigionamento.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore_nomateriali"));
                return;
            }
            var controllo = true;
            $.each(this.materiali, (index, item) => {
                if (item.Qta == undefined || item.Qta <= 0) controllo = false;
            });
            if (!controllo) {
                this.showValidationMessages = true;
                this.formRichiestaApprovvigionamento.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore_materiali_qta_errata"));
                return;
            }

            this.confermaRegistra();
        }

        private confermaRegistra(): void {
            var msg = this.localeFactory.get("registrazione_conferma");
            this.msgFactory.confirm(msg).then(
                result => {
                    if (!result.result) return;
                    this.doRegistra();
                }
            );
        }

        private doRegistra(): void {
            var vm = this;
            var lista = new Array<Produzione.MaterialeVm>();
            $.each(this.materiali, (index, item) => {
                if (item.Componenti != undefined && item.Componenti.length > 0) {
                    var comp = $.grep(item.Componenti, (el, id) => {
                        return el.Qta > 0;
                    });
                    if (comp != undefined && comp.length > 0)
                        lista = angular.merge(lista, comp);
                }
                else if (item.Qta > 0)
                    lista.push(item);
            });

            this.movimento.MovimentiDetails = this.getMovimentiDetailsFromMateriali(TipoMovimento.SCARICO, lista);
            this.richiesteApprovvigionamentiFactory.Accetta(this.movimento).then(
                result => {
                    this.movimento = result;
                    this.msgFactory.alert(this.localeFactory.get("registrazione_ok"));
                    this.isSaving = false;
                    this.canPrint = true;
                    this.formRichiestaApprovvigionamento.$setPristine();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("registrazione_errore", [this.currentState, reason.data]));
                    console.log("errore", reason);
                    this.isSaving = false;
                    this.canPrint = false;
                }
            );
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                this.backToList();
            });
        }
    }
}
app.controller("richiesteApprovvigionamentiController", Trace.RichiesteApprovvigionamenti.richiesteApprovvigionamentiController);