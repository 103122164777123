module Trace.Rot {
    export class analisiRotController extends epBaseController
    {
        private formAnalisi: any;
        private filtro: FiltroAnalisiRot;
        private listaDepositi: Array<Trace.Helpers.SimpleEntity>;
        private listaUtenti: Array<Trace.Helpers.SimpleEntity>;
        private listaMacchine: Array<Trace.Helpers.SimpleEntity>;
        private listaCentrali: Array<Trace.Helpers.SimpleDropdown>;

        private isLoading: boolean;
        private listaAnalisi: Array<Rot.VwAnalisiRot>;

        private flgShowDettagli: boolean = false;

        private urlExport: string;

        static $inject = ['$scope', 'rotFactory', 'localeFactory', 'msgFactory', 'dtFactory', 'utenteFactory', "$uibModal", "tracciabilitaFactory", "depositoFactory", "centraliFactory"];
        constructor(private $scope: ng.IScope,
            private rotFactory: rotFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory,
            public utenteFactory: Utente.utenteFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory,
            private depositoFactory: Deposito.depositoFactory,
            private centraleFactory: Centrale.centraliFactory)
        {
            super(undefined, utenteFactory, undefined, undefined, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            this.formAnalisi = {};
        }

        private init() {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 500);
            this.initFiltro();
            //this.getListaDepositi();
            //this.getListaUtenti();
            this.getListaMacchine();
            this.getListaCentrali();
        }

        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

        private getListaUtenti(): void {
            this.rotFactory.GetUtentiSmaltimento().then(
                result => {
                    this.listaUtenti = result;
                },
                reason => {
                    this.msgFactory.error(reason);
                });
        }

        private getListaCentrali(): void {
            this.centraleFactory.listaAsDropdown().then(
                result => {
                    this.listaCentrali = result;
                },
                reason => {
                    this.msgFactory.error(reason);
                });
        }

        private getListaDepositi(): void {
            this.rotFactory.GetDepositiSmaltimento().then(
                result => {
                    this.listaDepositi = result;
                },
                reason => {
                    this.msgFactory.error(reason);
                });
        }

        private getListaMacchine(): void {
            this.depositoFactory.GetMacchine().then(
                result => {
                    this.listaMacchine = result;
                },
                reason => {
                    this.msgFactory.error(reason);
                });
        }

        private initFiltro() {
            this.filtro = {
                CodiceRot: undefined,
                DataSmaltimentoAl: undefined,
                DataSmaltimentoDal: undefined,
                IdDeposito: undefined,
                IdMacchina: undefined,
                IdUtente: undefined,
                StrDataSmaltimentoAl: undefined,
                StrDataSmaltimentoDal: undefined,
                CicloMacchina: undefined,
                DataAccettazioneAl: undefined,
                DataAccettazioneDal: undefined,
                StrDataAccettazioneAl: undefined,
                StrDataAccettazioneDal: undefined,
                FlgAccettato: undefined,
                FlgAccettatoMoreThan72: undefined,
                FlgSmaltito: undefined,
                DataAl: undefined,
                DataDal: undefined,
                StrDataAl: undefined,
                StrDataDal: undefined,
                IdCentrale: undefined,
                TipiRot: undefined
            };
        }

        private azzeraFiltri(): void {
            this.initFiltro();
            this.listaAnalisi = null;
        }

        private ricerca(): void {
            this.flgShowDettagli = false;
            this.isLoading = true;
            var flgAll: boolean = $("input#flgAll").is(":checked");
            var flgS: boolean = $("input#flgS").is(":checked");
            var flgA: boolean = $("input#flgA").is(":checked");
            var flgA72: boolean = $("input#flgA72").is(":checked");

            if (flgAll || (!flgAll && !flgS && !flgA && !flgA72)) {
                this.filtro.FlgAccettato = undefined;
                this.filtro.FlgAccettatoMoreThan72 = undefined;
                this.filtro.FlgSmaltito = undefined;
            }
            else {
                if (flgA || flgA72)
                    this.filtro.FlgAccettato = true;
                if (flgA72)
                    this.filtro.FlgAccettatoMoreThan72 = true;
                if (flgS)
                    this.filtro.FlgSmaltito = true;                
            }

            // Parte flag del tipo rot
            var flgAllTipo: boolean = $("input#flgAllTipi").is(":checked");
            var flgTipo1: boolean = $("input#flgTipo-1").is(":checked"); // Sala operatoria
            var flgTipo2: boolean = $("input#flgTipo-2").is(":checked"); // Altri

            if (flgAllTipo || (!flgAllTipo && !flgTipo1 && !flgTipo2)) {
                this.filtro.TipiRot = undefined;
            }
            else {
                this.filtro.TipiRot = [];
                if (flgTipo1)
                    this.filtro.TipiRot.push(1);
                if (flgTipo2)
                    this.filtro.TipiRot.push(2);
            }

            this.rotFactory.RicercaAnalisiRot(this.filtro).then(
                result => {
                    this.listaAnalisi = result;
                    this.graficoRiepilogo();
                    this.graficoAndamento();
                    this.graficoCentrale();
                    this.urlExport = appRoot + "Reports/ExportExcelAnalisiRot/?strFiltro=" + encodeURIComponent(JSON.stringify(this.filtro));
                    this.isLoading = false;
                },
                reason => {
                    this.msgFactory.error(reason);
                    this.isLoading = false;
                });
        }

        private graficoRiepilogo(): void {
            var series = [{
                name: 'Stato',
                colorByPoint: true,
                data: [
                    {
                        name: 'Accettati e Smaltiti',
                        y: 0
                    },
                    {
                        name: 'SOLO Accettati',
                        y: 0
                    },
                    {
                        name: 'SOLO Smaltiti',
                        y: 0
                    }
                ]
            }];

            this.listaAnalisi.forEach((elem) => {
                if (elem.FlgAccettato && elem.FlgSmaltito)
                    series[0].data[0].y += 1;
                else if (elem.FlgAccettato)
                    series[0].data[1].y += 1;
                else if (elem.FlgSmaltito)
                    series[0].data[2].y += 1;
            });

            Highcharts.chart('chart-riepilogo', {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: null
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y} ({point.percentage:.1f}%)</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                series: series
            });
        }

        private graficoCentrale(): void {
            var categorie: Array<string> = new Array<string>();
            var series = [{
                    name: 'Accettati e Smaltiti',
                    data: []
                },
                {
                    name: 'SOLO Accettati',
                    data: []
                },
                {
                    name: 'SOLO Smaltiti',
                    data: []
                }];

            this.listaAnalisi.forEach(elem => {
                var idx = categorie.indexOf(elem.Centrale);
                var aEs = (elem.FlgAccettato && elem.FlgSmaltito) ? 1 : 0;
                var a = elem.FlgAccettato && !elem.FlgSmaltito ? 1 : 0;
                var s = elem.FlgSmaltito && !elem.FlgAccettato ? 1 : 0;
                if (idx < 0) {
                    categorie.push(elem.Centrale);
                    series[0].data.push(aEs);
                    series[1].data.push(a);
                    series[2].data.push(s);
                } 
                else {
                    series[0].data[idx] += (aEs);
                    series[1].data[idx] += (a);
                    series[2].data[idx] += (s);
                }
            });

            Highcharts.chart('chart-centrali', {
                chart: {
                    type: 'column'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: categorie
                },
                yAxis: {
                    min: 0
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: series
            });
        }

        public graficoAndamento(): void {
            var series = [
                {
                    name: 'Accettati',
                    data: []
                },
                {
                    name: 'Smaltiti',
                    data: []
                }
            ];
            var mesi = new Array<string>();
            this.listaAnalisi.forEach((elem) => {
                var m = moment(elem.DataAccettazione).format("MM/YYYY");
                var idx = mesi.indexOf(m);
                if (mesi.indexOf(m) < 0) {
                    mesi.push(m);
                    series[0].data.push(0);
                    series[1].data.push(0);
                    idx = mesi.length - 1;
                }

                if (elem.FlgAccettato === true)
                    series[0].data[idx] += 1;

                if (elem.FlgSmaltito === true)
                    series[1].data[idx] += 1;
            });

            Highcharts.chart('chart-andamento', {
                chart: {
                    type: 'line'
                },
                title: {
                    text: null
                },
                xAxis: {
                    categories: mesi
                },
                yAxis: {
                    title: {
                        text: 'Numero R.O.T.'
                    }
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            enabled: true
                        },
                        enableMouseTracking: false
                    }
                },
                series: series
            });
        }
    }
}
app.controller("analisiRotController", Trace.Rot.analisiRotController);