
module Trace.CentroCosto { 
    export class RicercaCentroCosto {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdGruppo: number;
        public IdSottoGruppo: number;
        public IdSede: number;
    }


    export class centriCostoController {
        public lista: centroCostoVm[];
        public recordCorrente: CentroCosto;
        public testoAzione: string;
        public gruppi: Helpers.SimpleEntity[];
        public gruppiRicerca: Gruppo.Gruppo[];
        public sottoGruppi: Helpers.SimpleEntity[];
        public sottoGruppiRicerca: SottoGruppo.SottoGruppo[];
        public sedi: Helpers.SimpleEntity[];
        public sediRicerca: Sede.Sede[];
        public ricerca: RicercaCentroCosto;

        public currentState: string;
        public titoloManipola: string;

        public showValidationMessages: boolean;
        public formCentriCosto: any;

        public ccOld: CentroCosto;


        static $inject = ['$scope', 'sottoGruppiFactory', 'gruppiFactory', 'sediFactory', 'centriCostoFactory', 'dtFactory',
            'localeFactory', 'msgFactory'];
        constructor(private $scope: ng.IScope, private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private gruppiFactory: Gruppo.gruppiFactory, private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory, private dtFactory: datatables.dtFactory,
            private localeFactory: Localization.localeFactory, private msgFactory: Messages.msgFactory) {

            gruppiFactory.asSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.gruppi = risultato;
            });

            gruppiFactory.lista().then((risultato: Gruppo.Gruppo[]) => {
                //this.gruppi = risultato;
                this.gruppiRicerca = risultato;

                var dummy: Gruppo.Gruppo = new Gruppo.Gruppo();
                dummy.Descrizione_it_IT = '';
                this.gruppiRicerca.unshift(dummy);
            });

            this.annulla();

        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formCentriCosto.$valid) {
                this.showValidationMessages = true;
                this.formCentriCosto.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {

                    if (this.recordCorrente.Id == undefined)
                        this.centriCostoFactory.inserisci(this.recordCorrente).then((result => {
                            this.annulla();
                        }),
                        (error => {
                            this.msgFactory.alert(error);
                        }));
                    else
                        this.centriCostoFactory.modifica(this.recordCorrente).then((result => {
                            this.annulla();
                        }),
                        (error => {
                            this.msgFactory.alert(error);
                        }));
                }
            });

        }

        public annulla(): void {
            this.recordCorrente = new CentroCosto();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.ricerca = new RicercaCentroCosto();

            this.centriCostoFactory.lista().then((risultato: centroCostoVm[]) => {
                this.lista = risultato;
                this.changeState('list');
            });

        }

        public cancella(CentroCosto: CentroCosto): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.centriCostoFactory.cancella(CentroCosto.Id).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        public modifica(CentroCostoVm: centroCostoVm): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.centriCostoFactory.leggi(CentroCostoVm.Id).then((centroCosto: CentroCosto) => {
                this.recordCorrente = centroCosto;

                this.ccOld = <CentroCosto>{
                    IdGruppo: this.recordCorrente.IdGruppo,
                    IdSottogruppo: this.recordCorrente.IdSottogruppo,
                    IdSede: this.recordCorrente.IdSede,
                    Codice: this.recordCorrente.Codice
                };

                this.testoAzione = this.localeFactory.get('button_modifica');
                this.cambiaGruppo();
                this.changeState('manipola');
            });
        }

        private fixCodice(): void {
            if (this.ccOld != undefined) {
                if (this.ccOld.IdGruppo != this.recordCorrente.IdGruppo || this.ccOld.IdSottogruppo != this.recordCorrente.IdSottogruppo || this.ccOld.IdSede != this.recordCorrente.IdSede) {
                    this.recordCorrente.Codice = undefined;
                }
                else
                {
                    this.recordCorrente.Codice = this.ccOld.Codice;
                }
            } 
        }

        public cambiaGruppo(): void {
            this.fixCodice();         
            if (this.recordCorrente.IdGruppo == undefined)
                return;

            this.sottoGruppiFactory.listaGruppoAsSe(this.recordCorrente.IdGruppo).then((risultato: Helpers.SimpleEntity[]) => {
                this.sottoGruppi = risultato;
                if (this.recordCorrente.IdSede != undefined)
                    this.cambiaSottoGruppo();
            });

            //this.sottoGruppiFactory.listaGruppo(this.recordCorrente.IdGruppo).then((risultato: SottoGruppo.SottoGruppo[]) => {
            //    this.sottoGruppi = risultato;
            //    if (this.recordCorrente.IdSede != undefined)
            //        this.cambiaSottoGruppo();
            //});
        }

        public cambiaSottoGruppo(): void {
            this.fixCodice();
            if (this.recordCorrente.IdSottogruppo == undefined) {
                this.sedi = [];
                return;
            }

            this.sediFactory.listaSottoGruppoAsSe(this.recordCorrente.IdSottogruppo).then((risultato: Helpers.SimpleEntity[]) => {
                this.sedi = risultato;
            });

            //this.sediFactory.listaSottoGruppo(this.recordCorrente.IdSottogruppo).then((risultato: Sede.Sede[]) => {
            //    this.sedi = risultato;
            //});

        }

        public aggiornaRicercaGruppo() {
            this.ricerca.IdSottoGruppo = undefined;
            this.ricerca.IdSede = undefined;
            this.sottoGruppiRicerca = [];
            this.sediRicerca = [];

            if (this.ricerca.IdGruppo == undefined) {
                this.dtFactory.aggiornaRicerca();
                return;
            }
            this.sottoGruppiFactory.listaGruppo(this.ricerca.IdGruppo).then((risultato: SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppiRicerca = risultato;

                var dummy: SottoGruppo.SottoGruppo = new SottoGruppo.SottoGruppo();
                dummy.Descrizione_it_IT = '';
                this.sottoGruppiRicerca.unshift(dummy);

                this.dtFactory.aggiornaRicerca();
            });
        }

        public aggiornaRicercaSottoGruppo() {
            this.ricerca.IdSede = undefined;
            this.sediRicerca = [];
            if (this.ricerca.IdSottoGruppo == undefined) {
                this.dtFactory.aggiornaRicerca();
                return;
            }
            this.sediFactory.listaSottoGruppo(this.ricerca.IdSottoGruppo).then((risultato: Sede.Sede[]) => {
                this.sediRicerca = risultato;

                var dummy: Sede.Sede = new Sede.Sede();
                dummy.Descrizione_it_IT = '';
                this.sediRicerca.unshift(dummy);

                this.dtFactory.aggiornaRicerca();
            });
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: ViewModels.DirezioneSort, filtro: Array<ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<ViewModels.SortRigaVm>();
            ricerca.sort.push(new ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.centriCostoFactory.ricerca(ricerca).then((risultato: centroCostoVm[]) => {
                this.lista = risultato;
            });
        }

        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = new CentroCosto();
            this.ccOld = undefined;
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formCentriCosto.$setPristine();
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaCentroCosto();
        }
    }
}
app.controller("centroCostoController", Trace.CentroCosto.centriCostoController);