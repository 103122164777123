app.factory("gruppiFactory", ($http, $location, $q) => new Trace.Gruppo.gruppiFactory($http, $location, $q));

module Trace.Gruppo {
    export class Gruppo {
        public Id : number;
        public Codice : string;
        public DataCreazione : Date;
        public DataObsoleto : Date;
        public DataUltimaModifica : Date;
        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public IdImmagine : number;
        public IdUtenteCreazione : number;
        public IdUtenteObsoleto : number;
        public IdUtenteUltimaModifica : number;
    }

    export class gruppiFactory {
        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Gruppo/";
        }

        public lista(): ng.IPromise<Gruppo[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Gruppo[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public listaCompleta(): ng.IPromise<Gruppo[]> {
            var workUrl = this.GetBaseUrl() + "ListaCompleta";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Gruppo[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(gruppo: Gruppo): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, gruppo).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(gruppo: Gruppo): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + gruppo.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, gruppo).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }


        public cancella(idGruppo: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idGruppo;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public patch(idGruppo: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idGruppo;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<Gruppo[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Gruppo[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idGruppo: number): ng.IPromise<Gruppo> {
            var workUrl = this.GetBaseUrl() + idGruppo;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Gruppo, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public asSe(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + 'asSe/';

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }
    }
}