
app.factory("carrelliFactory", ($http, $location, $q) => new Trace.Carrelli.carrelliFactory($http, $location, $q));
module Trace.Carrelli {
    export class carrelliFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {}

        public GetBaseUrl(): string {
            return appRoot + "api/Carrelli/";
        }

        public lista(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ricerca(filtro: ViewModels.RicercaVm, page: number): ng.IPromise<ViewModels.GenericPaginationVm<CarrelloVm>> {
            var workUrl = this.GetBaseUrl() + "Ricerca/" + page;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public inserisci(carrello: Carrello): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, carrello).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public modifica(carrello: Carrello): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + carrello.Id;
            var deferred = this.$q.defer();
            this.$http.put(workUrl, carrello).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}