module Trace.Components {
    export class interventoController {
        public intervento: Intervento.InterventoVm;
        public dettagli: Intervento.IInterventoDetailVm[];
        public dett: Intervento.IInterventoDetailVm;
        private codiceAccettazioneSfuso = "ACC-SFUSO";
        private codiceAccettazioneSfusoBT = "ACC-SFUSO-BT";
        public visible: boolean;
        static $inject = ["localeFactory"];
        constructor(public localeFactory: Localization.localeFactory) {
            var vm = this;
            if (vm.intervento != undefined) {
                setTimeout(function () {
                    $(".datetime").datepicker();
                }, 1000);
                if (vm.intervento.Dettagli != undefined) {
                    vm.dettagli = $.grep(this.intervento.Dettagli, (item, index) => {
                        return item.IdLottoProduzione == undefined;
                    });
                    console.log("dettagli non kit", vm.dettagli);
                }
            }
            vm.visible = true;
        }

        public dateChanged(property: string, item: Startup.StartupDetailVm): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

        public labelDettagli(): string {
            var label = (this.visible) ? 'button_hide_dettagli_intervento' : 'button_show_dettagli_intervento';
            return this.localeFactory.get(label);
        }

        public formatDate(data: Date, format: string = "DD/MM/YYYY"): string {
            var result = moment(data).format(format);
            return result;
        }

        public removeItemIntervento(): void {
            triggerRemoveItemIntervento(this.dett);
        }

        public numRot(dettagli: Intervento.IInterventoDetailVm[]): number {
            if (dettagli == undefined || dettagli.length <= 0) return 0;
            var rot = $.grep(dettagli, (elem, index) => {
                return elem.IdRot != undefined;
            }).length;

            return rot;
        }

        public numTnt(dettagli: Intervento.IInterventoDetailVm[]): number {
            if (dettagli == undefined || dettagli.length <= 0) return 0;
            var tnt = $.grep(dettagli, (elem, index) => {
                return elem.IdTnt != undefined;
            }).length;

            return tnt;
        }

        public isSfuso(): boolean {
            var result = this.intervento.Codice === this.codiceAccettazioneSfuso || this.intervento.Codice == this.codiceAccettazioneSfusoBT;
            return result;
        }
    }
}
app.controller("interventoController", Trace.Components.interventoController);

app.component("intervento", {
    templateUrl: "/views/Accettazione/intervento.html",
    controller: "interventoController",
    controllerAs: "vm",
    bindings: {
        intervento: "<",
        selected: "<",
        causali: "<",
        azioni: "<",
        idLottoSelezionato: "<",
        parent: "<"
    }
});

app.component("dettaglioIntervento", {
    templateUrl: "/views/Accettazione/dettaglio-intervento.html",
    controller: "interventoController",
    controllerAs: "vm",
    bindings: {
        dett: "<"
    }
});