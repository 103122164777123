app.factory("marcheFactory", ($http, $location, $q) => new Trace.Marche.marcheFactory($http, $location, $q));

module Trace.Marche {

    export class marcheFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Marche/";
        }

        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public lista(): ng.IPromise<Marca[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Marca[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idMarca: number): ng.IPromise<Marca> {
            var workUrl = this.GetBaseUrl() + idMarca;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Marca, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(marca: Marca): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, marca).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(marca: Marca): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + marca.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, marca).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public cancella(idMarca: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idMarca;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Marche.MarchePaginationVm> {
            var workUrl = this.GetBaseUrl() + 'Ricerca/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Marca[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
    }
}