module Trace.Tracciabilita {
    export class tracciabilitaSetController extends epBaseController {
        static $inject = ["$scope", "Upload", "tracciabilitaFactory", "utenteFactory", "prodottiFactory", "documentoFactory", "localeFactory", "msgFactory", "$uibModal"];
        private tracciaSet: TracciabilitaSetVm;
        private formTracciabilitaSet: any;
        private filtro: FiltroTracciabilitaSet;

        constructor(
            private $scope: ng.IScope,
            public upload: ng.angularFileUpload.IUploadService,
            public tracciabilitaFactory: tracciabilitaFactory,
            public utenteFactory: Utente.utenteFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            private documentoFactory: Documento.documentoFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService
        ) {
            super(upload, utenteFactory, undefined, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            var vm = this;
            this.formTracciabilitaSet = {};
            this.filtro = {
                IdTipoSet: "1",
                Codice: undefined,
                Lotto: undefined
            };
        }

        private init(): void {
            var vm = this;
            $(document).on("gotUserSession", (e: JQueryEventObject) => {
                vm.filtro.Codice = tCodSet;
                vm.ricerca();
            });
        }

        private lottoSelezionato(id: number): void {
            var f = <FiltroTracciabilitaSet>{
                Codice: undefined,
                Lotto: id
            };
            this.ricerca(f);
        }

        private ricerca(filtro: FiltroTracciabilitaSet = undefined): void {
            var vm = this;

            if (filtro == undefined) filtro = this.filtro;
            if (filtro.Lotto == undefined && filtro.Codice == undefined) return;

            if (filtro.Lotto != undefined && isNaN(filtro.Lotto))
                filtro.Lotto = undefined;

            if ((filtro.Codice == undefined && filtro.Lotto == undefined) ||
                (filtro.Codice == "" && filtro.Lotto <= 0)) {
                this.msgFactory.error(this.localeFactory.get("valorizzare_campi"));
                return;
            }

            this.tracciabilitaFactory.RicercaSet(filtro).then(
                result => {
                    this.tracciaSet = result;
                    if (this.tracciaSet.SelLotto) return;
                    setTimeout(() => {
                        vm.impostaAltezzaPannelli();
                    }, 900);
                },
                reason => {
                    if (reason.status == 404) {
                        this.msgFactory.error(this.localeFactory.get("no_result"));
                    }
                    this.tracciaSet = undefined;
                }
            );
        }

        private impostaAltezzaPannelli(): void {
            var h = 0;
            var items = new Array<any>();
            //alert($("form#form-pannelli-tracciabilita div.panel").length);
            $("form#form-pannelli-tracciabilita div.panel").each((index, item) => {
                items.push(item);
                var oh = $(item).outerHeight();
                if (h < oh) h = oh;
                if (index % 2 === 1) {
                    console.log("la nuova altezza generale è ", h);
                    $.each(items, (id, itm) => {
                        $(itm).outerHeight(h);
                    });
                    h = 0;
                    items = new Array<any>();
                }
            });
        }
    }
}
app.controller("tracciabilitaSetController", Trace.Tracciabilita.tracciabilitaSetController);