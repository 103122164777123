module Trace.Lavaferri {
    export class lavaferriController extends epProduzioneController {
        public ora: string;
        static $inject = ["$rootScope", "$scope", "lavaferriFactory",
            "centraliFactory", "setFactory", "produzioneFactory",
            "localeFactory", "msgFactory", "$uibModal", "$element",
            "dragularService", "$interval", "utenteFactory"];
        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private lavaferriFactory: lavaferriFactory,
            private centraliFactory: Centrale.centraliFactory,
            public setFactory: Set.setFactory,
            public produzioneFactory: Produzione.produzioneFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService, 
            private $element: any,
            private dragularService: any,
            public $interval: any,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($rootScope, $scope, $uibModal, localeFactory, msgFactory, setFactory, undefined, undefined, produzioneFactory, 4, "lavaferri", undefined, null, utenteFactory, tracciabilitaFactory);
            this.myRoot = <epRootScope>$rootScope;
            var vm = this;
            //this.listaFase();
            $(document).on("barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkEventHandler(e);
            }).on("barcodeGrigliaOk", (e: JQueryEventObject) => {
                this.barcodeGrigliaOkEventHandler(e);
            }).on("barcodeGrigliaCaricataOk", (e: JQueryEventObject) => {
                this.barcodeGrigliaCaricataOkEventHandler(e);
            }).on("mouseDownProduzione", (e: JQueryEventObject) => {
                this.mouseDownLotto(e);
            }).on("mouseDownGriglia", (e: JQueryEventObject) => {
                this.mouseDownGriglia(e);
            }).on("eseguiCicloManuale", (e: JQueryEventObject) => {
                this.eseguiCicloManuale(e);
            }).on("grigliaSfusa", (e: JQueryEventObject) => {
                this.grigliaSfusaHandler(e);
            });

            this.setupDeD();
        }

        private eseguiCicloManuale(e: JQueryEventObject): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("esecuzione_ciclo_manuale_conferma", [this.descrFase, e.macchina.Nome, e.macchina.NumCiclo])).then(
                result => {
                    if (!result.result) return;
                    this.doEseguiCicloManuale(e.macchina);
                }
            );
        }

        private doEseguiCicloManuale(macchina: Produzione.Macchina): void {
            this.produzioneFactory.EseguiCicloManuale(macchina.Id, macchina.NumCiclo).then(
                result => {
                    if (!result.Result) {
                        this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [result.Message]));
                        console.log("errore", result);
                        return;
                    }
                    this.listaFase();
                    this.macchine[this.idMacchinaSelezionata].Carico = undefined;
                    this.macchine[this.idMacchinaSelezionata].FlgManuale = false;
                    //this.listaCarichi(TipoMacchina.LAVAFERRI);
                    this.idMacchinaSelezionata = undefined;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private registerEvents(el: any): void {
            var vm = this;
            el.on('dragularrelease', function (e) {
                if ((el[0] === e.target && // filter bubbled
                    vm.dragularService.shared.extra && // extra on dragleave contains element the drag is leaving to
                    vm.dragularService.shared.extra.parentElement !== e.target) // is that element child of this container?
                    || e.type === 'dragularrelease') {
                    var idMacchina = parseInt($(el).data("idmacchina"));
                    console.log("ID Macchina", idMacchina);
                    vm.isDragging = false;
                    // Cambio la macchina selezionata al dorp
                    if (!isNaN(idMacchina) && idMacchina !== vm.idMacchinaSelezionata) 
                        vm.idMacchinaSelezionata = idMacchina;
                    if (vm.lottoDrag != undefined) {
                        triggerBarcode(vm.lottoDrag.Codice);
                        vm.lottoDrag = undefined;
                    }
                    else if (vm.grigliaDrag != undefined) {
                        triggerBarcode(vm.grigliaDrag.Codice);
                        vm.grigliaDrag = undefined;
                    }
                }
            });
        }

        private setupDeD(): void {
            this.dragularOptions = {
                classes: { mirror: 'custom-green-mirror' }
            };
        }

        public initModule(timer: boolean = false): void {
            var vm = this;
            console.log("timer?", timer);
            this.listaMacchine(TipoMacchina.LAVAFERRI);
            if (timer === true) {
                var stop: any;
                if (angular.isDefined(stop)) return;

                stop = this.$interval(function () {
                    vm.listaMacchine(TipoMacchina.LAVAFERRI);
                    vm.listaFase(timer);
                    vm.ora = vm.now();
                }, Globals.TimerRefreshAnalisi);
            }
            vm.listaFase(timer);
            vm.ora = vm.now();
        }

        public listaFase(analisi: boolean = false): void {
            var vm = this;
            this.idLottoSelezionato = undefined;
            this.lavaferriFactory.Lista().then(
                result => {
                    $(".set-container .dragular-sandbox .list-row-container").remove();
                    console.log("risultato", result);
                    this.lista = result;
                    setTimeout(function () {
                        console.log("lung", $(".dragular-sandbox").length);
                        $.each($(".dragular-sandbox"), (index, el) => {
                            vm.registerEvents(angular.element(el));
                        });

                        var d = $('div.macchina .body');
                        $.each(d, (did, del) => {
                            $(del).scrollTop(del.scrollHeight);
                        });

                        if (!vm.primoCaricamento) return;
                        vm.primoCaricamento = false;
                        vm.cambiaAltezzaMacchine(analisi);
                    }, 500);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public listaMacchine(idTipoMacchina: number = undefined): void {
            this.centraliFactory.ListaMacchine(idTipoMacchina).then(
                result => {
                    this.macchine = result;
                    this.listaCarichi(TipoMacchina.LAVAFERRI);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_lista_lavaferri_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public mouseDownLotto(e: JQueryEventObject): void {
            this.lottoDrag = e.lotto;
        };

        public mouseDownGriglia(e: JQueryEventObject): void {
            console.log("DRAG START", e.funzione);
            // Visualizzo l'area di drop solo se sto SCARICANDO
            if (e.funzione != 'daCaricare')
                this.isDragging = true;
            this.grigliaDrag = e.griglia;
        };

        private grigliaSfusaHandler(e: JQueryEventObject): void {
            console.log("griglia sfusa event", e);
            if (this.idMacchinaSelezionata == undefined)
                this.msgFactory.error(this.localeFactory.get('selezionare_macchina'));
            var codice = e.lettura;
            var result = this.findInListByCodiceGriglia(codice);
            triggerGrigliaStrumentiEvent("barcodeGrigliaOk", result);
            //this.barcodeOkEventHandler(e, result);
        };
        
        public barcodeOkEventHandler(e: JQueryEventObject, codice: string = undefined): void {
            var vm = this;
            if (this.idMacchinaSelezionata == undefined) {
                this.msgFactory.error(this.localeFactory.get('selezionare_macchina'));
                return;
            }
            console.log(e.griglieStrumenti);
        }

        public barcodeGrigliaCaricataOkEventHandler(e: JQueryEventObject): void {
            var vm = this;
            var carichi: any = e.carico;
            var idmacchina: number = undefined;
            var caricato = new Array<any>();

            if (e.griglieStrumenti instanceof Array) {
                $.each(carichi, (idCarico, carico) => {
                    $.each(this.macchine, (idm, m) => {
                        if (m.Id === carico.IdMacchina) {
                            console.log("macchina trovata", m, idm);
                            caricato.push(idm);
                            idmacchina = m.Id;
                            return;
                        }
                    });

                    carico.IdMacchina = idmacchina;
                    caricato.push(carico);
                });
            }
            else {
                var carico = <Produzione.CaricoMacchina>carichi;
                $.each(this.macchine, (idm, m) => {
                    if (m.Id === carico.IdMacchina) {
                        console.log("macchina trovata", m, idm);
                        caricato.push(idm);
                        idmacchina = m.Id;
                        return;
                    }
                });

                carico.IdMacchina = idmacchina;
                caricato.push(carico);
            }

            this.lottoCaricato(caricato);
        }

        public barcodeGrigliaOkEventHandler(e: JQueryEventObject): void {
            if (this.idMacchinaSelezionata == undefined) {
                this.msgFactory.error(this.localeFactory.get('selezionare_macchina'));
                return;
            }
            var carichi = new Array<Produzione.CaricoMacchinaVm>();
            console.log("griglia letta", e.griglieStrumenti);
            var m = this.macchine[this.idMacchinaSelezionata];
            var griglie: any = e.griglieStrumenti;
            if (this.grigliaDrag == undefined) {
                this.grigliaDrag = griglie[0];
            }
            var lotto: Produzione.LottoProduzioneVm;
            if (griglie != undefined && griglie instanceof Array) {
                $.each(griglie, (idgriglia, griglia) => {
                    $.each(this.lista, (idl, l) => {
                        if (l.Id === griglia.IdLotto) {
                            this.idLottoSelezionato = idl;
                            lotto = l;
                            return;
                        }
                    });

                    console.log("ho il lotto interessato dalla lettura", lotto);
                    var carico = new Produzione.CaricoMacchinaVm(TipoMacchina.LAVAFERRI, this.idMacchinaSelezionata, this.idLottoSelezionato);
                    carico.LottoProduzione = lotto;
                    carico.IdGriglia = griglia.Id;
                    carico.IdMacchina = m.Id;
                    carichi.push(carico);
                });
            }
            else {
                var griglia = <Produzione.GrigliaStrumentiVm>griglie;
                $.each(this.lista, (idl, l) => {
                    if (l.Id === griglia.IdLotto) {
                        this.idLottoSelezionato = idl;
                        lotto = l;
                        return;
                    }
                });

                console.log("ho il lotto interessato dalla lettura", lotto);
                var carico = new Produzione.CaricoMacchinaVm(TipoMacchina.LAVAFERRI, this.idMacchinaSelezionata, this.idLottoSelezionato);
                carico.LottoProduzione = lotto;
                carico.IdGriglia = griglia.Id;
                carico.IdMacchina = m.Id;
                carichi.push(carico);
            }
            
            return this.caricaLavaferri(carichi);
        }
              
        //public barcodeOkEventHandler(e: JQueryEventObject, codice: string = undefined): void {
        //    var vm = this;
        //    if (this.idMacchinaSelezionata == undefined) {
        //        this.msgFactory.error(this.localeFactory.get('selezionare_macchina'));
        //        return;
        //    }
        //    if (codice == undefined)
        //        codice = this.ultimaLetturaBarcode.testo;
        //    var result = this.findInList(codice);
        //    if (result) {
        //        console.log("trovata da caricare", result, this.ultimaLetturaBarcode);
        //        if (this.lista[this.idLottoSelezionato].Griglie.length > 0 && (this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA1 ||
        //            this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA2 ||
        //            this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA3)) {
        //            if (this.grigliaDrag == undefined) {
        //                this.grigliaDrag = $.grep(this.lista[this.idLottoSelezionato].Griglie, (item, index) => {
        //                    if (this.ultimaLetturaBarcode.codiceRealeGriglia.length === Globals.LunghezzaBarcodeGrigliaSet && (this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA1 ||
        //                        this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA2 ||
        //                        this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA3)) {
        //                        console.log("cerco il codice letto GRIGLIA NUOVA", item.Codice, this.ultimaLetturaBarcode.codiceRealeGriglia);
        //                        return item.Codice === this.ultimaLetturaBarcode.codiceRealeGriglia;
        //                    }
        //                    else {
        //                        console.log("cerco il codice letto GRIGLIA VECCHIA", item.Codice, codice);
        //                        return item.Codice === codice;
        //                    }
        //                })[0];
        //                console.log("griglia drag", this.grigliaDrag);
        //                if (this.grigliaDrag == undefined) return;
        //                this.ultimaLetturaBarcode.idGriglia = this.grigliaDrag.Id;
        //            }
        //            console.log("griglia", this.grigliaDrag);
        //            if (this.ultimaLetturaBarcode.codiceRealeGriglia.length === Globals.LunghezzaBarcodeGrigliaSet && (this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA1 ||
        //                this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA2 ||
        //                this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA3)) {
        //                return this.caricaLavaferri(this.grigliaSelezionata(this.lista, this.ultimaLetturaBarcode.codiceRealeGriglia));
        //            }
        //            else {
        //                return this.caricaLavaferri(this.grigliaSelezionata(this.lista, this.ultimaLetturaBarcode.testo));
        //            }
        //        }
        //        else {
        //            return this.caricaLavaferri(this.grigliaSelezionata(this.lista, this.ultimaLetturaBarcode.testo));
        //        }
        //    }
        //    // Il lotto esiste ed è già caricato in un'altra macchina
        //    var caricato = (this.ultimaLetturaBarcode.codiceRealeGriglia.length === Globals.LunghezzaBarcodeGrigliaSet && (this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA1 ||
        //        this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA2 ||
        //        this.ultimaLetturaBarcode.tipo === TipoBarcode.GRIGLIA3))
        //        ? this.findInLottiCaricati(this.ultimaLetturaBarcode.codiceRealeGriglia)
        //        : this.findInLottiCaricati(codice);

        //    if (caricato != undefined) {
        //        this.lottoCaricato(caricato);
        //    }
        //    console.log("non so che farci con questo codice");
        //}

        public grigliaSelezionata(lista: Array<Produzione.LottoProduzioneVm>, codice: string): Produzione.CaricoMacchina {
            var vm = this;
            if (vm.idLottoSelezionato == undefined) return null;
            var lotto = lista[vm.idLottoSelezionato];
            var result = new Produzione.CaricoMacchinaVm(TipoMacchina.LAVAFERRI, vm.idMacchinaSelezionata, lotto.Id);
            result.LottoProduzione = lotto;

            // Controllo se è una griglia o un contai
            if (lista[vm.idLottoSelezionato].Griglie == undefined || lista[vm.idLottoSelezionato].Griglie.length <= 0) {
                result.LottoProduzione = lotto;
                result.IdGriglia = 0;
            }
            else {
                if (this.ultimaLetturaBarcode.testo.length == Globals.LunghezzaBarcodeSet ||
                    (this.ultimaLetturaBarcode.testo.length == Globals.LunghezzaBarcodeDettaglioSet &&
                        this.ultimaLetturaBarcode.testo.substr(this.ultimaLetturaBarcode.testo.length - 1) == "0")) {
                    result.IdGriglia = 0;
                }
                else {
                    var griglie = $.grep(lista[vm.idLottoSelezionato].Griglie, (item, index) => {
                        return codice == item.Codice;
                    });
                    console.log("griglie", vm.idLottoSelezionato, griglie, codice);
                    if (griglie == undefined || griglie.length <= 0) return null;
                    result.IdGriglia = griglie[0].Id;
                }
            }
            console.log("grigliaSelezionata result", result);
            return result;
        }

        private lottoCaricato(caricato: Array<any>): void {
            // CANCELLAZIONE - Lotto presente nella stessa macchina selezionata
            console.log("controllo se è cancellazione o modifica", caricato[0], this.idMacchinaSelezionata);
            if (caricato[0] === this.idMacchinaSelezionata) {
                this.msgFactory.confirm(this.localeFactory.getFormatted("msg_cancellazione_conferma", [caricato[2], caricato[3]])).then(
                    result => {
                        if (!result.result) {
                            this.listaCarichi(TipoMacchina.LAVAFERRI);
                            return;
                        }
                        console.log("Rimuovo griglia", caricato);
                        //this.rimuoviLottoDaLavaferri(this.macchine[<number>caricato[0]].Carico[<number>caricato[1]]);
                        this.rimuoviLottoDaLavaferri(caricato[1]);
                    }
                );
            }
            // SPOSTAMENTO - Lotto presente in un'altra macchina rispetto a quella selezionata
            else {
                this.msgFactory.confirm(this.localeFactory.getFormatted("msg_spostamento_conferma", [caricato[2], caricato[3]])).then(
                    result => {
                        if (!result.result) {
                            this.listaCarichi(TipoMacchina.LAVAFERRI);
                            return;
                        }
                        console.log("Sposto griglia", caricato[1]);
                        (<Produzione.CaricoMacchina>caricato[1]).IdMacchina = this.macchine[this.idMacchinaSelezionata].Id;
                        this.caricaLavaferri(caricato[1]);
                    }
                );
            }
        }

        private rimuoviLottoDaLavaferri(carico: Produzione.CaricoMacchina): void {
            var lotto = carico.LottoProduzione;
            this.lavaferriFactory.RimuoviGriglia(lotto.Id, carico.IdGriglia).then(
                result => {
                    if (!result.Result) {
                        this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [result.Message]));
                        console.log("errore", result);
                        return;
                    }
                    this.listaFase();
                    this.listaCarichi(TipoMacchina.LAVAFERRI);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private caricaLavaferri(carichiSpostati: Produzione.CaricoMacchinaVm[]): void {
            var macchina = this.macchinaSelezionata();
            if (macchina == null) {
                this.msgFactory.error(this.localeFactory.get("nessuna_macchina_selezionata"));
                return;
            }
            $.each(carichiSpostati, (idCarico, caricoSpostato) => {
                var lotto = caricoSpostato.LottoProduzione;
                var carico = caricoSpostato;//new Produzione.CaricoMacchinaVm(TipoMacchina.LAVAFERRI, macchina.Id, lotto.Id);
                carico.IdLottoProduzione = lotto.Id;
                console.log("carico la lavaferri con questo", carico);
                //if (this.ultimaLetturaBarcode.tipo !== TipoBarcode.SET && this.ultimaLetturaBarcode.idGriglia != undefined) {
                //    carico.IdGriglia = this.ultimaLetturaBarcode.idGriglia;
                //}
                this.produzioneFactory.CaricaMacchina(carico).then(
                    result => {
                        if (!result.Result) {
                            this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [result.Message]));
                            console.log("errore", result);
                            return;
                        }
                        this.eliminaGriglieDeD();
                        this.listaCarichi(TipoMacchina.LAVAFERRI);
                        this.listaFase();
                    },
                    reason => {
                        this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                        console.log("errore", reason);
                    }
                );
            });
        }

        private eliminaGriglieDeD(): void {
            var domMacchina = $(".macchina.selected");
            var domGriglia = domMacchina.find(".griglia").parent();
            domGriglia.remove();
        }
    }
}
app.controller("lavaferriController", Trace.Lavaferri.lavaferriController);