module Trace.Carrelli {
    export class Carrello implements CarrelloVm {
        public Id: number;
        public Codice: string;
        public Descrizione_it_IT: string;
        public Descrizione_en_US: string;
        public Descrizione_sq_AL: string;
        public IdGruppo: number;
        public Gruppo: string;
        public IdSottogruppo: number;
        public Sottogruppo: string;
        public IdSede: number;
        public Sede: string;
        public IdCentroCosto: number;
        public CentroCosto: string;
    }
}