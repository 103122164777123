module Trace.Tracciabilita {
    export class tracciabilitaMaterialeController extends epBaseController {
        static $inject = ["$scope", "depositoFactory", "Upload", "tracciabilitaFactory", "utenteFactory", "prodottiFactory", "documentoFactory", "localeFactory", "msgFactory", "$uibModal"];
        private lista: any;
        private formTracciabilitaTnt: any;
        private filtro: FiltroTracciabilitaMateriale;
        private depositi: Trace.Deposito.DepositoVm[];

        constructor(
            private $scope: ng.IScope,
            public depositoFactory: Trace.Deposito.depositoFactory,
            public upload: ng.angularFileUpload.IUploadService,
            public tracciabilitaFactory: tracciabilitaFactory,
            public utenteFactory: Utente.utenteFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            private documentoFactory: Documento.documentoFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService
        ) {
            super(upload, utenteFactory, undefined, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            this.formTracciabilitaTnt = {};
            this.filtro = {
                IdDeposito: undefined,
                Codice: undefined,
                Lotto: undefined,
                DataRiferimentoDa: undefined,
                DataRiferimentoA: undefined
            };
            this.depositoFactory.listaCompleta(true).then(
                result => {
                    this.depositi = result;
                }
            );

            setTimeout(() => $(".datetime").datepicker(), 2000);
            this.setUpWatchers();
        }

        private init(): void {
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.filtro.StrDataRiferimentoDa", (newVal: string, oldVal: string) => {
                if (this.filtro == undefined) return;
                var t = moment(newVal, "DD/MM/YYYY").toDate();
                this.filtro.DataRiferimentoDa = newVal != undefined && newVal != "" && t.toString() != "Invalid date" ? t : undefined;
            });
            this.$scope.$watch("vm.filtro.StrDataRiferimentoA", (newVal: string, oldVal: string) => {
                if (this.filtro == undefined) return;
                var t = moment(newVal, "DD/MM/YYYY").toDate();
                this.filtro.DataRiferimentoA = newVal != undefined && newVal != "" && t.toString() != "Invalid date" ? t : undefined;
            });
        }
        
        private ricerca(): void {
            if ((this.filtro.Codice == undefined || this.filtro.Codice.trim() == "") && (this.filtro.Lotto == undefined || this.filtro.Lotto.trim() == "")) {
                this.msgFactory.error(this.localeFactory.get("valorizzare_codice_lotto"));
                return;
            }
            this.tracciabilitaFactory.RicercaMateriale(this.filtro).then(
                result => {
                    this.lista = result;
                },
                reason => {
                    var acc = []
                    $.each(reason, (index, value) => {
                        acc.push(index + ': ' + value);
                    });
                    this.msgFactory.alert(JSON.stringify(acc));
                }
            );
        }
    }
}
app.controller("tracciabilitaMaterialeController", Trace.Tracciabilita.tracciabilitaMaterialeController);