module Trace.Components {
    export class listRowBoxController {
        public box: Produzione.BoxVm;
        public contenuto: Produzione.VwContenutoBox;
        static $inject = ["localeFactory"];

        constructor(public localeFactory: Localization.localeFactory) {
            console.log("Box: ", this.box);
        }

        public descrizioneBox(): string {
            if (this.box == undefined) return "";
            var descr = this.localeFactory.getFormatted("label_box", [this.box.Descrizione, this.formatDate(this.box.DataCreazione)]);
            return descr;
        }
        public showFlagEseguito(item: Produzione.TrattamentoVm): boolean {
            return (item.idTrattamento == 1 && this.contenuto.FlgUltrasuoni) ||
                (item.idTrattamento == 2 && this.contenuto.FlgLavaggioManuale);
        }
        public callFunction(item: Produzione.AzioneVm): void {
            if (this.box == undefined) return;

            console.log("Azione per il Box", this.box);
            triggerBoxEvent("listRowBoxAction", this.box, item.funzione);
        }

        public showButton(item: Produzione.AzioneVm): boolean {
            return true;
        }

        public formatDate(data: Date): string {
            var result = moment(data).format("DD/MM/YYYY");
            return result;
        }

        public localized(item: any, campo: string): string {
            if (item == undefined) return "";
            var result = item[campo + dbCulture];
            if (result == undefined)
                result = item[campo + culture.capitalize()];

            return result;
        }
    }
}
app.controller("listRowBoxController", Trace.Components.listRowBoxController);

app.component("listRowBox", {
    templateUrl: "/views/Componenti/listRowBox.html",
    controller: "listRowBoxController",
    controllerAs: "vm",
    bindings: {
        box: "<",
        azioni: "=",
        selezionato: "=",
        rowid: "="
    }
});


app.component("contenutoBox", {
    templateUrl: "/views/Lavaggio/contenutoBox.html",
    controller: "listRowBoxController",
    controllerAs: "vm",
    bindings: {
        contenuto: "<",
        trattamenti: "<",
        selezionato: "="
    }
});