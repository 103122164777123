app.factory("rotFactory", ($http, $location, $q) => new Trace.Rot.rotFactory($http, $location, $q));

module Trace.Rot {
    export class Rot {
        public Id : number;
        public Codice : string;
        public DataCreazione : Date;
        public DataObsoleto : Date;
        public DataUltimaModifica : Date;
        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public IdUtenteCreazione : number;
        public IdUtenteObsoleto : number;
        public IdUtenteUltimaModifica: number;
        public IdTipo: number;
    }
    export class RotVm extends Rot {        
        public IdTipoRot: number;
        public TipoRotStr: string;
        public IdIntervento: number;
        public IdInterventoDetail: number;
    }

    export class rotFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Rot/";
        }

        static $inject = ["$http", "$location", "$q"]; 

        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public getInterventiAssociazioneRot(codiceRot: string): ng.IPromise<any[]> {
            var workUrl = this.GetBaseUrl() + 'InterventiAssociazioneRot/' + codiceRot;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: any[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public CheckRotAccettatoMoreThanOne(codiceRot: string): ng.IPromise<any> { // any => { MoreThanOne: true/false, IdInterventoDetail: id nel caso sia un solo intervento}
            var workUrl = this.GetBaseUrl() + 'CheckRotAccettatoMoreThanOne/' + codiceRot;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: any, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        public CheckRotSmaltitoMoreThanOne(codiceRot: string): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + 'CheckRotSmaltitoMoreThanOne/' + codiceRot;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: any, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public getSimpleTipiRot(flgStampabili: boolean = null): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + 'GetSimpleTipiRot';
            if (flgStampabili != null) workUrl+="/" + flgStampabili;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public lista(): ng.IPromise<Rot[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Rot[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaCompleta(): ng.IPromise<Rot[]> {
            var workUrl = this.GetBaseUrl() + "ListaCompleta";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Rot[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(elemento: Rot): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, elemento).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public modifica(elemento: Rot): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + elemento.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, elemento).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public cancella(idElemento: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idElemento;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public patch(idElemento: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idElemento;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, prodotto: boolean = false): ng.IPromise<Rot[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca/' + prodotto;

            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Rot[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public leggi(idElemento: number): ng.IPromise<Rot> {
            var workUrl = this.GetBaseUrl() + idElemento;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Rot, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggiByCodice(codice: string): ng.IPromise<Rot> {
            var workUrl = this.GetBaseUrl() + codice;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Rot, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public riepilogo(): ng.IPromise<RiepilogoRot[]> {
            var workUrl = this.GetBaseUrl() + "Riepilogo";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SmaltimentoByCodice(codice: string): ng.IPromise<RotVm> {
            var workUrl = this.GetBaseUrl() + "SmaltimentoByCodice/" + codice;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }



        public RegistraSmaltimento(model: SmaltimentoRotVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + 'RegistraSmaltimento';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, model).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        }

        public RicercaSmaltimenti(filtro: Trace.ViewModels.RicercaVm, page: number): ng.IPromise<ViewModels.GenericPaginationVm<VwSmaltimentoRot>> {
            var workUrl = this.GetBaseUrl() + 'RicercaSmaltimenti/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        }

        public RicercaAnalisiRot(filtro: Trace.Rot.FiltroAnalisiRot): ng.IPromise<VwAnalisiRot[]> {
            var workUrl = this.GetBaseUrl() + 'RicercaAnalisiRot';
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        }

        public GetSmaltimento(id: number): ng.IPromise<SmaltimentoRotVm> {
            var workUrl = this.GetBaseUrl() + 'GetSmaltimento/' + id;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        }

        public GetDepositiSmaltimento(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + 'GetDepositiSmaltimento';
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        }

        public GetUtentiSmaltimento(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + 'GetUtentiSmaltimento';
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );

            return deferred.promise;
        }
    }
}