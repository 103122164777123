app.factory("tracciabilitaFactory", ($http, $q) => new Trace.Tracciabilita.tracciabilitaFactory($http, $q));
module Trace.Tracciabilita {
    export class tracciabilitaFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Tracciabilita/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public RicercaMateriale(filtro: FiltroTracciabilitaMateriale): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + "RicercaMateriale";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaTnt(filtro: FiltroTracciabilitaTnt): ng.IPromise<TracciabilitaTntVm[]> {
            var workUrl = this.GetBaseUrl() + "RicercaTnt";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaSet(filtro: FiltroTracciabilitaSet): ng.IPromise<TracciabilitaSetVm> {
            var workUrl = this.GetBaseUrl() + "RicercaSet";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ComposizioneSet(filtro: FiltroTracciabilitaComposizioneSet): ng.IPromise<VwComposizioneSet[]> {
            var workUrl = this.GetBaseUrl() + "RicercaComposizioneSet";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public StrumentiMancanti(filtro: FiltroTracciabilitaComposizioneSet): ng.IPromise<VwStrumentiMancanti[]> {
            var workUrl = this.GetBaseUrl() + "RicercaMancanti";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public QuerySearch(query: string): ng.IPromise<QuerySearchResultVm[]> {
            var workUrl = this.GetBaseUrl() + "QuerySearch/" + query;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}