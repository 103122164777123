
module Trace.MappaturaCentrale { 
    export class RicercaMappaturaCentrale {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string; 
    }

    export class mappatureCentraliController {
        public listaMappe: MappaturaCentraleVm[];
        public recordCorrente: MappaturaCentraleVm;
        public titoloManipola: string;
        public testoAzione: string;

        public areeCentrale: Trace.Centrale.Aree[];
        //public centrali: Helpers.SimpleEntity[];
        public centraliMappabili: Centrale.CentraleMappabileVm[];
        public centraliRicerca: Centrale.Centrale[];

        public currentState: string;
        public currentPage: number;

        public showValidationMessages: boolean;
        public formMappature: any;
        public FlgModificheDettagli: boolean;
        //public recordCorrente: MappaturaCentrale.MappaturaCentraleVm;
    //    public recordCorrente: Gruppo;
    //    public testoAzione: string;
        public ricerca: RicercaMappaturaCentrale;

    //    public currentState: string;
    //    public titoloManipola: string;

    //    public showValidationMessages: boolean;
    //    public formGruppi: any;

        public detailToAdd: MappaturaCentraleDetailsVm;
        public detailToEdit: MappaturaCentraleDetailsVm;
        public detailToEdit_BACKUP: MappaturaCentraleDetailsVm;
        public addDetailErrors: any;
        public editDetailErrors: any;
        public addDetailSubmitted: boolean;


        static $inject = ['$scope', 'mappatureCentraliFactory','centraliFactory', 'localeFactory', 'msgFactory', 'dtFactory' ];
        constructor(private $scope: ng.IScope,
            private mappatureCentraliFactory: MappaturaCentrale.mappatureCentraliFactory,
            private centraliFactory: Trace.Centrale.centraliFactory,
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory) {

            //centraliFactory.asSe().then((risultato: Helpers.SimpleEntity[]) => {
            //    this.centrali = risultato;
            //});

            centraliFactory.lista().then((risultato: Centrale.Centrale[]) => {
                this.centraliRicerca = risultato;
                var dummy: Centrale.Centrale = new Centrale.Centrale();
                dummy.Descrizione_it_IT = '';
                this.centraliRicerca.unshift(dummy);
            });

            centraliFactory.listaMappabili().then((risultato: Centrale.CentraleMappabileVm[]) => {
                this.centraliMappabili = risultato;               
            });

            this.FlgModificheDettagli = false;

            //this.annulla();
            this.addDetailSubmitted = false;
            this.initDetailToAdd();
            this.addDetailErrors = this.initDetailErrors();
            this.editDetailErrors = this.initDetailErrors();
        }

        public checkEditDetailStatus(dettaglio: any): boolean {
            return dettaglio === this.detailToEdit ? true : false;
        }

        public onCentraleChange() {
            let centrale = this.centraliMappabili.filter(h => h.IdCentrale == this.recordCorrente.IdCentrale)[0];
            this.recordCorrente.CentraleStr = centrale.Descrizione_it_IT;
            this.centraliFactory.listaAree(centrale.IdCentrale).then((risultato: Centrale.Aree[]) => {
                this.areeCentrale = risultato;
            });
        }

        public onAddDetailChange() {
            this.validaDettaglio(this.detailToAdd, this.addDetailErrors, this.addDetailSubmitted);
        }
        public onEditDetailChange() {
            this.validaDettaglio(this.detailToEdit, this.editDetailErrors, true);
        }
        public initDetailErrors(): any {
            return {
                IdArea: { },
                IpAddress: { }
            };
        }
        private addDettaglio() {
            this.FlgModificheDettagli = true;
            let annullaModifiche = true;

            if (this.detailToEdit != undefined) annullaModifiche = this.editDetailFocusOut();

            if (annullaModifiche && this.detailToEdit == undefined) this.doAddDettaglio();
        }
        private doAddDettaglio() {
            this.addDetailSubmitted = true;
            if (!this.validaDettaglio(this.detailToAdd, this.addDetailErrors, this.addDetailSubmitted)) {
                let boh = true;
                return;
            }
            this.detailToAdd.AreaStr = this.areeCentrale.filter(h => h.Id == this.detailToAdd.IdArea)[0].Descrizione_it_IT;
            this.recordCorrente.Dettagli.push(this.detailToAdd);
            this.svuotaAddDetail();       
        }
        private editModeDettaglio(item: Trace.MappaturaCentrale.MappaturaCentraleDetailsVm) {
            this.FlgModificheDettagli = true;
            let annullaModifiche = true;

            if (this.detailToEdit != undefined) annullaModifiche = this.editDetailFocusOut();            

            if (annullaModifiche && this.detailToEdit == undefined) {
                this.detailToEdit_BACKUP = {
                    Id: item.Id,
                    AreaStr: item.AreaStr,
                    IdArea: item.IdArea,
                    IpAddress: item.IpAddress,
                    MacAddress: item.MacAddress,
                    Note: item.Note
                }
                this.detailToEdit = item;
            }
        }
        private deleteDettaglio(item: Trace.MappaturaCentrale.MappaturaCentraleDetailsVm) {
            this.FlgModificheDettagli = true;
            let annullaModifiche = true;

            if (this.detailToEdit != undefined) annullaModifiche = this.editDetailFocusOut();            

            if (annullaModifiche && this.detailToEdit == undefined) this.recordCorrente.Dettagli = this.recordCorrente.Dettagli.filter(h => h !== item);
        }
        private saveDettaglio() {
            if (!this.validaDettaglio(this.detailToEdit, this.editDetailErrors, true)) return; 
            this.detailToEdit.AreaStr = this.areeCentrale.filter(h => h.Id == this.detailToEdit.IdArea)[0].Descrizione_it_IT;
            this.detailToEdit = undefined;
            this.detailToEdit_BACKUP = undefined;            
        }
        public editDetailFocusOut(): boolean {
            this.msgFactory.confirm("Annullare le modifiche al dettaglio modificato?").then(
                result => {
                    if (result.result) {
                        this.doEditDetailFocusOut();
                         return true;
                    } else {
                        return false;
                        //alert("Hai premuto Annulla");
                    }
                }
            );   
            return false;          
        }
        public doEditDetailFocusOut() {
            let i = this.recordCorrente.Dettagli.indexOf(this.detailToEdit);
            this.recordCorrente.Dettagli[i] = this.detailToEdit_BACKUP;
            this.validaDettaglio(this.detailToEdit, this.editDetailErrors, false);
            this.detailToEdit = undefined;
            this.detailToEdit_BACKUP = undefined;
        }

        private validateIPaddress(ipaddress: string): boolean {
            if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
                return true;
            }
            //alert("You have entered an invalid IP address!")
            return false;
        }  


        private validaDettaglio(dettaglio: MappaturaCentraleDetailsVm, errors: any, submitted: boolean): boolean {
            //errors = this.initDetailErrors();
            
            errors["IdArea"] = {};
            errors["IpAddress"] = {};
            var result = true;
            if (submitted) {
                if (dettaglio.IdArea == undefined || dettaglio.IdArea.toString() == "null") {
                    result = false;
                    errors["IdArea"].isValid = false;
                    errors["IdArea"].codeType = "validazione_obbligatorio";
                } else errors["IdArea"].isValid = true;
                if (dettaglio.IpAddress == undefined || dettaglio.IpAddress == "") {
                    result = false;
                    errors["IpAddress"].isValid = false;
                    errors["IpAddress"].codeType = "validazione_obbligatorio";
                } else if (!this.validateIPaddress(dettaglio.IpAddress)) {
                    result = false;
                    errors["IpAddress"].isValid = false;
                    errors["IpAddress"].codeType = "validazione_formato_ip";
                }
                else errors["IpAddress"].isValid = true;               
            }
            return result;
        }
        private svuotaAddDetail() {
            this.addDetailSubmitted = false;
            this.initDetailToAdd();
            this.validaDettaglio(this.detailToAdd, this.addDetailErrors, this.addDetailSubmitted);
        }
        private initDetailToAdd() {
            this.detailToAdd = <MappaturaCentraleDetailsVm>{
                Id: undefined,
                IdArea: undefined,
                AreaStr: undefined,
                IpAddress: undefined,
                MacAddress: undefined,
                Note: undefined
            };
        }        


        public backToList(): void {
            this.changeState('list');
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formMappature.$valid || this.recordCorrente.Dettagli.length < 1) {
                this.showValidationMessages = true;
                this.formMappature.$setDirty();
                let errorMsg = '';
                if (!this.formMappature.$valid) errorMsg += this.localeFactory.get("validazione_errore") + ". ";
                if (this.recordCorrente.Dettagli.length < 1) errorMsg += this.localeFactory.get("validazione_num_dettagli");
                this.msgFactory.error(errorMsg);
                return;
            }
            
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_salvataggio_conferma"));

            conf.then(result => {
                if (!result.result) return;
                var promise = (this.recordCorrente.Id == undefined)
                    ? this.mappatureCentraliFactory.inserisci(this.recordCorrente)
                    : this.mappatureCentraliFactory.modifica(this.recordCorrente);

                promise.then(
                    result => {
                        var alertPromise = this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                        alertPromise.then(result => { this.changeState("list"); });
                    },
                    reason => {
                        this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_errore", [reason.toString()]));
                    });
            });
        }

        public aggiungi(): void {
            //this.FlgModificheDettagli = false;
            //this.formMappature.$setPristine();
            //this.showValidationMessages = false;
            //this.addDetailErrors = this.initDetailErrors();

            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = new MappaturaCentraleVm(); // init
            //this.recordCorrente.Id = undefined;
            this.recordCorrente.IdCentrale = null;
            this.recordCorrente.Dettagli = new Array<Trace.MappaturaCentrale.MappaturaCentraleDetailsVm>();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.mappatureCentraliFactory.ricerca(ricerca, page).then((risultato: Trace.MappaturaCentrale.MappaturePaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.listaMappe = r.List;
            });
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                vm.changeState('list');
            });
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.initDetailToAdd();
                    this.detailToEdit = undefined;
                    this.editDetailErrors = this.initDetailErrors();
                    this.FlgModificheDettagli = false;
                    this.formMappature.$setPristine();
                    this.showValidationMessages = false;
                    this.addDetailErrors = this.initDetailErrors();

                    if (this.recordCorrente.IdCentrale != undefined) this.onCentraleChange();
                    break;
            }
        }  

        public leggi(id: number): void {
            //this.editMode = true;
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.mappatureCentraliFactory.leggi(id).then((risultato: Trace.MappaturaCentrale.MappaturaCentraleVm) => {
                this.recordCorrente = risultato;
                this.changeState("manipola");
                console.log("dettagli della mappatura", risultato);
                //this.strumenti = risultato.Strumenti;
                //this.modelliSetFactory.leggi(this.set.IdModelloSet).then(modelloResult => {
                //    this.modello = modelloResult;
                //    this.changeState("manipola");
                //});
            });
        }


    }
}
app.controller("mappatureCentraliController", Trace.MappaturaCentrale.mappatureCentraliController);