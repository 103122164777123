module Trace.Packaging {
    export class packagingController extends epProduzioneController {
        public associazioniLottiSterilizzazione: Array<Produzione.AssociazioneLottoSterilizzazioneSet>;
        public gruppi: Trace.Gruppo.Gruppo[];
        public sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];
        public depositi: Deposito.DepositoVm[];
        public idStrumentoSelezionato: number;

        static $inject = [
            "$rootScope", "$scope", "packagingFactory", "centraliFactory",
            "setFactory", "produzioneFactory", "localeFactory", "msgFactory",
            "$uibModal", "$element", "dragularService",
            'gruppiFactory', 'sottoGruppiFactory', 'sediFactory', 'centriCostoFactory',
            'depositoFactory', '$document', "utenteFactory", "tracciabilitaFactory"];
        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private packagingFactory: packagingFactory,
            private centraliFactory: Centrale.centraliFactory,
            public setFactory: Set.setFactory,
            public produzioneFactory: Produzione.produzioneFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            private $element: any,
            private dragularService: any,
            private gruppiFactory: Gruppo.gruppiFactory,
            private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory,
            public depositoFactory: Deposito.depositoFactory,
            public $document: any,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($rootScope, $scope, $uibModal, localeFactory, msgFactory, setFactory, undefined, undefined, produzioneFactory, 10, "packaging", $document, null, utenteFactory, tracciabilitaFactory);
            this.myRoot = <epRootScope>$rootScope;
            var vm = this;
            this.setUpAzioni();
            this.setUpWatchers();
            this.getGruppi();
            $(document).on("barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkEventHandler(e);
            });
            $(document).on("barcodeAssociazioneSter", (e: JQueryEventObject) => {
                this.associazioneSterHandler(e.lettura);
            });
            $(document).on("stampaLottiSterilizzazione", (e: JQueryEventObject) => {
                // this.stampaEtichetteLottiSterilizzazione(e.macchina);
            });
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.lotto.IdGruppoDest", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppi = undefined;
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSottogruppi(newVal);
            });
            this.$scope.$watch("vm.lotto.IdSottoGruppoDest", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSedi(newVal);
            });
            this.$scope.$watch("vm.lotto.IdSedeDest", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCosto = undefined;
                else this.getCentriCosto(newVal);
            });
            this.$scope.$watch("vm.lotto.IdCentroCostoDest", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.depositi = undefined;
                else this.getDepositi(newVal);
            });
            this.$scope.$watch('vm.currentState', (newVal: string, oldVal: string) => {
                if (newVal === "list" && this.macchine != undefined) {
                    $.each(this.macchine, (index, item) => {
                        item.Carico = undefined;
                    });
                    this.listaFase();
                    return;
                }
                if (newVal === "manipola") {
                    this.strumentoSelezionato = undefined;
                    this.titoloManipola = this.localeFactory.getFormatted("titolo_dettagli_produzione", [this.descrFase.capitalize(), this.descrizioneLotto()]);
                }
            });
        }

        public changeState(stato: string): void {
            super.changeState(stato);
            if (stato === "manipola")
                this.formDettagliProduzione.$setDirty();
        }

        private getGruppi(): void {
            this.gruppiFactory.lista().then(
                result => {
                    this.gruppi = result;
                }
            );
        }

        private getSottogruppi(idGruppo: number): void {
            this.sottoGruppiFactory.listaGruppo(idGruppo).then(
                result => {
                    this.sottoGruppi = result;
                }
            );
        }

        private getSedi(idSottogruppo: number): void {
            this.sediFactory.listaSottoGruppo(idSottogruppo).then(
                result => {
                    this.sedi = result;
                }
            );
        }

        private getCentriCosto(idSede: number): void {
            this.centriCostoFactory.listaSede(idSede).then(
                result => {
                    this.centriCosto = result;
                }
            );
        }

        private getDepositi(idCentroCosto: number): void {
            this.depositoFactory.listaCentroCosto(idCentroCosto).then(
                result => {
                    this.depositi = result;
                }
            );
        }

        private associazioneSterHandler(lettura: string): void {
            var primaLettera = lettura[0];
            if (primaLettera.toLowerCase() !== 's' && isNaN(parseInt(primaLettera))) return;
            if (this.associazioniLottiSterilizzazione == undefined)
                this.associazioniLottiSterilizzazione = new Array<Produzione.AssociazioneLottoSterilizzazioneSetVm>();
            var incompleti = $.grep(this.associazioniLottiSterilizzazione, (item, index) => {
                return !item.FlgCompleto;
            });
            var a = (incompleti.length <= 0)
                ? new Produzione.AssociazioneLottoSterilizzazioneSetVm()
                : incompleti[0];

            // Se ho letto un codice di un SET
            if (primaLettera.toLowerCase() === 's') {
                a.Codice = lettura;
                this.packagingFactory.GetAssociazioneLottoSterilizzazioneSet(a).then(
                    result => {
                        this.gestisciAssociazione(result);
                    }
                );
            }
            else {
                a.LottoSterilizzazione = lettura;
                this.packagingFactory.GetAssociazioneLottoSterilizzazioneSet(a).then(
                    result => {
                        this.gestisciAssociazione(result);
                    }
                );
            }
        }

        private gestisciAssociazione(a: Produzione.AssociazioneLottoSterilizzazioneSet): void {
            var rowId: number;
            $.each(this.associazioniLottiSterilizzazione, (index, item) => {
                if ((item.IdLotto != undefined && item.IdLotto == a.IdLotto) || (item.IdLottoSterilizzazione != undefined && item.IdLottoSterilizzazione == a.IdLottoSterilizzazione)) {
                    rowId = index;
                    return;
                }
            });
            if (a.IdLotto != undefined && a.IdLottoSterilizzazione != undefined) {
                a.FlgCompleto = true;
                this.associazioniLottiSterilizzazione[rowId] = a;
            }
            else {
                this.associazioniLottiSterilizzazione.push(a);
            }
        }

        private setUpAzioni(): void {
            this.azioni.push(new Produzione.AzioneVm("Dettagli", this.localeFactory.get("dettagli_lotto"), "fa-search fa-2x", "btn-touch btn-secondary"));
        }

        public initModule(): void {
            var vm = this;
            this.listaMacchine(TipoMacchina.AUTOCLAVE);
            setTimeout(function () {
                vm.cambiaAltezzaMacchine();
            }, 500);
        }

        public listaFase(): void {
            this.idLottoSelezionato = undefined;
            this.packagingFactory.Lista().then(
                result => {
                    var carichi = new Array<Produzione.CaricoMacchinaVm>();
                    if (result != undefined && result.length > 0) {
                        $.each(result, (index, item) => {
                            var c = new Produzione.CaricoMacchinaVm(TipoMacchina.AUTOCLAVE, item.IdMacchina, item.Id, item.Enabled);
                            c.LottoProduzione = item;
                            carichi.push(c);
                        });
                    }
                    this.attribuisciCaricoMacchine(carichi);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public listaMacchine(idTipoMacchina: number = undefined): void {
            this.centraliFactory.ListaMacchine(idTipoMacchina).then(
                result => {
                    this.macchine = result;
                    this.cambiaAltezzaMacchine();
                    this.listaFase();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_lista_autoclavi_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public mouseDownLotto(e: JQueryEventObject): void {
            this.lottoDrag = e.lotto;
        };

        public barcodeOkEventHandler(e: JQueryEventObject): void {
            //if (this.idMacchinaSelezionata == undefined) return;
            $.each(this.macchine, (idm, m) => {
                m.IdLottoSelezionato = null;
            });
            this.findInLottiCaricati(e.lettura.substr(0, 14));
            this.$scope.$apply();
        }

        public registra(): void {
            var scartoMassimoPeso = 0.2;
            this.showValidationMessages = false;
            if (!this.formDettagliProduzione.$valid) {
                this.showValidationMessages = true;
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                this.formDettagliProduzione.$setDirty();
                return;
            }
            var set = this.descrizioneLotto();
            // calcolo della percentuale di umidità accumulata in autoclave
            var pU = this.calcolaUmiditaAccumulata();
            if (pU > scartoMassimoPeso) {
                var messaggio = this.localeFactory.getFormatted("errore_controllo_peso", [set, pU.toFixed(2), scartoMassimoPeso]);
                this.msgFactory.error(messaggio);
                return;
            }
            var vm = this;
            this.msgFactory.confirm(this.localeFactory.getFormatted("conferma_registrazione_set", [this.descrFase, set])).then(
                result => {
                    if (!result.result) return;
                    vm.doRegistra();
                }
            );
        }

        private doRegistra(): void {
            var vm = this;
            var dati = new Produzione.DatiProduzioneVm();
            dati.PesoUscitaAutoclave = this.lotto.PesoUscitaAutoclave;
            this.produzioneFactory.RegistraDatiProduzione(vm.lotto.Id, dati).then(
                result => {
                    if (result === true) {
                        var lotto = vm.descrizioneLotto();
                        vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_ok", [lotto]));
                        vm.formDettagliProduzione.$setPristine();
                    }
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public associaLottiSterilizzazione(): void {
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Shared/associazioneLottiSterilizzazioneSet.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg"
            };
            this.associazioneLottiSterilizzazione = true;
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => {
                console.log("chiuso");
                this.associazioneLottiSterilizzazione = false;
            });
        }

        public confermaAssociazioneLottiSterilizzazione(): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("conferma_associazione_lotti_sterilizzazione", [this.associazioniLottiSterilizzazione.length])).then(
                result => {
                    if (!result.result) return;
                    this.packagingFactory.SetAssociazioneLottiSterilizzazioneSet(this.associazioniLottiSterilizzazione).then(
                        result => {
                            this.associazioniLottiSterilizzazione = null;
                            this.chiudiModal();
                            this.msgFactory.alert(this.localeFactory.get("associazione_lotti_sterilizzazione_ok"));
                        },
                        reason => {
                            this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_dati_lavorazione_errore", [reason.data]));
                            console.log("errore", reason);
                        }
                    );
                }
            );
        }

        public faseSuccessiva(): void {
            this.isSaving = true;
            this.showValidationMessages = false;
            if (!this.formDettagliProduzione.$valid) {
                this.showValidationMessages = true;
                this.formDettagliProduzione.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                this.isSaving = false;
                return;
            }
            this.msgFactory.confirm(this.localeFactory.getFormatted('conferma_salvataggio', [this.descrizioneLotto()])).then(
                result => {
                    if (!result.result) {
                        this.isSaving = false;
                        return;
                    }
                    this.doFaseSuccessiva();
                }
            );
        }

        private doFaseSuccessiva(): void {
            this.isSaving = true;
            var vm = this;
            var info = new Produzione.InfoPackagingVm();
            info.IdLotto = this.lotto.Id;
            info.IdSet = this.lotto.IdSet;
            info.Codice = this.lotto.Codice;
            info.IdDepositoDest = this.lotto.IdDepositoDest;
            this.packagingFactory.faseSuccessiva(info).then(
                result => {
                    var lotto = vm.descrizioneLotto();
                    if (result.Fase == undefined || result.Fase == "") result.Fase = "successiva";
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("avanzamento_set_ok", [lotto, result.Fase])).then(
                        result => {
                            vm.backToList();
                        }
                    );
                    this.isSaving = false;
                },
                reason => {
                    vm.msgFactory.error(vm.localeFactory.getFormatted("avanzamento_set_errore", [reason.data]));
                    console.log("errore", reason);
                    this.isSaving = false;
                }
            );
        }
    }
}
app.controller("packagingController", Trace.Packaging.packagingController);