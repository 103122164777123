module Trace.Rot {
    export class smaltimentoRotController extends epProduzioneController {
        static $inject = [
            "$rootScope", "$scope", "$uibModal", "dtFactory", "localeFactory", "msgFactory", "Upload", "rotFactory", "utenteFactory", "$document", "depositoFactory", "tracciabilitaFactory"
        ];

        public listaSmaltimenti: Array<VwSmaltimentoRot>;
        private listaRot: Array<RotVm>;
        private formSmaltimentoRot: any;
        private listaMacchine: Array<Helpers.SimpleEntity>;
        private smaltimento: SmaltimentoRotVm;
        private tipiRot: Array<Helpers.SimpleEntity>;
        private newRot: RotVm;

        private interventiRot: any[];
        private interventoSelezionato: any;
        //private showAddDetailError: boolean;
        //private formAddDetail: any;

        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            public $uibModal: ng.ui.bootstrap.IModalService,
            private dtFactory: datatables.dtFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public upload: ng.angularFileUpload.IUploadService,
            private rotFactory: rotFactory,
            public utenteFactory: Utente.utenteFactory,
            public $document: any,
            private depositoFactory: Deposito.depositoFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($scope, $rootScope, $uibModal, localeFactory, msgFactory, undefined, undefined, undefined, undefined, 0, "smaltimentoRot", $document, upload, utenteFactory, tracciabilitaFactory);
            this.myRoot = <epRootScope>$rootScope;
            //this.formSmaltimentoRot = {};
            //this.formAddDetail = {};
            this.getListaMacchine();
            this.getSimpleTipiRot();
            $(document).on("barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkEventHandler(e);
            });
        }

        private getListaMacchine(): void {
            this.depositoFactory.GetMacchine().then(
                result => {
                    this.listaMacchine = result;
                },
                reason => { }
            );
        }

        private getSimpleTipiRot(): void {
            this.rotFactory.getSimpleTipiRot().then(
                result => {
                    this.tipiRot = result;
                },
                reason => { }
            );          
        }

        private selezionaIntervento(item: any) {
            this.interventoSelezionato = item;
        }

        private getInterventiRot(codiceRot: string): void {
            this.rotFactory.getInterventiAssociazioneRot(codiceRot).then(
                result => {
                    this.interventiRot = result;
                },
                reason => { }
            );
        }

        public associaIntervento(codiceRot: string): void {
            this.interventoSelezionato = undefined;
            this.interventiRot = new Array();
            this.getInterventiRot(codiceRot);
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Rot/Smaltimento/modalListaInterventi.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }   

        public addDetail() {
            if (this.newRot.Codice == undefined || this.newRot.Codice.length < 1) {
                this.msgFactory.alert(this.localeFactory.get("rot_non_valido"));
                return;
            }

            if (this.newRot.IdTipoRot == null || this.newRot.IdTipoRot.toString() == "null") {
                this.rotFactory.leggiByCodice(this.newRot.Codice).then(
                    result => {
                        this.newRot.IdTipoRot = result.IdTipo;
                        this.addDetailStep1();
                    },
                    error => {
                        this.msgFactory.alert(this.localeFactory.getFormatted("codice_rot_non_trovato", [this.newRot.Codice]));
                    }
                );
            }
            else this.addDetailStep1();
        }

        public addDetailStep1() {
            if (this.newRot.IdTipoRot == null || this.newRot.IdTipoRot.toString() == "null") {
                this.msgFactory.alert(this.localeFactory.get("rot_non_valido"));
                return;
            }

            //this.showAddDetailError = true;
            //if (this.formAddDetail.$invalid) return;

            if (this.currentState === 'details') return;
            var exists = this.findInList(this.newRot.Codice);
            if (exists) return;

            this.rotFactory.CheckRotSmaltitoMoreThanOne(this.newRot.Codice).then(
                result => {
                    this.newRot.Id = result.IdRot;
                    if (result.MoreThanOne) {
                        this.msgFactory.confirm(this.localeFactory.get("confirm_rot_smaltito_piu_volte")).then(
                            result => {
                                if (!result.result) return;
                                this.addDetailStep2();
                            }
                        );
                    }
                    else this.addDetailStep2();
                }
                , reason => {
                    this.msgFactory.alert(this.localeFactory.getFormatted("codice_rot_non_trovato", [this.newRot.Codice]));
                }
            );
        }

        public addDetailStep2() {
            this.rotFactory.CheckRotAccettatoMoreThanOne(this.newRot.Codice).then(
                result => {
                    if (result.MoreThanOne) {
                        this.associaIntervento(this.newRot.Codice);
                    }
                    else {
                        this.doAddDetail(result.IdInterventoDetail);
                    }
                },
                reason => {
                    this.msgFactory.alert(this.localeFactory.getFormatted("codice_rot_non_trovato", [this.newRot.Codice]));
                }
            );
        }

        public doAddDetail(idInterventoDetail: number = null) {
            this.newRot.IdInterventoDetail = idInterventoDetail;

            this.listaRot.push(this.newRot);
            this.svuotaAddDetail();
        }

        private svuotaAddDetail(): void {
            //this.showAddDetailError = false;
            this.newRot = <RotVm>{};
        }

        private dataCreazioneChanged(ricerca: any): void {
            var vm = this;
            setTimeout(() => {
                ricerca.DataCreazione = $('.datetime').val();
                vm.dtFactory.aggiornaRicerca();
            }, 500);
        }

        private getMacchinaSmaltimento(id: number): string {
            if (this.listaMacchine == undefined || id == undefined) return "";
            var result = $.grep(this.listaMacchine, (elem, index) => {
                return elem.Id == id;
            });

            return result == undefined ? "" : result[0].Descrizione;
        }

        public init(): void {
            this.changeState("list");
        }

        private leggiSmaltimento(id: number): void {
            this.rotFactory.GetSmaltimento(id).then(
                result => {
                    //this.showAddDetailError = false;
                    this.smaltimento = result;
                    this.listaRot = this.smaltimento.ListaRot;
                    this.changeState("details");
                },
                reason => { }
            );
        }

        public changeState(stato: string) {
            this.currentState = stato;
            switch (stato) {
                case 'list':
                    this.dtFactory.aggiornaRicerca();
                    setTimeout(() => {
                        $(".datetime").datepicker();
                    }, 500);
                    break;

                case 'manipola':
                    this.newRot = <RotVm>{};
                    //this.showAddDetailError = false;
                    this.showValidationMessages = false;
                    this.smaltimento = new SmaltimentoRotVm();
                    this.listaRot = new Array<RotVm>();
                    break;

                case 'details':
                    break;
            }
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.rotFactory.RicercaSmaltimenti(ricerca, page).then(
                risultato => {
                    var r = risultato;
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                    this.listaSmaltimenti = r.List;
                }
            );
        }

        private aggiungi() {
            this.changeState('manipola');
        }

        private cancella(rot: RotVm) {
            var vm = this;
            this.msgFactory.confirm(this.localeFactory.get("conferma_cancellazione_rot_smaltimento")).then(
                result => {
                    if (!result.result) return;
                    vm.doCancellaRot(rot);
                }
            );
        }

        private doCancellaRot(rot: RotVm): void {
            var idRot = this.listaRot.indexOf(rot);
            this.listaRot.splice(idRot, 1);
        }

        public barcodeOkEventHandler(e: JQueryEventObject): void {
            if (this.currentState === 'details') return;

            this.rotFactory.leggiByCodice(e.lettura).then(
                result => {
                    this.newRot = <RotVm>{
                        IdTipoRot: result.IdTipo,
                        Codice: result.Codice
                    };
                    this.addDetail();
                }, error => {
                    this.msgFactory.alert(this.localeFactory.getFormatted("codice_rot_non_trovato", [this.newRot.Codice]));
                }
            );
            

            //var exists = this.findInList(e.lettura);
            //if (exists) return;
            //this.rotFactory.CheckRotAccettatoMoreThanOne(e.lettura).then(
            //    result => {
            //        if (result) {
            //            this.associaIntervento(e.lettura);
            //        }
            //    },
            //    reason => {
            //        this.msgFactory.alert(this.localeFactory.getFormatted("codice_rot_non_trovato", [e.lettura]));
            //    }
            //);
            //this.rotFactory.SmaltimentoByCodice(e.lettura).then(
            //    result => {
            //        result.IdTipoRot = 1;
            //        this.listaRot.push(result);
            //    },
            //    reason => {
            //        this.msgFactory.alert(this.localeFactory.getFormatted("codice_rot_non_trovato", [e.lettura]));
            //    }
            //);
        }

        public findInList(lettura: string): boolean {
            var vm = this;
            var result: boolean = false;
            if (lettura == undefined) return false;
            $.each(this.listaRot, (index, item) => {
                console.log("findInList", item.Codice, lettura.substr(0, item.Codice.length));
                if (item.Codice === lettura.substr(0, item.Codice.length)) {
                    
                    result = true;
                    return;
                }
            });

            return result;
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formSmaltimentoRot.$valid) {
                this.showValidationMessages = true;
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                this.formSmaltimentoRot.$setDirty();
                return;
            }

            if (this.listaRot == undefined || this.listaRot.length <= 0) {
                this.msgFactory.error(this.localeFactory.get("errore_lista_smaltimento_vuota"));
                return;
            }
            else {
                let missTipo = this.listaRot.filter(h => h.IdTipoRot == null || h.IdTipoRot.toString() == "null");
                if (missTipo != undefined && missTipo.length > 0) {
                    this.msgFactory.alert(this.localeFactory.get("rot_non_valido"));
                    return;
                }
            }

            this.smaltimento.ListaRot = this.listaRot;
            this.rotFactory.RegistraSmaltimento(this.smaltimento).then(
                result => {
                    this.msgFactory.alert(this.localeFactory.get("messaggio_registrazione_ok")).then(
                        result => { this.backToList(); }
                    );
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_registrazione_errore", [reason.data]));
                }
            );
        }
    }
}
app.controller("smaltimentoRotController", Trace.Rot.smaltimentoRotController);