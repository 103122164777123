app.factory("documentoFactory", ($http, $q) => new Trace.Documento.documentoFactory($http, $q));
module Trace.Documento {
    export class documentoFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Documento/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public ricerca(filtro: ViewModels.RicercaVm): ng.IPromise<DocumentoVm[]> {
            var workUrl = this.GetBaseUrl() + "Ricerca/1";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public lista(aperti: boolean = undefined): ng.IPromise<DocumentoVm[]> {
            var workUrl = aperti == undefined ? this.GetBaseUrl() : this.GetBaseUrl() + aperti.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public DocumentiFornitori(aperti: boolean): ng.IPromise<DocumentoVm[]> {
            var workUrl = this.GetBaseUrl() + "DocumentiFornitori/" + aperti.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Valida(idDocumento: number): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + "Valida";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idDocumento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public PrintByMovimento(idMovimento: number, idTipoDocumento: number): ng.IPromise<boolean> {
            console.log("Stampo il movimento", idMovimento, idTipoDocumento);
            var workUrl = this.GetBaseUrl() + "PrintByMovimento/" + idMovimento.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idTipoDocumento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Details(idDocumento: number): ng.IPromise<Documento.RigaDocumento[]> {
            var workUrl = this.GetBaseUrl() + "Details/" + idDocumento.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public CreateByMovimento(idMovimento: number, idTipoDocumento: number): ng.IPromise<boolean> {
            console.log("Salvo il documento per il movimento", idMovimento, idTipoDocumento);
            var workUrl = this.GetBaseUrl() + "CreateByMovimento/" + idMovimento.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idTipoDocumento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}