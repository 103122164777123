
app.factory("gestioneManutenzioniFactory", ($http, $q) => new Trace.GestioneManutenzioni.gestioneManutenzioniFactory($http, $q));
module Trace.GestioneManutenzioni {
    export class gestioneManutenzioniFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/GestioneManutenzioni/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public getTipiManutenzione(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "GetTipiManutenzione";
             var deferred = this.$q.defer();
             this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public invioManutenzione(listaManutenzioni: Set.StrumentoVm[]): ng.IPromise<ViewModels.GenericPaginationVm<Set.StrumentoVm>> {
            var workUrl = this.GetBaseUrl() + "InvioManutenzione";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, listaManutenzioni).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, currentPage: number = undefined): ng.IPromise<ViewModels.GenericPaginationVm<Set.StrumentoVm>> {
            var workUrl = this.GetBaseUrl() + "Ricerca/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public leggi(id: number): ng.IPromise<Trace.Manutenzioni.ManutenzioneDetailsVm> {
            var workUrl = this.GetBaseUrl() + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Tracking(id: number): ng.IPromise<ViewModels.TrackingVm[]> {
            var workUrl = this.GetBaseUrl() + "Tracking/" + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}