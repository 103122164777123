
app.factory("gestioneManutenzioniRientriFactory", ($http, $q) => new Trace.GestioneManutenzioniRientri.gestioneManutenzioniRientriFactory($http, $q));
module Trace.GestioneManutenzioniRientri {
    export class gestioneManutenzioniRientriFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/GestioneManutenzioni/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, currentPage: number = undefined): ng.IPromise<ViewModels.GenericPaginationVm<Set.StrumentoVm>> {
            var workUrl = this.GetBaseUrl() + "RicercaRientriTransitPoint/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
        
        public rientroManutenzione(listaRientroTP: Set.StrumentoVm[]): ng.IPromise<ViewModels.GenericPaginationVm<Set.StrumentoVm>> {
            var workUrl = this.GetBaseUrl() + "RientroManutenzione";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, listaRientroTP).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}