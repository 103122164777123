module Trace.SottoGruppo { 
    export class RicercaSottoGruppo {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdGruppo: string;
    }

    export class sottoGruppiController {
        public lista: sottoGruppoVm[];
        public recordCorrente: SottoGruppo;
        public testoAzione: string;
        public gruppi: Helpers.SimpleEntity[];
        public gruppiRicerca: Gruppo.Gruppo[];
        public ricerca: RicercaSottoGruppo;

        public currentState: string;
        public titoloManipola: string;

        public showValidationMessages: boolean;
        public formSottogruppi: any;

        public sottogruppoOld: SottoGruppo;

        static $inject = ['$scope', 'sottoGruppiFactory', 'gruppiFactory', 'localeFactory', 'msgFactory', 'dtFactory'];
        constructor(private $scope: ng.IScope, private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private gruppiFactory: Gruppo.gruppiFactory, private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory, private dtFactory: datatables.dtFactory) {

            gruppiFactory.asSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.gruppi = risultato;
            });

            gruppiFactory.lista().then((risultato: Gruppo.Gruppo[]) => {
                this.gruppiRicerca = risultato;
                var dummy: Gruppo.Gruppo = new Gruppo.Gruppo();
                dummy.Descrizione_it_IT = '';
                this.gruppiRicerca.unshift(dummy);
            });

            this.annulla();
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formSottogruppi.$valid) {
                this.showValidationMessages = true;
                this.formSottogruppi.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }


            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {

                    if (this.recordCorrente.Id == undefined)
                        this.sottoGruppiFactory.inserisci(this.recordCorrente).then((result => {
                            this.annulla();
                        }),
                        (error => {
                            this.msgFactory.alert(error);
                        }));
                    else
                        this.sottoGruppiFactory.modifica(this.recordCorrente).then((result => {
                            this.annulla();
                        }),
                        (error => {
                            this.msgFactory.alert(error);
                        }));
                }
            });
        }

        public annulla(): void {
            this.recordCorrente = new SottoGruppo();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.ricerca = new RicercaSottoGruppo();

            this.sottoGruppiFactory.lista().then((risultato: sottoGruppoVm[]) => {
                this.lista = risultato;
                this.changeState('list');
            });

        }

        public cancella(SottoGruppo: SottoGruppo): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.sottoGruppiFactory.cancella(SottoGruppo.Id).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        private fixCodice(): void {
            if (this.sottogruppoOld != undefined) {
                if (this.sottogruppoOld.IdGruppo != this.recordCorrente.IdGruppo) {
                    this.recordCorrente.Codice = undefined;
                }
                else {
                    this.recordCorrente.Codice = this.sottogruppoOld.Codice;
                }
            }
        }

        public modifica(SottoGruppoVm: sottoGruppoVm): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.sottoGruppiFactory.leggi(SottoGruppoVm.Id).then((sottoGruppo: SottoGruppo) => {
                this.recordCorrente = sottoGruppo;
                this.sottogruppoOld = <SottoGruppo>{
                    IdGruppo: this.recordCorrente.IdGruppo,
                    Codice: this.recordCorrente.Codice
                };
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.changeState('manipola');
            });
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: ViewModels.DirezioneSort, filtro: Array<ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<ViewModels.SortRigaVm>();
            ricerca.sort.push(new ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.sottoGruppiFactory.ricerca(ricerca).then((risultato: sottoGruppoVm[]) => {
                this.lista = risultato;
            });
        }

        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = new SottoGruppo();
            this.sottogruppoOld = undefined;
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formSottogruppi.$setPristine();
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaSottoGruppo();
        }


    }
}

app.controller("sottoGruppoController", Trace.SottoGruppo.sottoGruppiController);