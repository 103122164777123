module Trace.Movimenti {
    export class epMovimentiController extends epBaseController {
        public currentState: string;
        public showValidationMessages: boolean;
        public formDettagliMovimento: any;
        public movimento: MovimentoRigaVm;
        public movimenti: MovimentoVm[];
        public documenti: Documento.DocumentoVm[];
        public depositi: Deposito.DepositoVm[];
        public depositoProvenienzaSelezionato: Deposito.DepositoVm;
        public depositoDestinazioneSelezionato: Deposito.DepositoVm;
        public codiceMateriale: string;
        public qtaMateriale: number;
        public tipiMovimenti: Helpers.SimpleEntity[];
        public tipoMateriale: Produzione.TipoMateriale;
        public tipiMateriali: Helpers.SimpleEntity[];
        public tipiProvenienze: Helpers.SimpleEntity[];
        public materiali: Produzione.MaterialeVm[];
        public materialiVisualizzati: Produzione.MaterialeVm[];
        public ricercaMaterialiResult: Produzione.Materiale[];
        public editMode: boolean;
        public canPrint: boolean;
        public idMaterialeSelezionato: number;
        public materialeSelezionato: Produzione.Materiale;
        public currentPage: number;
        public giacenzaDepositoProvenienza: Produzione.Materiale[];
        public giacenzaDepositoDestinazione: Produzione.Materiale[];
        public giacenze: Produzione.Materiale[];
        public documentoProvenienzaSelezionato: Documento.DocumentoVm;
        public tipiDepositi: Deposito.TipoDepositoVm[];
        public gruppiRicerca: Gruppo.Gruppo[];
        public sottoGruppiRicerca: SottoGruppo.SottoGruppo[];
        public sediRicerca: Sede.Sede[];
        public centriCostoRicerca: CentroCosto.CentroCosto[];
        public sdList: Deposito.DepositoVm[];
        public sdItem: Deposito.DepositoVm;
        public sdListRicerca: Deposito.RicercaDeposito;
        public statiDeposito: any[];
        public ricercaMateriali: Produzione.RicercaMaterialiVm;
        public stati: Helpers.SimpleEntity[];
        public dettaglioMovimentiMateriale: Movimenti.VwDettaglioMovimentoMateriale[];
        public dettaglioOrdiniMateriale: Ordini.DettaglioOrdineMaterialeVm[];

        constructor(
            public $scope: ng.IScope,
            public $rootScope: ng.IRootScopeService,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public dtFactory: datatables.dtFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public tntFactory: Tnt.tntFactory,
            public depositoFactory: Deposito.depositoFactory,
            public utenteFactory: Utente.utenteFactory,
            public movimentiFactory: movimentiFactory,
            public ordiniFactory: Ordini.ordiniFactory,
            public documentoFactory: Documento.documentoFactory,
            public gruppiFactory: Gruppo.gruppiFactory,
            public sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            public sediFactory: Sede.sediFactory,
            public centroCostoFactory: CentroCosto.centroCostoFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory,
            public Upload?: ng.angularFileUpload.IUploadService
        ) {
            super(Upload, utenteFactory, setFactory, prodottiFactory, tntFactory, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);
            this.currentState = "list";
            this.showValidationMessages = false;
            this.formDettagliMovimento = {};
            this.getListaDepositi();
            this.getTipiMovimenti();
            this.getTipiMateriali();
            this.getTipiDepositi();
            this.gruppiFactory.lista().then((risultato: Trace.Gruppo.Gruppo[]) => {
                this.gruppiRicerca = risultato;
            });
        }

        public canMoveQta(): boolean {
            var vm = this;
            if (this.tipoMateriale == undefined) return;
            var arr = $.grep(this.tipiMateriali, (item, index) => {
                return item.Id == vm.tipoMateriale;
            });
            if (arr.length <= 0) return false;
            return arr[0].FullObject === true;
        }
        
        public showMovimenti(item: Produzione.Materiale): void {
            var query: QueryDettaglioMovimenti = {
                Id: item.Id,
                IdTipo: item.IdTipo,
                IdDeposito: this.sdItem.Id
            };
            this.movimentiFactory.DettagliMateriale(query).then(
                result => {
                    this.dettaglioMovimentiMateriale = result;
                    this.showModalDettagliMovimentiMateriali();
                }
            );
        }

        public showOrdini(item: Produzione.Materiale): void {
            console.log("dettaglio ordini", item);
            var query: QueryDettaglioMovimenti = {
                Id: item.Id,
                IdTipo: item.IdTipo,
                IdDeposito: this.sdItem.Id
            };
            this.ordiniFactory.DettagliMateriale(query).then(
                result => {
                    this.dettaglioOrdiniMateriale = result;
                    this.showModalDettagliOrdiniMateriali();
                }
            );
        }

        public eliminaMaterialiVisualizzati(): void {
            var vm = this;
            vm.materiali = $.grep(this.materiali, function (el) { return $.inArray(el, vm.materialiVisualizzati) == -1 });
            vm.filtraTipoMateriali();
        }

        public eliminaMaterialiInScorta(): void {
            this.materiali = $.grep(this.materiali, (el, index) => {
                return el.FlgSottoScorta && el.FlgSottoRiordino;
            });
            this.filtraTipoMateriali();
        }

        public showModalDettagliOrdiniMateriali(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Deposito/sd-listaOrdini.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => {
                vm.dettaglioMovimentiMateriale = undefined;
            });
        }

        public showModalDettagliMovimentiMateriali(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Deposito/sd-listaMovimenti.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => {
                vm.dettaglioMovimentiMateriale = undefined;
            });
        }

        public aggiornaRicercaSdList(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.depositoFactory.SdList(ricerca).then(
                risultato => {
                    this.sdList = risultato;
                }
            );
        }

        public aggiornaRicercaMateriali(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            if (this.sdItem == undefined) return;
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.depositoFactory.Situazione(this.sdItem.Id, ricerca).then(
                risultato => {
                    this.sdItem = risultato;
                    if (this.sdItem.Situazione != undefined && this.sdItem.Situazione.length > 0)
                        this.sdItem.Situazione = this.raggruppaMateriali(this.sdItem.Situazione);
                }
            );
        }

        public getStati(idTipoStato: number): void {
            this.movimentiFactory.Stati(idTipoStato).then(
                result => {
                    this.stati = result;
                }
            );
        }

        private getTipiMateriali(): void {
            this.movimentiFactory.TipiMateriali().then(
                result => {
                    this.tipiMateriali = result;
                }
            );
        }

        private getTipiMovimenti(): void {
            this.movimentiFactory.Tipi().then(
                result => {
                    this.tipiMovimenti = result;
                }
            );
        }

        private getTipiDepositi(): void {
            this.depositoFactory.tipiDeposito().then(
                result => {
                    this.tipiDepositi = result;
                }
            );
        }

        public changeState(stato: string): void {
            var vm = this;
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.editMode = false;
                    this.movimenti = null;
                    this.movimento = null;
                    this.materiali = null;
                    this.idMaterialeSelezionato = null;
                    this.idProdottoSelected = null;
                    this.dtFactory.aggiornaRicerca("", this.currentPage);
                    break;

                case "details":
                    this.editMode = false;
                    break;

                case "carico":
                case "scarico":
                    this.editMode = true;
                    this.formDettagliMovimento.$setPristine();
                    this.depositoProvenienzaSelezionato = null;
                    this.depositoDestinazioneSelezionato = null;
                    this.documentoProvenienzaSelezionato = null;
                    this.giacenzaDepositoDestinazione = null;
                    this.giacenzaDepositoProvenienza = null;
                    this.materiali = null;
                    this.canPrint = false;
                    this.idMaterialeSelezionato = undefined;
                    this.titoloManipola = (this.currentState === "carico") ? this.localeFactory.get("titolo_carico") : this.localeFactory.get("titolo_scarico");
                    break;

                case "sd-list":
                    this.movimento = null;
                    this.editMode = false;
                    break;

                case "sd-details":
                    this.editMode = false;
                    this.idMaterialeSelezionato = undefined;
                    this.titoloManipola = this.localeFactory.getFormatted("situazione_deposito", [this.sdItem.Descrizione]);
                    break;
            }
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 500);
        }

        public backToList(): void {
            var listState = (this.currentState === "sd-details") ? "sd-list" : "list";
            this.changeState(listState);
        }

        public getListaDepositi(): void {
            this.depositoFactory.listaCompleta().then(
                result => {
                    this.depositi = result;
                    if (this.movimento != undefined) {
                        if (this.movimento.IdDestinazione != undefined)
                            this.depositoDestinazioneSelezionato = this.getDepositoById(this.movimento.IdDestinazione);
                        if (this.movimento.IdProvenienza != undefined && this.movimento.IdTipoMovimento == TipoMovimento.SCARICO)
                            this.depositoProvenienzaSelezionato = this.getDepositoById(this.movimento.IdProvenienza);
                    }
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public getManipolaState(idTipoMovimento: number): string {
            switch (idTipoMovimento) {
                case TipoMovimento.CARICO:
                    return "carico";
                case TipoMovimento.SCARICO:
                    return "scarico";
                default:
                    return "list";
            }
        }

        public getDepositoById(id: number): Deposito.DepositoVm {
            if (this.depositi == undefined)
                return undefined;
            var result = this.depositi.find(item => { return item.Id === id; });
            return result;
        }

        public getDocumentoById(id: number): Documento.DocumentoVm {
            var result = $.grep(this.documenti, (item, index) => { return item.Id === id; });
            return (result == undefined || result.length <= 0) ? null : result[0];
        }

        public numeroMateriali(materiali: Produzione.Materiale = undefined): number {
            var mat = materiali == undefined ? this.materiali : materiali;
            if (mat == undefined) return 0;
            var somma = 0;
            $.each(mat, (index, item) => {
                somma += item.Qta;
            });
            return somma;
        }

        public getMovimentiDetailsFromMateriali(idTipoMovimento: number, materiali?: Produzione.Materiale[]): Movimenti.MovimentoDetail[] {
            if (this.materiali == undefined)
                return null;
            var details = new Array<Movimenti.MovimentoDetail>();
            var mat = materiali != undefined ? materiali : this.materiali;
            if (idTipoMovimento == TipoMovimento.CARICO)
                mat = mat.filter(elem => elem.QtaValidata > 0);

            var campoQta = idTipoMovimento == TipoMovimento.CARICO ? "QtaValidata" : "Qta";
            mat.forEach(item => {
                if (item.Componenti && item.Componenti.length > 0)
                    item.Componenti.forEach(c => this.addItemToMovimento(details, c, campoQta));
                else
                    this.addItemToMovimento(details, item, campoQta);
            });
            return details;
        }

        private addItemToMovimento(details: Movimenti.MovimentoDetail[], item: Produzione.Materiale, campoQta: string): void {
            console.log("aggiungo il seguente materiale al dettaglio del movimento", item);
            console.log("campoQta", campoQta);
            var d = new MovimentoDetailVm();
            switch (item.IdTipo) {
                case Produzione.TipoMateriale.PRODOTTO:
                case Produzione.TipoMateriale.ROT:
                    d.IdProdotto = item.Id;
                    break;
                case Produzione.TipoMateriale.STRUMENTO:
                    d.IdStrumento = item.Id;
                    d.Uid = item.Uid;
                    break;
                case Produzione.TipoMateriale.SET:
                    d.IdSet = item.Id;
                    break;
                case Produzione.TipoMateriale.TNT:
                    d.IdTnt = item.Id;
                    break;
            }
            d.Qta = item[campoQta];
            d.IdDocumentoDetail = item.IdDocumentoDetail;
            d.IdDocumento = item.IdDocumento;
            d.IdOrdine = item.IdOrdine;
            d.IdOrdineDetail = item.IdOrdineDetail;
            d.DataScadenzaLotto = item.DataScadenzaLotto;
            d.Lotto = item.Lotto;
            //d.LottoMateriale = item.LottoMateriale;
            d.Uid = item.Uid;
            d.Matricola = item.Matricola;
            console.log("details", d);

            details.push(d);
        }

        public printMovimento(id: number, idTipoDocumento: number): void {
            this.documentoFactory.PrintByMovimento(id, idTipoDocumento);
        }

        public showGiacenza(giacenze: Produzione.Materiale[]): void {
            var vm = this;
            vm.giacenze = giacenze;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Deposito/modalGiacenza.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg",
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => { this.giacenze = undefined; });
        }

        public propostaApprovvigionamento(giacenze: Produzione.Materiale[]): void {
            var vm = this;
            var daApprovvigionare = new Array<Produzione.MaterialeVm>(); 
            $.each(giacenze, (index, item) => {
                if (item.MancanteMassimo != undefined && item.MancanteMassimo > 0) {
                    var dae = angular.copy(item);
                    dae.Qta = 0;
                    daApprovvigionare.push(<Produzione.MaterialeVm>dae);
                }
            });

            if (daApprovvigionare == undefined || daApprovvigionare.length <= 0) {
                this.msgFactory.alert(this.localeFactory.get('no_materiale_approvvigionamento'));
                return;
            }

            this.materiali = daApprovvigionare;
            this.filtraTipoMateriali();
        }



        public filtraTipoMateriali(): void {
            if (this.tipoMateriale == undefined) {
                this.materialiVisualizzati = angular.copy(this.materiali);
                console.log("filtro il tipo materiale", this.materialiVisualizzati);
                return;
            }

            if (this.tipoMateriale == Produzione.TipoMateriale.CONSUMABILI)
                this.materialiVisualizzati = (this.materiali == undefined) ? undefined : $.grep(this.materiali, (item, index) => {
                    console.log(item.IdTipo, item.IdTipoProdotto);
                    return item.IdTipo === 1 && item.IdTipoProdotto === 1003;
                });
            else {
                this.materialiVisualizzati = (this.materiali == undefined) ? undefined : $.grep(this.materiali, (item, index) => {
                    return item.IdTipo === this.tipoMateriale;
                });
            }
        }

        public showComponenti(element: Produzione.Materiale): void {
            var vm = this;
            vm.materialeSelezionato = element;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Deposito/modalComponenti.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg",
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.result.then(() => { vm.materialeSelezionato = undefined; });
        }

        public updateSoglie(item: Produzione.Materiale): void {
            item.FlgSottoRiordino = (item.QtaRiordino != undefined) ? item.QtaRiordino > item.Qta + item.QtaAttuale : false;
            item.FlgSottoScorta = (item.ScortaMinima != undefined) ? item.ScortaMinima > item.Qta + item.QtaAttuale : false;
        }

        public contieneTnt(lista: Array<Produzione.MaterialeVm>, item: Produzione.MaterialeVm, element: Produzione.Materiale): number {
            var esiste = 0;
            console.log("contiene TNT con lotto?", item.Uid, element.Uid, item.Id, element.Id);
            if (item.Id === element.Id) {
                if (item.Uid === element.Uid) {
                    item.QtaValidata = element.Qta;
                    esiste = 1;
                }
                else if (element.Uid != undefined) {
                    // esiste il componente generico
                    var chk = $.grep(lista, (l, idl) => {
                        return l.Uid === element.Uid;
                    });
                    esiste = chk.length > 0
                        ? 3
                        : (item.Uid != undefined) ? 1 : 0;
                }
            }
            return esiste;
        }

        public contieneProdotto(item: Produzione.MaterialeVm, element: Produzione.Materiale): number {
            if (element.IdTipoProdotto == 1003) {
                return item.Codice === element.Codice && item.Lotto == element.Lotto && (item.DataScadenzaLotto == element.DataScadenzaLotto || moment(element.DataScadenzaLotto).isSame(item.DataScadenzaLotto)) ? 3 : 0;
            }
            var esiste = item.Codice === element.Codice ? 3 : 0;
            return esiste;
        }

        public contieneComponente(item: Produzione.MaterialeVm, element: Produzione.Materiale): number {
            if (item.Componenti == undefined || item.Componenti.length <= 0) return 0;
            var esiste = 0;
                $.each(item.Componenti, (index, comp) => {
                    console.log("contiene componente?", comp.Uid, element.Uid, comp.Id, element.Id);
                    if (comp.Uid === element.Uid && comp.Id === element.Id) {
                        esiste = comp.FlgReale ? 2 : 1;
                        if (esiste === 1)
                            comp.FlgReale = true;
                        comp.QtaValidata = element.Qta;
                        return;
                    }
                });
            return esiste;
        }

        public listaDocumenti(aperti: boolean): void {
            this.documentoFactory.DocumentiFornitori(aperti).then(
                result => {
                    this.documenti = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public provenienzaModificata(idProvenienza: number, idTipoProvenienza: number): void {
            this.movimentiFactory.GetMaterialiProvenienza(idTipoProvenienza, idProvenienza).then(
                result => {
                    switch (idTipoProvenienza) {
                        case 3:
                            this.materiali = this.getMaterialiFromRigheDocumento(result);
                            break;

                        case 5:
                            this.materiali = this.getMaterialiFromRigheOrdine(result);
                            break;
                    }

                    this.filtraTipoMateriali();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public depositoProvenienzaModificato(newValue: number, oldValue: number): void {
            this.giacenzaDepositoProvenienza = null;
            console.log("provenienza modificata", newValue, oldValue);
            if (newValue != undefined) {
                if (this.depositoDestinazioneSelezionato != undefined && this.depositoDestinazioneSelezionato.Id === newValue) {
                    this.movimento.IdProvenienza = oldValue;
                    this.msgFactory.error(this.localeFactory.get("depositi_non_coincidenti"));
                    return;
                }
                this.depositoProvenienzaSelezionato = this.getDepositoById(newValue);
                this.depositoFactory.getGiacenze(newValue).then(
                    result => {
                        this.giacenzaDepositoProvenienza = result;
                        console.log("DEPOSITO DI PARTENZA", this.depositoProvenienzaSelezionato);
                    }
                );
            }
            else
                this.depositoProvenienzaSelezionato = null;
        }

        public documentoProvenienzaModificato(newValue: number, oldValue: number): void {
            this.documentoProvenienzaSelezionato = null;
            console.log("documento provenienza modificata", newValue, oldValue);
            if (newValue === 0) {
                // NON HO DOCUMENTO DI PROVENIENZA
                return;
            }
            if (newValue != undefined) {
                this.documentoProvenienzaSelezionato = this.getDocumentoById(newValue);
                this.documentoFactory.Details(newValue).then(
                    result => {
                        this.documentoProvenienzaSelezionato.Dettagli = result;
                        console.log("Righe documento", result);
                        this.materiali = this.getMaterialiFromRigheDocumento();
                        this.filtraTipoMateriali();
                    }
                );
            }
            else
                this.depositoProvenienzaSelezionato = null;
        }

        public raggruppaMateriali(materiali: Produzione.Materiale[]): Produzione.Materiale[] {
            var result = new Array<Produzione.Materiale>();
            var placed = new Array<number>();
            $.each(materiali, (index, item) => {
                if (placed.indexOf(item.Id) < 0) {
                    placed.push(item.Id);
                    if (item.IdTipo === Produzione.TipoMateriale.SET || item.IdTipo === Produzione.TipoMateriale.STRUMENTO) {
                        item.Componenti = new Array<Produzione.Materiale>();
                        $.each(materiali, (id, element) => {
                            if (element.Codice === item.Codice && element.IdTipo === item.IdTipo) {
                                placed.push(element.Id);
                                item.Componenti.push(element);
                                if (item.Uid != element.Uid) {
                                    console.log("aggiungo la quantità", element.Qta, "a quella generale", item.Qta);
                                    item.Qta += element.Qta;
                                }
                            }
                        });
                    }
                    this.updateSoglie(item);
                    result.push(item);
                }
            });
            return result;
        }
        public getMaterialiFromRigheDocumento(righe?: Documento.RigaDocumento[]): Produzione.MaterialeVm[] {
            if (this.documentoProvenienzaSelezionato == undefined) return null;
            var details = new Array<Produzione.MaterialeVm>();
            var rows = righe != undefined ? righe : this.documentoProvenienzaSelezionato.Dettagli;
            var placed = new Array<number>();
            $.each(rows, (index, item) => {
                if (placed.indexOf(item.Id) < 0) {
                    var father = this.rigaDocumentoToMateriale(item);
                    placed.push(father.Id);
                    if (this.depositoDestinazioneSelezionato != undefined)
                        this.checkGiacenzaDeposito(father, this.giacenzaDepositoDestinazione);
                    if (item.IdTipo === Produzione.TipoMateriale.SET || item.IdTipo === Produzione.TipoMateriale.STRUMENTO) {
                        father.Componenti = new Array<Produzione.MaterialeVm>();
                        $.each(rows, (id, element) => {
                            if (element.Codice === item.Codice && element.IdTipo === item.IdTipo) {
                                var c = this.rigaDocumentoToMateriale(element);
                                placed.push(c.Id);
                                father.Componenti.push(c);
                                if (father.Id !== c.Id)
                                    father.Qta += c.Qta;
                            }
                        });
                        details.push(father);
                    }
                    else
                        details.push(father);
                }
            });
            return details;
        }

        public getMaterialiFromRigheOrdine(righe: Ordini.RigaOrdine[]): Produzione.MaterialeVm[] {
            if (this.movimento == undefined && righe == undefined) return null;
            var details = new Array<Produzione.MaterialeVm>();
            var rows = righe != undefined ? righe : this.movimento.MovimentiDetails;
            var placed = new Array<number>();
            $.each(rows, (index, item) => {
                if (placed.indexOf(item.Id) < 0) {
                    var father = this.rigaOrdineToMateriale(item);
                    placed.push(father.Id);
                    details.push(father);
                }
            });
            return details;
        }

        public getMaterialiFromRigheMovimento(righe?: Movimenti.VwRigaMovimento[]): Produzione.MaterialeVm[] {
            if (this.movimento == undefined && righe == undefined) return null;
            var details = new Array<Produzione.MaterialeVm>();
            var rows = righe != undefined ? righe : this.movimento.MovimentiDetails;
            var placed = new Array<number>();
            $.each(rows, (index, item) => {
                if (placed.indexOf(item.Id) < 0) {
                    var father = this.rigaMovimentoToMateriale(item);
                    placed.push(father.Id);
                    if (item.IdTipo === Produzione.TipoMateriale.SET || item.IdTipo === Produzione.TipoMateriale.STRUMENTO) {
                        father.Componenti = new Array<Produzione.MaterialeVm>();
                        $.each(rows, (id, element) => {
                            if (element.Codice === item.Codice && element.IdTipo === item.IdTipo) {
                                var c = this.rigaDocumentoToMateriale(element);
                                placed.push(c.Id);
                                father.Componenti.push(c);
                                if (father.Uid !== c.Uid)
                                    father.Qta += c.Qta;
                            }
                        });
                        details.push(father);
                    }
                    else
                        details.push(father);
                }
            });
            return details;
        }

        public getMaterialiFromRigheRichiesta(righe?: RichiesteApprovvigionamenti.VwRigaRichiestaApprovvigionamento[]): Produzione.MaterialeVm[] {
            if (this.movimento == undefined && righe == undefined) return null;
            var details = new Array<Produzione.MaterialeVm>();
            var rows = righe != undefined ? righe : this.movimento.RichiestaDetails;
            var placed = new Array<number>();
            $.each(rows, (index, item) => {
                if (placed.indexOf(item.Id) < 0) {
                    var father = this.rigaRichiestaToMateriale(item);
                    placed.push(father.Id);
                    if (item.IdTipo === Produzione.TipoMateriale.SET || item.IdTipo === Produzione.TipoMateriale.STRUMENTO) {
                        father.Componenti = new Array<Produzione.MaterialeVm>();
                        $.each(rows, (id, element) => {
                            if (element.Codice === item.Codice && element.IdTipo === item.IdTipo) {
                                var c = this.rigaRichiestaToMateriale(element);
                                placed.push(c.Id);
                                father.Componenti.push(c);
                                if (father.Uid !== c.Uid)
                                    father.Qta += c.Qta;
                            }
                        });
                        details.push(father);
                    }
                    else
                        details.push(father);
                }
            });
            return details;
        }

        private rigaDocumentoToMateriale(element: Documento.RigaDocumento): Produzione.MaterialeVm {
            var mat = new Produzione.MaterialeVm();
            mat.Codice = element.Codice;
            mat.Descrizione = element['Descrizione_' + dbCulture];
            mat.FlgQta = false;
            mat.Id = element.Id;
            mat.IdTipo = element.IdTipo;
            mat.Qta = element.Qta;
            mat.Tipo = element.Tipo;
            mat.Uid = element.Uid;
            mat.QtaValidata = element.QtaValidata == undefined ? 0 : element.QtaValidata;
            mat.IdDocumento = element.IdDocumento;
            mat.IdDocumentoDetail = element.IdRiga;
            mat.FlgInDocumento = true;
            mat.FlgQta = element.FlgQta;
            mat.FlgReale = element.QtaValidata == undefined ? false : true;
            return mat;
        }

        private rigaOrdineToMateriale(element: Ordini.RigaOrdine): Produzione.MaterialeVm {
            var mat = new Produzione.MaterialeVm();
            mat.Codice = element.Codice;
            mat.Descrizione = element['Descrizione_' + dbCulture];
            mat.FlgQta = false;
            mat.Id = element.ID;
            mat.IdTipo = element.IdTipo;
            mat.Qta = element.Qta;
            mat.Tipo = element.Tipo;
            mat.Uid = undefined;
            mat.QtaValidata = element.QtaEvasa == undefined ? 0 : element.QtaEvasa;
            mat.IdOrdine = element.IdOrdine;
            mat.IdOrdineDetail = element.IdRiga;
            mat.FlgInDocumento = true;
            mat.FlgQta = element.FlgQta;
            mat.FlgReale = element.IdRiga == undefined ? false : true;

            console.log("riga ordine convertita in materiale", mat);
            return mat;
        }

        private rigaMovimentoToMateriale(element: Movimenti.VwRigaMovimento): Produzione.MaterialeVm {
            var mat = new Produzione.MaterialeVm();
            mat.Codice = element.Codice;
            mat.Descrizione = element['Descrizione_' + dbCulture];
            mat.Id = element.ID;
            mat.IdTipo = element.IdTipo;
            mat.Qta = element.Qta;
            mat.Tipo = element.Tipo;
            mat.Uid = element.Uid;
            mat.IdDocumentoDetail = element.IdRiga;
            mat.FlgInDocumento = true;
            mat.FlgQta = element.FlgQta;
            mat.Lotto = element.Lotto;
            mat.DataScadenzaLotto = element.DataScadenzaLotto;
            mat.Matricola = element.Matricola;
            //mat.LottoMateriale = element.LottoMateriale;
            return mat;
        }

        private rigaRichiestaToMateriale(element: RichiesteApprovvigionamenti.VwRigaRichiestaApprovvigionamento): Produzione.MaterialeVm {
            var mat = new Produzione.MaterialeVm();
            mat.Codice = element.Codice;
            mat.Descrizione = element['Descrizione_' + dbCulture];
            mat.Id = element.Id;
            mat.IdTipo = element.IdTipo;
            mat.Qta = element.Qta;
            mat.Tipo = element.Tipo;
            mat.Uid = element.Uid;
            mat.IdDocumentoDetail = element.IdRiga;
            mat.FlgInDocumento = true;
            mat.FlgQta = element.FlgQta;
            mat.Lotto = element.Lotto;
            mat.DataScadenzaLotto = element.DataScadenzaLotto;
            return mat;
        }

        private checkGiacenzaDeposito(mat: Produzione.Materiale, giacenza: Produzione.Materiale[]) {
            var g = $.grep(giacenza, (el, id) => {
                //console.log("cerco giacenza", el.Codice, mat.Codice, el.Id, mat.Id);
                return el.Codice === mat.Codice && el.Id === mat.Id;
            });
            if (g == undefined || g.length <= 0) return;
            mat.QtaRiordino = g[0].QtaRiordino;
            mat.MancanteMassimo = g[0].MancanteMassimo;
            mat.MancanteRiordino = g[0].MancanteRiordino;
            mat.ScortaMassima = g[0].ScortaMassima;
            mat.ScortaMinima = g[0].ScortaMinima;
            mat.FlgSottoRiordino = g[0].FlgSottoRiordino;
            mat.FlgSottoScorta = g[0].FlgSottoScorta;
        }

        public creaDdt(idMovimento: number): ng.IPromise<boolean> {
            return this.documentoFactory.CreateByMovimento(idMovimento, TipoDocumento.DDT);

        }

        public gruppoRicercaChanged(idGruppo: number): void {
            this.sottoGruppiRicerca = [];
            if (idGruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                    this.sottoGruppiRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sottoGruppoRicercaChanged(idSottogruppo: number): void {
            this.sediRicerca = [];
            this.centriCostoRicerca = [];
            if (idSottogruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                    this.sediRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sedeRicercaChanged(idSede: number): void {
            this.centriCostoRicerca = [];
            if (idSede == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.centroCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                    this.centriCostoRicerca = risultato;
                });
            }
        }

        public sdListStatusTitle(tipo: string, flgStato: number): string {
            var msgTipo: string;
            var msg: string;

            switch (tipo) {
                case "P":
                    msgTipo = this.localeFactory.get("prodotti");
                    break;
                case "S":
                    msgTipo = this.localeFactory.get("set");
                    break;
                case "T":
                    msgTipo = "T.N.T.";
                    break;
                case "R":
                    msgTipo = "R.O.T.";
                    break;
            }

            switch (flgStato) {
                case 2:
                    msg = msgTipo + " " + this.localeFactory.get("materiali_non_presenti");
                    break;
                case 1:
                    msg = msgTipo + " " + this.localeFactory.get("materiali_sotto_scorta_minima");
                    break;
                case 0:
                    msg = msgTipo + " " + this.localeFactory.get("materiali_sotto_soglia_riordino");
                    break;
                case -1:
                    msg = msgTipo + " " + this.localeFactory.get("materiali_scorta_ok");
                    break;
                case -2:
                    msg = msgTipo + " " + this.localeFactory.get("materiali_scorta_esubero");
                    break;
            }

            return msg;
        }

        public resetFormSdList(): void {
            this.sdListRicerca = new Deposito.RicercaDeposito();
        }

        public resetFormMateriali(): void {
            this.ricercaMateriali = new Produzione.RicercaMaterialiVm();
        }

        public situazioneDeposito(id: number): void {
            this.depositoFactory.Situazione(id, undefined).then(
                result => {
                    this.sdItem = result;
                    if (this.sdItem.Situazione != undefined && this.sdItem.Situazione.length > 0)
                        this.sdItem.Situazione = this.raggruppaMateriali(this.sdItem.Situazione);
                    this.changeState("sd-details")
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public checkSituazione(item: Produzione.Materiale): number {
            if (item.ScortaMassima == undefined && item.Qta < 0) return 2;
            if (item.ScortaMassima == undefined && item.Qta > 0) return -2;
            if (item.Qta > item.ScortaMassima) return -1;
            if (item.Qta <= item.ScortaMassima && item.Qta >= item.QtaRiordino) return 0;
            if (item.Qta < item.QtaRiordino && item.Qta >= item.ScortaMinima) return 1;
            return 2;
        }

        public addMaterialeToList(element: Produzione.Materiale) {
            if (this.ultimaLetturaBarcode != undefined && this.ultimaLetturaBarcode.ean != undefined && this.ultimaLetturaBarcode.ean.CodiceProduttore == element.Codice) {
                element.Uid = this.ultimaLetturaBarcode.ean.Originale;
                element.DataScadenzaLotto = this.ultimaLetturaBarcode.ean.DataScadenzaLotto;
                element.Lotto = this.ultimaLetturaBarcode.ean.Lotto;
                element.Qta = this.ultimaLetturaBarcode.ean.Unita;
                element.QtaValidata = this.ultimaLetturaBarcode.ean.Unita;
            }
            else if (element.IdTipo == 1 && element.IdTipoProdotto == 1003 && element.Lotto != undefined && element.DataScadenzaLotto != undefined && element.Qta != undefined)
            {
                // esattamente non fare un bel cazzo e lasiare com'è, ho fatto l'aggiungi di un codice clxxxxx
                element.QtaValidata = element.Qta;
            }
            else
            {
                element.Qta = 1;
                element.QtaValidata = 1;
            }

            if (element.DataScadenzaLotto != undefined) (element as any).StrDataScadenzaLotto = moment(element.DataScadenzaLotto).format("DD/MM/YYYY");
            element.FlgQta = (element.IdTipo != 3)

            console.log("Aggiungo alla lista", element);
            var exists = false;
            var generico = false;
            var toadd = new Array<Produzione.MaterialeVm>();
            var toremove = new Array<number>();
            if (this.materiali == undefined) {
                this.materiali = new Array<Produzione.MaterialeVm>();
                var m = <Produzione.MaterialeVm>this.chooseMaterialeLevel(element);
                this.materiali.push(m);
                this.filtraTipoMateriali();
                return;
            }

            $.each(this.materiali, (index, item) => {
                if (item != undefined && element != undefined && item.Codice === element.Codice && item.IdTipo === element.IdTipo) {
                    var esiste = (element.IdTipo === Produzione.TipoMateriale.TNT)
                        ? this.contieneTnt(this.materiali, item, element)
                        : (element.IdTipo === Produzione.TipoMateriale.PRODOTTO || element.IdTipo === Produzione.TipoMateriale.CONSUMABILI)
                            ? this.contieneProdotto(item, element)
                            : this.contieneComponente(item, element);
                    if (esiste > 0 && !(esiste === 1 && element.IdTipo === Produzione.TipoMateriale.TNT)) {
                        exists = true;
                        if (esiste === 2) {
                            this.msgFactory.alert(this.localeFactory.get('materiale_letto'));
                            return;
                        }
                    }

                    if (element.IdTipo === Produzione.TipoMateriale.SET || element.IdTipo === Produzione.TipoMateriale.STRUMENTO) {
                        item.Qta += 1;
                        this.addComponenteMateriale(item, element);
                        exists = true;
                    }
                    else if (element.IdTipo === Produzione.TipoMateriale.TNT) {
                        if (element.Uid != undefined && element.Uid !== "") {
                            if (element.Uid === item.Uid && element.Id === item.Id && element.IdTipo === item.IdTipo) {
                                item.QtaValidata = item.Qta + element.Qta;

                                if (item.FlgInDocumento == true)
                                    item.Qta += element.Qta;
                                else 
                                    item.Qta = item.QtaValidata;

                                exists = true;
                                console.log("Nuova quantità con lotto e scadenza", item, element);
                            }
                            else if (item.Uid == undefined) {
                                if (item.MancanteMassimo != undefined)
                                    item.MancanteMassimo -= element.Qta;
                                else if (item.Qta != undefined) {
                                    item.Qta = (item.QtaValidata != undefined && item.QtaValidata > 0)
                                        ? item.Qta - item.QtaValidata - element.Qta
                                        : item.Qta - element.Qta;
                                }

                                item.QtaValidata += element.Qta;
                                var newQta = item.MancanteMassimo == undefined ? item.Qta : item.MancanteMassimo;
                                console.log("Nuova quantità da approvvigionare", index, newQta);
                                if (newQta <= 0) toremove.push(index);
                                if (esiste <= 1) generico = true;
                            }
                        }
                    }
                    else if (element.IdTipo === Produzione.TipoMateriale.PRODOTTO && element.IdTipoProdotto == 1003) { // mi riferisco ai consumabili
                        if (element.Lotto != undefined && element.DataScadenzaLotto != undefined) {

                            if (element.Lotto === item.Lotto && moment(element.DataScadenzaLotto).isSame(item.DataScadenzaLotto) && element.Id === item.Id && element.IdTipo === item.IdTipo && element.IdTipoProdotto == item.IdTipoProdotto) {
                                item.QtaValidata = item.Qta + element.Qta;

                                if (item.FlgInDocumento == true)
                                    item.Qta += element.Qta;
                                else
                                    item.Qta = item.QtaValidata;

                                exists = true;
                                console.log("Nuova quantità con lotto e scadenza", item, element);
                            }
                            else if (item.Lotto == undefined && item.DataScadenzaLotto == undefined && this.movimento.IdTipoMovimento == 2) {
                                if (item.MancanteMassimo != undefined)
                                    item.MancanteMassimo -= element.Qta;
                                else if (item.Qta != undefined) {
                                    item.Qta = (item.QtaValidata != undefined && item.QtaValidata > 0)
                                        ? item.Qta - item.QtaValidata - element.Qta
                                        : item.Qta - element.Qta;
                                }

                                //item.QtaValidata += element.Qta;
                                var newQta = item.MancanteMassimo == undefined ? item.Qta : item.MancanteMassimo;
                                console.log("Nuova quantità da approvvigionare", index, newQta);
                                if (newQta <= 0) toremove.push(index);
                                if (esiste <= 1) generico = true;
                            }
                        }
                        else {
                            if (item.Lotto == undefined && (item.DataScadenzaLotto == undefined || item.DataScadenzaLotto.toString().trim() == '') && element.Id === item.Id && element.IdTipo === item.IdTipo && element.IdTipoProdotto == item.IdTipoProdotto) {
                                
                                item.Qta += element.Qta;                               

                                exists = true;
                                console.log("Nuova quantità senza lotto scadenza", item, element);

                                
                            }
                        }
                    }
                    else if (element.Uid != undefined && element.Uid !== "" && element.Id === item.Id && element.IdTipo === item.IdTipo) {
                        console.log("Aggiorno qta per NON TNT", index, element.Qta);
                        item.Qta += element.Qta;
                    }
                    this.updateSoglie(item);
                    return;
                }
                //else if (item != undefined)
                    //console.log("non trovato", index, item, element);
                //else
                    //console.log("item nell'array undefined - stato anomalo, non faccio niente", index, item, element);
            });

            if (!exists) {
                var m = <Produzione.MaterialeVm>this.chooseMaterialeLevel(element);
                m.FlgSottoRiordino = false;
                m.FlgInDocumento = false;
                m.FlgSottoScorta = false;
                m.MancanteMassimo = 0;
                toadd.push(m);
            }
            if (toadd.length > 0) {
                $.each(toadd, (taid, ta) => {
                    if (this.materiali.indexOf(ta) < 0)
                        this.materiali.push(<Produzione.MaterialeVm>ta);
                });
            }
            if (toremove.length > 0) {
                $.each(toremove, (trid, tr) => {
                    this.materiali.splice(tr, 1);
                });
            }
            this.filtraTipoMateriali();
            this.ultimaLetturaBarcode = undefined;
        }
        
        private chooseMaterialeLevel(element: Produzione.Materiale): Produzione.Materiale {
            if (element.IdTipo !== Produzione.TipoMateriale.SET && element.IdTipo !== Produzione.TipoMateriale.STRUMENTO) return element;
            var father: Produzione.Materiale = angular.copy(element);
            father.Uid = "";
            this.addComponenteMateriale(father, element);
            console.log("elemento padre", father);
            return father;
        }

        private addComponenteMateriale(father: Produzione.Materiale, element: Produzione.Materiale): void {
            if (father.Componenti == undefined)
                father.Componenti = new Array<Produzione.Materiale>();
            father.Componenti.push(element);
        }
    }
}