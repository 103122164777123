app.factory("valorizzazioneFactory", ($http, $q) => new Trace.Valorizzazione.valorizzazioneFactory($http, $q));
module Trace.Valorizzazione {
    export class valorizzazioneFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Valorizzazione/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public Ricerca(filtro: FiltroValorizzazione): ng.IPromise<VwValorizzazione[]> {
            var workUrl = this.GetBaseUrl() + "Ricerca";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetTipiMateriali(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "GetTipiMateriali";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}