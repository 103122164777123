module Trace.TipoIntervento {
    export class VwTipoInterventoVm implements VwTipoIntervento {
        Id: number;
        Codice: string;
        ComplessitaAl: string;
        ComplessitaEn: string;
        ComplessitaIt: string;
        DataCreazione: Date;
        DataObsoleto: Date;
        DataUltimaModifica: Date;
        Descrizione_en_US: string;
        Descrizione_it_IT: string;
        Descrizione_sq_AL: string;
        DescrizioneTessile: string;
        FlgControllo: boolean;
        IdCausaleUtilizzo: number;
        IdComplessita: number;
        IdMassimaComplessitaSet: number;
        IdUtenteCreazione: number;
        IdUtenteObsoleto: number;
        IdUtenteUltimaModifica: number;
        MassimoNumeroSet: number;
        SetAl: string;
        SetEn: string;
        SetIt: string;
        IdGruppo: number;
        IdSottogruppo: number;
        IdSede: number;
        IdCentroCosto: number;
        NumTessile: number;
        IdMedico: number;
        Medico: string;
        NumeroDdt: number;
        FlgImmagini: boolean;
        FlgCreazioneSet: boolean;
        FlgMocoma: boolean;
        FlgMedico: boolean;
        CodiceMocoma: string|number;
        NumeroGarze: number;
        NumeroTamponi: number;
        NumeroPezze: number;
        IdRaggruppamento: number;
    }
}