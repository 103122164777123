namespace Trace.Utente {
    export class RicercaUtente {
        public Id: number;
        public Codice: string;
        public Username: string;
        public Nome: string;
        public Cognome: string;
        public Email: string;
        public IdGruppo: number;
        public IdSottoGruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public RoleId: string;
        public IdTipoUtente: string;
        public FlgAffiancamento: boolean;
    }
    export class utenteController extends epBaseController {
        public formUtente: any;
        public utente: UtenteVm;
        public utenti: UtenteVm[];
        public ricerca: RicercaUtente;
        public ruoli: RuoloVm[];
        public gruppi: Gruppo.Gruppo[];
        public gruppiRicerca: Gruppo.Gruppo[];
        public centrali: Centrale.Centrale[];
        public depositi: Deposito.DepositoVm[];
        public sottoGruppi: SottoGruppo.SottoGruppo[];
        public sottoGruppiRicerca: SottoGruppo.SottoGruppo[];
        public sedi: Sede.Sede[];
        public sediRicerca: Sede.Sede[];
        public centriCosto: CentroCosto.CentroCosto[];
        public centriCostoRicerca: CentroCosto.CentroCosto[];
        public lingue: ViewModels.LinguaVm[];
        public cambioPasswordErrore: boolean;
        public idUtentiTimeMotoInUso: number[];
        public codiciUtentiFilePagheInUso: string[];
        public tipiUtenti: Helpers.SimpleEntity[];

        public ricercaUtenteTm: Utente.UtenteVm;
        public utentiTm: Utente.UtenteVm[];
        public utenteTmScelto: Utente.UtenteVm;
        public pannelloUtenteTimeMoto: Utente.UtenteVm;

        public ricercaUtenteFilePaghe: Helpers.SimpleCodeEntity;
        public utentiFilePaghe: Helpers.SimpleCodeEntity[];
        public utenteFilePagheScelto: Helpers.SimpleCodeEntity;
        public utenteFilePaghe: Helpers.SimpleCodeEntity; // per le info aggiuntive sul pannello

        public loggedUser: UtenteVm;

        static $inject = ["$scope", "$rootScope", "dtFactory", "utenteFactory",
            "gruppiFactory", "sottoGruppiFactory", "sediFactory", "centriCostoFactory", "Upload", "localeFactory",
            "msgFactory", "centraliFactory", "depositoFactory", "$uibModal", "$document", "tracciabilitaFactory"];

        constructor(
            private $scope: ng.IScope,
            public $rootScope: ng.IRootScopeService,
            private dtFactory: Trace.datatables.dtFactory,
            public utenteFactory: Trace.Utente.utenteFactory,
            private gruppiFactory: Trace.Gruppo.gruppiFactory,
            private sottoGruppiFactory: Trace.SottoGruppo.sottoGruppiFactory,
            private sediFactory: Trace.Sede.sediFactory,
            private centriCostoFactory: Trace.CentroCosto.centroCostoFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            private centraliFactory: Centrale.centraliFactory,
            private depositoFactory: Deposito.depositoFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            private $document: any,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {            
            super(Upload, utenteFactory, undefined, undefined, undefined, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);

            this.idUtentiTimeMotoInUso = [];
            this.codiciUtentiFilePagheInUso = [];
            this.formUtente = {};
            this.utenteFactory.lingue().then(risultato => {
                this.lingue = risultato;
            });
            this.gruppiFactory.lista().then((risultato: Trace.Gruppo.Gruppo[]) => {
                this.gruppi = risultato;
                this.gruppiRicerca = risultato;
            });
            this.utenteFactory.listaTipiUtenti().then(risultato => {
                this.tipiUtenti = risultato;
            });
            this.utenteFactory.listaRuoli().then(risultato => {
                this.ruoli = risultato;
            });
            this.centraliFactory.lista().then(risultato => {
                this.centrali = risultato;
            });
            this.setUpWatchers();
            //this.titolo = localeFactory.get("browser_titolo");

            this.getCurrentUser();
        }

        private getCurrentUser(): void {
            var vm = this;
            this.utenteFactory.currentUser().then(
                // OK
                result => {
                    vm.loggedUser = result;
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_sessione_errore", [errore]));
                }
            );
        }

        /**
         * Controllo se è stata richiesta la pagina di dettaglio di un utente specifico, altrimenti carico la lista
         */
        public checkUserId(): void {
            if (userId == undefined)
                this.changeState('list');
            else
                this.leggi(userId);
        }

        private getDepositi(idTipo: number = undefined): void {
            if (idTipo == undefined)
                this.depositoFactory.lista().then(risultato => {
                    this.depositi = risultato;
                });
            else
                this.depositoFactory.listaByTipo([idTipo]).then(risultato => {
                    this.depositi = risultato;
                });
        }

        private RoleIdChange(): void {
            if (this.utente.Id == undefined && this.utente.RoleId == 'OPERATORE') {
                this.utente.FlgMappa = true;
            }
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.idUtentiTimeMotoInUso = [];
                    this.codiciUtentiFilePagheInUso = [];
                    this.utente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.showValidationMessages = false;
                    this.utenteFactory.getIdUtentiTimeMotoInUso(this.utente.IdUtenteTimeMoto).then(
                        result => {
                            this.idUtentiTimeMotoInUso = result;
                        }
                    );
                    this.utenteFactory.getCodiciUtentiFilePagheInUso(this.utente.CodiceUtenteFilePaghe).then(
                        result => {
                            this.codiciUtentiFilePagheInUso = result;
                        }
                    );
                    this.formUtente.$setPristine();
                    $(".datetime").datepicker();
                    $(".centrale").each((index: number, elem: Element) => {
                        $(elem).prop("checked", false);
                    });
                    break;
            }
        }

        public nuovo(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.utente = new UtenteVm();
            this.changeState("manipola");
        }
        
        public idTimeMotoAlreadyExist(): boolean {
            if (this.utente.IdUtenteTimeMoto != undefined) {
                var trovato = this.idUtentiTimeMotoInUso.filter(h => h == this.utente.IdUtenteTimeMoto);
                return trovato != undefined && trovato.length > 0;
            }
            return false;
        }

        public codiceFilePagheAlreadyExist(): boolean {
            if (this.utente.CodiceUtenteFilePaghe != undefined) {
                var trovato = this.codiciUtentiFilePagheInUso.filter(h => h == this.utente.CodiceUtenteFilePaghe);
                return trovato != undefined && trovato.length > 0;
            }
            return false;
        }

        public registra(): void {
            var vm = this;
            this.showValidationMessages = false;

            if (!this.formUtente.$valid) {
                this.showValidationMessages = true;
                this.formUtente.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            if (this.idTimeMotoAlreadyExist() || this.codiceFilePagheAlreadyExist()) {
                this.showValidationMessages = true;
                this.formUtente.$setDirty();
            }
            if (this.isInCreate() && this.utente.Password !== this.utente.Conferma) {
                this.msgFactory.error(vm.localeFactory.get("messaggio_conferma_password_errore"));
                return;
            }
            else {
                var msg: string;

                if (this.idTimeMotoAlreadyExist() && this.codiceFilePagheAlreadyExist()) msg = "messaggio_salvataggio_conferma_sovrascrittura_tm_file_paghe";
                else if (this.idTimeMotoAlreadyExist()) msg = "messaggio_salvataggio_conferma_id_tm_exist";
                else if (this.codiceFilePagheAlreadyExist()) msg = "messaggio_salvataggio_conferma_codice_file_paghe_exist";
                else msg = "messaggio_salvataggio_conferma";

                var conf = this.msgFactory.confirm(this.localeFactory.get(msg));
                conf.then(result => {
                    if (!result.result) return;
                    this.doRegistrazione();
                });
            }
        }

        private doRegistrazione(): void {
            this.isSaving = true;
            var promise = (this.utente.Id == undefined)
                ? this.utenteFactory.inserisci(this.utente)
                : this.utenteFactory.modifica(this.utente);

            promise.then(
                result => {
                    var alertPromise = this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                    alertPromise.then(result => {
                        this.changeState("list");
                    });
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_errore", [reason]));
                }).finally(() => {
                    this.isSaving = false;
                });
        }

        public leggi(id: number): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.utenteFactory.leggi(id).then((risultato: UtenteVm) => {
                if (this.titoloManipola == 'MISSING_LABEL') this.titoloManipola = this.localeFactory.get("titolo_modifica");
                this.utente = risultato;
                this.caricaDatiUtenteTm();
                this.caricaDatiUtenteFilePaghe();
                if (this.utente.FlgCollegamentoCentrali === true) {
                    setTimeout(() => {
                        $.each(this.utente.Centrali, (index: number, idCentrale: number) => {
                            $("#centrale_" + idCentrale.toString()).prop("checked", true);
                        });
                    }, 300);
                }
                if (this.utente.FlgCollegamentoDepositi === true) {
                    setTimeout(() => {
                        $.each(this.utente.Depositi, (index: number, idDeposito: number) => {
                            $("#deposito_" + idDeposito.toString()).prop("checked", true);
                        });
                    }, 300);
                }
                this.changeState("manipola");
            });
        }

        public ricercaUtentiTm(): void{
            var filtro = this.dtFactory.generaOggettoRicerca(this.ricercaUtenteTm);
            
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            this.utenteFactory.ricercaUtentiTimeMoto(ricerca).then((risultato: UtenteVm[]) => {
                this.utentiTm = risultato;
            });
        }

        public caricaDatiUtenteTm() {
            if (this.utente == undefined || this.utente.IdUtenteTimeMoto == undefined) {
                this.pannelloUtenteTimeMoto = <UtenteVm>{};
                return;
            }
            var ricercaUtenteTm = <UtenteVm>{ Id: this.utente.IdUtenteTimeMoto };
            var filtro = this.dtFactory.generaOggettoRicerca(ricercaUtenteTm);

            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            this.utenteFactory.ricercaUtentiTimeMoto(ricerca).then((risultato: UtenteVm[]) => {
                if (risultato.length > 0) this.pannelloUtenteTimeMoto = risultato[0];
                else this.pannelloUtenteTimeMoto = <UtenteVm>{};
            });
        }

        public modalUtenteTM(): void {
            this.ricercaUtenteTm = <UtenteVm>{};
            this.utenteTmScelto = undefined;
            this.utentiTm = [];
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Utente/modalUtenteTm.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public selezionaUtenteTm(item: UtenteVm): void {
            this.utenteTmScelto = item;
        }
        public doSelezionaUtenteTm(): void {
            this.utente.IdUtenteTimeMoto = this.utenteTmScelto.Id;
            this.formUtente.$setDirty();
            this.$modalInstance.close();
        }

        public ricercaUtentiFilePaghe(): void {
            var filtro = this.dtFactory.generaOggettoRicerca(this.ricercaUtenteFilePaghe);

            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            this.utenteFactory.ricercaUtentiFilePaghe(ricerca).then((risultato: Helpers.SimpleCodeEntity[]) => {
                this.utentiFilePaghe = risultato;
            });
        }

        public caricaDatiUtenteFilePaghe() {
            if (this.utente == undefined || this.utente.CodiceUtenteFilePaghe == undefined || RegExp("/^\s*$/").test(this.utente.CodiceUtenteFilePaghe)) {
                this.utenteFilePaghe = <Helpers.SimpleCodeEntity>{};
                return;
            }
            var ricercaUtenteFilePaghe = <any>{ Codice_Esatto: this.utente.CodiceUtenteFilePaghe };
            var filtro = this.dtFactory.generaOggettoRicerca(ricercaUtenteFilePaghe);

            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            this.utenteFactory.ricercaUtentiFilePaghe(ricerca).then((risultato: Helpers.SimpleCodeEntity[]) => {
                if (risultato.length > 0) this.utenteFilePaghe = risultato[0];
                else this.utenteFilePaghe = <Helpers.SimpleCodeEntity>{};
            });
        }

        public modalUtenteFilePaghe(): void {
            this.ricercaUtenteFilePaghe = <Helpers.SimpleCodeEntity>{};
            this.utenteFilePagheScelto = undefined;
            this.utentiFilePaghe = [];
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Utente/modalUtenteFilePaghe.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public selezionaUtenteFilePaghe(item: Helpers.SimpleCodeEntity): void {
            this.utenteFilePagheScelto = item;
        }
        public doSelezionaUtenteFilePaghe(): void {
            this.utente.CodiceUtenteFilePaghe = this.utenteFilePagheScelto.Codice;
            this.formUtente.$setDirty();
            this.$modalInstance.close();
        }

        public cancella(username: string, id: number): void {
            var vm = this;
            this.utenteFactory.inUso(username).then(
                // OK
                risultato => {
                    if (risultato) {
                        vm.msgFactory.alert(vm.localeFactory.get("messaggio_in_uso"));
                        return;
                    }
                    this.msgFactory.confirm(vm.localeFactory.get("messaggio_cancellazione_conferma")).then(
                        risultato => {
                            if (!risultato.result) return;
                            vm.utenteFactory.cancella(id).then(
                                // OK
                                result => {
                                    vm.msgFactory.alert(vm.localeFactory.get("messaggio_cancellazione_ok")).then(result => {
                                        vm.changeState("list");
                                        vm.dtFactory.aggiornaRicerca();
                                    });
                                },
                                // ERRORE
                                reason => {
                                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_cancellazione_errore", [errore]));
                                }
                            );
                        }
                    );
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_controllo_errore", [errore]));
                }
            );
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.utente.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdGruppo", (newVal: number, oldVal: number) => {
                if(newVal != oldVal) this.gruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.utente.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if(newVal != oldVal) this.sottoGruppoChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if (newVal != oldVal) this.sottoGruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.utente.IdSede", (newVal: number, oldVal: number) => {
                this.sedeChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSede", (newVal: number, oldVal: number) => {
                if (newVal != oldVal) this.sedeRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdCentroCosto", (newVal: number, oldVal: number) => {
                this.dtFactory.aggiornaRicerca();
            });
            this.$scope.$watch("vm.utente.FlgCollegamentoCentrali", (newVal: boolean, oldVal: boolean) => {
                if (newVal && this.utente.Centrali == undefined) {
                    this.utente.Centrali = new Array<number>();
                }
            });
            this.$scope.$watch("vm.utente.FlgCollegamentoDepositi", (newVal: boolean, oldVal: boolean) => {
                if (newVal && this.utente.Depositi == undefined) {
                    this.utente.Depositi = new Array<number>();
                }
            });
            this.$scope.$watch("vm.utente.IdUtenteTimeMoto", (newVal: boolean, oldVal: boolean) => {
                this.caricaDatiUtenteTm();
            });
            this.$scope.$watch("vm.utente.CodiceUtenteFilePaghe", (newVal: boolean, oldVal: boolean) => {
                this.caricaDatiUtenteFilePaghe();
            });
            this.$scope.$watch("vm.utente.RoleId", (newVal: string, oldVal: string) => {
                if (newVal === 'SALA')
                    this.getDepositi(3);
                else
                    this.getDepositi();
            });
        }

        public depositoChanged(id: number): void {
            if ($("#deposito_" + id.toString()).is(":checked")) {
                this.utente.Depositi.push(id);
            }
            else {
                $.each(this.utente.Depositi, (listIndex: number, item: number) => {
                    if (item === id) {
                        this.utente.Depositi.splice(listIndex);
                        return;
                    }
                });
            }
        }

        public centraleChanged(id: number): void {
            if ($("#centrale_" + id.toString()).is(":checked")) {
                this.utente.Centrali.push(id);
            }
            else {
                $.each(this.utente.Centrali, (listIndex: number, item: number) => {
                    if (item === id) {
                        this.utente.Centrali.splice(listIndex);
                        return;
                    }
                });
            }
        }

        public gruppoChanged(idGruppo: number): void {
            this.sottoGruppi = [];
            if (idGruppo == undefined) return;
            this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppi = risultato;
            });
        }

        public gruppoRicercaChanged(idGruppo: number): void {
            this.sottoGruppiRicerca = [];
            if (idGruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                    this.sottoGruppiRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sottoGruppoChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];

            if (idSottogruppo == undefined) return;
            this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                this.sedi = risultato;
            });
        }

        public sottoGruppoRicercaChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];
            if (idSottogruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                    this.sediRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sedeChanged(idSede: number): void {
            this.centriCosto = [];

            if (idSede == undefined) return;
            this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                this.centriCosto = risultato;
            });
        }

        public sedeRicercaChanged(idSede: number): void {
            this.centriCosto = [];
            if (idSede == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                    this.centriCostoRicerca = risultato;
                });
            }
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = (filtro == null || filtro.length == 0) ? null : filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.utenteFactory.ricerca(ricerca).then((risultato: UtenteVm[]) => {
                this.utenti = risultato;
            });
        }

        public isInEdit(): boolean {
            var vm = this;
            return vm.currentState == "manipola" && vm.utente != undefined && vm.utente.Id != undefined;
        }

        public isInCreate(): boolean {
            var vm = this;
            return vm.currentState == "manipola" && vm.utente != undefined && vm.utente.Id == undefined;
        }

        public resetForm(): void {
            this.ricerca = new RicercaUtente();
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                this.backToList();
            });
        }

        public backToList(): void {
            this.changeState('list');
        }

        public onFileSelect($file: File) {
            var vm = this;
            var file = $file;
            this.Upload.upload({
                url: appRoot + "api/Upload",
                method: "POST",
                data: { tipo: "utenti" },
                file: file
            })
                .abort()
                .xhr((evt: any) => {
                    console.log('xhr');
                })
                .progress((evt: angular.angularFileUpload.IFileProgressEvent) => {
                    var percent = parseInt((100.0 * evt.loaded / evt.total).toString(), 10);
                    console.log("upload progress: " + percent + "% for " + evt.config.file.name);
                })
                //.error((data: any, status: number, response: any, headers: any) => {
                //    console.error(data, status, response, headers);
                //    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_upload_errore", [data]));
                //})
                .success((data: Trace.ViewModels.UploadResultVm, status: number, headers: any, config: angular.angularFileUpload.IFileUploadConfigFile) => {
                    var img = new Trace.ViewModels.ImmagineVm();
                    img.thumb = appRoot + data.Url;
                    img.img = appRoot + data.Url;
                    img.description = data.Name;
                    img.baseUrl = data.Url;
                    vm.utente.Immagine = img;
                    vm.formUtente.$setDirty();
                });
        }

        public cambioPassword() {
            this.cambioPasswordErrore = false;
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Utente/modalCambioPassword.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public doCambioPassword() {
            var vecchia = $("#vecchia").val();
            var nuova = $("#nuova").val();
            var conferma = $("#conferma").val();
            if (vecchia == "" || nuova == "" || conferma == "") {
                this.cambioPasswordErrore = true;
                return;
            }
            if (nuova !== conferma) {
                this.msgFactory.error(this.localeFactory.get("messaggio_conferma_password_errore"));
                return;
            }
            var vm = this;
            this.utenteFactory.checkLogin(this.utente.Username, vecchia).then(
                // OK
                checkResult => {
                    if (checkResult)
                        vm.utenteFactory.changePassword(vm.utente.Id, vecchia, nuova).then(
                            // OK
                            risultato => {
                                vm.msgFactory.alert(vm.localeFactory.get("messaggio_modifica_password_ok"));
                                vm.$modalInstance.close();
                            },
                            // ERRORE
                            reason => {
                                var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                                vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_modifica_password_errore", [errore]));
                            }
                        );
                    else {
                        vm.msgFactory.error(vm.localeFactory.get("messaggio_vecchia_password_sbagliata"));
                        return;
                    }
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_modifica_password_errore", [errore]));
                }
            );
        }
    }
}
app.controller("utenteController", Trace.Utente.utenteController);