module Trace.Startup {
    export class RicercaStartupVm {
        public Id: number;
        public DataCreazione: Date;
        public IdUtenteCreazione: number;
        public UtenteCreazione: string;
        public IdCentrale: number;
        public Codice: string;
        public Descrizione: string;
    }
}