module Trace.ViewModels {
    export class RicercaVm {
        public idProdotto: number;
        public entita: string;
        public max: number;
        public colonne: RicercaRigaVm[];
        public sort: SortRigaVm[];
    }

    export class RicercaRigaVm {
        public colonna: string;
        public valore: string;
        public direzioneSort: DirezioneSort;
        constructor(colonna: string = undefined, valore: string = undefined) {
            if (colonna != undefined) this.colonna = colonna;
            if (valore != undefined) this.valore = valore;
        }
    }

    export class SortRigaVm {
        constructor(public colonna: string, public direzioneSort: DirezioneSort) {}
    }

    export enum DirezioneSort {
        Ascendente = 1,
        Discendente = 2
    }
}