module Trace.Tracciabilita {
    export class tracciabilitaTntController extends epBaseController {
        static $inject = ["$scope", "Upload", "tracciabilitaFactory", "utenteFactory", "prodottiFactory", "documentoFactory", "localeFactory", "msgFactory", "$uibModal"];
        private lista: Array<TracciabilitaTntVm>;
        private formTracciabilitaTnt: any;
        private filtro: FiltroTracciabilitaTnt;

        constructor(
            private $scope: ng.IScope,
            public upload: ng.angularFileUpload.IUploadService,
            public tracciabilitaFactory: tracciabilitaFactory,
            public utenteFactory: Utente.utenteFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            private documentoFactory: Documento.documentoFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService
        ) {
            super(upload, utenteFactory, undefined, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            this.formTracciabilitaTnt = {};
            this.filtro = {
                Descrizione: undefined,
                Ean: undefined,
                IdDeposito: undefined,
                Codice: undefined,
                Lotto: undefined
            };
        }

        private init(): void {
            var vm = this;
            $(document).on("gotUserSession", (e: JQueryEventObject) => {
                if (tCodTnt == null) return;
                vm.filtro.Codice = tCodTnt;
                vm.ricerca();
            });
        }

        private ricerca(): void {
            if (this.filtro.Codice == undefined && this.filtro.Descrizione == undefined && this.filtro.Ean == undefined && this.filtro.IdDeposito == undefined && this.filtro.Lotto == undefined) {
                this.msgFactory.error(this.localeFactory.get("valorizzare_campi"));
                return;
            }
            this.tracciabilitaFactory.RicercaTnt(this.filtro).then(
                result => {
                    this.lista = result;
                },
                reason => {
                    var acc = []
                    $.each(reason, (index, value) => {
                        acc.push(index + ': ' + value);
                    });
                    this.msgFactory.alert(JSON.stringify(acc));
                }
            );
        }
    }
}
app.controller("tracciabilitaTntController", Trace.Tracciabilita.tracciabilitaTntController);