module Trace.Consumi {
    export interface VwConsumiGiornalieri {
        Id: number;
        IdGruppo: number;
        Gruppo: string;
        IdSottogruppo: number;
        Sottogruppo: string;
        IdSede: number;
        Sede: string;
        IdCentroCosto: number;
        CentroCosto: string;
        DataConsumo: Date;
        Qta: number;
        IdRaggruppamentoTnt: number;
        RaggruppamentoTnt: string;
    }
}