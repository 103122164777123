module Trace.SottoGruppo {
    export class sottoGruppoVm {
        public Id: number;
        public Codice: string;
        public Descrizione_it: string;
        public Descrizione_en: string;
        public Descrizione_al: string;
        public DataObsoleto: Date;
        public Gruppo: string;

    }
}