module Trace.TipoIntervento {
    export class TipoIntervento {
        public Id: number;
        public Codice: string;
        public DataCreazione: Date;
        public DataObsoleto: Date;
        public DataUltimaModifica: Date;
        public Descrizione_en_US: string;
        public Descrizione_it_IT: string;
        public Descrizione_sq_AL: string;
        public IdImmagine: number;
        public IdUtenteCreazione: number;
        public IdUtenteObsoleto: number;
        public IdUtenteUltimaModifica: number;
        public IdCausaleUtilizzo: number;
        public MassimoNumeroSet: number;
        public IdComplessita: number;
        public IdMassimaComplessitaSet: number;
        public IdGruppo: number;
        public IdSottogruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
    }

    export class tipoInterventoVm {
        public Id: number;
        public Codice: string;
        public DataCreazione: Date;
        public DataObsoleto: Date;
        public DataUltimaModifica: Date;
        public Descrizione_en_US: string;
        public Descrizione_it_IT: string;
        public Descrizione_sq_AL: string;
        public IdImmagine: number;
        public IdUtenteCreazione: number;
        public IdUtenteObsoleto: number;
        public IdUtenteUltimaModifica: number;
        public Protocollo: string;
        public IdRaggruppamento: number;
        public Distinta: Array<TipoInterventoRigaSimpleVm>;
    }

    export class tipoInterventoRigaVm {
        public Id: number;
        public DataCreazione: Date;
        public DataObsoleto: Date;
        public DataUltimaModifica: Date;
        public IdUtenteCreazione: number;
        public IdUtenteObsoleto: number;
        public IdUtenteUltimaModifica: number;

        public IdIntervento: number;
        public IdSet: number;
        public IdTnt: number;
        public IdRot: number;
        public Qta: number;

        public DescSet: string;
        public DescRot: string;
        public DescTnt: string;

    }

    export class TipoInterventoRigaSimpleVm {
        public Id: number;
        public IdIntervento: number;

        public IdSet: number;
        public IdTnt: number;
        public IdRot: number;
        public Qta: number;

        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;

        public Tipo: string;

    }
}