module Trace.Centrale {
    export class CentraleMappabileVm {
        public IdCentrale: number;
        public Descrizione_it_IT: string;
        public Descrizione_en_US: string;
        public Descrizione_sq_AL: string;
        public IsAnOption: boolean;
        public Info: string;
    }
}
