app.factory("accettazioneFactory", ($http, $location, $q) => new Trace.Accettazione.accettazioneFactory($http, $location, $q));
module Trace.Accettazione {
    export class DatiAccettazione {
        public Lotti: Array<Produzione.LottoProduzioneVm>;
    }

    export class accettazioneFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {}

        public GetBaseUrl(): string {
            return appRoot + "api/Accettazione/";
        }

        public Causali(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "CausaliUtilizzoSet";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Lista(): ng.IPromise<Produzione.LottoProduzioneVm[]> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AccettaSetAssociati(idSet: number, idIntervento: number): ng.IPromise<Produzione.CambioStatoResult[]> {
            var workUrl = this.GetBaseUrl() + "AccettaSetAssociati/" + idSet.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idIntervento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
   
        public AccettaSet(barcode: string, idIntervento: number, idLotto?: number): ng.IPromise<Produzione.CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + idIntervento.toString();
            var deferred = this.$q.defer();
            var read = new DataRead(barcode, idLotto);
            this.$http.post(workUrl, read).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SalvaDati(interventi: Array<Intervento.IInterventoVm>): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.put(workUrl, interventi).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AccettazioneMassivaLotti(lotti: Array<Accettazione.AccettazioneLottoVm>): ng.IPromise<Produzione.CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "AccettazioneMassivaLotti";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, lotti).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public CancellaLottoDaIntervento(idLotto: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CancellaLottoDaIntervento/" + idLotto;
            var deferred = this.$q.defer();
            this.$http.delete(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        } 

        public AccettaMaterialeTessileModel(model: AccettazioneMaterialeVm): ng.IPromise<Produzione.CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "AccettaMaterialeTessile";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, model).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AccettaMaterialeTessile(descrizione: string, numero: number, numeroDdt: number, idIntervento: number): ng.IPromise<Produzione.CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "AccettaMaterialeTessile";
            var deferred = this.$q.defer();
            var materiale = <AccettazioneMaterialeVm>{
                Descrizione: descrizione,
                Qta: numero,
                IdIntervento: idIntervento,
                NumeroDdt: numeroDdt
            };
            this.$http.post(workUrl, materiale).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AccettaLottoSfuso(idIntervento: number, lettura: string): ng.IPromise<Prodotto.IStrumentoSfuso> {
            var workUrl = this.GetBaseUrl() + "AccettaLottoSfuso";
            var deferred = this.$q.defer();
            var data: Accettazione.IAccettazioneLottoVm = {
                IdIntervento: idIntervento,
                IdLotto: parseInt(lettura)
            }
            this.$http.post(workUrl, data).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AccettaMaterialeSfuso(accettazione: Accettazione.IAccettazioneSfuso): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AccettaMaterialeSfuso";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, accettazione).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AccettaMaterialeTransito(accettazione: Accettazione.IAccettazioneSfuso): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AccettaMaterialeTransito";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, accettazione).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaAccettazioni(page: number, filtro: Trace.Accettazione.FiltroAccettazioni): ng.IPromise<Trace.ViewModels.GenericPaginationVm<Trace.Accettazione.Accettazione>> {
            var workUrl = this.GetBaseUrl() + "RicercaAccettazioni/" + page;
            var deferred = this.$q.defer<Trace.ViewModels.GenericPaginationVm<Trace.Accettazione.Accettazione>>();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(<Trace.ViewModels.GenericPaginationVm<Trace.Accettazione.Accettazione>>result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public EditAccettazione(accettazione: Accettazione.AccettazioneVm): ng.IPromise<Accettazione.Accettazione> {
            var workUrl = this.GetBaseUrl() + "Edit";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, accettazione).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetNumeroDdtTessile(): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl() + "GetNumeroDdtTessile";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}