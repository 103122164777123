module Trace.Analisi {
    export class produttivitaFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Analisi/";
        }

        public Ricerca(filtro: Analisi.FiltroProduttivita): ng.IPromise<Analisi.GraficiProduttivitaVm> {
            var workUrl = this.GetBaseUrl() + "RicercaProduttivita";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaProduttivitaCentrali(filtro: Analisi.FiltroProduttivitaCentrale): ng.IPromise<Analisi.GraficiProduttivitaCentraliVm> {
            var workUrl = this.GetBaseUrl() + "RicercaProduttivitaCentrali";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ExportPdfProdMensile(item: ExportPdfVm): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "ReportProduttivitaMensile";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, item).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaProduttivitaMensile(filtro: any): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + "RicercaProduttivitaMensile";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
        public RicercaProdCentraliMensile(filtro: any): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + "RicercaProdCentraliMensile";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaAnalisiPresenze(filtro: Analisi.FiltroAnalisiPresenze): ng.IPromise<Analisi.GraficiPresenzeOperatoriVm> {
            var workUrl = this.GetBaseUrl() + "RicercaAnalisiPresenze";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}
app.factory("produttivitaFactory", ($http, $location, $q) => new Trace.Analisi.produttivitaFactory($http, $location, $q));