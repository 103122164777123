
app.factory("setFactory", ($http, $q) => new Trace.Set.setFactory($http, $q));
module Trace.Set {
    export class setFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Set/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }


        public RicercaStrumentiRevisioni(page: number, filtro: Trace.Accettazione.FiltroAccettazioni): ng.IPromise<Trace.ViewModels.GenericPaginationVm<Trace.Accettazione.Accettazione>> {
            var workUrl = this.GetBaseUrl() + "RicercaAccettazioni/" + page;
            var deferred = this.$q.defer<Trace.ViewModels.GenericPaginationVm<Trace.Accettazione.Accettazione>>();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(<Trace.ViewModels.GenericPaginationVm<Trace.Accettazione.Accettazione>>result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }


        public RicercaSfusoInBusta(strumenti: string[]): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "RicercaSfusoInBusta";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, strumenti).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetSfusoInBusta(id: number): ng.IPromise<Produzione.MaterialeSimple[]> {
            var workUrl = this.GetBaseUrl() + "GetSfusoInBusta/" + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public DecodificaGriglia(codice: string): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "DecodificaGriglia/" + codice;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public listaTipiSet(): ng.IPromise<TipoSetVm[]> {
            var workUrl = this.GetBaseUrl() + "TipiSet";
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success((data: TipoSetVm[], status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public getTipoSet(id: number): ng.IPromise<TipoSetVm> {
            var workUrl = this.GetBaseUrl() + "GetTipoSet/"+id;
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success((data: TipoSetVm, status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public listaUnitaSterilizzazione(): ng.IPromise<UnitaSterilizzazioneVm[]> {
            var workUrl = this.GetBaseUrl() + "UnitaSterilizzazione";
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success((data: TipoSetVm[], status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public listaComplessita(): ng.IPromise<ComplessitaVm[]> {
            var workUrl = this.GetBaseUrl() + "Complessita";
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success((data: ComplessitaVm[], status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public listaTipiSterilizzazione(): ng.IPromise<TipoSterilizzazioneVm[]> {
            var workUrl = this.GetBaseUrl() + "TipiSterilizzazione";
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success((data: TipoSterilizzazioneVm[], status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public lista(): ng.IPromise<SetVm[]> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success((data: SetVm[], status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Set.SetPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'Ricerca/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: SetVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public codiceSet(idCentroCosto: number): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + 'CodiceSet/' + idCentroCosto.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: string, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(set: SetVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, set).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public modifica(set: SetVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + set.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, set).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public attiva(id: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "Attiva";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, id).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaTipiSetAsSe(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "TipiSetAsSe";
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success((data: Helpers.SimpleEntity[], status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public marcaturaStrumenti(idModello: number, idSet: number, strumenti: Array<StrumentoVm>): ng.IPromise<StrumentoVm[]> {
            var workUrl = this.GetBaseUrl() + "MarcaturaStrumenti/" + idModello.toString() + "/" + idSet.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, strumenti)
                .success((data: Helpers.SimpleEntity[], status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public leggi(idSet: number): ng.IPromise<Trace.Set.SetVm> {
            var workUrl = this.GetBaseUrl() + idSet;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Trace.Set.SetVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public GetByCodice(codice: string): ng.IPromise<SetVm> {
            var workUrl = this.GetBaseUrl() + "GetByCodice/" + codice;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: SetVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public duplica(idSet: number, numeroCopie: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "Duplica/" + idSet.toString();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, numeroCopie).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public CheckUid(uid: string): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CheckUid/" + uid;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SalvaProduzione(idModello: number, produzioni: ModelliSet.ProduzioneVm[]): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "SalvaProduzione/" + idModello.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, produzioni).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SaveUid(idStrumento: number, uid: string): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "SaveUid/" + uid;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idStrumento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetCopiaSet(idModello: number): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl() + "GetCopiaSet/" + idModello.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetBarcode(info: InfoBarcodeVm): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "GetBarcode";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, info).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetNumeroCopie(idModello: number): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl() + "GetNumeroCopie/" + idModello.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ScadenzeSet(filtro: Trace.Scadenze.FiltroScadenzeVm): ng.IPromise<Array<Trace.Set.ScadenzaSet>> {
            var workUrl = this.GetBaseUrl() + "ScadenzeSet";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public listaRaggruppamenti(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "listaRaggruppamenti";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}