module Trace.Analisi {
    export class produttivitacentraliController {
        private filtro: FiltroProduttivitaCentrale; 
        private graficoShow: boolean;
        private centrali: Trace.Helpers.SimpleDropdown[];
        private risultatoRicerca: Trace.Analisi.GraficiProduttivitaCentraliVm;
        private isLoading: boolean;

        // vm.localeFactory.get('seleziona')

        static $inject = ['$scope', 'centraliFactory', 'produttivitaFactory', 'localeFactory', 'msgFactory'];
        constructor(
            private $scope: ng.IScope,
            private centraliFactory: Centrale.centraliFactory,
            private produttivitaFactory: Analisi.produttivitaFactory,
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory            
        ) {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 250);

            this.risultatoRicerca = <Trace.Analisi.GraficiProduttivitaCentraliVm>{ };
            this.filtro = new FiltroProduttivitaCentrale();            
            this.setUpWatchers();
        }

        public ricerca(): void {
            var centraliSelezionate = this.centrali.filter(h => h.Selezionato == true);
            if (centraliSelezionate == undefined || centraliSelezionate.length == 0) {
                this.msgFactory.alert("Seleziona almeno una centrale prima di procedere alla ricerca");
                return;
            }
            this.filtro.IdCentrale = centraliSelezionate.map(function (a) { return a.Id });
            this.isLoading = true;
            this.graficoShow = true;
            //this.graficoShow = this.filtro.IdFaseProduzione != undefined ? true : false;
            this.produttivitaFactory.RicercaProduttivitaCentrali(this.filtro).then(
                result => {
                    this.risultatoRicerca = result;
                    this.isLoading = false;
                    //this.updateLottiLavorati1();
                    //this.updateFasiLottiLavorati2();
                    //if (this.ricercaPerFase) this.updateFasiLottiLavorati3();

                    if (this.risultatoRicerca != null && this.risultatoRicerca.DatiTorta != null && this.risultatoRicerca.DatiTorta.length > 0) {
                        //this.updateFasiLottiLavorati4();
                        //this.updateFasiLottiLavorati5();
                        this.updateTorta1();
                        this.updateIstogrammaConfronto();
                    }
                    else {
                        this.graficoShow = false;
                        this.msgFactory.alert(this.localeFactory.get("nessun_risultato_ricerca"));
                    }
                }
            );
        }

        private updateIstogrammaConfronto(): void {
            $("#my-chart-2-container").highcharts(
                {
                    chart: {
                        type: 'column'
                    },

                    title: {
                        text: null
                    },

                    xAxis: {
                        categories: this.risultatoRicerca.CentraliLabel
                    },

                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: 'Number of fruits'
                        }
                    },

                    tooltip: {
                        formatter: function () {
                            return '<b>' + this.x + '</b><br/>' +
                                this.series.name + ': ' + this.y + '<br/>' +
                                'Total: ' + this.point.stackTotal;
                        }
                    },

                    plotOptions: {
                        column: {
                            stacking: 'normal'
                        }
                    },

                    series: this.risultatoRicerca.SerieConfrontoCentrali
                }
            );
        }

        private updateTorta1() {
            // Build the chart
            var chart = Highcharts.chart({
                chart: {
                    renderTo: "my-chart-1-container",
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie',
                    events: {
                        drilldown: function (e) {
                            chart.setTitle({ text: "Set lavorati" +" - "+ e.point.name, verticalAlign: "" });
                        },
                        drillup: function (e) {
                            chart.setTitle({ text: "Set lavorati" });
                        }
                    }
                },
                title: {
                    text: "Set lavorati" //'Browser market shares in January, 2018'
                },
                tooltip: {
                    pointFormat: 'Procedure: <b>{point.y}</b><br/>Percentuale: <b>{point.percentage:.1f}%</b>'
                },
                //plotOptions: {
                //    pie: {
                //        allowPointSelect: true,
                //        cursor: 'pointer',
                //        dataLabels: {
                //            enabled: true,
                //            format: '<b>{point.name}</b>:{point.y1} {point.percentage:.1f} % ',
                //            style: {
                //                color: 'black'
                //            },
                //            connectorColor: 'silver'
                //        }
                //    }
                //},
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                series: [{
                    name: 'Procedure',
                    data: this.risultatoRicerca.DatiTorta
                }],
                drilldown: {
                    series: this.risultatoRicerca.DrilldownTorta
                }
            });
        }

        public init() {                    
            this.getCentrali();        
        }  
       
        private getCentrali(): void {
            var vm = this;
            var dropDownOption = {
                //data: json1.data,
                //limitCount: 40,
                multipleMode: 'label',
                choice: function (a, b) {
                    //console.log(arguments, this);
                    //vm.dropDownPristine = false;
                    var item = vm.centrali.filter(h => h.Id == b.id)[0];
                    item.Selezionato = b.selected == true ? true : false;
                    //vm.$scope.$apply();
                    //vm.formFornitori.$commitViewValue();
                },
                limitCount: Infinity,
                input: '<input type="text" id="cerca-marca" maxLength="20" style="font-size:12px" placeholder="' + "Cerca.."/*vm.localeFactory.get("campo_ricerca")*/ + '">',
                searchable: true,
                searchNoData: '<li style="color:#CCC">No Results</li>',

            };
            this.centraliFactory.listaAsDropdown().then(
                result => {
                    this.centrali = result;
                    setTimeout(
                        function () {
                            $('#selezioneCentrali').dropdown(dropDownOption);
                        }, 250);
                }
            );
        }        

        private setUpWatchers(): void {
           
        }

        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }            
    }    
}
app.controller("produttivitacentraliController", Trace.Analisi.produttivitacentraliController);