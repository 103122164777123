module Trace.Ordini {
    export class otherOrdiniController extends epBaseController {
        public idRicercaMateriali: number;
        public ricerca: RicercaOrdiniVm;
        public ricercaModelliSet: Trace.ModelliSet.RicercaModelliSet;
        public ricercaProdotti: Prodotto.RicercaProdotti;
        public ordini: OrdineVm[];
        public ordine: OrdineVm;
        public gruppi: Trace.Gruppo.Gruppo[];
        public gruppiRicerca: Trace.Gruppo.Gruppo[];
        public sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        public sottoGruppiRicerca: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public sediRicerca: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];
        public centriCostoRicerca: Trace.CentroCosto.CentroCosto[];
        public currentPage: number;
        public formOrdine: any;
        public modelliSet: Set.SetVm[];
        public modelloSet: Set.SetVm;
        public prodotti: Prodotto.ProdottoVm[];
        public fornitori: Helpers.SimpleEntity[];

        static $inject = ["$scope", "dtFactory", "ordiniFactory", "modelliSetFactory",
            "setFactory", "prodottiFactory", "gruppiFactory", "sottoGruppiFactory",
            "sediFactory", "centriCostoFactory", "Upload", "localeFactory",
            "msgFactory", "$uibModal", "$document", "utenteFactory", "tracciabilitaFactory"];

        constructor(
            public $scope: ng.IScope,
            private dtFactory: Trace.datatables.dtFactory,
            private ordiniFactory: ordiniFactory,
            private modelliSetFactory: ModelliSet.modelliSetFactory,
            public setFactory: Trace.Set.setFactory,
            public prodottiFactory: Trace.Prodotto.prodottiFactory,
            private gruppiFactory: Trace.Gruppo.gruppiFactory,
            private sottoGruppiFactory: Trace.SottoGruppo.sottoGruppiFactory,
            private sediFactory: Trace.Sede.sediFactory,
            private centriCostoFactory: Trace.CentroCosto.centroCostoFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            private $document: any,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(Upload, utenteFactory, setFactory, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, null, tracciabilitaFactory);

            this.formOrdine = {};
            this.gruppiFactory.lista().then((risultato: Trace.Gruppo.Gruppo[]) => {
                this.gruppi = risultato;
                this.gruppiRicerca = risultato;
            });
            this.ordiniFactory.SimpleListFornitori().then(
                result => {
                    this.fornitori = result;
                }
            );
            this.setUpWatchers();
        }

        public downloadFile(tipo: string, idOrdine: number): void {
            var vm = this;
            $(document).unbind("moduleLogin")
                .on("moduleLogin", (e: JQueryEventObject) => {
                    window.location.assign("/Ordini/DownloadFile/?tipo=" + tipo + "&id=" + idOrdine);
                    vm.chiudiModal();
                });
            this.checkModulePassword("Download file", "", this.$scope, false);
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.ordine = null;
                    this.modelloSet = null;
                    this.dtFactory.aggiornaRicerca("", this.currentPage);
                    break;

                case "manipola":
                    this.strumentoSelezionato = undefined;
                    this.formOrdine.$setPristine();
                    break;
            }

            $(".datetime").datepicker();
        }

        private setUpWatchers(): void {
            var vm = this;
            this.$scope.$watch("vm.ordine.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.ordine.IdSottogruppo", (newVal: number, oldVal: number) => {
                this.sottoGruppoChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSottoGruppo", (newVal: number, oldVal: number) => {
                this.sottoGruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.ordine.IdSede", (newVal: number, oldVal: number) => {
                this.sedeChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSede", (newVal: number, oldVal: number) => {
                this.sedeRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdCentroCosto", (newVal: number, oldVal: number) => {
                this.dtFactory.aggiornaRicerca();
            });
        }

        public gruppoRicercaChanged(idGruppo: number): void {
            this.sottoGruppiRicerca = [];
            if (idGruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                    this.sottoGruppiRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sottoGruppoRicercaChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];
            if (idSottogruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                    this.sediRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sedeRicercaChanged(idSede: number): void {
            this.centriCosto = [];
            if (idSede == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                    this.centriCostoRicerca = risultato;
                });
            }
        }   

        public gruppoChanged(idGruppo: number): void {
            this.sottoGruppi = [];

            if (idGruppo == undefined) return;
            this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppi = risultato;
            });
        }

        public sottoGruppoChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];

            if (idSottogruppo == undefined) return;
            this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                this.sedi = risultato;
            });
        }

        public sedeChanged(idSede: number): void {
            this.centriCosto = [];

            if (idSede == undefined) return;
            this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                this.centriCosto = risultato;
            });
        }

        public cancella(ordine: OrdineVm): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("cancella_ordine_conferma", [ordine.Numero, ordine.Serie])).then(
                result => {
                    if (!result.result) return;

                    this.ordiniFactory.Delete(ordine.Id).then(
                        delResult => {
                            this.msgFactory.alert(this.localeFactory.getFormatted("cancella_ordine_ok", [ordine.Numero, ordine.Serie]));
                            this.dtFactory.aggiornaRicerca();
                        }
                    );
                }
            );
        }

        public nuovo(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.ordine = new Trace.Ordini.OrdineVm();
            this.ordiniFactory.SerieNumeroOrdine("TRC").then(
                result => {
                    this.ordine.Serie = result[1];
                    this.ordine.Numero = result[0];
                },
                reason => {
                }
            );
            this.changeState("manipola");
        }

        public copieModelloSetChanged(): void {
            $.each(this.ordine.Dettagli, (index, item) => {
                if (item.QtaProposta > 0) {
                    item.QtaProposta = item.QtaUnitaria * this.ordine.Copie;
                    item.Qta = item.QtaUnitaria * this.ordine.Copie;
                }
            });

            this.rigaOrdineChanged();
        }

        public changeModelloSet(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Ordini/modalModelliSet.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.colonne.push(new ViewModels.RicercaRigaVm("Serie", "TRC"));
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.ordiniFactory.ricerca(ricerca, page).then(
                result => {
                    var r = result.List;
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>result;
                    this.ordini = result.List;
                },
                reason => {
                }
            );
        }

        public resetForm(): void {
            this.ricerca = new RicercaOrdiniVm();
        }

        public aggiornaRicercaModelliSet(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.modelliSetFactory.ricerca(ricerca).then(
                risultato => {
                    this.modelliSet = risultato.List;
                }
            );
        }

        public resetFormModelliSet(): void {
            this.ricercaModelliSet = new Trace.ModelliSet.RicercaModelliSet();
        }

        public selezionaModelloSet(mds: Set.SetVm): void {
            var vm = this;
            this.ordine.IdModelloSet = mds.Id;
            this.ordine.ModelloSet = mds.Descrizione_it_IT;
            
            this.modelliSetFactory.leggi(mds.Id).then(
                result => {
                    this.modelloSet = result;
                    this.ordine.NumRighe = this.modelloSet.Corpo.length;
                    this.ordine.NumProdotti = 0;
                    this.ordine.TotaleOrdine = 0;
                    this.ordine.Copie = 1;
                    this.ordine.Dettagli = new Array<Ordini.OrdineDetail>();
                    $.each(this.modelloSet.Corpo, (index, item) => {
                        console.log(item.IdMarca);
                        if (item.IdMarca != 1) {
                            var r = new Ordini.OrdineDetail();
                            r.Codice = item.Codice;
                            r.Descrizione = item.DescrizioneIt;
                            r.IdModelloSetDetail = item.Id;
                            r.IdProdotto = item.IdProdotto;
                            r.IdTipo = 1;
                            r.QtaUnitaria = item.Qta == 0 ? 1 : item.Qta;
                            r.Qta = item.Qta == 0 ? 1 : item.Qta;
                            r.QtaProposta = item.Qta == 0 ? 1 : item.Qta;
                            r.PrzUnitario = item.Prezzo == undefined || item.Prezzo <= 0 ? 0 : ((item.Prezzo * Globals.ScontoSaniService) / 100);
                            r.PrzRiga = r.PrzUnitario * r.Qta;
                            vm.ordine.NumProdotti += r.Qta;
                            vm.ordine.Dettagli.push(r);
                            vm.ordine.TotaleOrdine += r.PrzRiga;
                        }
                    });
                    vm.ordine.Dettagli.sort(SortByCodice);
                },
                reason => {
                }
            );
        }

        public nuovoProdotto(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Ordini/modalProdotti.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public aggiornaRicercaProdotti(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.colonne.push(new ViewModels.RicercaRigaVm("IdMarca", "not 1"));
            ricerca.colonne.push();
            ricerca.max = 50;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.prodottiFactory.ricerca(ricerca).then(
                risultato => {
                    this.prodotti = risultato;
                }
            );
        }

        public resetFormProdotti(): void {
            this.ricercaProdotti = new Prodotto.RicercaProdotti();
        }

        public aggiungiProdotto(item: Prodotto.ProdottoVm): void {
            console.log("Other ordiniController");
            var vm = this;
            var presente = false;
            if (vm.ordine.Dettagli == undefined)
                vm.ordine.Dettagli = new Array<OrdineDetail>();
            $.each(vm.ordine.Dettagli, (index, dett) => {
                if (dett.Codice === item.Codice) {
                    dett.Qta += 1;
                    vm.rigaOrdineChanged();
                    presente = true;
                    return;
                }
            });
            if (presente) {
                this.msgFactory.alert(this.localeFactory.get('prodotto_presente_qta_aggiornata'));
            }
            else {
                var r = new Ordini.OrdineDetail();
                r.Codice = item.Codice;
                r.Descrizione = item.Descrizione_it_IT;
                r.IdProdotto = item.Id;
                r.IdTipo = 1;
                r.Qta = 1;
                r.QtaProposta = 0;
                r.PrzUnitario = item.CostoStandard == undefined || item.CostoStandard <= 0 ? 0 :
                    this.ordine.Serie == "SNS"
                        ? ((item.CostoStandard * Globals.ScontoSaniService) / 100)
                        : item.CostoStandard;
                r.PrzRiga = r.PrzUnitario * r.Qta;
                vm.ordine.Dettagli.push(r);
            }
            this.rigaOrdineChanged();
        }

        public rigaOrdineChanged(): void {
            var numProdotti = 0;
            var totOrdine = 0;
            var moltiplicatore = this.ordine.Copie;
            if (this.ordine == undefined || this.ordine.Dettagli == undefined) return;

            $.each(this.ordine.Dettagli, (idex, dett) => {
                dett.PrzRiga = dett.PrzUnitario * dett.Qta;
                numProdotti += dett.Qta;
                totOrdine += dett.PrzRiga;
            });

            this.ordine.TotaleOrdine = totOrdine;
            this.ordine.NumProdotti = numProdotti;
            this.ordine.NumRighe = this.ordine.Dettagli.length;
        }

        public removeRiga(item: OrdineDetail): void {
            var vm = this;
            this.msgFactory.confirm(this.localeFactory.getFormatted("remove_riga_conferma", [item.Descrizione])).then(
                result => {
                    if (!result.result) return;
                    $.each(vm.ordine.Dettagli, (index, dett) => {
                        if (dett.Codice === item.Codice) {
                            vm.ordine.Dettagli.splice(index, 1);
                            vm.rigaOrdineChanged();
                            return;
                        }
                    });
                }
            );
        }

        public backToList(): void {
            this.changeState('list');
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                this.backToList();
            });
        }

        public registra(): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("salva_ordine_conferma", [this.ordine.Numero, this.ordine.Serie])).then(
                result => {
                    if (!result.result) return;
                    this.doRegistra();
                }
            );
        }

        public doRegistra(): void {
            this.isSaving = true;
            this.ordiniFactory.registra(this.ordine).then(
                result => {
                    if (result === true) {
                        this.msgFactory.alert(this.localeFactory.getFormatted("salva_ordine_ok", [this.ordine.Numero, this.ordine.Serie]));
                        this.backToList();
                    }
                    else {
                        this.msgFactory.alert(result);
                    }
                    this.isSaving = false;
                },
                reason => {
                    
                    this.isSaving = false;
                }
            );
        }
    }
}
app.controller("otherOrdiniController", Trace.Ordini.otherOrdiniController);