namespace Trace.Localization {
    export class localeFactory {
        private genericLang: Object;
        private specificLang: Object;
        static $inject = ["$http"];
        constructor(private $http: ng.IHttpService) {
            var vm = this;
            $http.get(appRoot + "js/lang/lang." + culture + ".json").success((result: Object) => {
                this.genericLang = result;
            });
            $http.get(appRoot + "js/lang/lang." + controllerName.toLowerCase() + "." + culture + ".json")
                .success((result: Object) => {
                    vm.specificLang = result;
                })
                .error(function (data: any): void {
                    vm.specificLang = null;
                });
        }

        public get(key: string): string {            
            if (this.specificLang != undefined && this.specificLang.hasOwnProperty(key))
                return this.specificLang[key];
            else if (this.genericLang != undefined && this.genericLang.hasOwnProperty(key))
                return this.genericLang[key];
            else
                return "MISSING_LABEL";
        }

        public getFormatted(key: string, args: Array<any>): string {
            var stringa: any = "";
            if (this.specificLang != undefined && this.specificLang.hasOwnProperty(key))
                stringa = this.specificLang[key];
            else if (this.genericLang != undefined && this.genericLang.hasOwnProperty(key))
                stringa = this.genericLang[key];
            else
                return "MISSING_LABEL";

            return stringa.format(args);
        } 

        //public getLanguages(): angular.IHttpPromise<Array<Trace.ViewModels.LinguaVm>> {
        //    return this.$http.post("/api/languages/Get", {});
        //}
    }
}
app.factory("localeFactory", ($http) => new Trace.Localization.localeFactory($http));