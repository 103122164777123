
module Trace.Startup {
    export class startupController extends epBaseController {
        public ricerca: RicercaStartupVm;
        public startup: StartupVm;
        public sezioni: Array<Array<IStartupDetailVm>>;
        public tipiMacchine: ViewModels.TipoMacchina[];
        public tipiMacchineCentrale: ViewModels.TipoMacchina[];
        public listaStartup: IStartupVm[];
        public centrali: Centrale.Centrale[];
        public centrale: Centrale.Centrale;
        public formStartup: any;
        private currentPage: number;
        public loadingSezioni: boolean;
        static $inject = ["$rootScope", "$scope", "dtFactory", "utenteFactory", "startupFactory", "centraliFactory", "localeFactory", "msgFactory", "$uibModal", "tracciabilitaFactory"];
        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private dtFactory: datatables.dtFactory,
            public utenteFactory: Utente.utenteFactory,
            private startupFactory: startupFactory,
            private centraliFactory: Centrale.centraliFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(null, utenteFactory, undefined, undefined, undefined, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);
            this.formStartup = {};
            var vm = this;
            $(document).on("gotUserSession", (e: JQueryEventObject) => {
                vm.init();
            });
            this.getTipiMacchine();
        }

        public init(): void {
            if (this.applicationSession.IdCentrale == undefined) {
                this.getCentraliUtente();
            }
            else {
                this.centraliFactory.leggi(this.applicationSession.IdCentrale).then(
                    result => {
                        console.log("centrale caricata", result);
                        this.centrale = result;
                        this.centraliFactory.ListaMacchine().then(
                            result => {
                                this.centrale.Macchine = result;
                            }
                        );
                    }
                );
            }
            this.changeState('list');
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                vm.changeState('list');
            });
        }

        public backToList(): void {
            this.changeState('list');
        }

        private getTipiMacchine(): void {
            this.centraliFactory.TipiMacchine().then(
                result => {
                    this.tipiMacchine = result;
                }
            );
        }

        public getCentraliUtente(): void {
            this.centraliFactory.listaUtente(this.applicationSession.IdUtente).then(
                result => {
                    this.centrali = result;
                }
            );
        }

        public changeState(stato: string): void {
            var vm = this;
            switch (stato) {
                case "list":
                    this.startup = undefined;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                case "details":
                    if (this.formStartup != undefined) this.formStartup.$setPristine();
                    vm.loadingSezioni = true;
                    setTimeout(function () {
                        var altezza = 0;
                        $(".startup-panel").each((index, item) => {
                            if (altezza < $(item).outerHeight()) altezza = $(item).outerHeight();
                        });
                        $(".startup-panel").outerHeight(altezza);
                        vm.loadingSezioni = false;
                    }, 500);
                    break;
            }
            this.currentState = stato;
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 1000);
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            if (this.centrale != undefined) {
                var f = new ViewModels.RicercaRigaVm("IdCentrale", this.centrale.Id.toString());
                filtro.push(f);
            }
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.startupFactory.ricerca(ricerca, page).then(
                risultato => {
                    var r = risultato;
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                    this.listaStartup = r.List;
                }
            );
        }

        public resetForm(): void {
            this.ricerca = new RicercaStartupVm();
        }

        public leggi(id: number): void {
            this.startupFactory.leggi(id).then(
                result => {
                    this.startup = result;
                    this.changeState('details');
                    this.setUpManipola(result.Dettagli);
                }
            );
        }

        public aggiungi(): void {
           this.doNuovo();
        }

        private doNuovo(): void {
            this.startupFactory.ModelloStartupDetails(this.applicationSession.IdCentrale).then(
                result => {
                    this.startup = new StartupVm();
                    var centrale = this.localized(this.centrale, "Descrizione_");
                    this.startup.Descrizione = this.localeFactory.getFormatted("descrizione_registrazione_startup", [moment().format("DD/MM/YYYY"), moment().format("HH:mm"), centrale]);
                    this.startup.IdCentrale = this.centrale.Id;
                    this.setUpManipola(result);
                    this.editMode = false;
                    this.titoloManipola = this.localeFactory.get("titolo_new");
                    this.changeState("manipola");
                }
            );
        }

        private setUpManipola(model: IStartupDetailVm[]): void {
            this.tipiMacchineCentrale = new Array<ViewModels.TipoMacchina>();
            this.sezioni = new Array<Array<IStartupDetailVm>>();
            $.each(this.centrale.Macchine, (index, item) => {
                var tp = $.grep(this.tipiMacchine, (tp, idtp) => {
                    return tp.Codice === item.CodiceTipo;
                })[0];
                this.tipiMacchineCentrale.push(tp);
            });

            this.tipiMacchineCentrale = this.distinct(this.tipiMacchineCentrale, ["Codice"]);

            this.tipiMacchineCentrale.push(<ViewModels.TipoMacchina>{
                Codice: 'V-Pro',
                Descrizione_it_IT: 'V-Pro',
                Descrizione_en_US: 'V-Pro',
                Plurale_it_IT: 'V-Pro',
                Plurale_en_US: 'V-Pro'
            });

            $.each(this.tipiMacchineCentrale, (index, item) => {
                var arr = $.grep(model, (m, mid) => {
                    if (m.Codice == 'AUT' && m.Macchina == 'V Pro') m.Codice = 'V-Pro';
                    return m.Codice === item.Codice;
                });
                this.sezioni.push(arr);
            });

            console.log("Sezioni isolate", this.sezioni);
        }

        public registra(): void {
            this.showValidationMessages = false;
            var result = this.validazione();
            if (!result) return;
            
            this.msgFactory.confirm(this.localeFactory.get("registrazione_startup_conferma")).then(
                result => {
                    if (!result.result) return;
                    this.doRegistra();
                }
            );
        }

        private validazione(): boolean {
            if (!this.formStartup.$valid) {
                this.showValidationMessages = true;
                this.formStartup.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return false;
            }
            return true;
        }

        private doRegistra(): void {
            var dett = new Array<IStartupDetailVm>();
            $.each(this.sezioni, (index, item) => {
                $.each(item, (idf, f) => {
                    dett.push(f);
                });
            });
            
            this.startup.Dettagli = dett;
            console.log("salvo questi dati", this.startup);
            this.startupFactory.registra(this.startup).then(
                result => {
                    this.msgFactory.alert(this.localeFactory.get("registrazione_startup_ok"));
                    this.changeState("list");
                },
                reason => {}
            );
        }
    }
}
app.controller("startupController", Trace.Startup.startupController);