module Trace.Set {
    export class StrumentoVm {
        public Id: number;
        public IdStrumento: number;
        public DataCreazione: Date;
        public Uid: string;
        public IdProdotto: number;
        public IdTnt: number;
        public IdRot: number;
        public IdSet: number;
        public IdLottoProduzioneDetail: number;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public Qta: number;
        public Matricola: string;
        public NumGriglia: number;
        public NumSpillone: number;
        public NumPosizione: number;
        public IdProprietario: number;
        public FlgFermo: boolean;
        public IdMotivoFermo: number;
        public IdSostituto: number;
        public DataObsoleto: Date;
        public IdUtenteObsoleto: Date;
        public DataUltimaModifica: Date;
        public IdUltimaModifica: number;
        public HasDistinta: boolean;
        public Immagini: any;
        public Precauzioni: string;
        public Note: String;
        public IdModelloSetDetail: number;
        public FlgUltrasuoni: boolean;
        public FlgLavaggioManuale: boolean;
        public FlgGuasto: boolean;
        public FlgMancante: boolean;
        public FlgContato: boolean;
        public QtaGuasto: number;
        public QtaMancante: number;
        public QtaContati: number;
        public QtaEsaurimento: number;
        public Ordinamento: number;
        public FlgMarcatura: boolean;
        public FlgManutenzione: boolean;
        public FlgSostituzione: boolean;
        public FlgStessoCodice: boolean;
        public FlgEsaurimento: boolean;
        public FlgSmaltimento: boolean;
        public FlgReintegroDisponibile: boolean;
        public CodiceStato: string;
        public DescrStato: string;
        public DataStato: string;
        public DataFermo: string;
        public DataClickConfezionamento: Date;
        public QtaSostituiti: number;
        public Peso: number;
        public IdTipoManutenzione: number;
        public IdAziendaRiparatrice: number;
        public DescrizioneAziendaRiparatrice: string;
    }
}