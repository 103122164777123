
module Trace.Cespiti { 

    export class cespitiAmmortamentiController {
        public idAmmCespite: number;
        public lista: Trace.Cespiti.AmmCespitiVm[];
        public recordCorrente: Trace.Cespiti.AmmCespitiVm;
        public testoAzione: string;
        public ricerca: any;
        public ricercaDettagli: any;

        public currentState: string;
        public titoloManipola: string;

        public showValidationMessages: boolean;
        public formAmmortamenti: any;

        public isSaving: boolean;

        public currentPage: number;

        static $inject = ['$scope', 'cespitiFactory', 'localeFactory', 'msgFactory', 'dtFactory'];
        constructor(private $scope: ng.IScope, private cespitiFactory: Trace.Cespiti.cespitiFactory,
            private localeFactory: Localization.localeFactory, private msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory) {
            //this.annulla();
        }

        public formatValuta(value: number, simboloValuta: string = '€'): string {
            if (isNaN(value)) value = 0;
            if (value == null || value == undefined) value = 0;
            var result = simboloValuta + ' ' + value.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
            result = result.replace(".", ",");
            while (result.indexOf(',') >= 0) {
                var count = (result.match(/,/g) || []).length;
                if (count == 1) break;
                result = result.replace(",", ".");
            }

            return result;
        }

        private getLabelAmmortamento(field: string) {
            if (this.recordCorrente == undefined) return '';
            switch (field) {
                case 'ammortizzato_prec':
                    return 'Ammortizzato al ' + moment(this.recordCorrente.DataRiferimentoDal).add('days', -1).format("DD/MM/YYYY");
                case 'residuo_prec':
                    return 'Da ammortizzare al ' + moment(this.recordCorrente.DataRiferimentoDal).add('days', -1).format("DD/MM/YYYY");
            }
        }

        //public registra(): void {
        //    this.showValidationMessages = false;
        //    if (!this.formAmmortamenti.$valid) {
        //        this.showValidationMessages = true;
        //        this.formAmmortamenti.$setDirty();
        //        this.msgFactory.error(this.localeFactory.get("validazione_errore"));
        //        return;
        //    }
        //    this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
        //        if (result.result) {
        //            if (this.recordCorrente.Id == undefined)
        //                this.marcheFactory.inserisci(this.recordCorrente).then((result => {
        //                    this.annulla();
        //                }));
        //            else
        //                this.marcheFactory.modifica(this.recordCorrente).then((result => {
        //                    this.annulla();
        //                }));
        //        }
        //    });
        //}

        //public annulla(): void {
        //    this.recordCorrente = <Trace.Marche.Marca>{};
        //    this.testoAzione = this.localeFactory.get('button_inserisci');
        //    this.ricerca = new Trace.Marche.RicercaMarca();

        //    this.marcheFactory.lista().then((risultato: Trace.Marche.Marca[]) => {
        //        this.lista = risultato;
        //        this.changeState('list');
        //    });
        //}

        //public cancella(idMarca: number): void {
        //    this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
        //        if (result.result) {
        //            this.marcheFactory.cancella(idMarca).then((result => {
        //                this.annulla();
        //            })).catch((reason: string) => {
        //                this.msgFactory.error(this.localeFactory.get(reason));
        //            });
        //        }
        //    });
        //}

        public calcola() {
            this.isSaving = true;
            if (this.recordCorrente.VariazionePercentualeApplicata == undefined) this.recordCorrente.VariazionePercentualeApplicata = 0;
            this.cespitiFactory.calcolaAmmortamento(this.recordCorrente).then(
                result => {
                    this.recordCorrente = result;
                    this.idAmmCespite = this.recordCorrente.Id;
                    this.dtFactory.aggiornaRicerca("lista-amm-details");
                    this.isSaving = false;
                },
                error => {
                    this.msgFactory.error(error);
                    this.isSaving = false;
                }
            );
        }

        public leggi(idAmm: number): void {
            this.idAmmCespite = idAmm;
            this.resetFormDettagli();
            this.titoloManipola = this.localeFactory.get("titolo_modifica_amm");
            this.cespitiFactory.leggiAmmTestata(idAmm).then((amm: Trace.Cespiti.AmmCespitiVm) => {
                this.recordCorrente = amm;
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.changeState('manipola');
            });
        }

        public doNuovo(data: any): void {
            this.idAmmCespite = undefined;
            this.titoloManipola = this.localeFactory.get("titolo_nuovo_amm");
            this.recordCorrente = <Trace.Cespiti.AmmCespitiVm>{
                FlgAmmIt: data.FlgAmmIt,
                FlgDefinitivo: data.FlgDefinitivo,
                DataRiferimentoDal: data.DataRiferimentoDal,
                DataRiferimentoAl: data.DataRiferimentoAl,
                VariazionePercentualeApplicata: 0,
                VariazionePercentualeApplicataOriginale: 0
            };
            this.changeState("manipola");
        }

        public nuovo(flgAmmIt: boolean) {
            this.cespitiFactory.checkNuovoAmmortamento(flgAmmIt).then(
                result => {
                    switch (result.Result) {
                        case "CREAZIONE_OK":
                            this.doNuovo(result);
                            break;
                        case "TRIMESTRE_NON_CONCLUSO":
                            this.msgFactory.alert(this.localeFactory.get("cespiti_amm_trimestre_non_concluso"));
                            break;
                        case "ESISTE_AMM_PROVA":
                            this.msgFactory.confirm(this.localeFactory.get("cespiti_amm_esiste_amm_prova")).then(
                                resConfirm => {
                                    if (resConfirm.result) this.leggi(result.IdAmmProva);
                                }
                            );
                            break;
                    }
                },
                error => { }
            );
        }

        public rendiDefinitivo() {
            let annullaModifiche = true;
            if (this.detailToEdit != undefined) annullaModifiche = this.editDetailFocusOut();

            if (annullaModifiche && this.detailToEdit == undefined) {
                this.isSaving = true;
                this.cespitiFactory.rendiAmmDefinitivo(this.recordCorrente.Id).then(
                    result => {
                        this.recordCorrente.FlgDefinitivo = true;
                        // rendere non modificabile il tutto
                        this.isSaving = false;
                    },
                    error => this.isSaving = false
                );
            }
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.cespitiFactory.ricercaAmm(ricerca, page).then((risultato: Trace.Cespiti.AmmCespitiPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.lista = risultato.List;
            });
        }

        public aggiornaRicercaDettagli(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var annullaModifiche = true;
            if (this.detailToEdit != undefined) annullaModifiche = this.editDetailFocusOut();
            if (!annullaModifiche) return;
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.colonne.push(<ViewModels.RicercaRigaVm>{
                colonna: "IdAmmCespite",
                valore: this.idAmmCespite.toString(),
                direzioneSort: Trace.ViewModels.DirezioneSort.Ascendente
            });
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.cespitiFactory.ricercaAmmDetails(ricerca, page).then((risultato: Trace.Cespiti.AmmCespitiDetailsPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.recordCorrente.Dettagli = risultato.List;
            });
        }

        //public aggiungi(): void {
        //    this.titoloManipola = this.localeFactory.get("titolo_nuovo");
        //    this.recordCorrente = <Trace.Marche.Marca>{};
        //    this.testoAzione = this.localeFactory.get('button_inserisci');
        //    this.changeState('manipola');
        //}

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca("lista-amm");
                    break;

                case "manipola":
                    this.formAmmortamenti.$setPristine();
                    this.isSaving = false;
                    this.detailToEdit = undefined;
                    this.detailToEdit_BACKUP = undefined;
                    this.FlgModificheDettagli = false;
                    this.editDetailErrors = this.initDetailErrors();
                    this.dtFactory.aggiornaRicerca("lista-amm-details");
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public isDefinitivo(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.FlgDefinitivo;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = {};
        }
        public resetFormDettagli(): void {
            this.ricercaDettagli = {};
        }

        public formatDate(data: Date, format: string = "DD/MM/YYYY"): string {
            if (data == null) data = new Date();
            var result = moment(data).format(format);
            return result;
        }


        /************************************************
        *                                               *
        *       -- GESTIONE PANNELLO DETTAGLI --        *
        *                                               *
        ************************************************/
        public FlgModificheDettagli: boolean;
        public detailToEdit: AmmCespitiDetailsVm;
        public detailToEdit_BACKUP: AmmCespitiDetailsVm;
        public editDetailErrors: any;
                
        public onEditDetailChange() {
            this.validaDettaglio(this.detailToEdit, this.editDetailErrors, true);
        }
        public initDetailErrors(): any {
            return {
                TotaleAmmortizzatoOriginale: {},
                TotaleDaAmmortizzareOriginale: {}
            };
        }
        //private addDettaglio() {
        //    this.FlgModificheDettagli = true;
        //    let annullaModifiche = true;

        //    if (this.detailToEdit != undefined) annullaModifiche = this.editDetailFocusOut();

        //    if (annullaModifiche && this.detailToEdit == undefined) this.doAddDettaglio();
        //}
        //private doAddDettaglio() {
        //    this.addDetailSubmitted = true;
        //    if (!this.validaDettaglio(this.detailToAdd, this.addDetailErrors, this.addDetailSubmitted)) {
        //        let boh = true;
        //        return;
        //    }
        //    this.detailToAdd.AreaStr = this.areeCentrale.filter(h => h.Id == this.detailToAdd.IdArea)[0].Descrizione_it_IT;
        //    this.recordCorrente.Dettagli.push(this.detailToAdd);
        //    this.svuotaAddDetail();
        //}
        public checkEditDetailStatus(dettaglio: any): boolean {
            return dettaglio === this.detailToEdit ? true : false;
        }
        private editModeDettaglio(item: AmmCespitiDetailsVm) {
            if (this.isSaving) return;

            this.FlgModificheDettagli = true;
            let annullaModifiche = true;

            if (this.detailToEdit != undefined) annullaModifiche = this.editDetailFocusOut();

            if (annullaModifiche && this.detailToEdit == undefined) {
                this.detailToEdit_BACKUP = {
                    Id: item.Id,
                    Codice: item.Codice,
                    CodiceEsterno: item.CodiceEsterno,
                    Descrizione: item.Descrizione,
                    NumFattura: item.NumFattura,
                    DataFattura: item.DataFattura,
                    IdCategoria: item.IdCategoria,
                    Categoria: item.Categoria,
                    IdUbicazione: item.IdUbicazione,
                    CodiceUbicazione: item.CodiceUbicazione,
                    CommessaUbicazione: item.CommessaUbicazione,
                    Ubicazione: item.Ubicazione,
                    TotaleAmmortizzato: item.TotaleAmmortizzato,
                    TotaleDaAmmortizzare: item.TotaleDaAmmortizzare,
                    TotaleAmmortizzatoOriginale: item.TotaleAmmortizzatoOriginale,
                    TotaleDaAmmortizzareOriginale: item.TotaleDaAmmortizzareOriginale,
                    ValoreTotale: item.ValoreTotale,
                    TotaleAmmortizzatoPrec: item.TotaleAmmortizzatoPrec,
                    TotaleDaAmmortizzarePrec: item.TotaleDaAmmortizzarePrec
                }
                this.detailToEdit = item;
            }
        }
        //private deleteDettaglio(item: any) {
        //    this.FlgModificheDettagli = true;
        //    let annullaModifiche = true;

        //    if (this.detailToEdit != undefined) annullaModifiche = this.editDetailFocusOut();

        //    if (annullaModifiche && this.detailToEdit == undefined) this.recordCorrente.Dettagli = this.recordCorrente.Dettagli.filter(h => h !== item);
        //}
        private saveDettaglio() {
            if (this.isSaving) return;

            if (this.detailToEdit.TotaleAmmortizzatoOriginale == this.detailToEdit_BACKUP.TotaleAmmortizzatoOriginale && this.detailToEdit.TotaleDaAmmortizzareOriginale == this.detailToEdit_BACKUP.TotaleDaAmmortizzareOriginale) {
                this.doEditDetailFocusOut();
                return;
            }

            if (!this.validaDettaglio(this.detailToEdit, this.editDetailErrors, true)) return;

            this.isSaving = true;
            this.cespitiFactory.calcolaAmmortamentoRiga(this.detailToEdit).then(
                (result: Trace.Cespiti.AmmCespitiVm) =>  {

                    var a = result.Dettagli[0];

                    this.detailToEdit.TotaleAmmortizzato = a.TotaleAmmortizzato;
                    this.detailToEdit.TotaleDaAmmortizzare = a.TotaleDaAmmortizzare;
                    this.detailToEdit.TotaleAmmortizzatoOriginale = a.TotaleAmmortizzatoOriginale;
                    this.detailToEdit.TotaleDaAmmortizzareOriginale = a.TotaleDaAmmortizzareOriginale;

                    // Update totali testata
                    this.recordCorrente.TotaleAmmortizzato = result.TotaleAmmortizzato;
                    this.recordCorrente.TotaleDaAmmortizzare = result.TotaleDaAmmortizzare;

                    this.detailToEdit = undefined;
                    this.detailToEdit_BACKUP = undefined;

                    this.isSaving = false;
                },
                error => this.isSaving = false
            );
        }
        public editDetailFocusOut(): boolean {
            this.msgFactory.confirm("Annullare le modifiche al dettaglio modificato?").then(
                result => {
                    if (result.result) {
                        this.doEditDetailFocusOut();
                        return true;
                    } else {
                        return false;
                        //alert("Hai premuto Annulla");
                    }
                }
            );
            return false;
        }
        public doEditDetailFocusOut() {
            if (this.isSaving) return;

            let i = this.recordCorrente.Dettagli.indexOf(this.detailToEdit);
            this.recordCorrente.Dettagli[i] = this.detailToEdit_BACKUP;
            this.validaDettaglio(this.detailToEdit, this.editDetailErrors, false);
            this.detailToEdit = undefined;
            this.detailToEdit_BACKUP = undefined;
        }

        //private validateIPaddress(ipaddress: string): boolean {
        //    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        //        return true;
        //    }
        //    //alert("You have entered an invalid IP address!")
        //    return false;
        //}

        public isCalcoloIniziale(): boolean {
            return this.recordCorrente != undefined && moment(this.recordCorrente.DataRiferimentoDal).format("DD/MM/YYYY") == '01/01/2016';
        }

        public quantitaAmmChanged(item: AmmCespitiDetailsVm, field: string): void {
            switch (field) {
                case 'TotaleAmmortizzatoOriginale':
                    if (item.TotaleAmmortizzatoOriginale != undefined && !isNaN(item.TotaleAmmortizzatoOriginale)) item.TotaleDaAmmortizzareOriginale = item.ValoreTotale - item.TotaleAmmortizzatoPrec - item.TotaleAmmortizzatoOriginale;
                    break;
                case 'TotaleDaAmmortizzareOriginale':
                    if (item.TotaleDaAmmortizzareOriginale != undefined && !isNaN(item.TotaleDaAmmortizzareOriginale)) item.TotaleAmmortizzatoOriginale = item.ValoreTotale - item.TotaleDaAmmortizzarePrec - item.TotaleDaAmmortizzareOriginale;
                    break;
            }
        }

        public getExportUrl(tipo: string): string {
            if (this.idAmmCespite == undefined) return '';
            var result: string;
            switch (tipo) {
                default:
                    result = appRoot + "Reports/ExportAmmortamentoPeriodo" + "?idAmmCespite=" + this.idAmmCespite;
                    break;
            }
            return result;
        }

        private validaDettaglio(dettaglio: any, errors: any, submitted: boolean): boolean {
            //errors = this.initDetailErrors();

            errors["TotaleAmmortizzatoOriginale"] = {};
            errors["TotaleDaAmmortizzareOriginale"] = {};
            var result = true;
            if (submitted) {
                if (dettaglio.TotaleAmmortizzatoOriginale == undefined || isNaN(dettaglio.TotaleAmmortizzatoOriginale)) {
                    result = false;
                    errors["TotaleAmmortizzatoOriginale"].isValid = false;
                    errors["TotaleAmmortizzatoOriginale"].codeType = "validazione_obbligatorio";
                }
                else {
                    if (dettaglio.TotaleAmmortizzatoOriginale < 0) {
                        result = false;
                        errors["TotaleAmmortizzatoOriginale"].isValid = false;
                        errors["TotaleAmmortizzatoOriginale"].codeType = "validazione_num_positivo";
                    }
                    else errors["TotaleAmmortizzatoOriginale"].isValid = true;
                }
                if (dettaglio.TotaleDaAmmortizzareOriginale == undefined || isNaN(dettaglio.TotaleDaAmmortizzareOriginale)) {
                    result = false;
                    errors["TotaleDaAmmortizzareOriginale"].isValid = false;
                    errors["TotaleDaAmmortizzareOriginale"].codeType = "validazione_obbligatorio";
                }
                else {
                    if (dettaglio.TotaleDaAmmortizzareOriginale < 0) {
                        result = false;
                        errors["TotaleDaAmmortizzareOriginale"].isValid = false;
                        errors["TotaleDaAmmortizzareOriginale"].codeType = "validazione_num_positivo";
                    }
                    else errors["TotaleDaAmmortizzareOriginale"].isValid = true;
                }
            }
            return result;
        }
        //private svuotaAddDetail() {
        //    this.addDetailSubmitted = false;
        //    this.initDetailToAdd();
        //    this.validaDettaglio(this.detailToAdd, this.addDetailErrors, this.addDetailSubmitted);
        //}
        //private initDetailToAdd() {
        //    this.detailToAdd = <MappaturaCentraleDetailsVm>{
        //        Id: undefined,
        //        IdArea: undefined,
        //        AreaStr: undefined,
        //        IpAddress: undefined,
        //        MacAddress: undefined,
        //        Note: undefined
        //    };
        //}    

    }
}
app.controller("cespitiAmmortamentiController", Trace.Cespiti.cespitiAmmortamentiController);