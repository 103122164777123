app.factory("sediFactory", ($http, $location, $q) => new Trace.Sede.sediFactory($http, $location, $q));

module Trace.Sede {
    export class Sede {
        public Id : number;
        public Codice : string;
        public DataCreazione : Date;
        public DataObsoleto : Date;
        public DataUltimaModifica : Date;
        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public IdImmagine : number;
        public IdUtenteCreazione : number;
        public IdUtenteObsoleto : number;
        public IdUtenteUltimaModifica : number;
        public IdGruppo: number;
        public IdSottogruppo: number;
        public Indirizzo: string;
        public FlgEditabile: boolean = true;
    }

    export class sediFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Sede/";
        }


        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {

        }

        public lista(): ng.IPromise<sedeVm[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: sedeVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public listaCompleta(): ng.IPromise<sedeVm[]> {
            var workUrl = this.GetBaseUrl() + "ListaCompleta";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: sedeVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(sede: Sede): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, sede).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(sede: Sede): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + sede.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, sede).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }


        public cancella(idGruppo: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idGruppo;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public patch(idGruppo: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idGruppo;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public leggi(idSede: number): ng.IPromise<Sede> {
            var workUrl = this.GetBaseUrl() + idSede;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Sede, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public listaSottoGruppo(idSottoGruppo: number): ng.IPromise<Trace.Sede.Sede[]> {
            var workUrl = this.GetBaseUrl() + "ListaSottoGruppo/" + idSottoGruppo;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Trace.SottoGruppo.SottoGruppo[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<sedeVm[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: sedeVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public listaSottoGruppoAsSe(idGruppo: number): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "ListaSottoGruppoAsSe/" + idGruppo;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

    }
}