module Trace.Analisi {
    export class analisiController {
        public $modalInstance: ng.ui.bootstrap.IModalServiceInstance;
        public filtro: FiltroInterventi;
        public gruppi: Trace.Gruppo.Gruppo[]; 
        public sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];
        public listaComplessita: Set.ComplessitaVm[];
        public lista: Analisi.AnalisiItem[];
        public listaProduzione: Analisi.VwAnalisiIntervento[];
        public flgShowDettagli: boolean;
        public groupedResult: Array<Analisi.AnalisiResultVm>;
        public listaTnt: Helpers.SimpleEntity[];
        public tipiInterventi: Helpers.SimpleEntity[];
        public modelliSet: Helpers.SimpleEntity[];
        public isLoading: boolean;
        public arrayRicerca: Helpers.SimpleEntity[];
        public dettagliLotto: Array<VwDettaglioLotto>;
        public ricercaDescrizioneTipoIntervento: string;
        public ricercaDescrizioneSet: string;
        public ricercaDescrizioneTnt: string;
        public tipo: string;
        public selectedItem: Analisi.VwAnalisiIntervento;
        public selectedIntervento: Analisi.AnalisiItem;
        public utentiAttivi: Utente.UtenteSimpleVm[];
        public lottiAttivi: Produzione.LottoProduzioneAttivoVm[];
        public fasiProduzione: Produzione.FaseDetailVm[];
        static $inject = ['$rootScope', '$scope', 'analisiFactory', 'setFactory', 'gruppiFactory', 'sottoGruppiFactory', 'sediFactory',
            'centriCostoFactory', 'tipoInterventoFactory', 'modelliSetFactory', 'tntFactory', 'utenteFactory', 'produzioneFactory',
            'fasiFactory', 'localeFactory', 'msgFactory', '$uibModal'];

        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private analisiFactory: Analisi.analisiFactory,
            private setFactory: Set.setFactory,
            private gruppiFactory: Gruppo.gruppiFactory,
            private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory,
            private tipoInterventoFactory: TipoIntervento.tipoInterventoFactory,
            private modelliSetFactory: ModelliSet.modelliSetFactory,
            private tntFactory: Tnt.tntFactory,
            private utenteFactory: Utente.utenteFactory,
            private produzioneFactory: Produzione.produzioneFactory,
            private fasiFactory: Fase.fasiFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService
        ) {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 500);
            this.filtro = new FiltroInterventi();
            this.getModelliSet();
            this.getTipiInterventi();
            this.getTnt();
            this.getComplessita();
            this.getGruppi();
            this.setUpWatchers();
        }

        public initStatoCentrale(): void {
            this.utenteFactory.sessioneCorrente().then(
                result => {
                    this.fasiFactory.SimpleList().then(
                        fasiResult => {
                            this.fasiProduzione = fasiResult;
                            this.getUtentiAttivi(result.IdCentrale);
                            this.getLottiProduzioneAttivi(result.IdCentrale);
                        }
                    );
                }
            );
        }

        private getUtentiAttivi(idCentrale: number): void {
            this.utenteFactory.UtentiAttivi(idCentrale).then(
                result => {
                    this.utentiAttivi = result;
                }
            );
        }

        private getLottiProduzioneAttivi(idCentrale): void {
            this.produzioneFactory.LottiProduzioneAttivi(idCentrale).then(
                result => {
                    this.lottiAttivi = result;
                }
            );
        }

        private getTnt(): void {
            this.tntFactory.SimpleList().then(
                result => {
                    this.listaTnt = result;
                }
            );
        }

        private getTipiInterventi(): void {
            this.tipoInterventoFactory.SimpleList().then(
                result => {
                    this.tipiInterventi = result;
                }
            );
        }

        private getModelliSet(): void {
            this.modelliSetFactory.SimpleList().then(
                result => {
                    this.modelliSet = result;
                }
            );
        }

        private getComplessita(): void {
            this.setFactory.listaComplessita().then(
                risultato => {
                    this.listaComplessita = risultato;
                }
            );
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.filtro.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppi = undefined;
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSottogruppi(newVal);
            });
            this.$scope.$watch("vm.filtro.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSedi(newVal);
            });
            this.$scope.$watch("vm.filtro.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCosto = undefined;
                else this.getCentriCosto(newVal);
            });

            this.$scope.$watch("vm.ricercaDescrizioneTipoIntervento", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.arrayRicerca = angular.copy(this.tipiInterventi);
                    return;
                }
                var vm = this;
                this.arrayRicerca = $.grep(this.tipiInterventi, (elem, id) => {
                    return elem.Descrizione.toLowerCase().indexOf(vm.ricercaDescrizioneTipoIntervento.toLowerCase()) >= 0;
                });
            });

            this.$scope.$watch("vm.ricercaDescrizioneSet", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.arrayRicerca = angular.copy(this.modelliSet);
                    return;
                }
                var vm = this;
                this.arrayRicerca = $.grep(this.modelliSet, (elem, id) => {
                    return elem.Descrizione.toLowerCase().indexOf(vm.ricercaDescrizioneSet.toLowerCase()) >= 0;
                });
            });
        }

        private getGruppi(): void {
            this.gruppiFactory.lista().then(
                result => {
                    this.gruppi = result;
                }
            );
        }

        private getSottogruppi(idGruppo: number): void {
            this.sottoGruppiFactory.listaGruppo(idGruppo).then(
                result => {
                    this.sottoGruppi = result;
                }
            );
        }

        private getSedi(idSottogruppo: number): void {
            this.sediFactory.listaSottoGruppo(idSottogruppo).then(
                result => {
                    this.sedi = result;
                }
            );
        }

        private getCentriCosto(idSede: number): void {
            this.centriCostoFactory.listaSede(idSede).then(
                result => {
                    this.centriCosto = result;
                }
            );
        }

        public ricerca(): void {
            this.isLoading = true;
            this.analisiFactory.Ricerca(this.filtro).then(
                result => {
                    this.flgShowDettagli = false;
                    this.groupResult(result);
                    this.lista = result;
                    this.updateSintesi();
                    this.updateBusteOspedale();
                    this.isLoading = false;
                }
            );
        }

        public dettagliScarichi(item: Analisi.AnalisiItem): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Analisi/modalDettagliIntervento.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            
            this.selectedIntervento = item;
            this.$modalInstance = this.$uibModal.open(options);
        }

        public showScansione(item: Analisi.VwAnalisiIntervento): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Analisi/modalScan.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.selectedItem = item;
            this.$modalInstance = this.$uibModal.open(options);
        }

        private groupResult(result: Array<Analisi.AnalisiItem>): void {
            var gruppi = new Array<Helpers.SimpleEntity>();
            var sgruppi = new Array<Helpers.SimpleEntity>();
            var centricosto = new Array<Helpers.SimpleEntity>();
            this.groupedResult = new Array<AnalisiResultVm>();
            $.each(result, (index, item) => {
                var ge = $.grep(gruppi, (gg) => {
                    return gg.Id === item.IdGruppo;
                });
                if (ge == undefined || ge.length <= 0)
                    gruppi.push({
                        Id: item.IdGruppo,
                        Descrizione: this.localized(item, "Gruppo_"),//.Gruppo_it_IT,
                        FullObject: null
                    });
                var se = $.grep(sgruppi, (sg) => {
                    return sg.Id === item.IdSottogruppo;
                });
                if (se == undefined || se.length <= 0)
                    sgruppi.push({
                        Id: item.IdSottogruppo,
                        Descrizione: this.localized(item, "Sottogruppo_"),//item.Sottogruppo_it_IT,
                        FullObject: null
                    });
                var ce = $.grep(centricosto, (cg) => {
                    return cg.Id === item.IdCentroCosto;
                });
                if (ce == undefined || ce.length <= 0)
                    centricosto.push({
                        Id: item.IdCentroCosto,
                        Descrizione: this.localized(item, "CentroCosto_"),//item.CentroCosto_it_IT,
                        FullObject: null
                    });
            });
            $.each(gruppi, (did, d) => {
                var filtered = $.grep(result, (r) => {
                    //console.log("Tipo intervento", r.IdTipoIntervento);
                    return r.IdGruppo === d.Id && r.IdTipoIntervento != 0;
                });
                var filteredBuste = $.grep(result, (r) => {
                    return r.IdGruppo === d.Id && r.IdTipoIntervento == 0;
                });
                var gr = new AnalisiResultVm();
                gr.sum = 0;
                gr.sumTnt = 0;
                gr.sumBuste = 0;
                gr.count = filtered.length;
                gr.countBuste = 0;
                $.each(filteredBuste, (idx, elem) => {
                    gr.countBuste += elem.ListaLotti.length;
                });
                gr.title = d.Descrizione;
                gr.lista = filtered;
                gr.children = new Array<AnalisiResultVm>();
                $.each(sgruppi, (sid, s) => {
                    var sfiltered = $.grep(filtered, (rs) => {
                        return rs.IdSottogruppo === s.Id;
                    });
                    var sfilteredBuste = $.grep(filteredBuste, (rs) => {
                        return rs.IdSottogruppo === s.Id;
                    });
                    var sgr = new AnalisiResultVm();
                    sgr.sum = 0;
                    sgr.sumTnt = 0;
                    sgr.sumBuste = 0;
                    sgr.count = sfiltered.length;
                    sgr.countBuste = 0;
                    $.each(sfilteredBuste, (idx, elem) => {
                        sgr.countBuste += elem.ListaLotti.length;
                    });
                    sgr.title = s.Descrizione;
                    sgr.lista = sfiltered;
                    sgr.children = new Array<AnalisiResultVm>();
                    $.each(centricosto, (cid, c) => {
                        var valoreTnt = 0;
                        var cfiltered = $.grep(sfiltered, (rc) => {
                            return rc.IdCentroCosto === c.Id;
                        });
                        var cfilteredBuste = $.grep(sfilteredBuste, (rc) => {
                            return rc.IdCentroCosto === c.Id;
                        });
                        var cc = new AnalisiResultVm();
                        cc.sum = 0;
                        cc.sumTnt = 0;
                        cc.sumBuste = 0;
                        cc.count = cfiltered.length;
                        cc.countBuste = 0;
                        $.each(cfilteredBuste, (idx, elem) => {
                            cc.countBuste += elem.ListaLotti.length;
                        });

                        $.each(cfiltered, (idcf, cf) => {
                            cc.sum += cf.CostoIntervento;
                            cc.sumTnt += this.costoTntIntervento(cf.IdIntervento);
                        });

                        $.each(cfilteredBuste, (idcf, cf) => {
                            cc.sumBuste += cf.CostoIntervento;
                        });
                        
                        cc.title = c.Descrizione;
                        cc.lista = $.merge(cfiltered, cfilteredBuste);

                        sgr.sum += cc.sum;
                        sgr.sumTnt += cc.sumTnt;
                        sgr.sumBuste += cc.sumBuste;
                        sgr.children.push(cc);
                    });
                    gr.sum += sgr.sum;
                    gr.sumTnt += sgr.sumTnt;
                    gr.sumBuste += sgr.sumBuste;
                    gr.children.push(sgr);
                });
                this.groupedResult.push(gr);
            });
        }

        public costoTntIntervento(id: number): number {
            var result = 0;
            if (this.lista == undefined) return result;
            $.each(this.lista, (index, item) => {
                if (item.IdIntervento == id)
                    result += item.PrezzoUnitarioTnt;
            });
            return result;
        }

        public cambioEuroLek(euro: number): string {
            var lek = euro * 135.5;
            return this.formatCurrency(lek, "Lek");
        }

        public formatCurrency(currency: number, valuta: string = "€"): string {
            if (currency == undefined)
                currency = 0.0;
            var result = currency.toFixed(2) + " " + valuta;
            return result;
        }

        public costoMedioIntervento(numInterventi: number, costoInterventi: number, euro: boolean = false): string {
            var result = costoInterventi / numInterventi;
            var valore = (euro)
                ? (result / 135.5).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()
                : result.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
            return valore;
        }

        public costoMedioTntIntervento(interventi: Array<AnalisiItem>, euro: boolean = false): string {
            var numInterventi = interventi.length;
            var costoInterventi = 0;
            $.each(interventi, (index, item) => {
                costoInterventi += this.costoTntIntervento(item.IdIntervento);
            });
            var avg = costoInterventi / numInterventi;
            var valoreAvg = (euro)
                ? avg.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString() //(avg / 135.5).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()
                : avg.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
            var valoreTot = (euro)
                ? costoInterventi.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()//; (costoInterventi / 135.5).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString()
                : costoInterventi.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,").toString();
            return this.localeFactory.getFormatted("costo_medio_tnt", [valoreTot, valoreAvg]);
        }

        private groupProduzioneResult(result: Array<Analisi.VwAnalisiIntervento>): void {
            var date = new Array<string>();
            
            this.groupedResult = new Array<AnalisiResultVm>();
            $.each(result, (index, item) => {
                var df = this.formatDate(item.OnlyData);
                if (date.indexOf(df) < 0)
                    date.push(df);
            });
            $.each(date, (did, d) => {
                var filtered = new Array<Analisi.VwAnalisiIntervento>();
                var interventi = new Array<string>();
                $.each(result, (idr, r) => {
                    var df = this.formatDate(r.OnlyData);
                    if (df === d) {
                        filtered.push(r);
                        if (interventi.indexOf(r.Codice) < 0 && r.Codice != undefined && r.Codice !== "") interventi.push(r.Codice);
                    }
                });

                var gr = new AnalisiResultVm();
                gr.count = filtered.length;
                gr.sum = interventi.length;
                gr.title = d;
                gr.lista = filtered;
                this.groupedResult.push(gr);
            });
        }

        public ricercaProduzione(): void {
            this.isLoading = true;            
            this.analisiFactory.RicercaProduzione(this.filtro).then(
                result => {
                    this.listaProduzione = result;
                    this.groupProduzioneResult(result);
                    this.updateAndamento();
                    this.isLoading = false;
                }
            );
        }

        public updateBusteOspedale(): void {
            var chartOptions = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Buste'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'gray'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    x: 0,
                    verticalAlign: 'bottom',
                    y: 55,
                    floating: true,
                    backgroundColor: 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Totale: {point.stackTotal}'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            color: 'white',
                            style: {
                                textShadow: '0 0 3px black'
                            }
                        }
                    }
                },
                series: []
            };

            var ospedali = new Array<string>();
            var series = new Array<ViewModels.HighCharts.Serie>();
            $.each(this.lista, (index, item) => {
                if (item.IdTipoIntervento == 0) {
                    var df = this.localized(item, 'Sottogruppo_');
                    if (ospedali.indexOf(df) < 0) ospedali.push(df);
                }
            });
            $.each(this.lista, (index, item) => {
                if (item.IdTipoIntervento == 0) {
                    var df = this.localized(item, 'Sottogruppo_');;
                    var exists = false;
                    var costo = Math.floor(item.CostoIntervento / item.ListaLotti.length);
                    $.each(series, (idc, c) => {
                        if (c.id === costo.toString()) {
                            exists = true;
                            var idOspedale = ospedali.indexOf(df);
                            c.data[idOspedale] += item.ListaLotti.length;
                            return;
                        }
                    });
                    if (!exists) {
                        var data = new Array<number>();
                        $.each(ospedali, (idd, d) => {
                            data.push(0);
                        });
                        var d = new ViewModels.HighCharts.Serie(this.localized(item, "Sottogruppo_"), data);
                        var idOspedale = ospedali.indexOf(df);
                        d.data[idOspedale] += item.ListaLotti.length;
                        d.id = costo.toString();
                        d.name = (costo < 1000) ? this.localeFactory.get("buste_vapore") : this.localeFactory.get("buste_plasma");
                        series.push(d);
                    }
                }
            });

            chartOptions.xAxis.categories = ospedali;
            chartOptions.series = series;
            $('#sintesi-buste-ospedale').highcharts(chartOptions);
        }

        public updateAndamento(): void {
            var chartOptions = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    categories: []
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Procedure'
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                            color: 'gray'
                        }
                    }
                },
                legend: {
                    align: 'right',
                    x: 0,
                    verticalAlign: 'bottom',
                    y: 55,
                    floating: true,
                    backgroundColor: 'white',
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Totale: {point.stackTotal}'
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                        dataLabels: {
                            enabled: true,
                            color: 'white',
                            style: {
                                textShadow: '0 0 3px black'
                            }
                        }
                    }
                },
                series: []
            };

            var date = new Array<string>();
            var series = new Array<ViewModels.HighCharts.Serie>();
            $.each(this.listaProduzione, (index, item) => {
                var df = this.formatDate(item.OnlyData);
                if (date.indexOf(df) < 0) date.push(df);
            });
            $.each(this.listaProduzione, (index, item) => {
                var df = this.formatDate(item.OnlyData);
                var exists = false;
                $.each(series, (idc, c) => {
                    if (c.id === item.IdComplessita.toString()) {
                        exists = true;
                        var idData = date.indexOf(df);
                        c.data[idData] += 1;
                        return;
                    }
                });
                if (!exists) {
                    var data = new Array<number>();
                    $.each(date, (idd, d) => {
                        data.push(0);
                    });
                    var d = new ViewModels.HighCharts.Serie(item.IdComplessita.toString(), data);
                    var idData = date.indexOf(df);
                    d.data[idData] += 1;
                    d.id = item.IdComplessita.toString();
                    d.name = this.localized(item, "Complessita_");
                    series.push(d);
                }
            });

            chartOptions.xAxis.categories = date;
            chartOptions.series = series;
            $('#sintesi-andamento-giorno').highcharts(chartOptions);
        }

        public updatePivot(pivotData: Array<any>, pivotDataVal: Array<any>): void {
            var domObj: any = $('#sintesi-risultati');
            var rows = new Array<string>();
            var cols = new Array<string>();
            var dataDerived = function (record) {
                return moment(record.Data).format("DD/MM/YYYY");
            }

            cols.push("Complessita");
            rows.push("Data Intervento");

            domObj.pivotUI(
                pivotData,
                {
                    rows: rows,
                    cols: cols,
                    derivedAttributes: { "Data Intervento": dataDerived },
                    hiddenAttributes: ["Data"]
                });

            var tpl: any = $.pivotUtilities.aggregatorTemplates;
            domObj = $('#sintesi-risultati-valore');
            domObj.pivotUI(
                pivotDataVal,
                {
                    rows: rows,
                    cols: cols,
                    derivedAttributes: { "Data Intervento": dataDerived },
                    hiddenAttributes: ["Data"],
                    aggregators: {
                        'Lek value': function () { return tpl.sum()(["Costo Intervento"]); },
                    }
                });
        }

        public updateSintesi(): void {
            var listaModelli = new Array<AnalisiSimpleEntity>();
            var listaTnt = new Array<Helpers.SimpleEntity>();
            var columnOptions = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: 'Utilizzo del T.N.T. sterile'
                },
                xAxis: {
                    type: 'category',
                    labels: {
                        rotation: -45,
                        style: {
                            fontSize: '9px',
                            fontFamily: 'Verdana, sans-serif'
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Quantità utilizzata'
                    }
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    pointFormat: '<b>{point.y}</b>'
                },
                series: []
            };
            var pieOptions = {
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                    type: 'pie'
                },
                title: {
                    text: ''
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y})</b>'
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        dataLabels: {
                            enabled: false
                        },
                        showInLegend: true
                    }
                },
                series: []
            };
            var serieProtocolli = new ViewModels.HighCharts.Serie("Protocolli di intervento", Array<ViewModels.HighCharts.SeriesData>());  
            //var serieSet = new ViewModels.HighCharts.Serie("Set", Array<ViewModels.HighCharts.SeriesData>());
            var serieOspedale = new ViewModels.HighCharts.Serie("Interventi per ospedale", Array<ViewModels.HighCharts.SeriesData>());
            var serieTnt = new ViewModels.HighCharts.Serie("T.N.T.", Array<ViewModels.HighCharts.SeriesData>()); 
            var serieComplessita = new ViewModels.HighCharts.Serie('Complessità', new Array<ViewModels.HighCharts.SeriesData>());
            var serieCc = new ViewModels.HighCharts.Serie('Centro di Costo', new Array<ViewModels.HighCharts.SeriesData>());
            var pivotData = new Array<any>();
            var pivotDataVal = new Array<any>();
            var complexExists = false;
            var protocolloExists = false;
            var setExists = false;
            var ccExists = false;
            var ospedaleExists = false;

            $.each(this.lista, (index, item) => {
                // Protocolli
                protocolloExists = false;
                $.each(serieProtocolli.data, (idp, p) => {
                    if (p.id === item.Protocollo) {
                        protocolloExists = true;
                        p.y += 1;
                        return;
                    }
                });
                if (!protocolloExists) {
                    var d = new ViewModels.HighCharts.SeriesData();
                    d.id = item.Protocollo;
                    d.name = item.Protocollo;
                    d.y = 1;
                    serieProtocolli.data.push(d);
                }

                // Complessità dell'intervento
                complexExists = false;
                $.each(serieComplessita.data, (idc, c) => {
                    if (item.IdComplessita == 5) 
                        complexExists = true;
                    else if (c.id === item.IdComplessita.toString()) {
                        complexExists = true;
                        c.y += item.Complessita_it_IT.substr(0, 3) === '---' ? item.ListaLotti.length : 1;
                        return;
                    }
                });
                if (!complexExists) {
                    console.log("Complessità", item.IdComplessita.toString());
                    var d = new ViewModels.HighCharts.SeriesData();
                    d.id = item.IdComplessita.toString();
                    d.name = this.localized(item, "Complessita_"); // item.Complessita_it_IT;
                    d.y = item.Complessita_it_IT.substr(0, 3) === '---' ? item.ListaLotti.length : 1;
                    serieComplessita.data.push(d);
                }

                // Centro di costo
                ccExists = false;
                $.each(serieCc.data, (idc, c) => {
                    if (c.id === item.IdCentroCosto.toString()) {
                        ccExists = true;
                        c.y += 1;
                        return;
                    }
                });
                if (!ccExists) {
                    var d = new ViewModels.HighCharts.SeriesData();
                    d.id = item.IdCentroCosto.toString();
                    d.name = this.localized(item, "CentroCosto_");// item.CentroCosto_it_IT;
                    d.y = 1;
                    serieCc.data.push(d);
                }

                // Ospedale
                ospedaleExists = false;
                $.each(serieOspedale.data, (idc, c) => {
                    if (c.id === item.IdSottogruppo.toString()) {
                        ospedaleExists = true;
                        // Considero solo gli interventi
                        if (item.IdTipoIntervento != 0) c.y += 1;
                        return;
                    }
                });
                if (!ospedaleExists) {
                    var d = new ViewModels.HighCharts.SeriesData();
                    d.id = item.IdSottogruppo.toString();
                    d.name = this.localized(item, "Sottogruppo_");
                    d.y = 1;
                    serieOspedale.data.push(d);
                }

                if (this.tipo === 'consumabili')
                    pivotData.push({
                        "Data": item.Data,
                        "Centro di costo": this.localized(item, "CentroCosto_"),//item.CentroCosto_it_IT,
                        "Set": item.Set_it_IT,
                        "Tipo intervento": item.TipoIntervento_it_IT,
                        "Complessita": item.Complessita_it_IT,
                        "Costo Intervento": item.CostoIntervento,
                        "Protocollo": item.Protocollo
                    });
                else if (this.tipo === 'interventi') {
                    if (item.Complessita_it_IT.indexOf('---') < 0) {
                        pivotData.push({
                            "Data": item.Data,
                            "Centro di costo": this.localized(item, "CentroCosto_"),//item.CentroCosto_it_IT,
                            "Set": item.Set_it_IT,
                            "Tipo intervento": item.TipoIntervento_it_IT,
                            "Complessita": item.Complessita_it_IT,
                            "Costo Intervento": item.CostoIntervento
                        });
                    }
                    pivotDataVal.push({
                        "Data": item.Data,
                        "Centro di costo": this.localized(item, "CentroCosto_"),//item.CentroCosto_it_IT,
                        "Set": item.Set_it_IT,
                        "Tipo intervento": item.TipoIntervento_it_IT,
                        "Complessita": item.Complessita_it_IT,
                        "Costo Intervento": item.CostoIntervento
                    });
                    
                }

                if (item.ListaModelliSet != undefined) {
                    $.each(item.ListaModelliSet, (idms, ms) => {
                        var insert = true;
                        $.each(listaModelli, (idm, m) => {
                            if (m.Codice.substr(0, 11) === ms.Codice.substr(0, 11)) {
                                insert = false;
                                if (ms.Codice.slice(-1) == '1')
                                    m.FullObject += ms.Qta;

                                return;
                            }
                        });

                        if (insert) {
                            listaModelli.push(<AnalisiSimpleEntity>({
                                Descrizione: ms.Descrizione,
                                Id: ms.Id,
                                Codice: ms.Codice,
                                FullObject: ms.Qta
                            }));
                        }
                    });
                }

                if (item.ListaTnt != undefined) {
                    $.each(item.ListaTnt, (idtt, tt) => {
                        var insert = true;
                        $.each(listaTnt, (idt, t) => {
                            if (t.Id === tt.Id) {
                                insert = false;
                                t.FullObject += tt.Qta;
                                return;
                            }
                        });

                        if (insert) {
                            listaTnt.push(<Helpers.SimpleEntity>({
                                Descrizione: tt.Codice + " - " + tt.Descrizione,
                                Id: tt.Id,
                                FullObject: tt.Qta
                            }));
                        }
                    });
                }
            });

            //$.each(listaModelli, (idm, item) => {
            //    var d = new ViewModels.HighCharts.SeriesData();
            //    d.id = item.Id.toString();
            //    d.name = item.Descrizione;
            //    d.y = item.FullObject;
            //    serieSet.data.push(d);
            //});

            listaTnt.sort(SortByFullObject).reverse();
            $.each(listaTnt, (idm, item) => {
                var d = new ViewModels.HighCharts.SeriesData();
                d.id = item.Id.toString();
                d.name = item.Descrizione;
                d.y = item.FullObject;
                serieTnt.data.push(d);
            });

            //var setOptions = angular.copy(pieOptions);
            //setOptions.series.push(serieSet);
            var complexOptions = angular.copy(pieOptions);
            complexOptions.series.push(serieComplessita);
            var ccOptions = angular.copy(pieOptions);
            ccOptions.series.push(serieCc);
            var ospedaleOptions = angular.copy(pieOptions);
            ospedaleOptions.series.push(serieOspedale);
            var protOptions = angular.copy(pieOptions);
            protOptions.series.push(serieProtocolli);
            var tntOptions = angular.copy(columnOptions);
            serieTnt.dataLabels = {
                enabled: true,
                rotation: -90,
                color: '#000000',
                align: 'right',
                format: '{point.y}',
                y: -30, // 10 pixels down from the top
                style: {
                    fontSize: '12px',
                    fontFamily: 'Verdana, sans-serif'
                }
            };
            tntOptions.series.push(serieTnt);

            //$('#sintesi-set').highcharts(setOptions);
            $('#sintesi-centro-costo').highcharts(ccOptions);
            $('#sintesi-ospedale').highcharts(ospedaleOptions);
            $('#sintesi-complessita').highcharts(complexOptions);
            $('#sintesi-protocolli').highcharts(protOptions);
            $('#sintesi-tnt').highcharts(tntOptions);

            this.updatePivot(pivotData, pivotDataVal);
        }

        private apriModalTipoIntervento(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Analisi/modalTipiInterventi.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.ricercaDescrizioneTipoIntervento = "";
            this.arrayRicerca = angular.copy(this.tipiInterventi);
            this.$modalInstance = this.$uibModal.open(options);
        }

        public selezionaTipiInterventi(): void {
            this.filtro.TipiIntervento = new Array<number>();
            $.each($("input.checkTi:checked"), (index, item) => {
                this.filtro.TipiIntervento.push(parseInt($(item).val()));
            });
            this.chiudiModal();
        }

        private apriModalSet(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Analisi/modalSet.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.ricercaDescrizioneSet = "";
            this.arrayRicerca = angular.copy(this.modelliSet);
            this.$modalInstance = this.$uibModal.open(options);
        }

        public selezionaSet(): void {
            this.filtro.ListaSet = new Array<number>();
            $.each($("input.checkSet:checked"), (index, item) => {
                this.filtro.ListaSet.push(parseInt($(item).val()));
            });
            this.chiudiModal();
        }

        private apriModalTnt(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Analisi/modalTnt.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.ricercaDescrizioneTnt = "";
            this.arrayRicerca = angular.copy(this.listaTnt);
            this.$modalInstance = this.$uibModal.open(options);
        }

        public selezionaTnt(): void {
            this.filtro.ListaTnt = new Array<number>();
            $.each($("input.checkTnt:checked"), (index, item) => {
                this.filtro.ListaTnt.push(parseInt($(item).val()));
            });
            this.chiudiModal();
        }

        public getExportUrl(tipo: string): string {
            var result: string;
            switch (tipo) {
                case 'Valorizzazione':
                    result = appRoot + "Reports/ExportValorizzazioneSet?strFiltro=" + JSON.stringify(this.filtro);
                    break;

                default:
                    result = appRoot + "Reports/ExportAnalisi" + tipo + "?strFiltro=" + JSON.stringify(this.filtro);
                    break;
            }
            return result;
        }

        public chiudiModal(): void {
            this.$modalInstance.close();
            this.$modalInstance.dismiss('cancel');
        }

        public formatDate(data: Date, format: string = "DD/MM/YYYY"): string {
            if (data == null) data = new Date();
            var result = moment(data).format(format);
            return result;
        }

        public conta(prop: string, val: any): number {
            if (this.lista == undefined) return 0;
            var arr = $.grep(this.lista, (item, index) => {
                return (item[prop] === val);
            });
            return arr.length;
        }

        public somma(lista: any[], prop: string): number {
            var result = 0;
            $.each(lista, (idl, l) => {
                result += parseInt(l[prop]);
            });
            return result;
        }

        public dettagliProduzione(idLotto: number): void {
            this.analisiFactory.DettagliProduzione(idLotto).then(
                result => {
                    this.dettagliLotto = result;
                    this.showDettagliLotto();
                }
            );
        }

        public localized(item: any, campo: string): string {
            if (item == undefined) return "";
            var result = item[campo + dbCulture];
            if (result == undefined)
                result = item[campo + culture.capitalize()];

            //console.log("analisiConstroller_localized() item ", item);
            //console.log("analisiConstroller_localized() campo ", campo);
            //console.log("analisiConstroller_localized() culture ", culture);

            //console.log("analisiConstroller_localized()", result);

            return result;
        }

        public showDettagliLotto(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Analisi/modalDettagliLotto.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public formatSeconds(s: number): string {
            return moment().startOf('day')
                .seconds(s)
                .format('H:mm:ss');
        }

        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

        public exportPdfInterventi(mostraCosti: boolean): void {
            this.isLoading = true;
            var immaginiGrafici = new Array<Helpers.SimpleEntity>();
            var grafici = $(".highcharts-container");
            $.each(grafici, (index, item) => {
                var b64str = this.svgToImage("#" + $(item).attr("id") + " svg");
                immaginiGrafici.push(<Helpers.SimpleEntity>{
                    Id: index,
                    Descrizione: "",
                    FullObject: b64str
                });
            });
            var obj = <Analisi.ExportPdfVm>{
                Immagini: immaginiGrafici,
                Filtro: this.filtro,
                FiltroProdMensile: undefined
            };
            this.analisiFactory.ExportPdfInterventi(obj, mostraCosti).then(
                result => {
                    var url = appRoot + "rpts/ANALISIINTERVENTI/" + result;
                    window.open(url, "_blank");
                    this.isLoading = false;
                },
                reason => {
                    this.isLoading = false;
                }
            );
        }

        public svgToImage(svgSelector: string): string {
            var svg = $(svgSelector).get(0);
            canvg(document.getElementById('drawingArea'), svg.outerHTML);
            var data = document.getElementById('drawingArea').toDataURL("image/png");
            return data;
        }

        public numInterventi(g: Analisi.AnalisiResultVm): string {
            var lista = $.grep(g.lista, (elem) => {
                return elem.Complessita_it_IT.substring(0, 3) != '---';
            });
            return lista.length.toString();
        }

        public numSfusi(g: Analisi.AnalisiResultVm): string {
            var qta = 0; 
            $.each(g.lista, (index, elem) => {
                if (elem.Complessita_it_IT.substring(0, 3) == '---')
                    qta += elem.ListaLotti.length;
            });
            return qta.toString();
        }

        public changedTipoSet(item: AnalisiItem, lista: AnalisiItem[], index: number): boolean {
            if (index == 0) return false;
            var prec = lista[index - 1];
            return item.IdTipoIntervento == 0 && prec.IdTipoIntervento != 0;
        }
    }
}
app.controller("analisiController", Trace.Analisi.analisiController);