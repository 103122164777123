namespace Trace.Set {
    export class SetDetailVm {
        public Id: number;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public Qta: number;
        public Matricola: string;
        public Griglia: number;
        public Spillone: number;
        public Posizione: number;
        public IdProdotto: number;
        public IdSet: number;
        public IdProprietario: number;
        public HasDistinta: boolean;
        public Precauzioni: string;
        public Note: string;
        public Ordinamento: number;
        public FlgMarcatura: boolean;
        public Prezzo: number;
        public Immagini: ViewModels.ImmagineVm[];
        public QtaGiacenzaDoc: number;
        public QtaGiacenzaDep: number;
        public QtaPosizionabileDoc: number;
        public QtaPosizionabileDep: number;
        public PrcPosizionabile: number;
        public RowClass: string;
        public IdMarca: number;
        public Marca: string;
        public IdFornitore: number;
        public Fornitore: string;
        public Scorta: number;
    }
}