module Trace.GestioneRiparazioni { 
    export class gestioneRiparazioniController extends epBaseController {
        public currentState: string;
        public titoloManipola: string;
        public flgShowCentrali: boolean;
        public centrali: Centrale.Centrale[];
        public listaTnt: Tnt.tntVm[];
        public listaRot: Rot.Rot[];
        public listaSet: Set.SetVm[];
        public listaProdotti: Prodotto.ProdottoVm[];
        public mostraDistinta: boolean;

        public showValidationMessages: boolean;
        public formDeposito: any;
        public tipoElemento: string;

        static $inject = ['$scope', 'sottoGruppiFactory', 'gruppiFactory', 'sediFactory', 'centriCostoFactory', 'dtFactory',
            'localeFactory', 'msgFactory', 'depositoFactory', '$uibModal', 'tntFactory', 'modelliSetFactory', 'setFactory',
            'rotFactory', 'prodottiFactory', 'centraliFactory', 'Upload', 'utenteFactory', "tracciabilitaFactory"];
        constructor(private $scope: ng.IScope, private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private gruppiFactory: Gruppo.gruppiFactory, private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory, private dtFactory: datatables.dtFactory,
            public localeFactory: Localization.localeFactory, public msgFactory: Messages.msgFactory,
            private depositoFactory: Deposito.depositoFactory, public $uibModal: ng.ui.bootstrap.IModalService,
            public tntFactory: Tnt.tntFactory, private modelliSetFactory: ModelliSet.modelliSetFactory,
            public setFactory: Set.setFactory, private rotFactory: Rot.rotFactory, 
            private prodFactory: Prodotto.prodottiFactory, private centraliFactory: Centrale.centraliFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory) {
            super(Upload, null, setFactory, prodFactory, tntFactory, localeFactory, msgFactory, $uibModal, null, tracciabilitaFactory);
            this.formDeposito = {};
            var vm = this;
        }
    }
}

app.controller("gestioneRiparazioniController", Trace.GestioneRiparazioni.gestioneRiparazioniController);