app.factory("fornitoriFactory", ($http, $location, $q) => new Trace.Fornitori.fornitoriFactory($http, $location, $q));

module Trace.Fornitori {
    //export class FornitoreVm implements Trace.Fornitori.Fornitore {
    //    public Id: number;
    //    Codice: string;
    //    DataCreazione: Date;
    //    Descrizione_en_US: string;
    //    Descrizione_it_IT: string;
    //    Descrizione_sq_AL: string;
    //    IdDeposito: number;
    //    IndirizzoRiga1: string;
    //    IndirizzoRiga2: string;
    //    IndirizzoRiga3: string;
    //    Logo: string;
    //    PartitaIva: string;
    //    RagioneSociale: string;
    //}

    export class fornitoriFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Fornitori/";
        }

        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public AsSimpleList(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "AsSimpleList";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public lista(): ng.IPromise<FornitoreVm[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: FornitoreVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public getFornitoriMarche(idMarca?: number): ng.IPromise<FornitoriMarcheVm[]> {
            var workUrl = this.GetBaseUrl() + "GetFornitoriMarche";
            if (idMarca != undefined) workUrl += "/" + idMarca;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: FornitoriMarcheVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idFornitore: number): ng.IPromise<FornitoreVm> {
            var workUrl = this.GetBaseUrl() + idFornitore;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: FornitoreVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public marcheDropDown(): ng.IPromise<Trace.Helpers.SimpleDropdown[]> {
            var workUrl = this.GetBaseUrl() + "GetMarcheSelezionabili";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Trace.Helpers.SimpleDropdown[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(fornitore: Trace.Fornitori.FornitoreVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, fornitore).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(fornitore: Trace.Fornitori.FornitoreVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + fornitore.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, fornitore).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public cancella(idFornitore: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idFornitore;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Fornitori.FornitoriPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'Ricerca/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: FornitoreVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

    }
}