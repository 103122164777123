
app.factory("gestioneRiparazioniFactory", ($http, $location, $q) => new Trace.GestioneRiparazioni.gestioneRiparazioniFactory($http, $location, $q));

module Trace.GestioneRiparazioni {
    export class gestioneRiparazioniFactory {
        static $inject = ["$http", "$location", "$q"];

        public GetBaseUrl(): string {
            return appRoot + "api/GestioneRiparazioni/";
        }

        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        //    public lista(): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl();
        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: DepositoVm[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public listaValorizzazione(): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + "ListaValorizzazione";
        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: DepositoVm[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public listaGiaceza(): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + "ListaGiacenza";
        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: DepositoVm[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public listaByTipo(idTipi: number[]): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + "GetByTipo";
        //        var deferred = this.$q.defer();
        //        this.$http.post(workUrl, idTipi).then(
        //            result => {
        //                deferred.resolve(result.data);
        //            },
        //            reason => {
        //                deferred.reject(reason);
        //            }
        //        );
        //        return deferred.promise;
        //    }

        //    public listaUtente(idUtente: number): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + "Utente/" + idUtente.toString();

        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: DepositoVm[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public listaCompleta(tipoDepFlgShow: boolean = null): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + "ListaCompleta" + (tipoDepFlgShow != null ? "/" + tipoDepFlgShow : "");

        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: DepositoVm[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public inserisci(centroCosto: DepositoVm): ng.IPromise<boolean> {
        //        var workUrl = this.GetBaseUrl();

        //        var deferred = this.$q.defer();
        //        this.$http.post(workUrl, centroCosto).success((data: boolean, status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;

        //    }

        //    public modifica(deposito: DepositoVm): ng.IPromise<boolean> {
        //        var workUrl = this.GetBaseUrl() + deposito.Id;

        //        var deferred = this.$q.defer();
        //        this.$http.put(workUrl, deposito).success((data: boolean, status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;

        //    }

        //    public cancella(idDeposito: number): ng.IPromise<boolean> {
        //        var workUrl = this.GetBaseUrl() + idDeposito;

        //        var deferred = this.$q.defer();
        //        this.$http.delete(workUrl).success((data: boolean, status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public patch(idCentroCosto: number, daPatchare: {}): ng.IPromise<boolean> {
        //        var workUrl = this.GetBaseUrl() + idCentroCosto;

        //        var deferred = this.$q.defer();
        //        this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;

        //    }

        //    public listaSede(idSede: number): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + "ListaSede/" + idSede;

        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: DepositoVm[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public leggi(idDeposito: number): ng.IPromise<DepositoVm> {
        //        var workUrl = this.GetBaseUrl() + idDeposito;

        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: DepositoVm, status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;

        //    }

        //    public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + 'Ricerca';

        //        var deferred = this.$q.defer();
        //        this.$http.post(workUrl, filtro).success((data: DepositoVm[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public tipiDeposito(): ng.IPromise<Deposito.TipoDepositoVm[]>{
        //        var workUrl = this.GetBaseUrl() + "TipiDeposito";

        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });

        //        return deferred.promise;
        //    }

        //    public getGiacenze(idDeposito: number): ng.IPromise<Produzione.Materiale[]> {
        //        var workUrl = this.GetBaseUrl() + "Giacenze/" + idDeposito.toString();
        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).then(
        //            result => {
        //                deferred.resolve(result.data);
        //            },
        //            reason => {
        //                deferred.reject(reason);
        //            }
        //        );
        //        return deferred.promise;
        //    }

        //    // Lista delle situazioni dei depositi
        //    public SdList(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<Deposito.DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + "RicercaSituazioneDepositi";
        //        var deferred = this.$q.defer();
        //        this.$http.post(workUrl, filtro).then(
        //            result => {
        //                deferred.resolve(result.data);
        //            },
        //            reason => {
        //                deferred.reject(reason);
        //            }
        //        );
        //        return deferred.promise;
        //    }

        //    public Situazione(id: number, filtro: Trace.ViewModels.RicercaVm): ng.IPromise<DepositoVm> {
        //        var workUrl = this.GetBaseUrl() + "Situazione/" + id.toString();
        //        var deferred = this.$q.defer();
        //        this.$http.post(workUrl, filtro).then(
        //            result => {
        //                deferred.resolve(result.data);
        //            },
        //            reason => {
        //                deferred.reject(reason);
        //            }
        //        );
        //        return deferred.promise;
        //    }

        //    public listaCentroCosto(idSede: number): ng.IPromise<DepositoVm[]> {
        //        var workUrl = this.GetBaseUrl() + "ListaCentroCosto/" + idSede;
        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).success((data: DepositoVm[], status) => {
        //            deferred.resolve(data);
        //        }).error(error => {
        //            deferred.reject(error);
        //        });
        //        return deferred.promise;
        //    }

        //    public ReadExcel(idDeposito: number, item: ViewModels.ReadExcelItem): ng.IPromise<string> {
        //        var workUrl = this.GetBaseUrl() + "ReadExcel/" + idDeposito.toString();
        //        var deferred = this.$q.defer();
        //        this.$http.post(workUrl, item).then(
        //            result => {
        //                deferred.resolve(result.data);
        //            },
        //            reason => {
        //                deferred.reject(reason);
        //            }
        //        );
        //        return deferred.promise;
        //    }

        //    public GetMacchine(): ng.IPromise<Helpers.SimpleEntity[]> {
        //        var workUrl = this.GetBaseUrl() + "GetMacchine";
        //        var deferred = this.$q.defer();
        //        this.$http.get(workUrl).then(
        //            result => {
        //                deferred.resolve(result.data);
        //            },
        //            reason => {
        //                deferred.reject(reason);
        //            }
        //        );
        //        return deferred.promise;
        //    }

        //    public SituazioneAllaData(data: Date, idDeposito: number): ng.IPromise<string> {
        //        var workUrl = this.GetBaseUrl() + "SituazioneAllaData/" + ((idDeposito == undefined) ? "" : idDeposito.toString());
        //        var deferred = this.$q.defer();
        //        this.$http.post(workUrl, data).then(
        //            result => {
        //                deferred.resolve(result.data);
        //            },
        //            reason => {
        //                deferred.reject(reason);
        //            }
        //        );
        //        return deferred.promise;
        //    }
        //}
    }
}