module Trace.ModelliSet {
    export class Produzione implements ProduzioneVm {
        constructor(public NumCopia: number, public BaseCopy: number, public TotCopie: number, public Strumenti: Array<Set.SetDetailVm>, public giacenze: Array<GiacenzaProdottoModelloSetVm>) {
            this.filteredStrumenti = Strumenti;
            this.RealCopy = NumCopia + BaseCopy;
            $.each(this.Strumenti, (index, item) => {
                this.setUpData(index);
            });
        }

        public setUpData(idStrumento: number, qDoc: number = undefined, qDep: number = undefined, giacenze: Array<GiacenzaProdottoModelloSetVm> = undefined): void {
            if (giacenze != undefined) this.giacenze = angular.copy(giacenze);
            if (qDep == 0) qtaDep = undefined;
            if (qDoc == 0) qtaDoc = undefined;
            var item = this.Strumenti[idStrumento];
            if (item == undefined) return;
            var arrG = $.grep(this.giacenze, (g, idg) => {
                return g.IdProdotto === item.IdProdotto;
            });

            if (arrG == undefined || arrG.length <= 0) {
                item.QtaGiacenzaDoc = 0;
                item.QtaGiacenzaDep = 0;
                console.log(this.NumCopia, "non ho trovato la giacenza per il prodotto");
            }
            else {
                if (qDep != undefined)
                    arrG[0].QtaDeposito = qDep;
                if (qDoc != undefined)
                    arrG[0].QtaDocumento = qDoc;

                var qtaDep = arrG[0].QtaDeposito < 0 ? 0 : arrG[0].QtaDeposito;
                var qtaDoc = arrG[0].QtaDocumento;
                var multiplier = (this.NumCopia - 1);

                console.log(this.NumCopia, "quantità documento", qtaDoc, "quantità strumento", item.Qta, multiplier);

                if ((item.Qta * multiplier) <= qtaDoc) {
                    item.QtaGiacenzaDep = qtaDep < 0 ? 0 : qtaDep;
                    item.QtaGiacenzaDoc = qtaDoc - (item.Qta * multiplier);
                }
                else {
                    item.QtaGiacenzaDep = qtaDep - Math.abs(qtaDoc - (item.Qta * multiplier));
                    item.QtaGiacenzaDoc = 0;
                }
            }

            var classe = (item.QtaGiacenzaDoc + item.QtaGiacenzaDep <= 0)
                ? ""
                : ((item.QtaGiacenzaDoc + item.QtaGiacenzaDep) < item.Qta)
                    ? "warning"
                    : "success";
            item.RowClass = classe;

            if (item.QtaGiacenzaDoc > 0) {
                if (item.QtaGiacenzaDoc >= item.Qta) {
                    item.QtaPosizionabileDoc = item.Qta;
                    item.QtaPosizionabileDep = 0;
                }
                else {
                    var residuo = item.Qta - item.QtaGiacenzaDoc;
                    item.QtaPosizionabileDoc = item.QtaGiacenzaDoc;
                    if (item.QtaGiacenzaDep > 0)
                        item.QtaPosizionabileDep = (item.QtaGiacenzaDep >= residuo) ? residuo : item.QtaGiacenzaDep;
                    else
                        item.QtaPosizionabileDep = 0;
                }
            }
            else {
                var t = item.QtaGiacenzaDep >= Math.abs(item.QtaGiacenzaDoc - item.Qta) ? Math.abs(item.QtaGiacenzaDoc - item.Qta) : item.QtaGiacenzaDep;
                item.QtaPosizionabileDep = t >= 0 ? t : 0;
                item.QtaPosizionabileDoc = 0;
            }

            item.PrcPosizionabile = (item.QtaPosizionabileDoc + item.QtaPosizionabileDep) * 100 / item.Qta;
        }

        public filteredStrumenti: Array<Set.SetDetailVm>;
        public IdGruppo: number;
        public IdSottogruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public RealCopy: number;
    }
}