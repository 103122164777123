module Trace.GestioneManutenzioni {
    export class RicercaGestioneManutenzioniVm {
        public Id: number;
        public DescrizioneAl: string;
        public DescrizioneEn: string;
        public DescrizioneIt: string;
        public Codice: string;
        public Uid: string;
        public CodiceStato: string;
        public Matricola: string;
        public Utente: string;
        public IdStato: number;
        public DataFermo: Date;
    }

    export class RicercaGestioneManutenzioniTransitPointVm {
        public Id: number;
        public DescrizioneAl: string;
        public DescrizioneEn: string;
        public DescrizioneIt: string;
        public idProvenienza: string;
        public NumMateriali: string;
        public DataCreazione: Date;
    }
}