
app.factory("gestioneManutenzioniRientriCentraleFactory", ($http, $q) => new Trace.GestioneManutenzioniRientriCentrale.gestioneManutenzioniRientriCentraleFactory($http, $q));
module Trace.GestioneManutenzioniRientriCentrale {
    export class gestioneManutenzioniRientriCentraleFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/GestioneManutenzioni/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, currentPage: number = undefined): ng.IPromise<ViewModels.GenericPaginationVm<Set.StrumentoVm>> {
            var workUrl = this.GetBaseUrl() + "RicercaRientriCentrale/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
        
        public rientroManutenzione(listaRientroCentrale: Set.StrumentoVm[]): ng.IPromise<ViewModels.GenericPaginationVm<Set.StrumentoVm>> {
            var workUrl = this.GetBaseUrl() + "RientroCentrale";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, listaRientroCentrale).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}