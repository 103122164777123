module Trace.Prodotto {
    export class ProdottoVm {
        public Id: number;
        public DataCreazione: Date;
        public IdUtenteCreazione: number;
        public Codice: string;
        public Descrizione_it_IT: string;
        public Descrizione_en_US: string;
        public Descrizione_sq_AL: string;
        public IdTipo: number;
        public Tipo: string;
        public DataObsoleto: Date;
        public IdUtenteObsoleto: number;
        public DataUltimaModifica: Date;
        public IdUtenteUltimaModifica: number;
        public Immagini: Array<Trace.ViewModels.ImmagineVm>;
        public Distinta: Array<DistintaVm>;
        public Precauzioni: string;
        public Note: string;
        public FlgMarcatura: boolean;
        public CostoStandard: number;
        public IdMarca: number;
        public IdFornitore: number;
        public Marca: string;
        public Fornitore: string;
        public Scorta: number;
        public IdUnitaMisura: number;
        public IdRaggruppamento: number;
        public DataInizioValiditaCodiceAlternativo: Date;
        public FlgCensito: boolean;
        public FlgMatricolaObbligatoria: boolean;
    }
}