
module Trace.Inventario { 
    export class RicercaInventario {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public IdTipoMateriale: number;
        public FlgElaborato: boolean;
        public IdDeposito: number;
    }

    export class inventarioController extends epBaseController {
        public lista: Inventario[];
        public recordCorrente: any;
        public testoAzione: string;
        public ricerca: RicercaInventario;
        public currentPage: number;

        public saved: boolean;
        public isSaving: boolean;

        public depositi: Trace.Deposito.DepositoVm[];

        //Modal nuovo inventario
        public idDeposito: number;
        public codTipoMateriale: string;
        public uploadInCorso: boolean;
        public file: ViewModels.ReadExcelInventario;
        public risultatoCheckExcel: any;

        public currentState: string;
        public titoloManipola: string;

        public showValidationMessages: boolean;
        

        static $inject = ["$scope", "inventarioFactory","depositoFactory", "dtFactory", "localeFactory", "msgFactory", "Upload", "$uibModal", "utenteFactory"];

        constructor(
            private $scope: ng.IScope,
            public inventarioFactory: inventarioFactory,
            private depositoFactory: Deposito.depositoFactory,
            private dtFactory: Trace.datatables.dtFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory) {
            super(Upload, utenteFactory, null, null, undefined, localeFactory, msgFactory, $uibModal, null, null);
            var vm = this;

            // sostituire con la lista dei depositi, e la lista dei tipi materiali anche se poi sara solo il tipo tnt  o prodotto
            vm.getListaDepositi();

            $(document).on("fileUpload", (e: JQueryEventObject) => {
                console.log("FileUpload event", e);
                var file = new ViewModels.ReadExcelInventario();
                file.FileName = e.nomeFileUpload;
                file.OriginalName = e.lettura;
                file.Subfolder = e.tipoUpload;
                file.IdDeposito = vm.idDeposito;
                file.CodTipoMateriale = vm.codTipoMateriale;
                vm.inventarioFactory.CheckExcel(file).then(
                    result => {
                        vm.uploadInCorso = false;
                        vm.file = file;
                        vm.risultatoCheckExcel = result;
                    },
                    reason => {
                        vm.uploadInCorso = false;
                        vm.file = undefined;
                        vm.risultatoCheckExcel = undefined;
                        vm.msgFactory.error(reason);
                    }
                );
            });

            //this.changeState('list');
        }
        public confermaNuovoInventario() {
            this.inventarioFactory.ReadExcel(this.file).then(
                result => {
                    this.chiudiModal();
                    this.msgFactory.alert(this.localeFactory.get('importazione_ok'));
                    this.dtFactory.aggiornaRicerca();
                },
                error => {
                    this.msgFactory.error(error.toString());
                });
        }
        public getDescrizioneDeposito(id: number): string {
            if (id == undefined) return '';
           return  this.depositi.filter(h => h.Id == id)[0].Descrizione_it;
        }
        public getListaDepositi(): void {
            this.depositoFactory.listaCompleta().then(
                result => {
                    this.depositi = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }
        public gestioneNuovoInventario(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Inventario/modalNuovoInventario.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xl"
            };
            this.file = undefined;
            this.risultatoCheckExcel = undefined;
            this.idDeposito = undefined;
            this.codTipoMateriale = "PRD";
            this.uploadInCorso = false;
            this.$modalInstance = this.$uibModal.open(options);
        }
        public chiudiModal(): void {
            this.$modalInstance.close();
            this.$modalInstance.dismiss('cancel');
        }
        
        public cancella(idInventario: number): void {
            if (this.isInEdit()) idInventario = this.recordCorrente.Id;
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.inventarioFactory.cancella(idInventario).then((result => {
                        this.changeState('list');
                    })).catch((reason: string) => {
                        this.msgFactory.error(reason.toString());
                    });
                }
            });
        }

        public confermaRettifica(): void {
            this.msgFactory.confirm(this.localeFactory.get('confirm_conferma_rettifica')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.inventarioFactory.generaMovimentiRettifica(this.recordCorrente.Id).then(result => {
                        this.msgFactory.alert(this.localeFactory.get('rettifica_ok'));
                        this.changeState('list');
                    }, error => {
                        this.msgFactory.error(error.toString());
                    });
                }
            });
        }

        public validaQta(n: any): boolean {
            const parsed = parseInt(n, 10);
            if (isNaN(parsed)) {
                return false;
            }
            else return true;
        }
        
        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.inventarioFactory.ricerca(ricerca, page).then((risultato: InventarioPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.lista = risultato.List;
            });
        }
        

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.saved = true;
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaInventario();
        }

        public leggi(item: any): void {
            this.inventarioFactory.leggi(item.Id).then((result) => {
                this.recordCorrente = result;
                this.changeState('manipola');
            });
        }
        public annullaRettificaDetail(item: any): void {
            item.QtaRettificaDefinitiva = 0;
            this.saved = false;
        }
        public validaDettagli() {
            if (this.recordCorrente == null) return true;
            let valido = true;
            this.recordCorrente.Dettagli.forEach(element => {
                const parsed = parseInt(element, 10);
                if (isNaN(parsed)) {
                    valido = false;
                    return;
                }
            });
            return valido;
        }
        public registra(): void {
            this.showValidationMessages = false;
            if (!this.validaDettagli) {                
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            this.isSaving = true;
            this.inventarioFactory.salva(this.recordCorrente).then(
                result => {
                    this.saved = true;
                    this.isSaving = false;
                    this.msgFactory.alert(this.localeFactory.get("salvataggio_ok"));
                },
                error => {
                    this.isSaving = false;
                    this.msgFactory.error(error.toString());
                }
            );
        }

        

        //public aggiungi(): void {
        //    this.titoloManipola = this.localeFactory.get("titolo_nuovo");
        //    this.recordCorrente = new Gruppo();
        //    this.testoAzione = this.localeFactory.get('button_inserisci');
        //    this.changeState('manipola');
        //}
    }
}
app.controller("inventarioController", Trace.Inventario.inventarioController);