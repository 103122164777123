/// <reference path="app.ts" />
/// <reference path="epbasecontroller.ts" />
/// <reference path="epproduzionecontroller.ts" />
/// <reference path="viewmodels/paginationvm.ts" />
/// <reference path="../../org.epartner.trace.core/helpers/simpledropdown.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/cespiti/cespite.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/fornitoricespiti.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/produttoricespiti.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/ubicazionicespiti.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/tracciabilita/filtrotracciabilitamateriale.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/categoriecespiti.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/cespiti/ammcespitivm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/cespiti/ammcespitidetailsvm.cs.d.ts" />


/// <reference path="../../org.epartner.trace.core/helpers/simpleentity.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/analisi/filtroproduttivitavm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/analisi/analisiproduttivitavm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwanalisirot.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwscadenzetnt.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/rot/filtroanalisirot.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/analisi/filtroinevasovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwanalisiinevaso.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/marca.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwscadenzeprodotti.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/fornitore.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwscortestrumentario.cs.d.ts" />

/// <reference path="../../org.epartner.trace.core/models/aree.cs.d.ts" />

/// <reference path="../../org.epartner.trace.core/models/vwmodellicentricosto.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/lottoproduzione_grigliastrumenti_trattamento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/lottoproduzionedetail_trattamento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/lottoproduzione_trattamento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/movimentodetail.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwscaricoconsumabile.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/rigadocumento.cs.d.ts" />
/// <reference path="viewmodels/immaginevm.ts" />
/// <reference path="../../org.epartner.trace.core/models/tipomacchina.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwcomposizioneset.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/rigaordine.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwcontenutobox.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/tracciabilita/filtrotracciabilitatnt.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/boxvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/tracciabilita/querysearchresultvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwstrumentimancanti.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwtracciabilitatnt.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/trattamento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/scadenzaset.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/accettazione.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwvalorizzazione.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/ordini/dettaglioordinematerialevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/tracciabilita/filtrotracciabilitaset.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/valorizzazione/filtrovalorizzazione.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/accettazione/accettazionevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwanalisiintervento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwsmaltimentorot.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/tracciabilita/filtrotracciabilitacomposizioneset.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/tracciabilita/tracciabilitasetvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwrichiestaapprovvigionamento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/stampe/filtroscaricoconsumabili.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwrigarichiestaapprovvigionamento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/tracciabilita/tracciabilitatntvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwdettagliolotto.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwdettaglioordine.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwgiacenzadeposito.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwtipointervento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/stampe/filtrosmistamentostrumentifatture.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/vwdettagliomovimentomateriale.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/movimento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/models/lottoproduzione.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/scadenze/filtroscadenzevm.cs.d.ts" />
/// <reference path="fornitori/fornitorevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/accettazione/filtroaccettazioni.cs.d.ts" />

/// <reference path="../../org.epartner.trace.core/viewmodels/modelliset/giacenzaprodottomodellosetplus.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/startup/istartupdetailvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/accettazione/accettazionematerialevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/analisi/analisidetail.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/macchina.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/contastrumentiitem.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/cambiostatoresult.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/lottoproduzionevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/grigliastrumentivm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/analisi/filtrointerventivm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/fasedetailvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/anomaliastrumento.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/associazionelottosterilizzazioneset.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/caricomacchina.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/datiproduzione.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/infopackaging.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/materiale.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/lottoproduzioneattivovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/ricercamateriali.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/produzione/materialesimple.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/movimenti/querydettagliomovimenti.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/movimenti/movimentovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/intervento/iinterventovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/documento/documentovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/accettazione/iaccettazionelottovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/accettazione/accettazionematerialevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/analisi/exportpdfvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/analisi/analisiitem.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/carrelli/carrellovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/deposito/depositorigasimplevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/deposito/depositovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/deposito/tipodepositovm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/trackingvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/manutenzione/manutenzionedetailsvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/accettazione/accettazionesfuso.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/rot/riepilogorot.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/set/iinfobarcodevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/startup/istartupvm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/utente/utentesimplevm.cs.d.ts" />
/// <reference path="../../org.epartner.trace.core/viewmodels/modelliset/produzionevm.cs.d.ts" />