
module Trace.Components {
    export class startupPanelController {
        public tipo: ViewModels.TipoMacchina;
        public macchine: Startup.StartupDetailVm[];
        static $inject = ["localeFactory"];
        constructor(public localeFactory: Localization.localeFactory) {
            var vm = this;
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 1000);
        }

        public dateChanged(property: string, item: Startup.StartupDetailVm): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

        public itemValueChanged(item: Startup.StartupDetailVm, property: string, $index: number): void {
            // Ogni valore va inserito per ogni macchina (gestire singolarità Madre Teresa)
            return;
            /*
            if ($index > 0 || this.tipo.Codice !== 'TER') return;
            var val = item[property];
            $.each(this.macchine, (idm, m) => {
                if(idm !== $index)
                    m[property] = val;
            });
            if (property.indexOf("Data") === 0)
                this.itemValueChanged(item, "Str" + property, $index);
            */
        }

        public titoloPannello(): string {
            return this.tipo["Plurale_" + dbCulture];

        }

        public nomeMacchina(item: Startup.IStartupDetailVm, prefisso: string): string {
            return prefisso + "_" + this.tipo.Codice + "_" + item.IdMacchina.toString();
        }
    }
}
app.controller("startupPanelController", Trace.Components.startupPanelController);

app.component("startupPanel", {
    templateUrl: "/views/Startup/panel-macchina.html",
    controller: "startupPanelController",
    controllerAs: "vm",
    bindings: {
        tipo: "<",
        macchine: "<"
    }
});

app.component("startupPanelReadonly", {
    templateUrl: "/views/Startup/panel-macchina-readonly.html",
    controller: "startupPanelController",
    controllerAs: "vm",
    bindings: {
        tipo: "<",
        macchine: "<"
    }
});