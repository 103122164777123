app.factory("creazioneddtFactory", ($http, $q) => new Trace.CreazioneDdt.creazioneddtFactory($http, $q));
module Trace.CreazioneDdt {
    export class creazioneddtFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/CreazioneDdt/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) {

        }

        public lista(): ng.IPromise<Movimenti.MovimentoVm[]> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Dettagli(id: number): ng.IPromise<Movimenti.MovimentoVm> {
            var workUrl = this.GetBaseUrl() + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ListaVettori(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "ListaVettori";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Conferma(id: number): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + "Conferma";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, id).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public registra(movimento: Movimenti.MovimentoVm): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, movimento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}