module Trace.Valorizzazione {
    export class valorizzazioneController extends epBaseController {
        static $inject = ["$scope", "Upload", "valorizzazioneFactory", "tracciabilitaFactory", "utenteFactory", "prodottiFactory", "documentoFactory",
            "localeFactory", "msgFactory", "$uibModal", "depositoFactory"];
        private formValorizzazione: any;
        private filtro: FiltroValorizzazione;
        private depositi: Deposito.DepositoVm[];
        private lista: Array<VwValorizzazione>;
        private tipi: Array<Helpers.SimpleEntity>;
        private tipoAnalisi: number;

        constructor(
            private $scope: ng.IScope,
            public upload: ng.angularFileUpload.IUploadService,
            public valorizzazioneFactory: valorizzazioneFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory,
            public utenteFactory: Utente.utenteFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            private documentoFactory: Documento.documentoFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            private depositoFactory: Deposito.depositoFactory
        ) {
            super(upload, utenteFactory, undefined, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            var vm = this;
            this.formValorizzazione = {};
            this.filtro = <FiltroValorizzazione>{
                DataA: undefined,
                DataDa: undefined,
                FlgTnt: undefined,
                IdCentroCosto: undefined,
                IdDeposito: undefined,
                IdGruppo: undefined,
                IdSede: undefined,
                IdSottoGruppo: undefined,
                IdTipo: undefined,
                IdTipoAnalisi: undefined // default tipo 1
            };
            (<any>this.filtro).IdTipoAnalisi = "1"; // default tipo 1, barbatrucco per far compilare automatico sul filtro

            this.getDepositi();
            this.getTipi();
        }

        private tipoChangeHandler(): void {
            if (this.filtro.IdTipo == undefined) {
                this.filtro.FlgTnt = undefined;
                return;
            }
            var idTipo = this.filtro.IdTipo;
            var tipo = $.grep(this.tipi, (elem, index) => {
                return elem.Id == idTipo;
            })[0];
            this.filtro.FlgTnt = tipo.FullObject === "TNT";
        }

        private init(): void {
            var vm = this;
            $(document).on("gotUserSession", (e: JQueryEventObject) => { });
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 500);
        }

        private getDepositi(): void {
            this.depositoFactory.listaValorizzazione().then(
                result => {
                    this.depositi = result;
                },
                error => { }
            );
        }

        private getTipi(): void {
            this.valorizzazioneFactory.GetTipiMateriali().then(
                result => {
                    this.tipi = result;
                },
                error => { }
            );
        }
        
        private ricerca(): void {
            this.isSaving = true;

            if (this.filtro.StrDataDa != undefined)
                this.filtro.DataDa = moment(this.filtro.StrDataDa, "DD/MM/YYYY").add("hour", 2).toDate();
            if (this.filtro.StrDataA != undefined)
                this.filtro.DataA = moment(this.filtro.StrDataA, "DD/MM/YYYY").add("hour", 2).toDate();
            var tipoRicerca = this.filtro.IdTipoAnalisi;
            this.valorizzazioneFactory.Ricerca(this.filtro).then(
                result => {
                    this.tipoAnalisi = tipoRicerca;
                    this.lista = result;
                    this.isSaving = false;
                }
            );
        }

        private getExportUrl(): string {
            var result = appRoot + "Reports/ExportValorizzazione?strFiltro=" + JSON.stringify(this.filtro);
            return result;
        }
    }
}
app.controller("valorizzazioneController", Trace.Valorizzazione.valorizzazioneController);