app.factory("gestioneManutenzioniTransitPointFactory", ($http, $q) => new Trace.GestioneManutenzioniTransitPoint.gestioneManutenzioniTransitPointFactory($http, $q));

module Trace.GestioneManutenzioniTransitPoint {
    export class gestioneManutenzioniTransitPointFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/GestioneManutenzioni/";
        }
        static $inject = ["$http", "$q"];

        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public getTipiManutenzione(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "GetTipiManutenzione";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public getAziendeRiparatrici(): ng.IPromise<Marche.Marca[]> {
            var workUrl = this.GetBaseUrl() + "GetAziendeRiparatrici";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public ricercaTransitPoint(filtro: Trace.ViewModels.RicercaVm, currentPage: number = undefined): ng.IPromise<ViewModels.GenericPaginationVm<Movimenti.MovimentoVm>> {
            var workUrl = this.GetBaseUrl() + "RicercaTransitPoint/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public leggi(id: number): ng.IPromise<Trace.Movimenti.MovimentoVm> {
            var workUrl = this.GetBaseUrl() + "GetMovimentoTransitPointDetail/" + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public avanzaManutenzione(id: number): ng.IPromise<Trace.Movimenti.Movimento> {
            var workUrl = this.GetBaseUrl() + "AvanzaManutenzione/" + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public salvaTransitPoint(mov: Movimenti.MovimentoVm): ng.IPromise<Trace.Movimenti.MovimentoVm> {
            var workUrl = this.GetBaseUrl() + "SalvaManutenzioneTransitPoint";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, mov).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}