module Trace.Intervento {
    export class InterventoVm implements IInterventoVm {
        public Id: number;
        public IdTipoIntervento: number;
        public Codice: string;
        public Descrizione: string;
        public Data: Date;
        public StrData: string;
        public IdCausaleUtilizzoSet: number;
        public MassimoNumeroSet: number;
        public NumeroSet: number;
        public IdComplessita: number;
        public IdMassimaComplessitaSet: number;
        public IdComplessitaSet: number;
        public Dettagli: Trace.Intervento.IInterventoDetailVm[];
        public Lotti: Produzione.LottoProduzioneVm[];
        public Gruppo: string;
        public Sottogruppo: string;
        public Sede: string;
        public CentroCosto: string;
        public Anomalie: Helpers.SimpleEntity[];
        public Note: string;
        public Complessita: string;
        public MassimaComplessitaSet: string;
        public FlgWarning: boolean;
        public FlgReintervento: boolean;
        public IdGruppo: number;
        public IdSottogruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public Immagine: ViewModels.ImmagineVm;
        public IdMedico: number;
        public Medico: string;
        public Box: string;
        public NumeroDdt: number;
        public FlgImmagini: boolean;
        public FlgCreazioneSet: boolean;
        public FlgMocoma: boolean;
        public CodiceMocoma: string|number;
        public ImmaginiTransiti: any = [];
    }
}