module Trace.Accettazione {
    export class AccettazioneMateriale implements AccettazioneMaterialeVm {
        public Descrizione: string;
        public Qta: number;
        public IdIntervento: number;
        public IdGruppo: number;
        public IdSottogruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public NumeroDdt: number;
        public ListaTessili: Array<AccettazioneTessileVm>;
    }
}