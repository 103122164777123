module Trace.Produzione {
    export class LetturaBarcode {
        public ean: ViewModels.EanCodeInfoVm;
        public barcode: string;
        public testo: string;
        constructor(private lettura: string, public tipo: number, public idGriglia?: number, public codiceRealeGriglia: string = "") {
            this.testo = lettura;
            this.barcode = (tipo === TipoBarcode.SET || tipo === TipoBarcode.NONE || tipo === TipoBarcode.GRIGLIASFUSA || tipo === TipoBarcode.PRODOTTO)
                ? lettura
                : lettura.slice(0, -1);
        };
    }
}