module Trace.Carrelli {
    export class carrelliController extends epBaseController {
        public carrelli: CarrelloVm[];
        public ricerca: RicercaCarrelliVm;
        private currentPage: number;

        public gruppi: Trace.Gruppo.Gruppo[];
        public sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];

        public gruppiRicerca: Trace.Gruppo.Gruppo[];
        public sottoGruppiRicerca: Trace.SottoGruppo.SottoGruppo[];
        public sediRicerca: Trace.Sede.Sede[];
        public centriCostoRicerca: Trace.CentroCosto.CentroCosto[];

        public carrello: Carrello;
        public formCarrello: any;
        static $inject = ['$rootScope', '$scope', 'carrelliFactory', 'gruppiFactory', 'sottoGruppiFactory', 'sediFactory', 'centriCostoFactory', 'dtFactory', 'localeFactory', 'msgFactory', '$uibModal', "tracciabilitaFactory", "tracciabilitaFactory"];
        constructor(
            public $rootScope: ng.IRootScopeService,
            private $scope: ng.IScope,
            private carrelliFactory: carrelliFactory,
            private gruppiFactory: Gruppo.gruppiFactory,
            private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory,
            private dtFactory: datatables.dtFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(null, undefined, undefined, undefined, undefined, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);
            this.getGruppi();
            this.setUpWatchers();
            this.formCarrello = {};
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.carrello.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppi = undefined;
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSottogruppi(newVal, "");
            });
            this.$scope.$watch("vm.ricerca.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppiRicerca = undefined;
                    this.sediRicerca = undefined;
                    this.centriCostoRicerca = undefined;
                }
                else this.getSottogruppi(newVal, "r");
            });
            this.$scope.$watch("vm.carrello.IdSottogruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSedi(newVal, "");
            });
            this.$scope.$watch("vm.ricerca.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sediRicerca = undefined;
                    this.centriCostoRicerca = undefined;
                }
                else this.getSedi(newVal, "r");
            });
            this.$scope.$watch("vm.carrello.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCosto = undefined;
                else this.getCentriCosto(newVal, "");
            });
            this.$scope.$watch("vm.ricerca.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCostoRicerca = undefined;
                else this.getCentriCosto(newVal, "r");
            });
        }

        public init() {
            this.dtFactory.aggiornaRicerca();
            this.changeState('list');
        }

        private getGruppi(tipo: string = undefined): void {
            this.gruppiFactory.lista().then(
                result => {
                    if(tipo == undefined || tipo == "r")
                        this.gruppiRicerca = result;
                    if (tipo == undefined || tipo == "")
                        this.gruppi = result;
                }
            );
        }

        private getSottogruppi(idGruppo: number, tipo: string = undefined): void {
            this.sottoGruppiFactory.listaGruppo(idGruppo).then(
                result => {
                    console.log(result);
                    if (tipo == undefined || tipo == "r") this.sottoGruppiRicerca = result;
                    if (tipo == undefined || tipo == "") this.sottoGruppi = result;
                }
            );
        }

        private getSedi(idSottogruppo: number, tipo: string = undefined): void {
            this.sediFactory.listaSottoGruppo(idSottogruppo).then(
                result => {
                    if (tipo == undefined || tipo == "r") this.sediRicerca = result;
                    if (tipo == undefined || tipo == "") this.sedi = result;
                }
            );
        }

        private getCentriCosto(idSede: number, tipo: string = undefined): void {
            this.centriCostoFactory.listaSede(idSede).then(
                result => {
                    if (tipo == undefined || tipo == "r") this.centriCostoRicerca = result;
                    if (tipo == undefined || tipo == "") this.centriCosto = result;
                }
            );
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var vm = this;
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.carrelliFactory.ricerca(ricerca, page).then(
                risultato => {
                    var r = risultato;
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                    this.carrelli = r.List;
                },
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_lettura_immagini_errore", [errore]));
                }
            );
        }

        public resetForm(): void {
            this.ricerca = new RicercaCarrelliVm();
        }

        public changeState(state: string) {
            this.currentState = state;
            switch (state) {
                case 'list':
                    this.editMode = false;
                    this.carrello = null;
                    break;

                case 'manipola':
                    break;
            }
        }

        public cancella(carrello: CarrelloVm): void {
            this.msgFactory.confirm(this.localeFactory.get(""))
        }

        public modifica(carrello: CarrelloVm) {
            this.carrello = carrello;
            this.editMode = true;
            this.changeState('manipola');
        }

        public aggiungi(): void {
            this.carrello = new Carrello();
            this.editMode = false;
            this.titoloManipola = this.localeFactory.get("nuovo_carrello");
            this.changeState('manipola');
        }

        public backToList(): void {
            this.changeState('list');
        }

        public isInEdit(): boolean {
            return this.editMode && this.currentState === "manipola";
        }

        public registra(): void {
            var vm = this;
            this.showValidationMessages = false;
            if (!this.formCarrello.$valid) {
                this.showValidationMessages = true;
                this.formCarrello.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            this.confermaRegistra();
        }

        public confermaRegistra(showConfirm: boolean = true): void {
            if (showConfirm) {
                var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_salvataggio_conferma"));
                conf.then(result => {
                    if (!result.result) return;
                    this.doRegistrazione();
                });
            }
            else {
                this.doRegistrazione();
            }
        }

        private doRegistrazione(): void {
            this.isSaving = true;
            this.carrello.Codice = this.carrello.Codice.toUpperCase();
            var promise = (this.carrello.Id == undefined)
                ? this.carrelliFactory.inserisci(this.carrello)
                : this.carrelliFactory.modifica(this.carrello);

            promise.then(
                result => {
                    var alertPromise = this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                    alertPromise.then(result => {
                        this.changeState("list");
                    });
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_errore", [reason]));
                }).finally(() => {
                    this.isSaving = false;
                });
        }
    }
}
app.controller("carrelliController", Trace.Carrelli.carrelliController);