module Trace.Home {
    export class homeController extends epBaseController {
        public utente: Utente.UtenteVm;
        public ultimaSessione: Utente.ApplicationSessionVm;
        public aggiornaIsBusy: boolean;
        static $inject = ["$scope", "$rootScope", "localeFactory", "utenteFactory", "msgFactory", "$uibModal", "tracciabilitaFactory"];
        constructor(private $scope: ng.IScope, public $rootScope: ng.IRootScopeService, public localeFactory: Trace.Localization.localeFactory,
            public utenteFactory: Utente.utenteFactory, public msgFactory: Messages.msgFactory, public $uibModal: ng.ui.bootstrap.IModalService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory)
        {
            super(null, utenteFactory, undefined, undefined, undefined, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);
            this.getCurrentUser();
            this.getUltimaSessione();
            this.aggiornaIsBusy = false;
        }

        public avatar(): string {
            if (this.utente == undefined) return appRoot + "images/user-female.png";
            return this.utente.Immagine != undefined ? this.utente.Immagine.img : appRoot + "images/" + ((this.utente.Sesso == "F") ? "user-female.png" : "user-male.png");
        }

        public datiUltimaSessione(): string {
            if (this.ultimaSessione == undefined) return "";
            var durata = this.ultimaSessione.Durata == undefined ? "0" : Math.ceil(this.ultimaSessione.Durata).toString();
            var data = moment(this.ultimaSessione.DataInizio).format("DD/MM/YYYY") + " alle " + moment(this.ultimaSessione.DataInizio).format("HH:mm");
            var row1 = this.localeFactory.getFormatted('ultima_sessione_data', [data]);
            var row2 = this.localeFactory.getFormatted('ultima_sessione_durata', [durata]);
            return "<p><i class='fa fa-cog text-primary'></i>&nbsp;&nbsp;" + row1 + "</p><p><i class='fa fa-clock-o text-primary'></i>&nbsp;&nbsp;" + row2 + "</p>";
        }

        private getUltimaSessione(): void {
            var vm = this;
            this.utenteFactory.ultimaSessione().then(
                // OK
                result => {
                    console.log("Ultima sessione:", typeof (result));
                    vm.ultimaSessione = (typeof (result) != "object") ? undefined : result;
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_sessione_errore", [errore]));
                }
            );
        }
        private spAggiornaDb(): void {
            var vm = this;
            this.aggiornaIsBusy = true;
            this.utenteFactory.spAggiornaDb().then(
                // OK
                result => {
                    vm.msgFactory.alert(vm.localeFactory.get("messaggio_aggiornamento_ok"));
                    this.aggiornaIsBusy = false;
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? vm.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_sessione_errore", [errore]));
                    this.aggiornaIsBusy = false;
                }
            );
        }
        private getCurrentUser(): void {
            var vm = this;
            this.utenteFactory.currentUser().then(
                // OK
                result => {
                    vm.utente = result;
                    vm.titolo = result.Nome + " " + result.Cognome;                    
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_sessione_errore", [errore]));
                }
            );
        }
    }
}
app.controller("homeController", Trace.Home.homeController);