module Trace.Analisi {
    export class produttivitamensileController {
        private filtro: FiltroProduttivitaMensile; 
        private graficoShow: boolean;
        private centrali: Trace.Centrale.Centrale[];
        private risultatoRicerca: any;
        private isLoading: boolean;
        private anni: any;

        static $inject = ['$scope', 'centraliFactory', 'produttivitaFactory', 'localeFactory', 'msgFactory'];
        constructor(
            private $scope: ng.IScope,
            private centraliFactory: Centrale.centraliFactory,
            private produttivitaFactory: Analisi.produttivitaFactory, 
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory            
        ) {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 1000);
                        
            this.anni = this.getPeriodiFiltro('Anni');
            this.filtro = new FiltroProduttivitaMensile();
            this.filtro.AnnoRiferimento = moment(Date.now()).get('Year');
            
            this.getCentrali();
            this.setUpWatchers();
        }

        private getPeriodiFiltro(datePart: string): any[] {
            switch (datePart) {
                case 'Mesi':
                    return [
                        { label: 'Gennaio', value: 1 },
                        { label: 'Febbraio', value: 2 },
                        { label: 'Marzo', value: 3 },
                        { label: 'Aprile', value: 4 },
                        { label: 'Maggio', value: 5 },
                        { label: 'Giugno', value: 6 },
                        { label: 'Luglio', value: 7 },
                        { label: 'Agosto', value: 8 },
                        { label: 'Settembre', value: 9 },
                        { label: 'Ottobre', value: 10 },
                        { label: 'Novembre', value: 11 },
                        { label: 'Dicembre', value: 12 }
                    ];
                case 'Anni':
                    let intervalloAnni = 5;
                    let annoCorrente = moment(Date.now()).get('Year');
                    let year: number;
                    let res: any[] = [];
                    for (year = annoCorrente - intervalloAnni; year <= annoCorrente /*+ intervalloAnni*/; year++) {
                        res.push({ label: year.toString(), value: year });
                    }
                    return res;
            }
            return [];
        }

        public ricerca(): void {
            if (this.filtro.IdCentrale == undefined || this.filtro.AnnoRiferimento == undefined) {
                let msg = "Seleziona ";
                if (this.filtro.AnnoRiferimento == undefined) msg += "un anno di riferimento ";
                if (this.filtro.IdCentrale == undefined) msg += this.filtro.AnnoRiferimento == undefined ? "ed una centrale " : "una centrale ";
                msg += "prima di procedere alla ricerca.";
                this.msgFactory.alert(msg);
                return;
            }
            this.isLoading = true;
            this.graficoShow = true;
            //this.graficoShow = this.filtro.IdFaseProduzione != undefined ? true : false;
            this.produttivitaFactory.RicercaProduttivitaMensile(this.filtro).then(
                result => {
                    this.risultatoRicerca = result;
                    this.isLoading = false;                    

                    if (this.risultatoRicerca != null && this.risultatoRicerca.DatiProduttivitaMensile.length > 0) {                        
                        // update dati grafici
                        this.graficoProdMensile();
                        this.graficoProdCentrali();
                    }
                    else {
                        //this.graficoShow = false;
                        this.msgFactory.alert(this.localeFactory.get("nessun_risultato_ricerca"));
                    }
                }
            );
        }
        private init() {
           
        }

        public svgToImage(svgSelector: string): string {
            var svg = $(svgSelector).get(0);
            canvg(document.getElementById('drawingArea'), svg.outerHTML);
            var data = document.getElementById('drawingArea').toDataURL("image/png");
            return data;
        }
        public exportPdfProdMensile(): void {
            this.isLoading = true;
            var immaginiGrafici = new Array<Helpers.SimpleEntity>();
            var grafici = $(".highcharts-container");
            $.each(grafici, (index, item) => {
                var b64str = this.svgToImage("#" + $(item).attr("id") + " svg");
                immaginiGrafici.push(<Helpers.SimpleEntity>{
                    Id: index,
                    Descrizione: "",
                    FullObject: b64str
                });
            });
            var obj = <Analisi.ExportPdfVm>{
                Immagini: immaginiGrafici,
                Filtro: undefined,
                FiltroProdMensile: this.filtro
            };
            this.produttivitaFactory.ExportPdfProdMensile(obj).then(
                result => {
                    var url = appRoot + "rpts/ANALISIPRODUTTIVITA/" + result;
                    window.open(url, "_blank");
                    this.isLoading = false;
                },
                reason => {
                    this.isLoading = false;
                }
            );
        }

        private graficoProdMensile(): void  {
            let risultatoRicerca = (this.risultatoRicerca != undefined ? this.risultatoRicerca.DatiProduttivitaMensile : null) as DatiProduttivitaMensileVm[];

            if (risultatoRicerca == undefined || risultatoRicerca.length <= 0) return;

            var categories = risultatoRicerca.map(h => { return h.MeseRiferimento; }); 
            var data = risultatoRicerca.map(h => { return h.Produttivita; }); 

            //var avg: number = 0;
            //var len: number = 0;
            //$.each(data, (index, value) => {
            //    if (value != undefined) {
            //        avg += value; len++;
            //    }
            //});
            //avg = avg / len;

            //var plotLine = [];
            //if (len > 0) plotLine = [{
            //    value: avg,
            //    color: 'green',
            //    dashStyle: 'shortdash',
            //    width: 2,
            //    label: {
            //        text: 'Valore medio'
            //    }
            //}];

            $("#my-chart-1-container").highcharts(
                {
                    chart: {
                        type: 'column'
                    },

                    title: {
                        text: null
                    },

                    xAxis: {
                        categories: categories
                    },

                    yAxis: {
                        allowDecimals: false,
                        min: 0,
                        title: {
                            text: 'Valore'
                        },
                        //plotLines: plotLine
                    },

                    //tooltip: {
                    //    formatter: function () {
                    //        return '<b>' + this.x + '</b><br/>' +
                    //            this.series.name + ': ' + this.y + '<br/>' +
                    //            'Total: ' + this.point.stackTotal;
                    //    }
                    //},

                    plotOptions: {
                        column: {
                            stacking: 'normal'
                        }
                    },

                    series: [{
                        name: "Indice US/ore",
                        data: data
                    }]
                }
            );
        }

        private graficoProdCentrali(): void {
            let risultatoRicerca = this.risultatoRicerca != undefined ? this.risultatoRicerca.DatiProduttivitaCentrali : null;

            if (risultatoRicerca == undefined || risultatoRicerca.length <= 0) return;
            
            $("#my-chart-2-container").highcharts(
                {
                    chart: {
                        type: 'line'
                    },
                    title: {
                        text: null
                    },
                    xAxis: {
                        categories: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]
                    },
                    yAxis: {
                        title: {
                            text: 'Indice US/ore'
                        }
                    },
                    plotOptions: {
                        line: {
                            dataLabels: {
                                enabled: true
                            },
                            enableMouseTracking: false
                        }
                    },
                    series: risultatoRicerca
                }
            );
        }

        public dynamicSort(property: string, sortOrder: number/*, isDate: boolean = false*/) {
            sortOrder = sortOrder == 2 ? -1 : sortOrder;
            return function (a, b) {
                var result;
                //if (!isDate)
                    result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                //else result = (moment(a[property]).isBefore(b[property])) ? -1 : (moment(a[property]).isAfter(b[property])) ? 1 : 0;
                return result * sortOrder;
            }
        }
        private getCentrali(): void {
            this.centraliFactory.lista().then(
                result => {
                    this.centrali = result;
                }
            );
        }
        private setUpWatchers(): void {
           
        }
        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

            
    }    
}
app.controller("produttivitamensileController", Trace.Analisi.produttivitamensileController);