module Trace.Components {
    export class macchinaController {
        public tipo: number;
        public macchina: Produzione.Macchina;
        public lotti: Array<Produzione.LottoProduzioneVm>;
        public idLottoSelezionato: number;

        constructor() {}

        public cicloManuale(): void {
            triggerMacchinaEvent("cicloManuale", this.macchina);
        }

        public eseguiCicloManuale(): void {
            triggerMacchinaEvent("eseguiCicloManuale", this.macchina);
        }

        public stampaEtichetteLottiSterilizzazione(): void {
            triggerMacchinaEvent("stampaLottiSterilizzazione", this.macchina);
        }
    }
}
app.controller("macchinaController", Trace.Components.macchinaController);

app.component("macchina", {
    templateUrl: "/views/Autoclavi/macchina.html",
    controller: "macchinaController",
    controllerAs: "vm",
    bindings: {
        macchina: "<",
        lotti: "<",
        id: "=",
        selected: "<",
        idLottoSelezionato: "<"
    }
});

app.component("macchinaAnalisiAutoclavi", {
    templateUrl: "/views/Autoclavi/macchinaAnalisi.html",
    controller: "macchinaController",
    controllerAs: "vm",
    bindings: {
        macchina: "<",
        lotti: "<",
        id: "=",
        selected: "<",
        idLottoSelezionato: "<"
    }
});

app.component("macchinaAnalisiLavaggio", {
    templateUrl: "/views/Lavaferri/macchinaAnalisi.html",
    controller: "macchinaController",
    controllerAs: "vm",
    bindings: {
        macchina: "<",
        lotti: "<",
        id: "=",
        selected: "<",
        idLottoSelezionato: "<",
    }
});

app.component("macchinaLavaggio", {
    templateUrl: "/views/Lavaferri/macchina.html",
    controller: "macchinaController",
    controllerAs: "vm",
    bindings: {
        macchina: "<",
        lotti: "<",
        id: "=",
        selected: "<",
        idLottoSelezionato: "<",
    }
});

app.component("macchinaPackaging", {
    templateUrl: "/views/Packaging/macchina.html",
    controller: "macchinaController",
    controllerAs: "vm",
    bindings: {
        macchina: "<",
        lotti: "<",
        id: "=",
        selected: "<",
        idLottoSelezionato: "<",
        azioni: "<"
    }
});