module Trace.Tnt {
    export class tntVm {
        public Id: number;
        public Codice: string;
        public Descrizione_it: string;
        public Descrizione_en: string;
        public Descrizione_al: string;
        public DataObsoleto: Date;
        public Tipo: string;

    }

    export class fullTntVm {
        public Id: number;
        public Codice: string;
        public DataCreazione: Date;
        public DataObsoleto: Date;
        public DataUltimaModifica: Date;
        public Descrizione_en_US: string;
        public Descrizione_it_IT: string;
        public Descrizione_sq_AL: string;
        public IdUtenteCreazione: number;
        public IdUtenteObsoleto: number;
        public IdUtenteUltimaModifica: number;
        public IdTipoTnt: number;
        public QtaScatola: number;
        public CostoStandard: number;
        public IdRaggruppamento: number;

        public Immagini: Array<ViewModels.ImmagineVm>;
        public Details: Array<tntDetailVm>;
        public DaCancellare: Array<number>;
    }
}