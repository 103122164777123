app.factory("mappatureCentraliFactory", ($http, $location, $q) => new Trace.MappaturaCentrale.mappatureCentraliFactory($http, $location, $q));

module Trace.MappaturaCentrale {
    
    export class MappaturePaginationVm extends ViewModels.PaginationVm {
        constructor() {
            super();
        }
        public List: Array<MappaturaCentrale.MappaturaCentraleVm>;
    }
    export class mappatureCentraliFactory {
        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/MappatureCentrali/";
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.MappaturaCentrale.MappatureCentraliPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'Ricerca/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: MappaturaCentraleVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        //public lista(): ng.IPromise<Gruppo[]> {
        //    var workUrl = this.GetBaseUrl();

        //    var deferred = this.$q.defer();
        //    this.$http.get(workUrl).success((data: Gruppo[], status) => {
        //        deferred.resolve(data);
        //    }).error(error => {
        //        deferred.reject(error);
        //    });

        //    return deferred.promise;
        //}


        //public listaCompleta(): ng.IPromise<Gruppo[]> {
        //    var workUrl = this.GetBaseUrl() + "ListaCompleta";

        //    var deferred = this.$q.defer();
        //    this.$http.get(workUrl).success((data: Gruppo[], status) => {
        //        deferred.resolve(data);
        //    }).error(error => {
        //        deferred.reject(error);
        //    });

        //    return deferred.promise;
        //}

        public leggi(id: number): ng.IPromise<Trace.MappaturaCentrale.MappaturaCentraleVm> {
            var workUrl = this.GetBaseUrl() + "GetMappaturaCentrale/" +id;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Trace.MappaturaCentrale.MappaturaCentraleVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(mappatura: Trace.MappaturaCentrale.MappaturaCentraleVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, mappatura).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(mappatura: Trace.MappaturaCentrale.MappaturaCentraleVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + mappatura.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, mappatura).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }


        //public cancella(idGruppo: number): ng.IPromise<boolean> {
        //    var workUrl = this.GetBaseUrl() + idGruppo;

        //    var deferred = this.$q.defer();
        //    this.$http.delete(workUrl).success((data: boolean, status) => {
        //        deferred.resolve(data);
        //    }).error(error => {
        //        deferred.reject(error);
        //    });

        //    return deferred.promise;

        //}

        //public patch(idGruppo: number, daPatchare: {}): ng.IPromise<boolean> {
        //    var workUrl = this.GetBaseUrl() + idGruppo;

        //    var deferred = this.$q.defer();
        //    this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
        //        deferred.resolve(data);
        //    }).error(error => {
        //        deferred.reject(error);
        //    });

        //    return deferred.promise;

        //}

        //public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<Gruppo[]> {
        //    var workUrl = this.GetBaseUrl() + 'Ricerca';

        //    var deferred = this.$q.defer();
        //    this.$http.post(workUrl, filtro).success((data: Gruppo[], status) => {
        //        deferred.resolve(data);
        //    }).error(error => {
        //        deferred.reject(error);
        //    });

        //    return deferred.promise;
        //}

        //public leggi(idGruppo: number): ng.IPromise<Gruppo> {
        //    var workUrl = this.GetBaseUrl() + idGruppo;

        //    var deferred = this.$q.defer();
        //    this.$http.get(workUrl).success((data: Gruppo, status) => {
        //        deferred.resolve(data);
        //    }).error(error => {
        //        deferred.reject(error);
        //    });

        //    return deferred.promise;
        //}

        public asSe(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + 'asSe/';

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }
    }
}