module Trace.Startup {
    export class StartupVm implements IStartupVm {
        public Id: number;
        public Codice: string;
        public Descrizione: string;
        public DataCreazione: Date;
        public IdUtenteCreazione: number;
        public UtenteCreazione: string;
        public IdCentrale: number;
        public Centrale: string;
        public Note: string;
        public Dettagli: Trace.Startup.IStartupDetailVm[];
    }
}