module Trace.Tnt {
    export class tntDetailVm {
        public Id: number;
        public IdProdotto: number;
        public Codice: string;
        public Descrizione_it: string;
        public Descrizione_en: string;
        public Descrizione_al: string;
        public DataObsoleto: Date;
        public Tipo: string;
        public Quantita: number;
    }
}