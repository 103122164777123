var app = angular.module("traceApp", ["ngSanitize", "ngFileUpload", "jkuri.gallery", "ui.bootstrap", "duScroll", "ngAnimate", "dragularModule"]);
var appRoot = "";
var userId = null;
var modelId = null;
var setId = null;
var productId = null;
var culture = "";
var controllerName = "";
var actionName = "";
var dbCulture = "";
var idLingua = null;
var idDeposito = null;
var idDestinazione = null;
var idProvenienza = null;
var idTipoMateriale = null;
var idCentrale = null;
var onlyMartin = null;
var richiesta = false;

// tracciabilità
var tCodSet = null;
var tCodTnt = null;
var tCodPrd = null;

// anagrafiche
var idTnt = null;
var idSet = null;

app.directive("ngWhenReady", [function () {
    return {
        priority: Number.MIN_VALUE, // execute last, after all other directives if any.
        restrict: "A",
        link: function (scope, $element, $attributes) {
            scope.$eval($attributes.ngWhenReady); // execute the expression in the attribute.          
        }
    };
}]);

app.directive("bindUnsafeHtml", [
    "$compile",
    function ($compile) {
        return function (scope, element, attrs) {
            scope.$watch(
                function (scope) {
                    // watch the "bindUnsafeHtml" expression for changes
                    return scope.$eval(attrs.bindUnsafeHtml);
                },
                function (value) {
                    // when the "bindUnsafeHtml" expression changes
                    // assign it into the current DOM
                    element.html(value);

                    // compile the new DOM and link it to the current
                    // scope.
                    // NOTE: we only compile .childNodes so that
                    // we don"t get into infinite loop compiling ourselves
                    $compile(element.contents())(scope);
                }
            );
        };
    }
]);

app.directive("ngEpTable", ["dtFactory", function (dtFactory) {
    return {
        priority: Number.MIN_VALUE, // execute last, after all other directives if any.
        restrict: "A",
        link: function (scope, $element, $attributes) {
            dtFactory.evalTable(scope, $element);
        }
    };
}]);

app.directive("ngEpResetForm", ["dtFactory", function (dtFactory) {
    return {
        priority: Number.MIN_VALUE, // execute last, after all other directives if any.
        restrict: "A",
        link: function (scope, $element, $attributes) {
            dtFactory.evalResetForm($element);
        }
    };
}]);

app.directive("ngEpHeaders", ["dtFactory", function (dtFactory) {
    return {
        priority: Number.MIN_VALUE, // execute last, after all other directives if any.
        restrict: "A",
        link: function (scope, $element, $attributes) {
            dtFactory.evalHeaders(scope, $element);
        }
    };
}]);

app.directive("ngEpSection", ["sectionsFactory", function (sectionsFactory) {
    return {
        priority: Number.MIN_VALUE, // execute last, after all other directives if any.
        restrict: "A",
        link: function (scope, $element, $attributes) {
            sectionsFactory.evalSection(scope, $element, $attributes);
        }
    };
}]);

app.directive("ngEpRenderSection", ["$compile", "sectionsFactory",
    function ($compile, sectionsFactory) {
        return function (scope, element, attrs) {
            sectionsFactory.evalSectionRenderer(scope, element, attrs);
            $compile(element.contents())(scope);
        };
    }
]);

app.directive("mAppLoading", function ($animate) {
        // Return the directive configuration.
        return ({
            link: link,
            restrict: "C"
        });
        // I bind the JavaScript events to the scope.
        function link(scope, element, attributes) {
            // Due to the way AngularJS prevents animation during the bootstrap
            // of the application, we can't animate the top-level container; but,
            // since we added "ngAnimateChildren", we can animated the inner
            // container during this phase.
            // --
            // NOTE: Am using .eq(1) so that we don't animate the Style block.
            var lEl = element.children().eq(0);
            $animate.leave(lEl).then(
                function cleanupAfterAnimation() {
                    // Remove the root directive element.
                    element.remove();
                    // Clear the closed-over variable references.
                    scope = element = attributes = null;
                }
            );
        }
});

app.directive('ngLastIteration', function () {
    return function (scope, element, attrs) {
        if (scope.$last) {
            scope.$eval(attrs.ngLastIteration);
        }
    };
});

app.directive('onFinishRender', function ($rootScope) {
    return {
        restrict: 'A',
        link: function (scope:any, element, attr) {
            if (scope.$last) {
                $(document).trigger("ngRepeatFinished");
            }
        }
    };
});

app.directive('epHighlighter', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        scope: {
            model: '=highlighter'
        },
        link: function (scope, element) {
            scope.$watch('model', function (nv, ov) {
                if (nv !== ov) {
                    // apply class
                    element.addClass('highlight');

                    // auto remove after some delay
                    $timeout(function () {
                        element.removeClass('highlight');
                    }, 1000);
                }
            });
        }
    };
}]);

app.directive('ngEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.ngEnter);
                });
                event.preventDefault();
            }
        });
    };
})

app.filter('orderObjectBy', function () {
    return function (input, attribute) {
        if (!angular.isObject(input)) return input;

        var array = [];
        for (var objectKey in input) {
            array.push(input[objectKey]);
        }

        array.sort(function (a, b) {
            a = parseInt(a[attribute]);
            b = parseInt(b[attribute]);
            return a - b;
        });
        return array;
    }
});

app.filter('matchFase', function () {
    return function (items, idFase) {
        var filtered = [];
        angular.forEach(items, function (item) {
            console.log(idFase, item.IdFase);
            if (idFase == item.IdFase) {
                filtered.push(item);
            }
        });
        return filtered;
    };
});

angular.element(document).ready(function () {
    moment.locale("it");
    var element = angular.element(document);

    ////This will be truthy if initialized and falsey otherwise.
    var isInitialized = element.injector();
    if (!isInitialized) {
        angular.bootstrap(document, ["traceApp"]);
    }
     
    $(document).on("keyup", (event: JQueryEventObject) => {
        //console.log(event.keyCode);
        switch (event.keyCode) {
            case 119:
                attivaLettoreBarcode();
                break;

            case 13:
                if ($("footer>#barcodeReader").is(":focus"))
                    letturaBarcodeEseguita();
                break;
        }
    });
});

function attivaLettoreBarcode() {
    // è stato attivato il lettore barcode quindi do il focus alla casella di testo del barcode reader
    $("footer>#barcodeReader").val("");
    $("footer>#barcodeReader").focus();
}

function letturaBarcodeEseguita() {
    var testo: string = $("footer>#barcodeReader").val();
    //$("footer>#barcodeReader").val("");
    triggerBarcode(testo);
}

function padNumber(myNumber: number, paddedLength: number): string {
    var head = "";
    while (paddedLength > 0) {
        head += "0";
        paddedLength--;
    }

    return (head + myNumber).slice(-paddedLength);
}

interface String {
    format(args: Array<any>): string;
    capitalize(): string;
}

interface JQueryStatic {
    pivotUtilities: any;    
}

interface JQuery {
    dropdown: any;
}

interface JQueryEventObject {
    lettura: string;
    fittizio?: boolean;
    carico: Trace.Produzione.CaricoMacchina;
    lotto: Trace.Produzione.LottoProduzioneVm;
    box: Trace.Produzione.BoxVm;
    idLotto: number;
    griglia: Trace.Produzione.GrigliaVm;
    griglieStrumenti: Trace.Produzione.GrigliaStrumentiVm[];
    strumento: Trace.Set.StrumentoVm;
    macchina: Trace.Produzione.Macchina;
    funzione: string;
    ean: Trace.ViewModels.EanCodeInfoVm;
    image: Trace.ViewModels.ImmagineVm;
    movimento: Trace.Movimenti.MovimentoVm;
    itemIntervento: Trace.Intervento.IInterventoDetailVm;
    tipoUpload: string;
    nomeFileUpload: string;
    queryString: string;
}

interface HighchartsStatic {
    Point: any;
    Pointer: any;
}

String.prototype.format = function (arguments: any): string {
    // console.log("argomenti", arguments.length);
    if (typeof arguments !== 'object') {
        arguments = [arguments];
    }
    var s = this,
        i = arguments.length;

    while (i--) {
        if (arguments[i] == undefined)
            s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), '');
        else
            s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i].toString());
    }
    return s;
};

String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
}

var triggerUploadEvent = function (type: string, tipo: string, nome: string, originale: string) {
    var e = $.Event(type);
    e.tipoUpload = tipo;
    e.nomeFileUpload = nome;
    e.lettura = originale;
    $(document).trigger(e);
};

var triggerImageEvent = function (type: string, image: Trace.ViewModels.ImmagineVm) {
    var e = $.Event(type);
    e.image = image;
    $(document).trigger(e);
};

var triggerRemoveImage = function (image: Trace.ViewModels.ImmagineVm) {
    var e = $.Event("removeImage");
    e.image = image;
    $(document).trigger(e);
}

var triggerRemoveItemIntervento = function (item: Trace.Intervento.IInterventoDetailVm) {
    var e = $.Event("removeItemIntervento");
    e.itemIntervento = item;
    $(document).trigger(e);
}

var triggerKeyUp = function (element, keyCode) {
    var e = $.Event("keyup");
    e.which = keyCode;
    element.trigger(e);
};

var triggerBarcode = function (text: string, fittizio: boolean = false) {
    var e = $.Event("barcode");
    e.lettura = text;
    e.fittizio = fittizio;
    $(document).trigger(e);
};

var triggerBarcodeByLotto = function (text: string, lotto: string) {
    var e = $.Event("barcode");
    e.lettura = text;
    e.idLotto = parseInt(lotto);
    $(document).trigger(e);
}

var triggerEvent = function (type: string, value: any, idLotto?: number) {
    var e = $.Event(type);
    e.lettura = value;
    e.idLotto = idLotto;
    $(document).trigger(e);
};

var triggerEanEvent = function (type: string, value: Trace.ViewModels.EanCodeInfoVm) {
    var e = $.Event(type);
    e.ean = value;
    $(document).trigger(e);
};

var triggerProduzioneEvent = function (type: string, lotto: Trace.Produzione.LottoProduzioneVm, funzione: string) {
    var e = $.Event(type);
    e.lotto = lotto;
    e.funzione = funzione;
    $(document).trigger(e);
};

var triggerBoxEvent = function (type: string, box: Trace.Produzione.BoxVm, funzione: string) {
    var e = $.Event(type);
    e.box = box;
    e.funzione = funzione;
    $(document).trigger(e);
};

var triggerMovimentoEvent = function (type: string, mov: Trace.Movimenti.MovimentoVm, funzione: string) {
    var e = $.Event(type);
    e.movimento = mov;
    e.funzione = funzione;
    $(document).trigger(e);
};

var triggerGrigliaEvent = function (type: string, griglia: Trace.Produzione.GrigliaVm, funzione: string) {
    var e = $.Event(type);
    e.griglia = griglia;
    e.funzione = funzione;
    $(document).trigger(e);
};

var triggerGrigliaStrumentiEvent = function (type: string, griglia: any) {
    var e = $.Event(type);
    if (type === "barcodeGrigliaCaricataOk")
        e.carico = <Trace.Produzione.CaricoMacchina>griglia;
    else
        e.griglieStrumenti = <Trace.Produzione.GrigliaStrumentiVm[]>griglia;
    $(document).trigger(e);
};

var triggerStrumentoEvent = function (type: string, strumento: Trace.Set.StrumentoVm, funzione: string) {
    var e = $.Event(type);
    e.strumento = strumento;
    e.funzione = funzione;
    $(document).trigger(e);
};

var triggerMacchinaEvent = function (type: string, macchina: Trace.Produzione.Macchina) {
    var e = $.Event(type);
    e.macchina = macchina;
    $(document).trigger(e);
};

var triggerQuerySearchEvent = function (type: string, query: string) {
    var e = $.Event(type);
    e.queryString = query;
    $(document).trigger(e);
}

var getNumber = function (num): Array<any> {
    return new Array(num);
}

var dateChanged = function (property: string, item: any): void {
    var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
    item[property] = data;
}

var SortByName = function (a, b) {
    var aName = a.name.toLowerCase();
    var bName = b.name.toLowerCase();
    return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
}

var SortByFullObject = function (a, b) {
    var aName = a.FullObject;
    var bName = b.FullObject;
    return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
}

var SortByCodice = function (a, b) {
    var aName = a.Codice;
    var bName = b.Codice;
    return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
}

declare var canvg: (a: any, b: any) => any;

interface HTMLElement {
    toDataURL: Function;
}

var Once = function (elem, a, b) {
    return elem.each(function () {
        $(this).off(a).on(a, b);
    });
}

interface Window {
    RTCPeerConnection: any;
    webkitRTCPeerConnection: any;
    mozRTCPeerConnection: any;
}

interface Array<T> {
    find: (param) => T
};

var sleep = function (milliseconds) {
    var e = new Date().getTime() + (milliseconds);
    while (new Date().getTime() <= e) { }
}