module Trace.Carrelli {
    export class RicercaCarrelliVm {
        public Id: number;
        public DataCreazione: Date;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdGruppo: number;
        public IdSottogruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
    }
}