module Trace.SoglieConsumi {
    export interface VwSoglieConsumiDetails {
        Id: number;
        DataCreazione: Date;
        IdUtenteCreazione: number;
        UtenteCreazione: string;
        IdSogliaConsumo: number;
        IdRaggruppamento: number;
        Raggruppamento: string;
        IdTipoRaggruppamento: number;
        TipoRaggruppamento: string;
        Qta: number;
        DataUltimaModifica: Date;
        IdUtenteUltimaModifica: number;
        UtenteUltimaModifica: string;
        DataObsoleto: Date;
        IdUtenteObsoleto: number;
        UtenteCancellazione: string;
    }
}