app.factory("ordiniFactory", ($http, $q) => new Trace.Ordini.ordiniFactory($http, $q));
module Trace.Ordini {
    export class ordiniFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Ordini/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, currentPage: number): ng.IPromise<Trace.Ordini.OrdiniPaginationVm> {
            var workUrl = this.GetBaseUrl() + "Ricerca/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SerieNumeroOrdine(serie: string = "SNS"): ng.IPromise<string[]> {
            var workUrl = this.GetBaseUrl() + "SerieNumeroOrdine/" + serie;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public registra(ordine: OrdineVm): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, ordine).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Delete(id: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "Delete/" + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SimpleListFornitori(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "SimpleListFornitori";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AccettaPreventivo(idOrdine: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AccettaPreventivo/" + idOrdine;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public registraOrdineFornitore(ordine: OrdineVm, idFornitore: number): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl() + "RegistraOrdineFornitore/" + idFornitore;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, ordine).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public EvasioneParzialeOrdine(idOrdine: number, movimenti: Movimenti.MovimentoDetail[]): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl() + "EvasioneParzialeOrdine/" + idOrdine;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, movimenti).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public EvasioneOrdine(idOrdine: number, movimenti: Movimenti.MovimentoDetail[]): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl() + "EvasioneOrdine/" + idOrdine;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, movimenti).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public DettagliMateriale(query: Movimenti.QueryDettaglioMovimenti): ng.IPromise<Ordini.DettaglioOrdineMaterialeVm[]> {
            var workUrl = this.GetBaseUrl() + "DettagliMateriale";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, query).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}