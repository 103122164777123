module Trace.Consumi {
    export interface VwConsumiPerIntervento {
        Id: number;
        IdIntervento: number;
        DataAccettazione: Date;
        DataIntervento: Date;
        IdSet: number;
        Set: string;
        IdTipoIntervento: number;
        TipoIntervento: string;
        IdComplessita: number;
        Complessita: string;
        IdRaggruppamentoIntervento: number;
        RaggruppamentoIntervento: string;
        IdRaggruppamentoTnt: number;
        IdGruppo: number;
        Gruppo: string;
        IdSottogruppo: number;
        Sottogruppo: string;
        IdSede: number;
        Sede: string;
        IdCentroCosto: number;
        CentroCosto: string;
        RaggruppamentoTnt: string;
        Qta: number;
        QtaSoglia: number;
    }
}