module Trace.TimeMoto {
    export class discordanzeutentiController {
        private tabSelezionata: string;
        private analisiDiscordanze: Utente.AnalisiDiscordanzeUtenti;
        private isLoading: boolean;

        static $inject = ['$scope', 'utenteFactory', 'localeFactory', 'msgFactory'];
        constructor(
            private $scope: ng.IScope,
            private utentiFactory: Utente.utenteFactory,
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory            
        ) {
            this.selezioneTab('Associati');

            this.caricaDati();
                     
            this.setUpWatchers();
        }

        private caricaDati() {
            this.isLoading = true;
            this.utentiFactory.analisiDiscordanzeUtenti().then((risultato) => {
                this.analisiDiscordanze = risultato;
                this.isLoading = false;
            });
        }

        private selezioneTab(chiave: string) {
            switch (chiave) {
                case 'Associati':
                    this.tabSelezionata = chiave;
                    break;
                case 'SoloTrace':
                    this.tabSelezionata = chiave;
                    break;
                case 'SoloTimeMoto':
                    this.tabSelezionata = chiave;
                    break;
                case 'Discordanze':
                    this.tabSelezionata = chiave;
                    break;
            }
        }

        private getFiltered(): Utente.UtenteTraceTimeMoto[] {
            if (this.analisiDiscordanze == undefined) return [];
            return this.analisiDiscordanze[this.tabSelezionata];
        }

        public init(): void { }       

        private setUpWatchers(): void { }          
    }    
}
app.controller("discordanzeutentiController", Trace.TimeMoto.discordanzeutentiController);