module Trace.datatables {
    export class DtTable {
        public table: string;
        public element: JQuery;
        public columns: Array<JQuery>;
        public dtSort: string;
        public dtSortDir: Trace.ViewModels.DirezioneSort;
        public objectRicerca: string;
        public pages: Array<number>;
        public pagination: ViewModels.PaginationVm;
        private range: ViewModels.PagesVm;

        constructor(private scope: ng.IScope, private dtf: dtFactory, private localeFactory: Localization.localeFactory, private id: number) {
            var prop = "vm.dtFactory.tables[" + this.id.toString() + "].pagination.CurrentPage";
            this.scope.$watch(prop, (newVal: number, oldVal: number) => {
                this.pageChanged();
            });
        }
        
        private isLastPage(): boolean {
            if (this.pagination == undefined) return false;
            return this.pagination.CurrentPage === this.pagination.TotalPages;
        }

        private isFirstPage(): boolean {
            if (this.pagination == undefined) return false;
            return this.pagination.CurrentPage === 1;
        }

        public previousDisabled(): boolean {
            return this.isFirstPage();
        }

        public nextDisabled(): boolean {
            return this.isLastPage();
        }

        public isCurrentPage(p: number): boolean {
            if (this.pagination == undefined) return false;
            return p === this.pagination.CurrentPage;
        }

        public gotoPrevPage(): void {
            if (this.isFirstPage()) return;
            var p: number = this.pagination.CurrentPage - 1;
            this.gotoPage(p);
        }

        public gotoNextPage(): void {
            if (this.isLastPage()) return;
            var p: number = this.pagination.CurrentPage + 1;
            this.gotoPage(p);
        }

        public gotoPage(p: number): void {
            this.range = new ViewModels.PagesVm();
            this.range.fromPage = this.pages[0];
            this.range.toPage = this.pages[this.pages.length - 1];
            this.dtf.aggiornaRicerca(this.table, p);
        }

        private pageChanged(): void {
            if (this.pagination == undefined) return;
            var maxPage = this.pagination.TotalPages;
            var fromPage = Math.floor(this.pagination.CurrentPage / 10) + 1;
            var toPage = Math.floor(this.pagination.CurrentPage / 10) + 10;
            
            if (this.range != undefined && this.range.fromPage != undefined && this.range.toPage != undefined) {
                if (this.pagination.CurrentPage > this.range.fromPage && this.pagination.CurrentPage < this.range.toPage) {
                    fromPage = this.range.fromPage;
                    toPage = this.range.toPage;
                }
                else {
                    if (this.pagination.CurrentPage <= this.range.fromPage) {
                        fromPage = this.pagination.CurrentPage !== 1 ? this.pagination.CurrentPage - 1 : 1;
                        toPage = fromPage + (10 - 1);
                    }
                    if (this.pagination.CurrentPage >= this.range.toPage) {
                        toPage = this.pagination.CurrentPage !== this.pagination.TotalPages ? this.pagination.CurrentPage + 1 : this.pagination.TotalPages;
                        fromPage = toPage - (10 - 1);
                        
                    }
                }
            }
            if (toPage > maxPage) toPage = maxPage;
            if (fromPage < 1) fromPage = 1;

            this.pages = [];
            for (var i = fromPage; i <= toPage; i++) {
                this.pages.push(i);
            }
        }

        public paginationCurrent(): string {
            if (this.pagination == undefined)
                return;
            var from = (this.pagination.ItemsPerPage * (this.pagination.CurrentPage - 1)) + 1;
            var to = this.pagination.ItemsPerPage * this.pagination.CurrentPage;
            if (to > this.pagination.TotalItems)
                to = this.pagination.TotalItems;

            let strFrom = from ? from.toString() : "";
            let strTo = to ? to.toString() : "";
            let strTotItems = this.pagination.TotalItems ? this.pagination.TotalItems.toString() : "";
            let strCurrentPage = this.pagination.CurrentPage ? this.pagination.CurrentPage.toString() : "";
            let strTotPages = this.pagination.TotalPages ? this.pagination.TotalPages.toString() : "";

            if (strTotPages == "")
                return;

            return this.localeFactory.getFormatted(
                "paginazione_label", [
                    strFrom,
                    strTo,
                    strTotItems,
                    strCurrentPage,
                    strTotPages
                ]);
        }
    }

    export class dtFactory {
        private t: string;
        public tables: Array<DtTable>;
        private scope: ng.IScope;
        static $inject = ["$templateRequest", "$sce", "$compile", "localeFactory"];

        constructor(private $templateRequest: ng.ITemplateRequestService, private $sce: ng.ISCEService, private $compile: ng.ICompileService, private localeFactory: Localization.localeFactory) {
            this.tables = [];
        }

        private replaceAll(string: string, Find: string, Replace: string): string {
            try {
                return string.replace(new RegExp(Find, "gi"), Replace);
            } catch (ex) {
                return string;
            }
        }

        public evalTable(scope: ng.IScope, element: JQuery): void {
            //console.log("eval table");
            var vm = this;
            var forceEvalHeaders = false;
            if (element.attr("id") == undefined || element.attr("id") === "")
                element.attr("id", "dtTable-" + this.tables.length);
            //var exists = $.grep(this.tables, (item, index) => {
            //    return item.element.attr("id") === element.attr("id");
            //}).length > 0;
            //if (exists) {
            //    console.log("La tabella è già stata gestita, esco");
            //    return;
            //}
            $.each(this.tables, (index, item) => {
                if (item.element.attr("id") === element.attr("id")) {
                    vm.tables.splice(index, 1);
                    console.log("cancello la tabella", item.element.attr("id"));
                    forceEvalHeaders = true;
                    return;
                }
            });
            var t = new DtTable(scope, this, this.localeFactory, this.tables.length);
            t.element = element;
            t.table = element.attr("id");
            var or = element.attr("ng-ep-object-ricerca");
            t.objectRicerca = "vm." + ((or == undefined || or === "") ? "ricerca" : or);
            this.scope = scope;
            element.addClass("dataTable");
            element.find("tfoot").find(".form-control").each((id: number, elem: Element) => {
                if ($(elem).attr("ng-ep-noupdate") == undefined) {
                    if ($(elem).is("select")) {
                        $(elem).change((e: JQueryKeyEventObject) => {
                            vm.aggiornaRicerca(t.table);
                        });
                    }
                    else {
                        $(elem).keyup((e: JQueryKeyEventObject) => {
                            vm.aggiornaRicerca(t.table);
                        });
                    }
                }
            });

            // Controllo se è richiesta la paginazione
            var pTag = element.attr("ng-ep-pagination");
            var pagination = (pTag != undefined);
            if (pagination) {
                var vm = this;
                var pId = element.attr("id") + "-pagination-repo";
                element.parent().append("<div class='row' id='" + pId + "'></div>");
                var pTemplate = this.$sce.getTrustedResourceUrl(appRoot + "views/Pagination/pagination.html");
                this.$templateRequest(pTemplate).then(
                    result => {
                        var num = this.tables.length - 1;
                        result = this.replaceAll(result, ".dtFactory.", ".dtFactory.tables[" + num.toString() + "]."); 
                        this.$compile($("#" + pId).html(result).contents())(this.scope);
                    }
                );
            }
            //console.log("aggiungo la tabella", t);
            this.tables.push(t);
            if (forceEvalHeaders) {
                forceEvalHeaders = false;
                this.evalHeaders(scope, element.find("thead"));
            }
        }

        public aggiornaRicerca(idTabella: string = "", page: number = 1, retry: number = 0): void {
            var vm = this;
            if (this.tables.length <= 0) {
                if (retry < 4) {
                    setTimeout(function () {
                        vm.aggiornaRicerca(idTabella, page, retry++)
                    }, 500);
                }
                return;
            }
            
            var tId: number = -1;
            var t: DtTable;
            
            if (idTabella === "" || idTabella == undefined) {
                tId = 0;
                t = this.tables[0];
                
            }
            else {
                $.each(this.tables, (index: number, item: DtTable) => {
                    if (item.table === idTabella) {
                        tId = index;
                        t = item;
                        return;
                    }
                });
            }
            //console.log("La tabella è", t, t.element, t.dtSort, t.dtSortDir);
            if (t == undefined || t.element == undefined || t.dtSort == undefined || t.dtSortDir == undefined) {
                //console.log("ahi", t, tId, retry);
                if (retry < 4) {
                    retry = retry + 1;
                    setTimeout(function () {
                        vm.aggiornaRicerca(idTabella, page, retry++)
                    }, 500);
                }
                return;
            }
            if (t.dtSort != undefined && t.dtSortDir != undefined) {
                var func = "vm." + t.element.attr("ng-ep-table") + "('" + t.dtSort + "', " + t.dtSortDir.valueOf().toString() + ", " + JSON.stringify(this.getFilters(t)) + ", " + tId.toString() + ", " + page.toString() + ")";
                //.log("Ok funzione", func);
                this.scope.$eval(func);
            }
        }

        public evalResetForm(element: JQuery): void {
            var vm = this;
            var container = element.parent("form");
            var table = element.closest("table");
            var inlineReset = false;
            var func = "";
            // controllo le opzioni aggiuntive
            if (element.attr("ng-ep-reset-form").indexOf("|") > 0) {
                func = element.attr("ng-ep-reset-form").split("|")[0];
                var opt = element.attr("ng-ep-reset-form").split("|")[1];
                if (opt === "inline")
                    inlineReset = true;
            }
            else {
                func = element.attr("ng-ep-reset-form");
            }

            if (!inlineReset) {
                container.prepend("<div class='row'><div class='col-sm-12'><button id='ep-reset-form-" + table.attr("id") + "' type='button' class='btn btn-danger' title='Svuota tutti i campi di ricerca'>svuota campi di ricerca</button></div></div>");
                element.wrap("<div class='row'>").wrap("<div class='col-sm-12'>");
            }
            else {
                element.find("[ng-ep-inline-form-reset]").addClass("actions").addClass("vam").append("<a id='ep-reset-form-" + table.attr("id") + "' role='button' href='javascript:void(0)' title='Svuota tutti i campi di ricerca' class='text-danger'><i class='fa fa-ban'></i></a>");
            }

            $("#ep-reset-form-" + table.attr("id")).click((e: JQueryEventObject) => {
                vm.scope.$eval("vm." + func + "()");
                vm.aggiornaRicerca(table.attr("id"));
            });
        }

        public evalHeaders(scope: ng.IScope, element: JQuery, retry: number = 0): void {
            var vm = this;
            var idTabella = element.closest("table").attr("id");
            if (this.tables.length <= 0) {
                if (retry < 4) {
                    setTimeout(function () {
                        vm.evalHeaders(scope, element, retry++);
                    }, 300);
                }
                return;
            }
            var t: DtTable = idTabella === "" || idTabella == undefined ? this.tables[0] : $.grep(this.tables, (item: DtTable, index: number) => {
                return item.table === idTabella;
            })[0];
            if (t == undefined) {
                if (retry < 4) {
                    setTimeout(function () {
                        vm.evalHeaders(scope, element, retry++);
                    }, 300);
                }
                return;
            }
            if (t.columns == undefined) {
                t.columns = new Array<JQuery>();
            }
            //console.log("eval Headers " + t.table, t);
            element.find("th").each((id: number, elem: Element) => {
                if ($(elem).attr("ng-ep-model") != undefined) {
                    t.columns.push($(elem));
                }

                if ($(elem).attr("ng-ep-model") != undefined && ($(elem).attr("ng-ep-sort") == undefined || $(elem).attr("ng-ep-sort") === "true")) {
                    $(elem).addClass("sorting");
                    $(elem).click((event: JQueryEventObject) => {
                        if ($(elem).attr("ng-ep-model") === t.dtSort) {
                            t.dtSortDir = t.dtSortDir === Trace.ViewModels.DirezioneSort.Ascendente
                                ? Trace.ViewModels.DirezioneSort.Discendente
                                : Trace.ViewModels.DirezioneSort.Ascendente;
                        }
                        else {
                            t.dtSort = $(elem).attr("ng-ep-model");
                            t.dtSortDir = Trace.ViewModels.DirezioneSort.Ascendente;
                        }
                        
                        vm.changeSort(t);
                        vm.aggiornaRicerca(idTabella);
                    });
                    // Controllo se la colonna è quella ordinata di default ed inoltre la direzione del sort
                    if ($(elem).attr("ng-ep-default-sort") != undefined) {
                        var s = $(elem).attr("ng-ep-default-sort");
                        t.dtSort = $(elem).attr("ng-ep-model");
                        switch (s) {
                            case "asc":
                                t.dtSortDir = Trace.ViewModels.DirezioneSort.Ascendente;
                                $(elem).addClass("sorting_asc");
                                break;
                            
                            default:
                                t.dtSortDir = Trace.ViewModels.DirezioneSort.Discendente;
                                $(elem).addClass("sorting_desc");
                                break;
                        }
                        t.dtSortDir = s.toLowerCase() === "asc"
                            ? Trace.ViewModels.DirezioneSort.Ascendente
                            : Trace.ViewModels.DirezioneSort.Discendente;

                        this.changeSort(t);
                    }
                }
            });
        }

        private getFilters(t: DtTable): Array<Trace.ViewModels.RicercaRigaVm> {
            var ricerca = this.scope.$eval(t.objectRicerca);
            return this.generaOggettoRicerca(ricerca);
        }

        public generaOggettoRicerca(ricerca: any): Array<ViewModels.RicercaRigaVm> {
            var result = new Array<Trace.ViewModels.RicercaRigaVm>();
            for (var key in ricerca) {
                var r = new Trace.ViewModels.RicercaRigaVm();
                r.colonna = key;
                r.valore = ricerca[key];
                result.push(r);
            }

            return result;
        }

        private changeSort(t: DtTable): void {
            var vm = this;
            t.columns.forEach((item: JQuery, id: number) => {
                if (item.attr("ng-ep-model") !== t.dtSort) {
                    item.removeClass("sorting_asc").removeClass("sorting_desc").attr("title", "");
                }
                else {
                    var sortClass = t.dtSortDir === Trace.ViewModels.DirezioneSort.Ascendente ? "sorting_asc" : "sorting_desc";
                    var title = t.dtSortDir === Trace.ViewModels.DirezioneSort.Ascendente ? "asc" : "desc";
                    item.removeClass("sorting_asc").removeClass("sorting_desc").addClass(sortClass).attr("title", $(item).html() + " " + title);
                }
            });
        }
    }
}
app.factory("dtFactory", ($templateRequest, $sce, $compile, localeFactory) => new Trace.datatables.dtFactory($templateRequest, $sce, $compile, localeFactory));