app.factory("modelliSetFactory", ($http, $q) => new Trace.ModelliSet.modelliSetFactory($http, $q));
module Trace.ModelliSet {
    export class modelliSetFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/ModelliSet/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public lista(): ng.IPromise<Trace.Set.SetVm[]> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.get(workUrl)
                .success((data: Trace.Set.SetVm[], status) => {
                    deferred.resolve(data);
                })
                .error(error => {
                    deferred.reject(error);
                });

            return deferred.promise;
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, currentPage: number = undefined): ng.IPromise<Trace.Set.SetPaginationVm> {
            var workUrl = currentPage == undefined
                ? this.GetBaseUrl() + "Ricerca"
                : this.GetBaseUrl() + "Ricerca/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Trace.Set.SetPaginationVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public codiceSet(idCentroCosto: number): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + 'CodiceSet/' + idCentroCosto.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: string, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(set: Trace.Set.SetVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, set).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public modifica(set: Trace.Set.SetVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + set.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, set).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idModelloSet: number): ng.IPromise<Trace.Set.SetVm> {
            var workUrl = this.GetBaseUrl() + idModelloSet;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Trace.Set.SetVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public SimpleList(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "SimpleList";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public inUso(idModelloSet: number): ng.IPromise<boolean> {
            var workUrl = (<any>("{0}InUso/{1}")).format(this.GetBaseUrl(), idModelloSet);
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public cancella(idModelloSet: number): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + idModelloSet;
            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: any, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public clona(idModelloSet: number): ng.IPromise<Trace.Set.SetVm> {
            var workUrl = this.GetBaseUrl() + "Clona";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idModelloSet).success((data: Trace.Set.SetVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public spezza(idModelloSet: number): ng.IPromise<Trace.Set.SetVm> {
            var workUrl = this.GetBaseUrl() + "Spezza";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idModelloSet).success((data: Trace.Set.SetVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public nuovaRevisione(idModelloSet: number, dataInizioValidita: Date): ng.IPromise<number> {
            var workUrl = this.GetBaseUrl() + "NuovaRevisione";
            var nuovaRevisione = new NuovaRevisioneVm();
            nuovaRevisione.Id = idModelloSet;
            nuovaRevisione.DataInizioValidita = dataInizioValidita;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, nuovaRevisione).success((data: Trace.Set.SetVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public ImportExcel(tipo: string, nome: string): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "ImportExcel/" + tipo;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, nome).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ReadExcel(item: ViewModels.ReadExcelItem): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "ReadExcel";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, item).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetGiacenzeProdotti(idModello: number): ng.IPromise<ModelliSet.GiacenzaProdottoModelloSetVm[]> {
            var workUrl = this.GetBaseUrl() + "GetGiacenzeProdotti/" + idModello.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ExportExcelProduzioneSet(idModello: number, produzioni: Produzione[]): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "ExportExcelProduzioneSet/" + idModello;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, produzioni).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}