module Trace.Movimenti {
    export class MovimentoDetailVm implements MovimentoDetail {
        public Id: number;
        public DataCreazione: Date;
        public IdDocumento: number;
        public IdDocumentoDetail: number;
        public IdOrdine: number;
        public IdOrdineDetail: number;
        public IdMovimento: number;
        public IdProdotto: number;
        public IdStrumento: number;
        public IdRot: number;
        public IdSet: number;
        public IdTnt: number;
        public Qta: number;
        public Uid: string;
        public Lotto: string;
        public DataScadenzaLotto: Date;
        public Matricola: string;
        //public LottoMateriale: string;
    }
}