module Trace.Reports {
    export class reportsController extends epBaseController  {
        private formScadenze: any;
        public gruppi: Trace.Gruppo.Gruppo[];
        public sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];
        public listaScadenze: Array<Trace.Set.ScadenzaSet>;
        private filtro: Trace.Scadenze.FiltroScadenzeVm;
        static $inject = ['$scope', 'dtFactory', 'setFactory', 'localeFactory', 'msgFactory', '$uibModal', 'tracciabilitaFactory', 'gruppiFactory', 'sottoGruppiFactory', 'sediFactory', 'centriCostoFactory'];

        constructor(
            private $scope: ng.IScope,
            private dtFactory: Trace.datatables.dtFactory,
            public setFactory: Trace.Set.setFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory,
            private gruppiFactory: Gruppo.gruppiFactory,
            private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory
        )
        {
            super(undefined, undefined, setFactory, undefined, undefined, localeFactory, msgFactory, $uibModal, null, tracciabilitaFactory);
            this.formScadenze = {};
            this.getGruppi();
            this.setUpWatchers();
        }

        private getGruppi(): void {
            this.gruppiFactory.lista().then(
                result => {
                    this.gruppi = result;
                }
            );
        }

        private getSottogruppi(idGruppo: number): void {
            this.sottoGruppiFactory.listaGruppo(idGruppo).then(
                result => {
                    this.sottoGruppi = result;
                }
            );
        }

        private getSedi(idSottogruppo: number): void {
            this.sediFactory.listaSottoGruppo(idSottogruppo).then(
                result => {
                    this.sedi = result;
                }
            );
        }

        private getCentriCosto(idSede: number): void {
            this.centriCostoFactory.listaSede(idSede).then(
                result => {
                    this.centriCosto = result;
                }
            );
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.filtro.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppi = undefined;
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSottogruppi(newVal);
            });
            this.$scope.$watch("vm.filtro.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSedi(newVal);
            });
            this.$scope.$watch("vm.filtro.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCosto = undefined;
                else this.getCentriCosto(newVal);
            });
        }

        private submit(): void {
            var vm = this;
            this.isSaving = true;
            this.filtro.DataDa = $("#formScadenze #dataDa").datepicker('getDate');
            this.filtro.DataA = $("#formScadenze #dataA").datepicker('getDate');
            console.log("questo è il filtro da applicare", this.filtro);
            this.setFactory.ScadenzeSet(this.filtro).then(
                result => {
                    this.listaScadenze = result;
                    this.isSaving = false;
                },
                reason => {
                    var errore: string = reason == undefined ? vm.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_scadenze_errore", [errore]));
                    this.isSaving = false;
                }
            );
        }

        private setUpFiltro(): void {
            setTimeout(() => {
                $(".datetime").datepicker();
            }, 1000);
        }

        private numeroScadenze(): string {
            if (this.listaScadenze == undefined) return "";
            var dal = $("#formScadenze #dataDa").datepicker('getDate');
            var al = $("#formScadenze #dataA").datepicker('getDate');
            var strDal = (dal == undefined) ? "" : " dal " + moment(dal).format("DD/MM/YYYY");
            var strAl = (al == undefined) ? "" : " al " + moment(al).format("DD/MM/YYYY");
            return this.listaScadenze.length + " SET in scadenza" + strDal + strAl;
        }

        private getExportUrl(tipo: string = 'excel'): string {
            var url = tipo == 'excel' ? 'ExportScadenzeSet' : 'ExportPdfScadenzeSet';
            var result = `${appRoot}Reports/${url}?strFiltro=${JSON.stringify(this.filtro)}`;
            console.log("ecco il link", result);
            return result;
        }
    }
}
app.controller("reportsController", Trace.Reports.reportsController);