module Trace.Flusso {
    export class flussoVm {
        public Id: number;
        public Codice: string;
        public Descrizione_it: string;
        public Descrizione_en: string;
        public Descrizione_al: string;
        public Note_it: string;
        public Note_en: string;
        public Note_al: string;

        public DataObsoleto: Date;
        public TipoSet: string;
        public Centrale: string;
        public IdTipoSet: number;
        public IdCentrale: number;

        public Distinta: flussoDetailVm[];
    }
}