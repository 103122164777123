
module Trace.Cespiti { 
    export class RicercaCategorie {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
    }

    export class cespitiCategorieController {
        public lista: Trace.Cespiti.CategorieCespiti[];
        public recordCorrente: Trace.Cespiti.CategorieCespiti;
        public testoAzione: string;
        public ricerca: Trace.Cespiti.RicercaCategorie;

        public currentState: string;
        public titoloManipola: string;

        public showValidationMessages: boolean;
        public formCategorie: any;

        public currentPage: number;

        static $inject = ['$scope', 'cespitiFactory', 'localeFactory', 'msgFactory', 'dtFactory'];
        constructor(private $scope: ng.IScope, private cespitiFactory: Trace.Cespiti.cespitiFactory,
            private localeFactory: Localization.localeFactory, private msgFactory: Messages.msgFactory,
            private dtFactory: datatables.dtFactory) {
            //this.annulla();
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formCategorie.$valid) {
                this.showValidationMessages = true;
                this.formCategorie.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    if (this.recordCorrente.Id == undefined)
                        this.cespitiFactory.inserisciCategoria(this.recordCorrente).then((result => {
                            this.annulla();
                        }));
                    else
                        this.cespitiFactory.modificaCategoria(this.recordCorrente).then((result => {
                            this.annulla();
                        }));
                }
            });
        }

        public annulla(): void {
            this.recordCorrente = <Trace.Cespiti.CategorieCespiti>{};
            this.testoAzione = this.localeFactory.get('button_inserisci_categorie');
            this.ricerca = new Trace.Cespiti.RicercaCategorie();

            this.cespitiFactory.listaCategorie().then((risultato: Trace.Cespiti.CategorieCespiti[]) => {
                this.lista = risultato;
                this.changeState('list');
            });
        }

        public cancella(id: number): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.cespitiFactory.cancellaCategoria(id).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        public modifica(id: number): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica_categorie");
            this.cespitiFactory.leggiCategoria(id).then((p: Trace.Cespiti.CategorieCespiti) => {
                this.recordCorrente = p;
                this.testoAzione = this.localeFactory.get('button_modifica_categorie');
                this.changeState('manipola');
            });
        }

        public nuovo(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo_categorie");
            this.recordCorrente = <Trace.Cespiti.CategorieCespiti>{};
            this.changeState("manipola");
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.cespitiFactory.ricercaCategorie(ricerca, page).then((risultato: CategorieCespitiPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.lista = risultato.List;
            });
        }

        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo_categorie");
            this.recordCorrente = <Trace.Cespiti.CategorieCespiti>{};
            this.testoAzione = this.localeFactory.get('button_inserisci_categorie');
            this.changeState('manipola');
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formCategorie.$setPristine();
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new Trace.Cespiti.RicercaCategorie();
        }
    }
}
app.controller("cespitiCategorieController", Trace.Cespiti.cespitiCategorieController);