app.factory("interventoFactory", ($http, $location, $q) => new Trace.Intervento.interventoFactory($http, $location, $q));
module Trace.Intervento {
    export class interventoFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Intervento/";
        }

        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public RegistraIntervento(intervento: IInterventoVm): ng.IPromise<IInterventoVm> {
            var workUrl = this.GetBaseUrl() + "RegistraIntervento";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, intervento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public InterventiAccettazione(interventi: Array<number>): ng.IPromise<IInterventoVm[]> {
            var workUrl = this.GetBaseUrl() + "InterventiAccettazione";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, interventi).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RemoveItem(idItem: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "RemoveItem";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idItem).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AddMateriale(idIntervento: number, materiale: Produzione.Materiale): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AddMateriale/" + idIntervento.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, materiale).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetInterventiDaScansioni(): ng.IPromise<Intervento.IInterventoVm[]> {
            var workUrl = this.GetBaseUrl() + "GetInterventiDaScansioni";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AggiornaComplessita(interventi: Array<Trace.Helpers.SimpleEntity>): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AggiornaComplessita";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, interventi).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ScaricaMateriali(interventi: Array<Trace.Helpers.SimpleEntity>): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "ScaricaMateriali";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, interventi).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Medici(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "Medici";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public MediciPerNome(): ng.IPromise<string[]> {
            var workUrl = this.GetBaseUrl() + "MediciPerNome";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}