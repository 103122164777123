/// <reference path="../../../org.epartner.trace.core/viewmodels/produzione/cambiostatoresult.cs.d.ts" />
/// <reference path="../../../org.epartner.trace.core/viewmodels/produzione/lottoproduzionevm.cs.d.ts" />
app.factory("lavaggioFactory", ($http, $location, $q) => new Trace.Lavaggio.lavaggioFactory($http, $location, $q));
module Trace.Lavaggio {
    export class lavaggioFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Lavaggio/";
        }

        public Lista(): ng.IPromise<Produzione.LottoProduzioneVm[]> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}