module Trace.Components {
    export class listRowController {
        public mov: Movimenti.MovimentoVm;
        public lotto: Produzione.LottoProduzioneVm;
        public carico: Trace.Produzione.CaricoMacchinaVm;
        public strumento: Set.StrumentoVm;
        public azioni: Array<Produzione.AzioneVm>;
        public griglie: Array<Produzione.GrigliaStrumentiVm>;
        public idTipoSet: number;
        public idFase: number;
        static $inject = ["localeFactory", "msgFactory"];


        constructor(public localeFactory: Localization.localeFactory, public msgFactory: Trace.Messages.msgFactory) {
            var vm = this;
        }

        public descrizioneLavaferri(): string {
            //console.log("CARICO", this.carico);
            if (this.carico == undefined) return;
            var nomeSet = this.descrizioneLotto();
            if (this.carico.NumGriglia === 0) {
                var copia = this.carico.Codice.substr(11, 2);
                return "[" + this.localeFactory.get('container') + "] - " + nomeSet;
            }
            var codice = this.carico.Codice.substring(0, this.carico.Codice.length - 1);
            var suffisso = this.carico.Codice.substring(this.carico.Codice.length - 1);
            var copia = this.carico.Codice.substr(5, 2);
            var descr = (this.lotto.IdTipoSet == 4 || this.lotto.IdTipoSet == 9)
                ? codice + suffisso + " - " + nomeSet
                : (this.carico.FlgAggiunta)
                    ? codice + suffisso + " - " + nomeSet + " " + this.localeFactory.get("copia") + " " + copia
                    : codice + "[" + suffisso + "] - " + nomeSet + " " + this.localeFactory.get("copia") + " " + copia;
            //console.log("descr. lavaferri", descr, this.lotto.IdTipoSet);
            return descr;
        }

        public startDrag(e: JQueryEventObject) {
            $(e.currentTarget).addClass("grabbing");
        }

        public stopDrag(e: JQueryEventObject) {
            $(e.currentTarget).removeClass("grabbing");
        }

        public descrizioneLotto(): string {

            if (this.lotto == undefined) return "";
            //console.log(this.lotto.Descrizione_it_IT, this.lotto.IdTipoSet);
            var totAssoc = this.lotto.NumContainerAssociati + 1;
            var numAssoc = this.lotto.Codice.substr(13, 1)

            //console.log("descrizioneLotto()", this.lotto);
            //console.log("dbCulture", dbCulture);
            var descr = (this.lotto.IdTipoSet == 4 || this.lotto.IdTipoSet == 9)
                ? this.lotto["Descrizione_" + dbCulture]
                : this.lotto["Descrizione_" + dbCulture] + " " + this.localeFactory.get("copia") + " " + this.lotto.Codice.substr(11, 2) + " " + numAssoc.toString() + "/" + totAssoc.toString();


            //console.log("descr", descr);

            
            return descr;
        }

        public descrizioneStrumento(): string {
            var result = this.strumento["Descrizione" + culture.capitalize()];
            if (this.strumento.HasDistinta) {
                result += "<span class='help-block big'><i class='fa fa-circle text-danger'></i> " + this.localeFactory.get("prodotto_con_distinta") + "</span>";
            }

            return result;
        }

        public callFunction(item: Produzione.AzioneVm): void {
            if (this.lotto != undefined) {
                //console.log("LOTTO", this.lotto);
                triggerProduzioneEvent("listRowAction", this.lotto, item.funzione);
            }
            else {
                //console.log("MOVIMENTO", this.mov);
                triggerMovimentoEvent("listRowMovAction", this.mov, item.funzione);
            }
        }

        public callStrumentoFunction(item: Produzione.AzioneVm): void {
            triggerStrumentoEvent("strumentoAction", this.strumento, item.funzione);
        }

        public showButton(item: Produzione.AzioneVm): boolean {
            if (this.lotto.FlgUrgenza && item.funzione === "setUrgenza") return false;
            if (this.lotto != undefined || this.strumento == undefined) return true;
            if (item.funzione == "notePrecauzioniStrumento")
                return (this.strumento.Note != undefined && this.strumento.Note !== "") || (this.strumento.Precauzioni != undefined && this.strumento.Precauzioni !== "");
            if (item.funzione == "dististaStrumento")
                return this.strumento.HasDistinta;
            return true;
        }

        public showFlagEseguito(item: Produzione.TrattamentoVm): boolean {
            return (item.idTrattamento == 1 && this.strumento.FlgUltrasuoni) ||
                (item.idTrattamento == 2 && this.strumento.FlgLavaggioManuale);
        }

        public showFlagAnomalia(item: Produzione.TrattamentoVm): boolean {
            return (item.idTrattamento == 1 && this.strumento.FlgGuasto) ||
                (item.idTrattamento == 2 && this.strumento.FlgMancante) ||
                (item.idTrattamento == 3 && this.strumento.FlgEsaurimento);
        }

        public mouseDown(element: string): void {
            triggerProduzioneEvent("mouseDownProduzione", this.lotto, element);
        }

        public mouseDownSet(lotto: Produzione.LottoProduzioneVm): void {
            triggerProduzioneEvent("mouseDownProduzione", lotto, "");
        }

        public mouseDownGriglia(griglia: Produzione.GrigliaVm, elem: string): void {
            if (griglia == undefined) {
                griglia = new Produzione.GrigliaVm();
                griglia.Codice = this.carico.Codice;
                griglia.FlgAggiunta = this.carico.FlgAggiunta;
                griglia.Id = this.carico.IdGriglia;
                griglia.IdCentrale = this.carico.IdCentrale;
                griglia.NumGriglia = this.carico.NumGriglia;
            }
            triggerGrigliaEvent("mouseDownGriglia", griglia, elem);
        }

        public checkContati(): void {
            console.log("checkContati");
            var vm = this;
            vm.strumento.DataClickConfezionamento = new Date();
            //console.log('checkContati()' + vm.strumento.QtaSostituiti);

            if (vm.strumento.FlgGuasto || vm.strumento.FlgMancante || vm.strumento.FlgEsaurimento) {
                if (vm.strumento.QtaSostituiti) {
                    this.msgFactory.confirm("Stai tornando indietro da una marcatura come guasto, mancante o esaurimento. Il sostituo verrà rimosso. Vuoi continuare?").then(
                        result => {
                            if (!result.result)
                                return;

                            vm.strumento.FlgGuasto = false;
                            vm.strumento.QtaGuasto = 0;

                            vm.strumento.FlgMancante = false;
                            vm.strumento.QtaMancante = 0;


                            vm.strumento.Qta = 1;
                            vm.strumento.QtaContati = 1;
                            vm.strumento.FlgContato = true;

                            // TOGLI STRUMENTO
                            triggerEvent("cancellaLottoDetailSostituito", null, vm.strumento.Id);
                        }
                    );
                } else {
                    vm.strumento.FlgGuasto = false;
                    vm.strumento.QtaGuasto = 0;

                    vm.strumento.FlgMancante = false;
                    vm.strumento.QtaMancante = 0;

                    vm.strumento.FlgEsaurimento = false;
                    vm.strumento.QtaEsaurimento = 0;

                    vm.strumento.Qta = 1;
                    vm.strumento.QtaContati = 1;
                    vm.strumento.FlgContato = true;
                }
            } else {
                // Se non sei in questi casi allora devo semplicemente invertire i flg e qta
                vm.strumento.FlgContato = !vm.strumento.FlgContato
                vm.strumento.QtaContati = vm.strumento.FlgContato ? 1 : 0
            }
        }

        // #LUCA
        public cancellaLottoDaIntervento(idLotto: number) {
            triggerEvent("cancellaLottoDaIntervento", null, idLotto);
        }

        // #LUCA
        public gestioneEscludiProduzione(lotto: any) {
            triggerEvent("gestioneEscludiProduzione",lotto);
        }

        //public setUpGriglie(): void {
        //    if (this.lotto == undefined) return;
        //    this.griglie = new Array<Produzione.GrigliaStrumentiVm>();
        //    var ng = this.lotto.NumGriglie;
        //    console.log("numero griglie", ng);
        //    for (var i = 1; i <= ng; i++) {
        //        var g = new Produzione.GrigliaVm();
        //        g.IdLotto = this.lotto.Id;
        //        g.NumGriglia = i;
        //        this.griglie.push();
        //    }
        //}

        public spaziGriglie(): string {
            var result = Math.floor(12 / this.lotto.Griglie.length);
            return result.toString();
        }

        public motivoFermo(): string {
            return this.localeFactory.getFormatted("motivo_fermo", [this.strumento.DescrStato])
        }

        public formatDate(data: Date): string {
            var result = moment(data).format("DD/MM/YYYY");
            return result;
        }
    }
}
app.controller("listRowController", Trace.Components.listRowController);

app.component("listRowAccettazione", {
    templateUrl: "/views/Accettazione/listRow.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        azioni: "=",
        selezionato: "=",
        causali: "=",
        rowid: "="
    }
});

app.component("listRowMacchineAnalisi", {
    templateUrl: "/views/Autoclavi/listRowAnalisi.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        azioni: "=",
        selezionato: "=",
        causali: "=",
        rowid: "="
    }
});

app.component("listRowMacchine", {
    templateUrl: "/views/Autoclavi/listRow.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        azioni: "=",
        selezionato: "=",
        causali: "=",
        rowid: "="
    }
});

app.component("listRowMacchineLavaggio", {
    templateUrl: "/views/Lavaferri/listRow.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        azioni: "=",
        selezionato: "=",
        causali: "=",
        rowid: "="
    }
});

app.component("listRowMacchineLavaferriAnalisi", {
    templateUrl: "/views/Lavaferri/listRowAnalisi.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        carico: "<",
        azioni: "=",
        selezionato: "=",
        causali: "=",
        rowid: "="
    }
});

app.component("listRowMacchineLavaferri", {
    templateUrl: "/views/Lavaferri/listRowMacchina.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        carico: "<",
        azioni: "=",
        selezionato: "=",
        causali: "=",
        rowid: "="
    }
});

app.component("listRowLavaggio", {
    templateUrl: "/views/Lavaggio/listRow.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        azioni: "=",
        selezionato: "=",
        rowid: "="
    }
});

app.component("listRowPackaging", {
    templateUrl: "/views/Packaging/listRow.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        azioni: "=",
        selezionato: "=",
        rowid: "=",
        enabled: "<" 
    }
});

app.component("listRowCreazioneDdt", {
    templateUrl: "/views/CreazioneDdt/listRow.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        lotto: "<",
        azioni: "=",
        selezionato: "=",
        rowid: "=",
        enabled: "<",
        mov: "<"
    }
});

app.component("strumentoLavaggio", {
    templateUrl: "/views/Lavaggio/strumento.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        strumento: "<",
        azioni: "=",
        trattamenti: "<",
        selezionato: "=",
        rowid: "="
    }
});

app.component("strumentoConfezionamento", {
    templateUrl: "/views/Confezionamento/strumento.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        strumento: "<",
        azioni: "=",
        trattamenti: "<",
        selezionato: "=",
        rowid: "=",
        idTipoSet: "="
    }
});

app.component("strumentoPackaging", {
    templateUrl: "/views/Packaging/strumento.html",
    controller: "listRowController",
    controllerAs: "vm",
    bindings: {
        strumento: "<",
        azioni: "=",
        trattamenti: "<",
        selezionato: "=",
        rowid: "=",
        idTipoSet: "="
    }
});