module Trace.GestioneManutenzioniRientriCentrale {
    export class gestioneManutenzioniRientriCentraleController extends Movimenti.epMovimentiController {
        public ricerca: Trace.GestioneManutenzioni.RicercaGestioneManutenzioniVm;
        public formDettagliManutenzione: any;
        public manutenzioni: Set.StrumentoVm[];
        public tipiManutenzione: Helpers.SimpleEntity[];
        public manutenzioniToSend: Set.StrumentoVm[]; 
        public manutenzione: Trace.Manutenzioni.ManutenzioneDetailsVm;
        public giorniManutenzione: string;


        static $inject = ["$rootScope", "$scope", "gestioneManutenzioniRientriCentraleFactory", "dtFactory", "movimentiFactory", "produzioneFactory",
            "documentoFactory", "setFactory", "prodottiFactory", "depositoFactory", "utenteFactory", "localeFactory", "msgFactory", "$uibModal",
            "gruppiFactory", "sottoGruppiFactory", "sediFactory", "centriCostoFactory", "tracciabilitaFactory"];

        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private gestioneManutenzioniRientriCentraleFactory: GestioneManutenzioniRientriCentrale.gestioneManutenzioniRientriCentraleFactory,
            public dtFactory: Trace.datatables.dtFactory,
            public movimentiFactory: Movimenti.movimentiFactory,
            private produzioneFactory: Produzione.produzioneFactory,
            public documentoFactory: Documento.documentoFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public depositoFactory: Deposito.depositoFactory,
            public utenteFactory: Utente.utenteFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public gruppiFactory: Gruppo.gruppiFactory,
            public sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            public sediFactory: Sede.sediFactory,
            public centroCostoFactory: CentroCosto.centroCostoFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($scope, $rootScope, $uibModal, dtFactory, localeFactory, msgFactory, setFactory, prodottiFactory, undefined,
                depositoFactory, utenteFactory, movimentiFactory, undefined, documentoFactory,
                gruppiFactory, sottoGruppiFactory, sediFactory, centroCostoFactory, tracciabilitaFactory);

            this.formDettagliManutenzione = {};
        }

        public init(): void {
            this.changeState('list');
            this.manutenzioniToSend = [];
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            this.gestioneManutenzioniRientriCentraleFactory.ricerca(ricerca, 1).then(
                risultato => {
                    var r = risultato;
                    console.log(risultato);
                    this.manutenzioni = r.List;
                }
            );
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.gestioneManutenzioniRientriCentraleFactory.ricerca(ricerca, page).then(
                risultato => {
                    var r = risultato;
                    console.log(risultato);
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                    this.manutenzioni = r.List;
                }
            );
        }

        public resetForm(): void {
            this.ricerca = new Trace.GestioneManutenzioni.RicercaGestioneManutenzioniVm();
        }

        public annullaManutenzione(strumento: Set.StrumentoVm): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("annulla_manutenzione_conferma", [strumento.Codice, strumento.Uid, this.localized(strumento, "Descrizione")])).then(
                result => {
                    if (!result.result) return;
                    this.doAnnullaManutenzione(strumento.Id);
                }
            );
        }

        private doAnnullaManutenzione(id: number): void {
            this.produzioneFactory.AnnullaManutenzione(id).then(
                result => {
                    this.msgFactory.alert("annulla_manutenzione_ok");
                    this.dtFactory.aggiornaRicerca();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }
        
        public changeState(stato: string): void {
            super.changeState(stato);
            switch (stato) {
                case "list":
                    this.giorniManutenzione = null;
                    this.strumentoSelezionato = null;
                    break;
                case "manipola":
                    var strumento = this.localized(this.manutenzione.Strumento, "Descrizione");
                    this.titoloManipola = this.localeFactory.getFormatted("dettagli_strumento", [strumento]);
                    break;
            }
        }

        public addToListaManutenzioni(strumento: Set.StrumentoVm) {
        
            if (this.manutenzioniToSend.some((item) => item.Id === strumento.Id)) {
                this.manutenzioniToSend = this.manutenzioniToSend.filter(item => item.Id !== strumento.Id);
                return;
            }

            this.manutenzioniToSend.push(strumento);
        }

        public inListaManutenzione(id: number): boolean {
            return this.manutenzioniToSend.some((item) => item.Id === id);
        }

        public salvaManutenzioniRientri() {
            this.msgFactory.confirm("Confermare il rientro al Transit Point?", "Conferma il rientro al Transit Point").then(
                result => {
                    if (!result.result)
                        return;

                    this.isSaving = true;
                    this.gestioneManutenzioniRientriCentraleFactory.rientroManutenzione(this.manutenzioniToSend).then(
                        result => {
                            this.manutenzioniToSend = [];
                            this.isSaving = false;
                            this.msgFactory.alert("Strumenti rientrati nelle Centrali con successo", "Rientro Centrale Effettuato");
                            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
                            this.gestioneManutenzioniRientriCentraleFactory.ricerca(ricerca, 1).then(
                                risultato => {
                                    var r = risultato;
                                    console.log(risultato);
                                    this.manutenzioni = r.List;
                                }
                            );
                        }
                    )
                }
            )
        }

        public annullaManutenzioniToSend() {
            this.msgFactory.confirm("Annullare l'invio?", "Annulla invio manutenzione").then(
                result => {
                    if (result.result)
                        this.manutenzioniToSend = [];
                }
            );
        }
        
    }
}
app.controller("gestioneManutenzioniRientriCentraleController", Trace.GestioneManutenzioniRientriCentrale.gestioneManutenzioniRientriCentraleController);