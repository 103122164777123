app.factory("richiesteApprovvigionamentiFactory", ($http, $location, $q) => new Trace.RichiesteApprovvigionamenti.richiesteApprovvigionamentiFactory($http, $location, $q));

module Trace.RichiesteApprovvigionamenti {
    export class richiesteApprovvigionamentiFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/RichiesteApprovvigionamenti/";
        }

        static $inject = ["$http", "$location", "$q"];

        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, currentPage: number = undefined): ng.IPromise<ViewModels.GenericPaginationVm<Movimenti.MovimentoVm>> {
            var workUrl = this.GetBaseUrl() + "Ricerca/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public leggi(id: number): ng.IPromise<Movimenti.MovimentoVm> {
            var workUrl = this.GetBaseUrl() + id;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Accetta(movimento: Movimenti.MovimentoVm): ng.IPromise<Movimenti.MovimentoVm> {
            var workUrl = this.GetBaseUrl() + "Accetta/" + movimento.Id;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, movimento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}