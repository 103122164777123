module Trace.Produzione {
    export class CaricoMacchinaVm implements CaricoMacchina {
        public LottoProduzione: LottoProduzioneVm;
        public IdCentrale: number;
        public IdGriglia: number;
        public NumGriglia: number;
        public FlgAggiunta: boolean;
        public Codice: string;
        public CodiceAlternativo: string;
        constructor(public IdTipoMacchina: number, public IdMacchina: number, public IdLottoProduzione: number, public Enabled?: boolean) { }
    }
}