namespace Trace.Utente {
    export class UtenteVm {
        public Id: number;
        public Email: string;
        public DataCreazione: Date;
        public Nome: string;
        public Cognome: string;
        public RoleId: string;
        public Ruolo: string;
        public Gruppo: string;
        public Sottogruppo: string;
        public Sede: string;
        public CentroCosto: string;
        public IdGruppo: number;
        public IdSottoGruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public Username: string;
        public Password: string;
        public Conferma: string;
        public Immagine: ViewModels.ImmagineVm;
        public Telefono: string;
        public IdLingua: number;
        public Sesso: string;
        public FlgCollegamentoCentrali: boolean;
        public FlgCollegamentoDepositi: boolean;
        public Centrali: Array<number>;
        public Depositi: Array<number>;
        public Centrale: string;
        public IdCentrale: number;
        public Deposito: string;
        public IdDeposito: number;
        public IdSessione: string;   
        public IdUtenteTimeMoto: number;
        public FlgMappa: boolean;
        public IdTipoUtente: number;
        public FlgAffiancamento: boolean;
        public CodiceUtenteFilePaghe: string;
    }

    export class UtenteTraceTimeMoto {
        public Id: number;
        public Username: string;
        public Nome: string;
        public Cognome: string;
        public IdTimeMoto: number;
        public UsernameTimeMoto: string;
        public NomeTimeMoto: string;
        public CognomeTimeMoto: string;
    }

    export class AnalisiDiscordanzeUtenti {
        public TotaleUtenti: number;
        public TotaleUtentiTimeMoto: number;
        public SoloTrace: UtenteTraceTimeMoto[];
        public SoloTimeMoto: UtenteTraceTimeMoto[];
        public Discordanze: UtenteTraceTimeMoto[];
        public Associati: UtenteTraceTimeMoto[];
    }

    export class UtenteTraceFilePaghe {
        public Id: number;
        public Username: string;
        public Nome: string;
        public Cognome: string;
        public CodiceFilePaghe: string;
        public UtenteFilePaghe: string;
    }

    export class AnalisiDiscordanzeUtentiFilePaghe {
        public TotaleUtenti: number;
        public TotaleUtentiFilePaghe: number;
        public SoloTrace: UtenteTraceFilePaghe[];
        public SoloFilePaghe: UtenteTraceFilePaghe[];
        public Discordanze: UtenteTraceFilePaghe[];
        public Associati: UtenteTraceFilePaghe[];
    }
}
