module Trace.Analisi {
    export class FiltroProduttivita implements FiltroProduttivitaVm {
        DataRiferimentoDal: Date;
        StrDataRiferimentoDal: string;
        DataRiferimentoAl: Date;
        StrDataRiferimentoAl: string;
        IdGruppo: number;
        IdSottoGruppo: number;
        IdSede: number;
        IdCentroCosto: number;
        IdTipoSet: number;
        IdCentrale: number;
        IdComplessita: number;
        IdUtente: number;
        IdModelloSet: number;
        IdFaseProduzione: number;
    }

    export class FiltroProduttivitaCentrale implements FiltroProduttivitaCentraleVm {
        DataRiferimentoDal: Date;
        StrDataRiferimentoDal: string;
        DataRiferimentoAl: Date;
        StrDataRiferimentoAl: string;
        IdCentrale: number[];
    }
    export class FiltroProdCentraliMensile implements FiltroProdCentraliMensileVm{
        AnnoRiferimento: number;
        MeseRiferimento: number;
    }

    export class FiltroProduttivitaMensile implements FiltroProduttivitaMensileVm {
        AnnoRiferimento: number;
        IdCentrale: number;
    }

    export class FiltroAnalisiPresenze implements FiltroAnalisiPresenzeVm {
        DataRiferimentoDal: Date;
        StrDataRiferimentoDal: string;
        DataRiferimentoAl: Date;
        StrDataRiferimentoAl: string;
        IdUtente: number[];
    }
}
