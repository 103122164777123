module Trace.Tnt { 
    export class RicercaTnt {
        public Codice: string;
        public Descrizione_it: string;
        public Descrizione_en: string;
        public Descrizione_al: string;
        public IdTipo: number;
    }

    export class tntController extends epBaseController {
        public lista: tntVm[];
        public recordCorrente: fullTntVm;
        public testoAzione: string;
        public tipi: Helpers.SimpleEntity[];
        public tipiRicerca: TipoTnt[];
        public ricerca: RicercaTnt;
        public dettaglioCorrente: TntDetail;
        public prodotti: Prodotto.ProdottoVm[];
        public testoAzioneDettaglio: string;
        public listaDettagli: tntDetailVm[];
        public isSaving: boolean;
        public notCoded: Prodotto.DistintaVm;

        public ricercaDistinta: Prodotto.RicercaProdotti;
        public distintaTnt: Prodotto.ProdottoVm[];
        public tipiProdotti: Prodotto.TipoProdottoVm[];
        public raggruppamenti: Helpers.SimpleEntity[];

        public showValidationMessages: boolean;
        public formTnt: any;
        public gestioneNotCoded: boolean;

        public currentState: string;
        public titoloManipola: string;


        static $inject = ['$scope', 'tntFactory', 'prodottiFactory', 'localeFactory', 'msgFactory',
            'Upload', 'dtFactory', '$uibModal', "utenteFactory", "tracciabilitaFactory"];
        constructor(
            private $scope: ng.IScope,
            public tntFactory: tntFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            private dtFactory: datatables.dtFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        )
        {
            super(Upload, utenteFactory, null, prodottiFactory, tntFactory, localeFactory, msgFactory, $uibModal, null, tracciabilitaFactory);
            var vm = this;
            tntFactory.listaTipiAsSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.tipi = risultato;
            });

            tntFactory.listaRaggruppamenti().then(
                result => {
                    this.raggruppamenti = result;
                });

            this.formTnt = {};
            tntFactory.listaTipi().then((risultato: TipoTnt[]) => {
                this.tipiRicerca = risultato;
                var dummy: TipoTnt = new TipoTnt();
                dummy.Descrizione_it_IT = '';
                this.tipiRicerca.unshift(dummy);
            });
            // Voglio solo i prodotti TNT
            var ric = new ViewModels.RicercaVm();
            ric.colonne = new Array<ViewModels.RicercaRigaVm>();
            var c = new ViewModels.RicercaRigaVm();
            c.colonna = "IdTipo";
            c.valore = "3";
            ric.colonne.push(c);
            prodottiFactory.ricerca(ric).then((risultato: Prodotto.ProdottoVm[]) => {
                this.prodotti = risultato;
                var dummy: Prodotto.ProdottoVm = new Prodotto.ProdottoVm();
                dummy.Descrizione_it_IT = "";
                this.prodotti.unshift(dummy);
            });
            prodottiFactory.tipiProdotto().then((risultato: Prodotto.TipoProdottoVm[]) => {
                this.tipiProdotti = risultato;
            });
            $(document).on("imageUpload", (e: JQueryEventObject) => {
                vm.recordCorrente.Immagini.push(e.image);
                vm.formTnt.$setDirty();
            });
            this.changeState('list');
        }

        public init(): void {
            
            var vm = this;
            $(document).on("gotUserSession", (e: JQueryEventObject) => {
                if (idTnt == null)
                    vm.changeState('list');
                else
                    vm.modifica(idTnt);
            });
        }

        /**
         * 23/04/2020 - Stampa delle etichette
         */
        private etichetta: any;
        private formEtichetta: any;
        private stampaEtichetta() {
            // Stampa in caso di TNT NON STERILE
            if (this.recordCorrente.IdTipoTnt !== 2) {
                if (this.recordCorrente.QtaScatola == undefined) {
                    alert("Prima di stampare l'etichetta, impostare la quantità per scatola");
                    return;
                }
                var url = appRoot + "Reports/EtichettaTnt?idTnt=" + this.recordCorrente.Id;
                window.open(url, "_blank");
            }
            // Stampa in caso di TNT STERILE
            else {
                this.etichetta = undefined;
                this.formTnt.$setPristine();
                var vm = this;
                var options: ng.ui.bootstrap.IModalSettings = {
                    templateUrl: '/views/Tnt/modalGestioneEtichetta.html',
                    scope: this.$scope,
                    windowClass: "modal-styled",
                    size: "md"
                };
                this.$modalInstance = this.$uibModal.open(options);
            }
        }

        private doStampaEtichetta() {
            console.log(this.etichetta);
            var url = appRoot + "Reports/EtichettaGenerica/?tipo=tnt&id=" + this.recordCorrente.Id + "&lotto=" + this.etichetta.Lotto + "&scadenza=" + moment(this.etichetta.DataScadenza).format("YYYY-MM-DD");
            window.open(url, "_blank");
            this.chiudiModal();
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formTnt.$valid) {
                this.showValidationMessages = true;
                this.formTnt.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    if (this.recordCorrente.Id == undefined)
                        this.tntFactory.inserisci(this.recordCorrente).then((result => {
                            var alertPromise = this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                            alertPromise.then(result => {
                                this.changeState("list");
                            });
                        })).catch((reason: string) => {
                            this.msgFactory.error(reason);
                        });
                    else
                        this.tntFactory.modifica(this.recordCorrente).then((result => {
                            var alertPromise = this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                            alertPromise.then(result => {
                                this.changeState("list");
                            });
                        })).catch((reason: string) => {
                            this.msgFactory.error(reason);
                        });
                }
            });
        }

        public aggiungiDistinta(prodotto: Prodotto.ProdottoVm): void {
            var presente: boolean = false;
            if (this.recordCorrente.Details != undefined) {
                $.each(this.recordCorrente.Details, (index: number, item: tntDetailVm) => {
                    if (item.Codice === prodotto.Codice) {
                        presente = true;
                        item.Quantita += 1;

                        return;
                    }
                });
            }
            else {
                this.recordCorrente.Details = new Array<tntDetailVm>();
            }

            if (presente) return;
            var d = new tntDetailVm();
            d.Codice = prodotto.Codice;
            d.Descrizione_al = prodotto.Descrizione_sq_AL;
            d.Descrizione_en = prodotto.Descrizione_en_US;
            d.Descrizione_it = prodotto.Descrizione_it_IT;
            d.IdProdotto = prodotto.Id;
            d.Quantita = 1;
            d.Tipo = ''; //prodotto.Tipo;
            this.recordCorrente.Details.push(d);
            this.formTnt.$setDirty();
        }


        public cancellaDistinta(elemento: tntDetailVm): void {
            if (this.recordCorrente != undefined && this.recordCorrente.Details != undefined)
            {
                if (elemento.Id != undefined && elemento.Id != 0) {
                    if (this.recordCorrente.DaCancellare == undefined)
                        this.recordCorrente.DaCancellare = [];
                    this.recordCorrente.DaCancellare.push(elemento.Id);
                }
                var posizione = this.recordCorrente.Details.indexOf(elemento);
                this.recordCorrente.Details.splice(posizione,1);
            }
        }

        public annulla(): void {
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                this.changeState('list');
            });

        }

        public cancella(Tnt: Tnt): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.tntFactory.cancella(Tnt.Id).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(reason);
                    });
                }
            });
        }

        public modifica(id: number): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.tntFactory.leggi(id).then((tnt: fullTntVm) => {
                this.recordCorrente = tnt;
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.changeState('manipola');
            });
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: ViewModels.DirezioneSort, filtro: Array<ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<ViewModels.SortRigaVm>();
            ricerca.sort.push(new ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.tntFactory.ricerca(ricerca).then((risultato: tntVm[]) => {
                this.lista = risultato; 
            });
        }

        public aggiornaRicercaDistinta(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            var f = new ViewModels.RicercaRigaVm();
            f.colonna = "IdTipo";
            f.valore = "3";
            filtro.push(f);
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.prodottiFactory.ricerca(ricerca).then((risultato: Prodotto.ProdottoVm[]) => {
                this.distintaTnt = risultato;
            });
        }


        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = new fullTntVm();
            this.recordCorrente.Immagini = new Array<Trace.ViewModels.ImmagineVm>();

            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public aggiungiDettaglio(): void {
            var nuovoDettaglio = new tntDetailVm();
            nuovoDettaglio.Codice = this.dettaglioCorrente.Codice;
            nuovoDettaglio.Descrizione_al = this.dettaglioCorrente.Descrizione_sq_AL;
            nuovoDettaglio.Descrizione_en = this.dettaglioCorrente.Descrizione_en_US;
            nuovoDettaglio.Descrizione_it = this.dettaglioCorrente.Descrizione_it_IT;
            nuovoDettaglio.IdProdotto = this.recordCorrente.Id;
            nuovoDettaglio.IdProdotto = this.dettaglioCorrente.Id;
            nuovoDettaglio.Quantita = this.dettaglioCorrente.Quantita;
            this.recordCorrente.Details.push(nuovoDettaglio);
            this.dettaglioCorrente = new TntDetail();
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formTnt.$setPristine();
                    break;
            }
        }

        public gestioneDistinta(): void {
            this.dettaglioCorrente = new TntDetail();

            this.testoAzioneDettaglio = this.localeFactory.get('button_inserisci');

            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Tnt/modalDistinta.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.gestioneNotCoded = false;
            this.$modalInstance = this.$uibModal.open(options);
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaTnt();
        }

        public resetFormDistinta(): void {
            this.ricercaDistinta = new Prodotto.RicercaProdotti();
        }

        public registraDettaglio(): void {
            this.tntFactory.aggiungiDettaglio(this.recordCorrente.Id, this.dettaglioCorrente).then((risultato: boolean) => {
                this.tntFactory.dettagli(this.recordCorrente.Id).then((tntDetails: tntDetailVm[]) => {
                    this.listaDettagli = tntDetails;
                    this.dettaglioCorrente = new TntDetail();
                });
            }); 
        }

        public annullaDettaglio(): void {

            this.dettaglioCorrente = new TntDetail();
        }

        public cancellaDettaglio(dettaglio: tntDetailVm): void {
            this.tntFactory.cancellaDettaglio(dettaglio.Id).then((risultato: boolean) => {
                this.tntFactory.dettagli(this.recordCorrente.Id).then((tntDetails: tntDetailVm[]) => {
                    this.listaDettagli = tntDetails;
                    this.dettaglioCorrente = new TntDetail();
                });
            }); 
        }


        public addNotCoded(show: boolean): void {
            this.gestioneNotCoded = show;
            if (show)
                this.notCoded = new Prodotto.DistintaVm();
        }

        public aggiungiDistintaNotCoded(prodotto: Prodotto.DistintaVm): void {
            var presente: boolean = false;
            if (this.recordCorrente.Details != undefined) {
                $.each(this.recordCorrente.Details, (index: number, item: tntDetailVm) => {
                    if (item.Codice === prodotto.Codice) {
                        presente = true;
                        item.Quantita += prodotto.Qta;
                        return;
                    }
                });
            }
            else {
                this.recordCorrente.Details = new Array<tntDetailVm>();
            }

            if (presente) return;
            var d = new tntDetailVm();
            d.Codice = prodotto.Codice;
            d.Descrizione_al = prodotto.DescrizioneAl;
            d.Descrizione_en = prodotto.DescrizioneEn;
            d.Descrizione_it = prodotto.DescrizioneIt;
            d.IdProdotto = prodotto.IdProdotto;
            d.Quantita = 1;
            d.Tipo = '';
            this.recordCorrente.Details.push(d);
            this.formTnt.$setDirty();
        }
        
        public chiudiModal(): void {
            this.$modalInstance.close();
            this.$modalInstance.dismiss('cancel');
        }
    }
}

app.controller("tntController", Trace.Tnt.tntController);