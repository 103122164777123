module Trace.Tnt {
    export class scadenzeTntController extends epBaseController {
        private formScadenze: any;
        private depositi: Array<Deposito.DepositoVm>;
        private listaScadenze: any[];
        private filtro: Scadenze.FiltroScadenze;

        static $inject = ['$scope', 'tntFactory', 'localeFactory', 'msgFactory', 'Upload', '$uibModal', "utenteFactory", "tracciabilitaFactory", 'depositoFactory'];
        constructor(
            private $scope: ng.IScope,
            public tntFactory: tntFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory,
            public depositiFactory: Deposito.depositoFactory
        ) {
            super(Upload, utenteFactory, null, null, tntFactory, localeFactory, msgFactory, $uibModal, null, tracciabilitaFactory);
            this.formScadenze = {};
        }

        private init() {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 1000);
            this.depositiFactory.listaGiaceza().then(
                result => {
                    this.depositi = result;
                },
                reason => {
                    this.msgFactory.error(reason);
                });
        }

        private numeroScadenze(): string {
            if (this.listaScadenze == undefined)
                return "";

            return this.listaScadenze.length + " Lotti di T.N.T. in scadenza";
        }

        private getExportUrl(): string {
            var result = appRoot + "Reports/ExportScadenzeTnt?strFiltro=" + JSON.stringify(this.filtro);
            console.log("ecco il link", result);
            return result;
        }

        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

        private submit(): void {
            var vm = this;
            this.isSaving = true;

            console.log("questo è il filtro da applicare", this.filtro);
            this.tntFactory.ScadenzeTnt(this.filtro).then(
                result => {
                    this.listaScadenze = result;
                    this.isSaving = false;
                },
                reason => {
                    var errore: string = reason == undefined ? vm.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_scadenze_errore", [errore]));
                    this.isSaving = false;
                }
            );
        }
    }
}
app.controller("scadenzeTntController", Trace.Tnt.scadenzeTntController);