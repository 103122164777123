module Trace.MappaturaCentrale {
	export class MappaturaCentraleDetailsVm {
		Id: number;
        IdArea: number;
        AreaStr: string;
		IpAddress: string;
		MacAddress: string;
		Note: string;
	}
}
