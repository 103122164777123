app.factory("cespitiFactory", ($http, $location, $q) => new Trace.Cespiti.cespitiFactory($http, $location, $q));

module Trace.Cespiti {

    export class cespitiFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Cespiti/";
        }

        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

         /************************************************
         *
         *          -- ANAGRAFICA CESPITI --
         *
         ***********************************************/        

        public lista(): ng.IPromise<Cespite[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Cespite[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idCespite: number): ng.IPromise<Cespite> {
            var workUrl = this.GetBaseUrl() + idCespite;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Cespite, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(marca: Cespite): ng.IPromise<Cespite> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, marca).success((data: Cespite, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(marca: Cespite): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + marca.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, marca).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public cancella(idCespite: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idCespite;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        // Eptable Anagrafica Cespiti
        public ricerca(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Cespiti.CespitiPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'Ricerca/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Cespite[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

         /*******************************************************
         *                                                      *
         *                -- CATEGORIE CESPITI --               *
         *                                                      *
         *******************************************************/

        public listaCategorie(): ng.IPromise<CategorieCespiti[]> {
            var workUrl = this.GetBaseUrl() + "ListaCategorieCespiti";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: CategorieCespiti[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisciCategoria(marca: CategorieCespiti): ng.IPromise<CategorieCespiti> {
            var workUrl = this.GetBaseUrl() + "InserisciCategoria";

            var deferred = this.$q.defer();
            this.$http.post(workUrl, marca).success((data: CategorieCespiti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modificaCategoria(categoria: CategorieCespiti): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "ModificaCategoria/" + categoria.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, categoria).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public cancellaCategoria(idCategoria: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CancellaCategoria/" + idCategoria;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggiCategoria(idCespite: number): ng.IPromise<CategorieCespiti> {
            var workUrl = this.GetBaseUrl() + "GetCategoria/" + idCespite;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: CategorieCespiti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        // Eptable Anagrafica Cespiti
        public ricercaCategorie(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Cespiti.CategorieCespitiPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'RicercaCategorie/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Trace.Cespiti.CategorieCespitiPaginationVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        /*******************************************************
        *                                                      *
        *                -- CATEGORIE CESPITI --               *
        *                                                      *
        *******************************************************/

        public listaFornitori(): ng.IPromise<FornitoriCespiti[]> {
            var workUrl = this.GetBaseUrl() + "ListaFornitoriCespiti";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: FornitoriCespiti[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisciFornitore(marca: FornitoriCespiti): ng.IPromise<FornitoriCespiti> {
            var workUrl = this.GetBaseUrl() + "InserisciFornitore";

            var deferred = this.$q.defer();
            this.$http.post(workUrl, marca).success((data: FornitoriCespiti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modificaFornitore(fornitore: FornitoriCespiti): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "ModificaFornitore/" + fornitore.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, fornitore).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public cancellaFornitore(idFornitore: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CancellaFornitore/" + idFornitore;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggiFornitore(idCespite: number): ng.IPromise<FornitoriCespiti> {
            var workUrl = this.GetBaseUrl() + "GetFornitore/" + idCespite;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: FornitoriCespiti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        // Eptable Anagrafica Cespiti
        public ricercaFornitori(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Cespiti.FornitoriCespitiPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'RicercaFornitori/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Trace.Cespiti.FornitoriCespitiPaginationVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        /*******************************************************
        *                                                      *
        *               -- UBICAZIONI CESPITI --               *
        *                                                      *
        *******************************************************/

        public listaUbicazioni(): ng.IPromise<UbicazioniCespiti[]> {
            var workUrl = this.GetBaseUrl() + "ListaUbicazioniCespiti";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: UbicazioniCespiti[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisciUbicazione(marca: UbicazioniCespiti): ng.IPromise<UbicazioniCespiti> {
            var workUrl = this.GetBaseUrl() + "InserisciUbicazione";

            var deferred = this.$q.defer();
            this.$http.post(workUrl, marca).success((data: UbicazioniCespiti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modificaUbicazione(ubicazione: UbicazioniCespiti): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "ModificaUbicazione/" + ubicazione.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, ubicazione).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public cancellaUbicazione(idUbicazione: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CancellaUbicazione/" + idUbicazione;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggiUbicazione(idCespite: number): ng.IPromise<UbicazioniCespiti> {
            var workUrl = this.GetBaseUrl() + "GetUbicazione/" + idCespite;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: UbicazioniCespiti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        // Eptable Anagrafica Cespiti
        public ricercaUbicazioni(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Cespiti.UbicazioniCespitiPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'RicercaUbicazioni/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Trace.Cespiti.UbicazioniCespitiPaginationVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        /********************************************************
         *                                                      *
         *               -- PRODUTTORI CESPITI --               *
         *                                                      *
         *******************************************************/

        public listaProduttori(): ng.IPromise<ProduttoriCespiti[]> {
            var workUrl = this.GetBaseUrl() + "ListaProduttoriCespiti";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ProduttoriCespiti[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisciProduttore(marca: ProduttoriCespiti): ng.IPromise<ProduttoriCespiti> {
            var workUrl = this.GetBaseUrl()+"InserisciProduttore";

            var deferred = this.$q.defer();
            this.$http.post(workUrl, marca).success((data: ProduttoriCespiti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }
        
        public modificaProduttore(produttore: ProduttoriCespiti): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl()+ "ModificaProduttore/" + produttore.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, produttore).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public cancellaProduttore(idProduttore: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "CancellaProduttore/" + idProduttore;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggiProduttore(idCespite: number): ng.IPromise<ProduttoriCespiti> {
            var workUrl = this.GetBaseUrl() + "GetProduttore/" + idCespite;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ProduttoriCespiti, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        // Eptable Anagrafica Cespiti
        public ricercaProduttori(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Cespiti.ProduttoriCespitiPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'RicercaProduttori/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Trace.Cespiti.ProduttoriCespitiPaginationVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        /********************************************************
         *                                                      *
         *             -- AMMORTAMENTI CESPITI --               *
         *                                                      *
         *******************************************************/

        
        public ricercaAmm(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Cespiti.AmmCespitiPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'RicercaAmmortamenti/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Trace.Cespiti.AmmCespitiVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricercaAmmDetails(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Cespiti.AmmCespitiDetailsPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'RicercaAmmortamentiDetails/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Trace.Cespiti.AmmCespitiDetailsVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public checkNuovoAmmortamento(flgAmmIt: boolean): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() +"CheckNuovoAmmortamento/" + flgAmmIt;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: any, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public calcolaAmmortamentoRiga(model: AmmCespitiDetailsVm): ng.IPromise<AmmCespitiVm> {
            var workUrl = this.GetBaseUrl() + "CalcolaAmmortamentoRiga";

            var deferred = this.$q.defer();
            this.$http.post(workUrl, model).success((data: AmmCespitiVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public calcolaAmmortamento(model: AmmCespitiVm): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + "CalcolaAmmortamento";

            var deferred = this.$q.defer();
            this.$http.post(workUrl, model).success((data: any, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public rendiAmmDefinitivo(idAmm: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "RendiAmmortamentoDefinitivo/" + idAmm;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggiAmmTestata(idAmm: number): ng.IPromise<AmmCespitiVm> {
            var workUrl = this.GetBaseUrl() + "GetTestataAmmortamentoVm/" + idAmm;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: AmmCespitiVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggiAmm(idAmm: number): ng.IPromise<AmmCespitiVm> {
            var workUrl = this.GetBaseUrl()+"GetAmmortamentoVm/" + idAmm;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: AmmCespitiVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
    }
}