app.factory("prodottiFactory", ($http, $location, $q) => new Trace.Prodotto.prodottiFactory($http, $location, $q));

module Trace.Prodotto {
    export class prodottiFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Prodotti/";
        }

        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {

        }

        public inserisciCodiceAlternativo(idProdotto: number, idProdottoAlternativo: number, data: Date): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "InserisciProdottiAlternativi";

            var deferred = this.$q.defer();

            var dati = {
                idProdotto: idProdotto,
                idProdottoAlternativo: idProdottoAlternativo,
                data: data
            };

            this.$http.post(workUrl, dati).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public rimuoviProdottoAlternativo(idProdotto: number, idProdottoAlternativo: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + 'RimuoviProdottoAlternativo';

            var data = {
                IdProdotto: idProdotto,
                IdProdottoAlternativo: idProdottoAlternativo
            }

            var deferred = this.$q.defer();
            this.$http.post(workUrl, data).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public inserisci(prodotto: ProdottoVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, prodotto).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public immagini(id: number): ng.IPromise<Trace.ViewModels.ImmagineVm[]> {
            var workUrl = this.GetBaseUrl() + "Immagini/" + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Trace.ViewModels.ImmagineVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public modifica(prodotto: ProdottoVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + prodotto.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, prodotto).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public proprietari(): ng.IPromise<Trace.ViewModels.ProprietarioVm[]> {
            var workUrl = this.GetBaseUrl() + "Proprietari";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Trace.ViewModels.ProprietarioVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public distinta(id: number): ng.IPromise<DistintaVm[]> {
            var workUrl = ("{0}Distinta/{1}").format([this.GetBaseUrl(), id]);

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Trace.ViewModels.ProprietarioVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inUso(idProdotto: number): ng.IPromise<boolean> {
            var workUrl = ("{0}InUso/{1}").format([this.GetBaseUrl(), idProdotto]);
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public CodiceExist(codice: string): ng.IPromise<boolean> {
            var workUrl = ("{0}CodiceExist/{1}").format([this.GetBaseUrl(), codice]);
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });
            return deferred.promise;
        }

        public cancella(idProdotto: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idProdotto;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public patch(idProdotto: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idProdotto;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<ProdottoVm[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: ProdottoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricercaPaginata(filtro: Trace.ViewModels.RicercaVm, currentPage: number): ng.IPromise<ProdottiPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'RicercaPaginata/' + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: ProdottoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricercaCodiciAlternativi(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<ProdottoVm[]> {
            var workUrl = this.GetBaseUrl() + 'RicercaCodiciAlternativi';
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: ProdottoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricercaCodiciAlternativiPaginata(filtro: Trace.ViewModels.RicercaVm, currentPage: number): ng.IPromise<ProdottiPaginationVm> {
            console.log("ricercaCodiciAlternativiPaginata: " + filtro);
            var workUrl = this.GetBaseUrl() + 'RicercaCodiciAlternativiPaginata/' + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: ProdottoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricercaComponenti(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<ProdottoVm[]> {
            var workUrl = this.GetBaseUrl() + 'ricercaComponenti';
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: ProdottoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public ricercaComponentiaPaginata(filtro: Trace.ViewModels.RicercaVm, currentPage: number): ng.IPromise<ProdottiPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'ricercaComponentiaPaginata/' + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: ProdottoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public tipiProdotto(): ng.IPromise<TipoProdottoVm[]> {
            var workUrl = this.GetBaseUrl() + "TipiProdotti";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: TipoProdottoVm[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idProdotto: number): ng.IPromise<ProdottoVm> {
            var workUrl = this.GetBaseUrl() + idProdotto;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ProdottoVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public decodeConsumabileLotto(id: number): ng.IPromise<ViewModels.EanCodeInfoVm> {
            var workUrl = this.GetBaseUrl() + "DecodeConsumabileLotto/" + id;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ViewModels.EanCodeInfoVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public GetByCodice(codice: string): ng.IPromise<ProdottoVm> {
            var workUrl = this.GetBaseUrl() + "GetByCodice/" + codice;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: ProdottoVm, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public AssociaCentroCosto(id: number, idCentroCosto: number): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + 'AssociaCentroCosto/' + id.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idCentroCosto).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ReadExcel(item: ViewModels.ReadExcelItem): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "ReadExcel";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, item).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ListaMarche(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "ListaMarche";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Scorte(idProdotto: number): ng.IPromise<Trace.Prodotti.VwScorteStrumentario[]> {
            var workUrl = this.GetBaseUrl() + "scorte/" + idProdotto;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Scadenze(filtro: Trace.Scadenze.FiltroScadenzeVm): ng.IPromise<Array<Trace.Prodotto.VwScadenzeProdotti>> {
            var workUrl = this.GetBaseUrl() + "RicercaScadenze";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public listaUnitaMisura(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "listaUnitaMisura";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public listaRaggruppamenti(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "listaRaggruppamenti";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}