module Trace.SoglieConsumi {
    export interface VwSoglieConsumi {
        Id: number;
        DataCreazione: Date;
        IdUtenteCreazione: number;
        UtenteCreazione: string;
        IdRaggruppamento: number;
        Raggruppamento: string;
        IdTipoRaggruppamento: number;
        IdGruppo: number;
        Gruppo: string;
        IdSottogruppo: number;
        Sottogruppo: string;
        IdSede: number;
        Sede: string;
        IdCentroCosto: number;
        CentroCosto: string;
        IdCentrale: number;
        Centrale: string;
        DataUltimaModifica: Date;
        IdUtenteUltimaModifica: number;
        UtenteUltimaModifica: string;
        DataObsoleto: Date;
        IdUtenteObsoleto: number;
        UtenteCancellazione: string;
        Details: VwSoglieConsumiDetails[];
    }
}