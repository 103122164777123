app.factory("consumiFactory", ($http, $location, $q) => new Trace.Consumi.consumiFactory($http, $location, $q));
module Trace.Consumi {

    export class consumiFactory {
        public get baseUrl(): string {
            return appRoot + "api/consumi/";
        }

        static $inject = ["$http", "$location", "$q"];
        constructor(
            private $http: ng.IHttpService,
            private $location: ng.ILocationService,
            private $q: ng.IQService
        ) { }
    }
}