
app.factory("startupFactory", ($http, $location, $q) => new Trace.Startup.startupFactory($http, $location, $q));
module Trace.Startup {
    export class startupFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Startup/";
        }

        public ricerca(filtro: ViewModels.RicercaVm, page: number): ng.IPromise<ViewModels.GenericPaginationVm<IStartupVm>> {
            var workUrl = this.GetBaseUrl() + "Ricerca/" + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ModelloStartupDetails(idCentrale: number): ng.IPromise<IStartupDetailVm[]> {
            var workUrl = this.GetBaseUrl() + "ModelloStartupDetails/" + idCentrale.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public registra(startup: IStartupVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, startup).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public modifica(id: number, startup: IStartupVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + id.toString();
            var deferred = this.$q.defer();
            this.$http.patch(workUrl, startup).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public leggi(id: number): ng.IPromise<IStartupVm> {
            var workUrl = this.GetBaseUrl() + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}