module Trace.Account {    
    export class accountController {

        public username: string;
        public password: string;
        public error: string;
        public hasError: boolean;
        public isLoading: boolean;
        public idCentrale: number;
        public idDeposito: number;
        public idUtente: number;
        public loginSuccess: boolean;
        public immagine: ViewModels.ImmagineVm;
        public centrali: Centrale.Centrale[];
        public depositi: Deposito.DepositoVm[];
        public showCentrali: boolean;
        public showDepositi: boolean;
        public canEnter: boolean;
        public ipaddress: string;

        static $inject = ["$scope", "utenteFactory", "localeFactory", "msgFactory", "centraliFactory", "depositoFactory"];

        constructor(private $scope: ng.IScope, private utenteFactory: Trace.Utente.utenteFactory,
            public localeFactory: Localization.localeFactory, public msgFactory: Messages.msgFactory,
            public centraliFactory: Centrale.centraliFactory, public depositoFactory: Deposito.depositoFactory) {
            this.hasError = false;
            this.isLoading = false;
            this.loginSuccess = false;
            this.setUpWatchers();

            let vm = this;
            this.getLocalIPs(function (ips) { // <!-- ips is an array of local IP addresses.
                vm.ipaddress = ips[0];
            });
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.idDeposito", (newVal: number, oldVal: number) => {
                this.canEnter = (newVal != undefined && newVal > 0);
            });
            this.$scope.$watch("vm.idCentrale", (newVal: number, oldVal: number) => {
                this.canEnter = (newVal != undefined && newVal > 0);
            });
        }

        public init(): void {

        }

        public recuperaPassword(): void {
            var vm = this;
            if (vm.username == undefined || vm.username == "") {
                vm.msgFactory.error(vm.localeFactory.get("messaggio_recupero_password_username"));
                return;
            }
            vm.msgFactory.confirm(vm.localeFactory.get("messaggio_recupero_password_conferma")).then(
                risultato => {
                    if (!risultato.result) return;
                    vm.utenteFactory.recoverPassword(vm.username).then(
                        // OK
                        risultato => {
                            vm.msgFactory.modalAlert(vm.localeFactory.getFormatted("messaggio_recupero_password_ok", [risultato]));
                        },
                        // ERRORE
                        reason => {
                            if (reason == undefined || reason == "") {
                                vm.msgFactory.error(vm.localeFactory.get("messaggio_recupero_password_username_errore"));
                            }
                            else {
                                var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                                vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_recupero_password_errore", [errore]));
                            }
                        }
                    );
                }
            );
        }

        private getImmagine(): string {
            var an = actionName.toLowerCase();
            var genericImage = (an == "logints") ? "images/generic-user-ts.png" : "images/generic-user.png";
            return this.immagine == undefined ? appRoot + genericImage : this.immagine.img;
        }

        private getAvatar(): void {
            var vm = this;
            if (vm.username == undefined) return;
            vm.utenteFactory.avatar(vm.username).then(
                // OK
                risultato => {
                    var tipo = typeof (risultato);
                    vm.immagine = typeof (risultato) != "object" ? null : risultato;
                },
                // ERRORE
                reason => {
                    vm.immagine = null;
                }
            );
        }

        public apriSessione(): void {
            this.hasError = false;
            if (this.idCentrale == undefined && this.idDeposito == undefined) {
                if ((this.idCentrale == undefined && this.centrali != undefined && this.centrali.length > 0) ||
                    (this.idDeposito == undefined && this.depositi != undefined && this.depositi.length > 0))    {
                    this.hasError = true;
                    this.error = this.localeFactory.get("login_selezione_centrale_deposito");
                    return;
                }
            }
            var vm = this;
            this.isLoading = true;
            this.utenteFactory.apriSessione(this.idUtente, this.idCentrale, this.idDeposito).then(
                // OK
                apriSessioneResult => {
                    var an = actionName.toLowerCase();
                    var action = (an == "logints") ? "Home/indexts" : "";
                    window.location.assign(appRoot + action);
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_sessione_errore", [errore]));
                }
            );
        }

        public showEnterButton(): boolean {
            return this.loginSuccess && this.canEnter;
        }


        public getLocalIPs(callback) {
            var ips = [];

            var RTCPeerConnection = window.RTCPeerConnection ||
                window.webkitRTCPeerConnection || window.mozRTCPeerConnection;

            console.log("MIO IP ",window.RTCPeerConnection, window.webkitRTCPeerConnection, window.mozRTCPeerConnection);

            var pc = new RTCPeerConnection({
                // Don't specify any stun/turn servers, otherwise you will
                // also find your public IP addresses.
                iceServers: []
            });
            // Add a media line, this is needed to activate candidate gathering.
            pc.createDataChannel('');

            // onicecandidate is triggered whenever a candidate has been found.
            pc.onicecandidate = function (e) {
                if (!e.candidate) { // Candidate gathering completed.
                    pc.close();
                    callback(ips);
                    return;
                }
                var ip = /^candidate:.+ (\S+) \d+ typ/.exec(e.candidate.candidate)[1];
                if (ips.indexOf(ip) == -1) // avoid duplicate entries (tcp/udp)
                    ips.push(ip);
            };
            pc.createOffer(function (sdp) {
                pc.setLocalDescription(sdp);
            }, function onerror() { });
        }

        public submit(): void {
            this.hasError = false;
            this.isLoading = true;
            console.log(this.ipaddress);

            var vm = this;
            this.utenteFactory.login(this.username, this.password, this.ipaddress).then(
                // OK
                risultato => {
                    if (risultato.StatusCode != undefined && risultato.StatusCode === 404) {
                        this.hasError = true;
                        this.error = this.localeFactory.get("messaggio_login_fallito");
                        this.isLoading = false;
                        return;
                    }

                    console.log("login riuscito", risultato);
                    this.loginSuccess = true;
                    this.idUtente = risultato.Value.Id;
                    if (risultato.Value.Descrizione == "ConMappa") {
                        var an = actionName.toLowerCase();
                        var action = (an == "logints") ? "Home/indexts" : "";
                        window.location.assign(appRoot + action);
                    }                       
                    else {
                        this.getCentrali();
                    }
                },
                // ERRORE
                reason => {
                    this.hasError = true;
                    this.error = this.localeFactory.get("messaggio_login_fallito");
                    this.isLoading = false;
                }
            );
        }

        public getCentrali(): void {
            var vm = this;
            this.centraliFactory.listaUtente(this.idUtente).then(
                // OK
                centraliResult => {
                    vm.centrali = centraliResult;
                    vm.getDepositi();
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_centrali_errore", [errore]));
                }
            );
        }

        private checkAccessi(): void {
            // Non ci sono scelte da fare, avvio la sessione
            if (this.centrali.length <= 0 && this.depositi.length <= 0)
                this.apriSessione();
            // Lascio scegliere l'utente
            if (this.centrali.length > 0 && this.depositi.length > 0)
                return;
            if (this.centrali.length > 0) {
                if (this.centrali.length > 1)
                    this.showCentrali = true;
                else {
                    this.idCentrale = this.centrali[0].Id;
                    this.apriSessione();
                }
            }
            else {
                if (this.depositi.length > 1)
                    this.showDepositi = true;
                else {
                    this.idDeposito = this.depositi[0].Id;
                    this.apriSessione();
                }
            }
        }

        public getDepositi(): void {
            var vm = this;
            this.depositoFactory.listaUtente(this.idUtente).then(
                // OK
                depositiResult => {
                    vm.depositi = depositiResult;
                    vm.checkAccessi();
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_centrali_errore", [errore]));
                }
            ).finally(() => {
                this.isLoading = false;
            });
        }

        public selectCentrale(): void {
            if (this.centrali.length === 1) {
                this.idCentrale = this.centrali[0].Id;
                this.apriSessione();
                return;
            }
            this.showCentrali = true;
            this.showDepositi = false;
        }

        public selectDeposito(): void {
            console.log("depositi", this.depositi);
            if (this.depositi.length === 1) {
                this.idDeposito = this.depositi[0].Id;
                this.apriSessione();
                return;
            }
            this.showCentrali = false;
            this.showDepositi = true;
        }
    }
}
angular.module("traceApp").controller("accountController", Trace.Account.accountController);
