
app.factory("produzioneFactory", ($http, $location, $q) => new Trace.Produzione.produzioneFactory($http, $location, $q));
module Trace.Produzione {
    export class produzioneFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Produzione/";
        }

        public CancellaLottoDetailSostituito(idLotto: number) {
            var workUrl = this.GetBaseUrl() + "CancellaLottoDetailSostituito";
            var deferred = this.$q.defer();

            this.$http.post(workUrl, idLotto).then(
                result => {
                    deferred.resolve(result);
                }
            );
            return deferred.promise;
        }

        public SaveStrumentoGestioneSegnalazioni(lottoDetail: Confezionamento.ConfezionamentoLottoDetailVm) {
            var workUrl = this.GetBaseUrl() + "SaveStrumentoGestioneSegnalazioni";
            var deferred = this.$q.defer();
            console.log(lottoDetail);
            this.$http.post(workUrl, lottoDetail).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RipristinaUltimaFaseLottoProduzione(idLotto: number, flgRemoveIntervento: boolean = false) {
            var workUrl = this.GetBaseUrl() + "RipristinaUltimaFaseLottoProduzione/" + flgRemoveIntervento.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idLotto).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public BarcodeByLotto(idLotto: string): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "BarcodeByLotto/" + idLotto;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetImmagini(idLotto: number): ng.IPromise<Array<any>> {
            var workUrl = this.GetBaseUrl() + "GetImmaginiLotto/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AccettaLottoProduzione(idLotto: number, idIntervento: number): ng.IPromise<CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "AccettaLottoProduzione/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idIntervento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public CambiaFaseLottoProduzione(idLotto: number, idFase?: number): ng.IPromise<CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "CambiaFaseLottoProduzione/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idFase).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public CambiaFaseBox(idBox: number, idFase?: number): ng.IPromise<CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "CambiaFaseBox/" + idBox.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idFase).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public CambiaFasiLottiProduzione(lotti: Array<number>, idFase?: number): ng.IPromise<CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "CambiaFasiLottiProduzione";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, lotti).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Dettagli(idLotto: number, idFase: number): ng.IPromise<LottoProduzioneVm> {
            var workUrl = this.GetBaseUrl() + "Dettagli/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idFase).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Trattamenti(): ng.IPromise<Trattamento[]> {
            var workUrl = this.GetBaseUrl() + "Trattamenti";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RegistraDatiProduzione(idLotto: number, dati: DatiProduzioneVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "RegistraDatiProduzione/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, dati).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public CaricaMacchina(carico: CaricoMacchinaVm): ng.IPromise<Produzione.CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "CaricaMacchina";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, carico).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ListaCarichi(idTipoMacchina: number, idMacchina?: number): ng.IPromise<CaricoMacchina[]> {
            var workUrl = this.GetBaseUrl() + "ListaCarichi/" + idTipoMacchina.toString();
            var deferred = this.$q.defer();
            if (idMacchina != undefined) {
                this.$http.post(workUrl, idMacchina).then(
                    result => {
                        deferred.resolve(result.data);
                    },
                    reason => {
                        deferred.reject(reason);
                    }
                );
            }
            else {
                this.$http.get(workUrl).then(
                    result => {
                        deferred.resolve(result.data);
                    },
                    reason => {
                        deferred.reject(reason);
                    }
                );
            }
            return deferred.promise;
        }

        public EseguiCicloManuale(idMacchina: number, numCiclo: number): ng.IPromise<CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "EseguiCicloManuale/" + idMacchina.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, numCiclo).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SetUrgenza(idLotto: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "SetUrgenza/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public GetLottoByUid(uid: string): ng.IPromise<CambioStatoResult> {
            var workUrl = this.GetBaseUrl() + "GetLottoByUid/" + uid;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ListaFase(idFase: number): ng.IPromise<Produzione.LottoProduzioneVm[]> {
            var workUrl = this.GetBaseUrl() + "ListaFase/" + idFase;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ListaFaseByBox(idFase: number): ng.IPromise<Produzione.BoxVm[]> {
            var workUrl = this.GetBaseUrl() + "ListaFaseByBox/" + idFase;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaGriglia(codice: string): ng.IPromise<Produzione.GrigliaVm> {
            var workUrl = this.GetBaseUrl() + "RicercaGriglia/" + codice;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaCesta(codice: string): ng.IPromise<Produzione.GrigliaVm> {
            var workUrl = this.GetBaseUrl() + "RicercaCesta/" + codice;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AggiungiGrigliaSet(idLotto: number, idGriglia: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AggiungiGrigliaSet/" + idLotto;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idGriglia).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AggiungiGrigliaBox(idBox: number, idGriglia: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AggiungiGrigliaBox/" + idBox;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idGriglia).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AggiungiCestaSet(idLotto: number, idCesta: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AggiungiCestaSet/" + idLotto;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idCesta).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public CestaAssociata(idLotto: number): ng.IPromise<Produzione.GrigliaVm> {
            var workUrl = this.GetBaseUrl() + "CestaAssociata/" + idLotto;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ListaGriglie(idLotto: number): ng.IPromise<Produzione.GrigliaVm[]> {
            var workUrl = this.GetBaseUrl() + "ListaGriglie/" + idLotto;
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaMateriali(ricerca: RicercaMaterialiVm, accettazione?: boolean): ng.IPromise<Materiale[]> {
            if (accettazione == undefined)
                accettazione = false;
            var workUrl = this.GetBaseUrl() + "RicercaMateriali/" + accettazione;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, ricerca).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public AnnullaManutenzione(idMovimentoDetail: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "AnnullaManutenzione/" + idMovimentoDetail.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public CheckMagazzinoStrumenti(idLottoProduzione: number): ng.IPromise<Set.StrumentoVm[]> {
            var workUrl = this.GetBaseUrl() + "CheckMagazzinoStrumenti/" + idLottoProduzione.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RegistraReintegroStrumenti(idLotto: number, idStrumenti: Array<number>): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "RegistraReintegroStrumenti/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, idStrumenti).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public LottiProduzioneAttivi(idCentrale: number): ng.IPromise<Produzione.LottoProduzioneAttivoVm[]> {
            var workUrl = this.GetBaseUrl() + "LottiProduzioneAttivi/";
            if (idCentrale != undefined)
                workUrl += idCentrale.toString();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ContaStrumenti(idLotto: number): ng.IPromise<IContaStrumentiItem> {
            var workUrl = this.GetBaseUrl() + "ContaStrumenti/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public DettagliBox(idBox: number): ng.IPromise<Produzione.BoxVm> {
            var workUrl = this.GetBaseUrl() + "DettagliBox/" + idBox.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}
