module Trace.Analisi {
    export class AnalisiResultVm {
        public lista: Array<any>;
        public count: number;
        public countBuste: number;
        public sum: number;
        public sumTnt: number;
        public sumBuste: number;
        public title: string;
        public children: Array<AnalisiResultVm>;
        public costoMedioIntervento(): number {
            return this.count == undefined || this.count == 0 ? 0
                : this.sum / this.count;
        }
    }
}