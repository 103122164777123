module Trace.ModelliSet {
    export class itemProduzioneController {
        public produzione: Produzione;
        public filteredStrumenti: Set.SetDetailVm[]; 
        public percentualeCompletamento: number;
        public totStrumenti: number;
        public totMancanti: number;

        constructor(public localeFactory: Localization.localeFactory) {
            var q = 0;
            var p = 0;
            var m = 0;
            $.each(this.produzione.Strumenti, (ide, e) => {
                q += e.Qta;
                p += e.QtaPosizionabileDoc + e.QtaPosizionabileDep;
                m += (e.Qta - e.QtaPosizionabileDoc - e.QtaPosizionabileDep);
            });
            this.totStrumenti = q;
            this.totMancanti = m;
            this.percentualeCompletamento = Math.floor(p * 100 / q);
            this.filtra(0);
            $(document).on("filtroGeneraleProduzione", (event) => {
                this.filtra(0);
            });
        }

        public filtra(tipo: number): void {
            switch (tipo) {
                // tutti
                case 0:
                    this.filteredStrumenti = this.produzione.filteredStrumenti;
                    break;
                // presenti
                case 1:
                    this.filteredStrumenti = $.grep(this.produzione.filteredStrumenti, (e, ide) => {
                        return (e.QtaPosizionabileDep + e.QtaPosizionabileDoc) == e.Qta;
                    });
                    break;
                // parziali
                case 2:
                    this.filteredStrumenti = $.grep(this.produzione.filteredStrumenti, (e, ide) => {
                        return (e.QtaPosizionabileDep + e.QtaPosizionabileDoc) < e.Qta && (e.QtaPosizionabileDep + e.QtaPosizionabileDoc) > 0;
                    });
                    break;
                // nessuno
                case 3:
                    this.filteredStrumenti = $.grep(this.produzione.filteredStrumenti, (e, ide) => {
                        return (e.QtaPosizionabileDep + e.QtaPosizionabileDoc) <= 0;
                    });
                    break;
            }
        }

        public titolo(): string {
            return this.localeFactory.getFormatted("produzione_copia_numero", [this.produzione.RealCopy]);
        }

        public showQta(p: Set.SetDetailVm): string {
            return (p.QtaPosizionabileDoc + p.QtaPosizionabileDep <= 0)
                ? p.QtaPosizionabileDoc + " / " + p.Qta
                : "<span title='" + p.QtaPosizionabileDoc + " da documenti più " + p.QtaPosizionabileDep + " dal deposito'><span class='text-success'>" + p.QtaPosizionabileDoc + "</span> + <span class='text-danger'>" + p.QtaPosizionabileDep + "</span> / " + p.Qta + "</span>";
        }

        public showDescrizione(descr: string) {
            return descr.length > 32 ? descr.substr(0, 29) + "..." : descr;
        }
    }
}
app.controller("itemProduzioneController", Trace.ModelliSet.itemProduzioneController);

app.component("itemProduzione", {
    templateUrl: "/views/ModelliSet/itemProduzione.html",
    controller: "itemProduzioneController",
    controllerAs: "vm",
    bindings: {
        produzione: "<"
    }
});