module Trace.ViewModels {
    export class ReadExcelItem {
        public FileName: string;
        public OriginalName: string;
        public Subfolder: string;
    }
    export class ReadExcelInventario extends ReadExcelItem {
        public IdDeposito: number;
        public CodTipoMateriale: string;
    }
}