module Trace.Analisi {
    export class prodcentralimensileController {
        private filtro: FiltroProdCentraliMensile; 
        private graficoShow: boolean;
        private risultatoRicerca: any;
        private isLoading: boolean;
        private anni: any;
        private mesi: any;

        static $inject = ['$scope', 'centraliFactory', 'produttivitaFactory', 'localeFactory', 'msgFactory'];
        constructor(
            private $scope: ng.IScope,
            private centraliFactory: Centrale.centraliFactory,
            private produttivitaFactory: Analisi.produttivitaFactory, 
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory            
        ) {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 1000);         
            this.anni = this.getPeriodiFiltro('Anni');
            this.mesi = this.getPeriodiFiltro('Mesi');
            this.filtro = new FiltroProdCentraliMensile();
            this.filtro.AnnoRiferimento = moment(Date.now()).get('Year');
            this.filtro.MeseRiferimento = 1+moment(Date.now()).get('Month');
            if (this.filtro.MeseRiferimento > 1) this.filtro.MeseRiferimento--;
            else {
                this.filtro.AnnoRiferimento--;
                this.filtro.MeseRiferimento = 12;
            }
            
            this.setUpWatchers();
        }

        private mtohhmm(minute: number): string {
            if (minute == undefined) return null;
            var hour = Math.floor(minute / 60);
            var min = Math.round(minute % 60);
            var txt = Math.abs(hour) < 9 ? '0' : '';
            txt += Math.abs(hour)+':';
            txt += Math.abs(min) < 9 ? '0' : '';
            txt += Math.abs(min);
            txt = minute < 0 ? '-' + txt : txt
            return txt;
        }

        private getPeriodiFiltro(datePart: string): any[] {
            switch (datePart) {
                case 'Mesi':
                    return [
                        { label: 'Gennaio', value: 1 },
                        { label: 'Febbraio', value: 2 },
                        { label: 'Marzo', value: 3 },
                        { label: 'Aprile', value: 4 },
                        { label: 'Maggio', value: 5 },
                        { label: 'Giugno', value: 6 },
                        { label: 'Luglio', value: 7 },
                        { label: 'Agosto', value: 8 },
                        { label: 'Settembre', value: 9 },
                        { label: 'Ottobre', value: 10 },
                        { label: 'Novembre', value: 11 },
                        { label: 'Dicembre', value: 12 }
                    ];
                case 'Anni':
                    let intervalloAnni = 5;
                    let annoCorrente = moment(Date.now()).get('Year');
                    let year: number;
                    let res: any[] = [];
                    for (year = annoCorrente - intervalloAnni; year <= annoCorrente /*+ intervalloAnni*/; year++) {
                        res.push({ label: year.toString(), value: year });
                    }
                    return res;
            }
            return [];
        }

        public ricerca(): void {
            if (this.filtro.MeseRiferimento == undefined || this.filtro.AnnoRiferimento == undefined) {
                let msg = "Seleziona ";
                if (this.filtro.AnnoRiferimento == undefined) msg += "un anno di riferimento ";
                if (this.filtro.MeseRiferimento == undefined) msg += this.filtro.AnnoRiferimento == undefined ? "ed un mese " : "un mese ";
                msg += "prima di procedere alla ricerca.";
                this.msgFactory.alert(msg);
                return;
            }
            this.isLoading = true;
            this.graficoShow = true;

            this.produttivitaFactory.RicercaProdCentraliMensile(this.filtro).then(
                result => {
                    this.risultatoRicerca = result;
                    this.isLoading = false;                    
                                        
                    console.log(this.risultatoRicerca);
                    
                }
            );
        }
        private init() {
            
        }
           
        private rigaEsplosa: string = "";
        private esplodiRiga(index: number) {
            var idHTML = "row-centrale-" + index;
            this.rigaEsplosa = this.rigaEsplosa == idHTML ? "" : idHTML;            
        }

       

        public dynamicSort(property: string, sortOrder: number/*, isDate: boolean = false*/) {
            sortOrder = sortOrder == 2 ? -1 : sortOrder;
            return function (a, b) {
                var result;
                    result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                //else result = (moment(a[property]).isBefore(b[property])) ? -1 : (moment(a[property]).isAfter(b[property])) ? 1 : 0;
                return result * sortOrder;
            }
        }
       
        private setUpWatchers(): void {
           
        }
                    
    }    
}
app.controller("prodcentralimensileController", Trace.Analisi.prodcentralimensileController);