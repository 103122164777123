module Trace.Startup {
    export class StartupDetailVm implements IStartupDetailVm {
        public Id: number;
        public IdStartup: number;
        public IdMacchina: number;
        public Codice: string;
        public Macchina: string;
        public LottoMacchina: string;
        public Lotto: string;
        public DataScadenza: Date;
        public StrDataScadenza: string;
        public LottoHelix: string;
        public DataScadenzaHelix: Date;
        public StrDataScadenzaHelix: string;
        public LottoBd: string;
        public DataScadenzaBd: Date;
        public StrDataScadenzaBd: string;
        public Detergente: string;
        public Neutralizzante: string;
        public LottoNeutralizzante: string;
        public DataScadenzaNeutralizzante: Date;
        public StrDataScadenzaNeutralizzante: string;
        public CicloBd: number;
        public CicloHelix: number;
        public LottoProvaBiologica: string;
        public DataScadenzaProvaBiologica: Date;
        public StrDataScadenzaProvaBiologica: string;

    }
}