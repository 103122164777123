module Trace.Analisi {
    export class inevasoController {
        private filtro: FiltroInevaso;         
        private gruppi: Trace.Gruppo.Gruppo[];
        private sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        private sedi: Trace.Sede.Sede[];
        private centriCosto: Trace.CentroCosto.CentroCosto[]; 
        private utenti: Helpers.SimpleEntity[];
        private fornitori: Helpers.SimpleEntity[];
        private risultatoRicerca: Trace.Analisi.VwAnalisiInevaso[];

        private isLoading: boolean;

        static $inject = ['$scope','fornitoriFactory','inevasoFactory','gruppiFactory', 'sottoGruppiFactory', 'sediFactory',
                            'centriCostoFactory', 'localeFactory', 'msgFactory'];
        constructor(
            private $scope: ng.IScope,
            private fornitoriFactory: Fornitori.fornitoriFactory,
            private inevasoFactory: Analisi.inevasoFactory,       
            private gruppiFactory: Gruppo.gruppiFactory,
            private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory,  
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory            
        ) {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 500);

            //this.risultatoRicerca = <Trace.Analisi.GraficiProduttivitaVm>{ GrafProdOraria: Array<Trace.Analisi.ProdOraria>() };
            this.filtro = new FiltroInevaso();

            this.getFornitori();
            this.getUtentiInevasi();           
            this.getGruppi();            
            this.setUpWatchers();
        }

        public getExportUrl(): string {
            return appRoot + "Reports/ExportAnalisiOrdiniInevasi" + "?strFiltro=" + JSON.stringify(this.filtro);
        }

        public formatDate(data: Date, format: string = "DD/MM/YYYY"): string {
            var result = moment(data).format(format);
            return result;
        }

        public ricerca(): void {
            
            this.isLoading = true;

            this.inevasoFactory.Ricerca(this.filtro).then(
                result => {
                    this.risultatoRicerca = result;
                    this.isLoading = false;
                }
            );
        }
         
        private setUpWatchers(): void {
            this.$scope.$watch("vm.filtro.IdGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sottoGruppi = undefined;
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSottogruppi(newVal);
            });
            this.$scope.$watch("vm.filtro.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if (newVal == undefined) {
                    this.sedi = undefined;
                    this.centriCosto = undefined;
                }
                else this.getSedi(newVal);
            });
            this.$scope.$watch("vm.filtro.IdSede", (newVal: number, oldVal: number) => {
                if (newVal == undefined)
                    this.centriCosto = undefined;
                else this.getCentriCosto(newVal);
            });
        }
        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

        private getUtentiInevasi(): void {
            this.inevasoFactory.GetUtenti().then(
                result => {
                    this.utenti = result;
                }
            );
        }

        private getFornitori(): void {
            this.fornitoriFactory.AsSimpleList().then(
                result => {
                    this.fornitori = result;
                }
            );
        }

        private getGruppi(): void {
            this.gruppiFactory.lista().then(
                result => {
                    this.gruppi = result;
                }
            );
        }

        private getSottogruppi(idGruppo: number): void {
            this.sottoGruppiFactory.listaGruppo(idGruppo).then(
                result => {
                    this.sottoGruppi = result;
                }
            );
        }

        private getSedi(idSottogruppo: number): void {
            this.sediFactory.listaSottoGruppo(idSottogruppo).then(
                result => {
                    this.sedi = result;
                }
            );
        }

        private getCentriCosto(idSede: number): void {
            this.centriCostoFactory.listaSede(idSede).then(
                result => {
                    this.centriCosto = result;
                }
            );
        }        
    }    
}
app.controller("inevasoController", Trace.Analisi.inevasoController);