module Trace.Manutenzioni {
    export class manutenzioniController extends Movimenti.epMovimentiController {
        public ricerca: RicercaManutenzioniVm;
        public formDettagliManutenzione: any;
        public manutenzioni: Set.StrumentoVm[]; 
        public manutenzione: ManutenzioneDetailsVm;
        public giorniManutenzione: string;
        static $inject = ["$rootScope", "$scope", "manutenzioniFactory", "dtFactory", "movimentiFactory", "produzioneFactory",
            "documentoFactory", "setFactory", "prodottiFactory", "depositoFactory", "utenteFactory", "localeFactory", "msgFactory", "$uibModal",
            "gruppiFactory", "sottoGruppiFactory", "sediFactory", "centriCostoFactory", "tracciabilitaFactory"];

        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private manutenzioniFacotry: manutenzioniFactory,
            public dtFactory: Trace.datatables.dtFactory,
            public movimentiFactory: Movimenti.movimentiFactory,
            private produzioneFactory: Produzione.produzioneFactory,
            public documentoFactory: Documento.documentoFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public depositoFactory: Deposito.depositoFactory,
            public utenteFactory: Utente.utenteFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public gruppiFactory: Gruppo.gruppiFactory,
            public sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            public sediFactory: Sede.sediFactory,
            public centroCostoFactory: CentroCosto.centroCostoFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super($scope, $rootScope, $uibModal, dtFactory, localeFactory, msgFactory, setFactory, prodottiFactory, undefined,
                depositoFactory, utenteFactory, movimentiFactory, undefined, documentoFactory,
                gruppiFactory, sottoGruppiFactory, sediFactory, centroCostoFactory, tracciabilitaFactory);

            this.formDettagliManutenzione = {};
        }

        public init(): void {
            this.getStati(2);
            this.changeState('list');
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.manutenzioniFacotry.ricerca(ricerca, page).then(
                risultato => {
                    var r = risultato;
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                    this.manutenzioni = r.List;
                }
            );
        }

        public resetForm(): void {
            this.ricerca = new RicercaManutenzioniVm();
        }

        public annullaManutenzione(strumento: Set.StrumentoVm): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted("annulla_manutenzione_conferma", [strumento.Codice, strumento.Uid, this.localized(strumento, "Descrizione")])).then(
                result => {
                    if (!result.result) return;
                    this.doAnnullaManutenzione(strumento.Id);
                }
            );
        }

        private doAnnullaManutenzione(id: number): void {
            this.produzioneFactory.AnnullaManutenzione(id).then(
                result => {
                    this.msgFactory.alert("annulla_manutenzione_ok");
                    this.dtFactory.aggiornaRicerca();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public leggi(id: number): void {
            this.manutenzioniFacotry.leggi(id).then(
                result => {
                    this.manutenzione = result;
                    this.giorniManutenzione = moment(this.manutenzione.Strumento.DataFermo).fromNow();
                    this.infoProdotto(result.Strumento);
                    this.changeState('manipola');
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public changeState(stato: string): void {
            super.changeState(stato);
            switch (stato) {
                case "list":
                    this.giorniManutenzione = null;
                    this.strumentoSelezionato = null;
                    break;
                case "manipola":
                    var strumento = this.localized(this.manutenzione.Strumento, "Descrizione");
                    this.titoloManipola = this.localeFactory.getFormatted("dettagli_strumento", [strumento]);
                    break;
            }
        }

        public strumentoRientrato(stato: string): void {
            //alert("Stato: " + stato);
            var vm = this;
            var strumento = vm.descrizioneStrumentoSelezionato();
            this.msgFactory.confirm(vm.localeFactory.getFormatted('passaggio_stato_conferma', [strumento])).then(
                result => {
                    if (!result.result) return;
                    this.movimentiFactory.SetStato(vm.manutenzione.IdManutenzioneDetail, stato).then(
                        csResult => {
                            vm.manutenzioniFacotry.Tracking(vm.manutenzione.IdManutenzioneDetail).then(
                                trkResult => {
                                    vm.manutenzione.Tracking = trkResult;
                                }
                            );
                        }
                    );
                }
            );
        }
    }
}
app.controller("manutenzioniController", Trace.Manutenzioni.manutenzioniController);