module Trace.TipoIntervento {
    export class tipoInterventoController extends epBaseController {
        public raggruppamenti: Helpers.SimpleEntity[];
        public ricerca: RicercaTipoIntervento;
        public ricercaDistinta: RicercaDistintaTipoIntervento;
        public tipiSet: Array<Set.TipoSetVm>;
        public tipiTnt: Array<Tnt.TipoTnt>;
        public lista: VwTipoInterventoVm[];
        public listaTnt: Tnt.tntVm[];
        public listaRot: Rot.Rot[];
        public listaSet: Set.SetVm[];
        public listaComplessita: Array<Set.ComplessitaVm>;
        public listaComplessitaRicerca: Array<Set.ComplessitaVm>;
        public recordCorrente: tipoInterventoVm;
        public formTipiInterventi: any;
        public tipoElemento: string;
        public testoAzione: string;

        static $inject = ['$rootScope', '$scope', 'tipoInterventoFactory', 'localeFactory', 'dtFactory', '$uibModal',
            'msgFactory', 'tntFactory', 'modelliSetFactory', 'setFactory', 'rotFactory', "tracciabilitaFactory"];

        constructor(public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            private tipoInterventoFactory: tipoInterventoFactory,
            public localeFactory: Localization.localeFactory,
            private dtFactory: datatables.dtFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public msgFactory: Messages.msgFactory,
            public tntFactory: Tnt.tntFactory,
            private modelliSetFactory: ModelliSet.modelliSetFactory,
            public setFactory: Set.setFactory,
            private rotFactory: Rot.rotFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(undefined, undefined, setFactory, undefined, tntFactory, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);
            this.formTipiInterventi = {};
            this.setFactory.listaTipiSet().then((risultato: Set.TipoSetVm[]) => {
                this.tipiSet = risultato;
            });
            this.setFactory.listaComplessita().then(risultato => {
                this.listaComplessita = risultato;
                this.listaComplessitaRicerca = risultato;
            });
            this.tntFactory.listaTipi().then((risultato: Tnt.TipoTnt[]) => {
                this.tipiTnt = risultato;
            });
            this.tipoInterventoFactory.listaRaggruppamenti().then(
                result => {
                    this.raggruppamenti = result;
                });
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.dtFactory.aggiornaRicerca();
                    this.recordCorrente = new tipoInterventoVm();
                    this.ricercaDistinta = new RicercaDistintaTipoIntervento();
                    break;

                case "manipola":
                    this.formTipiInterventi.$setPristine();
                    break;
            }
        }

        public titoloRicerca(): string {
            var titolo = "";
            switch (this.tipoElemento) {
                case "SET":
                    titolo = this.localeFactory.get("titolo_ricerca_set");
                    break;

                case "TNT":
                    titolo = this.localeFactory.get("titolo_ricerca_tnt");
                    break;

                case "ROT":
                    titolo = this.localeFactory.get("titolo_ricerca_rot");
                    break;
            }

            return titolo;
        }

        public resetForm(): void {
            this.ricerca = new RicercaTipoIntervento();
        }

        public resetFormDistinta(): void {
            this.ricercaDistinta = new RicercaDistintaTipoIntervento();
            var tableId = "";
            switch (this.tipoElemento) {
                case "SET":
                    tableId = "lista-distinta-set";

                    break;

                case "TNT":
                    tableId = "lista-distinta-tnt";
                    break;

                case "ROT":
                    tableId = "lista-distinta-rot";
                    break;
            }
            this.dtFactory.aggiornaRicerca(tableId);
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: ViewModels.DirezioneSort, filtro: Array<ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<ViewModels.SortRigaVm>();
            ricerca.sort.push(new ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.tipoInterventoFactory.ricerca(ricerca).then((risultato: VwTipoInterventoVm[]) => {
                this.lista = risultato;
            });
        }

        public aggiornaRicercaDistinta(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));

            switch (this.tipoElemento) {
                case "SET":
                    this.modelliSetFactory.ricerca(ricerca, 1).then((risultato: Set.SetPaginationVm) => {
                        this.listaSet = risultato.List;
                    });
                    break;

                case "TNT":
                    this.tntFactory.ricerca(ricerca).then((risultato: Tnt.tntVm[]) => {
                        this.listaTnt = risultato;
                    });
                    break;

                case "ROT":
                    this.rotFactory.ricerca(ricerca).then((risultato: Rot.Rot[]) => {
                        this.listaRot = risultato;
                    });
                    break;
            }
        }

        public cancella(intervento: TipoIntervento): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.tipoInterventoFactory.cancella(intervento.Id).then(
                        result => {
                            this.changeState('list');
                        },
                        reason => {
                            this.msgFactory.error(reason);
                        }
                    );
                }
            });
        }

        public modifica(intervento: tipoInterventoVm): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.tipoInterventoFactory.leggi(intervento.Id).then(
                newItem => {
                    this.recordCorrente = newItem;
                    this.changeState("manipola");
                }
            );
        }

        public aggiungi(): void {
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState("manipola");
        }

        public nuovo(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.recordCorrente = new tipoInterventoVm();
            this.changeState("manipola");
        }

        public gestioneDistinta(): void {
            var vm = this;
            this.tipoElemento = "";
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Intervento/modalDistinta.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public aggiungiDistinta(d: any): void {
            var iD = new TipoInterventoRigaSimpleVm();

            switch (this.tipoElemento) {
                case "SET":
                    iD.Tipo = this.tipoElemento;
                    iD.IdSet = d.Id;
                    iD.IdRot = null;
                    iD.IdTnt = null;
                    iD.DescrizioneIt = d.Descrizione_it_IT;
                    iD.DescrizioneEn = d.Descrizione_en_US;
                    iD.DescrizioneAl = d.Descrizione_sq_AL;
                    break;

                case "TNT":
                    iD.Tipo = "T.N.T.";
                    iD.IdTnt = d.Id;
                    iD.IdSet = null;
                    iD.IdRot = null;
                    iD.DescrizioneIt = d.Descrizione_it;
                    iD.DescrizioneEn = d.Descrizione_en;
                    iD.DescrizioneAl = d.Descrizione_al;
                    break;

                case "ROT":
                    iD.Tipo = this.tipoElemento;
                    iD.IdRot = d.Id;
                    iD.IdSet = null;
                    iD.IdTnt = null;
                    iD.DescrizioneIt = d.Descrizione_it_IT;
                    iD.DescrizioneEn = d.Descrizione_en_US;
                    iD.DescrizioneAl = d.Descrizione_sq_AL;
                    break;
            }

            var presente: boolean = false;
            if (this.recordCorrente.Distinta != undefined) {
                $.each(this.recordCorrente.Distinta, (index: number, item: tipoInterventoRigaVm) => {
                    if (item.IdRot === iD.IdRot && item.IdSet === iD.IdSet && item.IdTnt === iD.IdTnt) {
                        presente = true;
                        item.Qta += 1;
                        return;
                    }
                });
            }
            else {
                this.recordCorrente.Distinta = new Array<TipoInterventoRigaSimpleVm>();
            }

            if (presente) return;
            if (this.recordCorrente.Id != undefined)
                iD.IdIntervento = this.recordCorrente.Id;
            iD.Qta = 1;
            this.recordCorrente.Distinta.push(iD);
            this.formTipiInterventi.$setDirty();
        }

        public cancellaDistinta(elemento: TipoInterventoRigaSimpleVm): void {
            if (this.recordCorrente != undefined && this.recordCorrente.Distinta != undefined) {
                var posizione = this.recordCorrente.Distinta.indexOf(elemento);
                this.recordCorrente.Distinta.splice(posizione, 1);
                this.formTipiInterventi.$setDirty();
            }
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formTipiInterventi.$valid) {
                this.showValidationMessages = true;
                this.formTipiInterventi.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    if (this.recordCorrente.Id == undefined) {
                        this.tipoInterventoFactory.inserisci(this.recordCorrente).then(
                            result => {
                                this.backToList();
                            },
                            reason => {
                                this.msgFactory.error(reason);
                            }
                        );
                    }
                    else {
                        this.tipoInterventoFactory.modifica(this.recordCorrente).then(
                            result => {
                                this.backToList();
                            },
                            reason => {
                                this.msgFactory.error(reason);
                            }
                        );
                    }
                }
            });

        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                vm.backToList();
            });
        }
    }
}
app.controller("tipoInterventoController", Trace.TipoIntervento.tipoInterventoController);