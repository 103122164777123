module Trace.Produzione {
    export class AnomaliaStrumentoVm implements AnomaliaStrumento {
        public FlgGuasto: boolean;
        public FlgMancante: boolean;
        public FlgEsaurimento: boolean;
        public Qta: number;
        public QtaMax: number;
        public QtaContati: number;
        public Id: number;
        public Note: string;
        public DataClickConfezionamento: Date;
        public FlgNote: boolean;
        public FlgValidazione: boolean;
        public NumeroBuste: number;
    }
}