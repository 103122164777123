module Trace.ViewModels {
    export class PaginationVm {
        public TotalPages: number;
        public TotalItems: number;
        public CurrentPage: number;
        public ItemsPerPage: number;
    }

    export class GenericPaginationVm<T> {
        public TotalPages: number;
        public TotalItems: number;
        public CurrentPage: number;
        public ItemsPerPage: number;
        public List: Array<T>;
    }

    export class PagesVm {
        public fromPage: number;
        public toPage: number;
    }
}