module Trace.Stampe {
    export class smistamentoStrumentiFattureController extends epBaseController {
        static $inject = ["$scope", "Upload", "stampeFactory", "utenteFactory", "prodottiFactory", "documentoFactory", "localeFactory", "msgFactory", "$uibModal", "tracciabilitaFactory"];
        private lista: Array<any>;
        private listaFatture: Array<Documento.DocumentoVm>;
        private listaFattureFiltrate: Array<Documento.DocumentoVm>;
        private formSmistamentoStrumenti: any;
        private filtro: FiltroSmistamentoStrumentiFatture;
        private filtroFatture: FiltroFatture;

        constructor(
            private $scope: ng.IScope,
            public upload: ng.angularFileUpload.IUploadService,
            private stampeFactory: stampeFactory,
            public utenteFactory: Utente.utenteFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            private documentoFactory: Documento.documentoFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(upload, utenteFactory, undefined, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            this.formSmistamentoStrumenti = {};
            this.filtro = {
                ListaFatture: undefined
            };
            this.getFatture();
            this.setUpWatchers();
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.filtroFatture.Numero", (newVal: string, oldVal: string) => {
                this.applicaFiltroFatture();
            });
            this.$scope.$watch("vm.filtroFatture.Data", (newVal: string, oldVal: string) => {
                this.applicaFiltroFatture();
            });
        }

        private ricerca(): void {
            this.stampeFactory.RicercaOrdiniByFatture(this.filtro).then(
                result => {
                    this.lista = result;
                },
                reason => {

                }
            );
        }

        private applicaFiltroFatture(): void {
            if (this.listaFatture == undefined) return;
            this.listaFattureFiltrate = $.grep(this.listaFatture, (element) => {
                var result = true;
                console.log();
                if (this.filtroFatture != undefined) {
                    result = this.filtroFatture.Numero == undefined || this.filtroFatture.Numero === "" || element.Numero.indexOf(this.filtroFatture.Numero) >= 0;

                    if (this.filtroFatture.Data != undefined && this.filtroFatture.Data !== "") {
                        var elemData = moment(element.Data.toString().substring(0, 10), "YYYY-MM-DD").toDate();
                        result = result && elemData.getFullYear() == parseInt(this.filtroFatture.Data);
                    } else
                        result = result && true;
                }
                return result;
            });
        }

        private getFatture(): void {
            var filtro: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            filtro.colonne = [];
            filtro.colonne.push(new ViewModels.RicercaRigaVm("IdTipo", "5"));
            filtro.colonne.push(new ViewModels.RicercaRigaVm("IdFornitore", "1"));
            filtro.sort = [];
            filtro.sort.push(new ViewModels.SortRigaVm("Data", ViewModels.DirezioneSort.Discendente));
            this.documentoFactory.ricerca(filtro).then(
                result => {
                    this.listaFatture = result;
                    this.listaFattureFiltrate = angular.copy(this.listaFatture);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason]));
                    console.log("errore", reason);
                }
            );
        }

        private init(stato: string): void {
            this.currentState = stato;
        }

        private apriModalFatture(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Stampe/modalFatture.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.filtroFatture = new FiltroFatture();
            this.$modalInstance = this.$uibModal.open(options);
        }

        private selezionaFatture(): void {
            this.filtro.ListaFatture = new Array<number>();
            $.each($("#formSelezioneFatture input.checkSet:checked"), (index, item) => {
                this.filtro.ListaFatture.push(parseInt($(item).val()));
            });
            this.chiudiModal();
        }

        private getExportUrl(): string {
            var result = appRoot + "Reports/ExportSmistamentoStrumentiFatture?strFiltro=" + JSON.stringify(this.filtro);
            return result;
        }
    }
}
app.controller("smistamentoStrumentiFattureController", Trace.Stampe.smistamentoStrumentiFattureController);