namespace Trace.Messages {
    export class ModalSizes {
        static _sm: string = "sm";
        static _l: string = "l";
        static _xl: string = "xl";
        static _xxl: string = "xxl";
    }

    export class ModalButton {
        public label: string;
        public style: string;
        public buttonBinding: ModalResultButton;
        public boolResult: boolean;
        public objectResult: any;
        public messageResult: string;
    }

    export class ModalResult {
        public button: ModalResultButton;
        public result: boolean;
        public message: string;
        public object: any;
    }

    export enum ModalResultButton {
        OK,
        YES,
        NO,
        CONFIRM,
        CANCEL,
    }

    export class msgController {
        public ricercaUidResult: Produzione.CambioStatoResult;
        static controllerId = "msgController";
        static $inject = ["$uibModalInstance", "localeFactory", "message", "title", "buttons", "produzioneFactory"];
        constructor(
            private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
            public localeFactory: Trace.Localization.localeFactory,
            public message: string,
            public title: string,
            public buttons: Array<ModalButton>,
            public produzioneFactory: Produzione.produzioneFactory) { }

        public ok(): void {
            var result = new ModalResult();
            result.button = ModalResultButton.OK;
            result.result = true;
            this.$uibModalInstance.close(result);
        }

        public confirm(btn: ModalResultButton): void {
            var result = new ModalResult();
            result.button = btn;
            result.result = btn == ModalResultButton.YES;
            this.$uibModalInstance.close(result);
        }

        public buttonClickHandler(btn: ModalButton): void {
            var result = new ModalResult();
            result.button = btn.buttonBinding;
            result.result = btn.boolResult;
            result.message = btn.messageResult;
            result.object = eval(btn.objectResult);
            this.$uibModalInstance.close(result);
        }

        public ricercaUid(): void {
            var uid = $("form#uid input#uid").val();
            this.produzioneFactory.GetLottoByUid(uid).then(
                result => {
                    this.ricercaUidResult = result;
                }
            );
        }
    }

    export class msgFactory {
        private $modalInstance: ng.ui.bootstrap.IModalServiceInstance;
        static $inject = ["ui.bootstrap", "localeFactory"];
        private options: ng.ui.bootstrap.IModalSettings;
        constructor(private $uibModal: ng.ui.bootstrap.IModalService, private localeFactory: Trace.Localization.localeFactory) {
            this.options = {
                windowClass: "modal-styled",
                controller: msgController.controllerId + " as modal"
            }
        }

        public alert(msg: string, title: string = "Trace", size: string = ModalSizes._xl, okLabel: string = this.localeFactory.get("button_ok")): ng.IPromise<ModalResult> {
            var buttons = new Array<ModalButton>();
            var b = new ModalButton();

            this.options.windowClass = "modal-styled";

            b.boolResult = true;
            b.buttonBinding = ModalResultButton.OK;
            b.label = okLabel;
            b.style = "secondary";

            buttons.push(b);

            return this.showModal(msg, title, "alert.html", size, buttons, false);
        }

        public modalAlert(msg: string, title: string = "Trace", size: string = ModalSizes._xl, okLabel: string = this.localeFactory.get("button_ok")): ng.IPromise<ModalResult> {
            var buttons = new Array<ModalButton>();
            var b = new ModalButton();

            this.options.windowClass = "modal-styled";

            b.boolResult = true;
            b.buttonBinding = ModalResultButton.OK;
            b.label = okLabel;
            b.style = "secondary";

            buttons.push(b);

            return this.showModal(msg, title, "alert.html", size, buttons, true);
        }

        public confirm(msg: string, title: string = "Trace", size: string = ModalSizes._xl, yesLabel: string = this.localeFactory.get("button_si"), noLabel: string = this.localeFactory.get("button_no")): ng.IPromise<ModalResult> {
            var buttons = new Array<ModalButton>();
            var yes = new ModalButton();
            var no = new ModalButton();

            this.options.windowClass = "modal-styled";

            yes.boolResult = true;
            yes.buttonBinding = ModalResultButton.YES;
            yes.label = yesLabel;
            yes.style = "success";
            no.boolResult = false;
            no.buttonBinding = ModalResultButton.NO;
            no.label = noLabel;
            no.style = "danger";
            buttons.push(no);
            buttons.push(yes);
            return this.showModal(msg, title, "alert.html", size, buttons, true);
        }

        public error(msg: string, title: string = "Errore - Trace", size: string = ModalSizes._xl, okLabel: string = this.localeFactory.get("button_ok")): ng.IPromise<ModalResult> {
            var buttons = new Array<ModalButton>();
            var b = new ModalButton();

            this.options.windowClass = "modal-error";

            b.boolResult = true;
            b.buttonBinding = ModalResultButton.OK;
            b.label = okLabel;
            b.style = "secondary";


            buttons.push(b);

            return this.showModal(msg, title, "error.html", size, buttons, false);
        }

        public showModal(msg: string, title: string, templateUrl: string, size: string, buttons: Array<ModalButton>, waitForAnswere: boolean, modalStyle: string = undefined, values: ViewModels.DictionaryItem[] = undefined): ng.IPromise<ModalResult> {
            if (modalStyle != undefined)
                this.options.windowClass = modalStyle;
            this.options.templateUrl = "/views/Shared/" + templateUrl;
            this.options.size = size;
            this.options.resolve = {
                localeFactory: () => this.localeFactory,
                message: () => msg,
                title: () => title,
                buttons: () => buttons
            };
            if (waitForAnswere) {
                this.options.keyboard = false;
                this.options.backdrop = "static";
            }
            else {
                this.options.keyboard = true;
                this.options.backdrop = true;
            }
            this.$modalInstance = this.$uibModal.open(this.options);
            if (values != undefined && values.length > 0) {
                this.$modalInstance.opened.then(() => {
                    setTimeout(function () {
                        $.each(values, (idv, v) => {
                            $(v.Key).val(v.Value);
                        });
                    }, 500);
                });
            }
            return this.$modalInstance.result as ng.IPromise<ModalResult>;
        }
    }
}
app.factory("msgFactory", ($uibModal, localeFactory) => new Trace.Messages.msgFactory($uibModal, localeFactory));
app.controller("msgController", Trace.Messages.msgController);