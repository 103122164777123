namespace Trace {
    export class epBaseController {
        public $modalInstance: ng.ui.bootstrap.IModalServiceInstance;
        public $modalInstance2: ng.ui.bootstrap.IModalServiceInstance;
        public currentState: string;
        public isSaving: boolean;
        public titoloManipola: string;
        public showValidationMessages: boolean;
        public editMode: boolean;
        public idProdottoSelected: number;
        public set: Set.SetVm;
        public strumentoSelezionato: Set.StrumentoVm;
        public distinta: Trace.Prodotto.DistintaVm[];
        public titolo = "";
        public myRoot: epRootScope;
        public ultimaLetturaBarcode: Produzione.LetturaBarcode;
        public dbCulture: string;
        public culture: string;
        public applicationSession: Utente.ApplicationSessionVm;
        public listGestioneImmagini: Array<ViewModels.ImmagineVm>;
        public currentUserIsAdministrator: boolean;
        public selectedModule: string;
        public modulePassword: string;
        public modulePasswordOk: boolean;
        public moduleUrl: string;
        private queryResult: Array<Tracciabilita.QuerySearchResultVm>;
        public flgNuovaRiga: boolean;

        constructor(
            public Upload: ng.angularFileUpload.IUploadService,
            public utenteFactory: Utente.utenteFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public tntFactory: Tnt.tntFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public $rootScope: ng.IRootScopeService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        )
        {
            this.editMode = false;
            this.currentUserIsAdministrator = false;
            this.myRoot = <epRootScope>$rootScope;
            this.dbCulture = dbCulture;
            this.culture = culture;
            $(document).on("barcode", (e: JQueryEventObject) => {
                    this.barcodeEventHandler(e);
                })
                .on("mainNavQuerySearch", (e: JQueryEventObject) => {
                    this.querySearch(e.queryString);
                });
            this.getUserSession();
        }

        private querySearch(query: string): void {
            this.tracciabilitaFactory.QuerySearch(query).then(
                result => {
                    console.log("risultato query search", result);
                    triggerQuerySearchEvent("querySearchDone", "");
                    this.queryResult = result;
                },
                error => {
                    console.log("errore query search", error);
                    triggerQuerySearchEvent("querySearchDone", "");
                    this.queryResult = undefined;
                }
            )
        }

        private closeQueryResult(): void {
            this.queryResult = undefined;
            $("#mainNavQuerySearch").val("");
        }

        public copiaDaBarcode(barcode: string): string {
            if (barcode == undefined || barcode.length < Globals.LunghezzaBarcodeSet) return;
            var copia = barcode.substr(11, 2);
            return copia;
        }

        public containerDaBarcode(barcode: string): string {
            if (barcode == undefined || barcode.length < Globals.LunghezzaBarcodeSet) return;
            var cont = barcode.substr(13, 1);
            return cont;
        }

        private gestioneImmagini(immagini: ViewModels.ImmagineVm[], $scope: ng.IScope): void {
            var vm = this;
            this.listGestioneImmagini = immagini;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Shared/modalImmagini.html',
                scope: $scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.rendered.then(() => {
                $(".datetime").datepicker({ minDate: new Date() });
            });
            this.$modalInstance.result.then(() => {
                vm.listGestioneImmagini = undefined;
            });
        }

        public removeImage(image: ViewModels.ImmagineVm): void {
            this.msgFactory.confirm(this.localeFactory.get('rimozione_immagine_conferma')).then(
                result => {
                    if (!result.result) return;
                    triggerRemoveImage(image);
                }
            );
        }

        private getUserSession(): void {
            console.log("utente factory", this.utenteFactory);
            if (this.utenteFactory == undefined) return;
            this.utenteFactory.sessioneCorrente().then(
                result => {
                    this.applicationSession = result;
                    this.currentUserIsAdministrator = this.isAdministrator();
                    triggerEvent("gotUserSession", null);
                }
            );
        }

        public isAdministrator(): boolean {
            if (this.applicationSession == undefined) return false;
            console.log("Privilegi", this.applicationSession.IdPrivilegi.toLowerCase());
            var privilegi = ["root", "super", "admin"];
            var result = privilegi.indexOf(this.applicationSession.IdPrivilegi.toLowerCase()) >= 0;
            console.log("Privilegi", this.applicationSession.IdPrivilegi.toLowerCase(), result);
            return result;
        }

        public scrollTo($document: any, id: string): ng.IPromise<any> {
            var vm = this;
            var top = angular.element(document.getElementById(id));
            return $document.scrollToElement(top, 20, 300);
        }

        public somma(elem: any, proprieta: string): number {
            if (elem == undefined || $.isArray(elem) === false) return 0;
            var somma = 0;
            $.each(elem, (index, item) => {
                somma += item[proprieta];
            });
            //console.log(elem);
            //console.log("strumenti epBaseController.somma(): " + somma);
            return somma;
        }

        public checkBarcodeText(text: string): number {
            var result = TipoBarcode.NONE;
            var prefisso = "";
            // Funzione per il controllo di congruità della lettura del barcode reader
            switch (text.charAt(0).toLowerCase()) {
                case "a":
                    prefisso = text.substr(0, 4).toLowerCase();
                    // E' stato letto il codice di un termodisinfettore
                    if (prefisso === "aut-") {
                        result = TipoBarcode.AUTOCLAVE;
                    }
                    break;

                case "t":
                    prefisso = text.substr(0, 4).toLowerCase();
                    // E' stato letto il codice di un termodisinfettore
                    if (prefisso === "ter-") {
                        result = TipoBarcode.TERMODISINFETTORE;
                    }
                    // TNT STERILI NON UJET TLxxxxxx => <id> in EtichetteTnt
                    else if (text.length == 8 && text.substr(0, 2).toLocaleLowerCase() == Globals.PrefissoTntLotto.toLocaleLowerCase()) {
                        result = Trace.TipoBarcode.TNT_LOTTO;
                        //text = text.substring(2);
                        //text = parseInt(text).toString();
                        break;
                    }
                    break;

                // E' stato letto il codice a barre di un set
                case "s":
                    // codice generico del set
                    if (text.length == Globals.LunghezzaBarcodeSet) {
                        result = TipoBarcode.SET;
                    }
                    // Container o speifica griglia del set
                    else if (text.length == Globals.LunghezzaBarcodeDettaglioSet) {
                        var specifica = text.charAt(Globals.LunghezzaBarcodeDettaglioSet - 1);
                        if (parseInt(specifica) >= Globals.LimiteInferioreBarcodeSet && parseInt(specifica) <= Globals.LimiteSuperioreBarcodeSet)
                            result = parseInt(specifica);
                    }
                    // Codici dei set in busta
                    else if ((text.length >= Globals.LunghezzaBarcodeSetInBusta && text.length <= Globals.LunghezzaBarcodeSetInBusta + 3) && text.substr(0, Globals.PrefissoMaterialeSfuso.length) == Globals.PrefissoMaterialeSfuso) {
                        result = TipoBarcode.SET;
                    }
                    break;

                case "c":
                    prefisso = text.substr(0, 2);
                    // E' stato letto il codice di una cesta sfusa
                    if (text.length == Globals.LunghezzaBarcodeGrigliaSfusa && prefisso.toLowerCase() == Globals.PrefissoCesta.toLowerCase()) {
                        result = TipoBarcode.CESTA;
                    }
                    // E' stato letto il codice di un centro di costo da una stampa
                    else if (text.length == Globals.LunghezzaBarcodeCentroCosto && prefisso.toLowerCase() === Globals.PrefissoCentroCosto.toLowerCase()) {
                        result = TipoBarcode.CENTROCOSTO;
                    }  
                    // CONSUMABILI CLxxxxxx => <id> in EtichetteConsumabili
                    else if (text.length == 8 && prefisso.toLocaleLowerCase() == Globals.PrefissoConsumabileLotto.toLocaleLowerCase()) {
                        result = Trace.TipoBarcode.CONSUMABILE_LOTTO;
                        //text = text.substring(2);
                        //text = parseInt(text).toString();
                        break;
                    }
                    break;

                // E' stato letto il codice di una griglia sfusa
                case "g":
                    prefisso = text.substr(0, 2);
                    if (text.length == Globals.LunghezzaBarcodeGrigliaSfusa && prefisso.toLowerCase() == Globals.PrefissoGrigliaSfusa.toLowerCase()) {
                        result = TipoBarcode.GRIGLIASFUSA;
                    }
                    else if (text.length == Globals.LunghezzaBarcodeGrigliaSet) {
                        var specifica = text.charAt(Globals.LunghezzaBarcodeGrigliaSet - 1);
                        result = parseInt(specifica);
                    }
                    break;

                default:
                    // Codice EAN-128
                    if (text.substr(0, 2).toLowerCase() === "+c" || text.substr(0, 2).toLowerCase() === "]c")
                        text = text.substr(3);
                    if (text.length >= 28)
                        result = Trace.TipoBarcode.EAN;
                    else if (text.length >= 9 && text.length <= 14 && (text.substr(0, 1).toLowerCase() === "z" || text.substr(8, 1).toLowerCase() === "q"))
                        result = Trace.TipoBarcode.EAN2;
                    else if (text.substr(0, 1) === "$") {
                        var lastQindex = this.lastIndexOf(text, "q");
                        result = (lastQindex != undefined && !isNaN(parseFloat(text.substr(lastQindex + 1))))
                            ? Trace.TipoBarcode.EAN2
                            : Trace.TipoBarcode.PRODOTTO;
                    }
                    else
                        result = Trace.TipoBarcode.PRODOTTO;
                    break;
            }
            console.log("codice letto", text, "risultato del controllo codice", result);
            return result;
        }

        public lastIndexOf(str: string, char: string): number {
            var indices = new Array<number>();
            for (var i = 0; i < str.length; i++) {
                if (str[i].toLowerCase() === char.toLowerCase()) indices.push(i);
            }
            return indices.length === 0 ? undefined : indices[indices.length - 1];
        }

        public decodeEan(codice: string, fittizio: boolean): ViewModels.EanCodeInfoVm {
            var result = new ViewModels.EanCodeInfoVm();
            result.Fittizio = fittizio;

            var prefisso = codice.substr(0, 3);
            var pipeIndex = this.lastIndexOf(codice, "|");

            var codArt = "";
            var lotto = "";
            var strDataScadenza = "";
            var qta = 1;
            if (pipeIndex !== undefined) {
                codArt = prefisso == '240' ? codice.substring(3, pipeIndex) : codice.substring(0, pipeIndex);
                lotto = codice.substr(pipeIndex + 2, 10);
                strDataScadenza = "20" + codice.substr(pipeIndex + 14, 6);
                qta = parseInt(codice.substring(pipeIndex + 22));
            }
            else {
                // #Lorenzo modifica nella lettura del barcode
                // i codici dei tnt sono stati accorciati di un caratttere
                // in questo modo leggo qualsiasi codice di qualsiasi lunghezza

                //var splitCodice = codice.split(")");

                //console.log(splitCodice);
                //codArt = splitCodice[1].split("(")[0];
                //lotto = splitCodice[2].split("(")[0];
                //strDataScadenza = splitCodice[3];


                codArt = codice.substr(3, 7);

                var idxStartLotto = codice.indexOf(codArt) + codArt.length + 2;
                var lenLotto = codice.substring(idxStartLotto + 6).indexOf("17") + 6;
                lotto = codice.substr(idxStartLotto, lenLotto);

                var idxStartDataScadenza = codice.indexOf("10" + lotto + "17") + 2 + lotto.length + 2;
                strDataScadenza = "20" + codice.substr(idxStartDataScadenza, 6);
                
                if (prefisso === "240") {
                    var idxQta = idxStartDataScadenza + 6 + 2;
                    var qta = parseInt(codice.substring(idxQta));
                }
            }

            result.Originale = codice;
            result.CodiceProduttore = codArt;
            result.Lotto = lotto;
            result.DataScadenzaLotto = moment(strDataScadenza, "YYYYMMDD").toDate();
            result.Unita = qta;

            return result;
        }

        private simulaEan(lettura: string) {
            var eancode = "";

            // Se è il codice di un TNT non Sterile
            if (lettura[0] !== "$")
                eancode = "+C1240" + lettura.substr(1, 7) + "|100000000001799013137" + lettura.substr(9);
            // Se è il codice di un materiale di consumo
            else {
                var lastQindex = this.lastIndexOf(lettura, "q");
                var qta = parseFloat(lettura.substr(lastQindex + 1));
                if (lastQindex != undefined && !isNaN(qta)) 
                    eancode = "+C1240" + lettura.substring(1, lastQindex) + "|100000000001799013137" + qta.toString();
            }

            triggerBarcode(eancode, true);
        }

        public decodeConsumabileLotto() {
            var id = parseInt(this.ultimaLetturaBarcode.barcode.substring(2));            
            this.prodottiFactory.decodeConsumabileLotto(id).then(
                result => {
                    this.ultimaLetturaBarcode.ean = result;
                    triggerEvent("consumabileLotto", this.ultimaLetturaBarcode.testo);
                }
            );
        }

        public decodeTntLotto(lettura: string = undefined) {
            if (lettura == undefined)
                lettura = this.ultimaLetturaBarcode.barcode;
            var id = parseInt(lettura.substring(2));
            this.tntFactory.decodeTntLotto(id).then(
                result => {
                    this.ultimaLetturaBarcode.ean = result;
                    triggerEvent("tntLotto", this.ultimaLetturaBarcode.testo);
                }
            );
        }

        public barcodeEventHandler(e: JQueryEventObject): void {
            var checkResult = this.checkBarcodeText(e.lettura);
            if (checkResult == Trace.TipoBarcode.EAN2) {
                this.simulaEan(e.lettura);
                return;
            }
            this.ultimaLetturaBarcode = new Trace.Produzione.LetturaBarcode(e.lettura, checkResult);
            console.log("ultimaLetura", this.ultimaLetturaBarcode);
            if (checkResult == Trace.TipoBarcode.EAN) {
                var ean = this.decodeEan(e.lettura.substr(3), e.fittizio);
                this.ultimaLetturaBarcode.ean = ean;
                console.log("EAN decodificato", ean);
                triggerEanEvent("eanCode", ean);
                return;
            }

            if (checkResult == Trace.TipoBarcode.CONSUMABILE_LOTTO) { // #LUCA
                this.ultimaLetturaBarcode.barcode = e.lettura;
                this.ultimaLetturaBarcode.testo = e.lettura;
                this.decodeConsumabileLotto();
                return;
            }

            if (checkResult == Trace.TipoBarcode.TNT_LOTTO) {
                this.ultimaLetturaBarcode.barcode = e.lettura;
                this.ultimaLetturaBarcode.testo = e.lettura;
                this.decodeTntLotto();
                return;
            }

            if (e.lettura.length != Trace.Globals.LunghezzaBarcodeDettaglioSet && (checkResult == Trace.TipoBarcode.GRIGLIA1 ||
                checkResult == Trace.TipoBarcode.GRIGLIA2 ||
                checkResult == Trace.TipoBarcode.GRIGLIA3)) {
                //this.decodeGriglia(e.lettura, checkResult);
                return;
            }
            triggerEvent("barcodeOk", this.ultimaLetturaBarcode.testo);
        }

        public chiudiModal(): void {
            if (this.$modalInstance == undefined) {
                console.warn("Nessuna istanza di modal aperta. Impossibile continuare");
                return;
            }
            this.$modalInstance.close();
            this.$modalInstance.dismiss('cancel');
        }

        public barcodeReaderGotFocus(): void {
            this.myRoot.barcodeReaderActive = true;
        }

        public barcodeReaderLostFocus(): void {
            this.myRoot.barcodeReaderActive = false;
        }

        public formatCurrency(currency: number, valuta: string = "€"): string {
            if (currency == undefined)
                currency = 0.0; 
            var result = currency.toFixed(2) + " " + valuta;
            return result;
        }
        
        public formatDate(data: Date, format: string = "DD/MM/YYYY"): string {
            var result = moment(data).format(format);
            return result;
        }

        public formatNullableDate(data: Date, ifNull: string = "", format: string = "DD/MM/YYYY"): string {
            return (data == null || data == undefined)
                ? ifNull
                : this.formatDate(data, format);
        }

        public infoProdotto(prodotto: Trace.Set.StrumentoVm): void {
            console.log("Dettagli prodotto", prodotto.IdProdotto, prodotto.HasDistinta);
            this.strumentoSelezionato = prodotto;
            // Distinta
            this.distintaProdotto(prodotto.IdProdotto, prodotto.HasDistinta);
            // Immagini
            if (prodotto.IdProdotto != undefined) this.immaginiProdotto(prodotto.IdProdotto);
            else this.strumentoSelezionato.Immagini = [];
        }

        public immaginiProdotto(idProdotto: number): void {
            var vm = this;
            this.prodottiFactory.immagini(idProdotto).then(
                // OK
                result => {
                    this.strumentoSelezionato.Immagini = result;
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_lettura_immagini_errore", [errore]));
                }
            );
        }

        public distintaProdotto(idProdotto: number, hasDistinta: boolean): void {
            if (!hasDistinta) {
                this.distinta = [];
                return;
            }

            var vm = this;
            this.prodottiFactory.distinta(idProdotto).then(
                // OK
                risultato => {
                    vm.distinta = risultato;
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_spezza_errore", [errore]));
                }
            );
        }

        public descrizioneStrumentoSelezionato(): string {
            if (this.strumentoSelezionato == undefined) return "";
            switch (culture) {
                case "it":
                    return this.strumentoSelezionato.DescrizioneIt;
                case "al":
                    return this.strumentoSelezionato.DescrizioneAl;
                case "en":
                    return this.strumentoSelezionato.DescrizioneEn;
            }
        } 

        public ricercaUid(flgProduzione: boolean = false): void {
            var buttons = new Array();
            var b = new Trace.Messages.ModalButton();
            var b2 = new Trace.Messages.ModalButton();
            var b4 = new Trace.Messages.ModalButton();

            b.boolResult = true;
            b.buttonBinding = Trace.Messages.ModalResultButton.CONFIRM;
            b.label = this.localeFactory.get("dettagli_strumento");
            b.style = "default";
            b.objectResult = "$('form#uid #idStrumento').val()";
            b.messageResult = "dettStrumento";

            b4.boolResult = true;
            b4.buttonBinding = Trace.Messages.ModalResultButton.CONFIRM;
            b4.label = this.localeFactory.get("dettagli_set");
            b4.style = "info";
            b4.objectResult = "$('form#uid #idSet').val()";
            b4.messageResult = "dettSet";
            
            b2.boolResult = false;
            b2.buttonBinding = Trace.Messages.ModalResultButton.CANCEL;
            b2.label = this.localeFactory.get("chiudi");
            b2.style = "danger";

            buttons.push(b2);
            buttons.push(b);
            buttons.push(b4);
            if (flgProduzione) {
                var b3 = new Trace.Messages.ModalButton();
                b3.boolResult = true;
                b3.buttonBinding = Trace.Messages.ModalResultButton.OK;
                b3.label = this.localeFactory.get("lavorabile_fase");
                b3.style = "facebook";
                b3.objectResult = "$('form#uid #barcode').val()";
                b3.messageResult = "lavoraSet";
                buttons.push(b3);
            }
            this.msgFactory.showModal(this.localeFactory.get("label_ricerca_uid"), this.localeFactory.get("titolo_ricerca_uid"), "ricercaUid.html", "md", buttons, true).then(
                result => {
                    if (!result.result) return;
                    console.log(result);
                    switch (result.message) {
                        case "dettStrumento":
                            document.location.assign(appRoot + "Prodotti/?id=" + result.object);
                            break;

                        case "dettSet":
                            document.location.assign(appRoot + "Set/?id=" + result.object);
                            break;

                        case "lavoraSet":
                            triggerEvent("barcode", result.object);
                            break;
                    }
                }
            );
        }

        public distinct<T>(collection: Array<T>, keyNames: Array<string>): Array<T> {
            var output = [],
                keys = [];

            angular.forEach(collection, (item, index) => {
                var stringaChiave = "";
                $.each(keyNames, (id, itm) => {
                    stringaChiave += "-" + item[itm];
                });
                var key = stringaChiave;
                if (keys.indexOf(key) === -1) {
                    keys.push(key);
                    output.push(item);
                }
            });

            return output;
        }

        public localized(item: any, campo: string): string {
            if (item == undefined) return "";
            var result = item[campo + this.dbCulture];
            if (result == undefined)
                result = item[campo + this.culture.capitalize()];
            
            return result;
        }

        public sum(array: any[], property: string): number {
            if (array == undefined || array.length <= 0) return 0;
            var result: number = 0;
            $.each(array, (index, item) => {
                result += item[property];
            });

            return result;
        }

        public localizedPropertyName(name: string, flgDbCulture: boolean = true): string {
            var result = name + (flgDbCulture ? this.dbCulture : this.culture.capitalize());
            return result;
        }

        public checkFile($file: File[], tipo: string, isImage: boolean = true): void {
            if ($file == null) return;
            if ($file.length <= 0) {
                if (isImage) {
                    this.msgFactory.error(this.localeFactory.getFormatted("upload_immagine_dimensione", [Globals.UploadMaxFileSize]));
                    return;
                }
            }
            $.each($file, (index, item) => this.onFileSelect(item, tipo, isImage));
        }

        public onFileSelect($file: File, tipo: string, isImage: boolean = true) {
            var vm = this;
            var file = $file;
            this.Upload.upload({
                url: appRoot + "api/Upload",
                method: "POST",
                data: { tipo: tipo },
                file: file
            })
                .abort()
                .xhr((evt: any) => {
                    console.log('xhr');
                })
                .progress((evt: angular.angularFileUpload.IFileProgressEvent) => {
                    if (evt.config.file == undefined) return;
                    var percent = parseInt((100.0 * evt.loaded / evt.total).toString(), 10);
                    console.log("upload progress: " + percent + "% for " + evt.config.file.name);
                })
                .error((data: any, status: number, response: any, headers: any) => {
                    console.error(data, status, response, headers);
                    if (status === 400 && data != undefined && data.Message != undefined) {
                        this.msgFactory.error(this.localeFactory.getFormatted("upload_immagine_errore2", [file.name, data.Message]));
                    }
                })
                .success((data: Trace.ViewModels.UploadResultVm, status: number, headers: any, config: angular.angularFileUpload.IFileUploadConfigFile) => {
                    if (isImage) {
                        var img = new Trace.ViewModels.ImmagineVm();
                        img.thumb = appRoot + data.Url;
                        img.img = appRoot + data.Url;
                        img.description = data.Name;
                        img.baseUrl = data.Url;
                        triggerImageEvent("imageUpload", img);
                    }
                    else {
                        console.log("Upload completato", data);
                        triggerUploadEvent("fileUpload", tipo, data.Name, file.name);
                    }
                });
        }

        public checkModulePassword(label: string, url: string, $scope: ng.IScope, goToUrl: boolean = true): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Shared/modulePassword.html',
                scope: $scope,
                windowClass: "modal-styled",
                size: "md"
            };

            this.selectedModule = label;
            this.moduleUrl = goToUrl === true ? url : undefined;
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.rendered.then(() => {
                $(".datetime").datepicker({ minDate: new Date() });
            });
            this.$modalInstance.result.then(() => {
                vm.selectedModule = undefined;
                vm.modulePasswordOk = undefined;
                vm.moduleUrl = undefined;
            });
        }

        public moduleLogin(): void {
            this.utenteFactory.moduleLogin(this.modulePassword).then(
                result => {
                    this.modulePasswordOk = result;
                    if (result === true && this.moduleUrl != undefined) {
                        window.location.assign(this.moduleUrl.replace("~", ""));
                    }

                    triggerEvent("moduleLogin", null);
                }
            );
        }

        public now(): string {
            return moment().format('HH:mm:ss');
        }

        public siNo(valore: boolean): string {
            return (valore)
                ? "<span class='text-success'>Sì</span>"
                : "<span class='text-danger'>No</span>";
        }

        //public decodeGriglia(codice: string, checkResult: number): void {
        //    //this.setFactory.DecodificaGriglia(codice).then(
        //    //    result => {
        //    //        this.ultimaLetturaBarcode = new Produzione.LetturaBarcode(result, checkResult, null, codice);
        //    //        console.log("griglia decodificata", result, this.ultimaLetturaBarcode);
        //    //        triggerEvent("barcodeOk", this.ultimaLetturaBarcode.barcode);
        //    //    },
        //    //    reason => {

        //    //    }
        //    //);
        //    $.each(this.lista, (idl, l) => {
        //        $.each(l.);
        //    });
        //}
    }
}