module Trace.Set {
    export class RicercaSet {
        public Id: number;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdTipo: string;
    }

    export class setController extends epBaseController {
        public modello: SetVm;
        public strumenti: StrumentoVm[];
        public modelli: SetVm[];
        public tipoSet: TipoSetVm;
        private complessita: ComplessitaVm;
        public listaSet: SetVm[];
        public prodotti: Trace.Prodotto.ProdottoVm[];
        public gruppi: Trace.Gruppo.Gruppo[];
        public gruppiRicerca: Trace.Gruppo.Gruppo[];
        public sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        public sottoGruppiRicerca: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public sediRicerca: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];
        public centriCostoRicerca: Trace.CentroCosto.CentroCosto[];
        public tipiSet: TipoSetVm[];
        public listaUs: UnitaSterilizzazioneVm[];
        public listaComplessita: ComplessitaVm[];
        public tipiSterilizzazione: Trace.Set.TipoSterilizzazioneVm[];
        public proprietari: Trace.ViewModels.ProprietarioVm[];
        public dt: Trace.datatables.dtFactory;
        public ricerca: RicercaSet;
        public ricercaProdotti: Trace.Prodotto.RicercaProdotti;
        public ricercaModello: ModelliSet.RicercaModelliSet;
        public enableUs: boolean;
        public formSet: any;
        private prefissoCodice = "S";
        public codiceMarcatura: string;
        public codiceInUso: boolean;
        public listaEtichette: ViewModels.DictionaryItem[];
        public raggruppamenti: Helpers.SimpleEntity[];

        static $inject = ["$scope", "$rootScope", "dtFactory", "modelliSetFactory", "setFactory", "prodottiFactory", "gruppiFactory",
            "sottoGruppiFactory", "sediFactory", "centriCostoFactory", "Upload", "localeFactory",
            "msgFactory", "$uibModal", "$document", "utenteFactory", "tracciabilitaFactory"];

        constructor(
            private $scope: ng.IScope,
            public $rootScope: ng.IRootScopeService,
            private dtFactory: Trace.datatables.dtFactory,
            private modelliSetFactory: ModelliSet.modelliSetFactory,
            public setFactory: setFactory,
            public prodottiFactory: Trace.Prodotto.prodottiFactory,
            private gruppiFactory: Trace.Gruppo.gruppiFactory,
            private sottoGruppiFactory: Trace.SottoGruppo.sottoGruppiFactory,
            private sediFactory: Trace.Sede.sediFactory,
            private centriCostoFactory: Trace.CentroCosto.centroCostoFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            private $document: any,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(Upload, utenteFactory, setFactory, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);
            var vm = this;
            this.formSet = {};

            this.setFactory.listaTipiSet().then((risultato: Trace.Set.TipoSetVm[]) => {
                this.tipiSet = risultato;
            });
            this.setFactory.listaUnitaSterilizzazione().then((risultato: Trace.Set.UnitaSterilizzazioneVm[]) => {
                this.listaUs = risultato;
            });
            this.setFactory.listaComplessita().then((risultato: Trace.Set.ComplessitaVm[]) => {
                this.listaComplessita = risultato;
            });
            this.setFactory.listaTipiSterilizzazione().then((risultato: Trace.Set.TipoSterilizzazioneVm[]) => {
                this.tipiSterilizzazione = risultato;
            });
            this.prodottiFactory.proprietari().then(risultato => {
                this.proprietari = risultato;
            });
            this.gruppiFactory.lista().then((risultato: Trace.Gruppo.Gruppo[]) => {
                this.gruppi = risultato;
                this.gruppiRicerca = risultato;
            });
            this.setFactory.listaRaggruppamenti().then(
                result => {
                    this.raggruppamenti = result;
                });
            $(document).on("imageUpload", (e: JQueryEventObject) => {
                vm.set.Immagini.push(e.image);
                vm.formSet.$setDirty();
            }).on("removeImage", (e: JQueryEventObject) => {
                var id = vm.set.Immagini.indexOf(e.image);
                vm.set.Immagini.splice(id, 1);
                vm.formSet.$setDirty();
            });
            this.setUpWatchers();
        }

        public gestioneRevisioneStrumento(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Set/modalListaStrumentiRevisione.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public checkDetailsId(): void {
            if (setId == undefined) this.changeState('list');
            else this.leggi(setId);
        }

        public imageUrl(immagine: string) {
            return appRoot + immagine;
        }

        public changeState(stato: string): void {
            var vm = this;
            this.currentState = stato;
            switch (stato) {
                case "list":
                    this.editMode = false;
                    this.set = null;
                    this.modello = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.strumentoSelezionato = undefined;
                    this.formSet.$setPristine();
                    $(".datetime").datepicker();
                    console.log("Edit mode", this.editMode);
                    if (this.editMode) return;
                    setTimeout(function () {
                        vm.calcolaCodice();
                    }, 1000);
                    break;
            }
        }

        public nuovo(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Set/modalListaModelli.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public selezionaModello(item: SetVm): void {
            this.modello = item;
        }

        public doNuovo(): void {
            if (this.modello == undefined) return;
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.changeState("manipola");
            var vm = this;
            this.modelliSetFactory.leggi(this.modello.Id).then(
                // OK
                modelloResult => {
                    this.modello = modelloResult;
                    // Ricerco il numero delle copie già create per questo set
                    this.setFactory.GetCopiaSet(this.modello.Id).then(
                        result => {
                            this.set = angular.copy(modelloResult);
                            this.set.Immagini = angular.copy(modelloResult.Immagini);
                            this.set.Id = undefined;
                            this.set.IdModelloSet = this.modello.Id;
                            this.set.NumCopia = result;
                            this.set.TotCopie = result;
                            this.generaSetBody();
                            this.$modalInstance.close();
                        }
                    );
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_nuovo_set_errore", [errore]));
                }
            );
        }

        private decodificaInformazioniSet(): void {
            var vm = this;
            var complessita = $.grep(this.listaComplessita, (c, idc) => {
                return c.Id === vm.set.IdComplessita;
            });
            var tipo = $.grep(this.tipiSet, (c, idc) => {
                return c.Id === vm.set.IdTipo;
            });
            var us = $.grep(this.listaUs, (c, idc) => {
                return c.Id === vm.set.IdUnitaSterilizzazione;
            });

            var gruppo = $.grep(this.gruppi, (c, idc) => {
                return c.Id === vm.set.IdGruppo;
            });
            var sottogruppo = $.grep(this.sottoGruppi, (c, idc) => {
                return c.Id === vm.set.IdSottogruppo;
            });
            var sede = $.grep(this.sedi, (c, idc) => {
                return c.Id === vm.set.IdSede;
            });
            var centroCosto = $.grep(this.centriCosto, (c, idc) => {
                return c.Id === vm.set.IdCentroCosto;
            });

            this.set.Complessita = complessita == undefined || complessita.length <= 0
                ? ""
                : complessita[0]["Descrizione_" + dbCulture];
            this.set.Tipo = tipo == undefined || tipo.length <= 0
                ? ""
                : tipo[0]["Descrizione_" + dbCulture];
            this.set.UnitaSterilizzazione = us == undefined || us.length <= 0
                ? ""
                : us[0]["Descrizione_" + dbCulture];

            this.set.Gruppo = gruppo == undefined || gruppo.length <= 0
                ? ""
                : gruppo[0]["Descrizione_" + dbCulture];
            this.set.Sottogruppo = sottogruppo == undefined || sottogruppo.length <= 0
                ? ""
                : sottogruppo[0]["Descrizione_" + dbCulture];
            this.set.Sede = sede == undefined || sede.length <= 0
                ? ""
                : sede[0]["Descrizione_" + dbCulture];
            this.set.CentroCosto = centroCosto == undefined || centroCosto.length <= 0
                ? ""
                : centroCosto[0]["Descrizione_" + dbCulture];
        }

        private generaSetBody(): void {
            var vm = this;
            this.strumenti = new Array<StrumentoVm>();
            $.each(this.modello.Corpo, (index, item) => {
                var qt = item.Qta;
                while (qt > 0) {
                    var s = new StrumentoVm();
                    s.Codice = item.Codice;
                    s.DescrizioneAl = item.DescrizioneAl;
                    s.DescrizioneEn = item.DescrizioneEn;
                    s.DescrizioneIt = item.DescrizioneIt;
                    s.FlgFermo = false;
                    s.IdModelloSetDetail = item.Id;
                    s.IdProdotto = item.IdProdotto;
                    s.IdProprietario = item.IdProprietario;
                    s.Matricola = item.Matricola;
                    s.Qta = 1;
                    s.Uid = "";
                    s.NumGriglia = item.Griglia;
                    s.NumSpillone = item.Spillone;
                    s.NumPosizione = item.Posizione;
                    s.HasDistinta = item.HasDistinta;
                    s.Ordinamento = item.Ordinamento;
                    s.FlgMarcatura = item.FlgMarcatura;
                    vm.strumenti.push(s);
                    qt--;
                }
            });

            console.log("lista strumenti", this.strumenti);
            this.marcaStrumenti();
        }

        public marcaStrumenti(): void {
            var vm = this;
            this.setFactory.marcaturaStrumenti(this.modello.Id, 0, this.strumenti).then(
                // OK
                result => {
                    this.strumenti = result;
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_marcatura_errore", [errore]));
                }
            );
        }

        public marcaStrumento(strumento: StrumentoVm): void {
            var vm = this;
            var array = new Array<StrumentoVm>();
            array.push(strumento);
            this.setFactory.marcaturaStrumenti(this.modello.Id, 0, array).then(
                // OK
                result => {
                    var idStrumento = vm.strumenti.indexOf(strumento);
                    vm.strumenti[idStrumento] = result[0];
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_marcatura_errore", [errore]));
                }
            );
        }

        public infoContainer(): string {
            if (this.set == undefined) return "";
            return this.localeFactory.getFormatted('container_associato', [this.set.NumContainer, this.set.TotContainer]);
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.set.IdGruppo", (newVal: number, oldVal: number) => {
                if (this.editMode) return;
                this.gruppoChanged(newVal);
                if (this.set != undefined)
                    this.set.Codice = "";
            });
            this.$scope.$watch("vm.ricerca.IdGruppo", (newVal: number, oldVal: number) => {
                if (oldVal == undefined && newVal == undefined) return;
                this.gruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.set.IdSottogruppo", (newVal: number, oldVal: number) => {
                if (this.editMode) return;
                this.sottoGruppoChanged(newVal);
                if (this.set != undefined)
                    this.set.Codice = "";
            });
            this.$scope.$watch("vm.ricerca.IdSottoGruppo", (newVal: number, oldVal: number) => {
                if (oldVal == undefined && newVal == undefined) return;
                this.sottoGruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.set.IdSede", (newVal: number, oldVal: number) => {
                if (this.editMode) return;
                this.sedeChanged(newVal);
                if (this.set != undefined)
                    this.set.Codice = "";
            });
            this.$scope.$watch("vm.ricerca.IdSede", (newVal: number, oldVal: number) => {
                if (oldVal == undefined && newVal == undefined) return;
                this.sedeRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.set.IdCentroCosto", (newVal: number, oldVal: number) => {
                if (this.editMode) return;
                if (this.set != undefined && newVal !== oldVal)
                    this.calcolaCodice();
            });
            this.$scope.$watch("vm.ricerca.IdCentroCosto", (newVal: number, oldVal: number) => {
                if (oldVal == undefined && newVal == undefined) return;
                this.dtFactory.aggiornaRicerca();
            });
        }

        public leggi(id: number): void {
            this.editMode = true;
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.setFactory.leggi(id).then((risultato: Trace.Set.SetVm) => {
                this.set = risultato;
                console.log("dettagli del set", risultato);
                this.strumenti = risultato.Strumenti;
                this.modelliSetFactory.leggi(this.set.IdModelloSet).then(modelloResult => {
                    this.modello = modelloResult;
                    this.changeState("manipola");
                });
            });
        }

        public isAttivo(attivo: boolean): string {
            var classe = attivo ? "success" : "danger";
            var label = attivo
                ? this.localeFactory.get("si")
                : this.localeFactory.get("no");

            return "<span class='text-" + classe + "'>" + label + "</span>";
        }

        public attiva(): void {
            var vm = this;
            var result = this.validazione();
            if (!result) return;
            
            this.msgFactory.confirm(this.localeFactory.get("messaggio_attivazione_conferma")).then(
                result => {
                    if (!result.result) return;
                    this.setFactory.attiva(this.set.Id).then(
                        result => {
                            this.isSaving = false;
                            this.set.FlgAttivo = true;
                            vm.msgFactory.alert(vm.localeFactory.get("messaggio_attivazione_ok"));
                        },
                        reason => {
                            this.isSaving = false;
                            this.set.FlgAttivo = false;
                            vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_attivazione_errore", [reason.toString()]));
                        }
                    );
                }
            );
        }

        public proprietaStrumentoChanged(item: StrumentoVm): void {
            this.marcaStrumento(item);
        }

        public calcolaCodice(): void {
            var vm = this;
            if (this.set == undefined) return;
            if (this.set.IdCentroCosto == undefined || this.set.IdCentroCosto === 0) {
                vm.set.Codice = "";
                return;
            }

            var info = new InfoBarcodeVm();
            info.Codice = this.modello.Codice;
            info.IdCentroCosto = this.set.IdCentroCosto;
            info.NumContainer = this.set.NumContainer.toString();
            info.NumCopia = this.set.NumCopia;

            console.log("Calcolo il barcode del set", info);
            this.setFactory.GetBarcode(info).then(
                result => {
                    this.set.Codice = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("generazione_barcode_errore"));
                }
            );

            //var codGruppo = $.grep(this.gruppi, (item: Trace.Gruppo.Gruppo, index: number) => { return item.Id === vm.set.IdGruppo; })[0].Codice;
            //var codSgruppo = "";
            //if (this.set.IdSottogruppo != undefined && this.set.IdSottogruppo > 0) {
            //    var tmp1 = $.grep(this.sottoGruppi, (item: Trace.SottoGruppo.SottoGruppo, index: number) => { return item.Id === vm.set.IdSottogruppo; });
            //    if (tmp1.length > 0)
            //        codSgruppo = tmp1[0].Codice;
            //}
            //var codSede = "";
            //if (this.set.IdSede != undefined && this.set.IdSede > 0) {
            //    var tmp2 = $.grep(this.sedi, (item: Trace.Sede.Sede, index: number) => { return item.Id === vm.set.IdSede; });
            //    if (tmp2.length > 0)
            //        codSede = tmp2[0].Codice;
            //}
            //var codCc = "";
            //if (this.set.IdCentroCosto != undefined && this.set.IdCentroCosto > 0) {
            //    var tmp3 = $.grep(this.centriCosto, (item: Trace.CentroCosto.CentroCosto, index: number) => { return item.Id === vm.set.IdCentroCosto; });
            //    if (tmp3.length > 0) {
            //        codCc = tmp3[0].Codice;
            //    }
            //}

            //if (codGruppo !== "" && codSgruppo !== "" && codSede !== "" && codCc !== "") {
            //    this.setFactory.codiceSet(this.set.IdCentroCosto).then((codice: string) => {
            //        vm.set.Codice = (this.prefissoCodice + codCc + codice + padNumber(this.set.NumCopia, 2) + this.set.NumContainer).toUpperCase();
            //    });
            //}
            //else
            //    vm.set.Codice = "";
        }

        public resetForm(): void {
            this.ricerca = new RicercaSet();
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                vm.changeState('list');
            });
        }

        public registra(): void {
            console.log("registraSetController");
            this.showValidationMessages = false;
            var result = this.validazione();
            if (!result) return;
            var noProprieta = $.grep(this.strumenti, (item: StrumentoVm, index: number) => {
                return item.IdProprietario == undefined || item.IdProprietario === 0;
            });
            var vm = this;
            if (noProprieta.length > 0) {
                this.msgFactory.confirm(this.localeFactory.getFormatted("conferma_noproprietario", [noProprieta.length.toString()])).then(
                    result => {
                        if (!result.result) return;
                        vm.doRegistra();
                    }
                );
            }
            else
                vm.doRegistra();
        }

        public validazione(): boolean {
            if (!this.formSet.$valid) {
                this.showValidationMessages = true;
                this.formSet.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                console.log("validazione", this.formSet);
                return false;
            }
            if (this.set.IdTipo === 4) return true;

            var noMarcatura = $.grep(this.strumenti, (item: StrumentoVm, index: number) => {
                return item.Uid == undefined || $.trim(item.Uid) === "";
            });
            if (noMarcatura.length > 0) {
                this.msgFactory.error(this.localeFactory.getFormatted("validazione_marcatura_errore", [noMarcatura.length.toString()]));
                return false;
            }
            if (this.strumenti == undefined || this.strumenti.length === 0) {
                this.msgFactory.error(this.localeFactory.get("validazione_nostrumenti_errore"));
                return false;
            }

            return true;
        }

        private doRegistra(): void {
            var vm = this;
            var conf = (this.editMode || this.set.TotContainer == undefined || this.set.TotContainer <= 1)
                ? vm.msgFactory.confirm(this.localeFactory.get("messaggio_salvataggio_conferma"))
                : vm.msgFactory.confirm(this.localeFactory.getFormatted("messaggio_salvataggio_conferma_associati", [this.set.TotContainer - 1]));
            
            conf.then(result => {
                if (!result.result) return;
                vm.set.Strumenti = vm.strumenti;
                vm.set.FlgAttivo = true;
                var promise = (vm.set.Id == undefined)
                    ? vm.setFactory.inserisci(vm.set)
                    : vm.setFactory.modifica(vm.set);

                promise.then(
                    result => {
                        var alertPromise = vm.msgFactory.alert(vm.localeFactory.get("messaggio_salvataggio_ok"));
                        alertPromise.then(result => { vm.changeState("list"); });
                    },
                    reason => {
                        vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_salvataggio_errore", [reason.toString()]));
                    });
            });
        }

        private initFilters(): void {
            // inizializzo il modello della ricerca
            this.resetForm();
        }

        public gestioneCorpoSet(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Set/modalCorpoSet.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public aggiornaRicercaCorpoSet(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.prodottiFactory.ricerca(ricerca).then((risultato: Trace.Prodotto.ProdottoVm[]) => {
                this.prodotti = risultato;
            });
        }

        public resetFormCorpoSet(): void {
            this.ricercaProdotti = new Trace.Prodotto.RicercaProdotti();
        }

        public gruppoChanged(idGruppo: number): void {
            this.sottoGruppi = [];

            if (idGruppo == undefined) return;
            this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppi = risultato;
            });
        }

        public sottoGruppoChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];

            if (idSottogruppo == undefined) return;
            this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                this.sedi = risultato;
            });
        }

        public sedeChanged(idSede: number): void {
            this.centriCosto = [];

            if (idSede == undefined) return;
            this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                this.centriCosto = risultato;
                if (this.set != undefined)
                    this.decodificaInformazioniSet();
            });
        }

        public gruppoRicercaChanged(idGruppo: number): void {
            this.sottoGruppiRicerca = [];
            if (idGruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                    this.sottoGruppiRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sottoGruppoRicercaChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];
            if (idSottogruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                    this.sediRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sedeRicercaChanged(idSede: number): void {
            this.centriCosto = [];
            if (idSede == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                    this.centriCostoRicerca = risultato;
                });
            }
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.setFactory.ricerca(ricerca, page).then((risultato: Trace.Set.SetPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.listaSet = r.List;
            });
        }

        //public onFileSelect($file: File): void {
        //    var vm = this;
        //    var file = $file;
        //    this.Upload.upload({
        //        url: appRoot + "api/Upload",
        //        method: "POST",
        //        data: { tipo: "set" },
        //        file: file
        //    })
        //        .abort()
        //        .xhr((evt: any) => {
        //            console.log('xhr');
        //        })
        //        .progress((evt: angular.angularFileUpload.IFileProgressEvent) => {
        //            var percent = parseInt((100.0 * evt.loaded / evt.total).toString(), 10);
        //            console.log("upload progress: " + percent + "% for " + evt.config.file.name);
        //        })
        //        //.error((data: any, status: number, response: any, headers: any) => {
        //        //    console.error(data, status, response, headers);
        //        //    this.msgFactory.error(this.localeFactory.getFormatted("upload_immagine_errore", [data]));
        //        //})
        //        .success((data: Trace.ViewModels.UploadResultVm, status: number, headers: any, config: angular.angularFileUpload.IFileUploadConfigFile) => {
        //            var img = new Trace.ViewModels.ImmagineVm();
        //            img.thumb = appRoot + data.Url;
        //            img.img = appRoot + data.Url;
        //            img.description = data.Name;
        //            img.baseUrl = data.Url;
        //            vm.set.Immagini.push(img);
        //            vm.formSet.$setDirty();
        //        });
        //}

        public ricercaModelliSet(): void {
            var filtro = this.dtFactory.generaOggettoRicerca(this.ricercaModello);
            
            filtro.push(new ViewModels.RicercaRigaVm("IdModelloNuovaRevisione", ""));
            filtro.push(new ViewModels.RicercaRigaVm("DataFineValidita", ""));
            filtro.push(new ViewModels.RicercaRigaVm("DataObsoleto", ""));
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            console.log("filtro modelli", filtro);
            this.modelliSetFactory.ricerca(ricerca).then((risultato: Trace.Set.SetPaginationVm) => {
                this.modelli = risultato.List;
            });
        }

        public descrizioneModelloRiferimento(): string {
            if (this.modello != undefined) {
                switch (culture) {
                    case "it":
                        return this.modello.Descrizione_it_IT;
                    case "al":
                        return this.modello.Descrizione_sq_AL;
                    case "en":
                        return this.modello.Descrizione_en_US;
                }
            }
            else if (this.set != undefined && this.set.ModelloSet != undefined) {
                return this.set.ModelloSet;
            }
            return "";
        }

        public linkModello(): string {
            if (this.set == undefined || this.set.IdModelloSet == undefined) return "";
            return appRoot + "ModelliSet/Index/" + this.set.IdModelloSet;
        }

        public alertCreazioneContainerAssociati(): string {
            if (this.set == undefined || this.modello == undefined) return "";
            var descrizioneModello: string = this.modello["Descrizione_" + dbCulture];
            return this.localeFactory.getFormatted('alert_container_creati_automaticamente', [this.set.NumContainer, this.set.TotContainer, descrizioneModello])
        }

        public duplica(): void {
            var vm = this;
            var buttons = new Array<Messages.ModalButton>();
            var b = new Messages.ModalButton();
            var b2 = new Messages.ModalButton();
            b.boolResult = true;
            b.buttonBinding = Messages.ModalResultButton.CONFIRM;
            b.label = this.localeFactory.get("conferma");
            b.style = "success";
            b.objectResult = '$("#form-duplica input#numCopie").val()';
            buttons.push(b);

            b2.boolResult = false;
            b2.buttonBinding = Messages.ModalResultButton.CANCEL;
            b2.label = this.localeFactory.get("annulla");
            b2.style = "danger";
            buttons.push(b2);

            this.msgFactory.showModal(this.localeFactory.get("label_scegli_numcopie"), this.localeFactory.get("titolo_duplica_set"), "duplicaSet.html", "md", buttons, true, "modal-styled").then(
                result => {
                    console.log("risultato del modal", result.object);
                    if (!result.result) return;
                    if ($.trim(result.object) == "" || isNaN(<number>result.object)) {
                        vm.msgFactory.error(this.localeFactory.get("messaggio_numcopie_obbligatorio"), this.localeFactory.get("titolo_duplica_set"));
                        return;
                    }
                    vm.doDuplica(<number>result.object);
                }
            );
        }

        public doDuplica(numeroCopie: number): void {
            var vm = this;
            this.msgFactory.confirm(this.localeFactory.getFormatted("messaggio_duplica_conferma", [numeroCopie, this.set["Descrizione_" + dbCulture]]), this.localeFactory.get("titolo_duplica_set")).then(
                result => {
                    if (!result.result) return;
                    vm.setFactory.duplica(vm.set.Id, numeroCopie).then(
                        result => {
                            this.msgFactory.alert(this.localeFactory.getFormatted("messaggio_duplica_ok", [numeroCopie, this.set["Descrizione_" + dbCulture]]));
                            this.changeState("list");
                        },
                        reason => {
                            this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                            console.log("errore", reason);
                        }
                    );
                }
            );
        }

        public modificaMarcatura(item: Set.StrumentoVm): void {
            this.strumentoSelezionato = item;
            this.codiceMarcatura = item.Uid;
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Set/modalModificaMarcatura.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
            //this.$modalInstance.rendered.then(() => {
            //    $(".datetime").datepicker({ minDate: new Date() });
            //});
        }

        public associaMarcaturaStrumento(): void {
            this.codiceInUso = false;
            if (this.codiceMarcatura === "" || this.codiceMarcatura.length != 10) {
                this.msgFactory.error(this.localeFactory.get("codice_marcatura_necessario"));
                return;
            }
            this.setFactory.CheckUid(this.codiceMarcatura).then(
                result => {
                    if (result) {
                        this.salvaCodiceMarcatura();
                        return;
                    }
                    this.codiceInUso = true;
                }
            );
        }

        private salvaCodiceMarcatura(): void {
            this.setFactory.SaveUid(this.strumentoSelezionato.Id, this.codiceMarcatura).then(
                result => {
                    if (result) {
                        this.strumentoSelezionato.Uid = this.codiceMarcatura;
                        this.codiceMarcatura = undefined;
                        this.chiudiModal();
                    }
                },
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason.data;
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_modifica_uid_errore", [this.strumentoSelezionato.DescrizioneIt, errore]));
                }
            );
        }

        public stampaEtichette(): void {
            var vm = this;
            vm.listaEtichette = new Array<ViewModels.DictionaryItem>();
            vm.listaEtichette.push(new ViewModels.DictionaryItem("0", vm.localeFactory.get("container")));

            for (var i = 1; i <= vm.set.NumGriglie; i++) {
                vm.listaEtichette.push(new ViewModels.DictionaryItem(i.toString(), vm.localeFactory.getFormatted("griglia_numero", [i])));
            }

            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Set/modalSelectEtichette.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public getPrintLink(): string {
            var param = "";
            $.each($(".chk-etichetta"), (idc, chk) => {
                if ($(chk).is(":checked"))
                    param += (param === "")
                        ? $(chk).val()
                        : ";;" + $(chk).val();
            });
            var result = appRoot + "Reports/EtichetteSet?idSet=" + this.set.Id + "&elementi=" + param;
            console.log("url stampa etichette", result);
            return result;
        }
    }
}
app.controller("setController", Trace.Set.setController);