module Trace.Ordini {
    export class OrdineDetail {
        public Id: number;
        public DataCreazione: Date;
        public Codice: string;
        public Descrizione: string;
        public IdModelloSetDetail: number;
        public IdOrdine: number;
        public IdProdotto: number;
        public IdTipo: number;
        public IdTnt: number;
        public ImponibileRiga: number;
        public IvaRiga: number;
        public PrzRiga: number;
        public PrzUnitario: number;
        public Qta: number;
        public QtaProposta: number;
        public QtaUnitaria: number;
    }
}