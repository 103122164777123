
app.factory("analisiFactory", ($http, $location, $q) => new Trace.Analisi.analisiFactory($http, $location, $q));
module Trace.Analisi {
    export class analisiFactory {
        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Analisi/";
        }

        public Ricerca(filtro: Analisi.FiltroInterventiVm): ng.IPromise<Analisi.AnalisiItem[]> {
            var workUrl = this.GetBaseUrl() + "Ricerca";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaProduzione(filtro: Analisi.FiltroInterventiVm): ng.IPromise<Analisi.VwAnalisiIntervento[]> {
            var workUrl = this.GetBaseUrl() + "RicercaProduzione";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public DettagliProduzione(idLotto: number): ng.IPromise<VwDettaglioLotto[]> {
            var workUrl = this.GetBaseUrl() + "DettagliProduzione/" + idLotto.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ExportPdfInterventi(item: ExportPdfVm, showCosti: boolean): ng.IPromise<string> {
            // var workUrl = this.GetBaseUrl() + "ExportPdfInterventi/" + showCosti;
            var workUrl = this.GetBaseUrl() + "ReportInterventi/" + showCosti;
            var deferred = this.$q.defer();
            this.$http.post(workUrl, item).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ValorizzaSet(): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + "ValorizzaSet";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}