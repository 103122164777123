module Trace.Movimenti {
    export class movimentiController extends epMovimentiController {
        public idRicercaMateriali: number;
        public ricerca: RicercaMovimentiVm;
        private provenienze: Helpers.SimpleEntity[];
        private fornitori: Helpers.SimpleEntity[];
        private idOrdine: number;
        private idFornitore: number;
        public dataSituazione: Date;
        private isSearching: ViewModels.RicercaVm;

        static $inject = ["$rootScope", "$scope", "dtFactory", "produzioneFactory", "movimentiFactory", "documentoFactory", "setFactory", "prodottiFactory",
            "rotFactory", "tntFactory", "depositoFactory", "utenteFactory", "localeFactory", "msgFactory", "$uibModal", "gruppiFactory", "sottoGruppiFactory",
            "sediFactory", "centriCostoFactory", "ordiniFactory", "fornitoriFactory", "tracciabilitaFactory","inventarioFactory", "Upload"];
        constructor(
            public $rootScope: ng.IRootScopeService,
            public $scope: ng.IScope,
            public dtFactory: Trace.datatables.dtFactory,
            private produzioneFactory: Produzione.produzioneFactory,
            public movimentiFactory: movimentiFactory,
            public documentoFactory: Documento.documentoFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            private rotFactory: Rot.rotFactory,
            public tntFactory: Tnt.tntFactory,
            public depositoFactory: Deposito.depositoFactory,
            public utenteFactory: Utente.utenteFactory,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public gruppiFactory: Gruppo.gruppiFactory,
            public sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            public sediFactory: Sede.sediFactory,
            public centroCostoFactory: CentroCosto.centroCostoFactory,            
            public ordiniFactory: Ordini.ordiniFactory,
            private fornitoriFactory: Fornitori.fornitoriFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory,
            public inventarioFactory: Inventario.inventarioFactory,
            public Upload: ng.angularFileUpload.IUploadService
        )
        {
            super($scope, $rootScope, $uibModal, dtFactory, localeFactory, msgFactory, setFactory, prodottiFactory, tntFactory,
                depositoFactory, utenteFactory, movimentiFactory, ordiniFactory, documentoFactory,
                gruppiFactory, sottoGruppiFactory, sediFactory, centroCostoFactory, tracciabilitaFactory, Upload);
            this.setUpWatchers();
            $(document).on("barcodeOk", (e: JQueryEventObject) => {
                this.barcodeOkHandler(e);
            });
            $(document).on("eanCode", (e: JQueryEventObject) => {
                this.eanCodeHandler(e);
            });
            $(document).on("consumabileLotto", (e: JQueryEventObject) => {
                this.consumabileLottoHandler(e);
            });
            $(document).on("tntLotto", (e: JQueryEventObject) => {
                this.tntLottoHandler(e);
            });
            var vm = this;

             // #REM LUCA 06/10/2020 - La vecchia import inventario di Stefano ormai è stata cambiata in toto
            //$(document).on("fileUpload", (e: JQueryEventObject) => {
            //    console.log("FileUpload event", e);
            //    var item = new ViewModels.ReadExcelItem();
            //    item.FileName = e.nomeFileUpload;
            //    item.OriginalName = e.lettura;
            //    item.Subfolder = e.tipoUpload;
            //    vm.movimentiFactory.ReadExcelInventario(item).then(
            //        result => {
            //            if (result === "") {
            //                vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_import_file_inventario_ok", [e.lettura]));
            //                vm.dtFactory.aggiornaRicerca();
            //            }
            //            else
            //                vm.msgFactory.error(result);
            //        },
            //        reason => {
            //        }
            //    );
            //});

            // #LUCA 06/10/2020 - File Inventario
            // dalla Sit.Depositi possono fare il carico del file, ce lo ha chiesto Altin per gli operatori del magazzino
            // la gestione completa è possibile dalla relativa maschera "Invetari"
            $(document).on("fileUpload", (e: JQueryEventObject) => {
                console.log("FileUpload event", e);
                var file = new ViewModels.ReadExcelInventario();
                file.FileName = e.nomeFileUpload;
                file.OriginalName = e.lettura;
                file.Subfolder = e.tipoUpload;
                file.IdDeposito = vm.sdItem.Id;
                file.CodTipoMateriale = vm.codTipoMateriale;
                vm.inventarioFactory.CheckExcel(file).then(
                    result => {
                        vm.uploadInCorso = false;
                        vm.file = file;
                        vm.risultatoCheckExcel = result;
                    },
                    reason => {
                        vm.uploadInCorso = false;
                        vm.file = undefined;
                        vm.risultatoCheckExcel = undefined;
                        vm.msgFactory.error(reason);
                    }
                );
            });
        }
        /***************************************************************************
         *  GESTIONE INVENTARIO (SOLO CARICO) - Maschera: Situazioni Depositi Detail
         ***************************************************************************/
        //public idDeposito: number; // uso sdItem da epMovimentiController
        public codTipoMateriale: string;
        public uploadInCorso: boolean;
        public file: ViewModels.ReadExcelInventario;
        public risultatoCheckExcel: any;

        public gestioneNuovoInventario(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Inventario/modalNuovoInventario.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xl"
            };
            this.file = undefined;
            this.risultatoCheckExcel = undefined;
            //this.idDeposito = undefined;
            this.codTipoMateriale = "PRD";
            this.uploadInCorso = false;
            this.$modalInstance = this.$uibModal.open(options);
        }

        public confermaNuovoInventario() {
            this.inventarioFactory.ReadExcel(this.file).then(
                result => {
                    this.chiudiModal();
                    this.msgFactory.alert(this.localeFactory.get('importazione_ok'));
                    this.dtFactory.aggiornaRicerca();
                },
                error => {
                    this.msgFactory.error(error.toString());
                });
        }
        //****************************************************

        public attivaLettoreBarcode(): void {
            attivaLettoreBarcode();
        }

        private isRichiestaApprovvigionamento(): boolean {
            return richiesta;
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            if (this.isSearching)
                return;

            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;

            this.isSearching = ricerca;

            this.movimentiFactory.ricerca(ricerca, page).then(
                risultato => {
                    this.isSearching = undefined;
                    var r = risultato;
                    this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                    this.movimenti = r.List;
                },
                error => {
                    this.isSearching = undefined;
                }
            );
        }

        public dataScadenzaChanged(item: any) {
            if (item == undefined) return;
            var t = moment(item.StrDataScadenzaLotto, "DD/MM/YYYY").format("YYYY/MM/DDT00:00:00");
            item.DataScadenzaLotto = item.StrDataScadenzaLotto != undefined && item.StrDataScadenzaLotto != "" && t.toString() != "Invalid date" ? t : undefined;
        }

        public resetForm(): void {
            this.ricerca = new RicercaMovimentiVm();
        }

        public initSituazioniDepositi(): void {
            var vm = this;
            setTimeout(function () {
                vm.statiDeposito = [
                    { Id: 0, Descrizione: vm.localeFactory.get("materiali_rifornire_tutti") },
                    { Id: 1, Descrizione: vm.localeFactory.get("materiali_da_rifornire") },
                    { Id: 2, Descrizione: vm.localeFactory.get("materiali_da_non_rifornire") },
                    { Id: 3, Descrizione: vm.localeFactory.get("materiali_da_rifornire_prodotti") },
                    { Id: 4, Descrizione: vm.localeFactory.get("materiali_da_rifornire_set") },
                    { Id: 5, Descrizione: vm.localeFactory.get("materiali_da_rifornire_tnt") },
                    { Id: 6, Descrizione: vm.localeFactory.get("materiali_da_rifornire_rot") }
                ];
            }, 1000);
            vm.changeState('sd-list');
        }

        public situazioneAllaData(): void {
            var vm = this;
            vm.idRicercaMateriali = undefined;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Movimenti/modalDataSituazioneDepositi.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "sm",
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.rendered.then(event => {
                $("#formSituazioneAl .datetime").val("");
                $("#formSituazioneAl .datetime").datepicker();
            });
        }

        public dataSituazioneAlChanged(): void {
            //this.dataSituazione = $("#formSituazioneAl .datetime").datepicker("getDate");
        }

        public stampaSituazioneAllaData(): void {
            this.isSaving = true;
            var dataSituazione = moment(<any>(this.dataSituazione), "DD/MM/YYYY").toDate();
            var idDeposito = this.sdItem != undefined ? this.sdItem.Id : undefined;
            this.depositoFactory.SituazioneAllaData(dataSituazione, idDeposito).then(
                result => {
                    console.log("stampaSituazioneAllaData", result);
                    this.dataSituazione = undefined;
                    this.$modalInstance.close();
                    this.isSaving = false;
                    window.location.assign("/Reports/DownloadExcel?path=" + result);
                },
                error => {
                    console.log("stampaSituazioneAllaData ERROR", error);
                    this.msgFactory.error(error.toString());
                    this.isSaving = false;
                });
        }

        public initMovimenti(): void {
            var vm = this;
            $(document).on("gotUserSession", (e: JQueryEventObject) => {
                if (idDestinazione == undefined)
                    vm.changeState('list');
                else
                    vm.nuovo(TipoMovimento.SCARICO);
            });
            this.movimentiFactory.GetTipiProvenienze().then(
                result => {
                    this.tipiProvenienze = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
            this.fornitoriFactory.AsSimpleList().then(
                result => {
                    this.fornitori = result;
                    this.idFornitore = undefined;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );    
        }

        private lottoMaterialeAllowed(item: Produzione.MaterialeVm): boolean {
            var deny = [2, 3,4, 5]; // Il Tnt (4) lo escludo anch'esso, perché lotto e data_scadenza vengono fuori diversamente
            return deny.indexOf(item.IdTipo) == -1;
        }

        private barcodeOkHandler(e: JQueryEventObject): void {
            if (this.movimento.IdProvenienza == undefined || this.movimento.IdDestinazione == undefined ) {
                this.msgFactory.error(this.localeFactory.get("selezionare_provenienza_destinazione"));
                return;
            }
            var barcode = this.ultimaLetturaBarcode.barcode;
            console.log(barcode);
            this.codiceMateriale = barcode;
            this.aggiungiMateriale(true);
        }

        private consumabileLottoHandler(e: JQueryEventObject): void {
            if (this.movimento.IdProvenienza == undefined || this.movimento.IdDestinazione == undefined) {
                this.msgFactory.error(this.localeFactory.get("selezionare_provenienza_destinazione"));
                return;
            }
            var barcode = this.ultimaLetturaBarcode.barcode;
            console.log(barcode);
            this.codiceMateriale = this.ultimaLetturaBarcode.ean.CodiceProduttore;
            //this.tipoMateriale = Produzione.TipoMateriale.CONSUMABILI_LOTTO;
            this.aggiungiMateriale(true);
        }

        private tntLottoHandler(e: JQueryEventObject): void {
            if (this.movimento.IdProvenienza == undefined || this.movimento.IdDestinazione == undefined) {
                this.msgFactory.error(this.localeFactory.get("selezionare_provenienza_destinazione"));
                return;
            }
            var barcode = this.ultimaLetturaBarcode.barcode;
            console.log(barcode);
            this.codiceMateriale = this.ultimaLetturaBarcode.ean.CodiceProduttore;
            //this.tipoMateriale = Produzione.TipoMateriale.CONSUMABILI_LOTTO;
            this.aggiungiMateriale(true);
        }

        private eanCodeHandler(e: JQueryEventObject): void {
            if (this.movimento.IdProvenienza == undefined || this.movimento.IdDestinazione == undefined) {
                this.msgFactory.error(this.localeFactory.get("selezionare_provenienza_destinazione"));
                return;
            }
            this.codiceMateriale = e.ean.CodiceProduttore;
            this.aggiungiMateriale(true);
        }

        public tipoMaterialeChanged(): void {
            console.log("tipo materiale modificato", this.tipoMateriale);
            this.filtraTipoMateriali();
        }
                
        private setUpWatchers(): void {
            let vm = this;
            this.$scope.$watch("vm.movimento.StrDataCreazione", () => {
                if (vm.movimento == null) return;
                vm.dateChanged('DataCreazione', vm.movimento);
                vm.aggiornaDescrizioneMovimento()
            });

            this.$scope.$watch("vm.materialiVisualizzati", () => {
                setTimeout(function () {
                    $(".datetime").datepicker();
                }, 500);
            });

            this.$scope.$watch("vm.movimento.IdTipoProvenienza", (newValue: number, oldValue: number) => {
                this.tipoProvenienzaChanged(newValue);
            });

            this.$scope.$watch("vm.idFornitore", (newValue: number, oldValue: number) => {
                if (this.movimento == undefined) return;
                this.tipoProvenienzaChanged(this.movimento.IdTipoProvenienza);
            });

            this.$scope.$watch("vm.movimento.IdProvenienza", (newValue: number, oldValue: number) => {
                if (this.movimento == undefined)
                    return;

                switch (this.movimento.IdTipoMovimento) {
                    case TipoMovimento.CARICO:
                        this.provenienzaModificata(newValue, this.movimento.IdTipoProvenienza);
                        break;

                    case TipoMovimento.SCARICO:
                        this.depositoProvenienzaModificato(newValue, oldValue);
                        break;

                    case TipoMovimento.SET:
                        break;
                }
            });

            this.$scope.$watch("vm.movimento.IdDestinazione", (newValue: number, oldValue: number) => {
                if (this.movimento == undefined)
                    return;
                this.giacenzaDepositoDestinazione = null;
                console.log("destinazione modificata", newValue, oldValue);
                if (newValue != undefined) {
                    if (this.depositoProvenienzaSelezionato != undefined && this.depositoProvenienzaSelezionato.Id === newValue) {
                        this.movimento.IdDestinazione = oldValue;
                        this.msgFactory.error(this.localeFactory.get("depositi_non_coincidenti"));
                        return;
                    }
                    this.depositoDestinazioneSelezionato = this.getDepositoById(newValue);
                    this.depositoFactory.getGiacenze(newValue).then(
                        result => {
                            this.giacenzaDepositoDestinazione = result;
                            if (idDestinazione != undefined)
                                this.propostaApprovvigionamento(result);
                        }
                    );
                }
                else
                    this.depositoDestinazioneSelezionato = null;
            });

            this.$scope.$watch("vm.ricerca.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSottoGruppo", (newVal: number, oldVal: number) => {
                this.sottoGruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSede", (newVal: number, oldVal: number) => {
                this.sedeRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdCentroCosto", (newVal: number, oldVal: number) => {
                this.dtFactory.aggiornaRicerca();
            });
        }

        private tipoProvenienzaChanged(newValue: number): void {
            console.log("tp prov", newValue);
            if (newValue == undefined || newValue == 0) {
                this.provenienze = undefined;
                return;   
            }
            if (newValue != 3 && newValue != 5)
                this.idFornitore = undefined;
            if (newValue == 6) {
                this.provenienze = undefined;
                this.movimento.IdProvenienza = 0;
                return; 
            }

            this.movimentiFactory.GetProvenienze(newValue, this.idFornitore).then(
                result => {
                    this.provenienze = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public aggiornaDescrizioneMovimento() {
            this.movimento.Descrizione = this.localeFactory.getFormatted("descrizione_movimento", [this.state, this.formatDate(this.movimento.DataCreazione), this.formatDate(new Date(), "HH:mm")])
        }
        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }
        public state: any;

        public nuovo(idTipo: number): void {
            this.movimento = new MovimentoRigaVm();
            this.materiali = [];
            this.materialiVisualizzati = [];
            this.movimento.IdTipoMovimento = idTipo;
            this.movimento.IdTipoProvenienza = (idTipo === TipoMovimento.CARICO) ? TipoControparte.DOCUMENTO : TipoControparte.DEPOSITO;
            this.movimento.IdTipoDestinazione = TipoControparte.DEPOSITO;
            var newState = this.getManipolaState(idTipo);
            this.state = newState;
            this.movimento.Descrizione = this.localeFactory.getFormatted("descrizione_movimento", [newState, this.formatDate(new Date()), this.formatDate(new Date(), "HH:mm")])
            var t:any = this.movimento;
            t.StrDataCreazione = this.formatDate(new Date());
            this.dateChanged("DataCreazione", this.movimento);

            //console.log(idTipo, "passo allo stato", newState, this.applicationSession.IdDeposito);

            if (newState === "scarico") {
                if (richiesta === true) 
                    this.movimento.Descrizione = this.localeFactory.getFormatted("descrizione_richiesta_approvvigionamento", [this.formatDate(new Date()), this.formatDate(new Date(), "HH:mm")])
                //this.movimento.IdProvenienza = this.applicationSession.IdDeposito;
                this.setProvenienzaDestinazione();
            }
            else {
                this.listaDocumenti(true);
                this.movimento.IdDestinazione = this.applicationSession.IdDeposito;
            }
            
            this.changeState(newState);
            setTimeout(function () {
                var a =
                    $(".dataCreazione");
                a.datepicker({ maxDate: new Date() });
            }, 1000);
        }

        private setProvenienzaDestinazione(): void {
            this.movimento.IdDestinazione = idDestinazione;
            if (idProvenienza != undefined) this.movimento.IdProvenienza = idProvenienza;
            else this.movimentiFactory.getDepositoProvenienza(idDestinazione, idTipoMateriale).then(
                result => {
                    this.movimento.IdProvenienza = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public nessunaProvenienza(): void {
            this.movimento.IdProvenienza = 0;
        }

        public aggiungiMateriale(exact: boolean): void {
            this.formDettagliMovimento.$setDirty();
            var ricerca = new Produzione.RicercaMaterialiVm(this.codiceMateriale, this.tipoMateriale, exact);
            ricerca.IdDepositoDestinazione = this.depositoDestinazioneSelezionato.Id;
            this.produzioneFactory.RicercaMateriali(ricerca).then(
                result => {
                    this.codiceMateriale = "";
                    this.ricercaMaterialiResult = result;
                    if (this.ricercaMaterialiResult == undefined || this.ricercaMaterialiResult.length <= 0) {
                        this.msgFactory.error(this.localeFactory.getFormatted("codice_no_corrispondenza", [this.codiceMateriale]));
                        return;
                    }
                    if (this.ricercaMaterialiResult.length == 1) {
                        switch (this.movimento.IdTipoMovimento) {
                            case TipoMovimento.SCARICO:
                                this.addMaterialeToList(this.ricercaMaterialiResult[0]);
                                break;
                            case TipoMovimento.CARICO:
                                this.checkMaterialePresente(<Produzione.MaterialeVm>this.ricercaMaterialiResult[0]);
                                break;
                        }
                    }
                    else {
                        this.chooseMateriale();
                    }
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );

            this.filtraTipoMateriali();
        }

        private chooseMateriale(): void {
            var vm = this;
            vm.idRicercaMateriali = undefined;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Movimenti/modalSelectMateriale.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg",
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        private initRichieste(): void {
            
        }

        public changeState(stato: string): void {
            super.changeState(stato);
            if (stato === 'scarico') {
                if (richiesta === true)
                    this.titoloManipola = this.localeFactory.get("titolo_richiesta_approvvigionamento");

                this.idOrdine = undefined;
                if (idTipoMateriale != undefined)
                    this.tipoMateriale = idTipoMateriale;
            }
            else if (stato === 'carico') {
                this.materiali = undefined;
                this.materialiVisualizzati = undefined;
            }
            else if (stato === 'sd-list')
                this.sdItem = undefined;
        }

        public itemRicercaMaterialeSelected(): void {
            var vm = this;
            if (vm.idRicercaMateriali == undefined) return;
            switch (vm.movimento.IdTipoMovimento) {
                case TipoMovimento.SCARICO:
                    vm.addMaterialeToList(vm.ricercaMaterialiResult[vm.idRicercaMateriali]);
                    break;
                case TipoMovimento.CARICO:
                    this.checkMaterialePresente(<Produzione.MaterialeVm>vm.ricercaMaterialiResult[vm.idRicercaMateriali]);
                    break;
            }
            vm.chiudiModal();
        }

        private checkMaterialePresente(element: Produzione.MaterialeVm): void {
            this.formDettagliMovimento.$setDirty();
            element.FlgReale = true;
            if (this.ultimaLetturaBarcode != undefined && this.ultimaLetturaBarcode.ean != undefined) {
                element.Lotto = this.ultimaLetturaBarcode.ean.Lotto;
                element.DataScadenzaLotto = this.ultimaLetturaBarcode.ean.DataScadenzaLotto;
            }
            var exists = false;
            if (this.materiali == undefined) {
                element.QtaValidata = element.Qta;
                element.FlgInDocumento = false;
                this.addMaterialeToList(element);
                return;
            }
            $.each(this.materiali, (index, item) => {
                if (item.Codice === element.Codice && item.IdTipo === element.IdTipo) {
                    if (item.Componenti == undefined || item.Componenti.length <= 0) {
                        // #LUCA - branch per i consumabili, dato che ora possono essere con o senza lotto
                        if (item.IdTipo === Produzione.TipoMateriale.PRODOTTO && item.IdTipoProdotto == 1003) {
                            if (element.Lotto != undefined && element.Lotto != "0" && element.Lotto != "" && element.DataScadenzaLotto != undefined) {
                                element.IdDocumento = item.IdDocumento;
                                element.IdDocumentoDetail = item.IdDocumentoDetail;
                                if (item.Lotto == element.Lotto && moment(item.DataScadenzaLotto).isSame(element.DataScadenzaLotto)) {
                                    exists = true;
                                    element.QtaValidata = element.Qta;
                                    element.FlgInDocumento = false;
                                    this.addMaterialeToList(element);
                                }
                            }
                            else {
                                item.QtaValidata += element.Qta;
                                item.FlgReale = true;
                                this.formDettagliMovimento.$setDirty();
                                exists = true;
                            }
                        }
                        else if ((item.IdTipo === Produzione.TipoMateriale.TNT || item.IdTipo === Produzione.TipoMateriale.PRODOTTO) && element.Lotto != undefined && element.Lotto != "0" && element.Lotto != "") {
                            element.IdDocumento = item.IdDocumento;
                            element.IdDocumentoDetail = item.IdDocumentoDetail;
                            if (item.Lotto == element.Lotto && moment(item.DataScadenzaLotto).isSame(element.DataScadenzaLotto)) {
                                //element.Uid = this.ultimaLetturaBarcode.ean.Originale;
                                //element.DataScadenzaLotto = this.ultimaLetturaBarcode.ean.DataScadenzaLotto;
                                //element.Lotto = this.ultimaLetturaBarcode.ean.Lotto;
                                //element.Qta = this.ultimaLetturaBarcode.ean.Unita;
                                //element.QtaValidata = this.ultimaLetturaBarcode.ean.Unita;

                                //if (item.QtaValidata == item.Qta)
                                //    item.Qta += element.Qta;
                                //item.QtaValidata += element.Qta;

                                //var generici = $.grep(this.materiali, (el, idx) => {
                                //    return el.Codice == item.Codice && el.IdTipo == item.IdTipo && el.Lotto == undefined;
                                //});

                                //if (generici != undefined && generici.length > 0) {
                                    
                                //}

                                //item.FlgReale = true;
                                //this.updateSoglie(item);
                                //this.formDettagliMovimento.$setDirty();
                                //this.filtraTipoMateriali();
                                exists = true;
                                element.QtaValidata = element.Qta;
                                element.FlgInDocumento = false;
                                this.addMaterialeToList(element);
                            }
                        }
                        else {
                            item.QtaValidata += element.Qta;
                            item.FlgReale = true;
                            this.formDettagliMovimento.$setDirty();
                            exists = true;
                        }
                    }
                    else {
                        var esiste = this.contieneComponente(item, element);
                        if (esiste > 0) {
                            exists = true;
                            if (esiste === 2) {
                                this.msgFactory.alert(this.localeFactory.get('materiale_letto'));
                                return;
                            }
                            item.QtaValidata += 1;
                        }
                    }
                }
            });
            if (!exists) {
                element.QtaValidata = element.Qta;
                element.FlgInDocumento = false;
                element.FlgReale = true;
                this.addMaterialeToList(element);
            }
        }

        public rimuoviMateriale(element: Produzione.Materiale, index: number): void {
            this.msgFactory.confirm(this.localeFactory.getFormatted('rimozione_materiale_conferma', [element.Tipo, element.Descrizione])).then(
                result => {
                    if (!result.result) return;
                    var item = this.materialiVisualizzati[index];
                    var idm = this.materiali.indexOf(item);
                    var idml = this.materialiVisualizzati.indexOf(item);
                    this.materiali.splice(idm, 1);
                    this.materialiVisualizzati.splice(idml, 1);
                }
            );
        }

        public registra(): void {
            this.canPrint = false;
            this.showValidationMessages = false;
            if (!this.formDettagliMovimento.$valid) {
                this.showValidationMessages = true;
                this.formDettagliMovimento.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                console.log("form non valido", this.formDettagliMovimento);
                return;
            }
            if (this.materiali == undefined || this.materiali.length <= 0) {
                this.showValidationMessages = true;
                this.formDettagliMovimento.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore_nomateriali"));
                return;
            }

            this.confermaRegistra();
        }

        private confermaRegistra(): void {
            var vm = this;

            if (this.movimento.IdTipoMovimento === TipoMovimento.SCARICO) {
                if (this.depositoProvenienzaSelezionato == undefined)
                    this.depositoProvenienzaSelezionato = this.depositi.find(r => r.Id == this.movimento.IdProvenienza);

                if (this.depositoDestinazioneSelezionato == undefined)
                    this.depositoDestinazioneSelezionato = this.depositi.find(r => r.Id == this.movimento.IdDestinazione);
            }

            this.materiali.forEach((item, index) => {
                let arr = this.materialiVisualizzati.filter(m => m.Id === item.Id);
                if (arr.length > 0)
                    item.QtaValidata = arr[0].QtaValidata;
            });
            if (this.movimento.IdTipoMovimento === TipoMovimento.CARICO)
                this.checkRegistrazioneParziale();
            else {
                var msg = "";
                // registro l'ordine a fornitore
                if (this.depositoProvenienzaSelezionato.FlgFornitoreEsterno)
                    msg = this.localeFactory.get("ordinefornitore_conferma");
                // registro la richiesta di approvvigionamento
                else if (richiesta === true)
                    msg = this.localeFactory.get("richiesta_approvvigionamento_conferma");
                // registro il movimento
                else 
                    msg = this.localeFactory.get("registrazione_conferma_2");
                this.msgFactory.confirm(msg).then(
                    result => {
                        if (!result.result) return;
                        this.doRegistra(result.result);
                    }
                );
            }
        }

        public annulla(): void {
            var vm = this;
            var conf = (richiesta === true)
                ? this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento_richiesta_approvvigionamento"))
                : this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                this.backToList();
            });
        }

        public backToList(): void {
            if (richiesta === true) window.location.assign(appRoot + "RichiesteApprovvigionamento/");
            else super.backToList();
        }

        private checkRegistrazioneParziale(): void {
            var buttons = new Array<Messages.ModalButton>();
            var completo = new Messages.ModalButton();
            var parziale = new Messages.ModalButton();
            var annulla = new Messages.ModalButton();

            completo.boolResult = true;
            completo.buttonBinding = Messages.ModalResultButton.YES;
            completo.label = this.localeFactory.get("registrazione_completa");
            completo.style = "success";

            parziale.boolResult = false;
            parziale.buttonBinding = Messages.ModalResultButton.NO;
            parziale.label = this.localeFactory.get("registrazione_parziale");
            parziale.style = "warning";
            
            annulla.boolResult = undefined;
            annulla.buttonBinding = Messages.ModalResultButton.CANCEL;
            annulla.label = this.localeFactory.get("annulla");
            annulla.style = "danger";

            buttons.push(annulla, parziale, completo);

            var msg = this.localeFactory.getFormatted("registrazione_conferma", [this.currentState]);
            var title = "Trace";
            this.msgFactory.showModal(msg, title, "alert.html", Messages.ModalSizes._xl, buttons, true).then(
                result => {
                    if (result.button === Messages.ModalResultButton.CANCEL || result.result == undefined) return;
                    var nonConformi: Array<Produzione.Materiale>;
                    if (this.movimento.IdTipoMovimento === TipoMovimento.CARICO && result.result) {
                        // Controllo se il materiale letto non è conforme a quanto dichiarato nel documento
                        nonConformi = $.grep(this.materiali, (item, index) => {
                            if (!item.FlgInDocumento || item.QtaValidata != item.Qta) {
                                console.log("NON Conforme", item.Codice, item.Descrizione);
                                return true;
                            }
                        });
                    }

                    if (nonConformi != undefined && nonConformi.length > 0) {
                        this.msgFactory.confirm(this.localeFactory.getFormatted("registrazione_conferma_nonConforme", [nonConformi.length, this.currentState])).then(
                            nonConformeResult => {
                                if (!nonConformeResult.result) return;
                                this.doRegistra(result.result);
                            }
                        );
                    }
                    else {
                        this.doRegistra(result.result);
                    }
                }
            );
        }

        private doRegistra(chiudiDocumento: boolean = undefined): void {
            if (this.movimento.IdTipoMovimento === TipoMovimento.SCARICO && this.depositoProvenienzaSelezionato != undefined && this.depositoProvenienzaSelezionato.FlgFornitoreEsterno === true)
                this.doRegistraOrdine();
            else if (this.movimento.IdTipoMovimento === TipoMovimento.SCARICO && richiesta === true)
                this.doRegistraRichiestaApprovvigionamento();
            else 
                this.doRegistraMovimento(chiudiDocumento);
        }

        private doRegistraRichiestaApprovvigionamento(): void {
            var vm = this;
            var lista = new Array<Produzione.MaterialeVm>();

            this.materialiVisualizzati.forEach((item, index) => {
                if (item.Componenti != undefined && item.Componenti.length > 0) {
                    var comp = item.Componenti.filter(el => this.movimento.IdTipoMovimento === TipoMovimento.SCARICO && el.Qta > 0);
                    if (comp && comp.length > 0)
                        lista = angular.merge(lista, comp);
                }
                else if (this.movimento.IdTipoMovimento === TipoMovimento.SCARICO && item.Qta > 0)
                    lista.push(item);
            });
            
            this.movimento.MovimentiDetails = this.getMovimentiDetailsFromMateriali(this.movimento.IdTipoMovimento, lista);
            console.log("movimenti details", this.movimento.MovimentiDetails);
            this.isSaving = true;
            this.movimentiFactory.RegistraRichiestaApprovvigionamento(this.movimento).then(
                result => {
                    this.isSaving = false;
                    this.movimento.Id = result;
                    if (this.movimento.IdTipoMovimento === TipoMovimento.SCARICO) {
                        this.movimentoRegistrato(richiesta);
                    }
                },
                reason => {
                    this.isSaving = false;
                    this.msgFactory.error(this.localeFactory.getFormatted("richiesta_approvvigionamento_registrata_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private doRegistraOrdine(): void {
            var ordine = new Ordini.OrdineVm();
            ordine.IdFornitore = this.depositoProvenienzaSelezionato.IdFornitore;
            ordine.Dettagli = new Array<Ordini.OrdineDetail>();

            this.isSaving = true;
            $.each(this.materialiVisualizzati, (index, item) => {
                if (item.Qta > 0) {
                    var dett = new Ordini.OrdineDetail();
                    dett.Codice = item.Codice;
                    dett.Descrizione = item.Descrizione;
                    dett.IdTipo = item.IdTipo;
                    switch (item.IdTipo) {
                        case Produzione.TipoMateriale.PRODOTTO:
                            dett.IdProdotto = item.Id;
                            break;
                        case Produzione.TipoMateriale.TNT:
                            dett.IdTnt = item.Id;
                            break;
                    }
                    dett.Qta = item.Qta;
                    dett.QtaProposta = item.MancanteMassimo;
                    ordine.Dettagli.push(dett);
                }
            });
            this.ordiniFactory.registraOrdineFornitore(ordine, this.depositoProvenienzaSelezionato.IdFornitore).then(
                result => {
                    this.isSaving = false;
                    this.msgFactory.alert(this.localeFactory.get("ordinefornitore_registrato_ok"));
                    this.canPrint = true;
                    this.idOrdine = result;
                    this.formDettagliMovimento.$setPristine();
                },
                reason => {
                    this.isSaving = false;
                    this.msgFactory.alert(this.localeFactory.getFormatted("ordinefornitore_registrato_errore", [reason]));
                }
            );
        }

        private doRegistraMovimento(chiudiDocumento: boolean = undefined): void {
            if (this.movimento.IdTipoMovimento === TipoMovimento.CARICO) {
                switch (this.movimento.IdTipoProvenienza) {
                    // Documenti
                    case 3:
                        this.movimento.IdDocumento = this.movimento.IdProvenienza;
                        break;
                    // Ordini
                    case 5:
                        this.movimento.IdOrdine = this.movimento.IdProvenienza;
                        break;
                    // Nessuna provenienza
                    case 6:
                        this.movimento.IdDocumento = undefined;
                        this.movimento.IdOrdine = undefined;
                        break;
                }
            }

            var lista = new Array<Produzione.MaterialeVm>();
            this.materialiVisualizzati.forEach(item => {
                if (item.Componenti && item.Componenti.length > 0) {
                    var comp = item.Componenti.filter(el => {
                        return (this.movimento.IdTipoMovimento === TipoMovimento.SCARICO && el.Qta > 0) ||
                            (this.movimento.IdTipoMovimento === TipoMovimento.CARICO && el.QtaValidata > 0 && el.FlgReale);
                    });
                    if (comp && comp.length > 0)
                        lista = angular.merge(lista, comp);
                }
                else if ((this.movimento.IdTipoMovimento === TipoMovimento.SCARICO && item.Qta > 0) ||
                    (this.movimento.IdTipoMovimento === TipoMovimento.CARICO && item.QtaValidata > 0 && item.FlgReale))
                    lista.push(item);
            });

            //$.each(vm.materialiVisualizzati, (index, item) => {
            //    console.log("salvataggio materiale", vm.movimento.IdTipoMovimento, item.QtaValidata, item.FlgReale);
            //    if (item.Componenti != undefined && item.Componenti.length > 0) {
            //        var comp = $.grep(item.Componenti, (el, id) => {
            //            return vm.movimento.IdTipoMovimento === TipoMovimento.SCARICO && el.Qta > 0 ||
            //                vm.movimento.IdTipoMovimento === TipoMovimento.CARICO && el.QtaValidata > 0 && el.FlgReale;
            //        });
            //        if (comp != undefined && comp.length > 0)
            //            lista = angular.merge(lista, comp);
            //    }
            //    else if (vm.movimento.IdTipoMovimento === TipoMovimento.SCARICO && item.Qta > 0 || vm.movimento.IdTipoMovimento === TipoMovimento.CARICO && item.QtaValidata > 0)
            //        lista.push(item);
            //});

            this.movimento.MovimentiDetails = this.getMovimentiDetailsFromMateriali(this.movimento.IdTipoMovimento, lista);
            this.isSaving = true;
            this.movimentiFactory.Registra(this.movimento).then(
                result => {
                    this.movimentoRegistratoHandler(result, chiudiDocumento);
                },
                reason => {
                    this.isSaving = false;
                    this.msgFactory.error(this.localeFactory.getFormatted("registrazione_errore", [this.currentState, reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private movimentoRegistratoHandler(result, chiudiDocumento): void {
            this.isSaving = false;
            this.movimento.Id = result;
            this.movimentoRegistrato();
            if (this.movimento.IdTipoMovimento === TipoMovimento.CARICO) {
                if (chiudiDocumento) {
                    switch (this.movimento.IdTipoProvenienza) {
                        // Documento
                        case 3:
                            this.documentoFactory.Valida(this.movimento.IdProvenienza);
                            break;
                        // Ordine
                        case 5:
                            this.ordiniFactory.EvasioneOrdine(this.movimento.IdProvenienza, this.movimento.MovimentiDetails).then(
                                result => { },
                                reason => {
                                    this.msgFactory.alert(this.localeFactory.getFormatted("ordinefornitore_registrato_errore", [reason]));
                                }
                            );
                            break;
                    }
                }
                else {
                    switch (this.movimento.IdTipoProvenienza) {
                        // Documento
                        case 3:
                            break;
                        // Ordine
                        case 5:
                            this.ordiniFactory.EvasioneParzialeOrdine(this.movimento.IdProvenienza, this.movimento.MovimentiDetails).then(
                                result => { },
                                reason => {
                                    this.msgFactory.alert(this.localeFactory.getFormatted("ordinefornitore_registrato_errore", [reason]));
                                }
                            );
                            break;
                    }
                }
            }
            else { // #LUCA 02.11.2020 per ripulire i dati dopo il link rapido allo scarico fatto da anag. deposito dettaglio                
                //this.clearQueryString();
                idProvenienza = undefined;
                idDestinazione = undefined;
            }
        }

        // non funziona diavolo cane
        public clearQueryString() {
            var uri = window.location.toString();
            if (uri.indexOf("?") > 0) {
                var clean_uri = uri.substring(0, uri.indexOf("?"));
                window.history.replaceState({}, document.title, clean_uri);
            }
        }

        public validaDocumento(id: number): void {
            this.applicationSession.IdPrivilegi
            this.documentoFactory.Valida(id).then(
                result => {
                    if (result === true) {
                        this.msgFactory.alert(this.localeFactory.get("documento_chiuso_ok"));
                        this.listaDocumenti(true);
                    }
                    else {
                        this.msgFactory.error(this.localeFactory.getFormatted("documento_chiuso_ko", [<string>result]));
                    }
                }
            );
        }

        private movimentoRegistrato(richiestaApprovvigionamento: boolean = false): void {
            if (!richiestaApprovvigionamento)
                this.msgFactory.alert(this.localeFactory.getFormatted("registrazione_ok", [this.currentState]));
            else 
                this.msgFactory.alert(this.localeFactory.get("richiesta_approvvigionamento_registrata_ok"));

            this.formDettagliMovimento.$setPristine();
            this.canPrint = true;
        }

        public leggi(id: number): void {
            this.movimentiFactory.leggi(id).then(
                result => {
                    this.movimento = result;
                    this.materiali = this.getMaterialiFromRigheMovimento(this.movimento.Details);
                    this.changeState('details');
                    this.filtraTipoMateriali();
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        private getLinkRichiestaApprovvigionamento(): string {
            if (this.sdItem == undefined || this.ricercaMateriali == undefined || this.applicationSession == undefined) return ""; 
            var link = appRoot + "Movimenti/?idDestinazione=" + this.sdItem.Id + "&idTipoMateriale=" + this.ricercaMateriali.IdTipo;
            if (this.applicationSession.FlgRichestaApprovvigionamento === true)
                link += "&richiesta=true";

            return link;
        }

        private getExportUrl(tipo: string): string {
            var result: string = "";
            switch (tipo) {
                case 'SituazioneDepositiDetails':
                    result = appRoot + "Reports/SituazioneDepositiDetails?strFiltro=" + ((this.sdListRicerca == undefined) ? "" : JSON.stringify(this.sdListRicerca));
                    break;
                case 'SituazioneDepositiDetails2024':
                    result = appRoot + "Reports/SituazioneDepositiDetails2024?strFiltro=" + ((this.sdListRicerca == undefined) ? "" : JSON.stringify(this.sdListRicerca));
                    break;
            }
            return result;
        }
    }
}
app.controller("movimentiController", Trace.Movimenti.movimentiController);