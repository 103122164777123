module Trace.Produzione {
    export class ListaAccettazioneVm {
        public LottiAccettati: Trace.Produzione.LottoAccettatoVm[];
    }

    export class LottoAccettatoVm {
        public IdLotto: number;
        public DataIntervento: string;
        public CodiceIntervento: string;
        public IdUtilizzo: number;
    }
}