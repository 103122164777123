module Trace.Deposito { 
    export class RicercaDeposito {
        public Id: string;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdGruppo: number;
        public IdSottoGruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
        public IdTipo: number;
        public Stato: number;
    }

    export class RicercaDistintaIntervento {
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdTipo: number;
    }

    export class depositoController extends epBaseController {
        public lista: DepositoVm[];
        public recordCorrente: DepositoVm;
        public testoAzione: string;
        public ricercaDistinta: RicercaDistintaIntervento;

        public gruppi: Helpers.SimpleEntity[];
        public gruppiRicerca: Gruppo.Gruppo[];

        public sottoGruppi: Helpers.SimpleEntity[];
        public sottoGruppiRicerca: SottoGruppo.SottoGruppo[];

        public sedi: Helpers.SimpleEntity[];
        public sediRicerca: Sede.Sede[];

        public centriCosto: Helpers.SimpleEntity[];
        public centriCostoRicerca: CentroCosto.CentroCosto[];

        public tipiDeposito: TipoDepositoVm[];
        public tipiDepositoRicerca: TipoDepositoVm[];

        public ricerca: RicercaDeposito;

        public currentState: string;
        public titoloManipola: string;
        public flgShowCentrali: boolean;
        public centrali: Centrale.Centrale[];
        public listaTnt: Tnt.tntVm[];
        public listaRot: Rot.Rot[];
        public listaSet: Set.SetVm[];
        public listaProdotti: Prodotto.ProdottoVm[];
        public mostraDistinta: boolean;

        public showValidationMessages: boolean;
        public formDeposito: any;
        public tipoElemento: string;

        static $inject = ['$scope', 'sottoGruppiFactory', 'gruppiFactory', 'sediFactory', 'centriCostoFactory', 'dtFactory',
            'localeFactory', 'msgFactory', 'depositoFactory', '$uibModal', 'tntFactory', 'modelliSetFactory', 'setFactory',
            'rotFactory', 'prodottiFactory', 'centraliFactory', 'Upload', 'utenteFactory', "tracciabilitaFactory"];
        constructor(private $scope: ng.IScope, private sottoGruppiFactory: SottoGruppo.sottoGruppiFactory,
            private gruppiFactory: Gruppo.gruppiFactory, private sediFactory: Sede.sediFactory,
            private centriCostoFactory: CentroCosto.centroCostoFactory, private dtFactory: datatables.dtFactory,
            public localeFactory: Localization.localeFactory, public msgFactory: Messages.msgFactory,
            private depositoFactory: Deposito.depositoFactory, public $uibModal: ng.ui.bootstrap.IModalService,
            public tntFactory: Tnt.tntFactory, private modelliSetFactory: ModelliSet.modelliSetFactory,
            public setFactory: Set.setFactory, private rotFactory: Rot.rotFactory, 
            private prodFactory: Prodotto.prodottiFactory, private centraliFactory: Centrale.centraliFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory) {
            super(Upload, null, setFactory, prodFactory, tntFactory, localeFactory, msgFactory, $uibModal, null, tracciabilitaFactory);
            this.formDeposito = {};
            var vm = this;

            gruppiFactory.asSe().then((risultato: Helpers.SimpleEntity[]) => {
                this.gruppi = risultato;
            });

            gruppiFactory.lista().then((risultato: Gruppo.Gruppo[]) => {
                this.gruppiRicerca = risultato;

                var dummy: Gruppo.Gruppo = new Gruppo.Gruppo();
                dummy.Descrizione_it_IT = '';
                this.gruppiRicerca.unshift(dummy);
            });

            depositoFactory.tipiDeposito().then((risultato: TipoDepositoVm[]) => {
                this.tipiDeposito = risultato;
                this.tipiDepositoRicerca = risultato;
            });

            this.depositoFactory.tipiDeposito().then(
                result => {
                    this.tipiDepositoRicerca = result;
                }
            );

            $(document).on("fileUpload", (e: JQueryEventObject) => {
                console.log("FileUpload event", e);
                var item = new ViewModels.ReadExcelItem();
                item.FileName = e.nomeFileUpload;
                item.OriginalName = e.lettura;
                item.Subfolder = e.tipoUpload;
                vm.depositoFactory.ReadExcel(this.recordCorrente.Id, item).then(
                    result => {
                        if (result === "") {
                            vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_nuovo_da_excel_ok", [vm.recordCorrente.Descrizione_it]));
                            vm.depositoFactory.leggi(vm.recordCorrente.Id).then((deposito: DepositoVm) => {
                                this.recordCorrente = deposito;
                            });
                        }
                        else
                            vm.msgFactory.error(result);
                    },
                    reason => {
                    }
                );
            });
            this.annulla();
        }

        public changeTipoDeposito(): void {
            var tipoDeposito: TipoDepositoVm = $.grep(this.tipiDeposito, (item, index) => {
                return item.Id === this.recordCorrente.IdTipo;
            })[0];
            console.log(tipoDeposito);
            this.flgShowCentrali = tipoDeposito.FlgAssociazioneCentrale;
        }
        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formDeposito.$valid) {
                this.showValidationMessages = true;
                this.formDeposito.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }

            this.msgFactory.confirm(this.localeFactory.get('conferma_registrazione')).then((result: Messages.ModalResult) => {
                if (result.result) {

                    if (this.recordCorrente.Id == undefined)
                        this.depositoFactory.inserisci(this.recordCorrente).then((result => {
                            this.annulla();
                        }));
                    else
                        this.depositoFactory.modifica(this.recordCorrente).then((result => {
                            this.annulla();
                        }));
                }
            });

        }

        private getEmptyDepositoRiga(): DepositoRigaSimpleVm {
            return {
                Id: undefined,
                IdSet: undefined,
                IdTnt: undefined,
                IdRot: undefined,
                IdProdotto: undefined,
                Qta: undefined,
                ScortaMinima: undefined,
                SogliaRiordino: undefined,
                DescrizioneIt: undefined,
                DescrizioneEn: undefined,
                DescrizioneAl: undefined,
                Tipo: undefined,
                Codice: undefined
            };
        }

        private resetRecordCorrente(): void {
            this.recordCorrente = {
                CentroCosto: undefined,
                Codice: undefined,
                DataObsoleto: undefined,
                Descrizione_al: undefined,
                Descrizione_en: undefined,
                Descrizione_it: undefined,
                Descrizione: undefined,
                Gruppo: undefined,
                Id: undefined,
                IdCentroCosto: undefined,
                IdGruppo: undefined,
                IdSede: undefined,
                IdSottogruppo: undefined,
                Sede: undefined,
                SottoGruppo: undefined,
                IdCentrali: [],
                Scorte: [],
                IdTipo: undefined,
                FlgScortaProdotti: undefined,
                FlgScortaRot: undefined,
                FlgScortaSet: undefined,
                FlgScortaTnt: undefined,
                Tipo: undefined,
                Situazione: undefined,
                FlgFornitoreEsterno: undefined,
                IdFornitore: undefined,
                FlgStrumentarioSet: undefined
            };
        }
 
        public annulla(): void {
            this.resetRecordCorrente();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.ricerca = new RicercaDeposito();

            if (this.centrali != undefined) { 
                this.centrali.forEach((centrale: Centrale.Centrale) => {
                    $("#centrale_" + centrale.Id.toString()).attr("checked", "");
                });
            }

            this.depositoFactory.lista().then((risultato: DepositoVm[]) => {
                this.lista = risultato;
                this.changeState('list');
            });
        }

        public cancella(deposito: DepositoVm): void {
            this.msgFactory.confirm(this.localeFactory.get('conferma_cancellazione')).then((result: Messages.ModalResult) => {
                if (result.result) {
                    this.depositoFactory.cancella(deposito.Id).then((result => {
                        this.annulla();
                    })).catch((reason: string) => {
                        this.msgFactory.error(this.localeFactory.get(reason));
                    });
                }
            });
        }

        public modifica(deposito: DepositoVm): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.depositoFactory.leggi(deposito.Id).then((nuovoDeposito: DepositoVm) => {
                this.recordCorrente = nuovoDeposito;
                this.testoAzione = this.localeFactory.get('button_modifica');
                this.cambiaGruppo();

                this.recordCorrente.IdCentrali.forEach((id: number) => {
                    $("#centrale_" + id.toString()).attr("checked", "checked");
                });

                this.changeState('manipola');
            });
        }

        public cambiaGruppo(): void {
            if (this.recordCorrente.IdGruppo == undefined)
                return;
            this.sottoGruppiFactory.listaGruppoAsSe(this.recordCorrente.IdGruppo).then((risultato: Helpers.SimpleEntity[]) => {
                this.sottoGruppi = risultato;
                if (this.recordCorrente.IdSede != undefined)
                    this.cambiaSottoGruppo();
            });
        }

        public cambiaSottoGruppo(): void {
            if (this.recordCorrente.IdSottogruppo == undefined)
                return;
            this.sediFactory.listaSottoGruppoAsSe(this.recordCorrente.IdSottogruppo).then((risultato: Helpers.SimpleEntity[]) => {
                this.sedi = risultato;
                if (this.recordCorrente.IdCentroCosto != undefined)
                    this.cambiaSede();
            });

        }

        public cambiaSede(): void {
            if (this.recordCorrente.IdSede == undefined)
                return;
            this.centriCostoFactory.listaSedeAsSe(this.recordCorrente.IdSede).then((risultato: Helpers.SimpleEntity[]) => {
                this.centriCosto = risultato;
            });

        }

        public aggiornaRicercaGruppo() {
            this.ricerca.IdSottoGruppo = undefined;
            this.ricerca.IdSede = undefined;
            this.sottoGruppiRicerca = [];
            this.sediRicerca = [];

            if (this.ricerca.IdGruppo == undefined) {
                this.dtFactory.aggiornaRicerca();
                return;
            }
            this.sottoGruppiFactory.listaGruppo(this.ricerca.IdGruppo).then((risultato: SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppiRicerca = risultato;

                var dummy: SottoGruppo.SottoGruppo = new SottoGruppo.SottoGruppo();
                dummy.Descrizione_it_IT = '';
                this.sottoGruppiRicerca.unshift(dummy);

                this.dtFactory.aggiornaRicerca();
            });
        }

        public aggiornaRicercaSottoGruppo() {
            this.ricerca.IdSede = undefined;
            this.sediRicerca = [];
            if (this.ricerca.IdSottoGruppo == undefined) {
                this.dtFactory.aggiornaRicerca();
                return;
            }
            this.sediFactory.listaSottoGruppo(this.ricerca.IdSottoGruppo).then((risultato: Sede.Sede[]) => {
                this.sediRicerca = risultato;

                var dummy: Sede.Sede = new Sede.Sede();
                dummy.Descrizione_it_IT = '';
                this.sediRicerca.unshift(dummy);

                this.dtFactory.aggiornaRicerca();
            });
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: ViewModels.DirezioneSort, filtro: Array<ViewModels.RicercaRigaVm>): void {
            var ricerca: ViewModels.RicercaVm = new ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<ViewModels.SortRigaVm>();
            ricerca.sort.push(new ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.depositoFactory.ricerca(ricerca).then((risultato: DepositoVm[]) => {
                this.lista = risultato;
            });
        }

        public aggiungi(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.resetRecordCorrente();
            this.testoAzione = this.localeFactory.get('button_inserisci');
            this.changeState('manipola');
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.flgShowCentrali = false;
                    this.recordCorrente = null;
                    this.dtFactory.aggiornaRicerca();
                    break;

                case "manipola":
                    this.formDeposito.$setPristine();
                    break;
            }
        }

        public isInEdit(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined
                && this.recordCorrente.Id != undefined;
        }

        public isInCreate(): boolean {
            return this.currentState == "manipola" && this.recordCorrente != undefined
                && this.recordCorrente.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public resetForm(): void {
            this.ricerca = new RicercaDeposito();
        }

        public gestioneDistinta(): void {
            var vm = this;
            this.tipoElemento = "";
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Deposito/modalDistinta.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public aggiornaRicercaDistinta(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.max = 50;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));

            switch (this.tipoElemento) {
                case "SET":
                    this.modelliSetFactory.ricerca(ricerca).then((risultato: Set.SetPaginationVm) => {
                        this.listaSet = risultato.List;
                    });
                    break;

                case "TNT":
                    this.tntFactory.ricerca(ricerca).then((risultato: Tnt.tntVm[]) => {
                        this.listaTnt = risultato;
                    });
                    break;

                case "ROT":
                    this.rotFactory.ricerca(ricerca, true).then((risultato: Rot.Rot[]) => {
                        this.listaRot = risultato;
                    });
                    break;

                case "PRD":
                    this.prodFactory.ricerca(ricerca).then((risultato: Prodotto.ProdottoVm[]) => {
                        this.listaProdotti = risultato;
                    });
                    break;
            }
        }

        public resetFormDistinta(): void {
            this.ricercaDistinta = new RicercaDistintaIntervento();
            var tableId = "";
            switch (this.tipoElemento) {
                case "SET":
                    tableId = "lista-distinta-set";

                    break;

                case "TNT":
                    tableId = "lista-distinta-tnt";
                    break;

                case "ROT":
                    tableId = "lista-distinta-rot";
                    break;

                case "PRD":
                    tableId = "lista-distinta-prd";
                    break;
            }
            this.dtFactory.aggiornaRicerca(tableId);
        }

        public aggiungiDistinta(d: any): void {
            var iD = this.getEmptyDepositoRiga();

            switch (this.tipoElemento) {
                case "SET":
                    iD.Tipo = this.tipoElemento;
                    iD.IdSet = d.Id;
                    iD.IdRot = null;
                    iD.IdTnt = null;
                    iD.IdProdotto = null;
                    iD.DescrizioneIt = d.Descrizione_it_IT;
                    iD.DescrizioneEn = d.Descrizione_en_US;
                    iD.DescrizioneAl = d.Descrizione_sq_AL;
                    iD.Codice = d.Codice;
                    break;

                case "TNT":
                    iD.Tipo = "T.N.T.";
                    iD.IdTnt = d.Id;
                    iD.IdSet = null;
                    iD.IdRot = null;
                    iD.IdProdotto = null;
                    iD.DescrizioneIt = d.Descrizione_it;
                    iD.DescrizioneEn = d.Descrizione_en;
                    iD.DescrizioneAl = d.Descrizione_al;
                    iD.Codice = d.Codice;
                    break;

                case "ROT":
                    iD.Tipo = this.tipoElemento;
                    iD.IdRot = d.Id;
                    iD.IdSet = null;
                    iD.IdTnt = null;
                    iD.IdProdotto = null;
                    iD.DescrizioneIt = d.Descrizione_it_IT;
                    iD.DescrizioneEn = d.Descrizione_en_US;
                    iD.DescrizioneAl = d.Descrizione_sq_AL;
                    iD.Codice = d.Codice;
                    break;

                case "PRD":
                    iD.Tipo = this.tipoElemento;
                    iD.IdRot = null;
                    iD.IdSet = null;
                    iD.IdTnt = null;
                    iD.IdProdotto = d.Id;
                    iD.DescrizioneIt = d.Descrizione_it_IT;
                    iD.DescrizioneEn = d.Descrizione_en_US;
                    iD.DescrizioneAl = d.Descrizione_sq_AL;
                    iD.Codice = d.Codice;
                    break;
            }

            var presente: boolean = false;
            if (this.recordCorrente.Scorte == undefined) 
                this.recordCorrente.Scorte = new Array<DepositoRigaSimpleVm>();

            $.each(this.recordCorrente.Scorte, (index: number, item: DepositoRigaSimpleVm) => {
                if (item.IdRot == iD.IdRot && item.IdSet == iD.IdSet && item.IdTnt == iD.IdTnt && item.IdProdotto == iD.IdProdotto) {
                    presente = true;
                    item.Qta += 1;
                    return;
                }
            });

            if (presente) return;
            //if (this.recordCorrente.Id != undefined)
            //    iD.IdIntervento = this.recordCorrente.Id;
            iD.Qta = 1;
            iD.ScortaMinima = 1;
            iD.SogliaRiordino = 1;
            this.recordCorrente.Scorte.push(iD);
            //this.dettaglioCorrente = new interventoRigaVm(); 
            this.formDeposito.$setDirty();
        }

        public cancellaDistinta(elemento: DepositoRigaSimpleVm): void {
            if (this.recordCorrente != undefined && this.recordCorrente.Scorte != undefined) {
                var posizione = this.recordCorrente.Scorte.indexOf(elemento);
                this.recordCorrente.Scorte.splice(posizione, 1);
                this.formDeposito.$setDirty();
            }
        }

        public chiudiModal(): void {
            this.$modalInstance.dismiss('cancel');
        }

        public centraleChanged(id: number): void {
            if ($("#centrale_" + id.toString()).is(":checked")) {
                this.recordCorrente.IdCentrali.push(id);
            } 
            else {
                $.each(this.recordCorrente.IdCentrali, (listIndex: number, item: number) => {
                    if (item === id) {
                        this.recordCorrente.IdCentrali.splice(listIndex);
                        return;
                    }
                });
            }
        }

    }
}

app.controller("depositoController", Trace.Deposito.depositoController);