app.factory("tipoInterventoFactory", ($http, $location, $q) => new Trace.TipoIntervento.tipoInterventoFactory($http, $location, $q));
module Trace.TipoIntervento {
    export class tipoInterventoFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/TipoIntervento/";
        }

        static $inject = ["$http", "$location", "$q"];
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public lista(): ng.IPromise<VwTipoInterventoVm[]> {
            var workUrl = this.GetBaseUrl();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<VwTipoInterventoVm[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public cancella(idIntervento: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idIntervento;
            var deferred = this.$q.defer();
            this.$http.delete(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public leggi(idIntervento: number): ng.IPromise<tipoInterventoVm> {
            var workUrl = this.GetBaseUrl() + idIntervento;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public SimpleList(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "SimpleList";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public inserisci(intervento: tipoInterventoVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, intervento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public modifica(intervento: tipoInterventoVm): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + intervento.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, intervento).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public listaRaggruppamenti(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + "listaRaggruppamenti";
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}