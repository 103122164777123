module Trace.ModelliSet {
    export class RicercaModelliSet {
        public Id: number;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdTipo: number;
        public IdGruppo: number;
        public IdSottoGruppo: number;
        public IdSede: number;
        public IdCentroCosto: number;
    }

    export class modelliSetController extends epBaseController {
        public set: Trace.Set.SetVm;
        public tipoSet: Trace.Set.TipoSetVm;
        private complessita: Trace.Set.ComplessitaVm;
        public listaSet: Trace.Set.SetVm[];
        public prodotti: Trace.Prodotto.ProdottoVm[];
        public gruppi: Trace.Gruppo.Gruppo[];
        public gruppiRicerca: Trace.Gruppo.Gruppo[];
        public gruppiProduzione: Trace.Gruppo.Gruppo[];
        public sottoGruppi: Trace.SottoGruppo.SottoGruppo[];
        public sottoGruppiRicerca: Trace.SottoGruppo.SottoGruppo[];
        public sottoGruppiProduzione: Trace.SottoGruppo.SottoGruppo[];
        public sedi: Trace.Sede.Sede[];
        public sediRicerca: Trace.Sede.Sede[];
        public sediProduzione: Trace.Sede.Sede[];
        public centriCosto: Trace.CentroCosto.CentroCosto[];
        public centriCostoRicerca: Trace.CentroCosto.CentroCosto[];
        public centriCostoProduzione: Trace.CentroCosto.CentroCosto[];
        public tipiSet: Trace.Set.TipoSetVm[];
        public tipiSterilizzazione: Trace.Set.TipoSterilizzazioneVm[];
        public listaUs: Trace.Set.UnitaSterilizzazioneVm[];
        public listaComplessita: Trace.Set.ComplessitaVm[];
        public distinta: Trace.Prodotto.DistintaVm[];
        public proprietari: Trace.ViewModels.ProprietarioVm[];
        public proprietario: number;
        public dt: Trace.datatables.dtFactory;
        public ricerca: RicercaModelliSet;
        public ricercaProdotti: Trace.Prodotto.RicercaProdotti;
        public enableUs: boolean;
        public currentState: string;
        public titoloManipola: string;
        public isSaving: boolean;
        public showValidationMessages: boolean;
        public nuovaRevisioneErrore: boolean;
        public formSet: any;
        public strumentoSelezionato: Set.StrumentoVm;
        public formProduzione: any;
        private currentPage: number;
        public produzioni: Produzione[];
        public cc: CentroCosto.CentroCosto;
        public giacenze: GiacenzaProdottoModelloSetVm[];
        public riepilogo: GiacenzaProdottoModelloSetVm[];
        public riepilogoConfermato: boolean;
        public strumentiInQuantita: Set.SetDetailVm[];
        public totCopie: number;
        public currentTotCopie: number;
        public numCopie: number;;

        static $inject = ["$scope", "dtFactory", "modelliSetFactory",
            "setFactory", "prodottiFactory", "gruppiFactory", "sottoGruppiFactory",
            "sediFactory", "centriCostoFactory", "Upload", "localeFactory",
            "msgFactory", "$uibModal", "$document", "utenteFactory", "tracciabilitaFactory"];

        constructor(
            public $scope: ng.IScope,
            private dtFactory: Trace.datatables.dtFactory,
            private modelliSetFactory: modelliSetFactory,
            public setFactory: Trace.Set.setFactory,
            public prodottiFactory: Trace.Prodotto.prodottiFactory,
            private gruppiFactory: Trace.Gruppo.gruppiFactory,
            private sottoGruppiFactory: Trace.SottoGruppo.sottoGruppiFactory,
            private sediFactory: Trace.Sede.sediFactory,
            private centriCostoFactory: Trace.CentroCosto.centroCostoFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            private $document: any,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(Upload, utenteFactory, setFactory, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, null, tracciabilitaFactory);
            var vm = this;
            this.cc = new CentroCosto.CentroCosto();
            this.formSet = {};
            this.formProduzione = {};
            this.setFactory.listaTipiSet().then((risultato: Trace.Set.TipoSetVm[]) => {
                this.tipiSet = risultato;
            });
            this.setFactory.listaUnitaSterilizzazione().then((risultato: Trace.Set.UnitaSterilizzazioneVm[]) => {
                this.listaUs = risultato;
            });
            this.setFactory.listaComplessita().then((risultato: Trace.Set.ComplessitaVm[]) => {
                this.listaComplessita = risultato;
            });
            this.setFactory.listaTipiSterilizzazione().then((risultato: Trace.Set.TipoSterilizzazioneVm[]) => {
                this.tipiSterilizzazione = risultato;
            });
            this.prodottiFactory.proprietari().then(risultato => {
                this.proprietari = risultato;
            });
            this.gruppiFactory.lista().then((risultato: Trace.Gruppo.Gruppo[]) => {
                this.gruppi = risultato;
                this.gruppiRicerca = risultato;
                this.gruppiProduzione = risultato;
            });
            $(document).on("imageUpload", (e: JQueryEventObject) => {
                vm.set.Immagini.push(e.image);
                vm.formSet.$setDirty();
            }).on("removeImage", (e: JQueryEventObject) => {
                var id = vm.set.Immagini.indexOf(e.image);
                vm.set.Immagini.splice(id, 1);
                vm.formSet.$setDirty();
            }).on("fileUpload", (e: JQueryEventObject) => {
                console.log("FileUpload event", e);
                var item = new ViewModels.ReadExcelItem();
                item.FileName = e.nomeFileUpload;
                item.OriginalName = e.lettura;
                item.Subfolder = e.tipoUpload;
                vm.modelliSetFactory.ReadExcel(item).then(
                    result => {
                        if (result === "") {
                            vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_nuovo_da_excel_ok", [e.lettura]));
                            vm.dtFactory.aggiornaRicerca();
                        }
                        else
                            vm.msgFactory.error(result);
                    },
                    reason => {
                    }
                );
            }).on('ngRepeatFinished', function (temp) {
                vm.setAutoScroll();
            });
            this.setUpWatchers();
        }

        /**
         * Controllo se è stata richiesta la pagina di dettaglio di un modello set specifico, altrimenti carico la lista
         */
        public checkModelId(): void {
            if (modelId == undefined)
                this.changeState('list');
            else
                this.leggi(modelId);
        }

        public infoContainer(): string {
            if (this.set == undefined) return "";
            return this.localeFactory.getFormatted('container_associato', [this.set.NumContainer, this.set.TotContainer]);
        }

        public ordinaStrumento(ordinamento: number, item: Set.SetDetailVm, index: number): void {
            var oldOrdinamento = item.Ordinamento;
            var newOrdinamento = item.Ordinamento + ordinamento;
            var strumento = $.grep(this.set.Corpo, (elem, id) => {
                return elem.Ordinamento === newOrdinamento;
            })[0];
            strumento.Ordinamento = oldOrdinamento;
            item.Ordinamento = newOrdinamento;
            console.log("modifica ordinamento", oldOrdinamento, newOrdinamento);
            console.log("strumento cliccato", newOrdinamento, item.Ordinamento);
            console.log("strumento interessato", oldOrdinamento, strumento.Ordinamento);
            this.formSet.$setDirty();
        }

        public imageUrl(immagine: string) {
            return appRoot + immagine;
        }

        public checkDataValidita(data: Date): boolean {
            if (data == undefined) return true;
            var now = new Date();
            var result = moment(data).isAfter(now);
            return result;
        }

        public getDataValiditaTitle(di: Date, df: Date) {
            var dvf = this.checkDataValidita(df);
            var dvi = this.checkDataValidita(di);
            if (!dvf)
                return this.localeFactory.get('fine_validita');
            if (!dvi && dvf)
                return this.localeFactory.get('valido');
            if (dvi && df == undefined)
                return this.localeFactory.get('da_validare');
        }

        public formatDate(data: Date): string {
            if (data == undefined) data = new Date();
            var result = moment(data).format("DD/MM/YYYY");
            return result;
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.set = null;
                    this.dtFactory.aggiornaRicerca("", this.currentPage);
                    break;

                case "manipola":
                    this.strumentoSelezionato = undefined;
                    this.formSet.$setPristine();
                    break;

                case "produzione":
                    console.log("Form produzione", this.formProduzione);
                    this.formProduzione.$setPristine();
                    break;
            }
            $(".datetime").datepicker();
        }

        public chiudiModal(): void {
            this.$modalInstance.close();
            this.$modalInstance.dismiss('cancel');
        }

        public nuovo(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.set = new Trace.Set.SetVm();
            this.set.Immagini = new Array<Trace.ViewModels.ImmagineVm>();
            this.set.StrDataRevisione = moment(this.set.DataRevisione).format("DD/MM/YYYY");
            this.set.IdRevisione = 1;
            this.enableUs = false;
            this.changeState("manipola");
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.set.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoChanged(newVal);
                this.calcolaCodice();
            });
            this.$scope.$watch("vm.cc.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoProduzioneChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdGruppo", (newVal: number, oldVal: number) => {
                this.gruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.set.IdSottogruppo", (newVal: number, oldVal: number) => {
                this.sottoGruppoChanged(newVal);
                this.calcolaCodice();
            });
            this.$scope.$watch("vm.cc.IdSottogruppo", (newVal: number, oldVal: number) => {
                this.sottoGruppoProduzioneChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSottoGruppo", (newVal: number, oldVal: number) => {
                this.sottoGruppoRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.set.IdSede", (newVal: number, oldVal: number) => {
                this.sedeChanged(newVal);
                this.calcolaCodice();
            });
            this.$scope.$watch("vm.cc.IdSede", (newVal: number, oldVal: number) => {
                this.sedeProduzioneChanged(newVal);
            });
            this.$scope.$watch("vm.ricerca.IdSede", (newVal: number, oldVal: number) => {
                this.sedeRicercaChanged(newVal);
            });
            this.$scope.$watch("vm.set.IdCentroCosto", (newVal: number, oldVal: number) => {
                this.calcolaCodice();
            });
            this.$scope.$watch("vm.ricerca.IdCentroCosto", (newVal: number, oldVal: number) => {
                this.dtFactory.aggiornaRicerca();
            });
            this.$scope.$watch("vm.set.IdTipo", (newVal: number, oldVal: number) => {
                this.tipoSetChanged();
            });
            this.$scope.$watch("vm.set.IdComplessita", (newVal: number, oldVal: number) => {
                this.complessitaChanged();
            });
            this.$scope.$watch("vm.set.StrDataInizioValidita", (newVal: string, oldVal: string) => {
                if (this.set == undefined) return;
                this.set.DataInizioValidita = moment(newVal, "DD/MM/YYYY").toDate();
            });
            this.$scope.$watch("vm.set.StrDataRevisione", (newVal: string, oldVal: string) => {
                if (this.set == undefined) return;
                this.set.DataRevisione = moment(newVal, "DD/MM/YYYY").toDate();
            });
            this.$scope.$watch("vm.proprietario", (newVal: number, oldVal: number) => {
                if (this.set == undefined || this.set.Corpo == undefined || (newVal == undefined && oldVal == undefined)) return;
                $.each(this.set.Corpo, (index: number, item: Trace.Set.SetDetailVm) => {
                    if (item.IdProprietario == null || item.IdProprietario === oldVal)
                        item.IdProprietario = newVal;
                });
            });
        }

        public scrollTo(id: string): ng.IPromise<any> {
            var vm = this;
            var top = angular.element(document.getElementById(id));
            return this.$document.scrollToElement(top, 0, 300);
        }

        public associatoClicked(id: number): void {
            var vm = this;
            var top = angular.element(document.getElementById("top"));
            this.scrollTo("top").then(() => {
                vm.leggi(id);
            });
        }

        private complessitaChanged(): void {
            var vm = this;
            if (this.set == undefined || this.set.IdComplessita == undefined) {
                this.complessita = null;
                return;
            }
            this.complessita = $.grep(this.listaComplessita, (item: Trace.Set.ComplessitaVm, index: number) => { return item.Id === vm.set.IdComplessita; })[0];
            this.set.Costo = this.complessita.Costo;
        }

        private tipoSetChanged(): void {
            var vm = this;
            if (this.set == undefined || this.set.IdTipo == undefined) {
                this.tipoSet = null;
                return;
            }
            this.tipoSet = $.grep(this.tipiSet, (item: Trace.Set.TipoSetVm, index: number) => { return item.Id === vm.set.IdTipo; })[0];
        }

        private calcolaCodice(): void {
            /*
            var vm = this;
            if (this.set == undefined) return;
            if (this.set.IdGruppo == undefined || this.set.IdGruppo === 0) {
                vm.set.Codice = "";
                return;
            }
            var codGruppo = $.grep(this.gruppi, (item: Trace.Gruppo.Gruppo, index: number) => { return item.Id === vm.set.IdGruppo; })[0].Codice;
            var codSgruppo = "";
            if (this.set.IdSottogruppo != undefined && this.set.IdSottogruppo > 0 && this.sottoGruppi != undefined && this.sottoGruppi.length > 0) {
                var tSg = $.grep(this.sottoGruppi, (item: Trace.SottoGruppo.SottoGruppo, index: number) => { return item.Id === vm.set.IdSottogruppo; });
                if (tSg != undefined && tSg.length > 0)
                    codSgruppo = tSg[0].Codice;
            }
            var codSede = "";
            if (this.set.IdSede != undefined && this.set.IdSede > 0 && this.sedi != undefined && this.sedi.length > 0) {
                var tSd = $.grep(this.sedi, (item: Trace.Sede.Sede, index: number) => { return item.Id === vm.set.IdSede; });
                if (tSd != undefined && tSg.length > 0)
                    codSede = tSd[0].Codice;
            }
            if (this.set.IdCentroCosto != undefined && this.set.IdCentroCosto > 0 && this.centriCosto != undefined && this.centriCosto.length > 0) {
                var codCc = $.grep(this.centriCosto, (item: Trace.CentroCosto.CentroCosto, index: number) => { return item.Id === vm.set.IdCentroCosto; })[0].Codice;
                this.setFactory.codiceSet(this.set.IdCentroCosto).then((codice: string) => {
                    vm.set.Codice = modellisetController.prefissoCodice + (codGruppo + codSgruppo + codSede + codCc + codice).toUpperCase();
                });
            }
            */
        }

        public isInEdit(): boolean {
            var vm = this;
            return vm.currentState == "manipola" && vm.set != undefined && vm.set.Id != undefined;
        }

        public isInCreate(): boolean {
            var vm = this;
            return vm.currentState == "manipola" && vm.set != undefined && vm.set.Id == undefined;
        }
        
        public resetForm(): void {
            this.ricerca = new RicercaModelliSet();
        }

        public annulla(): void {
            var vm = this;
            var conf = (this.currentState === 'produzione')
                ? this.msgFactory.confirm(this.localeFactory.get("produzione_messaggio_annullamento"))
                : this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                if (this.currentState === 'produzione')
                    this.changeState("manipola");
                else 
                    this.backToList();
            });
        }

        public backToList(): void {
            this.changeState('list');
        }

        public registra(): void {
            var vm = this;
            this.showValidationMessages = false;
            if (!this.formSet.$valid) {
                this.showValidationMessages = true;
                this.formSet.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            else if (this.set.Corpo == undefined || this.set.Corpo.length <= 0) {
                var promise = this.msgFactory.confirm(this.localeFactory.getFormatted("validazione_alert_no_corpo", [this.set.Descrizione_it_IT]));
                promise.then(result => {
                    if (!result.result) return;
                    this.confermaRegistra(false);
                });
            }
            else {
                this.confermaRegistra();
            }
        }

        public confermaRegistra(showConfirm: boolean = true): void {
            if (showConfirm) {
                var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_salvataggio_conferma"));
                conf.then(result => {
                    if (!result.result) return;
                    this.doRegistrazione();
                });
            }
            else {
                this.doRegistrazione();
            }
        }

        private doRegistrazione(): void {
            this.isSaving = true;
            var promise = (this.set.Id == undefined)
                ? this.modelliSetFactory.inserisci(this.set)
                : this.modelliSetFactory.modifica(this.set);

            promise.then(
                result => {
                    var alertPromise = this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                    alertPromise.then(result => {
                        this.changeState("list");
                    });
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_errore", [reason]));
                }).finally(() => {
                    this.isSaving = false;
                });
        }

        public leggi(id: number): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.modelliSetFactory.leggi(id).then((risultato: Trace.Set.SetVm) => {
                this.set = risultato;
                $.each(this.set.Corpo, (index, item) => {
                    this.set.Corpo[index].Ordinamento = index;
                });
                this.changeState("manipola");
            });
        }

        public clona(): void {
            var vm = this;
            this.msgFactory.confirm(this.localeFactory.get("messaggio_clonazione_conferma")).then(
                risultato => {
                    if (!risultato.result) return;
                    vm.modelliSetFactory.clona(vm.set.Id).then(
                        // OK
                        clonaResult => {
                            vm.msgFactory.alert(vm.localeFactory.get("messaggio_clonazione_ok")).then(result => {
                                vm.leggi(clonaResult.Id);
                            });
                        },
                        // ERRORE
                        reason => {
                            var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                            vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_clonazione_errore", [errore]));
                        }
                    );
                }
            );
        }

        public spezza(): void {
            var vm = this;
            this.msgFactory.confirm(this.localeFactory.get("messaggio_spezza_conferma")).then(
                risultato => {
                    if (!risultato.result) return;
                    vm.modelliSetFactory.spezza(vm.set.Id).then(
                        // OK
                        spezzaResult => {
                            vm.msgFactory.alert(vm.localeFactory.get("messaggio_spezza_ok")).then(result => {
                                vm.leggi(spezzaResult.Id);
                            });
                        },
                        // ERRORE
                        reason => {
                            var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                            vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_spezza_errore", [errore]));
                        }
                    );
                }
            );
        }

        public nuovaRevisione(): void {
            if (this.set.IdModelloNuovaRevisione != undefined) {
                this.msgFactory.error(this.localeFactory.get("nuova_revisione_gia_presente"));
                return;
            }
            var now = new Date();
            var valido = !moment(this.set.DataInizioValidita).isAfter(now);
            if (!valido) {
                this.msgFactory.error(this.localeFactory.get("nuova_revisione_non_valido"));
                return;
            }
            this.nuovaRevisioneErrore = false;
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/ModelliSet/modalNuovaRevisione.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "md"
            };
            this.$modalInstance = this.$uibModal.open(options);
            this.$modalInstance.rendered.then(() => {
                $(".datetime").datepicker({ minDate: new Date() });
            });
        }

        public creaNuovaRevisione(): void {
            var data = $("#nuovaDataVal").datepicker("getDate");
            if (data == undefined) {
                this.nuovaRevisioneErrore = true;
                return;
            }
            var vm = this;
            this.$modalInstance.close();
            this.modelliSetFactory.nuovaRevisione(this.set.Id, data).then(
                // OK
                nuovaRevisioneResult => {
                    vm.msgFactory.alert(vm.localeFactory.get("messaggio_nuova_revisione_ok")).then(result => {
                        vm.leggi(nuovaRevisioneResult);
                    });
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_nuova_revisione_errore", [errore]));
                }
            );
        }

        public cancella(id: number): void {
            var vm = this;
            this.modelliSetFactory.inUso(id).then(
                // OK
                risultato => {
                    if (risultato) {
                        vm.msgFactory.alert(vm.localeFactory.get("messaggio_in_uso"));
                        return;
                    }
                    this.msgFactory.confirm(vm.localeFactory.get("messaggio_cancellazione_conferma")).then(
                        risultato => {
                            if (!risultato.result) return;
                            vm.modelliSetFactory.cancella(id).then(
                                // OK
                                result => {
                                    vm.msgFactory.alert(vm.localeFactory.get("messaggio_cancellazione_ok")).then(result => {
                                        vm.changeState("list");
                                        vm.dtFactory.aggiornaRicerca();
                                    });
                                },
                                // ERRORE
                                reason => {
                                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_cancellazione_errore", [errore]));
                                }
                            );
                        }
                    );
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_controllo_errore", [errore]));
                }
            );
        }

        private initFilters(): void {
            // inizializzo il modello della ricerca
            this.resetForm();
        }

        public gestioneCorpoSet(): void {
            this.scrollTo("panel-corpo");
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/ModelliSet/modalCorpoSet.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public aggiornaRicercaCorpoSet(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            // Limito la ricerca ai soli prodotti NON TNT e NON 
            var f = new ViewModels.RicercaRigaVm();
            f.colonna = "IdTipoNo";
            f.valore = "3";
            filtro.push(f);
            ricerca.max = 50;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.prodottiFactory.ricerca(ricerca).then((risultato: Trace.Prodotto.ProdottoVm[]) => {
                this.prodotti = risultato;
            });
        }

        public resetFormCorpoSet(): void {
            this.ricercaProdotti = new Trace.Prodotto.RicercaProdotti();
        }

        public aggiungiProdotto(prodotto: Trace.Prodotto.ProdottoVm): void {
            console.log("modelliSetContorller");
            var presente: boolean = false;
            if (this.set.Corpo != undefined) {
                $.each(this.set.Corpo, (index: number, item: Trace.Set.SetDetailVm) => {
                    if (item.IdProdotto === prodotto.Id) {
                        presente = true;
                        item.Qta += 1;
                        return;
                    }
                });
            }
            else {
                this.set.Corpo = new Array<Trace.Set.SetDetailVm>();
            }
            this.formSet.$setDirty();
            if (presente) return;
            var d = new Trace.Set.SetDetailVm();
            d.Codice = prodotto.Codice;
            d.DescrizioneAl = prodotto.Descrizione_sq_AL;
            d.DescrizioneEn = prodotto.Descrizione_en_US;
            d.DescrizioneIt = prodotto.Descrizione_it_IT;
            d.IdProdotto = prodotto.Id;
            d.Qta = 1;
            d.Ordinamento = this.set.Corpo.length;
            if (this.set.Id != undefined)
                d.IdSet = this.set.Id;
            this.set.Corpo.push(d);
        }

        public cancellaProdotto(idProdotto: number): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_cancellazione_prodotto_conferma"));
            conf.then(result => {
                if (!result.result) return;
                $.each(this.set.Corpo, (index: number, item: Trace.Set.SetDetailVm) => {
                    if (item.IdProdotto === idProdotto) {
                        vm.set.Corpo.splice(index, 1);
                    }
                });
                $.each(this.set.Corpo, (index, item) => {
                    this.set.Corpo[index].Ordinamento = index;
                });
                vm.formSet.$setDirty();
            });
        }

        //public infoProdotto(prodotto: Trace.Set.SetDetailVm): void {
        //    this.strumentoSelezionato = prodotto;
        //    // Distinta
        //    this.distintaProdotto(prodotto.IdProdotto, prodotto.HasDistinta);
        //    // Immagini
        //    this.immaginiProdotto(prodotto.IdProdotto);
        //}

        //public immaginiProdotto(idProdotto: number): void {
        //    var vm = this;
        //    this.prodottiFactory.immagini(idProdotto).then(
        //        // OK
        //        result => {
        //            console.log(result);
        //            this.strumentoSelezionato.Immagini = result;
        //        },
        //        // ERRORE
        //        reason => {
        //            var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
        //            vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_lettura_immagini_errore", [errore]));
        //        }
        //    );
        //}

        public distintaProdotto(idProdotto: number, hasDistinta: boolean): void {
            if (!hasDistinta) {
                this.distinta = [];
                return;
            }

            var vm = this;
            this.prodottiFactory.distinta(idProdotto).then(
                // OK
                risultato => {
                    vm.distinta = risultato;
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_spezza_errore", [errore]));
                }
            );
        }

        public descrizioneStrumentoSelezionato(): string {
            if (this.strumentoSelezionato == undefined) return "";
            switch (culture) {
                case "it":
                    return this.strumentoSelezionato.DescrizioneIt;
                case "al":
                    return this.strumentoSelezionato.DescrizioneAl;
                case "en":
                    return this.strumentoSelezionato.DescrizioneEn;
            }
        }

        public gruppoChanged(idGruppo: number): void {
            this.sottoGruppi = [];
            this.sedi = [];
            this.centriCosto = [];

            if (idGruppo == undefined) return;
            this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppi = risultato;
            });
        }

        public gruppoProduzioneChanged(idGruppo: number): void {
            this.sottoGruppiProduzione = [];
            this.sediProduzione = [];
            this.centriCostoProduzione = [];

            if (idGruppo == undefined) return;
            this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                this.sottoGruppiProduzione = risultato;
            });
        }

        public sottoGruppoChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];

            if (idSottogruppo == undefined) return;
            this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                this.sedi = risultato;
            });
        }

        public sottoGruppoProduzioneChanged(idSottogruppo: number): void {
            this.sediProduzione = [];
            this.centriCostoProduzione = [];

            if (idSottogruppo == undefined) return;
            this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                this.sediProduzione = risultato;
            });
        }

        public sedeChanged(idSede: number): void {
            this.centriCosto = [];

            if (idSede == undefined) return;
            this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                this.centriCosto = risultato;
            });
        }

        public sedeProduzioneChanged(idSede: number): void {
            this.centriCostoProduzione = [];
            if (idSede == undefined) return;
            this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                this.centriCostoProduzione = risultato;
            });
        }

        public gruppoRicercaChanged(idGruppo: number): void {
            this.sottoGruppiRicerca = [];
            if (idGruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sottoGruppiFactory.listaGruppo(idGruppo).then((risultato: Trace.SottoGruppo.SottoGruppo[]) => {
                    this.sottoGruppiRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sottoGruppoRicercaChanged(idSottogruppo: number): void {
            this.sedi = [];
            this.centriCosto = [];
            if (idSottogruppo == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.sediFactory.listaSottoGruppo(idSottogruppo).then((risultato: Trace.Sede.Sede[]) => {
                    this.sediRicerca = risultato;
                    this.dtFactory.aggiornaRicerca();
                });
            }
        }

        public sedeRicercaChanged(idSede: number): void {
            this.centriCosto = [];
            if (idSede == undefined) this.dtFactory.aggiornaRicerca();
            else {
                this.centriCostoFactory.listaSede(idSede).then((risultato: Trace.CentroCosto.CentroCosto[]) => {
                    this.centriCostoRicerca = risultato;
                });
            }
        }        

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.currentPage = page;
            this.modelliSetFactory.ricerca(ricerca, page).then((risultato: Trace.Set.SetPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.listaSet = r.List;
            });
        }

        public goToProduzione(): void {
            var vm = this;
            vm.isSaving = true;
            this.totCopie = undefined;
            this.cc = new CentroCosto.CentroCosto();
            this.riepilogo = undefined;
            this.produzioni = undefined;
            this.riepilogoConfermato = false;
            this.setFactory.GetNumeroCopie(this.set.Id).then(
                // OK
                risultato => {
                    vm.numCopie = risultato;
                    vm.modelliSetFactory.GetGiacenzeProdotti(vm.set.Id).then(
                        // OK
                        risultato => {
                            vm.isSaving = false;
                            vm.giacenze = risultato;
                            vm.setUpProduzione()
                        },
                        // ERRORE
                        reason => {
                            vm.isSaving = false;
                            var errore: string = reason == undefined ? vm.localeFactory.get("nessun_dettaglio") : reason;
                            vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_spezza_errore", [errore]));
                        }
                    );
                },
                // ERRORE
                reason => {
                    vm.isSaving = false;
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_spezza_errore", [errore]));
                }
            );
        }

        private setUpProduzione(): void {
            this.$scope.$watch("vm.totCopie", (newVal: number, oldVal: number) => {
                this.totCopieProduzioneChangeHandler(newVal);
            });
            
            this.strumentiInQuantita = $.grep(this.set.Corpo, (elem, id) => {
                elem.HasDistinta = false;
                return elem.Qta > 0;
            });
            this.changeState("produzione");
        }

        public totCopieProduzioneChangeHandler(newVal: number): void {
            var nCopia = this.numCopie;
            if (this.currentTotCopie == undefined) {
                this.produzioni = new Array<Produzione>();
                this.addProduzioni(newVal, 0, nCopia);
            }
            else {
                var diff = newVal - this.currentTotCopie;
                if (diff > 0) {
                    
                    this.addProduzioni(diff, this.produzioni.length, nCopia);
                }
                else {
                    this.removeProduzioni(Math.abs(diff));
                }
            }

            if (this.riepilogo == undefined || this.riepilogo.length == 0) {
                this.riepilogo = new Array<GiacenzaProdottoModelloSetVm>();
                this.setUpRiepilogo();
            }
            else {
                this.updateRiepilogo();
            }

            this.currentTotCopie = newVal;
        }

        private addProduzioni(num: number, base: number, baseCopy: number) {
            for (var i = 0; i < num; i++) {
                var g = angular.copy(this.giacenze);
                var s = angular.copy(this.strumentiInQuantita);
                s = s.sort(function (a, b) {
                    console.log(a.Codice, b.Codice);
                    var a1 = a.Codice.toLowerCase(), b1 = b.Codice.toLowerCase();
                    if (a1 == b1) return 0;
                    return a1 > b1 ? 1 : -1;
                });
                console.log(s);
                var p = new Produzione(base + i + 1, baseCopy, num, s, g);

                this.produzioni.push(p);
            }
        }

        private removeProduzioni(num: number) {
            if (this.produzioni == undefined) return;
            this.produzioni.splice(-1, num);
        }

        private setUpRiepilogo() {
            $.each(this.strumentiInQuantita, (ids, s) => {
                var r = new GiacenzaProdottoModelloSetVm();
                var g = $.grep(this.giacenze, (gia, idg) => {
                    return gia.IdProdotto == s.IdProdotto;
                });
                r.IdModelloSet = this.set.Id;
                r.IdProdotto = s.IdProdotto;
                r.QtaDeposito = (g != undefined && g.length > 0)
                    ? g[0].QtaDeposito
                    : 0;
                r.QtaDocumento = (g != undefined && g.length > 0)
                    ? g[0].QtaDocumento
                    : 0;
                r.UtilizzatiDeposito = 0;
                r.UtilizzatiDocumento = 0;
                this.riepilogo.push(r);
            });
        }

        private setUpUtilizzi(r: GiacenzaProdottoModelloSetVm, pDoc: number, pDep: number): void {
            console.log("Aggiorno qta doc:", r.UtilizzatiDeposito, pDep, "Aggiorno qta doc:", r.UtilizzatiDocumento, pDoc);
            r.UtilizzatiDeposito += pDep;
            r.UtilizzatiDocumento += pDoc;
        }
        
        private updateRiepilogo(): void {
            var uDoc = 0;
            var uDep = 0;
            $.each(this.produzioni, (idp, pr) => {
                $.each(pr.Strumenti, (ide, e) => {
                    if (idp == 0) {
                        this.riepilogo[ide].UtilizzatiDocumento = 0;
                        this.riepilogo[ide].UtilizzatiDeposito = 0;
                    }
                    this.setUpUtilizzi(this.riepilogo[ide], e.QtaPosizionabileDoc, e.QtaPosizionabileDep);
                });
            });
        }

        public setAutoScroll(): void {
            $(".auto-scroll").scrollTop(0);
            $(".auto-scroll").on("scroll", (event) => {
                $(".auto-scroll").scrollTop($(event.target).scrollTop());
            });

            this.updateRiepilogo();
        }

        public totProdotti(tipo: number): number {
            if (this.produzioni == undefined) return 0;
            var q = 0;
            var p = 0;
            var m = 0;
            $.each(this.produzioni, (idp, pr) => {
                $.each(pr.Strumenti, (ide, e) => {
                    q += e.Qta;
                    p += (e.QtaPosizionabileDep + e.QtaPosizionabileDoc);
                    m += (e.Qta - (e.QtaPosizionabileDep + e.QtaPosizionabileDoc));
                });
            });
            switch (tipo) {
                // tutti
                case 0:
                    return q;
                // presenti
                case 1:
                    return p;
                // nessuno
                case 3:
                    return m;
            }

            return 0;
        }

        public totRiepilogo(tipo: number): number {
            if (this.riepilogo == undefined) return 0;
            var doc = 0;
            var dep = 0;
            $.each(this.riepilogo, (idp, pr) => {
                doc += pr.UtilizzatiDocumento;
                dep += pr.UtilizzatiDeposito;
            });
            switch (tipo) {
                // documento
                case 0:
                    return doc;
                // deposito
                case 1:
                    return dep;
            }
            return 0;
        }

        public confermaRiepilogo(): void {
            this.riepilogoConfermato = this.riepilogoConfermato == undefined
                ? true
                : !this.riepilogoConfermato;
            $.each(this.riepilogo, (idr, r) => {
                r.Conferma = (r.QtaDocumento > 0 || r.QtaDeposito > 0) && this.riepilogoConfermato;
                $.each(this.produzioni, (idp, p) => {
                    $.each(p.Strumenti, (ids, s) => {
                        if (s.IdProdotto == r.IdProdotto && (s.QtaPosizionabileDep + s.QtaPosizionabileDoc) > 0) {
                            s.HasDistinta = r.Conferma;
                            return;
                        }
                    });
                });
            });
        }

        public confermaRigaRiepilogo(r: GiacenzaProdottoModelloSetVm): void {
            r.Conferma = !r.Conferma;
            $.each(this.produzioni, (idp, p) => {
                $.each(p.Strumenti, (ids, s) => {
                    if (s.IdProdotto == r.IdProdotto) {
                        s.HasDistinta = r.Conferma;
                        return;
                    }
                });
            });
        }

        public filtraProduzione(tipo: number): void {
            $.each(this.produzioni, (idp, p) => {
                p.filteredStrumenti = $.grep(p.Strumenti, (s, ids) => {
                    return (tipo == 0) ? true : (s.QtaPosizionabileDep + s.QtaPosizionabileDoc) > 0;
                });
            });
            $(document).trigger("filtroGeneraleProduzione");
        }

        public limitaUtilizzi(r: GiacenzaProdottoModelloSetVm) {
            if (this.giacenze == undefined) return;
            var idStrumento: number;
            var g = $.grep(this.giacenze, (gia, idg) => {
                return gia.IdProdotto == r.IdProdotto;
            });
            $.each(this.strumentiInQuantita, (ids, s) => {
                if (s.IdProdotto == r.IdProdotto) {
                    idStrumento = ids;
                    return;
                }
            });
            if (g == undefined || g.length <= 0) return;
            $.each(this.produzioni, (idp, p) => {
                if (r.LimiteDocumento == null) r.LimiteDocumento = undefined;
                if (r.LimiteDeposito == null) r.LimiteDeposito = undefined;
                p.setUpData(idStrumento, r.LimiteDocumento, r.LimiteDeposito, this.giacenze);
            });
            this.updateRiepilogo();
            $(document).trigger("filtroGeneraleProduzione");
        }

        public checkLimiti(r: GiacenzaProdottoModelloSetVm): boolean {
            var lDep = (r.LimiteDeposito != undefined);
            var lDoc = (r.LimiteDocumento != undefined);
            return lDep || lDoc;
        }

        public submitProduzione(): void {
            var vm = this;
            if (!this.formProduzione.$valid) {
                this.msgFactory.error("ATTENZIONE:\nDati errati o mancanti. Si prega di controllare il numero di copie da generare e/o il centro di costo a cui associare i set")
                return;
            }
            var confermati = $.grep(this.riepilogo, (p, idp) => {
                return p.Conferma === true;
            });
            if (confermati == undefined || confermati.length <= 0) {
                this.msgFactory.error("ATTENZIONE:\nNessuo scarico confermato. Impossibile continuare");
                return;
            }
            this.isSaving = true;
            var produzioni = new Array<Produzione>();

            $.each(this.produzioni, (idp, p) => {
                p.IdGruppo = this.cc.IdGruppo;
                p.IdCentroCosto = this.cc.Id;
                p.IdSottogruppo = this.cc.IdSottogruppo;
                p.IdSede = this.cc.IdSede;
                p.TotCopie = this.totCopie;
                p.NumCopia = this.numCopie;
                produzioni.push(p);
            }); 

            this.setFactory.SalvaProduzione(this.set.Id, produzioni).then(
                result => {
                    if (result) {
                        vm.msgFactory.alert("Produzione registrata correttamente").then(
                            torna => {
                                vm.backToList();
                            }
                        );
                    }
                    else {
                        vm.msgFactory.error("ATTENZIONE:\nErrore imprevisto durante la registrazione della produzione. Si prega di riprovare");
                    }
                    vm.isSaving = false;
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? vm.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.error(vm.localeFactory.getFormatted("messaggio_spezza_errore", [errore]));
                    vm.isSaving = false;
                    
                }
            );
        }

        public getExportUrl(): void {
            this.modelliSetFactory.ExportExcelProduzioneSet(this.set.Id, this.produzioni).then(
                result => {
                    console.log("ecco il link", result);
                    window.open("/" + result, "_blank");
                }
            );
        }
    }
}
app.controller("modelliSetController", Trace.ModelliSet.modelliSetController);