app.factory("stampeFactory", ($http, $q) => new Trace.Stampe.stampeFactory($http, $q));
module Trace.Stampe {
    export class stampeFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Stampe/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public RicercaOrdiniByFatture(filtro: Stampe.FiltroSmistamentoStrumentiFatture): ng.IPromise<Ordini.VwDettaglioOrdine[]> {
            var workUrl = this.GetBaseUrl() + "RicercaOrdiniByFatture";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public RicercaScarichiConsumabili(filtro: Stampe.FiltroScaricoConsumabili): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl() + "RicercaScarichiConsumabili";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}