
app.factory("manutenzioniFactory", ($http, $q) => new Trace.Manutenzioni.manutenzioniFactory($http, $q));
module Trace.Manutenzioni {
    export class manutenzioniFactory {
        public GetBaseUrl(): string {
            return appRoot + "api/Manutenzioni/";
        }

        static $inject = ["$http", "$q"];
        constructor(private $http: ng.IHttpService, private $q: ng.IQService) { }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, currentPage: number = undefined): ng.IPromise<ViewModels.GenericPaginationVm<Set.StrumentoVm>> {
            var workUrl = this.GetBaseUrl() + "Ricerca/" + currentPage.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public leggi(id: number): ng.IPromise<ManutenzioneDetailsVm> {
            var workUrl = this.GetBaseUrl() + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public Tracking(id: number): ng.IPromise<ViewModels.TrackingVm[]> {
            var workUrl = this.GetBaseUrl() + "Tracking/" + id.toString();
            var deferred = this.$q.defer();
            this.$http.get(workUrl).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
    }
}