
module Trace.Produzione {
    export class DatiProduzioneVm implements Produzione.DatiProduzione {
        public TrattamentiSet: Trace.Produzione.LottoProduzione_Trattamento[];
        public TrattamentiGriglie: Trace.Produzione.LottoProduzione_GrigliaStrumenti_Trattamento[];
        public TrattamentiStrumenti: Trace.Produzione.LottoProduzioneDetail_Trattamento[];
        public AnomalieStrumenti: Trace.Produzione.AnomaliaStrumento[];
        public FlgLavaggioContainer: boolean;
        public PesoIngressoAutoclave: number;
        public PesoUscitaAutoclave: number;
        public IdLottoProduzione: number;
        public AddToManutenzione: any[];
        public AddToSmaltimento: any[];
        public ContaLavaggio: number;
        public Note: string;
        public IdStatusUsura: number;
    }
}