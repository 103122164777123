module Trace.Consumi {
    export interface RicercaConsumi {
        DataDa: Date;
        DataA: Date;
        IdRaggruppamentoInterventi: number;
        IdRaggruppamentoTnt: number;
        IdGruppo: number;
        IdSottogruppo: number;
        IdSede: number;
        IdCentroCosto: number;
    }
}