module Trace.Movimenti {
    export class MovimentoRigaVm implements MovimentoVm {
        public Id: number;
        public DataCreazione: Date;
        public DataCreazioneReale: Date;
        public DataObsoleto: Date;
        public DataUltimaModifica: Date;
        public IdDestinazione: number;
        public IdDocumento: number;
        public IdProvenienza: number;
        public IdTipoDestinazione: number;
        public IdTipoMovimento: number;
        public IdTipoProvenienza: number;
        public IdUtenteCreazione: number;
        public IdUtenteObsoleto: number;
        public IdUtenteUltimaModifica: number;
        public NumRighe: number;
        public NumMateriali: number;
        public TipoMateriali: string;
        public Descrizione: string;
        public MovimentiDetails: Trace.Movimenti.MovimentoDetail[];
        public IdTipo: number;
        public DescrTipo: string;
        public Provenienza: Trace.ViewModels.ControparteVm;
        public Destinazione: Trace.ViewModels.ControparteVm;
        public DescrUtenteCreazione: string;
        public DescrTipoMateriali: string;
        public Details: Trace.Movimenti.VwRigaMovimento[];
        public RichiestaDetails: Trace.RichiesteApprovvigionamenti.VwRigaRichiestaApprovvigionamento[];
        public RemoteIp: string;
        public FlgMobile: boolean;
        public IdIntervento: number;
        public FlgConfermato: boolean;
        public IdVettore: number;
        public IdCarrello: number;
        public FlgDdtCentrale: boolean;
        public NumDocumento: string;
        public FlgAccettazione: boolean;
        public IdUtenteAccettazione: number;
        public UtenteAccettazione: string;
        public DataAccettazione: Date;
        public IdMovimento: number;
        public IdOrdine: number;
    }
}