module Trace.Prodotto {
    export class RicercaProdotti {
        public Id: number;
        public Codice: string;
        public DescrizioneIt: string;
        public DescrizioneEn: string;
        public DescrizioneAl: string;
        public IdTipo: string;
    }

    export class prodottiController extends epBaseController {
        public prodotti: ProdottoVm[];
        public prodottiAlternativi: ProdottoVm[];
        public distintaProdotti: ProdottoVm[];
        public prodotto: ProdottoVm;
        public notCoded: DistintaVm;
        public ricerca: RicercaProdotti;
        public ricercaCodiciAlternativi: RicercaProdotti;
        public ricercaDistinta: RicercaProdotti;
        public testoAzione: string;
        public currentState: string;
        public marche: Helpers.SimpleEntity[];
        public unitaMisura: Helpers.SimpleEntity[];
        public raggruppamenti: Helpers.SimpleEntity[];
        public tipi: TipoProdottoVm[];
        public gestioneNotCoded: boolean;
        public titoloManipola: string;
        public isSaving: boolean;
        public showValidationMessages: boolean;
        public formProdotti: any;
        public currentPage: number;
        public fornitori: Trace.Fornitori.FornitoriMarcheVm[]; 

        public prdAlternativo: any = undefined;

        static $inject = ["$scope", "prodottiFactory","fornitoriFactory", "dtFactory", "localeFactory", "msgFactory", "Upload", "$uibModal", "utenteFactory", "tracciabilitaFactory"];

        constructor(
            private $scope: ng.IScope,
            public prodottiFactory: prodottiFactory,
            public fornitoriFactory: Trace.Fornitori.fornitoriFactory,
            private dtFactory: Trace.datatables.dtFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public Upload: ng.angularFileUpload.IUploadService,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory)
        {
            super(Upload, utenteFactory, null, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, null, tracciabilitaFactory);
            var vm = this;
            this.formProdotti = {};

            prodottiFactory.tipiProdotto().then((risultato: TipoProdottoVm[]) => {
                vm.tipi = risultato;
                prodottiFactory.ListaMarche().then(
                    risultato => {
                        vm.marche = risultato;                        
                    }
                );
            });

            prodottiFactory.listaRaggruppamenti().then(
                result => {
                    this.raggruppamenti = result;
                });

            prodottiFactory.listaUnitaMisura().then(
                result => {
                    this.unitaMisura = result;
                });

            $(document).on("imageUpload", (e: JQueryEventObject) => {
                vm.prodotto.Immagini.push(e.image);
                vm.formProdotti.$setDirty();
            }).on("fileUpload", (e: JQueryEventObject) => {
                console.log("FileUpload event", e);
                var item = new ViewModels.ReadExcelItem();
                item.FileName = e.nomeFileUpload;
                item.OriginalName = e.lettura;
                item.Subfolder = e.tipoUpload;
                vm.prodottiFactory.ReadExcel(item).then(
                    result => {
                        if (result === "") {
                            vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_nuovo_da_excel_ok", [e.lettura]));
                            vm.dtFactory.aggiornaRicerca();
                        }
                        else
                            vm.msgFactory.error(result);
                    },
                    reason => {
                    }
                );
            })
        }


        public cacellaCodiceAlternativo(idProdottoAlternativo: number) {
            this.msgFactory.confirm("Stai per rimuovere un Codice Alternativo, vuoi proseguire?", "Rimuovi Codice Alternativo").then(
                result => {
                    if (!result.result)
                        return;
                    console.log("IdProdotto: " + this.prodotto.Id + "idProdottoAlternativo: " + idProdottoAlternativo)
                    this.prodottiFactory.rimuoviProdottoAlternativo(this.prodotto.Id, idProdottoAlternativo).then(
                        result => {
                            console.log("Strumento Cancellato");
                            this.prodottiAlternativi = [];
                            this.aggiornaRicercaCodiciAlternativi();
                        });
                });
        }
        
        public aggiornaRicercaCodiciAlternativi(
            sortColumn: string = 'Codice',
            sortDirection: Trace.ViewModels.DirezioneSort = 1,
            filtro: Array<Trace.ViewModels.RicercaRigaVm> = null,
            tableIndex: number = 0,
            page: number = 1
        ): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();

            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            ricerca.idProdotto = this.prodotto.Id;

            this.currentPage = page;

            this.prodottiFactory.ricercaCodiciAlternativiPaginata(ricerca, page).then((risultato: ProdottiPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                console.log(risultato);
                this.prodottiAlternativi = risultato.List;
            });
        }

        public gestioneCorpoSet(): void {
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Set/modalListaStrumentiRevisione.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public aggiungiStrumentoRevisione(idProdottoAlternativo: number, dataInizioValidita: Date): void {
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Prodotti/modalProdottoAlternativo.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xl"
            };
            this.prdAlternativo = {};
            this.prdAlternativo.idProdotto = idProdottoAlternativo;
            this.$modalInstance2 = this.$uibModal.open(options);
        }

        public caricaDocumentoEsaurimento() {
            alert("Upload");
        }

        public confermaCodiceAlternativo(): void {
            if (this.prdAlternativo.dataInizioValidita == undefined) 
                this.prdAlternativo.dataInizioValidita = new Date();

            this.prodottiFactory.inserisciCodiceAlternativo(this.prodotto.Id, this.prdAlternativo.idProdotto, this.prdAlternativo.dataInizioValidita)
                .then((risultato: boolean) => {
                    if (risultato) {
                        this.msgFactory.alert("Codice Alternativo associato con successo", "Codice Alternativo");
                        this.aggiornaRicercaCodiciAlternativi();
                        this.chiudiModal(2);
                    }
                });
        }
        
        public aggiornaRicercaCorpoSet(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.prodottiFactory.ricerca(ricerca).then((risultato: Trace.Prodotto.ProdottoVm[]) => {
                this.prodotti = risultato;
            });
        }

        public aggiornaRicerca(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>, tableIndex: number, page: number): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            page = page == undefined ? 1 : page;
            tableIndex = tableIndex == undefined ? 0 : tableIndex;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));

            this.currentPage = page;
            this.prodottiFactory.ricercaPaginata(ricerca, page).then((risultato: ProdottiPaginationVm) => {
                var r = risultato;
                this.dtFactory.tables[tableIndex].pagination = <ViewModels.PaginationVm>risultato;
                this.prodotti = risultato.List;
                console.log(this.prodotti);
            });

            if (this.currentState == 'manipola')
                this.aggiornaRicercaCodiciAlternativi(sortColumn, sortDirection, filtro, tableIndex, page);

        }

        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }

        private etichetta: any;

        private formEtichetta: any;

        private stampaEtichetta() {
            if (this.prodotto.IdTipo != 1003) { // 1003 = Materiale di consumo
                var url = appRoot + "Reports/EtichettaGenerica/?tipo=prodotti&id=" + this.prodotto.Id;
                window.open(url, "_blank");
            }
            else {
                this.etichetta = undefined;
                this.formProdotti.$setPristine();
                var vm = this;
                var options: ng.ui.bootstrap.IModalSettings = {
                    templateUrl: '/views/Prodotti/modalGestioneEtichetta.html',
                    scope: this.$scope,
                    windowClass: "modal-styled",
                    size: "md"
                };
                this.gestioneNotCoded = false;
                this.$modalInstance = this.$uibModal.open(options);
                
            }
        }

        private doStampaEtichetta() {
            console.log(this.etichetta);
            var url = appRoot + "Reports/EtichettaGenerica/?tipo=prodotti&id=" + this.prodotto.Id + "&lotto=" + this.etichetta.Lotto + "&scadenza=" + moment(this.etichetta.DataScadenza).format("YYYY-MM-DD");
            window.open(url, "_blank");
            this.chiudiModal();
        }

        public aggiornaComboFornitori() {
            var temp = this.fornitori;
            this.fornitoriFactory.getFornitoriMarche(this.prodotto.IdMarca).then(
                risultato => {
                    this.fornitori = risultato;            
            });
        }

        public checkDetailsId(): void {
            if (productId == undefined) this.changeState('list');
            else this.leggi(productId);
        }

        public resetForm(): void {
            this.ricerca = new RicercaProdotti();
        }

        public resetFormDistinta(): void {
            this.ricercaDistinta = new RicercaProdotti();
            this.dtFactory.aggiornaRicerca("lista-distinta");
        }

        public isInEdit(): boolean {
            var vm = this;
            return vm.currentState == "manipola" && vm.prodotto != undefined && vm.prodotto.Id != undefined;
        }

        public isInCreate(): boolean {
            var vm = this;
            return vm.currentState == "manipola" && vm.prodotto != undefined && vm.prodotto.Id == undefined;
        }

        public backToList(): void {
            this.changeState('list');
        }

        public aggiornaRicercaDistinta(sortColumn: string, sortDirection: Trace.ViewModels.DirezioneSort, filtro: Array<Trace.ViewModels.RicercaRigaVm>): void {
            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm(sortColumn, sortDirection));
            this.prodottiFactory.ricerca(ricerca).then((risultato: ProdottoVm[]) => {
                this.distintaProdotti = risultato;
            });
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                vm.changeState('list');
            });
        }

        public changeState(stato: string): void {
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.prodotto = null;
                    this.dtFactory.aggiornaRicerca("", this.currentPage);
                    break;

                case "manipola":
                    this.formProdotti.$setPristine();
                    this.aggiornaRicercaCodiciAlternativi();
                    break;
            }
        }

        public registra(): void {
            this.showValidationMessages = false;
            if (!this.formProdotti.$valid) {
                this.showValidationMessages = true;
                this.formProdotti.$setDirty();
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return;
            }
            if (this.prodotto.CostoStandard != undefined && this.prodotto.CostoStandard != null && isNaN(this.prodotto.CostoStandard)) {
                this.msgFactory.error(this.localeFactory.get("messaggio_costo_errato"));
                return;
            }
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_salvataggio_conferma"));

            conf.then(result => {
                if (!result.result) return;
                var promise = (this.prodotto.Id == undefined)
                    ? this.prodottiFactory.inserisci(this.prodotto)
                    : this.prodottiFactory.modifica(this.prodotto)

                promise.then(
                    result => {
                        var alertPromise = this.msgFactory.alert(this.localeFactory.get("messaggio_salvataggio_ok"));
                        alertPromise.then(result => { this.changeState("list"); });
                    },
                    reason => {
                        this.msgFactory.error(this.localeFactory.getFormatted("messaggio_salvataggio_errore", [reason.toString()]));
                    });
            });
        }

        public nuovo(): void {
            this.titoloManipola = this.localeFactory.get("titolo_nuovo");
            this.prodotto = new ProdottoVm();
            this.aggiornaComboFornitori();
            this.prodotto.FlgMarcatura = true;
            this.prodotto.Immagini = new Array<Trace.ViewModels.ImmagineVm>();            
            this.changeState("manipola");
        }

        public leggi(id: number): void {
            this.titoloManipola = this.localeFactory.get("titolo_modifica");
            this.prodottiFactory.leggi(id).then((risultato: ProdottoVm) => {
                console.log(risultato);
                this.prodotto = risultato;
                this.changeState('manipola');
                this.aggiornaComboFornitori();
            });

        }

        public cancella(id: number): void {
            var vm = this;
            this.prodottiFactory.inUso(id).then(
                // OK
                risultato => {
                    if (risultato) {
                        vm.msgFactory.alert(vm.localeFactory.get("messaggio_in_uso"));
                        return;
                    }
                    this.msgFactory.confirm(vm.localeFactory.get("messaggio_cancellazione_conferma")).then(
                        risultato => {
                            if (!risultato.result) return;
                            vm.prodottiFactory.cancella(id).then(
                                // OK
                                result => {
                                    vm.msgFactory.alert(vm.localeFactory.get("messaggio_cancellazione_ok")).then(result => {
                                        vm.changeState("list");
                                        vm.dtFactory.aggiornaRicerca();
                                    });
                                },
                                // ERRORE
                                reason => {
                                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_cancellazione_errore", [errore]));
                                }
                            );
                        }
                    );
                },
                // ERRORE
                reason => {
                    var errore: string = reason == undefined ? this.localeFactory.get("nessun_dettaglio") : reason;
                    vm.msgFactory.alert(vm.localeFactory.getFormatted("messaggio_controllo_errore", [errore]));
                }
            );
        }

        public cancellaDistinta(codice: string): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_cancellazione_conferma"));
            conf.then(result => {
                if (!result.result) return;
                $.each(this.prodotto.Distinta, (index: number, item: DistintaVm) => {
                    if (item.Codice === codice) {
                        vm.prodotto.Distinta.splice(index, 1);
                    }
                });
                vm.formProdotti.$setDirty();
            });
        }

        //public onFileSelect($file: File) {
        //    // $files: an array of files selected, each file has name, size, and type.
        //    //for (var i = 0; i < $files.length; i++) {
        //    var vm = this;
        //    var file = $file;
        //    this.Upload.upload({
        //        url: appRoot + "api/Upload",
        //        method: "POST",
        //        data: { tipo: "prodotto" },
        //        file: file
        //    })
        //        .abort()
        //        .xhr((evt: any) => {
        //            console.log('xhr');
        //        })
        //        .progress((evt: angular.angularFileUpload.IFileProgressEvent) => {
        //            var percent = parseInt((100.0 * evt.loaded / evt.total).toString(), 10);
        //            console.log("upload progress: " + percent + "% for " + evt.config.file.name);
        //        })
        //        //.error((data: any, status: number, response: any, headers: any) => {
        //        //    console.error(data, status, response, headers);
        //        //    this.msgFactory.alert("E' avvenuto un errore in fase di upload del file: " + data);
        //        //})
        //        .success((data: Trace.ViewModels.UploadResultVm, status: number, headers: any, config: angular.angularFileUpload.IFileUploadConfigFile) => {
        //            var img = new Trace.ViewModels.ImmagineVm();
        //            img.thumb = appRoot + data.Url;
        //            img.img = appRoot + data.Url;
        //            img.description = data.Name;
        //            img.baseUrl = data.Url;

        //            vm.prodotto.Immagini.push(img);
        //            vm.formProdotti.$setDirty();
        //        });
        //    //}
        //}

        public gestioneDistinta(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Prodotti/modalDistinta.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxl"
            };
            this.gestioneNotCoded = false;
            this.$modalInstance = this.$uibModal.open(options);
        }

        public addNotCoded(show: boolean): void {
            this.gestioneNotCoded = show;
            if(show)
                this.notCoded = new DistintaVm();
        }

        public aggiungiDistintaNotCoded(d: DistintaVm): void {
            var presente: boolean = false;
            if (this.prodotto.Distinta != undefined) {
                $.each(this.prodotto.Distinta, (index: number, item: DistintaVm) => {
                    if (item.Codice === d.Codice) {
                        presente = true;
                        item.Qta += d.Qta;
                        return;
                    }
                });
            }
            else {
                this.prodotto.Distinta = new Array<DistintaVm>();
            }

            if (presente) return;
            if (this.prodotto.Id != undefined)
                d.IdProdottoPadre = this.prodotto.Id;
            this.prodotto.Distinta.push(d);
            this.gestioneNotCoded = false;
            this.formProdotti.$setDirty();
        }

        public aggiungiDistinta(prodotto: ProdottoVm): void {
            var presente: boolean = false;
            if (this.prodotto.Distinta != undefined) {
                $.each(this.prodotto.Distinta, (index: number, item: DistintaVm) => {
                    if (item.Codice === prodotto.Codice) {
                        presente = true;
                        item.Qta += 1;

                        return;
                    }
                });
            }
            else {
                this.prodotto.Distinta = new Array<DistintaVm>();
            }

            if (presente) return;
            var d = new DistintaVm();
            d.Codice = prodotto.Codice;
            d.DescrizioneAl = prodotto.Descrizione_sq_AL;
            d.DescrizioneEn = prodotto.Descrizione_en_US;
            d.DescrizioneIt = prodotto.Descrizione_it_IT;
            d.IdProdotto = prodotto.Id;
            d.Qta = 1;
            if (this.prodotto.Id != undefined)
                d.IdProdottoPadre = this.prodotto.Id;
            this.prodotto.Distinta.push(d);
            this.formProdotti.$setDirty();
        }

        public chiudiModal(instance: number = undefined): void {
            if (!instance) {
                this.$modalInstance.close();
                this.$modalInstance.dismiss('cancel');
            }
            else if (instance == 1) {
                this.$modalInstance.close();
                this.$modalInstance.dismiss('cancel');
            }
            else if (instance == 2) {
                this.$modalInstance2.close();
                this.$modalInstance2.dismiss('cancel');
            }
        }
    }
}
app.controller("prodottiController", Trace.Prodotto.prodottiController);