module Trace.Analisi {
    export class analisipresenzeController {
        private filtro: FiltroAnalisiPresenze; 
        private graficoShow: boolean;
        private operatori: Trace.Helpers.SimpleDropdown[];
        private risultatoRicerca: Trace.Analisi.GraficiPresenzeOperatoriVm;
        private isLoading: boolean;
        private FlgTuttiOperatori: boolean;

        // vm.localeFactory.get('seleziona')

        static $inject = ['$scope', 'utenteFactory', 'produttivitaFactory', 'localeFactory', 'msgFactory'];
        constructor(
            private $scope: ng.IScope,
            private utentiFactory: Utente.utenteFactory,
            private produttivitaFactory: Analisi.produttivitaFactory,
            private localeFactory: Localization.localeFactory,
            private msgFactory: Messages.msgFactory            
        ) {
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 250);

            this.FlgTuttiOperatori = false;
            this.risultatoRicerca = <Trace.Analisi.GraficiPresenzeOperatoriVm>{};
            this.filtro = new FiltroAnalisiPresenze();            
            this.setUpWatchers();
        }

        public ricerca(): void {
            var operatoriSelezionati = this.operatori.filter(h => h.Selezionato == true);
            //if (operatoriSelezionati == undefined || operatoriSelezionati.length == 0) {
            //    this.msgFactory.alert("Seleziona almeno una centrale prima di procedere alla ricerca");
            //    return;
            //}
            this.filtro.IdUtente = operatoriSelezionati.map(function (a) { return a.Id });
            this.isLoading = true;
            this.graficoShow = true;
            //this.graficoShow = this.filtro.IdFaseProduzione != undefined ? true : false;
            this.produttivitaFactory.RicercaAnalisiPresenze(this.filtro).then(
                result => {
                    this.risultatoRicerca = result;
                    this.isLoading = false;
                    //this.updateLottiLavorati1();
                    //this.updateFasiLottiLavorati2();
                    //if (this.ricercaPerFase) this.updateFasiLottiLavorati3();

                    if (this.risultatoRicerca != null && this.risultatoRicerca.IstogrammaConfronto != null && this.risultatoRicerca.IstogrammaConfronto.length > 0) {
                        //this.updateFasiLottiLavorati4();
                        //this.updateFasiLottiLavorati5();
                        this.updateIstoConfrontoTempoOp();
                    }
                    else {
                        this.graficoShow = false;
                        this.msgFactory.alert(this.localeFactory.get("nessun_risultato_ricerca"));
                    }
                }
            );
        }

        private updateIstoConfrontoTempoOp() {
            if (this.risultatoRicerca == undefined) return;

            let datiChart = [
                {
                    name: 'Sessione Trace',
                    data: this.risultatoRicerca.IstogrammaConfronto.map(r => r.TempoSuperSessione)
                },
                {
                    name: 'Timbratura elettronica',
                    data: this.risultatoRicerca.IstogrammaConfronto.map(r => r.TempoTimeMoto)
                }
            ];

            let chartOption = {
                chart: {
                    type: 'column'
                },
                title: {
                    text: null
                },
                xAxis: [{
                    categories: this.risultatoRicerca.IstogrammaConfronto.map(r => r.LabelOperatore),
                    crosshair: true
                }],
                yAxis: {
                    title: {
                        text: 'Durata (hh:mm)',
                    },
                    labels: {
                        formatter: function () {
                            //let tmp = this.value / 60000;
                            //let h = Math.floor(tmp / 60);
                            //let m = Math.floor(tmp) % 60;
                            let h = Math.floor(this.value / 60);
                            let m = this.value % 60;
                            //if (m != 0) return null;
                            return h + ":" + m;
                        }

                    },
                },
                tooltip: {
                    pointFormatter: function () {
                        //let tmp = this.y / 60000;
                        //let h = Math.floor(tmp / 60);
                        //let m = Math.floor(tmp) % 60;

                        let h = Math.floor(this.y / 60);
                        let m = this.y % 60;
                        //return '<tr><td style="color:' + this.series.color + ';padding:0">' + this.series.name + ': </td>' +
                        //    '<td style="padding:0"><b>' + h.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + m.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '</b></td></tr>';
                        return '<span style="color:' + this.series.color + '">\u25CF</span> ' + this.series.name + ': <b>' + h.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + ':' + m.toLocaleString(undefined, { minimumIntegerDigits: 2 }) + '</b><br/>';
                    },
                    //headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    //pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    //'<td style="padding:0"><b>{point.y}</b></td></tr>',
                    //footerFormat: '</table>',
                    shared: true,
                    //useHTML: true
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series: datiChart
            };

            $('#my-chart-1-container').highcharts(chartOption);
        }

        public init() {    
            //alert("porco zeus!!");                
            this.getOperatori();        
        }  

        public selezionaTuttiOperatori() {
            if (this.FlgTuttiOperatori == true) {
                if (this.operatori != null && this.operatori.length > 0) $.each(this.operatori, function (index, value) { value.Selezionato = true; });
            }
            else {
                if (this.operatori != null && this.operatori.length > 0) $.each(this.operatori, function (index, value) { value.Selezionato = false; });
            }
        }
       
        private getOperatori(): void {
            var vm = this;
            var dropDownOption = {
                //data: json1.data,
                //limitCount: 40,
                multipleMode: 'label',
                choice: function (a, b) {
                    //console.log(arguments, this);
                    //vm.dropDownPristine = false;
                    var item = vm.operatori.filter(h => h.Id == b.id)[0];
                    item.Selezionato = b.selected == true ? true : false;
                    //vm.$scope.$apply();
                    //vm.formFornitori.$commitViewValue();
                },
                limitCount: Infinity,
                input: '<input type="text" id="cerca-marca" maxLength="20" style="font-size:12px" placeholder="' + "Cerca.."/*vm.localeFactory.get("campo_ricerca")*/ + '">',
                searchable: true,
                searchNoData: '<li style="color:#CCC">No Results</li>',

            };
            this.utentiFactory.OperatoriCentrale().then(
                result => {
                    this.operatori = result.map(function (a) { return <Trace.Helpers.SimpleDropdown>{ Id: a.Id, Descrizione: a.Descrizione, Selezionato: false }; });
                    setTimeout(
                        function () {
                            $('#selezioneOperatori').dropdown(dropDownOption);
                        }, 250);
                }
            );
        }        

        private setUpWatchers(): void {
           
        }

        public dateChanged(property: string, item: any): void {
            var data = moment(item["Str" + property], "DD/MM/YYYY").format("YYYY-MM-DDT00:00:00");
            item[property] = data;
        }            
    }    
}
app.controller("analisipresenzeController", Trace.Analisi.analisipresenzeController);