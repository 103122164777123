namespace Trace {
    export class DataRead {
        public Data: string;
        public IdLotto: number;
        constructor(lettura: string, idLotto?: number) {
            this.Data = lettura;
            this.IdLotto = idLotto;
        }
    }

    export class epProduzioneController extends epBaseController {
        public azioni: Array<Produzione.AzioneVm>;
        public azioniBox: Array<Produzione.AzioneVm>;
        public azioniStrumenti: Array<Produzione.AzioneVm>;
        public idLottoSelezionato: number;
        public idBoxSelezionato: number;
        public idContenutoSelezionato: number;
        public lista: Array<Produzione.LottoProduzioneVm>;
        public listaBox: Array<Produzione.BoxVm>;
        public box: Produzione.BoxVm;
        public lotto: Produzione.LottoProduzioneVm;
        public lottoDrag: Produzione.LottoProduzioneVm;
        public grigliaDrag: Produzione.GrigliaVm;
        public formDettagliProduzione: any;
        public trattamentiGriglie: Array<Produzione.InfoTrattamentoGriglia>;
        public trattStrumenti: Array<Produzione.TrattamentoVm>;
        public trattamentiSet: Array<Produzione.InfoTrattamento>;
        public trattamentiStrumenti: Array<Produzione.InfoTrattamentoStrumento>;
        public trattamenti: Array<Produzione.Trattamento>;
        public idStrumentoSelezionato: number;
        public strumentoSelezionato: Set.StrumentoVm;
        public idMacchinaSelezionata: number;
        public macchine: Array<Produzione.Macchina>;
        public dragularOptions: any;
        public associazioneLottiSterilizzazione: boolean;
        public griglie: Array<Produzione.GrigliaVm>;
        public strumentiMagazzini: Array<Set.StrumentoVm>;
        public strumentiReintegrati: Array<Set.StrumentoVm>;
        public primoCaricamento: boolean;
        public isDragging: boolean;
        public contaStrumenti: Produzione.IContaStrumentiItem;
        public strumentiDb: Array<Prodotto.ProdottoVm>;
        public strumentiSfusi: Array<Prodotto.StrumentoSfuso>;
        public strumentoSfuso: Prodotto.StrumentoSfuso;
        public modelliSfusoCompatibili: Array<Helpers.SimpleEntity>;
        public flgModelloSfuso: string;

        constructor(
            public $scope: ng.IScope,
            public $rootScope: ng.IRootScopeService,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public localeFactory: Localization.localeFactory,
            public msgFactory: Messages.msgFactory,
            public setFactory: Set.setFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            public tntFactory: Tnt.tntFactory,
            public produzioneFactory: Produzione.produzioneFactory,
            public idFase: number,
            public descrFase: string,
            public $document: any,
            public Upload: ng.angularFileUpload.IUploadService,
            public utenteFactory: Utente.utenteFactory,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        )
        {
            super(Upload, utenteFactory, setFactory, prodottiFactory, tntFactory, localeFactory, msgFactory, $uibModal, $rootScope, tracciabilitaFactory);
            this.primoCaricamento = true;
            this.currentState = "list";
            this.azioni = new Array<Produzione.AzioneVm>();
            this.azioniBox = new Array<Produzione.AzioneVm>();
            this.azioniStrumenti = new Array<Produzione.AzioneVm>();
            this.showValidationMessages = false;
            this.formDettagliProduzione = {};
            this.produzioneFactory = produzioneFactory;
            this.associazioneLottiSterilizzazione = false;
            $(document).off("barcode").on("barcode", (e: JQueryEventObject) => {
                this.barcodeEventHandler(e);
            }).on("selectMacchina", (e: JQueryEventObject) => {
                this.selectMacchinaHandler(e);
            });
            $(document).on("listRowAction", (e: JQueryEventObject) => {
                if (e.lotto == undefined) return;
                var f = e.funzione;
                var vm = this;
                eval("vm." + f + "(e.lotto)");
            }).on("listRowBoxAction", (e: JQueryEventObject) => {
                if (e.box == undefined) return;
                var f = e.funzione;
                var vm = this;
                eval("vm." + f + "(e.box)");
            }).on("cicloManuale", (e: JQueryEventObject) => {
                this.cicloManuale(e.macchina);
            }).on("lavoraSet", (e: JQueryEventObject) => {
                this.produzioneFactory.CambiaFaseLottoProduzione(parseInt(e.lettura.toString()), this.idFase).then(
                    result => {
                        this.produzioneFactory.ListaFase(this.idFase).then(
                            result => {
                                this.lista = result;
                                //console.log("this.lista = result " , result);
                            }
                        );
                    },
                    reason => {

                    }
                );
            });
        }

        public listaFaseBox(idFase: number): void {
            this.produzioneFactory.ListaFaseByBox(idFase).then(
                result => {
                    this.listaBox = result;
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.get("errore_non_gestito"));
                    console.log("errore lettura box per la fase", idFase, reason);
                }
            )
        }

        public anomaliaStrumenti(tipo: string): boolean {
            if (this.contaStrumenti == undefined) return false;
            var a = this.contaStrumenti.ContaAttuale;
            var p = this.contaStrumenti.ContaPrecedente;

            return (tipo === 'm') ? a < p : a > p;
        }

        public alertAnomaliaStrumenti(tipo: string): string {
            if (this.contaStrumenti == undefined) return "";
            var diff = Math.abs(this.contaStrumenti.ContaAttuale - this.contaStrumenti.ContaPrecedente);
            return (tipo === 'm')
                ? this.localeFactory.getFormatted("anomalia_conta_manca", [diff, this.contaStrumenti.OperatoreConta, this.contaStrumenti.ContaAttuale, this.contaStrumenti.ContaPrecedente])
                : this.localeFactory.getFormatted("anomalia_conta_eccesso", [diff, this.contaStrumenti.OperatoreConta, this.contaStrumenti.ContaAttuale, this.contaStrumenti.ContaPrecedente]);
        }

        public cambiaAltezzaMacchine(analisi: boolean = false): void {
            var selettore = (analisi === true) ? "div.content-analisi" : "div.content";
            //console.log("selettore", selettore);
            if ($(selettore + " .macchina-content").offset() == undefined) return;
            var top = $(selettore + " .macchina-content").offset().top;
            var bottom = $("footer").offset().top;
            var altezzaMacchine = bottom - top - 10;

            $(".macchine-container").css("padding-top", "20px");
            $("body").css("overflow", "hidden");
            $(selettore + " .macchina-content").height(altezzaMacchine);
            $(selettore + " .macchina").css("min-height", altezzaMacchine);
            $(selettore + " div.macchina > div.body").height(altezzaMacchine - 84);
            $("div.layout-sidebar").height(altezzaMacchine - 2);
            $("div.layout-main-left .layout-main").height(altezzaMacchine + 55);
        }

        private cicloManuale(macchina: Produzione.Macchina): void {
            var vm = this;
            var buttons = new Array();
            var b = new Trace.Messages.ModalButton();
            var b2 = new Trace.Messages.ModalButton();
            b.boolResult = true;
            b.buttonBinding = Trace.Messages.ModalResultButton.CONFIRM;
            b.label = this.localeFactory.get("conferma");
            b.style = "success";
            b.objectResult = '$("form#form-cicman input#cicMan").val()';
            buttons.push(b);
            b2.boolResult = false;
            b2.buttonBinding = Trace.Messages.ModalResultButton.CANCEL;
            b2.label = this.localeFactory.get("annulla");
            b2.style = "danger";
            buttons.push(b2);
            var values = [new ViewModels.DictionaryItem("#form-cicman #cicMan", macchina.NumCiclo)];
            this.msgFactory.showModal(this.localeFactory.get("label_num_ciclo"), this.localeFactory.getFormatted("set_num_ciclo_macchina", [macchina.Nome]), "cicloManuale.html", "md", buttons, true, undefined, values).then(
                result => {
                    if (!result.result) return;
                    var numCiclo: number = parseInt(result.object);
                    //if (numCiclo <= macchina.NumCiclo) {
                    //    vm.msgFactory.error(vm.localeFactory.getFormatted('ciclo_manuale_error_minore', [numCiclo, macchina.NumCiclo]));
                    //    macchina.FlgManuale = false;
                    //    return;
                    //}
                    macchina.NumCiclo = numCiclo;
                    macchina.FlgManuale = true;
                }
            );
        }

        public notePrecauzioniStrumento(strumento: Set.StrumentoVm): void {
            var vm = this;
            vm.strumentoSelezionato = strumento;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Produzione/modalNotePrecauzioni.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "lg",
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public listaCarichi(idTipoMacchina: number, idMacchina?: number): void {
            this.produzioneFactory.ListaCarichi(idTipoMacchina, idMacchina).then(
                result => {
                    this.attribuisciCaricoMacchine(result);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public attribuisciCaricoMacchine(lista: Produzione.CaricoMacchina[]) {
            if (this.macchine == undefined) return;
            $.each(this.macchine, (index, item) => {
                item.Carico = undefined;
                if (lista != undefined && lista.length > 0) {
                    var filtered = $.grep(lista, (c, id) => {
                        return c.IdMacchina === item.Id;
                    });
                    this.attribuisciCaricoMacchina(filtered, item);
                }
            });
        }

        private attribuisciCaricoMacchina(filtered: Produzione.CaricoMacchina[], item: Produzione.Macchina): void {
            var carico = new Array<Produzione.LottoProduzioneVm>();
            //console.log(filtered);
            $.each(filtered, (fid, f) => {
                carico.push(f.LottoProduzione);
            });
            item.Carico = filtered;
        }

        public listaTrattamenti(): void {
            this.produzioneFactory.Trattamenti().then(
                result => {
                    this.trattamenti = result;
                }
            );
        }

        public spaziDaGriglie(): string {
            if (this.lotto == undefined || this.griglie == undefined) return "12";
            var result = Math.floor(12 / this.griglie.length);
            return result.toString();
        }

        public trattamentiGriglia(idTrattamento: number, label: string, funzione: string, classe: string): Array<Produzione.TrattamentoVm> {
            if (this.lotto == undefined || this.griglie == undefined) return null;
            var ng: number = 0;
            var result = new Array<Produzione.TrattamentoVm>();

            $.each(this.griglie, (index, item) => {
                var gr = item.NumGriglia.toString();
                var msg = this.localeFactory.getFormatted(label, [gr]);
                var a = new Produzione.TrattamentoVm(item.NumGriglia, idTrattamento, msg, funzione, classe);
                a.idGriglia = item.Id;
                a.spazioGriglia = this.spaziDaGriglie();
                result.push(a);
            });
            return result;
        }

        public anomalieStrumento(): Array<Produzione.AnomaliaStrumentoVm> {
            if (this.lotto == undefined) return null;
            var ng: number = 0;
            var result = new Array<Produzione.AnomaliaStrumentoVm>();

            $.each(this.lotto.Strumenti, (index, item) => {
                var g: Produzione.AnomaliaStrumentoVm = new Produzione.AnomaliaStrumentoVm();
                g.Id = item.Id;
                g.QtaMax = item.Qta;
                result.push(g);
            });
            return result;
        }

        public descrizioneLotto(lotto?: Produzione.LottoProduzioneVm): string {
            //console.log("descrizioneLotto " + lotto);
            if (this.lotto == undefined && lotto == undefined)
                return "";
            if (lotto == undefined)
                lotto = this.lotto;

            //console.log("dbCulture" + dbCulture);
            return lotto["Descrizione_" + dbCulture];
        }

        public getContaStrumenti() {
            if (this.lotto == undefined) {
                this.contaStrumenti = undefined;
                return;
            }
            var vm = this;
            this.produzioneFactory.ContaStrumenti(this.lotto.Id).then(
                result => {
                    vm.contaStrumenti = result;
                },
                reason => {
                }
            );
        }

        public descrizioneBox(): string {
            if (this.box == undefined) return "";
            var descr = this.localeFactory.getFormatted("label_box", [this.box.Descrizione, this.formatDate(this.box.DataCreazione)]);
            return descr;
        }

        public changeState(stato: string): void {
            var vm = this;
            this.currentState = stato;
            switch (this.currentState) {
                case "list":
                    this.lotto = null;
                    this.box = null;
                    break;

                case "manipola":
                    this.formDettagliProduzione.$setPristine();
                    this.trattamentiSet = new Array<Produzione.InfoTrattamento>();
                    this.trattamentiGriglie = new Array<Produzione.InfoTrattamentoGriglia>();
                    this.trattamentiStrumenti = new Array<Produzione.InfoTrattamentoStrumento>();
                    break;
                case "manipolaBox":
                    this.formDettagliProduzione.$setDirty();
                    this.trattamentiSet = new Array<Produzione.InfoTrattamento>();
                    this.trattamentiGriglie = new Array<Produzione.InfoTrattamentoGriglia>();
                    this.trattamentiStrumenti = new Array<Produzione.InfoTrattamentoStrumento>();
                    break;
            }
            setTimeout(function () {
                $(".datetime").datepicker();
            }, 500);
        }

        public barcodeEventHandler(e: JQueryEventObject): void {
            var checkResult = this.checkBarcodeText(e.lettura);
            console.log("barcodeEventHandler", checkResult);
            if (checkResult == TipoBarcode.NONE) return;
            this.ultimaLetturaBarcode = new Produzione.LetturaBarcode(e.lettura, checkResult);
            if (this.grigliaDrag != undefined)
                this.ultimaLetturaBarcode.idGriglia = this.grigliaDrag.Id;
            if (checkResult >= Globals.LimiteInferioreBarcodeSet && checkResult <= Globals.LimiteSuperioreBarcodeSet) {
                if (checkResult == TipoBarcode.GRIGLIA1 ||
                    checkResult == TipoBarcode.GRIGLIA2 ||
                    checkResult == TipoBarcode.GRIGLIA3) {
                    console.log("codice della griglia", e.lettura);
                    var griglia = this.decodeGriglia(e.lettura, checkResult);
                    if (griglia != undefined) {
                        triggerGrigliaStrumentiEvent("barcodeGrigliaOk", griglia);
                        return;
                    }
                    var carico = this.findGrigliaInLottiCaricati(e.lettura);
                    if (carico == undefined) {
                        this.msgFactory.error(this.localeFactory.getFormatted('griglia_non_trovata', [e.lettura]));
                        return;
                    }
                    triggerGrigliaStrumentiEvent("barcodeGrigliaCaricataOk", carico);
                    return;
                }
                else if (this.idFase == 4)
                    triggerGrigliaStrumentiEvent("barcodeOk", this.ultimaLetturaBarcode.barcode);
            }
            if (checkResult == TipoBarcode.GRIGLIASFUSA) {
                triggerEvent("grigliaSfusa", e.lettura);
                return;
            }
            if (checkResult == TipoBarcode.EAN) {
                var ean = this.decodeEan(e.lettura.substr(3), e.fittizio);
                triggerEanEvent("eanCode", ean);
                return;
            }
            if (checkResult == TipoBarcode.AUTOCLAVE ||
                checkResult == TipoBarcode.TERMODISINFETTORE) {
                triggerEvent("selectMacchina", e.lettura);
                return;
            }
            if (checkResult == TipoBarcode.PRODOTTO) {
                triggerEvent("barcodeOk", this.ultimaLetturaBarcode.testo);
                return;
            }
            if (checkResult == TipoBarcode.CESTA) {
                triggerEvent("cesta", e.lettura);
                return;
            }
            if (checkResult == TipoBarcode.TNT_LOTTO) {
                this.decodeTntLotto(e.lettura);
                return;
            }
            triggerEvent("barcodeOk", this.ultimaLetturaBarcode.testo, e.idLotto);
        }

        public grigliaSfusaEventHandler(e: JQueryEventObject): void {
            var griglia = this.decodeGriglia(e.lettura, 0);
            if (griglia != undefined)
                triggerGrigliaStrumentiEvent("barcodeGrigliaOk", griglia);
        }

        public barcodeGrigliaOkEventHandler(e: JQueryEventObject): void {
            var result = this.findInList(e.griglieStrumenti[0].CodiceLotto);
            if (result) {
                this.$scope.$apply();
                var id = "row-" + this.idLottoSelezionato.toString();
                this.scrollTo(this.$document, id);
                return;
            }
            this.msgFactory.alert(this.localeFactory.getFormatted("no_set_lista", [e.lettura, this.descrFase]));
        }

        public barcodeOkEventHandler(e: JQueryEventObject): void {
            console.log("barcodeOk"); 
            var result = this.findInList(e.lettura);
            if (result) {
                this.$scope.$apply();
                var id = "row-" + this.idLottoSelezionato.toString();
                this.scrollTo(this.$document, id);
                return;
            } 
            this.msgFactory.alert(this.localeFactory.getFormatted("no_set_lista", [e.lettura, this.descrFase]));
        }

        public barcodeReaderGotFocus(): void {
            this.myRoot.barcodeReaderActive = true;
        }

        public barcodeReaderLostFocus(): void {
            this.myRoot.barcodeReaderActive = false;
        }

        public attivaLettoreBarcode(): void {
            attivaLettoreBarcode();
        }

        public findInList(lettura: string): boolean {
            //console.log("epProduzione findInList " + lettura);
            var vm = this;
            var result: boolean = false;
            if (lettura == undefined) return false;

            if (this.lista == undefined) {
                console.log("findInList(); lista undefiend, probabilmente mancano le fasi");
            }

            $.each(this.lista, (index, item) => {
                if (item.IdTipoSet == 4 || item.IdTipoSet == 9) {
                    //console.log("findInList", item.Codice, lettura);
                    if (item.Codice === lettura) {
                        vm.idLottoSelezionato = index;
                        result = true;
                        return;
                    }
                }
                else {
                    //console.log("findInList", item.Codice, lettura.substr(0, item.Codice.length));
                    if (item.Codice === lettura.substr(0, item.Codice.length)) {
                        vm.idLottoSelezionato = index;
                        result = true;
                        return;
                    }
                }
            });

            return result;
        }

        public isLottoSfuso(): boolean {
            if (this.lotto == undefined) return false;
            return (this.lotto.IdTipoSet === TipoSet.SFUSO || this.lotto.IdTipoSet === TipoSet.SFUSO_IN_BUSTA || this.lotto.IdTipoSet == TipoSet.SFUSO_BASSA_TEMP);
        }

        public isLottoTransito(): boolean {
            if (this.lotto == undefined) return false;
            return (this.lotto.IdTipoSet === TipoSet.IN_TRANSITO);
        }

        public findInListByCodiceGriglia(lettura: string): Produzione.GrigliaStrumentiVm[] {
            var vm = this;
            var result: Produzione.GrigliaStrumentiVm[] = new Array<Produzione.GrigliaStrumentiVm>();

            $.each(this.lista, (index, item) => {
                $.each(item.Griglie, (idg, g) => {
                    //console.log("findInListByCodiceGriglia", g.Codice, lettura.substr(0, item.Codice.length));
                    if (g.Codice === lettura.substr(0, item.Codice.length)) {
                        vm.idLottoSelezionato = index;
                        result.push(g);
                        return;
                    }
                });
            });
            console.log("findInListByCodiceGriglia", result);
            return result;
        }

        public findInLottiCaricati(lettura: string): Array<any> {
            var vm = this;
            var result: Array<any>;

            $.each(this.macchine, (index, item) => {
                if (item.Carico != undefined) {
                    $.each(item.Carico, (id, c) => {
                        if (this.idFase === 4)
                            console.log("controllo se l'elemento è caricato 4", c, c.Codice, lettura)
                        else
                            console.log("controllo se l'elemento è caricato no4", c.LottoProduzione.Codice, lettura)

                        var trovato = (this.idFase === 4)
                            ? c.Codice.substr(0, lettura.length) === lettura
                            : c.LottoProduzione.Codice.substr(0, lettura.length) === lettura;
                        console.log("trovato?", trovato);
                        if (trovato) {
                            item.IdLottoSelezionato = id;
                            result = new Array<any>();
                            // index della macchina
                            result.push(index);
                            // index della riga di carico
                            result.push(id);
                            result.push(this.descrizioneLotto(c.LottoProduzione));
                            result.push(c.NumGriglia);
                            return;
                        }
                    });
                }
            });
            console.log("risultato ricerca", result);
            return result;
        }

        public Dettagli(lotto: Produzione.LottoProduzioneVm): void {
            this.produzioneFactory.Dettagli(lotto.Id, this.idFase).then(
                result => {
                    this.lotto = result;
                    console.log("Dettagli lotto", lotto.Id.toString(), lotto);
                    this.setFactory.leggi(lotto.IdSet).then(
                        risultato => {
                            this.set = risultato;
                            this.produzioneFactory.GetImmagini(lotto.Id).then(
                                risultato => {
                                    this.set.Immagini = this.set.Immagini.concat(risultato);
                                }
                            );
                            this.cambiaAltezzaListaProdotti();
                        }
                    );
                    this.changeState("manipola");
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public DettagliBox(box: Produzione.BoxVm): void {
            
            this.produzioneFactory.DettagliBox(box.Id).then(
                result => {
                    this.box = result;
                    this.cambiaAltezzaListaProdotti();
                    this.griglie = this.box.Griglie;
                    this.changeState("manipolaBox");
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("errore_non_gestito_dettagli", [reason.data]));
                    console.log("errore", reason);
                });
        }

        public cambiaAltezzaListaProdotti(): void {
            if (this.idFase == 10)
                $(".lista-strumenti-content > .lista-strumenti").height(345);
            else
                $(".lista-strumenti-content > .lista-strumenti").height($(".lista-strumenti-content > .info-generali").outerHeight() + 180);
        }

        public lottoSelezionato(lista: Array<Produzione.LottoProduzioneVm>): Produzione.LottoProduzioneVm {
            var vm = this;
            if (vm.idLottoSelezionato == undefined) return null;
            return lista[vm.idLottoSelezionato];
        }

        public macchinaSelezionata(): Produzione.Macchina {
            var vm = this;
            if (vm.macchine == undefined || vm.idMacchinaSelezionata == undefined) return null;
            return vm.macchine[vm.idMacchinaSelezionata];
        }

        public infoContainer(): string {
            if (this.lotto == undefined) return "";
            return this.localeFactory.getFormatted('container_associato', [this.lotto.NumContainer, this.lotto.TotContainer]);
        }

        public linkModello(): string {
            if (this.lotto == undefined || this.lotto.IdModelloSet == undefined) return "";
            return appRoot + "ModelliSet/Index/" + this.lotto.IdModelloSet;
        }

        public descrizioneModelloRiferimento(): string {
            if (this.lotto == undefined) return "";
            return this.lotto.DescrModelloSet;
        }

        public complessita(): string {
            if (this.lotto == undefined) return "";
            return this.lotto.DescrComplessita == undefined ? this.localeFactory.get("no_complessita") : this.lotto.DescrComplessita;
        }

        public alertUrgenza(): string {
            if (this.lotto == undefined) return "";
            var dataUrgenza = this.formatDate(this.lotto.DataUrgenza, 'HH:mm')
            var msg = this.localeFactory.getFormatted("set_urgente_per", [dataUrgenza]);
            return msg;
        }

        public annulla(): void {
            var vm = this;
            var conf = this.msgFactory.confirm(this.localeFactory.get("messaggio_annullamento"));
            conf.then(result => {
                if (!result.result) return;
                this.backToList();
            });
        }

        public initTrattamenti(): void {
            this.trattamentiSet = new Array<Produzione.InfoTrattamento>();
            this.trattamentiGriglie = new Array<Produzione.InfoTrattamentoGriglia>();
            this.trattamentiStrumenti = new Array<Produzione.InfoTrattamentoStrumento>();
        }

        public backToList(): void {
            this.changeState('list');
        }

        public faseSuccessiva(box: boolean = false): void {
            var vm = this;
            this.isSaving = true;

            if (box) {
                this.produzioneFactory.CambiaFaseBox(vm.box.Id).then(
                    result => {
                        var lotto = vm.box.Descrizione;
                        if (result.Fase == undefined || result.Fase == "") result.Fase = "successiva";
                        vm.msgFactory.alert(vm.localeFactory.getFormatted("avanzamento_set_ok", [lotto, result.Fase])).then(
                            result => {
                                vm.backToList();
                                vm.isSaving = false;
                            }
                        );
                    },
                    reason => {
                        vm.msgFactory.error(vm.localeFactory.getFormatted("avanzamento_set_errore", [reason.data]));
                        console.log("errore", reason);
                        vm.isSaving = false;
                    },
                    notify => {

                    }
                );
            }
            else {
                this.produzioneFactory.CambiaFaseLottoProduzione(vm.lotto.Id).then(
                    result => {
                        var lotto = vm.descrizioneLotto();
                        if (result.Fase == undefined || result.Fase == "") result.Fase = "successiva";
                        vm.msgFactory.alert(vm.localeFactory.getFormatted("avanzamento_set_ok", [lotto, result.Fase])).then(
                            result => {
                                vm.backToList();
                                vm.isSaving = false;
                            }
                        );
                    },
                    reason => {
                        vm.msgFactory.error(vm.localeFactory.getFormatted("avanzamento_set_errore", [reason.data]));
                        console.log("errore", reason);
                        vm.isSaving = false;
                    },
                    notify => {

                    }
                );
            }
        }

        public setUrgenza(item: Produzione.LottoProduzioneVm): void {
            this.produzioneFactory.SetUrgenza(item.Id).then(
                result => {
                    triggerEvent("urgenzaSet", undefined);
                },
                reason => {
                    this.msgFactory.error(this.localeFactory.getFormatted("avanzamento_set_errore", [reason.data]));
                    console.log("errore", reason);
                }
            );
        }

        public calcolaUmiditaAccumulata(): number {
            var formula = Math.abs(((this.lotto.PesoUscitaAutoclave - this.lotto.PesoIngressoAutoclave) / this.lotto.PesoIngressoAutoclave) * 100);
            return formula;
        }

        public numGriglieAggiunte(): number {
            if (this.griglie == undefined) return null;
            var result = $.grep(this.griglie, (item, index) => {
                return item.FlgAggiunta;
            }).length;
            return result;
        }

        public numStrumentiSet(): number {
            if (this.set == undefined) return null;
            var qta = 0;
            $.each(this.set.Strumenti, (index, item) => {
                qta += (<Set.StrumentoVm>item).Qta;
            });
            return qta;
        }

        public numStrumentiContati(): number {
            if (this.lotto == undefined) return null;
            var qta = 0;
            $.each(this.lotto.Strumenti, (index, item) => {
                qta += (<Set.StrumentoVm>item).QtaContati;
            });
            //console.log(this.lotto.Strumenti);
            //console.log("numStrumentiContati: " + qta);
            return qta;
        }

        public numStrumentiMancanti(): number {
            if (this.lotto == undefined) return null;
            var qta = 0;
            $.each(this.lotto.Strumenti, (index, item) => {
                qta += (<Set.StrumentoVm>item).QtaMancante;
            });
            return qta;
        }

        public numStrumentiEsauriti(): number {
            if (this.lotto == undefined) return null;
            var qta = 0;
            $.each(this.lotto.Strumenti, (index, item) => {
                qta += (<Set.StrumentoVm>item).QtaEsaurimento;
            });
            return qta;
        }

        public numStrumentiGuasti(): number {
            if (this.lotto == undefined) return null;
            var qta = 0;
            $.each(this.lotto.Strumenti, (index, item) => {
                qta += (<Set.StrumentoVm>item).QtaGuasto;
            });
            return qta;
        }

        public selectMacchinaHandler(e: JQueryEventObject): void {
            var vm = this;
            if (this.macchine == undefined || this.macchine.length <= 0) return;
            var codiceMacchina = e.lettura;
            $.each(this.macchine, (index, item) => {
                console.log("cerco la macchina", item.Codice, codiceMacchina);
                if (item.Codice === codiceMacchina) {
                    vm.idMacchinaSelezionata = index;
                    return;
                }
            });
            this.$scope.$apply();
        }

        public decodeGriglia(codice: string, checkResult: number): Produzione.GrigliaStrumentiVm {
            var griglia: Produzione.GrigliaStrumentiVm;
            $.each(this.lista, (idl, l) => {
                var g = $.grep(l.Griglie, (g, idg) => {
                    console.log(g.Codice, g.CodiceAlternativo, g.CodiceLotto);
                    return g.Codice === codice || g.CodiceAlternativo === codice;
                })[0];
                if (g != undefined) {
                    console.log("Trovata", g);
                    griglia = g;
                    return;
                }
            });
            console.log("roitorno", griglia);
            return griglia;
        }

        public findGrigliaInLottiCaricati(codice: string): Produzione.CaricoMacchina {
            var griglia: Produzione.CaricoMacchina;
            $.each(this.macchine, (idm, m) => {
                var g = $.grep(m.Carico, (c, idc) => {
                    if (c.Codice === codice || c.CodiceAlternativo === codice) {
                        c.IdMacchina = m.Id;
                        console.log("trovata griglia caricata", c);
                        return true;
                    }
                })[0];
                if (g != undefined) {
                    console.log("Trovata in lavaferri", g);
                    griglia = g;
                    return;
                }
            });
            console.log("ritorno carico macchina", griglia);
            return griglia;
        }

        public censimentoSfuso(): void {
            var vm = this;
            var options: ng.ui.bootstrap.IModalSettings = {
                templateUrl: '/views/Produzione/modalCensimentoSfuso.html',
                scope: this.$scope,
                windowClass: "modal-styled",
                size: "xxxl",
                keyboard: false,
                backdrop: "static"
            };
            this.$modalInstance = this.$uibModal.open(options);
        }

        public queryStrumentiDb(tipo: string): void {
            if (tipo === "Codice" && this.strumentoSfuso.Codice.length < 3) return;
            if (tipo === "Descrizione" && this.strumentoSfuso.Descrizione.length < 3) return;

            var ricerca: Trace.ViewModels.RicercaVm = new Trace.ViewModels.RicercaVm();
            var filtro = new Array<ViewModels.RicercaRigaVm>();
            if (this.strumentoSfuso.Codice != undefined) {
                var fcodice = new ViewModels.RicercaRigaVm();
                fcodice.colonna = "Codice";
                fcodice.valore = this.strumentoSfuso.Codice;
                filtro.push(fcodice);
            }
            if (this.strumentoSfuso.Descrizione != undefined) {
                var fdescrizione = new ViewModels.RicercaRigaVm();
                fdescrizione.colonna = "DescrizioneIt";
                fdescrizione.valore = this.strumentoSfuso.Descrizione;
                filtro.push(fdescrizione);
            }
            
            ricerca.max = 50;
            ricerca.colonne = filtro;
            ricerca.sort = new Array<Trace.ViewModels.SortRigaVm>();
            ricerca.sort.push(new Trace.ViewModels.SortRigaVm("Descrizione_it_IT", ViewModels.DirezioneSort.Ascendente));
            this.prodottiFactory.ricerca(ricerca).then((risultato: Trace.Prodotto.ProdottoVm[]) => {
                this.strumentiDb = risultato;
            });
        }

        public strumentoDbSelected(s: Prodotto.ProdottoVm): void {
            if (this.strumentoSfuso == undefined) 
                this.strumentoSfuso = new Prodotto.StrumentoSfuso();

            if (this.strumentoSfuso.Qta == undefined)
                this.strumentoSfuso.Qta = 1;

            this.strumentoSfuso.Id = s.Id;
            this.strumentoSfuso.Codice = s.Codice;
            this.strumentoSfuso.Descrizione = this.localized(s, "Descrizione_");


        }

        public aggiungiStrumentoSfuso(idCentroCosto: number, flgCreazioneSet: boolean = false): boolean {
            var vm = this;
            if (!this.strumentoSfuso.Qta == undefined || this.strumentoSfuso.Descrizione == undefined || isNaN(this.strumentoSfuso.Qta)) {
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return undefined;
            }
            if (flgCreazioneSet == true && this.strumentoSfuso.Codice == undefined) {
                this.msgFactory.error(this.localeFactory.get("validazione_errore"));
                return undefined;
            }

            var exists = false;
            if (this.strumentiSfusi == undefined)
                this.strumentiSfusi = new Array<Prodotto.StrumentoSfuso>();
            else {
                let thereIsErrore: boolean = false;
                $.each(this.strumentiSfusi, (ids, s) => {
                    if (s.Codice === vm.strumentoSfuso.Codice && s.Descrizione === vm.strumentoSfuso.Descrizione) {
                        exists = true;
                        s.Qta += vm.strumentoSfuso.Qta;
                        return;
                    }
                    else if (flgCreazioneSet == true && s.Codice === vm.strumentoSfuso.Codice) {
                        this.msgFactory.error(this.localeFactory.get("validazione_codice_duplicato"));
                        thereIsErrore = true;
                        return;
                    }
                });
                if (thereIsErrore) return undefined;
            }

            if (exists) {
                this.strumentoSfuso = new Prodotto.StrumentoSfuso();
                return true;
            }
            else {
                if (flgCreazioneSet == true && this.strumentoSfuso.Id == undefined) {
                    this.prodottiFactory.CodiceExist(this.strumentoSfuso.Codice).then(
                        risultato => {
                            if (risultato) { // esiste un prodotto non obsoleto con il codice specificato
                                this.msgFactory.error(this.localeFactory.get("validazione_codice_duplicato"));
                                return undefined;
                            }
                            else {
                                this.strumentiSfusi.push(this.strumentoSfuso);                                                               
                                this.strumentoSfuso = new Prodotto.StrumentoSfuso();
                                return false;
                            }
                        }
                    );
                }
                else
                {
                    this.strumentiSfusi.push(this.strumentoSfuso);
                    if (!flgCreazioneSet && this.strumentoSfuso.Id != undefined) {
                        var codici = new Array<string>();
                        $.each(this.strumentiSfusi, (ids, s) => {
                            codici.push(s.Codice);
                        });
                        this.setFactory.RicercaSfusoInBusta(codici).then(
                            result => {
                                this.modelliSfusoCompatibili = result;
                            },
                            reason => { }
                        );
                    }
                    if (this.strumentoSfuso.Id != undefined) this.associaProdottoCentroCosto(this.strumentoSfuso.Id, idCentroCosto);
                    this.strumentoSfuso = new Prodotto.StrumentoSfuso();
                    return false;
                }                
            }
        }

        public modelloSfusoSelected(id: number, descrizione: string, codice: any): void {
            var vm = this;
            this.setFactory.GetSfusoInBusta(id).then(
                result => {
                    console.log("Sfuso in busta", result);
                    vm.flgModelloSfuso = codice;
                    this.strumentiSfusi = new Array<Prodotto.StrumentoSfuso>();
                    $.each(result, (idr, r) => {
                        var p = new Prodotto.StrumentoSfuso();
                        p.Codice = r.Codice;
                        p.Descrizione = r.Uid;
                        p.Qta = r.Qta;
                        vm.strumentiSfusi.push(p);
                    });
                },
                reason => { }
            );
        }

        public associaProdottoCentroCosto(idProdotto: number, idCentroCosto: number): void {
            this.prodottiFactory.AssociaCentroCosto(idProdotto, idCentroCosto);
            //    .then(
            //    result => { },
            //    reason => { }
            //);
        }
    }
}