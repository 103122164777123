app.factory("macchineFactory", ($http, $location, $q) => new Trace.Macchina.macchineFactory($http, $location, $q));

module Trace.Macchina {
    export class Macchina {
        public Id: number;
        public Codice: string;
        public DataCreazione: Date;

        public Descrizione_en_US: string;
        public Descrizione_it_IT: string;
        public Descrizione_sq_AL: string;
        public IdUtenteCreazione: number;
        public IdUtenteObsoleto: number;
        public IdUtenteUltimaModifica: number;
    }

    export class TipoMacchina {
        public Id : number;
        public DataCreazione: Date;

        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public IdFlussoProduzione : number;
        public IdFaseProduzione : number;
        public IdTipoMacchina: number;
        public Ordinamento: number;
    }

    export class macchineFactory {

        public GetBaseUrl(): string {
            return appRoot + "api/Macchina/";
        }


        static $inject = ['$http', '$location', '$q']; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) {

        }

        public lista(): ng.IPromise<Macchina[]> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Macchina[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }


        public listaCompleta(): ng.IPromise<Macchina[]> {
            var workUrl = this.GetBaseUrl() + "ListaCompleta";

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Macchina[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public inserisci(elemento: Macchina): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl();

            var deferred = this.$q.defer();
            this.$http.post(workUrl, elemento).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public modifica(elemento: Macchina): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + elemento.Id;

            var deferred = this.$q.defer();
            this.$http.put(workUrl, elemento).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }


        public cancella(idElemento: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idElemento;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public patch(idElemento: number, daPatchare: {}): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idElemento;

            var deferred = this.$q.defer();
            this.$http.patch(workUrl, daPatchare).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm): ng.IPromise<Macchina[]> {
            var workUrl = this.GetBaseUrl() + 'Ricerca';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: Macchina[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public leggi(idElemento: number): ng.IPromise<Macchina> {
            var workUrl = this.GetBaseUrl() + idElemento;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Macchina, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaTipi(): ng.IPromise<TipoMacchina[]> {
            var workUrl = this.GetBaseUrl() + 'ListaTipi';

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: TipoMacchina[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        public listaTipiAsSe(): ng.IPromise<Helpers.SimpleEntity[]> {
            var workUrl = this.GetBaseUrl() + 'ListaTipiAsSe';

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: Helpers.SimpleEntity[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }

    }
}