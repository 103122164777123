app.factory("inventarioFactory", ($http, $location, $q) => new Trace.Inventario.inventarioFactory($http, $location, $q));

module Trace.Inventario {
    export class Inventario {
        public Id : number;
        public Codice : string;
        public DataCreazione : Date;
        public DataObsoleto : Date;
        public DataUltimaModifica : Date;
        public Descrizione_en_US : string;
        public Descrizione_it_IT : string;
        public Descrizione_sq_AL : string;
        public IdImmagine : number;
        public IdUtenteCreazione : number;
        public IdUtenteObsoleto : number;
        public IdUtenteUltimaModifica : number;
    }

    export class inventarioFactory {
        static $inject = ["$http", "$location", "$q"]; 
        constructor(private $http: ng.IHttpService, private $location: ng.ILocationService, private $q: ng.IQService) { }

        public GetBaseUrl(): string {
            return appRoot + "api/Inventario/";
        }

        public ricerca(filtro: Trace.ViewModels.RicercaVm, page: number = undefined): ng.IPromise<Trace.Inventario.InventarioPaginationVm> {
            var workUrl = this.GetBaseUrl() + 'Ricerca/' + page.toString();
            var deferred = this.$q.defer();
            this.$http.post(workUrl, filtro).success((data: any[], status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        public CheckExcel(item: ViewModels.ReadExcelItem): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "CheckExcel";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, item).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }
        public ReadExcel(item: ViewModels.ReadExcelItem): ng.IPromise<string> {
            var workUrl = this.GetBaseUrl() + "ReadExcel";
            var deferred = this.$q.defer();
            this.$http.post(workUrl, item).then(
                result => {
                    deferred.resolve(result.data);
                },
                reason => {
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        public cancella(idInventario: number): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + idInventario;

            var deferred = this.$q.defer();
            this.$http.delete(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }

        public leggi(idInventario: number): ng.IPromise<any> {
            var workUrl = this.GetBaseUrl()+'Get/' + idInventario;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: any, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;
        }
               
        public salva(inventario: any): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl()+'Salva';

            var deferred = this.$q.defer();
            this.$http.post(workUrl, inventario).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }   
        public generaMovimentiRettifica(idInventario: any): ng.IPromise<boolean> {
            var workUrl = this.GetBaseUrl() + 'GeneraMovimentiRettifica/' + idInventario;

            var deferred = this.$q.defer();
            this.$http.get(workUrl).success((data: boolean, status) => {
                deferred.resolve(data);
            }).error(error => {
                deferred.reject(error);
            });

            return deferred.promise;

        }      
    }
}