module Trace.Analisi {
    export class FiltroInevaso implements FiltroInevasoVm {        
        DataCreazioneDal: Date;
        StrDataCreazioneDal: string;
        DataCreazioneAl: Date;
        StrDataCreazioneAl: string;
        IdUtenteCreazione: number;
        IdFornitore: number;
        IdGruppo: number;
        IdSottoGruppo: number;
        IdSede: number;
        IdCentroCosto: number;
        CodiceProdotto: string;
        DescrizioneProdotto: string;
        TipoProdotto: string;
        Numero: string;
        Serie: string;
    }
}
