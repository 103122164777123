module Trace.Stampe {
    export class scarichiConsumabiliController extends epBaseController {
        static $inject = ["$scope", "Upload", "stampeFactory", "utenteFactory", "prodottiFactory", "documentoFactory", "localeFactory", "msgFactory", "$uibModal", "tracciabilitaFactory"];
        private lista: Array<VwScaricoConsumabile>;
        private formScaricoConsumabili: any;
        private filtro: FiltroScaricoConsumabili;
        private filtroFatture: FiltroFatture;

        constructor(
            private $scope: ng.IScope,
            public upload: ng.angularFileUpload.IUploadService,
            private stampeFactory: stampeFactory,
            public utenteFactory: Utente.utenteFactory,
            public prodottiFactory: Prodotto.prodottiFactory,
            private documentoFactory: Documento.documentoFactory,
            public localeFactory: Trace.Localization.localeFactory,
            public msgFactory: Trace.Messages.msgFactory,
            public $uibModal: ng.ui.bootstrap.IModalService,
            public tracciabilitaFactory: Tracciabilita.tracciabilitaFactory
        ) {
            super(upload, utenteFactory, undefined, prodottiFactory, undefined, localeFactory, msgFactory, $uibModal, undefined, tracciabilitaFactory);
            this.formScaricoConsumabili = {};
            this.filtro = {
                DataDa: undefined,
                DataA: undefined,
                IdGruppo: undefined,
                IdSottogruppo: undefined,
                IdSede: undefined,
                IdCentroCosto: undefined,
                Codice: undefined
            };
            this.setUpWatchers();
        }

        private setUpWatchers(): void {
            this.$scope.$watch("vm.filtroFatture.Numero", (newVal: string, oldVal: string) => {
                
            });
        }

        private ricerca(): void {
            this.stampeFactory.RicercaScarichiConsumabili(this.filtro).then(
                result => {
                    this.lista = result;
                },
                reason => {

                }
            );
        }

        private getExportUrl(): string {
            var result = appRoot + "Reports/ExportScarichiConsumabili?strFiltro=" + JSON.stringify(this.filtro);
            return result;
        }
    }
}
app.controller("scarichiConsumabiliController", Trace.Stampe.scarichiConsumabiliController);